import { Popover } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/outline";

import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import { UserComboboxChildrenProps } from "@components/user-combobox/user-combobox";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

const DashboardUserSelector = ({
  setReferenceElement,
  value,
  clearable,
  onClearValue,
  onClickButton,
}: Omit<UserComboboxChildrenProps, "value"> & {
  value: UserComboboxUserOption | null;
}) => {
  const currentUser = currentUserVar();
  return (
    <div
      className={classNames(
        "flex items-center border justify-between relative w-full rounded-full px-2 text-left cursor-default sm:text-sm focus:ring-0 focus:outline-none",
        value && value.id !== currentUser.id
          ? "bg-blue-900 text-white border-blue-900"
          : "bg-white text-gray-800 border-gray-300"
      )}
    >
      <Popover.Button
        ref={setReferenceElement}
        className={classNames(
          "flex-1 flex items-center gap-3 text-sm py-1 focus:outline-0 focus:ring-0",
          "min-w-0" // prevent child el with flex-1 to overflow and will add ellipsis in that case
        )}
        onClick={onClickButton}
      >
        <Avatar user={value} size="6" />
        <div className="flex-1 text-left text-base font-medium truncate overflow-hidden">
          {value?.name}
        </div>
        {!clearable && (
          <div className="shrink-0 grow-0 px-1 flex items-center text-gray-400">
            <SelectorIcon className="h-5 w-5" />
          </div>
        )}
      </Popover.Button>
      {clearable && onClearValue && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClearValue();
          }}
          className="shrink-0 grow-0 px-1 flex items-center text-blue-900"
          aria-label="Combobox clear button"
        >
          <span className="px-0.5 py-0.5 opacity-60 hover:opacity-100 bg-white rounded-full ">
            <XIcon className="h-3 w-3" />
          </span>
        </button>
      )}
    </div>
  );
};

export default DashboardUserSelector;
