import { MenuItem } from "@szhsin/react-menu";
import copy from "copy-to-clipboard";

import { successNotificationVar } from "@cache/cache";
import { getUrl } from "@helpers/helpers";

const CopyUrlMenu = ({ meeting, meetingGroup, ...props }) => {
  const handleCopyLink = ({ syntheticEvent }) => {
    syntheticEvent.preventDefault();
    syntheticEvent.stopPropagation();
    const urlToCopy = `${window.location.origin}${getUrl({
      meetingGroupId: meetingGroup?.id || meeting.meetingGroup?.id,
      meetingId: meeting?.id,
    })}`;
    copy(urlToCopy);
    successNotificationVar({
      title: "Meeting url copied.",
    });
  };

  // Render
  return (
    <MenuItem className={"text-sm"} onClick={handleCopyLink} {...props}>
      Copy meeting link
    </MenuItem>
  );
};

export default CopyUrlMenu;
