import { useMutation } from "@apollo/client";
import { TbToggleLeft, TbToggleRightFilled } from "react-icons/tb";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import toggleTopicIndividualNoteVisibilityMutation from "../graphql/toggle-topic-individual-note-visibility-mutation";
import { getOptimisticResponseWhenTogglingIndividualNotes } from "../helpers";

export const defaultNotesLocalStorageKey = "default-notes";

const TopicToolbar = ({
  topic,
  meeting,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  // Render
  const [
    toggleIndividualNoteVisibility,
    { loading: loadingToggleIndividualNotes },
  ] = useMutation(toggleTopicIndividualNoteVisibilityMutation);

  const currentUser = currentUserVar();
  const individualNotes = topic.individualNotes
    ? assertEdgesNonNull(topic.individualNotes)
    : [];

  const currentUserNote = individualNotes.find(
    (node) => node.creator.id === currentUser.id
  );
  const currentUserNoteEnabled = currentUserNote?.enabled || false;

  const handleToggleIndividualNotes = () => {
    const enabled = !currentUserNoteEnabled;
    const optimisticResponse = getOptimisticResponseWhenTogglingIndividualNotes(
      meeting,
      topic,
      enabled
    );
    toggleIndividualNoteVisibility({
      variables: {
        topicIds: [topic.id],
        enabled,
      },
      optimisticResponse,
      onError: onNotificationErrorHandler(),
    });
  };

  const buttonClassName =
    "text-xs tracking-tighter @xl/topic-toolbar:tracking-tight text-gray-400 flex gap-0.5 @xl/topic-toolbar:gap-1 items-center px-1.5 py-1 hover:bg-gray-100 hover:text-gray-600 rounded-md ";
  return (
    topic.canUpdate.permission &&
    !meeting.meetingGroup?.isFormalOneonone && (
      <div className="flex items-center">
        <Tooltip text="Toggle my notes" delay={0}>
          <button
            className={buttonClassName}
            onClick={handleToggleIndividualNotes}
          >
            {loadingToggleIndividualNotes ? (
              <Loading mini size="4" />
            ) : topic.includesIndividualNotesForCurrentUser ? (
              <TbToggleRightFilled className="h-4 w-4" />
            ) : (
              <TbToggleLeft className="h-4 w-4" />
            )}
            My notes
          </button>
        </Tooltip>
      </div>
    )
  );
};

export default TopicToolbar;
