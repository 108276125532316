import { PlusCircleIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { ArtifactType, FeedbackState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import Error from "@components/error/error";

import getDashboardFeedbackQuery from "../graphql/get-dashboard-feedback-query";
import FeedbacksCollapsibleContainer from "./feedbacks-collapsible-container";

const DashboardFeedback = ({
  selectedUser,
  createdBetweenDates,
}: {
  selectedUser: BasicUser;
  createdBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);
  const label = useLabel();

  return (
    <div aria-label="Dashboard feedback container">
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Feedback,
            feedbackRecipients:
              selectedUser.id !== currentUser.id ? [selectedUser] : [],
          }}
          onClose={() => setIsShowingCreateDialog(false)}
          refetchQueries={[getDashboardFeedbackQuery]}
        />
      )}
      <div>
        <CollapsibleContainerParent
          title={
            selectedUser.id === currentUser.id
              ? label("feedback", { pluralize: true, capitalize: true })
              : `${selectedUser.firstName || selectedUser.name}'s ${label(
                  "feedback",
                  {
                    pluralize: true,
                    capitalize: true,
                  }
                )}`
          }
          titleLink="/feedbacks"
          rightSide={
            <Button
              className="shrink-0"
              icon
              theme={buttonTheme.iconGray}
              onClick={() => setIsShowingCreateDialog(true)}
              tooltip={`Give ${label("feedback")}`}
            >
              <PlusCircleIcon className="h-6 w-6" />
            </Button>
          }
        >
          <FeedbacksCollapsibleContainer
            title={`${label("feedback", { capitalize: true })} received`}
            filters={{
              feedbackRecipient: selectedUser.id,
              asUser: selectedUser.id,
              feedbackState: FeedbackState.Sent,
              first: 5,
              createdBetweenDates,
            }}
            localStorageKey="dashboardFeedbackReceivedExpanded"
          />
          <FeedbacksCollapsibleContainer
            title={`${label("feedback", { capitalize: true })} given`}
            filters={{
              feedbackSender: selectedUser.id,
              asUser: selectedUser.id,
              feedbackState: FeedbackState.Sent,
              first: 5,
              createdBetweenDates,
            }}
            localStorageKey="dashboardFeedbackGivenExpanded"
            roundedBottom
          />
        </CollapsibleContainerParent>
      </div>
    </div>
  );
};

export default Sentry.withErrorBoundary(DashboardFeedback, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
