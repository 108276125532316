import { Link, useLocation } from "react-router-dom";
import {
  ArtifactType,
  GoalArtifactNode,
  GoalState,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import DraftLabel from "@components/draft-label/draft-label";
import GoalIcon from "@components/goal-icon/goal-icon";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

export type WorkHistoryGoalDataItem = {
  isNew: boolean;
  isClosed: boolean;
  activityCount: number;
  goal?: Pick<GoalArtifactNode, "id" | "title" | "state" | "scope"> | null;
};

const WorkHistoryGoalUpdate = ({
  updateData,
}: {
  updateData: WorkHistoryGoalDataItem;
}) => {
  const location = useLocation<TFLocationState>();
  const label = useLabel();

  if (!updateData.goal) {
    return null;
  }

  const artifactUrl = getUrl({
    artifactId: updateData.goal.id,
    artifactType: ArtifactType.Goal,
  });

  return (
    <div className="flex items-center gap-2">
      <div>
        <GoalIcon state={updateData.goal.state} scope={updateData.goal.scope} />
      </div>
      <div>
        <div>
          <Link
            className={classNames("flex-1 break-word group line-clamp-2")}
            to={toWithBackground({
              pathname: artifactUrl,
              location,
            })}
          >
            <span className="font-medium no-underline group-hover:underline-offset-2 group-hover:underline group-hover:text-blue-600 group-hover:decoration-blue-300">
              {updateData.goal.title}
            </span>
            {updateData.goal.state === GoalState.Draft && (
              <DraftLabel className="ml-1" />
            )}
          </Link>
        </div>
        <div className="text-xs text-gray-500">
          {`${updateData.isNew && `New ${label("goal")}. `}${
            updateData.isClosed && `Closed. `
          }${updateData.activityCount} ${pluralize(
            "update",
            updateData.activityCount
          )} during this time period.`}
        </div>
      </div>
    </div>
  );
};

export default WorkHistoryGoalUpdate;
