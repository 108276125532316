import { useMutation } from "@apollo/client";
import { compact } from "lodash";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import { currentOrganizationVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import { refetchActionItemsInCollapsibleContainers } from "../../action-items-collapsible/helpers";
import updateArtifactMutation from "../graphql/update-artifact-mutation";

const ArtifactActionItemCompleteToggle = ({
  artifact,
  disabled = false,
  className,
  children = null,
  size = "5",
  isStatic = false,
}: {
  artifact: {
    id: number;
    isComplete?: boolean | null;
  };
  disabled?: boolean;
  className?: string;
  children?: any;
  size?: string | number;
  isStatic?: boolean;
}) => {
  const [updateArtifact] = useMutation(updateArtifactMutation);

  const currentOrganization = currentOrganizationVar();
  const actionItemStates = currentOrganization.actionItemStates
    ? compact(currentOrganization.actionItemStates)
    : [];
  const completeState = actionItemStates.find(
    (state) => state.isComplete
  )?.value;
  const incompleteState = actionItemStates.find(
    (state) => state.isDefaultIncomplete
  )?.value;

  const handleToggleActionItem = () => {
    if (disabled) return;
    const newState = artifact.isComplete ? incompleteState : completeState;
    const variables = {
      artifactId: artifact.id,
      additionalFields: { actionItemState: newState },
    };
    updateArtifact({
      variables,
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            isComplete: !artifact.isComplete,
            actionItemState: newState,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      update: (_, { data }) => {
        refetchActionItemsInCollapsibleContainers(
          data.createOrUpdateArtifact.artifact
        );
      },
      onError: onNotificationErrorHandler(),
      refetchQueries: [getArtifactActivitiesQuery],
    });
  };
  const icon = artifact.isComplete ? (
    <MdOutlineCheckBox className={`w-${size} h-${size}`} aria-label="checked" />
  ) : (
    <MdCheckBoxOutlineBlank
      className={`w-${size} h-${size}`}
      aria-label="unchecked"
    />
  );
  return isStatic ? (
    <span className="text-blue-600">{icon}</span>
  ) : (
    <button
      className={classNames(
        "text-blue-600 hover:text-blue-800 flex items-center gap-2 shrink-0",
        className
      )}
      disabled={disabled}
      contentEditable={false}
      aria-label="action item toggle button"
      onClick={handleToggleActionItem}
    >
      {icon}
      {children}
    </button>
  );
};

export default ArtifactActionItemCompleteToggle;
