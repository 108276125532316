import { ApolloError, useQuery } from "@apollo/client";
import { compact } from "lodash";
import { useEffect, useState } from "react";
import {
  Route,
  Switch,
  matchPath,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  GetMeetingGroupQueryQuery,
  GetMeetingGroupQueryQueryVariables,
} from "types/graphql-schema";

import ArtifactSidebarRoute from "@apps/artifact-sidebar/artifact-sidebar-route";
import { artifactTypePaths } from "@apps/main/routes";
import TopicSidebar from "@apps/topic-sidebar/topic-sidebar";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { artifactType } from "@helpers/constants";

import ArtifactsTab from "./components/artifacts/artifacts-tab";
import MeetingPageHeader from "./components/header";
import AllMeetingsTab from "./components/meetings-tab";
import SettingsTab from "./components/settings/settings-tab";
import getMeetingGroupQuery from "./graphql/get-meeting-group-query";

const MeetingPageError = ({ error }: { error: ApolloError | Error }) => {
  const currentUser = currentUserVar();

  return (
    <Container>
      <GraphqlError
        error={error}
        whatDidNotWork="We can't show you this meeting right now."
        descriptionList={[
          "The meeting might have been deleted by you or another participant.",
          "The meeting is a draft meeting and is only accessible by its creator.",
          `Or, make sure you're logged in using the email address matching the meeting invite. You're currently logged in as ${currentUser.emails.join(
            ", "
          )}.`,
          "Or, an unexpected error might have occurred on our side. We have been notified. Try again by refreshing the page.",
        ]}
      />
    </Container>
  );
};

const MeetingPage = () => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  let { meetingGroupId } = useParams<{
    meetingGroupId: any;
  }>();
  const label = useLabel();

  // If we start on a meeting page, store the current meeting id in the state so
  // that as we navigate to places like settings, the Meeting tab will go back
  // to the meeting we were viewing
  const [currentMeetingId, setCurrentMeetingId] = useState(null);

  useEffect(() => {
    const match: any = matchPath(location.pathname, {
      path: `${path}/:meetingId(\\d+)`,
      exact: false,
      strict: false,
    });
    const meetingId = match?.params?.meetingId || null;

    // only update the meeting id if:
    // - a meeting id was found. if one wasn't, we're probably on something like
    //   the settings page, and don't want to clear out the saved meeting id
    if (meetingId) {
      setCurrentMeetingId(meetingId);
    }
  }, [location]);

  meetingGroupId = parseInt(meetingGroupId);
  const tabs = compact([
    {
      label: "Meetings",
      path: currentMeetingId ? `/${currentMeetingId}` : "",
    },
    {
      label: "Action items",
      path: "/action-items",
      artifactType: artifactType.actionItem,
    },
    {
      label: "Decisions",
      path: "/decisions",
      artifactType: artifactType.decision,
    },
    {
      label: label("goal", { pluralize: true, capitalize: true }),
      path: "/goals",
      artifactType: artifactType.goal,
    },
    {
      label: "Documents",
      path: "/documents",
      artifactType: artifactType.document,
    },
    meetingGroupId && {
      label: "Settings",
      path: "/settings",
    },
  ]);

  const hasMeetingGroup = !!parseInt(meetingGroupId);
  const { loading, error, data } = useQuery<
    GetMeetingGroupQueryQuery,
    GetMeetingGroupQueryQueryVariables
  >(getMeetingGroupQuery, {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: {
      meetingGroupId: meetingGroupId || -1,
      hasMeetingGroup,
    },
    onError: onNotificationErrorHandler(),
  });

  // Renders
  if (error && !data) {
    return <MeetingPageError error={error} />;
  }
  if (loading || !data) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (!data.meetingGroup) {
    return (
      <MeetingPageError
        error={new Error("Meeting group could not be loaded")}
      />
    );
  }

  return (
    <div
      className="min-h-full flex flex-col items-stretch min-w-0"
      aria-label="Meeting page"
    >
      <MeetingPageHeader
        data={data}
        url={url}
        currentMeetingId={currentMeetingId}
      />

      <Switch>
        {tabs
          .filter((tab) => tab.artifactType)
          .map((tab) => (
            // routes for the artifacts
            <Route key={tab.artifactType} exact path={`${path}${tab.path}`}>
              <ArtifactsTab
                type={tab.artifactType}
                meetingGroupId={meetingGroupId}
              />
            </Route>
          ))}
        {!!meetingGroupId && (
          <Route exact path={[`${path}/template`, `${path}/settings`]}>
            <SettingsTab meetingGroup={data.meetingGroup} />
          </Route>
        )}

        {/* Show meeting group */}
        <Route path={[`${path}/:meetingId(\\d+)`, `${path}`]}>
          {!!data.meetingGroup && (
            <AllMeetingsTab meetingGroup={data.meetingGroup} />
          )}
        </Route>
      </Switch>

      <Route
        path={`${path}/:meetingId(\\d+)/(${artifactTypePaths})/:artifactId`}
      >
        <ArtifactSidebarRoute />
      </Route>
      <Route path={`${path}/:meetingId(\\d+)/topic/:topicId`}>
        <TopicSidebar />
      </Route>
    </div>
  );
};

export default MeetingPage;
