import PusherSystemUpdate from "@apps/pusher/components/system-update";
import { currentUserVar } from "@cache/cache";

import PusherArtifactCreated from "./components/artifact-created";
import PusherArtifactDeleted from "./components/artifact-deleted";
import PusherArtifactReordered from "./components/artifact-reordered";
import PusherArtifactUpdated from "./components/artifact-updated";
import PusherCommentAddedOrUpdated from "./components/comment-added-or-updated";
import PusherCommentDeleted from "./components/comment-deleted";
import PusherGoalProgressUpdated from "./components/goal-progress-updated";
import PusherMeetingGroupOneononeStatusChange from "./components/meeting-group-oneonone-status-change";
import PusherMeetingGroupTranscriptionBotSettingChange from "./components/meeting-group-transcription-bot-setting-change";
import PusherMeetingUpdated from "./components/meeting-updated";
import PusherNewChatMessage from "./components/new-chat-message";
import PusherRecallBotStatusChange from "./components/recall-bot-status-change";
import PusherTopicCreated from "./components/topic-created";
import PusherTopicDeleted from "./components/topic-deleted";
import PusherTopicReordered from "./components/topic-reordered";
import PusherTopicUpdated from "./components/topic-updated";
import PusherTranscriptionStatusChange from "./components/transcription-status-change";

export default function Pusher({ children }: { children: any }) {
  const currentUser = currentUserVar();
  if (!currentUser) {
    return children;
  }
  const channelName = currentUser.pusherChannelName;
  return channelName ? (
    <>
      <PusherTopicReordered channelName={channelName} />
      <PusherTopicCreated channelName={channelName} />
      <PusherTopicUpdated channelName={channelName} />
      <PusherMeetingUpdated channelName={channelName} />
      <PusherTopicDeleted channelName={channelName} />
      <PusherCommentDeleted channelName={channelName} />
      <PusherCommentAddedOrUpdated channelName={channelName} />
      <PusherMeetingGroupOneononeStatusChange channelName={channelName} />
      <PusherGoalProgressUpdated channelName={channelName} />
      <PusherArtifactUpdated channelName={channelName} />
      <PusherArtifactDeleted channelName={channelName} />
      <PusherArtifactCreated channelName={channelName} />
      <PusherArtifactReordered channelName={channelName} />
      <PusherNewChatMessage channelName={channelName} />
      <PusherTranscriptionStatusChange channelName={channelName} />
      <PusherRecallBotStatusChange channelName={channelName} />
      <PusherMeetingGroupTranscriptionBotSettingChange
        channelName={channelName}
      />
      <PusherSystemUpdate channelName="system" />
      {children}
    </>
  ) : null;
}
