import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetAssessmentQuery,
  GetAssessmentQueryVariables,
} from "types/graphql-schema";

import RelatedRequirements from "@apps/assessments/components/related-requirements";
import Feedbacks from "@apps/dashboard/components/feedbacks";
import Recognitions from "@apps/dashboard/components/recognitions";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getAssessmentQuery from "../graphql/get-assessment-query";
import { bgClassName } from "../helpers";
import AssessmentGoalSidebar from "./assessment-goal-sidebar";
import AssessmentLeftCol from "./new-assessment-page/assessment-left-col";
import AssessmentRightCol from "./new-assessment-page/assessment-right-col";

type AssessmentAnswer = {
  questionId: number;
  integerAnswer?: number | null;
  textAnswer: string | null;
  choices?: (number | null)[] | null;
  comment: string | null;
};

const getAnswersFromAssessment = (
  assessment: GetAssessmentQuery["assessment"]
) => {
  const answersEdges = assertEdgesNonNull(assertNonNull(assessment).answers);
  return answersEdges.map((a) => ({
    questionId: a.question.id,
    integerAnswer:
      a.__typename === "RangeAssessmentAnswerNode" ? a.integerAnswer : null,
    textAnswer:
      a.__typename === "TextAssessmentAnswerNode" ? a.textAnswer : null,
    choices:
      a.__typename === "MultiChoiceAssessmentAnswerNode"
        ? [...a.choices]
        : null,
    comment: a.comment,
  }));
};

const Assessment = () => {
  const [proposedAnswers, setProposedAnswers] = useState<AssessmentAnswer[]>(
    []
  );
  const { assessmentId: assessmentIdParam } = useParams<{
    assessmentId: string;
  }>();
  const assessmentId = parseInt(assessmentIdParam);
  const organization = currentOrganizationVar();
  const label = useLabel();

  const { data: myAssessmentsData, loading: isLoadingAssessment } = useQuery<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >(getAssessmentQuery, {
    variables: {
      assessmentId,
      organizationId: organization?.id,
    },
    onCompleted: (response) => {
      setProposedAnswers(getAnswersFromAssessment(response.assessment));
    },
    onError: onNotificationErrorHandler(),
  });

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );
  const complainceProgramPeriod = useMemo(
    () =>
      complianceProgram?.periodStartDate && complianceProgram?.periodEndDate
        ? [complianceProgram.periodStartDate, complianceProgram.periodEndDate]
        : undefined,
    [complianceProgram]
  );
  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );

  if (isLoadingAssessment) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessment) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label("review", { capitalize: true })} not found
      </div>
    );
  }

  const relatedMeetings = assessment.relatedMeetings.edges.map((edge) =>
    assertNonNull(edge?.node)
  );
  const showSidebar =
    relatedMeetings.length > 0 ||
    organization.featureFlags.goals ||
    organization.featureFlags.recognitions ||
    organization.featureFlags.feedbacks;

  return (
    <div className="grid lg:grid-cols-6 flex-1 gap-4 lg:gap-6">
      <div
        className={classNames(
          bgClassName,
          "flex  flex-col lg:flex-row",
          showSidebar ? "lg:col-span-4" : "lg:col-span-6"
        )}
        aria-label="Assessments > performance assessment form"
      >
        <AssessmentLeftCol
          myAssessmentsData={myAssessmentsData}
          isLoadingAssessment={isLoadingAssessment}
        />
        <AssessmentRightCol
          myAssessmentsData={myAssessmentsData}
          isLoadingAssessment={isLoadingAssessment}
          proposedAnswers={proposedAnswers}
          onChangeProposedAnswers={setProposedAnswers}
        />
      </div>
      {showSidebar && (
        <div className="lg:col-span-2 flex flex-col gap-6">
          <RelatedRequirements relatedMeetings={relatedMeetings} />
          {organization.featureFlags.goals && (
            <AssessmentGoalSidebar
              selectedUser={assertNonNull(target)}
              goalDueBetweenDates={complainceProgramPeriod}
            />
          )}
          {organization.featureFlags.recognitions && (
            <Recognitions
              selectedUser={assertNonNull(target)}
              createdBetweenDates={complainceProgramPeriod}
            />
          )}
          {organization.featureFlags.feedbacks && (
            <Feedbacks
              selectedUser={assertNonNull(target)}
              createdBetweenDates={complainceProgramPeriod}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Assessment;
