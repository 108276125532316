import { compact } from "lodash";
import { useRouteMatch } from "react-router-dom";
import {
  ArtifactType,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import ActionItemsCollapsible from "@apps/action-items-collapsible/action-items-collapsible-container";
import ActionItemsCollapsibleDragAndDropContext from "@apps/action-items-collapsible/action-items-collapsible-drag-drop-context";
import { currentOrganizationVar } from "@cache/cache";

import MeetingSidebarCollapsibleContainer from "./meeting-sidebar-collapsible-container";
import useMeetingSidebarCollapsibleContainer from "./use-meeting-sidebar-collapsible-container";

const ActionItems = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const { url } = useRouteMatch();
  const currentOrganization = currentOrganizationVar();
  const actionItemStates = compact(currentOrganization?.actionItemStates || []);

  const {
    isFormalOneonone,
    subject,
    filterAssigneeId,
    participantIds,
    toggledAssignee,
    toggledJustMeeting,
  } = useMeetingSidebarCollapsibleContainer({
    meeting,
    collapsibleFilterName: "ActionItems",
  });

  const variables = {
    meetingGroupId: toggledJustMeeting ? meeting.meetingGroup?.id : undefined,
    assigneeId: toggledAssignee ? filterAssigneeId : undefined,
    assigneeIds: toggledAssignee ? undefined : participantIds,
  };

  if (!meeting.meetingGroup) return null;

  return (
    <MeetingSidebarCollapsibleContainer
      collapsibleFilterName="ActionItems"
      subject={subject}
      isFormalOneonone={isFormalOneonone}
      toggledAssignee={toggledAssignee}
      toggledJustMeeting={toggledJustMeeting}
      createArtifactFormOptions={{
        artifactType: ArtifactType.ActionItem,
        meetingGroupId: meeting.meetingGroup?.id,
        meetingId: meeting.id,
      }}
      titleLabel="action item"
    >
      <ActionItemsCollapsibleDragAndDropContext
        actionItemStates={actionItemStates}
        orderContextId={meeting.meetingGroup?.id}
        orderContextTypePrefix="meeting_group_action_items"
      >
        {actionItemStates.map((actionItemState, i) => (
          <ActionItemsCollapsible
            key={actionItemState?.value}
            actionItemState={actionItemState}
            roundedBottom={i === actionItemStates.length - 1}
            uiPreferenceCacheNamespace={"meetingActionItemsExpanded"}
            path={url}
            queryVariables={{
              ...variables,
              after: null,
              first: 5,
              orderBy: "custom",
              orderContextType: `${"meeting_group_action_items"}:${
                actionItemState?.value
              }`,
              orderContextId: meeting.meetingGroup?.id,
              actionItemState: actionItemState?.value,
            }}
          />
        ))}
      </ActionItemsCollapsibleDragAndDropContext>
    </MeetingSidebarCollapsibleContainer>
  );
};

export default ActionItems;
