import { useMutation, useQuery } from "@apollo/client";
import { CalendarIcon } from "@heroicons/react/outline";
import { ClickEvent, MenuDivider, MenuItem, SubMenu } from "@szhsin/react-menu";
import sortBy from "lodash/sortBy";
import moment from "moment";
import {
  MeetingViewMeetingNodeFragmentFragment,
  TopicNode,
} from "types/graphql-schema";

import { errorNotificationVar, successNotificationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import copyTopicToMeetingMutation from "../graphql/copy-topic-to-meeting-mutation";
import getTopicMeetingsQuery from "../graphql/get-topic-meetings";

const AddToMeetingsSubMenu = ({
  topic,
  meetingGroup,
  excludeIds = [],
  withSubMenu = true,
}: {
  topic: Pick<TopicNode, "id">;
  meetingGroup: MeetingViewMeetingNodeFragmentFragment["meetingGroup"];
  excludeIds?: number[];
  withSubMenu?: boolean;
}) => {
  const { data, loading: loadingMeetings } = useQuery(getTopicMeetingsQuery, {
    variables: {
      topicId: topic.id,
      meetingGroupId: meetingGroup?.id,
      startDatetime: moment().startOf("day").format(),
      excludeIds,
    },
    onError: onNotificationErrorHandler(),
  });

  const [copyTopicToMeeting] = useMutation(copyTopicToMeetingMutation, {
    onError: (e) => {
      errorNotificationVar({
        description: "The topic could not be added to the meeting.",
      });
    },
    onCompleted: () => {
      successNotificationVar({ title: "Topic added in meeting." });
    },
  });

  /* Handler */
  const handleToggleMeeting = ({ value }: ClickEvent) => {
    copyTopicToMeeting({
      variables: {
        topicIds: [topic.id],
        meetingId: value,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  // Generate meeting menu items
  const meetingNodes = data?.meetingGroup.meetings.edges;
  const sortedMeetings = sortBy(meetingNodes, ({ node }) => node.startDatetime);
  const meetingMenuItems = sortedMeetings.reduce((memo, { node }) => {
    const id = node.id;
    const date = moment(node.startDatetime).format("dddd, MMM D, YYYY");
    const label = moment(node.startDatetime).isSame(moment(), "day")
      ? `${date} (Today)`
      : moment(node.startDatetime).isSame(moment().add(1, "day"), "day")
      ? `${date} (Tomorrow)`
      : date;
    return memo.concat({
      value: id,
      label,
    });
  }, []);
  const menuItems = [].concat(meetingMenuItems);

  const menuContent = loadingMeetings ? (
    <MenuItem onClick={handleToggleMeeting}>
      <div className="w-full flex items-center justify-center">
        <Loading mini size="4" />
      </div>
    </MenuItem>
  ) : (
    <>
      {menuItems.map(({ label, value }) =>
        value === "divider" ? (
          <MenuDivider key={value} />
        ) : (
          <MenuItem
            key={value}
            className="text-sm"
            onClick={handleToggleMeeting}
            value={value}
          >
            <div className="flex items-center">{label}</div>
          </MenuItem>
        )
      )}
    </>
  );

  // Render
  return withSubMenu ? (
    <SubMenu
      className="text-sm"
      aria-label="Copy to meeting dropdown menu list"
      label={
        <div className="flex items-center">
          {" "}
          <CalendarIcon
            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />{" "}
          Duplicate to meeting
        </div>
      }
    >
      {menuContent}
    </SubMenu>
  ) : (
    menuContent
  );
};

export default AddToMeetingsSubMenu;
