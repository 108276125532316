import { PlusCircleIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { ArtifactType, GoalState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import GoalsCollapsibleContainer from "@apps/dashboard/components/goals-collapsible-container";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import Error from "@components/error/error";

const AssessmentGoalSidebar = ({
  selectedUser,
  goalDueBetweenDates,
}: {
  selectedUser: BasicUser;
  goalDueBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const [isShowingCreateGoalDialog, setIsShowingCreateGoalDialog] =
    useState(false);
  const label = useLabel();

  return (
    <div aria-label="Dashboard goal container">
      {isShowingCreateGoalDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Goal,
            owners: [selectedUser],
          }}
          onClose={() => setIsShowingCreateGoalDialog(false)}
        />
      )}
      <div>
        <CollapsibleContainerParent
          title={
            selectedUser.id === currentUser.id
              ? label("goal", { pluralize: true, capitalize: true })
              : `${selectedUser.firstName || selectedUser.name}'s ${label(
                  "goal",
                  {
                    pluralize: true,
                    capitalize: true,
                  }
                )}`
          }
          titleLink={`/goals/user/${selectedUser.id}`}
          rightSide={
            <Button
              className="shrink-0"
              icon
              theme={buttonTheme.iconGray}
              onClick={() => setIsShowingCreateGoalDialog(true)}
              tooltip={`Create ${label("goal")}`}
              aria-label="Collapsible create goal button"
            >
              <PlusCircleIcon className="h-6 w-6" />
            </Button>
          }
        >
          <GoalsCollapsibleContainer
            title={"Owned"}
            filters={{
              owners: [selectedUser.id],
              goalStates: [GoalState.Open, GoalState.Draft, GoalState.Closed],
              forUserId: selectedUser.id,
              goalDueBetweenDates,
            }}
            localStorageKey="assessmentOwnedGoalsExpanded"
          />
          <GoalsCollapsibleContainer
            title={"Contributing"}
            filters={{
              contributors: [selectedUser.id],
              goalStates: [GoalState.Open, GoalState.Draft, GoalState.Closed],
              forUserId: selectedUser.id,
              goalDueBetweenDates,
            }}
            localStorageKey="assessmentContributingGoalsExpanded"
          />
        </CollapsibleContainerParent>
      </div>
    </div>
  );
};

export default Sentry.withErrorBoundary(AssessmentGoalSidebar, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
