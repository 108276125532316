import { useEffect, useState } from "react";

type DocumentExtended = Document & {
  msHidden?: boolean;
  webkitHidden?: boolean;
};

function getBrowserVisibilityProp(document: DocumentExtended) {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

function getBrowserDocumentHiddenProp(document: DocumentExtended) {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function getIsDocumentHidden(document: DocumentExtended) {
  const hiddenProp = getBrowserDocumentHiddenProp(document);
  if (!hiddenProp) {
    return true;
  }
  return !document[hiddenProp];
}

export default function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden(document));
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden(document));

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp(document);
    if (visibilityChange) {
      document.addEventListener(visibilityChange, onVisibilityChange, false);

      return () => {
        document.removeEventListener(visibilityChange, onVisibilityChange);
      };
    }
  });

  return isVisible;
}
