import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetKpiGroupQueryQuery,
  GetKpiGroupQueryQueryVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import createOrUpdateKpiGroupMutation from "../graphql/create-or-update-kpi-group-mutation";
import getKpiGroupQuery from "../graphql/get-kpi-group-query";

const KPIGroupForm = () => {
  const link = useLink();
  let { kpiGroupId } = useParams();
  kpiGroupId = parseInt(kpiGroupId) || null;
  const currentOrganization = currentOrganizationVar();

  const [form, setForm] = useState({ title: "" });

  const { loading, error } = useQuery<
    GetKpiGroupQueryQuery,
    GetKpiGroupQueryQueryVariables
  >(getKpiGroupQuery, {
    variables: { kpiGroupId },
    skip: !kpiGroupId,
    onCompleted: (response) => {
      const kpiGroup = response.kpiGroup;
      setForm({ title: kpiGroup.title });
    },
    onError: onNotificationErrorHandler(),
  });

  const canSubmit = form.title.trim().length > 0;

  const [createOrUpdateKPIGroup, { loading: loadingSave, error: errorSave }] =
    useMutation(createOrUpdateKpiGroupMutation);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const variables = {
      ...form,
      organizationId: currentOrganization?.id,
      kpiGroupId,
    };
    createOrUpdateKPIGroup({
      variables,
      onCompleted: () => {
        link.redirect(`/kpis`);
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeTitle = (e) => {
    setForm({ ...form, title: e.target.value });
  };

  // RENDER
  return (
    <div className="bg-white flex rounded-lg shadow overflow-hidden p-6">
      <form
        className="w-full flex flex-col gap-6"
        aria-label="KPI Group form"
        onSubmit={handleSubmitForm}
      >
        <div className="text-xl font-medium">
          {kpiGroupId ? "Edit" : "Create"} KPI Group
        </div>
        {loading ? (
          <Loading>Loading KPI group</Loading>
        ) : (
          <div className="w-full flex flex-col gap-6 max-w-144">
            <div className="w-full">
              <Input
                label="Title"
                aria-label="KPI group title input"
                className="w-full"
                value={form.title}
                placeholder="KPI group title..."
                onChange={handleChangeTitle}
              />
            </div>

            <GraphqlError
              error={errorSave || error}
              whatDidNotWork="The KPI Group could not be saved."
            />
            <div className="flex gap-4 items-center">
              <Button
                text="Save KPI Group"
                type="submit"
                theme={buttonTheme.primary}
                disabled={loadingSave || !canSubmit}
              />
              <Button type="button" to="/kpis" text="Cancel" />
              {loadingSave && <Loading mini size="4" />}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default KPIGroupForm;
