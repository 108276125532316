import { useMutation } from "@apollo/client";
import { CheckIcon, LockClosedIcon } from "@heroicons/react/outline";
import {
  AddTopicToMeetingMutationMutation,
  AddTopicToMeetingMutationMutationVariables,
  MeetingViewMeetingNodeFragmentFragment,
  TopicNode,
} from "types/graphql-schema";

import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, parseStringToJSON } from "@helpers/helpers";

import addTopicToMeetingMutation from "../graphql/add-topic-to-meeting-mutation";

const SuggestedTopic = ({
  meeting,
  topic,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
  topic: Pick<TopicNode, "id" | "title" | "discussionNotes" | "isMandatory">;
}) => {
  const meetingTopicTitles = assertEdgesNonNull(meeting?.topics).map(
    (node) => node.title
  );

  const [createTopic, { loading }] = useMutation<
    AddTopicToMeetingMutationMutation,
    AddTopicToMeetingMutationMutationVariables
  >(addTopicToMeetingMutation);
  const handleAddMeetingTopic = () => {
    createTopic({
      variables: {
        topicId: null,
        meetingId: meeting.id,
        includesIndividualNotes: false,
        title: topic.title,
        discussionNotes: parseStringToJSON(topic.discussionNotes),
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div
      className="flex-1 flex text-left justify-between items-center py-1.5 px-3 text-sm text-gray-700 hover:bg-gray-50"
      key={topic.id}
    >
      <div className="flex-1 flex gap-2">
        {topic.title}
        {topic.isMandatory && (
          <Tooltip text="This is a mandatory topic">
            <LockClosedIcon className="mt-0.5 h-4 w-4 text-gray-500" />
          </Tooltip>
        )}
      </div>
      {meetingTopicTitles.includes(topic.title) ? (
        <div className="p-1">
          <CheckIcon className="w-5 h-5 text-emerald-600" />
        </div>
      ) : loading ? (
        <div className="p-1">
          <Loading mini size="5" />
        </div>
      ) : (
        <Tooltip text="Add topic to agenda">
          <button
            className="hover:underline text-xs text-blue-link flex items-center gap-1"
            onClick={handleAddMeetingTopic}
          >
            Add topic
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default SuggestedTopic;
