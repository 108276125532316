import { gql } from "@apollo/client";

import MyWorkHistoryItemFragment from "./my-work-history-item-fragment";

export default gql`
  ${MyWorkHistoryItemFragment}
  query getMyWorkHistory($userId: Int!) {
    workHistory(userId: $userId) {
      edges {
        node {
          ...MyWorkHistoryItemFragment
        }
      }
    }
  }
`;
