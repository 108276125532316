import { useMutation } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { GoalVisibility } from "types/graphql-schema";

import getArtifactSidebarQuery from "@apps/artifact-sidebar/graphql/get-artifact-sidebar-query";
import useLabel from "@apps/use-label/use-label";
import GoalVisibilityIcon from "@components/goal-visibility-icon/goal-visibility-icon";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames, listBoxButtonClassName } from "@helpers/css";

import updateGoalVisibilityMutation from "../graphql/update-goal-visibility-mutation";

type Props = {
  className?: string;
  iconSize?: string;
};

export const GoalVisibilityDropdownInput = ({
  onChangeVisibility,
  artifact,
  className,
  iconSize = "4",
}: Props & {
  artifact: {
    canUpdate: {
      permission: boolean;
    };
    goalVisibility: GoalVisibility;
  };
  onChangeVisibility: (option: SelectOption<GoalVisibility>) => void;
}) => {
  const label = useLabel();
  return (
    <Select<GoalVisibility>
      disabled={!artifact.canUpdate.permission}
      options={[
        {
          value: GoalVisibility.Public,
          label: "Public",
          description: `Visible to all ${label(
            "organization"
          )} members and the ${label("goal")} participants`,
        },
        {
          value: GoalVisibility.Private,
          label: `Private`,
          description: `Visible only to ${label("goal")} participants`,
        },
      ]}
      value={artifact.goalVisibility}
      onChange={onChangeVisibility}
    >
      {({ selected, setReferenceElement, disabled }) => (
        <Listbox.Button
          className={classNames(
            listBoxButtonClassName({ disabled }),
            className
          )}
          aria-label="Goal visibility dropdown button"
          ref={setReferenceElement}
        >
          <GoalVisibilityIcon
            goalVisibility={artifact.goalVisibility}
            size={iconSize}
          />
          <span className="block truncate">{selected?.label}</span>
          {!disabled && (
            <SelectorIcon
              className={`h-${iconSize} w-${iconSize} text-gray-400 pointer-events-none`}
              aria-hidden="true"
            />
          )}
        </Listbox.Button>
      )}
    </Select>
  );
};

const GoalVisibilityDropdown = ({
  artifact,
  className,
  iconSize = "4",
}: Props & {
  artifact: {
    id: number;
    canUpdate: {
      permission: boolean;
    };
    goalVisibility: GoalVisibility;
  };
}) => {
  const [updateArtifact] = useMutation(updateGoalVisibilityMutation);

  const handleChangeVisibility = ({ value: newVisibility }: SelectOption) => {
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { goalVisibility: newVisibility },
      },
      refetchQueries: [getArtifactSidebarQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <GoalVisibilityDropdownInput
      artifact={artifact}
      onChangeVisibility={handleChangeVisibility}
      iconSize={iconSize}
      className={className}
    />
  );
};

export default GoalVisibilityDropdown;
