import { gql } from "@apollo/client";

import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

export default gql`
  ${CommonRecognitionArtifactFragment}
  query getMeetingRecognitionsQuery(
    $recognitionRecipients: [Int]
    $createdByIds: [Int]
    $meetingGroupId: Int
    $after: String
    $first: Int!
  ) {
    artifacts(
      artifactType: recognition
      first: $first
      orderBy: "-created"
      meetingGroupId: $meetingGroupId
      recognitionRecipients: $recognitionRecipients
      createdByIds: $createdByIds
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          canDelete {
            permission
          }
          canUpdate {
            permission
          }
          created
          ...CommonRecognitionArtifactFragment
        }
      }
    }
  }
`;
