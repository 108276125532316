import Onboarding from "@components/onboarding/onboarding";
import ProviderImage from "@components/provider-image/provider-image";

const CalendarSelected = ({ provider, email }) => {
  return (
    <div
      className="w-full items-center justify-between mt-4 border border-gray-300 rounded-md p-4 flex text-lg font-medium text-gray-700"
      aria-label="Calendar connection container"
    >
      <div className="flex items-center gap-4">
        <ProviderImage provider={provider} size="6" />
        {email}
      </div>
      <Onboarding.CompletedStep>Calendar selected</Onboarding.CompletedStep>
    </div>
  );
};

export default CalendarSelected;
