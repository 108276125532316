import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import {
  GetMeetingFeedbackNewPageQueryQuery,
  GetMeetingFeedbackNewPageQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import getMeetingFeedbackQuery from "@apps/meeting-new/graphql/get-meeting-feedback-query";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

const MeetingFeedbackCollapsible = ({
  expandedUiPreferenceKey,
  title,
  newSidebarDesign = false,
  variables,
}: {
  expandedUiPreferenceKey: keyof UiPreferenceCache;
  title: string;
  variables: GetMeetingFeedbackNewPageQueryQueryVariables;
  roundedBottom?: boolean;
  newSidebarDesign?: boolean;
}) => {
  const { url } = useRouteMatch();
  const label = useLabel();
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { data, fetchMore, loading } = useQuery<
    GetMeetingFeedbackNewPageQueryQuery,
    GetMeetingFeedbackNewPageQueryQueryVariables
  >(getMeetingFeedbackQuery, {
    notifyOnNetworkStatusChange: true,
    variables,
    skip: !uiPreferenceCache[`${expandedUiPreferenceKey}`],
    onError: onNotificationErrorHandler(),
  });

  const handleClickMore = () => {
    fetchMore({
      variables: {
        after: data?.artifacts?.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  const feedback = data?.artifacts ? assertEdgesNonNull(data.artifacts) : [];

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={expandedUiPreferenceKey}
      title={title}
      loading={!data && loading}
      emptyPlaceholder={
        feedback.length === 0 && `No ${label("feedback", { pluralize: true })}.`
      }
    >
      <Layout.SidebarSubSectionList
        loading={loading}
        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {feedback.map((feedbackItem) =>
          feedbackItem.__typename === "FeedbackArtifactNode" ? (
            <Layout.SidebarSubSectionListItem key={feedbackItem.id}>
              <Artifact artifact={feedbackItem} urlPrefix={url} />
            </Layout.SidebarSubSectionListItem>
          ) : null
        )}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default MeetingFeedbackCollapsible;
