import { gql } from "@apollo/client";

export default gql`
  fragment RecentlyCompletedAssessment on AssessmentNode {
    group {
      title
      id
      assessmentType
    }
    submittedDatetime
    target {
      id
      name
    }
    responder {
      id
      name
    }
    id
    complianceProgram {
      id
      title
    }
  }
  query getMeetingRecentlyCompletedAssessmentsQuery($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      id
      recentlyCompletedAssessments(orderBy: "-submitted_datetime", first: 20) {
        edges {
          node {
            ...RecentlyCompletedAssessment
          }
        }
      }
    }
  }
`;
