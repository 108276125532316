import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { endsWith } from "lodash";
import { Redirect, useParams } from "react-router-dom";

import ErrorComponent from "@components/error/error";
import GraphqlError from "@components/error/graphql-error";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Onboarding from "@components/onboarding/onboarding";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { publicClient } from "@graphql/client";
import { invitationStatus } from "@helpers/constants";

import acceptInvitationMutation from "../graphql/accept-invitation-mutation";
import getInvitationQuery from "../graphql/get-invitation-query";
import CalendarAssignedAutomatically from "./calendar/calendar-assigned-automatically";
import CalendarConnectCTA from "./calendar/calendar-connect-cta";
import CalendarSelected from "./calendar/calendar-selected";
import CalendarUseCTA from "./calendar/calendar-use-cta";
import Organization from "./organization";

const OnboardingOrgInvitation = ({ currentUser }) => {
  const link = useLink();
  const { inviteKey } = useParams();
  const { data, loading, error } = useQuery(getInvitationQuery, {
    variables: { inviteKey },
    // use public graphql url when user is logged out
    client: !currentUser ? publicClient : undefined,
    notifyOnNetworkStatusChange: true,
    onError: onNotificationErrorHandler(),
  });

  const invitationNotForCurrentUser =
    data?.invitation &&
    currentUser &&
    !currentUser.emails.includes(data.invitation.inviteeEmail);

  const invitation = data?.invitation;
  const hasAcceptedInvitation =
    invitation?.status === invitationStatus.accepted;

  const calendarsMatchingOrganization =
    currentUser?.syncCredentials.edges.filter(
      ({ node }) =>
        !node.organization ||
        node.organization?.id === invitation?.organizationId
    ) || [];

  const isMatchingDomainOfOrganizationNode = (node) =>
    node.organization &&
    node.organization.id === invitation.organizationId &&
    !node.organization.personal &&
    node.organization.domains.find((domain) =>
      endsWith(node.credentialsUid, domain)
    );

  const isNotAssociatedToAnOrganization = (node) => !node.organization;

  const [acceptInvitation, { loading: acceptLoading }] = useMutation(
    acceptInvitationMutation,
    {}
  );

  const handleClickAcceptInvitation = () => {
    if (!currentUser) {
      return link.redirect(`/register?inviteKey=${inviteKey}`);
    }
    acceptInvitation({
      variables: { invitationId: invitation.id },
      onError: onNotificationErrorHandler(),
    });
  };

  if (data?.invitation && !data.invitation.organizationId) {
    return <Redirect to={`/register?inviteKey=${inviteKey}`} />;
  }
  return loading ? (
    <Loading>Loading invitation...</Loading>
  ) : invitationNotForCurrentUser ? (
    <ErrorComponent title="This invitation is not addressed to you." />
  ) : data?.invitation ? (
    <div>
      <Onboarding.Title>
        {data.invitation.organizationName
          ? `Join ${data.invitation.organizationName} on Topicflow`
          : "Join Topicflow"}
      </Onboarding.Title>
      <div className="mt-8 text-gray-700 text-sm">
        Topicflow boosts your teams' performance in the flow of work. It allows
        you to track goals, KPIs, and action items, while also providing
        continuous feedback and rapid talent assessments—all in one place.
      </div>
      <div className="mt-8 shadow rounded-md p-8 px-8 py-6 bg-white">
        <Organization
          loading={acceptLoading}
          invitation={invitation}
          joined={data.invitation.status === invitationStatus.accepted}
          onClickJoin={handleClickAcceptInvitation}
        />

        {data.invitation.status === invitationStatus.accepted && (
          <div>
            {calendarsMatchingOrganization.map(({ node }) =>
              isMatchingDomainOfOrganizationNode(node) ? (
                <CalendarAssignedAutomatically
                  key={node.id}
                  email={node.credentialsUid}
                  organizationName={data.invitation.organizationName}
                />
              ) : isNotAssociatedToAnOrganization(node) ? (
                <CalendarUseCTA
                  key={node.id}
                  provider={node.provider}
                  syncCredentialId={node.id}
                  email={node.credentialsUid}
                  organizationId={data.invitation.organizationId}
                  organizationName={data.invitation.organizationName}
                />
              ) : (
                <CalendarSelected
                  key={node.id}
                  email={node.credentialsUid}
                  provider={node.provider}
                />
              )
            )}

            <div className="mt-4">
              <CalendarConnectCTA
                organizationId={data.invitation.organizationId}
                redirectUrl={`/onboarding/invitations/${inviteKey}`}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 text-gray-500 text-sm">
        Topicflow is where meeting management and performance management fuse
        together for greater impact on employee engagement and development.
        Leverage our AI Assist to enable a performance operating system that is
        used daily. Get started in less than 10 seconds by syncing your Google
        or Outlook calendar!
      </div>
      {hasAcceptedInvitation && (
        <div className="py-8 text-gray-500 text-sm flex justify-center">
          <a
            className="block py-2 px-4 rounded text-white bg-blue-700 hover:bg-blue-600"
            href="/"
          >
            Finish
          </a>
        </div>
      )}
    </div>
  ) : (
    <GraphqlError
      whatDidNotWork="We could not display this invitation."
      descriptionList={["The invitation might have been accepted already."]}
      error={error || new Error("No invitation")}
    />
  );
};

export default OnboardingOrgInvitation;
