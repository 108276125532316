import { ChevronDownIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { Menu } from "@szhsin/react-menu";
import {
  FinalizePermissions,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import useLabel, { getLabel } from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Error from "@components/error/error";

import FinalizeOneononeToggleSwitch from "./finalize-oneonone-toggle-switch";
import MeetingTypeDropdownOptions from "./meeting-type-dropdown-options";

export const getFinalizePermissionLabel = (
  finalizePermission: FinalizePermissions
) => {
  const label = getLabel();
  const finalizePermissionLabel =
    finalizePermission === FinalizePermissions.ManagersAndAdmins
      ? "managers and admins"
      : finalizePermission === FinalizePermissions.AdminsOnly
      ? "admins only"
      : finalizePermission === FinalizePermissions.FacilitatorsAndAdmins
      ? "facilitator and admins"
      : finalizePermission === FinalizePermissions.AllParticipants
      ? "all participants"
      : "";
  return `${
    finalizePermissionLabel
      ? `This ${label(
          "oneonone"
        )} can be finalized by ${finalizePermissionLabel}.`
      : ""
  }`;
};

const PrepOneonone = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const label = useLabel();
  const participants =
    meeting?.participants?.edges.map((edge) => edge!.node!.user) || [];
  const facilitator = meeting.meetingGroup?.facilitator || participants[0];
  const subject = participants.find(({ id }) => id !== facilitator?.id);
  const template = meeting.meetingGroup?.topicTemplate;
  const finalizePermission =
    template?.finalizePermissions || FinalizePermissions.FacilitatorsAndAdmins;
  const tooltipFinalizePermission = `Finalize ${label(
    "oneonone"
  )}. ${getFinalizePermissionLabel(finalizePermission)}`;

  return (
    <div className="my-3 py-2 px-5 bg-indigo-50 rounded-lg text-sm @container">
      <div className="flex flex-col @lg:flex-row justify-between gap-3 text-gray-600 items-center">
        <div className="flex-1 flex gap-3 items-center justify-start">
          <Avatar user={subject} size="7" />
          <div>
            <div className="font-medium text-gray-800 text-right">
              {subject?.name || "Unknown"}
            </div>
          </div>
        </div>
        <div className="items-center justify-center gap-3 flex shrink-0">
          <Menu
            portal
            align="start"
            transition={false}
            aria-label="Meeting type dropdown menu list"
            className="text-sm not-prose z-dropdown fs-unmask"
            menuButton={
              <button
                className="flex items-center gap-1 text-lg px-2 py-0 rounded-lg font-medium text-indigo-800 border border-transparent hover:border-indigo-200 hover:bg-white"
                data-testid="menu-type-dropdown-button"
                aria-label="Menu type dropdown menu button"
              >
                {label("oneonone", { pluralize: false, capitalize: true })}
                <ChevronDownIcon className="h-3 w-3" />
              </button>
            }
          >
            <MeetingTypeDropdownOptions
              meetingGroup={meeting.meetingGroup}
              meeting={meeting}
            />
          </Menu>
          <div className="hidden sm:flex items-center justify-center gap-1.5">
            <FinalizeOneononeToggleSwitch
              canFinalize={meeting.canFinalize}
              isFinalized={meeting.isFinalized}
              meetingId={meeting.id}
              topics={meeting.topics}
              tooltip={tooltipFinalizePermission}
              hasIncompleteMandatoryTopics={
                meeting.hasIncompleteMandatoryTopics
              }
            />
            <span className="text-xs tracking-tight">Finalize</span>
          </div>
        </div>

        <div className="flex-1 flex gap-3 items-center flex-row-reverse @lg:flex-row justify-end">
          <div>
            <div className="text-gray-500 text-xs tracking-tight @lg:text-right">
              Facilitator
            </div>
            <div className="font-medium text-gray-800 @lg:text-right">
              {facilitator?.name || "Unknown"}
            </div>
          </div>
          <Avatar user={facilitator} size="7" />
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(PrepOneonone, {
  fallback: <Error description={"This content could not be rendered."} />,
});
