import { gql } from "@apollo/client";

import { DashboardGoalFragment } from "@apps/dashboard/graphql/dashboard-goal-fragment";

export default gql`
  ${DashboardGoalFragment}
  query getMeetingGoalsQuery(
    $owners: [Int]!
    $asUsers: [Int]
    $meetingGroupId: Int
    $goalState: GoalState!
    $goalScopes: [GoalScope]
    $goalsCompletedInTheLastXDays: Int
    $after: String
    $first: Int!
  ) {
    artifacts(
      artifactType: goal
      first: $first
      orderBy: "-updated"
      asUsers: $asUsers
      goalState: $goalState
      goalScopes: $goalScopes
      meetingGroupId: $meetingGroupId
      goalOwners: $owners
      goalsCompletedInTheLastXDays: $goalsCompletedInTheLastXDays
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          activities(first: 1, activityType: goal_checkin) {
            edges {
              node {
                created
              }
            }
          }
          ...DashboardGoalFragment
        }
      }
    }
  }
`;
