import { ExclamationIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import { ReactElement } from "react";

const ArtifactError = ({
  onDeleteNode,
  children,
}: {
  onDeleteNode: (e: any) => void;
  children: ReactElement | string | string[];
}) => {
  return (
    <div
      className="flex flex-1 items-center justify-between"
      contentEditable={false}
    >
      <div className="flex items-center italic">
        <ExclamationIcon className="opacity-80 hover:opacity-100 w-5 h-5 mr-2" />
        <div>{children}</div>
      </div>
      <button
        className="ml-2 opacity-80 hover:opacity-100"
        onClick={onDeleteNode}
      >
        <TrashIcon className="h-4 w-4" />
      </button>
    </div>
  );
};

export default ArtifactError;
