import { gql } from "@apollo/client";

import TopicNodeFragment from "./topic-node-fragment";

export default gql`
  ${TopicNodeFragment}
  mutation createTopicNewPageMutation(
    $topicId: Int
    $meetingId: Int
    $includesIndividualNotes: Boolean
    $includesSharedNotes: Boolean
    $title: String
    $description: String
    $discussionNotes: String
  ) {
    createOrUpdateTopic(
      topicId: $topicId
      includesIndividualNotes: $includesIndividualNotes
      includesSharedNotes: $includesSharedNotes
      meetingId: $meetingId
      title: $title
      description: $description
      discussionNotes: $discussionNotes
    ) {
      topic {
        ...TopicNodeNewPageFragment
      }
    }
  }
`;
