import { withErrorBoundary } from "@sentry/react";
import moment from "moment";
import {
  ArtifactType,
  GetMeetingTranscriptQueryQuery,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import TranscriptionParticipation from "@apps/meeting-transcription-dialog/participation";
import TranscriptionText from "@apps/meeting-transcription-dialog/transcription-text";
import NoTranscriptCta from "@apps/meeting/components/no-transcript-cta";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  MeetingSummaryLengthButtonToggle,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import {
  ToggleButtonGroup,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  getUrl,
} from "@helpers/helpers";

import PrepGroup from "./prep-group";
import MeetingSummaryGroupTranscriptionSuggestedArtifact from "./summary-group-transcription-suggested-artifact";

const MeetingSummaryGroupTranscription = ({
  meeting,
  meetingGroupId,
}: {
  meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
  meetingGroupId: number;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const label = useLabel();
  const summary = meeting.transcript?.summary || meeting.notesSummary;
  const orderedTranscriptData = meeting.transcript?.orderedTranscriptData;
  const highlightList = meeting.transcript?.highlightList
    ? assertEdgesNonNullWithStringId(meeting.transcript.highlightList)
    : [];

  const suggestedArtifacts = meeting.transcript?.suggestedArtifacts
    ? assertEdgesNonNull(meeting.transcript.suggestedArtifacts)
    : [];
  const suggestedActionItems = suggestedArtifacts
    .filter((node) => node.artifactType === ArtifactType.ActionItem)
    .filter(({ hidden }: { hidden: boolean }) => !hidden);
  const suggestedRecognitions = suggestedArtifacts
    .filter((node) => node.artifactType === ArtifactType.Recognition)
    .filter(({ hidden }: { hidden: boolean }) => !hidden);
  const suggestedFeedback = suggestedArtifacts
    .filter((node) => node.artifactType === ArtifactType.Feedback)
    .filter(({ hidden }: { hidden: boolean }) => !hidden);
  const createdArtifacts = assertEdgesNonNull(meeting.artifacts).filter(
    (node) => node.id
  );

  const handleChangeSummaryLength = (
    option: ToggleButtonGroupType<MeetingSummaryLengthButtonToggle>
  ) => {
    saveUiPreference({ meetingSummaryLength: option.value });
  };

  if (!meeting.organization)
    return (
      <PrepGroup title="Summary" description="">
        <div className="mt-1 fs-mask text-gray-500">
          The meeting is not associated to an existing organization. The notes
          and/or transcript cannot be summarized.
        </div>
      </PrepGroup>
    );

  return (
    <>
      {summary ? ( // has summary
        <PrepGroup
          title={
            <div className="flex items-center gap-2 mr-2">
              Summary
              {(meeting.transcript?.summary ||
                meeting.transcript?.mediumSummary ||
                meeting.transcript?.longSummary) && (
                <ToggleButtonGroup<MeetingSummaryLengthButtonToggle>
                  buttons={[
                    {
                      active:
                        uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.short,
                      title: MeetingSummaryLengthButtonToggle.short,
                      value: MeetingSummaryLengthButtonToggle.short,
                    },
                    {
                      active:
                        uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.medium,
                      title: MeetingSummaryLengthButtonToggle.medium,
                      value: MeetingSummaryLengthButtonToggle.medium,
                    },
                    {
                      active:
                        uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.long,
                      title: MeetingSummaryLengthButtonToggle.long,
                      value: MeetingSummaryLengthButtonToggle.long,
                    },
                  ]}
                  onClick={handleChangeSummaryLength}
                />
              )}
            </div>
          }
          description=""
        >
          <div className="mt-2 fs-mask text-gray-500">
            {meeting.transcript?.summary ||
            meeting.transcript?.mediumSummary ||
            meeting.transcript?.longSummary ? (
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{
                  __html:
                    (uiPreferenceCache.meetingSummaryLength ===
                    MeetingSummaryLengthButtonToggle.short
                      ? meeting.transcript?.summary
                      : uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.medium
                      ? meeting.transcript?.mediumSummary
                      : meeting.transcript?.longSummary) || "No summary",
                }}
              />
            ) : (
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            )}
          </div>
        </PrepGroup>
      ) : meeting.organization.effectivePricingTier !== "ultimate" ? ( // not on a plan that enables summarization
        <PrepGroup title="Summary" description="">
          <div className="mt-1 fs-mask text-gray-500">
            <BillingDialogCTA className="text-blue-link underline">
              <span>Upgrade your plan</span>
            </BillingDialogCTA>{" "}
            to have your meetings automatically summarized
          </div>
        </PrepGroup>
      ) : !meeting.organization.enableMeetingSummarization ? ( // does not have summarization enabled
        <PrepGroup title="Summary" description="">
          <div className="mt-1 fs-mask text-gray-500">
            Enable AI Meeting Summarization in{" "}
            <AppLink
              to={`/settings/organization/${meeting.organization.id}/settings`}
              className="text-blue-600"
            >
              Organization Settings
            </AppLink>{" "}
            to have your meetings automatically summarized
          </div>
        </PrepGroup>
      ) : moment().isBefore(meeting.endDatetime) ? ( // is before meeting
        <PrepGroup title="Summary" description="">
          <div className="mt-1 fs-mask text-gray-500">
            The summary will be generated after the meeting has ended.
          </div>
        </PrepGroup>
      ) : null}

      {meeting.hasTranscript &&
      orderedTranscriptData &&
      orderedTranscriptData.length > 0 ? (
        <>
          <>
            <PrepGroup title="Highlights">
              <div className="mt-1 text-gray-500 whitespace-pre-line fs-mask">
                {highlightList.length > 0 ? (
                  <ul className="list-disc ml-4 mt-2">
                    {highlightList.map((node) => (
                      <li key={node.id}>{node.highlight}</li>
                    ))}
                  </ul>
                ) : (
                  <span>No highlights.</span>
                )}
              </div>
            </PrepGroup>

            <PrepGroup title="Created in meeting">
              <div className="mt-2 text-gray-500">
                {createdArtifacts.length > 0 ? (
                  <div className="border rounded divide-y">
                    {createdArtifacts.map((artifact) => (
                      <div className="px-3 py-1.5" key={artifact.id}>
                        <Artifact
                          urlPrefix={getUrl({
                            meetingGroupId: meetingGroupId,
                            meetingId: meeting.id,
                          })}
                          artifact={artifact}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-gray-500">
                    No action items created in meeting.
                  </div>
                )}
              </div>
            </PrepGroup>
            <PrepGroup title="Suggested action items">
              {suggestedActionItems.length > 0 ? (
                <div className="mt-2 text-gray-500 flex flex-col w-full divide-y divide-gray-100">
                  {suggestedActionItems.map((suggestedArtifact) => (
                    <MeetingSummaryGroupTranscriptionSuggestedArtifact
                      key={suggestedArtifact.id}
                      meeting={meeting}
                      suggestedArtifact={suggestedArtifact}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-1 text-gray-500">No suggestions.</div>
              )}
            </PrepGroup>
            <PrepGroup
              title={`Suggested ${label("recognition", { pluralize: true })}`}
            >
              {suggestedRecognitions.length > 0 ? (
                <div className="mt-2 text-gray-500 flex flex-col w-full divide-y divide-gray-100">
                  {suggestedRecognitions.map((suggestedArtifact) => (
                    <MeetingSummaryGroupTranscriptionSuggestedArtifact
                      key={suggestedArtifact.id}
                      meeting={meeting}
                      suggestedArtifact={suggestedArtifact}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-1 text-gray-500">No suggestions.</div>
              )}
            </PrepGroup>
            <PrepGroup
              title={`Suggested ${label("feedback", { pluralize: true })}`}
            >
              {suggestedFeedback.length > 0 ? (
                <div className="mt-2 text-gray-500 flex flex-col w-full divide-y divide-gray-100">
                  {suggestedFeedback.map((suggestedArtifact) => (
                    <MeetingSummaryGroupTranscriptionSuggestedArtifact
                      key={suggestedArtifact.id}
                      meeting={meeting}
                      suggestedArtifact={suggestedArtifact}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-1 text-gray-500">No suggestions.</div>
              )}
            </PrepGroup>
          </>
          <PrepGroup
            title="Transcript"
            collapsible={true}
            isCollapsedByDefault={true}
          >
            <div className="mt-1.5">
              <TranscriptionParticipation meeting={meeting} />
            </div>
            <div className="mt-4 fs-mask">
              <TranscriptionText meeting={meeting} />
            </div>
          </PrepGroup>
        </>
      ) : (
        <PrepGroup title="Transcription" description="">
          <div className="mt-1 fs-mask text-gray-500">
            <NoTranscriptCta
              videoConferenceType={meeting.videoConferenceType}
            />
          </div>
        </PrepGroup>
      )}
    </>
  );
};

export default withErrorBoundary(MeetingSummaryGroupTranscription, {
  fallback: (
    <Error description={"The meeting summary could not be rendered."} />
  ),
});
