import { useMutation } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { PropsWithChildren } from "react";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import { currentOrganizationVar } from "@cache/cache";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames, listBoxButtonClassName } from "@helpers/css";

import { refetchActionItemsInCollapsibleContainers } from "../../action-items-collapsible/helpers";
import updateArtifactMutation from "../graphql/update-artifact-mutation";

export const ActionItemStateDropdownInput = ({
  artifact,
  className = "",
  iconSize = "4",
  onChangeState,
  children,
}: PropsWithChildren<{
  artifact: any;
  className?: string;
  iconSize?: string;
  onChangeState: any;
}>) => {
  const organization = currentOrganizationVar();
  return (
    <Select
      disabled={!artifact.canUpdate.permission}
      options={compact(organization.actionItemStates)}
      value={artifact.actionItemState}
      onChange={onChangeState}
    >
      {({ selected, setReferenceElement, disabled }) =>
        children ? (
          <Listbox.Button ref={setReferenceElement}>{children}</Listbox.Button>
        ) : (
          <Listbox.Button
            className={classNames(
              listBoxButtonClassName({ disabled }),
              className
            )}
            aria-label="Action item state dropdown button"
            ref={setReferenceElement}
          >
            <div className="flex items-center gap-1.5">{selected?.label}</div>
            {!disabled && (
              <SelectorIcon
                className={`h-${iconSize} w-${iconSize} text-gray-400 pointer-events-none`}
                aria-hidden="true"
              />
            )}
          </Listbox.Button>
        )
      }
    </Select>
  );
};

const ActionItemStateDropdown = ({
  artifact,
  className = "",
  iconSize = "4",
  children = null,
}: PropsWithChildren<{
  artifact: any;
  className?: string;
  iconSize?: string;
}>) => {
  const organization = currentOrganizationVar();
  const [updateArtifact] = useMutation(updateArtifactMutation);

  const handleChangeState = ({ value: actionItemState }: { value: any }) => {
    const matchingActionItemState = organization.actionItemStates.find(
      (state) => state?.value === actionItemState
    );
    const variables = {
      artifactId: artifact.id,
      additionalFields: { actionItemState },
    };
    updateArtifact({
      variables,
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            actionItemState,
            isComplete: matchingActionItemState?.isComplete,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      update: (cache, { data }) => {
        refetchActionItemsInCollapsibleContainers(
          data.createOrUpdateArtifact.artifact
        );
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <ActionItemStateDropdownInput
      artifact={artifact}
      className={className}
      iconSize={iconSize}
      onChangeState={handleChangeState}
      children={children}
    />
  );
};

export default ActionItemStateDropdown;
