import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { TbSend } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import deleteDraftMeetingMutation from "@apps/meeting-new/graphql/delete-draft-meeting-mutation";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Modal from "@components/modal/modal";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { providerUrl } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, getUrl } from "@helpers/helpers";

import getMeetingQuery from "../graphql/get-meeting-query";

const MeetingDraftPopoverPanel = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const currentUser = currentUserVar();
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    useState(false);

  const calendars = assertEdgesNonNull(currentUser.syncCredentials);
  const validCalendars = calendars.filter(
    (node) => node.hasValidCalendarCredentials
  );
  const validCalendarsWithWriteAccess = validCalendars.filter(
    (node) => node.hasCalendarWriteCredentials
  );
  const validCalendarsWithoutWriteAccess = validCalendars.filter(
    (node) => !node.hasCalendarWriteCredentials
  );

  const noValidCalendars = validCalendars.length === 0;
  const noCalendarsWithWriteAccess =
    validCalendars.length > 0 && validCalendarsWithWriteAccess.length === 0;
  const disabledPublishMeetingButton =
    noValidCalendars || noCalendarsWithWriteAccess;
  const disabledPublishMeetingTooltip =
    currentUser.status === "demo"
      ? "Demo accounts cannot publish meetings"
      : noValidCalendars
      ? "Please connect your calendar."
      : noCalendarsWithWriteAccess
      ? "Please allow write access to your calendar"
      : null;

  const link = useLink();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openEditModal = queryParams.get("openEditModal") === "1";
  const [showCreateMeetingModal, setShowCreateMeetingModal] =
    useState(openEditModal);
  const [deleteDraftMeeting, { loading: deleteDraftLoading }] = useMutation(
    deleteDraftMeetingMutation
  );

  const handleClickDelete = () => {
    // eslint-disable-next-line
    if (confirm("Are you sure you want to delete this draft meeting?")) {
      deleteDraftMeeting({
        variables: { meetingId: meeting.id },
        refetchQueries: [getSidebarDataQuery],
        onCompleted: () => {
          link.redirect("/calendar");
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };
  const handleClickPublish = () => {
    if (!disabledPublishMeetingTooltip) {
      setShowCreateMeetingModal(true);
    }
  };

  const handleHideMeetingDialog = () => {
    setShowCreateMeetingModal(false);
  };

  const handleClickConnectCalendar = () => {
    setShowConnectCalendarModal(true);
  };

  return (
    <>
      {showCreateMeetingModal && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleHideMeetingDialog}
          defaultAction={meetingDialogAction.publish}
          onSaved={handleHideMeetingDialog}
          refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
        />
      )}
      <Modal open={showConnectCalendarModal}>
        <div
          className="p-6 flex justify-between items-start gap-8"
          aria-label="Connect calendar modal"
        >
          <div className="flex-1">
            <CalendarConnectNew
              next={getUrl({
                meetingGroupId: meeting.meetingGroup?.id,
                meetingId: meeting.id,
              })}
            />
          </div>
          <button
            onClick={() => setShowConnectCalendarModal(false)}
            className="p-1 rounded hover:bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <XIcon className="w-7 h-7" />
          </button>
        </div>
      </Modal>
      <Popover.Panel
        aria-label="Meeting draft popover panel"
        className={classNames(
          "right-0 top-5 text-sm",
          "absolute mt-1 z-dropdown bg-white shadow-lg rounded-md w-96 py-4 px-6 flex flex-col gap-4 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        )}
      >
        <div className="flex flex-col gap-4 text-sm">
          <div className="gap-0.5 flex flex-col text-gray-800">
            <div className="font-semibold">This is a draft meeting.</div>
            <div className="text-gray-500">
              Invites have not been sent to guests yet.
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Button
              onClick={handleClickDelete}
              theme={buttonTheme.default}
              disabled={deleteDraftLoading}
              aria-label="Delete draft meeting button"
              className="gap-3"
              small
            >
              Discard
            </Button>
            {!disabledPublishMeetingButton ? (
              <Button
                theme={buttonTheme.primary}
                onClick={handleClickPublish}
                disabled={disabledPublishMeetingButton}
                tooltip={disabledPublishMeetingTooltip}
                className="gap-2 items-center truncate overflow-hidden"
                small
              >
                <span>
                  Publish{" "}
                  <span className="hidden sm:inline md:hidden xl:inline">
                    meeting
                  </span>
                </span>
                <TbSend className="h-5 w-5 text-white" />
              </Button>
            ) : validCalendars.length === 0 && currentUser.status !== "demo" ? (
              <div className="text-gray-600 text-sm w-40">
                <button
                  className="text-blue-700 font-medium hover:underline"
                  onClick={handleClickConnectCalendar}
                >
                  Connect your calendar
                </button>{" "}
                to publish this meeting.
              </div>
            ) : (
              validCalendars.length > 0 &&
              validCalendarsWithWriteAccess.length === 0 &&
              validCalendarsWithoutWriteAccess[0]?.provider && (
                <div className="text-gray-600 text-sm w-36 sm:w-48">
                  <a
                    className="text-blue-700 font-medium hover:underline"
                    href={`/sso/${
                      providerUrl[validCalendarsWithoutWriteAccess[0].provider]
                    }?request_calendar_scope=true&request_write_scope=true&next=${getUrl(
                      {
                        meetingGroupId: meeting.meetingGroup?.id,
                        meetingId: meeting.id,
                      }
                    )}`}
                  >
                    Allow calendar write access
                  </a>{" "}
                  to publish this meeting.
                </div>
              )
            )}
          </div>
        </div>
      </Popover.Panel>
    </>
  );
};

export default MeetingDraftPopoverPanel;
