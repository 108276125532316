import { useReactiveVar } from "@apollo/client";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent, EditorOptions } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { compact } from "lodash";
import { ReactElementLike } from "prop-types";
import { useEffect, useState } from "react";

import { currentTiptapJWTVar } from "@cache/cache";
import useEditor from "@components/wysiwyg/use-editor";
import { classNames, inputBorderClassName } from "@helpers/css";
import { parseStringToJSON } from "@helpers/helpers";

import CustomLink from "./extensions/link";
import CustomListItem from "./extensions/list-item";
import { getAiExtension } from "./helpers";

const defaultFn = () => {};

const TextareaWysiwyg = ({
  deps = [],
  value,
  editable = false,
  autofocus = false,
  placeholder = "",
  className = "",
  renderAi,
  onChangeValue,
  onFocus = defaultFn,
  onBlur = defaultFn,
}: {
  deps?: any[];
  value?: JSON | string | null;
  autofocus?: boolean;
  editable?: boolean;
  placeholder?: string;
  className?: string;
  tiptapAiJwt?: string;
  renderAi?:
    | (({
        editor,
        aiLoading,
      }: {
        editor: Editor;
        aiLoading: boolean;
      }) => ReactElementLike)
    | null;
  onChangeValue?: (value: string, editor: Editor) => void;
  onFocus?: EditorOptions["onFocus"];
  onBlur?: EditorOptions["onBlur"];
}) => {
  const [aiLoading, setAiLoading] = useState(false);
  const enableAi = !!renderAi;
  const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);

  const jsonValue = parseStringToJSON(value);

  const content = !value // if falsy, return null
    ? null
    : typeof value !== "string" // if not a string, then it's JSON so we return the json
    ? value
    : jsonValue // if value can be parsed to JSON then return the parsed JSON value
    ? jsonValue
    : value; // or we just return the string value.

  const editor = useEditor(
    {
      editable,
      autofocus,
      extensions: compact([
        CustomLink.configure({
          openOnClick: true,
          autolink: true,
        }),
        StarterKit.configure({
          codeBlock: false,
          bulletList: false,
          orderedList: false,
          listItem: false,
        }),
        Underline,
        CustomListItem,
        BulletList.configure({
          itemTypeName: "listItem",
        }),
        OrderedList.configure({
          itemTypeName: "listItem",
        }),
        Placeholder.configure({
          placeholder,
        }),
        Typography,
        enableAi &&
          tiptapAiJwt &&
          getAiExtension(tiptapAiJwt, {
            onError: () => setAiLoading(false),
            onSuccess: () => setAiLoading(false),
            onLoading: () => setAiLoading(true),
          }),
      ]),
      editorProps: {
        attributes: {
          class: editable
            ? classNames(
                "prose max-w-full px-4 py-2 text-sm",
                inputBorderClassName,
                className
              )
            : classNames("prose text-sm", className),
        },
      },
      content,
      onFocus,
      onBlur,
    },
    [...deps, enableAi ? tiptapAiJwt : null]
  );

  useEffect(() => {
    const handleUpdateValue = ({ editor }: any) => {
      if (onChangeValue)
        onChangeValue(JSON.stringify(editor.getJSON()), editor);
    };
    editor?.on("update", handleUpdateValue);
    return function cleanup() {
      editor?.off("update", handleUpdateValue);
    };
  }, [editor, onChangeValue]);

  return (
    <>
      <EditorContent editor={editor} className="flex-1 min-w-0" />
      {renderAi && editor ? renderAi({ editor, aiLoading }) : null}
    </>
  );
};

export default TextareaWysiwyg;
