import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/outline";
import { EditorContent } from "@tiptap/react";
import { uniqBy, uniqueId } from "lodash";
import moment from "moment";
import { FormEvent, KeyboardEvent, useState } from "react";

import { currentUserVar, errorNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error.js";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import { isCommandEnterEvent } from "@helpers/helpers";

import createNoteCommentMutation from "../../graphql/create-note-comment-mutation.js";
import getInlineCommentsQuery from "../../graphql/get-inline-comments-query";
import useEditor from "../../use-editor.jsx";
import { CommentExtensions } from "./comment-form-tiptap.jsx";

export default function CommentForm({
  uuid,
  atMentionSuggestions = null,
  uploadVariable = null,
  canResolve,
  topicId,
  artifactId,
  isLoading,
  onCancel,
  onResolveComment,
}: {
  uuid: string;
  atMentionSuggestions?: any;
  uploadVariable?: any;
  canResolve: boolean;
  topicId?: number;
  artifactId?: number;
  isLoading: boolean;
  onCancel: () => void;
  onResolveComment: () => void;
}) {
  const currentUser = currentUserVar();
  const [commentTextByUuid, setCommentTextByUuid] = useState(
    {} as { [e: string]: any }
  );

  const [createNoteComment, { loading: loadingCreate }] = useMutation(
    createNoteCommentMutation,
    {
      onError: () => {
        errorNotificationVar({
          description: `Your comment could not be saved.`,
        });
      },
      update: (cache, response) => {
        const newEdge = {
          node: response.data.createOrUpdateComment.comment,
          __typename: "CommentNodeEdge",
        };
        cache.updateQuery(
          {
            query: getInlineCommentsQuery,
            variables: { uuid },
          },
          (data) => {
            if (!data) {
              return {
                me: currentUser,
                comments: {
                  totalCount: 1,
                  edges: [newEdge],
                },
                __typename: "CommentNodeConnection",
              };
            }
            return {
              ...data,
              comments: {
                ...data.comments,
                totalCount: data.comments.totalCount + 1,
                edges: uniqBy(
                  [...data.comments.edges, newEdge],
                  ({ node }) => node.id
                ),
              },
            };
          }
        );
      },
    }
  );

  const handleSubmitComment = (e?: FormEvent) => {
    e?.preventDefault();
    if (!commentTextByUuid[uuid]) return;
    const comment = JSON.stringify(commentTextByUuid[uuid]);
    createNoteComment({
      variables: {
        uuid,
        comment,
        topicId,
        artifactId,
      },
      optimisticResponse: {
        createOrUpdateComment: {
          comment: {
            id: uniqueId("temp-comment"),
            creator: currentUser,
            created: moment().toISOString(),
            comment,
            __typename: "CommentNode",
          },
          __typename: "CreateOrUpdateCommentMutation",
        },
      },
      onCompleted: () => {
        setCommentTextByUuid({
          ...commentTextByUuid,
          [`${uuid}`]: "",
        });
        commentEditor?.chain().setContent("").run();
      },
      onError: onNotificationErrorHandler(),
    });
    commentEditor?.chain().setContent("").run();
  };

  const handleTitleKeyDown = (e: KeyboardEvent) => {
    if (isCommandEnterEvent(e)) {
      handleSubmitComment();
    }
  };

  const commentEditor = useEditor(
    {
      editable: true,
      autofocus: true,
      extensions: CommentExtensions(atMentionSuggestions, uploadVariable),
      editorProps: {
        attributes: {
          class:
            "prose js-comment-input text-sm focus:ring-0 focus:outline-none pt-2 pb-4 pl-3 pr-1 bg-transparent max-h-36 overflow-auto text-gray-800",
          "aria-label": "Comment input",
        },
      },
      content: commentTextByUuid[uuid],
      onUpdate: ({ editor }) => {
        setCommentTextByUuid({
          ...commentTextByUuid,
          [`${uuid}`]: editor.getJSON(),
        });
      },
    },
    [uuid]
  );

  const isEmpty = isEmptyValue(commentTextByUuid[uuid]);

  const handleCancel = () => {
    setCommentTextByUuid({
      ...commentTextByUuid,
      [`${uuid}`]: "",
    });
    commentEditor?.chain().setContent("").run();
    onCancel();
  };

  return (
    <form
      className="relative"
      onSubmit={handleSubmitComment}
      aria-label="Comment form"
    >
      <EditorContent editor={commentEditor} onKeyDown={handleTitleKeyDown} />
      <div className="pb-2 px-3 gap-2 flex justify-between items-center">
        <div className="gap-1.5 flex items-center">
          <Button
            onClick={handleSubmitComment}
            disabled={isEmpty}
            className="px-1.5 py-0.5 text-xs"
            theme="primary"
            text="Comment"
            small
            type="submit"
          />
          <Button
            onClick={handleCancel}
            className="px-1.5 py-0.5 text-xs"
            text={"Cancel"}
            small
          />
          {(isLoading || loadingCreate) && (
            <Loading
              size="5"
              mini
              className="mr-1"
              aria-label="Comment form loading"
            />
          )}
        </div>
        {canResolve && (
          <Button
            onClick={onResolveComment}
            className="px-1.5 py-0.5 text-xs flex items-center gap-1"
            text={"Cancel"}
            small
          >
            <CheckIcon className="h-4 w-4 text-green-600" />
            Resolve
          </Button>
        )}
      </div>
    </form>
  );
}
