import * as Sentry from "@sentry/react";
import {
  ArtifactType,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Error from "@components/error/error";

import MeetingSidebarSection from "./meeting-sidebar-collapsible-container";
import MeetingRecognitionCollapsible from "./recognition-collapsible";
import useMeetingSidebarSection from "./use-meeting-sidebar-collapsible-container";

const Recognitions = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const label = useLabel();
  const {
    isFormalOneonone,
    subject,
    filterAssigneeId,
    participantIds,
    toggledAssignee,
    toggledJustMeeting,
  } = useMeetingSidebarSection({
    meeting,
    collapsibleFilterName: "Recognitions",
  });

  const commonFilters = {
    meetingGroupId: toggledJustMeeting ? meeting.meetingGroup?.id : undefined,
    first: 5,
  };

  return (
    <MeetingSidebarSection
      expandedUiPreferenceKey="meetingSidebarRecognitionsContainerExpanded"
      collapsibleFilterName="Recognitions"
      subject={subject}
      isFormalOneonone={isFormalOneonone}
      toggledAssignee={toggledAssignee}
      toggledJustMeeting={toggledJustMeeting}
      createArtifactFormOptions={{
        artifactType: ArtifactType.Recognition,
        meetingId: meeting.id,
        meetingGroupId: meeting.meetingGroup?.id,
      }}
      titleLabel="recognition"
      newSidebarDesign={waffle.flag_is_active("new-meeting-page")}
    >
      <MeetingRecognitionCollapsible
        expandedUiPreferenceKey="meetingReceivedRecognitionsExpanded"
        meeting={meeting}
        title={`${label("recognition", {
          pluralize: true,
          capitalize: true,
        })} received`}
        variables={{
          ...commonFilters,
          recognitionRecipients: toggledAssignee
            ? [filterAssigneeId]
            : participantIds,
        }}
        newSidebarDesign={waffle.flag_is_active("new-meeting-page")}
      />
      <MeetingRecognitionCollapsible
        expandedUiPreferenceKey="meetingGivenRecognitionsExpanded"
        meeting={meeting}
        title={`${label("recognition", {
          pluralize: true,
          capitalize: true,
        })} given`}
        variables={{
          ...commonFilters,
          createdByIds: toggledAssignee ? [filterAssigneeId] : participantIds,
        }}
        roundedBottom
        newSidebarDesign={waffle.flag_is_active("new-meeting-page")}
      />
    </MeetingSidebarSection>
  );
};

export default Sentry.withErrorBoundary(Recognitions, {
  fallback: <Error description={"The recognitions could not be rendered."} />,
});
