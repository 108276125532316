import { useMutation, useQuery } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useCallback, useState } from "react";
import { MdArchive, MdContentCopy, MdUnarchive } from "react-icons/md";
import {
  GetAssessmentQuestionsQuery,
  GetAssessmentQuestionsQueryVariables,
  RemoveAssessmentQuestionMutation,
  RemoveAssessmentQuestionMutationVariables,
  SaveAssessmentQuestionMutation,
  SaveAssessmentQuestionMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Loading from "@components/loading/loading";
import ManagedTable from "@components/table/managed-table";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";
import { capitalize } from "@helpers/string";

import createOrUpdateQuestionMutation from "../graphql/create-or-update-question-mutation";
import deleteQuestionMutation from "../graphql/delete-question-mutation";
import getQuestionsQuery from "../graphql/get-questions-query";
import { bgClassName } from "../helpers";

enum AssessmentQuestionsTab {
  active = "active",
  archived = "archived",
}

const AssessmentQuestions = () => {
  const label = useLabel();
  const organization = currentOrganizationVar();
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this question?"
  );
  const [showArchived, setShowArchived] = useState(false);
  const [questions, setQuestions] = useState<
    NonNullable<
      NonNullable<
        NonNullable<
          GetAssessmentQuestionsQuery["assessmentQuestions"]
        >["edges"][0]
      >["node"]
    >[]
  >([]);
  const { loading, refetch } = useQuery<
    GetAssessmentQuestionsQuery,
    GetAssessmentQuestionsQueryVariables
  >(getQuestionsQuery, {
    variables: {
      organizationId: organization.id,
      archived: false,
    },
    onCompleted: (response) => {
      setQuestions(assertEdgesNonNull(response.assessmentQuestions));
    },
    onError: onNotificationErrorHandler(),
  });

  const [removeAssessmentQuestion, { loading: isDeletingQuestion }] =
    useMutation<
      RemoveAssessmentQuestionMutation,
      RemoveAssessmentQuestionMutationVariables
    >(deleteQuestionMutation);

  const [createOrUpdateQuestion, { loading: isArchivingQuestion }] =
    useMutation<
      SaveAssessmentQuestionMutation,
      SaveAssessmentQuestionMutationVariables
    >(createOrUpdateQuestionMutation);

  const handleDeleteQuestion = useCallback(
    async (assessmentQuestionId: number) => {
      const confirmation = await confirm();
      if (confirmation) {
        removeAssessmentQuestion({
          variables: { assessmentQuestionId },
          onCompleted: () => {
            setQuestions(
              questions.filter((q) => q.id !== assessmentQuestionId)
            );
            successNotificationVar({ title: "Question deleted" });
          },
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [confirm, removeAssessmentQuestion, questions]
  );

  const handleChangeArchiveStatus = useCallback(
    (questionId: number, archived: boolean) => {
      createOrUpdateQuestion({
        variables: {
          questionId,
          archived,
        },
        onCompleted: () => {
          setQuestions(questions.filter((q) => q.id !== questionId));
          successNotificationVar({
            title: archived ? "Question archived" : "Question unarchived",
          });
        },
        onError: onNotificationErrorHandler(),
      });
    },
    [createOrUpdateQuestion, questions]
  );

  return (
    <div
      className={classNames(bgClassName, "p-6 w-full")}
      aria-label="Assessments > questions"
    >
      <ConfirmationDialog />
      <div className="flex w-full justify-between items-center">
        <Heading small title="Questions" />

        <div className="flex items-center gap-2">
          {(isDeletingQuestion || isArchivingQuestion) && (
            <Loading mini size="5" />
          )}
          <Button
            disabled={isDeletingQuestion || isArchivingQuestion}
            theme={buttonTheme.default}
            to="/assessments/assessment-questions/new"
            text="New question"
          />
        </div>
      </div>

      <div className="mt-4">
        <ManagedTable
          isLoading={loading}
          aboveTable={
            <div className="flex items-center">
              <ToggleButtonGroup<AssessmentQuestionsTab>
                theme={ToggleButtonGroupTheme.buttons}
                buttons={[
                  {
                    active: !showArchived,
                    title: "Active",
                    value: AssessmentQuestionsTab.active,
                  },
                  {
                    active: showArchived,
                    title: "Archived",
                    value: AssessmentQuestionsTab.archived,
                  },
                ]}
                onClick={(
                  option: ToggleButtonGroupType<AssessmentQuestionsTab>
                ) => {
                  const newShowArchived =
                    option.value === AssessmentQuestionsTab.archived;
                  setShowArchived(newShowArchived);
                  refetch({ archived: newShowArchived ? true : false });
                }}
              />
            </div>
          }
          sortingEnabled
          searchEnabled
          sortKey="title"
          sortDir="asc"
          data={questions}
          columns={[
            {
              header: "Title",
              field: "title",
              sortKey: "title",
            },
            {
              header: `${label("review", { capitalize: true })} type`,
              render: (row) => assessmentTypeLabels[row.assessmentType],
              sortKey: "type",
              value: (row) => assessmentTypeLabels[row.assessmentType],
            },
            {
              header: "Type",
              render: (row) => capitalize(row.questionType),
              sortKey: "questionType",
            },
            {
              header: "",
              render: (row) => {
                const deleteOrArchiveButton =
                  !row.archived && row.answersExist ? (
                    <Button
                      disabled={isDeletingQuestion || isArchivingQuestion}
                      theme={buttonTheme.redDanger}
                      tooltip="Archive"
                      onClick={() => handleChangeArchiveStatus(row.id, true)}
                    >
                      <MdArchive className="h-4 w-4" />
                    </Button>
                  ) : row.archived ? (
                    <Button
                      disabled={isDeletingQuestion || isArchivingQuestion}
                      theme={buttonTheme.lightBlue}
                      tooltip="Unarchive"
                      onClick={() => handleChangeArchiveStatus(row.id, false)}
                    >
                      <MdUnarchive className="h-4 w-4" />
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        !row.canDelete?.permission ||
                        isDeletingQuestion ||
                        isArchivingQuestion
                      }
                      tooltip={
                        !row.canDelete?.permission
                          ? row.canDelete?.reason ?? undefined
                          : "Delete"
                      }
                      theme={buttonTheme.redDanger}
                      onClick={() => handleDeleteQuestion(row.id)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  );
                return (
                  <div className="flex items-center gap-2 justify-end">
                    <Button
                      theme={buttonTheme.lightBlue}
                      to={`/assessments/assessment-questions/${row.id}/edit`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      theme={buttonTheme.lightBlue}
                      to={`/assessments/assessment-questions/${row.id}/duplicate`}
                    >
                      <MdContentCopy className="h-4 w-4" />
                    </Button>
                    {deleteOrArchiveButton}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AssessmentQuestions;
