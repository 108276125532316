import { gql } from "@apollo/client";

import TopicNodeFragment from "@apps/meeting/graphql/topic-node-fragment.ts";

export default gql`
  ${TopicNodeFragment}
  mutation addTopicToMeetingMutation(
    $topicId: Int
    $meetingId: Int
    $includesIndividualNotes: Boolean
    $title: String
    $discussionNotes: String
  ) {
    createOrUpdateTopic(
      topicId: $topicId
      includesIndividualNotes: $includesIndividualNotes
      meetingId: $meetingId
      title: $title
      discussionNotes: $discussionNotes
    ) {
      topic {
        ...TopicNodeFragment
      }
      meeting {
        id
        topics {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
