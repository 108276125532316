import { useHistory } from "react-router-dom";
import { BasicUser } from "types/topicflow";

import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

import useUserComboboxQuery from "../../../components/user-combobox/use-user-combobox-query";
import ActionItems from "../components/action-items";
import DashboardFeedback from "../components/feedbacks";
import Goals from "../components/goals";
import Insights from "../components/insights";
import DashboardUserSelector from "../dashboard-user-selector";
import DashboardPeopleTable from "./people-table";
import Recognitions from "./recognitions";

const DashboardContent = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const currentOrganization = currentOrganizationVar();
  const history = useHistory();
  const currentUser = currentUserVar();
  const { loading, query, setQuery, options } = useUserComboboxQuery({
    selected: { type: UserComboboxOptionType.USER, ...selectedUser },
  });

  useDocumentTitle(selectedUser ? selectedUser.name : "");

  const handleChangeAssignee = (assignee: UserComboboxOption) => {
    history.push(`/dashboard/user/${assignee.id}`);
  };

  const handleClearAssignee = () => {
    history.push(`/dashboard/user/${currentUser.id}`);
  };

  return (
    <div aria-label="Dashboard" className="flex flex-col flex-1">
      <div className="flex flex-col bg-gray-50 sm:flex-row gap-4 sm:gap-0 sm:items-center md:h-14 py-4 md:py-0 px-4 lg:px-6 border-b">
        <div className="flex items-center gap-8">
          <div className="flex-1 w-64">
            <UserCombobox
              disabled={loading}
              aria-label="Dashboard user dropdown"
              width="full"
              query={query}
              value={
                selectedUser
                  ? { type: UserComboboxOptionType.USER, ...selectedUser }
                  : null
              }
              options={options}
              clearable={selectedUser && currentUser.id !== selectedUser.id}
              onChangeValue={handleChangeAssignee}
              onChangeQuery={setQuery}
              onClearValue={handleClearAssignee}
            >
              {({
                clearable,
                onClearValue,
                value,
                onClickButton,
                setReferenceElement,
              }) =>
                value?.type === UserComboboxOptionType.USER ? (
                  <DashboardUserSelector
                    clearable={clearable}
                    onClearValue={onClearValue}
                    value={value}
                    onClickButton={onClickButton}
                    setReferenceElement={setReferenceElement}
                  />
                ) : null
              }
            </UserCombobox>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-6 flex-1 p-4 lg:p-6 gap-4 lg:gap-6">
        <div className="sm:col-span-3 pb-3 relative flex flex-col gap-6">
          {selectedUser.id === currentUser.id && (
            <Insights selectedUser={selectedUser} />
          )}
          {currentOrganization.featureFlags.homepageMyConnections && (
            <DashboardPeopleTable selectedUser={selectedUser} />
          )}
        </div>
        <div className={classNames("sm:col-span-3 flex flex-col gap-6")}>
          {currentOrganization.featureFlags.actionItems && (
            <ActionItems selectedUser={selectedUser} />
          )}
          {currentOrganization.featureFlags.goals && (
            <Goals selectedUser={selectedUser} />
          )}
          {currentOrganization.featureFlags.recognitions && (
            <Recognitions selectedUser={selectedUser} />
          )}
          {currentOrganization.featureFlags.feedbacks && (
            <DashboardFeedback selectedUser={selectedUser} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
