import { gql } from "@apollo/client";

export default gql`
  mutation deleteMeetingTranscriptsMutation($meetingId: Int!) {
    deleteMeetingTranscripts(meetingId: $meetingId) {
      meeting {
        id
        hasTranscript
        transcript {
          id
        }
      }
    }
  }
`;
