import { useQuery } from "@apollo/client";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ThumbUpIcon,
} from "@heroicons/react/outline";
import { compact, groupBy, orderBy } from "lodash";
import moment from "moment";
import {
  GetMeetingOverviewMeetingsQueryQuery,
  GetMeetingOverviewMeetingsQueryQueryVariables,
  MeetingOverviewMeetingFragmentFragment,
} from "types/graphql-schema";

import {
  explorerMeetingType,
  getExplorerFiltersUrl,
} from "@apps/explorer/helpers";
import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getMeetingOverviewMeetingsQuery from "../graphql/get-meeting-overview-meetings-query";
import OverviewMeeting from "./overview-meeting";
import StatsContainer from "./stats-container";

const getMeetingsTotalTime = (
  meetings: MeetingOverviewMeetingFragmentFragment[]
) => {
  return meetings.reduce((total, meeting) => {
    if ((meeting.participants?.totalCount || 0) < 2) return total;
    return (
      total + moment(meeting.endDatetime).diff(meeting.startDatetime, "minute")
    );
  }, 0);
};

const MeetingsOverviewGroupMeetings = ({
  selectedUser,
}: {
  selectedUser: UserComboboxOption;
}) => {
  const currentUser = currentUserVar();
  const startOfPreviousWeek = moment()
    .startOf("isoWeek")
    .subtract(1, "week")
    .format();
  const startOfTheWeek = moment().startOf("isoWeek").format();
  const endOfTheWeek = moment().endOf("isoWeek").format();

  const { data, loading } = useQuery<
    GetMeetingOverviewMeetingsQueryQuery,
    GetMeetingOverviewMeetingsQueryQueryVariables
  >(getMeetingOverviewMeetingsQuery, {
    variables: {
      forUserId: selectedUser.id,
      startOfPreviousWeek,
      endOfTheWeek,
    },
    onError: onNotificationErrorHandler(),
  });
  const meetings = compact(
    (data?.calendar ? assertEdgesNonNull(data.calendar) : []).map(
      (calendarItem) => calendarItem.meeting
    )
  );
  const meetingsGroupedByStartDate = groupBy(meetings, (meeting) =>
    moment(meeting.startDatetime).format("YYYY-MM-DD")
  );
  const meetingsGroupedByStartDateArray = Object.keys(
    meetingsGroupedByStartDate
  ).map((key) => ({
    date: key,
    meetings: meetingsGroupedByStartDate[key],
    duration: getMeetingsTotalTime(meetingsGroupedByStartDate[key]),
  }));
  const longestDay = orderBy(
    meetingsGroupedByStartDateArray.filter(({ date }) =>
      moment(date).isSameOrAfter(startOfTheWeek, "day")
    ),
    ["duration"],
    ["desc"]
  )?.[0];
  const longestDayDurationMins = longestDay?.duration || 0;
  const longestDayDurationHours = Math.ceil(longestDayDurationMins / 60);

  const timeInMeetingsThisWeek = getMeetingsTotalTime(
    meetings.filter((meeting) =>
      moment(meeting.startDatetime).isSameOrAfter(startOfTheWeek, "seconds")
    )
  );
  const hoursInMeetingsThisWeek = Math.ceil(timeInMeetingsThisWeek / 60);

  const timeInMeetingsLastWeek = getMeetingsTotalTime(
    meetings.filter((meeting) =>
      moment(meeting.startDatetime).isBefore(startOfTheWeek, "seconds")
    )
  );
  const hourDiffPercentage =
    Math.round((timeInMeetingsThisWeek / timeInMeetingsLastWeek) * 100) - 100;

  const draftMeetings = compact(
    (data?.draftMeetings ? assertEdgesNonNull(data.draftMeetings) : []).map(
      ({ meeting }) => meeting
    )
  );
  const scheduledDraftMeetings = draftMeetings.filter(
    (meeting) => meeting.startDatetime
  );
  const unscheduledDraftMeetings = draftMeetings.filter(
    (meeting) => !meeting.startDatetime
  );

  // RENDER
  return !data && loading ? (
    <Loading className="p-6">Loading meetings</Loading>
  ) : (
    <div className="flex flex-col gap-10">
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-6">
        <StatsContainer label="Meeting this week">
          <div className="flex items-end gap-1 justify-between">
            <div className="font-medium text-2xl">
              {hoursInMeetingsThisWeek > 0
                ? `${hoursInMeetingsThisWeek}hr${
                    hoursInMeetingsThisWeek > 1 ? "s" : ""
                  }`
                : ""}{" "}
              {timeInMeetingsThisWeek % 60}min
              {timeInMeetingsThisWeek === 1 ? "" : "s"}
            </div>
            <div
              className={classNames(
                "text-xs flex items-center gap-0.5",
                hourDiffPercentage < 0 ? "text-emerald-600" : "text-red-600"
              )}
            >
              {hourDiffPercentage < 0 ? (
                <ArrowSmDownIcon className="h-4 w-4" />
              ) : (
                <ArrowSmUpIcon className="h-4 w-4" />
              )}
              {Math.abs(hourDiffPercentage)}%
            </div>
          </div>
        </StatsContainer>
        <StatsContainer label="Meetings per day">
          <div className="font-medium text-2xl">
            {meetings.length > 0
              ? Math.round(
                  (meetings.length / meetingsGroupedByStartDateArray.length) *
                    100
                ) / 100
              : 0}
          </div>
        </StatsContainer>

        <StatsContainer label={`Busiest day`}>
          <div className="font-medium text-2xl">
            {longestDayDurationHours > 0
              ? `${longestDayDurationHours}hr${
                  longestDayDurationHours > 1 ? "s" : ""
                }`
              : ""}{" "}
            {longestDayDurationMins % 60}min
            {longestDayDurationMins === 1 ? "" : "s"}
          </div>
        </StatsContainer>
        <StatsContainer
          label={`Draft meetings`}
          url={getExplorerFiltersUrl({
            type: explorerMeetingType,
            meetingIsDraft: true,
            meetingParticipants: [currentUser.id],
          })}
        >
          <div className="font-medium text-2xl">{draftMeetings.length}</div>
        </StatsContainer>
      </div>
      <div className="grid sm:grid-cols-2 gap-12">
        <div>
          <div className="text-lg font-medium mb-3">This week</div>
          <div className="flex flex-col gap-6">
            {meetingsGroupedByStartDateArray.map((groupedMeetings) =>
              moment().isSameOrBefore(groupedMeetings.date, "day") ? (
                <div
                  className="flex flex-col gap-1.5"
                  key={groupedMeetings.date}
                >
                  <div className="font-medium text-xs text-gray-700">
                    {moment(groupedMeetings.date).isSame(moment(), "day")
                      ? "Today"
                      : moment(groupedMeetings.date).format("dddd, LL")}
                  </div>
                  <div className="rounded border divide-y bg-white overflow-hidden">
                    {groupedMeetings.meetings.map((meeting) => (
                      <OverviewMeeting meeting={meeting} key={meeting.id} />
                    ))}
                  </div>
                </div>
              ) : null
            )}
            {meetingsGroupedByStartDateArray.length === 0 && (
              <div className="flex flex-col justify-center items-center gap-2 p-4 rounded border bg-white text-gray-500 text-sm">
                <ThumbUpIcon className="h-5 w-5" />
                No more meetings this week.
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="text-lg font-medium mb-3">Draft Meetings</div>
          <div className="flex flex-col gap-6">
            {unscheduledDraftMeetings.length > 0 && (
              <div className="flex flex-col gap-1.5">
                <div className="font-medium text-xs text-gray-700">
                  Unscheduled
                </div>
                <div className="rounded border divide-y bg-white overflow-hidden">
                  {unscheduledDraftMeetings.map((meeting) => (
                    <OverviewMeeting meeting={meeting} key={meeting.id} />
                  ))}
                </div>
              </div>
            )}

            {scheduledDraftMeetings.length > 0 && (
              <div className="flex flex-col gap-1.5">
                <div className="font-medium text-xs text-gray-700">
                  Scheduled
                </div>
                <div className="rounded border divide-y bg-white overflow-hidden">
                  {scheduledDraftMeetings.map((meeting) => (
                    <OverviewMeeting meeting={meeting} key={meeting.id} />
                  ))}
                </div>
              </div>
            )}
            {draftMeetings.length === 0 && (
              <div className="rounded border divide-y bg-white overflow-hidden">
                <div className="px-3 py-2 text-sm text-gray-500">
                  No draft meetings.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingsOverviewGroupMeetings;
