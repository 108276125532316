import { useMutation, useQuery } from "@apollo/client";
import {
  ExternalLinkIcon,
  LinkIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { compact, uniqBy } from "lodash";
import numeral from "numeral";
import { ChangeEventHandler, useCallback, useState } from "react";
import {
  GetKpisToConnectToKrQueryQuery,
  GetKpisToConnectToKrQueryQueryVariables,
  GoalArtifactNode,
  GoalProgressType,
  KeyResultFragmentFragment,
  PermissionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { KpiPicker } from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import Input from "@components/input/input";
import KeyResultIcon from "@components/key-result-icon/key-result-icon";
import Select from "@components/select/select";
import StartCurrentTargetTooltip from "@components/tooltip/start-current-target-tooltip";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  getKeyResultProgressTypeOptions,
  graphqlNone,
} from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  getProgressFromStartCurrentTargetValues,
  getProgressLabel,
  getStartValueForProgressType,
  getTargetValueForProgressType,
} from "@helpers/helpers";

import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import getKpisToConnectToKrQuery from "../graphql/get-kpis-to-connect-to-kr-query";
import manageGoalKeyResultMutation from "../graphql/manage-goal-key-result-mutation";
import GoalKeyResultAssignee from "./goal-key-result-assignee";

const noKpiOption = { value: graphqlNone, label: "None" };

const GoalKeyResultItem = ({
  keyResult,
  goalArtifact,
  onHideEditing,
  onDelete,
  defaultIsEditing = false,
  isReadOnly = false,
  className = "",
}: {
  keyResult: KeyResultFragmentFragment;
  goalArtifact: Pick<GoalArtifactNode, "__typename" | "id"> & {
    canDelete: Pick<PermissionNode, "permission">;
    canUpdate: Pick<PermissionNode, "permission">;
  };
  onHideEditing?: () => void;
  onDelete?: (keyResult: KeyResultFragmentFragment) => void;
  defaultIsEditing?: boolean;
  isReadOnly?: boolean;
  className?: string;
}) => {
  const label = useLabel();
  const [progressType, setProgressType] = useState(keyResult.progressType);
  const [startValue, setStartValue] = useState(`${keyResult.startValue}`);
  const [targetValue, setTargetValue] = useState(`${keyResult.targetValue}`);
  const [title, setTitle] = useState(keyResult.title);
  const [assignee, setAssignee] = useState<BasicUser | null | undefined>(
    keyResult.assignee
  );
  const [isEditing, setIsEditing] = useState(defaultIsEditing);
  const [manageKeyResult, { loading: loadingMutation }] = useMutation(
    manageGoalKeyResultMutation
  );

  const [kpi, setKpi] = useState<
    (ComboboxGenericOption<number> & typeof keyResult.kpi) | null
  >(
    keyResult.kpi
      ? {
          ...keyResult.kpi,
          label: keyResult.kpi.title,
          value: keyResult.kpi.id,
        }
      : null
  );

  const { data, loading: kpiLoading } = useQuery<
    GetKpisToConnectToKrQueryQuery,
    GetKpisToConnectToKrQueryQueryVariables
  >(getKpisToConnectToKrQuery, {
    onError: onNotificationErrorHandler(),
  });
  const defaultKpiOptions = compact([
    noKpiOption,
    kpi?.value && {
      id: kpi.value,
      value: kpi.value,
      label: kpi.label,
      title: kpi.label,
      currentMeasurement: kpi.currentMeasurement,
    },
  ]);
  const kpis = data?.kpis ? assertEdgesNonNull(data.kpis) : [];
  const kpiOptions = uniqBy(
    defaultKpiOptions.concat(
      kpis.map(({ id, title, currentMeasurement }) => ({
        id,
        value: id,
        label: title,
        title,
        currentMeasurement,
      }))
    ),
    "value"
  );

  const handleSelectKpi = (option: any) => {
    setKpi(option.value === graphqlNone ? null : option);
  };

  const handleSelectProgressType = (
    option: ComboboxGenericOption<GoalProgressType>
  ) => {
    setProgressType(option.value);
  };

  const handleChangeStartValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStartValue(e.target.value);
  };

  const handleChangeTargetValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTargetValue(e.target.value);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleHideEditing = () => {
    setIsEditing(false);
    if (onHideEditing) onHideEditing();
  };

  const handleSaveKR = () => {
    const newStartValue = getStartValueForProgressType({
      progressType,
      startValue: Number(startValue),
    });
    const newTargetValue = getTargetValueForProgressType({
      progressType,
      targetValue: Number(targetValue),
    });
    manageKeyResult({
      variables: {
        title,
        startValue: newStartValue,
        targetValue: newTargetValue,
        kpiId: kpi?.value || graphqlNone,
        keyResultId: keyResult.id,
        goalId: goalArtifact.id,
        assigneeId: assignee?.id,
        progressType,
      },
      optimisticResponse: {
        createOrUpdateKeyResult: {
          keyResult: {
            ...keyResult,
            startValue: newStartValue,
            targetValue: newTargetValue,
            progressType,
            kpi,
            assignee: assignee,
          },
          goal: goalArtifact,
        },
      },
      onCompleted: () => {
        handleHideEditing();
        setStartValue(`${newStartValue}`);
        setTargetValue(`${newTargetValue}`);
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const handleDelete = useCallback(() => {
    if (
      onDelete &&
      window.confirm(
        `Are you sure you want to delete this ${label("key result")}?`
      )
    ) {
      onDelete(keyResult);
    }
  }, [onDelete, label, keyResult]);

  const progress = getProgressFromStartCurrentTargetValues(keyResult);

  return isEditing ? (
    <div className="py-4 flex flex-col gap-4 px-4 bg-gray-50">
      <div className="flex items-center gap-2">
        <div className="font-medium text-sm w-28">Title</div>
        <Input
          className="py-1 px-2 w-full text-sm max-w-96"
          value={title}
          placeholder={`New ${label("key result")}`}
          onChange={handleChangeTitle}
          aria-label="Key result title"
          disabled={!goalArtifact.canUpdate?.permission || isReadOnly}
          autoFocus
        />
        <GoalKeyResultAssignee assignee={assignee} onChange={setAssignee} />
      </div>
      {progressType !== GoalProgressType.Boolean && (
        <div className="flex gap-2 items-center">
          <div className="font-medium text-sm w-28">Start value</div>
          <Input
            type="text"
            className="w-24 text-sm px-2 py-0.5"
            value={startValue}
            onChange={handleChangeStartValue}
            aria-label="Key result start value"
          />
        </div>
      )}
      {progressType !== GoalProgressType.Boolean && (
        <div className="flex gap-2 items-center">
          <div className="font-medium text-sm w-28">Target value</div>
          <Input
            type="text"
            className="w-24 text-sm px-2 py-0.5 rounded border"
            value={targetValue}
            onChange={handleChangeTargetValue}
            aria-label="Key result target value"
          />
        </div>
      )}
      <div className="flex gap-2 items-start">
        <div className="font-medium text-sm w-28 mt-0.5 border border-transparent">
          Type
        </div>
        <div>
          <Select
            className="text-sm pl-2 py-0.5"
            onChange={handleSelectProgressType}
            value={progressType}
            options={getKeyResultProgressTypeOptions()}
          />
        </div>
      </div>
      {kpiOptions.length > 1 && (
        <div className="flex gap-2 items-start">
          <div className="font-medium text-sm w-28 mt-0.5 border border-transparent">
            Linked KPI
          </div>
          <div className="">
            <div className="flex items-center gap-2">
              <KpiPicker
                loading={kpiLoading}
                options={kpiOptions}
                value={kpi || noKpiOption}
                onSelectKpi={handleSelectKpi}
              />
              {kpi && kpi.id !== noKpiOption.value && (
                <a
                  href={`/kpis/${kpi.id}`}
                  rel="noreferrer"
                  target="_blank"
                  className="p-1 hover:bg-gray-100 rounded text-gray-500 hover:text-gray-800"
                >
                  <ExternalLinkIcon className="h-4 w-4" />
                </a>
              )}
            </div>
            {kpi && kpi.id !== noKpiOption.value && kpi.currentMeasurement && (
              <a
                href={`/kpis/${kpi.id}`}
                rel="noreferrer"
                target="_blank"
                className="mt-1 ml-0.5 text-xs tracking-tight text-gray-500 hover:underline"
              >
                Latest value recorded:{" "}
                {numeral(kpi.currentMeasurement.measurement).format()}
              </a>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-between items-center gap-2 ml-30">
        <div className="flex items-center gap-2">
          <Button
            theme={buttonTheme.primary}
            text="Save"
            disabled={loadingMutation || title.trim().length === 0}
            small
            onClick={handleSaveKR}
          />
          <Button text="Cancel" small onClick={handleHideEditing} />
        </div>
        {keyResult.id && goalArtifact.canDelete?.permission && (
          <Button
            text="Delete"
            disabled={loadingMutation}
            theme={buttonTheme.redDanger}
            small
            onClick={handleDelete}
          />
        )}
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        "flex gap-4 items-center w-full py-2 pr-2",
        className
      )}
    >
      <div className="flex-1 text-sm fs-mask flex items-start gap-2">
        <KeyResultIcon className="h-5 w-5 mt-px" />
        {keyResult.title}
      </div>
      {keyResult.kpi?.id && (
        <Tooltip
          text={`<div>Connected to KPI: ${
            keyResult.kpi.title
          }.</div><div>The value of this ${label(
            "key result"
          )} will be updated when the KPI's value is updated.</div>`}
        >
          <a
            className="rounded p-0.5 hover:bg-gray-100"
            aria-label="KR connected to KPI icon"
            href={`/kpis/${keyResult.kpi.id}`}
          >
            <LinkIcon className="h-4 w-4 text-gray-500" />
          </a>
        </Tooltip>
      )}
      <StartCurrentTargetTooltip goalOrKeyresult={keyResult}>
        <div className="text-xs tracking-tight flex rounded-full border px-2 justify-center w-28 relative overflow-hidden">
          <div
            className="bg-sky-100 absolute top-0 left-0 bottom-0 z-0"
            style={{ width: `${Math.min(Math.max(progress, 5), 100)}%` }}
          />
          <span className="z-10">
            {getProgressLabel(keyResult, { shortVersion: true })}
          </span>
        </div>
      </StartCurrentTargetTooltip>

      <Avatar user={keyResult.assignee} tooltipPrefix="Assigned to " size="5" />

      {goalArtifact.canUpdate.permission && !isReadOnly && (
        <Button
          theme={buttonTheme.iconGray}
          icon
          onClick={() => setIsEditing(true)}
          aria-label="Edit KR button"
        >
          <PencilIcon className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default GoalKeyResultItem;
