import { LinkIcon } from "@heroicons/react/outline";
import { MenuItem, MenuItemProps } from "@szhsin/react-menu";
import copy from "copy-to-clipboard";

import { successNotificationVar } from "@cache/cache";

type Props = Omit<MenuItemProps, "className" | "onClick"> & {
  topic: { id: number };
};

const CopyMenuItem = ({ topic, ...props }: Props) => {
  const urlToCopy = `${window.location.origin}/topic/${topic.id}`;

  const handleCopyLink = () => {
    copy(urlToCopy);
    successNotificationVar({
      title: "Topic link copied.",
    });
  };

  const menuItemClassName = "text-sm ";
  return (
    <MenuItem className={menuItemClassName} onClick={handleCopyLink} {...props}>
      <LinkIcon
        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        aria-hidden="true"
      />{" "}
      Copy link
    </MenuItem>
  );
};

export default CopyMenuItem;
