import { useMutation, useQuery } from "@apollo/client";
import { ArrowUpIcon } from "@heroicons/react/outline";
import { compact, debounce, last, uniqBy } from "lodash";
import { FormEvent, useEffect, useRef, useState } from "react";
import {
  ChatbotRoleEnum,
  GetMeetingChatSessionMessagesQueryQuery,
  GetMeetingChatSessionMessagesQueryQueryVariables,
  MeetingAskAiChatMessageFragment,
} from "types/graphql-schema";

import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNullWithStringId } from "@helpers/helpers";

import getChatSessionMessagesQuery from "../../graphql/get-chat-session-messages-query";
import sendMessageToChatbotMutation from "../../graphql/send-message-to-chatbot-mutation";

export const AIAssistAsk = ({
  meetingId,
  chatSessionId,
  onChangeChatSessionId,
}: {
  chatSessionId: number | null;
  onChangeChatSessionId: (chatSessionId: number) => void;
  meetingId: number;
}) => {
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [showAssistantLoading, setShowAssistantLoading] = useState(false);
  const [messages, setMessages] = useState<MeetingAskAiChatMessageFragment[]>(
    []
  );
  const [inputMessage, setInputMessage] = useState("");

  const [sendMessageToChatbot, { loading: loadingSubmit }] = useMutation(
    sendMessageToChatbotMutation
  );
  const { data, loading } = useQuery<
    GetMeetingChatSessionMessagesQueryQuery,
    GetMeetingChatSessionMessagesQueryQueryVariables
  >(getChatSessionMessagesQuery, {
    variables: { chatSessionId: chatSessionId || -1 },
    skip: !chatSessionId,
    onError: onNotificationErrorHandler(),
  });

  const setUniqueMessages = (messages: MeetingAskAiChatMessageFragment[]) => {
    setMessages(uniqBy(messages, "id"));
  };

  const sendMessage = (message: string) => {
    const newMessage: MeetingAskAiChatMessageFragment = {
      __typename: "ChatMessageNode",
      id: `${chatSessionId}-${messages.length + 1}`,
      role: ChatbotRoleEnum.User,
      content: message,
      autogenerated: false,
      actionItems: {
        edges: [],
        __typename: "ActionItemArtifactConnection",
      },
      goals: {
        edges: [],
        __typename: "GoalArtifactConnection",
      },
    };
    setUniqueMessages([...messages, newMessage]);
    setShowAssistantLoading(true);
    sendMessageToChatbot({
      variables: {
        chatSessionId,
        meetingId: meetingId,
        message,
      },
      onCompleted: (response) => {
        if (!chatSessionId) {
          onChangeChatSessionId(response.sendMessageToChatbot.chatSession.id);
        }
      },
      onError: onNotificationErrorHandler(),
    });
  };
  const debouncedSendMessage = debounce(sendMessage, 10);

  const handleSubmitMessage = (e: FormEvent) => {
    e.preventDefault();
    if (inputMessage.trim().length === 0) {
      return;
    }
    debouncedSendMessage(inputMessage);
    setInputMessage("");
  };

  const isLastMessageFromAssistant =
    last(messages)?.role === ChatbotRoleEnum.Assistant;

  useEffect(() => {
    const newMessages = data?.chatSession?.messages
      ? assertEdgesNonNullWithStringId(data.chatSession.messages)
      : [];
    setUniqueMessages(compact(newMessages));
  }, [data]);

  useEffect(() => {
    const scrollToBottomOfMessages = () => {
      if (messages.length > 0 && scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollToBottomOfMessages();
  }, [loading, messages.length, showAssistantLoading]);

  useEffect(() => {
    let st = null;
    if (isLastMessageFromAssistant) {
      setShowAssistantLoading(false);
    } else if (showAssistantLoading) {
      st = setTimeout(() => {
        setShowAssistantLoading(false);
      }, 20 * 1000); // 20s seems long enough?
    }
    return function cleanup() {
      if (st) {
        clearTimeout(st);
      }
    };
  }, [showAssistantLoading, isLastMessageFromAssistant]);

  const filteredMessages =
    messages.filter(
      ({ autogenerated, role }) =>
        !autogenerated && role !== ChatbotRoleEnum.System
    ) || [];

  return (
    <div className="rounded-b-lg divide divide-y divide-gray-200 overflow-hidden">
      <div className=" text-sm bg-white flex flex-col">
        {filteredMessages.length === 0 && loading ? (
          <Loading size="5" className="px-3 py-2 border-b" />
        ) : filteredMessages.length === 0 ? null : (
          <div
            className="p-3 flex flex-col gap-3 max-h-120 overflow-y-scroll border-b bg-gray-50"
            ref={scrollRef}
          >
            {filteredMessages.map((message) => (
              <div
                className={classNames(
                  "flex w-full",
                  message.role === ChatbotRoleEnum.User
                    ? "justify-end pl-12"
                    : "justify-start pr-12"
                )}
                key={message.id}
              >
                <div
                  className={classNames(
                    "border rounded-lg p-1 px-2 whitespace-pre-line text-gray-600 bg-white",
                    message.role === ChatbotRoleEnum.User &&
                      "bg-blue-50 border-blue-200 text-blue-700"
                  )}
                >
                  {message.content}
                  <ul className="list-disc ml-4 mt-2">
                    {message.actionItems?.edges.map((edge) => (
                      <li key={edge?.node?.id}>{edge?.node?.title}</li>
                    ))}
                  </ul>
                  <ul className="list-disc ml-4 mt-2">
                    {message.goals?.edges.map((edge) => (
                      <li key={edge?.node?.id}>{edge?.node?.title}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
            {showAssistantLoading && (
              <div>
                <Loading mini size="5" />
              </div>
            )}
          </div>
        )}
        <form className="relative" onSubmit={handleSubmitMessage}>
          <input
            autoFocus
            type="text"
            className="w-full px-3 pr-12 py-3 focus:ring-0 focus:outline-0 rounded-b-lg"
            placeholder="Ask anything about this meeting..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          <button
            type="submit"
            disabled={inputMessage.trim().length === 0 || loadingSubmit}
            className={classNames(
              "absolute right-2 top-2 z-1",
              "rounded-full p-1.5 bg-gray-100 text-gray-500",
              inputMessage.trim().length > 0 &&
                "bg-blue-link text-white hover:bg-blue-800"
            )}
          >
            <ArrowUpIcon className="h-4 w-4" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default AIAssistAsk;
