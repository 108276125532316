import { DocumentAddIcon } from "@heroicons/react/solid";
import TimeAgo from "react-timeago";

const TopicCreated = ({ topic }: { topic: any }) => (
  <li key={topic.id} data-testid={`comment-${topic.id}`}>
    <div className="relative pb-3">
      <div className="relative flex items-start space-x-3">
        <div>
          <div className="mt-1 relative px-1">
            <div className="h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center">
              <DocumentAddIcon
                className="h-4 w-4 text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
        <div className="min-w-0 flex-1 py-1.5">
          <div className="text-sm text-gray-500">
            <span className="font-medium text-gray-700">
              {topic.creator?.name || ""}
            </span>{" "}
            created this topic{" "}
            <span className="whitespace-nowrap text-xs tracking-tighter ml-3 text-gray-400">
              <TimeAgo minPeriod={60} date={topic.created} />
            </span>
          </div>
        </div>
      </div>
    </div>
  </li>
);

export default TopicCreated;
