import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  GetAssessmentQuestionCategoriesQuery,
  GetAssessmentQuestionCategoriesQueryVariables,
} from "types/graphql-schema";

import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { useNotificationError } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getQuestionCategoriesQuery from "../graphql/get-question-categories-query";

export type AssessmentQuestionCategoryPickerCategory = {
  id: number;
  title: string;
};

const AssessmentQuestionCategoryPickerDropdown = ({
  categories,
  onChange,
}: {
  categories: AssessmentQuestionCategoryPickerCategory[];
  onChange: (value: AssessmentQuestionCategoryPickerCategory) => void;
}) => {
  const [options, setOptions] = useState<
    ComboboxGenericOption<AssessmentQuestionCategoryPickerCategory>[]
  >([]);
  const categoryIds = categories.map(({ id }) => id);
  const [canLoadData, setCanLoadData] = useState(false);
  const { onError } = useNotificationError();

  const { loading } = useQuery<
    GetAssessmentQuestionCategoriesQuery,
    GetAssessmentQuestionCategoriesQueryVariables
  >(getQuestionCategoriesQuery, {
    skip: !canLoadData,
    onError,
    onCompleted: (data) => {
      const categories = assertEdgesNonNull(data.assessmentQuestionCategories);
      setOptions(
        uniqBy(categories, "id").map((category) => ({
          label: category.title,
          value: category,
        }))
      );
    },
  });

  const filteredOptions = options.filter(
    (option) => !option.value.id || !categoryIds.includes(option.value.id)
  );

  const handleChangeCategory = (
    option: ComboboxGenericOption<AssessmentQuestionCategoryPickerCategory>
  ) => {
    onChange(option.value);
  };

  const handleAddNewCategory = (newCategory: string) => {
    onChange({ id: -1, title: newCategory });
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  return (
    <ComboboxGeneric
      aria-label="Assessment question category picker dropdown"
      loading={loading}
      createable
      width="full"
      value={null}
      options={filteredOptions}
      onChangeValue={handleChangeCategory}
      onClickButton={handlePreloadData}
      onAddNewValue={handleAddNewCategory}
    >
      {({ setReferenceElement }) => (
        <div className={"flex justify-between rounded-md"}>
          <Popover.Button
            className="flex items-center text-sm gap-1 border rounded-lg py-1 px-2"
            onClick={handlePreloadData}
            ref={setReferenceElement}
          >
            <PlusIcon className="h-4 w-4" />
            Pick category
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

const AssessmentQuestionCategoryPicker = ({
  categories,
  onRemoveCategory,
  onAddCategory,
  canChange,
}: {
  categories: AssessmentQuestionCategoryPickerCategory[];
  onRemoveCategory: (
    recipient: AssessmentQuestionCategoryPickerCategory
  ) => void;
  onAddCategory: (option: AssessmentQuestionCategoryPickerCategory) => void;
  canChange: boolean;
}) => {
  return (
    <div>
      <div className="flex-1 flex gap-2 flex-wrap">
        {categories.map((category) => (
          <div
            className="border rounded-lg px-2 py-1 text-sm flex items-center gap-1"
            key={category.id}
          >
            {category.title}
            {canChange && (
              <button
                className="ml-0.5 rounded-full p-0.5 border bg-gray-50 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
                onClick={() => onRemoveCategory(category)}
              >
                <XIcon className="h-3 w-3" />
              </button>
            )}
          </div>
        ))}
      </div>
      {canChange && (
        <div className="mt-2">
          <AssessmentQuestionCategoryPickerDropdown
            categories={categories}
            onChange={onAddCategory}
          />
        </div>
      )}
    </div>
  );
};

export default AssessmentQuestionCategoryPicker;
