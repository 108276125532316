import { TrashIcon } from "@heroicons/react/outline";
import Image from "@tiptap/extension-image";
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { FormEvent, memo, useRef, useState } from "react";

import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import client from "@graphql/client";
import { classNames } from "@helpers/css";

import prepareTopicFileUploadMutation from "../graphql/prepare-topic-file-upload-mutation";
import WYSIWYGDialog from "./dialog";
import { uploadImagePlugin } from "./image-upload";

/**
 * Tiptap Extension to upload images
 * @see  https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 * @since 7th July 2021
 *
 * https://github.com/ueberdosis/tiptap/blob/main/packages/extension-image/src/image.ts
 *
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */

const ImageComponent = memo(
  ({
    node,
    updateAttributes,
    selected,
    deleteNode,
    editor,
  }: {
    node: any;
    updateAttributes: (attributes: any) => void;
    selected: boolean;
    deleteNode: () => void;
    editor: Editor;
  }) => {
    const focusRef = useRef(null);
    const ref = useRef(null);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const parentRef = useRef(null);
    const [formWidth, setFormWidth] = useState(node.attrs.width);
    const [openedWidthModal, setOpenedWidthModal] = useState(false);

    const handleSubmitForm = (e: FormEvent) => {
      e.preventDefault();
      const validWidth = parseInt(formWidth);
      if (validWidth) {
        updateAttributes({ width: validWidth });
      }
      setOpenedWidthModal(false);
    };

    const handleOpenDialog = () => {
      setOpenedWidthModal(true);
      if (imageRef.current) setFormWidth(imageRef.current.naturalWidth);
    };

    const naturalWidth = imageRef.current?.naturalWidth || 0;
    return (
      <NodeViewWrapper className="react-component not-prose" ref={parentRef}>
        {openedWidthModal && imageRef.current && (
          <WYSIWYGDialog
            title="Resize image"
            onClose={() => {
              setOpenedWidthModal(false);
            }}
            focusRef={focusRef}
          >
            <form
              className="text-sm flex flex-col gap-4"
              onSubmit={handleSubmitForm}
            >
              <div className="flex">
                <div className="w-48">
                  <Input
                    required
                    ref={focusRef}
                    label="Width (px)"
                    type="number"
                    value={formWidth}
                    onChange={(e) => setFormWidth(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  className="text-xs text-gray-500 bg-gray-200 px-1 py-0.5 rounded"
                  onClick={() => setFormWidth(naturalWidth / 2)}
                >
                  {naturalWidth / 2}px (50%)
                </button>
                <button
                  type="button"
                  className="text-xs text-gray-500 bg-gray-200 px-1 py-0.5 rounded"
                  onClick={() => setFormWidth(naturalWidth)}
                >
                  {imageRef.current.naturalWidth}px (100%)
                </button>
              </div>
              <div>
                <Button theme={buttonTheme.primary} type="submit" text="Save" />
              </div>
            </form>
          </WYSIWYGDialog>
        )}
        <div
          className="flex group"
          ref={ref}
          style={{ maxWidth: node.attrs.width }}
        >
          <div className="relative">
            <div className="absolute top-2 right-2 flex gap-px rounded overflow-hidden">
              <a
                className="px-2 py-1 text-xs bg-black/80 text-gray-100 hidden group-hover:block hover:bg-black hover:text-white group-hover:no-underline"
                href={node.attrs.src}
                target="_blank"
                rel="noreferrer"
              >
                Open
              </a>
              {editor.options.editable && (
                <button
                  className="px-2 py-1 text-xs bg-black/80 text-gray-100 hidden group-hover:block hover:bg-black hover:text-white group-hover:no-underline"
                  onClick={handleOpenDialog}
                >
                  Resize
                </button>
              )}
              {editor.options.editable && (
                <button
                  onClick={deleteNode}
                  className="px-2 py-1 text-xs bg-black/80 text-gray-100 hidden group-hover:block hover:bg-black hover:text-white group-hover:no-underline"
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              )}
            </div>
            <img
              className={classNames(
                "my-0 mx-0 p-0 block object-contain border-2 js-selected-image resize shadow-prosemirror-img",
                selected && "border-blue-200"
              )}
              src={node.attrs.src}
              ref={imageRef}
              alt="Uploaded by user"
            />
          </div>
        </div>
      </NodeViewWrapper>
    );
  }
);

export const asyncUploadImageErrorMessage = "The image could not be uploaded.";

export const asyncUploadImage = (uploadVariable: {
  topicId?: number;
  artifactId?: number;
  recognitionCoreValueId?: number;
}) => {
  return (file: File) => {
    const p: Promise<string | undefined> = new Promise((resolve, reject) => {
      // we upload the image to S3 with presigned url
      // https://docs.aws.amazon.com/AmazonS3/latest/userguide/PresignedUrlUploadObject.html
      client
        .mutate({
          mutation: prepareTopicFileUploadMutation,
          variables: {
            filename: `${Date.now()}${file.name.substring(0, 100)}`,
            contentType: file.type,
            ...uploadVariable,
          },
        })
        .then(({ data }) => {
          const { fileUploadUrl, fileViewingUrl } = data.prepareFileUpload;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function (event: ProgressEvent<FileReader>) {
            if (event.target?.readyState !== FileReader.DONE) {
              return;
            }
            fetch(fileUploadUrl, {
              method: "PUT",
              headers: {
                "Content-Type": file.type,
              },
              body: file,
            }).then(() => {
              resolve(fileViewingUrl);
            });
          };
        })
        .catch(() => {
          reject(asyncUploadImageErrorMessage);
        });
    });
    return p;
  };
};

export interface TipTapCustomImageOptions {
  width?: number;
}

export const TipTapCustomImage = (
  uploadFn: (file: File) => Promise<string>
) => {
  return Image.extend<TipTapCustomImageOptions>({
    addAttributes() {
      return {
        src: {
          default: null,
        },
        width: {
          default: "",
          parseHTML: () => this.options.width,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: "img",
        },
      ];
    },
    addNodeView() {
      return ReactNodeViewRenderer(ImageComponent);
    },
    addProseMirrorPlugins() {
      return [uploadImagePlugin(uploadFn)];
    },
  });
};

export default TipTapCustomImage;
