import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { Plugin, PluginKey } from "prosemirror-state";

import { currentUserVar } from "@cache/cache";
import { getPluginClickOn } from "@components/wysiwyg/helpers";
import { tiptapExtensionPriority } from "@helpers/constants";
import { isValidUrl } from "@helpers/helpers";

import RatingPlaceholder from "./rating-placeholder";
import RatingView from "./rating-view";
import SelectRatingForm from "./select-rating-form";

const RatingComponent = ({
  node,
  updateAttributes,
  extension,
  deleteNode,
  selected,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  extension: any;
  deleteNode: () => void;
  selected: boolean;
}) => {
  const currentUser = currentUserVar();
  return (
    <NodeViewWrapper className="mb-3">
      {!node.attrs.id && currentUser.id === node.attrs.createdByUser?.id ? (
        <SelectRatingForm
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
          node={node}
          extension={extension}
        />
      ) : !node.attrs.id && currentUser.id !== node.attrs.createdByUser?.id ? (
        <RatingPlaceholder node={node} />
      ) : (
        <RatingView
          selected={selected}
          deleteNode={deleteNode}
          node={node}
          extension={extension}
        />
      )}
    </NodeViewWrapper>
  );
};

const RatingExtension = Node.create({
  name: "rating",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    // Return an object with attribute configuration
    return {
      id: {
        default: null,
      },
      ratingId: {
        default: null,
        parseHTML: (node: HTMLAnchorElement) => {
          const regexp = new RegExp(
            `^${window.location.origin}/ratings/(\\d+)$`
          );
          const regexpResult = regexp.exec(node.href);
          if (!regexpResult) {
            return null;
          }
          return regexpResult[1];
        },
        renderHTML: (attributes) => {
          return {
            "data-ratingid": attributes.ratingId,
          };
        },
      },
      // used to show placeholder when a user creates a rating in CRDT
      createdByUser: {
        default: null,
      },
    };
  },

  addOptions() {
    return {
      relatedArtifactId: null,
      topicId: null,
      meetingId: null,
    };
  },

  parseHTML() {
    return [
      {
        tag: "a",
        priority: tiptapExtensionPriority.explorerLink,
        getAttrs: (node: HTMLElement | string) => {
          if (node && node instanceof HTMLAnchorElement) {
            const regexp = new RegExp(
              `^${window.location.origin}/ratings/(\\d+)$`
            );
            const regexpResult = regexp.exec(node.href);
            if (!regexpResult) {
              return false;
            }
          }
          return null;
        },
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(RatingComponent);
  },

  renderText({ node }) {
    return `${window.location.origin}/rating/${node.attrs.id}`;
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      insertRating:
        (attrs: any) =>
        ({ chain }: { chain: any }) => {
          return chain().insertContent({ type: "rating", attrs }).run();
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      getPluginClickOn(this.name),
      new Plugin({
        key: new PluginKey(`${this.name}-paste`),
        props: {
          handlePaste: (view, event) => {
            if (!event.clipboardData) {
              return false;
            }

            // don’t create a new code block within code blocks
            if (this.editor.isActive(this.type.name)) {
              return false;
            }

            const text = event.clipboardData.getData("text/plain");
            if (!text || !isValidUrl(text)) {
              return false;
            }

            // Matches explorer
            if (text.startsWith(`${document.location.origin}/ratings`)) {
              const currentUser = currentUserVar();
              const { tr } = view.state;
              const regexp = new RegExp(
                `^${window.location.origin}/ratings/(\\d+)$`
              );
              const regexpResult = regexp.exec(text);
              if (!regexpResult) {
                return false;
              }
              const id = regexpResult[1];
              tr.replaceSelectionWith(
                this.type.create({
                  ratingId: id,
                  createdByUser: {
                    id: currentUser.id,
                    name: currentUser.name,
                    avatar: currentUser.avatar,
                  },
                })
              );
              view.dispatch(tr);
              return true;
            }
            return false;
          },
        },
      }),
    ];
  },
});

export default RatingExtension;
