import { gql } from "@apollo/client";

import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";

export default gql`
  ${CommonFeedbackArtifactFragment}
  query getDashboardFeedbackQuery(
    $asUser: Int
    $feedbackRecipient: Int
    $feedbackSender: Int
    $feedbackState: FeedbackState
    $createdBetweenDates: [Date]
    $after: String
    $first: Int!
  ) {
    artifacts(
      artifactType: feedback
      feedbackRecipient: $feedbackRecipient
      feedbackSender: $feedbackSender
      feedbackState: $feedbackState
      createdBetweenDates: $createdBetweenDates
      asUser: $asUser
      orderBy: "-created"
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ... on FeedbackArtifactNode {
            artifactType
            title
            created
            updated
            creator {
              id
              name
              avatar
            }
            canDelete {
              permission
            }
            canUpdate {
              permission
            }
            ...CommonFeedbackArtifactFragment
          }
        }
      }
    }
  }
`;
