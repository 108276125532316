import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import moment from "moment";
import { ChangeEventHandler, useState } from "react";
import {
  GetPastTopicsQueryQuery,
  GetPastTopicsQueryQueryVariables,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  assertNonNull,
  getFormattedDatetime,
} from "@helpers/helpers";

import getPastTopicsQuery from "../graphql/get-past-topics-query";
import SuggestedTopicGroup from "./suggested-topic-group";

const SuggestedTopicsSidebarPastTopics = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const [filterText, setFilterText] = useState("");
  const debouncedFilterText = useDebounce(filterText, 300);
  const { data, loading, fetchMore } = useQuery<
    GetPastTopicsQueryQuery,
    GetPastTopicsQueryQueryVariables
  >(getPastTopicsQuery, {
    variables: {
      meetingGroupId: assertNonNull(meeting.meetingGroup?.id),
      search: debouncedFilterText,
      after: null,
    },
    onError: onNotificationErrorHandler(),
  });

  const topics = data
    ? assertEdgesNonNull(data.topics).filter(
        (topic) => topic.meeting?.id !== meeting.id
      )
    : [];

  const handleChangeFilterText: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterText(e.target.value);
  };

  const handleClickMore = () => {
    fetchMore({
      variables: { after: data?.topics?.pageInfo.endCursor, merge: true },
    });
  };

  const topicsGroupedByMeeting = topics.reduce(
    (memo: { [key: number]: any }, topic) => {
      const meetingId = assertNonNull(topic.meeting?.id);
      if (!memo[meetingId]) {
        memo[meetingId] = { ...topic.meeting, topics: [] };
      }
      memo[meetingId].topics = memo[meetingId].topics.concat(topic);
      return memo;
    },
    {}
  );
  const meetingsWithTopics = sortBy(
    Object.values(topicsGroupedByMeeting),
    (meeting) => -moment(meeting.startDatetime).unix()
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6">
        <Input
          value={filterText}
          onChange={handleChangeFilterText}
          placeholder="Filter past topics..."
        />
      </div>
      {loading ? (
        <Loading>Loading past topics</Loading>
      ) : meetingsWithTopics.length > 0 ? (
        <div className="flex flex-col gap-8">
          {meetingsWithTopics.map((meetingWithTopics) => (
            <SuggestedTopicGroup
              key={meetingWithTopics.id}
              meeting={meeting}
              title={`${meetingWithTopics.title} (${getFormattedDatetime(
                meetingWithTopics.startDatetime
              )})`}
              topics={meetingWithTopics.topics}
            />
          ))}
          {data?.topics?.pageInfo.hasNextPage && (
            <div className="px-4 py-2 flex items-center justify-center">
              <button
                className="text-gray-500 text-sm mr-4 hover:bg-gray-100 rounded border px-1.5 py-0.5"
                onClick={handleClickMore}
                disabled={loading}
              >
                View more
              </button>
            </div>
          )}
        </div>
      ) : meetingsWithTopics.length === 0 ? (
        <div className="w-full flex text-gray-500">No past topics.</div>
      ) : null}
    </div>
  );
};

export default SuggestedTopicsSidebarPastTopics;
