import Button from "@components/button/button";
import Onboarding from "@components/onboarding/onboarding";

const Organization = ({ invitation, joined, loading, onClickJoin }) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <Onboarding.SubTitle>
          {invitation.organizationName
            ? invitation.organizationName
            : "Topicflow"}
        </Onboarding.SubTitle>
        {invitation.organizationMemberCount && (
          <div className="text-gray-500 text-base">
            {invitation.organizationMemberCount} member
            {invitation.organizationMemberCount === 1 ? "" : "s"}
          </div>
        )}
      </div>
      <div>
        {joined ? (
          <Onboarding.CompletedStep>Joined</Onboarding.CompletedStep>
        ) : (
          <Button
            theme="primary"
            disabled={loading}
            onClick={onClickJoin}
            text="Join now"
          />
        )}
      </div>
    </div>
  );
};

export default Organization;
