import { gql } from "@apollo/client";

export default gql`
  mutation updateMeetingMutation(
    $meetingId: Int!
    $allowExternalViewers: Boolean
  ) {
    updateMeeting(
      meetingId: $meetingId
      allowExternalViewers: $allowExternalViewers
    ) {
      meeting {
        id
        allowExternalViewers
        writableSharingUrl
      }
    }
  }
`;
