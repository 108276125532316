import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import a from "indefinite";
import { defer, pull, uniq } from "lodash";
import debounce from "lodash/debounce";
import {
  KeyboardEvent,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { ArtifactType } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";
import { v4 as uuidv4 } from "uuid";

import getActionItemsCollapsibleQuery from "@apps/action-items-collapsible/graphql/get-action-items-collapsible-query";
import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import { getRecognitionRecipientTitle } from "@apps/artifact/artifact";
import getMeetingGoalsQuery from "@apps/meeting/graphql/get-meeting-goals-query";
import useLabel from "@apps/use-label/use-label";
import cache, { currentOrganizationVar, currentUserVar } from "@cache/cache";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useMountedState from "@components/use-mounted-state/use-mounted-state";
import ArtifactWYSIWYG from "@components/wysiwyg/artifact-wysiwyg";
import { batchClient } from "@graphql/client";
import { delay } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  errorMatches,
  getUrl,
  matchApolloErrorMessage,
  toWithBackground,
} from "@helpers/helpers";

import ArtifactComment from "./artifact-comment";
import ArtifactError from "./artifact-error";
import ArtifactIcon from "./artifact-icon";
import ArtifactInfos from "./artifact-infos";
import ArtifactInput from "./artifact-input";
import ArtifactToolbar from "./artifact-toolbar";
import ArtifactComponentGoalKeyResults from "./goals/key-results";
import WYSIWYGArtifactFragment from "./graphql/artifact-fragment";
import associateArtifactWithTopicOrArtifactMutation from "./graphql/associate-artifact-with-topic-mutation";
import createOrUpdateArtifactMutation from "./graphql/create-or-update-artifact-mutation";
import getArtifactQuery from "./graphql/get-wysiwyg-artifact-query";
import {
  deleteArtifact,
  getDefaultArtifact,
  handleKeyDownEvent,
} from "./helpers";

/**
 * We need a useQuery for 2 reasons:
 * - To fetch initially the artifact using the extension attr id
 * - Then to keep the artifact data up to date when its cache is updated in another view
 *
 * Then we use useMutation to create or update the artifact. We define a no-cache policy and ignore api response
 * to prevent the cache to be updated after the api call succeed, instead we update the cache optimistically before the api call.
 * We prevent async update of the artifact which would provide a broken UX while user types.
 *
 */
const ArtifactComponent = ({
  node,
  updateAttributes,
  selected,
  editor,
  getPos,
  extension,
  deleteNode,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  selected: boolean;
  editor: Editor;
  getPos: () => number;
  extension: any;
  deleteNode: () => void;
}) => {
  const isMounted = useMountedState();
  const ref = createRef<HTMLDivElement | undefined>();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const location = useLocation<TFLocationState>();
  const { id, createdByUser, commentId, copyId } = node.attrs;
  const artifactTypeName = node.type.name;
  const isActionItem = artifactTypeName === ArtifactType.ActionItem;
  const isDecision = artifactTypeName === ArtifactType.Decision;
  const isFeedback = artifactTypeName === ArtifactType.Feedback;
  const isDocument = artifactTypeName === ArtifactType.Document;
  const isGoal = artifactTypeName === ArtifactType.Goal;
  const isRecognition = artifactTypeName === ArtifactType.Recognition;
  const artifactId = useRef();
  const lastUpdate = useRef(Date.now());
  const lastPromise = useRef();
  const nextPromise = useRef();
  const l = useLabel();
  const uuid = node.attrs.uuid;
  const parentArtifactId = (editor.options.editorProps as any)
    .relatedArtifactId;
  const isCreatedByCurrentUser = createdByUser?.id === currentUser.id;
  const attributeArtifactId = id || copyId;

  // by default we show the description preview
  // except if it's in an artifact description in order to avoid inifinite loop with artifact referencing themselves
  const expandedUserIds = String(node.attrs.expandedUserIds).split(",");
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(
    // we do not expand when the artifact component is rendered in an artifact wysiwyg
    !parentArtifactId && expandedUserIds.includes(String(currentUser.id))
  );
  const [showCreateArtifactDialog, setShowCreateArtifactDialog] = useState(
    (waffle.flag_is_active("click-artifact-title-opens-sidebar") ||
      (!waffle.flag_is_active("click-artifact-title-opens-sidebar") &&
        isRecognition)) &&
      isCreatedByCurrentUser &&
      !attributeArtifactId
  );
  const [isFocusedOnInput, setIsFocusedOnInput] = useState(false);
  const [syncedArtifact, setSyncedArtifact] = useState<any>(
    getDefaultArtifact(
      artifactTypeName,
      currentUser,
      node.attrs.title || "",
      currentOrganization
    )
  );

  // HOOKS
  // skip if the artifact has not been attributed an id (not existing yet)
  // skip when user is editing main text input to prevent the async value update while typing
  const skip =
    (!id && !copyId) ||
    (!id && copyId && !isCreatedByCurrentUser) ||
    (selected && isFocusedOnInput);

  const { data, loading, error } = useQuery(getArtifactQuery, {
    fetchPolicy: "cache-and-network",
    variables: { artifactId: attributeArtifactId },
    skip,
    onError: (errors) => {
      if (matchApolloErrorMessage(errors, `Invalid id ${id}`)) {
        return;
      }
      Sentry.captureException(errors);
    },
    onCompleted: () => {
      lastUpdate.current = Date.now();
    },
    client: batchClient,
  });
  const seemsDeleted = id && data && data.artifact === null;
  const [associateArtifactWithTopicOrArtifact] = useMutation(
    associateArtifactWithTopicOrArtifactMutation
  );
  const [updateArtifact, { loading: loadingSave }] = useMutation(
    createOrUpdateArtifactMutation,
    {
      ignoreResults: true,
      fetchPolicy: "no-cache", // we handle updating the cache ourselves
    }
  );

  // if existing artifact was added to notes, we need to associate it with the topic
  useEffect(() => {
    if (!copyId || id) {
      return;
    }
    if (!createdByUser) {
      window.console.error("need to have a created by user");
    }
    if (!isCreatedByCurrentUser) {
      // only current user can associate an artifact
      return;
    }

    if (extension.options.topicId) {
      associateArtifactWithTopicOrArtifact({
        variables: {
          artifactId: copyId,
          topicId: extension.options.topicId,
        },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          if (isMounted()) {
            updateAttributes({ copyId: null, id: copyId });
          }
        },
      });
    } else if (extension.options.relatedArtifactId) {
      associateArtifactWithTopicOrArtifact({
        variables: {
          artifactId: copyId,
          otherArtifactId: extension.options.relatedArtifactId,
        },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          if (isMounted()) {
            updateAttributes({ copyId: null, id: copyId });
          }
        },
      });
    } else {
      setTimeout(() => {
        updateAttributes({ copyId: null, id: copyId });
      }, 6000); // 1s + debounce time of tiptap backend sync https://github.com/Topicflow/topicflow/blob/68e94db6bc205171d6345ee240fcd51d6104e6c1/websocket/server.js#L92
    }
  }, [id, copyId]);

  const saveArtifact = (dataToUpdate: any, config = { debounced: true }) => {
    const updatedArtifact = {
      ...dataToUpdate,
      artifactType: artifactTypeName,
      id: artifactId.current,
    };

    // sync in the app
    if (artifactId.current) {
      cache.writeFragment({
        id: cache.identify(updatedArtifact),
        fragment: WYSIWYGArtifactFragment,
        fragmentName: "WYSIWYGArtifactFragment",
        data: { ...syncedArtifact, ...updatedArtifact },
      });
    }

    // sync with server
    const dataToSave = {
      ...updatedArtifact,
      uuid: uuid || uuidv4(),
      additionalFields: isDecision
        ? {
            decision: updatedArtifact.decision,
          }
        : isGoal && !artifactId.current
        ? {
            ownerIds: [currentUser.id],
          }
        : null,
    };
    if (config.debounced) {
      debouncedSaveData(dataToSave);
    } else {
      saveData(dataToSave);
    }
  };

  const saveData = (dataToUpdate: any) => {
    const requestedAt = Date.now();
    const variables = {
      ...dataToUpdate,
      artifactId: artifactId.current,
      ...extension.options,
    };
    nextPromise.current = variables;

    // skip if there is currently a promise pending
    if (!lastPromise.current) {
      updateArtifact({
        variables,
        onError: onNotificationErrorHandler(),
        refetchQueries: artifactId.current
          ? []
          : [getMeetingGoalsQuery, getActionItemsCollapsibleQuery],
      }).then(({ data: updatedData }) => {
        const respondedAt = Date.now();
        const updatedArtifactId =
          updatedData.createOrUpdateArtifact.artifact.id;

        // update the artifact id
        if (updatedArtifactId !== variables.artifactId) {
          artifactId.current = updatedArtifactId;
          if (isMounted()) {
            updateAttributes({
              id: updatedArtifactId,
              uuid: updatedData.createOrUpdateArtifact.artifact.uuid,
              title: null,
              createdByUser: null,
            });
          }
        }

        // if a promise has started after executing the current promise
        // we execute the next saveData promise and will not update cache/local state.
        const nextPromiseExisting = lastPromise.current !== nextPromise.current;
        lastPromise.current = undefined;
        if (nextPromiseExisting && isMounted()) {
          return saveData(nextPromise.current);
        }

        // if this api call was requested before another update and its reponse came after the other update
        // we stop the cache/syncedState update, as we want to keep the state of latest update
        const cacheUpdatedBetweenRequestAndResponse =
          requestedAt < lastUpdate.current && respondedAt > lastUpdate.current;
        if (cacheUpdatedBetweenRequestAndResponse) {
          return;
        }

        // Update cache and local state
        if (isMounted()) {
          setSyncedArtifact(updatedData.createOrUpdateArtifact.artifact);
        }
        cache.writeFragment({
          id: cache.identify(updatedData.createOrUpdateArtifact.artifact),
          fragment: WYSIWYGArtifactFragment,
          data: updatedData.createOrUpdateArtifact.artifact,
          fragmentName: "WYSIWYGArtifactFragment",
        });
      });
      lastPromise.current = variables;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveData = useCallback(
    debounce(saveData, delay.autosaveDebounce),
    [id]
  );

  // HANDLERS
  const saveTitle = (title: string, options = { debounced: true }) => {
    if (title.trim()) {
      if (isDecision) {
        saveArtifact({ decision: title, title }, options);
      } else {
        saveArtifact({ title }, options);
      }
    }
  };
  const handleDelete = (_e?: any, callback?: () => void) => {
    deleteArtifact({
      artifact: syncedArtifact,
      deleteNode,
      callback,
    });
  };
  const handleKeyDown = (e?: KeyboardEvent) => {
    handleKeyDownEvent({
      e,
      editor,
      getPos,
      onDelete: handleDelete,
      onExpandArtifactDescription: handleToggleDescription,
    });
  };

  const handleCloseCreateArtifactDialog = (artifact: any) => {
    if (!artifact) {
      deleteNode();
    } else if (artifact.artifactType !== artifactTypeName) {
      deleteNode();
      editor
        .chain()
        .focus(getPos())
        .insertContent({
          type: artifact.artifactType,
          attrs: { uuid: node.attrs.uuid, id: artifact.id },
        })
        .run();
    } else {
      updateAttributes({ id: artifact.id });
      setShowCreateArtifactDialog(false);
    }
  };

  const handleFocusInput = () => {
    setIsFocusedOnInput(true);
  };
  const handleBlurInput = () => {
    setIsFocusedOnInput(false);
  };
  const handleToggleDescription = () => {
    // persist the expanded state only for top level artifact
    if (!parentArtifactId) {
      const newExpandedUserIds = descriptionIsExpanded
        ? pull(expandedUserIds, String(currentUser.id))
        : [...expandedUserIds, currentUser.id];
      const cleanedExpandedIds = newExpandedUserIds.filter(
        (expandedUserId) => expandedUserId && Number(expandedUserId)
      );
      updateAttributes({ expandedUserIds: uniq(cleanedExpandedIds).join(",") });
    }
    setDescriptionIsExpanded(!descriptionIsExpanded);
  };

  useEffect(() => {
    // seems like it was deleted
    if (seemsDeleted) {
      return;
    }
    if (data && data.artifact.id && !artifactId.current) {
      artifactId.current = data.artifact.id;
    }

    // extension has an id but local artifact state does not have one
    // so when we fetch the data from api, we refresh local state with api data
    if (
      artifactId.current &&
      data &&
      syncedArtifact?.id !== artifactId.current
    ) {
      return setSyncedArtifact(data.artifact);
    }

    // If title changes from cache/api, we update localstate only when user
    // is not focused on field, otherwise it'll create some weird interactions
    if (data && !isFocusedOnInput && syncedArtifact) {
      const titleChanged =
        (isActionItem || isDocument || isGoal) &&
        data.artifact.title !== syncedArtifact.title;
      const decisionTitleChanged =
        isDecision && data.artifact.decision !== syncedArtifact.decision;
      if (titleChanged || decisionTitleChanged) {
        return setSyncedArtifact(data.artifact);
      }
    }
  }, [data, id, isFocusedOnInput]);

  // update uuid attributes if there is one in database
  useEffect(() => {
    if (id && data?.artifact?.uuid && data.artifact.uuid !== node.attrs.uuid) {
      defer(() => {
        if (isMounted()) {
          updateAttributes({ uuid: data.artifact.uuid });
        }
      });
    }
  }, [data, id, node.attrs.uuid]);

  useEffect(() => {
    // when drag and dropping artifacts around, sometimes it will just update the id on the artifact extension
    // when it happens we have to ensure we update the ids in this component
    if (artifactId.current && id && id !== artifactId.current) {
      artifactId.current = id;
    }
  }, [id]);

  // Make sure we close any create dialog if id is set
  useEffect(() => {
    if (attributeArtifactId) {
      setShowCreateArtifactDialog(false);
    }
  }, [attributeArtifactId]);

  // Browsers tend to bug because of the draggable attribute on the parent DOM element of this component
  // It seems that contentEditable=true does not play well with draggable=true.
  // https://github.com/ueberdosis/tiptap/issues/1668#issuecomment-997755214
  // https://discuss.prosemirror.net/t/nested-draggable-editors-behave-differently-in-all-browsers/3807/13
  useEffect(() => {
    if (ref.current && ref.current.parentNode) {
      (ref.current.parentNode as HTMLElement).setAttribute(
        "draggable",
        "false"
      );
    }
  }, [ref]);

  const syncedTitle = isDecision
    ? syncedArtifact?.decision
    : syncedArtifact?.title;

  useEffect(() => {
    const st = null;
    if (descriptionIsExpanded && ref.current) {
      setTimeout(() => {
        const el = ref.current?.querySelector(
          ".js-topic-discussion-notes-input"
        ) as any;
        if (el) {
          el.editor.chain().focus().run();
        }
      }, 300);
    }
    return function cleanup() {
      if (st) clearTimeout(st);
    };
  }, [descriptionIsExpanded]);

  // RENDER
  const apiError = id && error;
  const apiErrorNoPermissions =
    apiError &&
    errorMatches(
      error,
      "You do not have permission to access the instance with id"
    );
  const apiNotFound =
    (apiError && errorMatches(error, "Invalid id")) || seemsDeleted;
  const unexpectedError =
    apiError ||
    (!isFocusedOnInput && id && !syncedArtifact.id && !data && !loading);

  const handleDescriptionChanged = ({ editor }: { editor: Editor }) => {
    setSyncedArtifact({
      ...syncedArtifact,
      description: JSON.stringify(editor.getJSON()),
    });
  };

  const handleSavedComment = (comment?: { id: number }) => {
    const commentId = comment?.id || null;
    updateAttributes({ commentId });
  };

  const handleRemoveComment = () => {
    updateAttributes({ commentId: null });
  };

  const handleDescriptionChangedDebounced = debounce(
    handleDescriptionChanged,
    2000
  );

  const sidebarTo = syncedArtifact
    ? toWithBackground({
        pathname: getUrl({
          artifactId: syncedArtifact.id,
          artifactType: syncedArtifact.artifactType as ArtifactType,
          meetingGroupId: extension.options.meetingGroupId,
          meetingId: extension.options.meetingId,
        }),
        location,
      })
    : "";

  return (
    <NodeViewWrapper
      className={classNames(
        "pl-3 pr-2 py-0.5 rounded-lg mt-2 mb-3 flex border",
        isActionItem && "bg-violet-50 border-violet-100",
        isDecision && "bg-emerald-50 border-emerald-100",
        isDocument && "bg-gray-50 border-gray-200",
        isGoal && "bg-blue-50 border-blue-100",
        isRecognition && "bg-amber-50 border-amber-200",
        isFeedback && "bg-fuchsia-50 border-fuchsia-200",
        selected &&
          editor?.options?.editable &&
          "ring-2 ring-blue-200 ring-offset-2 extension-is-selected"
      )}
      ref={ref}
    >
      {showCreateArtifactDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: artifactTypeName,
            meetingId: extension.options.meetingId,
            title: node.attrs.title || "",
          }}
          onClose={handleCloseCreateArtifactDialog}
        />
      )}
      {!id && copyId && !isCreatedByCurrentUser ? (
        <div className="text-gray-500 text-sm py-0.5 italic flex items-center gap-2">
          <Loading mini size="4" className="shrink-0" />
          <span className="flex-1">
            <span className="font-medium text-gray-600">
              {createdByUser.name}
            </span>{" "}
            is adding {a(l(artifactTypeName))}
          </span>
        </div>
      ) : !id &&
        createdByUser &&
        (createdByUser.id !== currentUser.id ||
          isRecognition ||
          waffle.flag_is_active("click-artifact-title-opens-sidebar")) ? (
        <div className="text-gray-500 text-sm py-0.5 italic flex items-center gap-2">
          <Loading mini size="4" className="shrink-0" />
          <span className="flex-1">
            <span className="font-medium text-gray-600">
              {createdByUser.name}
            </span>{" "}
            is creating {a(l(artifactTypeName))}
          </span>
        </div>
      ) : attributeArtifactId && !syncedArtifact?.id && loading ? (
        <div className="text-gray-500 text-sm py-0.5 italic flex items-center gap-2">
          <Loading mini size="4" className="shrink-0" />
          <span className="flex-1">Loading {l(artifactTypeName)}</span>
        </div>
      ) : apiErrorNoPermissions ? ( // USER DOES NOT HAVE PERMISSIONS TO VIEW ARTIFACT
        <ArtifactError onDeleteNode={handleDelete}>
          You don't have the permission to view this {l(artifactTypeName)}.
        </ArtifactError>
      ) : apiNotFound ? ( // ARTIFACT HAS BEEN DELETED OR NO ACCESS
        <ArtifactError onDeleteNode={handleDelete}>
          This {l(artifactTypeName)} does not exist anymore.
        </ArtifactError>
      ) : unexpectedError ? ( // UNKNOWN ERROR
        <ArtifactError onDeleteNode={handleDelete}>
          An unexpected error occurred. This {l(artifactTypeName)} can not be
          displayed.
        </ArtifactError>
      ) : artifactTypeName !== syncedArtifact?.artifactType ? ( // When artifact is added as wrong type
        <ArtifactError onDeleteNode={handleDelete}>
          <>
            We cannot display this {a(l(artifactTypeName))} because it is{" "}
            <a
              href={getUrl({
                artifactId: syncedArtifact.id,
                artifactType: syncedArtifact.artifactType,
              })}
              target="_blank"
              rel="noreferrer"
            >
              {a(l(syncedArtifact.artifactType))}
            </a>
            .
          </>
        </ArtifactError>
      ) : (
        // ARTIFACT EXIST
        <div
          className={classNames(
            "flex-1",
            // Do not remove min-w-0, as it prevents long text/url to wrap correctly
            // https://imgur.com/a/S90aplB
            "min-w-0"
          )}
        >
          <div
            className={classNames(
              "flex flex-1 items-start min-w-0 gap-2",
              "@container/wysiwyg-artifact",
              "group", // for showing toolbar when hovering this container
              "not-prose" // do not apply tailwind typography to the component
            )}
          >
            <ArtifactToolbar
              syncedArtifact={syncedArtifact}
              onToggleDescription={handleToggleDescription}
              onRemoveFromNotes={handleDelete}
              descriptionIsExpanded={descriptionIsExpanded}
              extension={extension}
              onSavedComment={handleSavedComment}
            />
            <ArtifactIcon node={node} syncedArtifact={syncedArtifact} />
            {isRecognition || isFeedback ? (
              <AppLink
                to={sidebarTo}
                className="flex flex-1 items-center py-0.5 min-w-0 hover:underline"
              >
                <div>
                  <div className="font-medium">
                    {isFeedback
                      ? syncedArtifact.title
                      : getRecognitionRecipientTitle(syncedArtifact)}
                  </div>
                  {isRecognition && (
                    <div className="text-gray-600">{syncedArtifact.title}</div>
                  )}
                </div>
              </AppLink>
            ) : waffle.flag_is_active("click-artifact-title-opens-sidebar") &&
              id ? (
              <AppLink
                to={sidebarTo}
                className="flex flex-1 items-center py-0.5 min-w-0 text-gray-600 font-medium hover:underline"
              >
                {syncedTitle}
              </AppLink>
            ) : waffle.flag_is_active("click-artifact-title-opens-sidebar") &&
              !id ? (
              <span className="flex flex-1 items-center py-0.5 min-w-0 text-gray-600 font-medium hover:underline gap-2">
                Creating {l(artifactTypeName)}
              </span>
            ) : (
              <ArtifactInput
                artifactType={artifactTypeName}
                artifactId={attributeArtifactId} // important for input autofocus to get asap the id prop. https://github.com/Topicflow/topicflow/issues/406
                value={syncedTitle}
                selected={selected}
                loading={loading}
                editorIsFocused={editor.isFocused}
                searchQuery={extension.options.searchQuery}
                onChangeTitle={saveTitle}
                onKeyDown={handleKeyDown}
                onFocusInput={handleFocusInput}
                onBlurInput={handleBlurInput}
              />
            )}
            <ArtifactInfos
              syncedArtifact={syncedArtifact}
              commentId={node.attrs.commentId}
              descriptionIsExpanded={descriptionIsExpanded}
              onToggleDescription={handleToggleDescription}
              loading={loading}
              loadingSave={loadingSave}
              onDelete={handleDelete}
              onSavedComment={handleSavedComment}
              onRemoveComment={handleRemoveComment}
            />
          </div>
          {isGoal && (
            <ArtifactComponentGoalKeyResults artifactId={syncedArtifact.id} />
          )}
          {descriptionIsExpanded && (
            <div
              key={`editor-expanded-${String(descriptionIsExpanded)}`}
              className="relative -ml-3 -mr-2 border-t border-t-black/10"
              aria-label="Artifact description container"
            >
              <ArtifactWYSIWYG
                artifact={syncedArtifact}
                className="bg-white/60 pl-5 pr-5 py-2 -mb-0.5 pb-4 rounded-b-lg"
                editable
                showFixedMenu={false}
                organizationId={syncedArtifact.organization?.id}
                onChange={handleDescriptionChangedDebounced}
                showPlusButton={false}
              />
            </div>
          )}
          {commentId && id && (
            <ArtifactComment
              commentId={commentId}
              artifact={syncedArtifact}
              meetingGroupId={extension.options.meetingGroupId}
              meetingId={extension.options.meetingId}
              onRemoveCommentAttribute={handleSavedComment}
            />
          )}
        </div>
      )}
    </NodeViewWrapper>
  );
};

export default ArtifactComponent;
