import { gql } from "@apollo/client";

export default gql`
  mutation toggleTopicMutation($key: String!, $value: JSONString!) {
    createOrUpdateKvItem(key: $key, value: $value) {
      kvItem {
        id
        value
      }
    }
  }
`;
