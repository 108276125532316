import { XIcon } from "@heroicons/react/solid";
import { ChangeEvent, useState } from "react";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import {
  MeetingOrTemplateVisibility,
  getMeetingAllowVisibilityLabel,
} from "@apps/meeting/helpers";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Modal from "@components/modal/modal";
import { classNames } from "@helpers/css";
import { getUrl } from "@helpers/helpers";

enum ConfirmOptions {
  useTemplateContentAccess = "useTemplateContentAccess",
  keepMeetingContentAccess = "keepMeetingContentAccess",
  createNewMeeting = "createNewMeeting",
}

const TemplateNoteAccessModal = ({
  meetingGroup,
  isRecurring,
  template,
  onCancel,
  onConfirm,
}: {
  meetingGroup: MeetingOrTemplateVisibility;
  template: {
    id: number;
  } & MeetingOrTemplateVisibility;
  isRecurring: boolean;
  onCancel: () => void;
  onConfirm: ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => void;
}) => {
  const link = useLink();
  const [showCreationDialog, setShowCreationDialog] = useState(false);
  const [changeMeetingNoteAccess, setChangeMeetingNoteAccess] =
    useState<string>(ConfirmOptions.useTemplateContentAccess);

  const handleChangeAllowAdminVisibility = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setChangeMeetingNoteAccess(e.target.value);
  };

  const handleConfirm = () => {
    if (changeMeetingNoteAccess === ConfirmOptions.createNewMeeting) {
      setShowCreationDialog(true);
    } else {
      onConfirm({
        allowAdminVisibility:
          changeMeetingNoteAccess === ConfirmOptions.useTemplateContentAccess
            ? template.allowAdminVisibility
            : meetingGroup.allowAdminVisibility,
        allowOrgVisibility:
          changeMeetingNoteAccess === ConfirmOptions.useTemplateContentAccess
            ? template.allowOrgVisibility
            : meetingGroup.allowOrgVisibility,
        allowManagementTreeVisibility:
          changeMeetingNoteAccess === ConfirmOptions.useTemplateContentAccess
            ? template.allowManagementTreeVisibility
            : meetingGroup.allowManagementTreeVisibility,
      });
    }
  };

  return (
    <>
      <Modal open={!showCreationDialog}>
        <div
          className="p-6 flex flex-col gap-8"
          aria-label="Template note access modal"
        >
          <div className="flex justify-between">
            <div className="text-xl font-medium">
              Change meeting content access?
            </div>
            <button onClick={onCancel}>
              <XIcon className="text-gray-500 h-5 w-5" />
            </button>
          </div>
          <div>
            <div>
              The template has a different content access than this meeting:
            </div>

            <div className="flex flex-col gap-1 text-sm mt-2">
              <div className="flex gap-2 rounded bg-blue-50 px-4 py-2">
                <div className="w-20 text-blue-800 font-medium">Meeting</div>
                <div className="flex-1">
                  Notes are accessible by{" "}
                  {getMeetingAllowVisibilityLabel(
                    {
                      allowAdminVisibility: meetingGroup.allowAdminVisibility,
                      allowOrgVisibility: meetingGroup.allowOrgVisibility,
                      allowManagementTreeVisibility:
                        meetingGroup.allowManagementTreeVisibility,
                    },
                    true
                  )}
                </div>
              </div>
              <div className="flex gap-2 rounded bg-emerald-50 px-4 py-2">
                <div className="w-20 text-emerald-800 font-medium">
                  Template
                </div>
                <div className={classNames("flex-1")}>
                  Notes are accessible by{" "}
                  <span className={changeMeetingNoteAccess ? "font-bold" : ""}>
                    {getMeetingAllowVisibilityLabel(
                      changeMeetingNoteAccess
                        ? {
                            allowAdminVisibility: template.allowAdminVisibility,
                            allowOrgVisibility: template.allowOrgVisibility,
                            allowManagementTreeVisibility:
                              template.allowManagementTreeVisibility,
                          }
                        : {
                            allowAdminVisibility:
                              meetingGroup.allowAdminVisibility,
                            allowOrgVisibility: meetingGroup.allowOrgVisibility,
                            allowManagementTreeVisibility:
                              meetingGroup.allowManagementTreeVisibility,
                          },
                      true
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1 text-sm px-1">
            <label className="flex gap-2 items-start cursor-pointer py-1 hover:text-black">
              <div className="pt-0.5">
                <input
                  type="radio"
                  name="changeMeetingNoteAccess"
                  aria-label={`Option ${ConfirmOptions.useTemplateContentAccess}`}
                  value={ConfirmOptions.useTemplateContentAccess}
                  checked={
                    changeMeetingNoteAccess ===
                    ConfirmOptions.useTemplateContentAccess
                  }
                  onChange={handleChangeAllowAdminVisibility}
                />
              </div>
              <div>
                <div className="font-medium">Set template's content access</div>
                <div className="text-gray-500">
                  The content access of the meeting will be set to match that of
                  the template.
                  {isRecurring
                    ? " All previous and future occurrences of this recurring meeting will be updated as well."
                    : ""}
                </div>
              </div>
            </label>
            <label className="flex gap-2 items-start cursor-pointer py-1 hover:text-black">
              <div className="pt-0.5">
                <input
                  type="radio"
                  name="changeMeetingNoteAccess"
                  aria-label={`Option ${ConfirmOptions.keepMeetingContentAccess}`}
                  value={ConfirmOptions.keepMeetingContentAccess}
                  checked={
                    changeMeetingNoteAccess ===
                    ConfirmOptions.keepMeetingContentAccess
                  }
                  onChange={handleChangeAllowAdminVisibility}
                />
              </div>
              <div>
                <div className="font-medium">Keep current content access</div>
                <div className="text-gray-500">
                  The meeting content access will remain the same. The meeting
                  will not respect the guidelines established by the template.
                </div>
              </div>
            </label>
            <label className="flex gap-2 items-start cursor-pointer py-1 hover:text-black">
              <div className="pt-0.5">
                <input
                  type="radio"
                  name="changeMeetingNoteAccess"
                  aria-label={`Option ${ConfirmOptions.createNewMeeting}`}
                  checked={
                    changeMeetingNoteAccess === ConfirmOptions.createNewMeeting
                  }
                  value={ConfirmOptions.createNewMeeting}
                  onChange={handleChangeAllowAdminVisibility}
                />
              </div>
              <div>
                <div className="font-medium">Create a new meeting</div>
                <div className="text-gray-500">
                  The current meeting will not be updated. Instead a new meeting
                  will be created with the template topics and access settings.
                </div>
              </div>
            </label>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <Button theme={buttonTheme.primary} onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
            <div>
              <Button theme={buttonTheme.default} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {showCreationDialog && (
        <MeetingDialog
          onClose={onCancel}
          formOptions={{
            templateId: template.id,
          }}
          onSaved={({ meeting }) => {
            if (meeting.meetingGroup) {
              link.redirect(
                getUrl({
                  meetingGroupId: meeting.meetingGroup.id,
                  meetingId: meeting.id,
                })
              );
            }
          }}
        />
      )}
    </>
  );
};

export default TemplateNoteAccessModal;
