import moment from "moment";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { MdTimer } from "react-icons/md";

import { classNames } from "@helpers/css";

const getDurationSinceMeetingEnd = (endDatetime: string) => {
  const seconds = moment(endDatetime).diff(moment(), "seconds");
  const daysRemaining = Math.floor(Math.abs(seconds / 60 / 60 / 24));
  if (daysRemaining) {
    return `${daysRemaining} ${pluralize("day", daysRemaining)}`;
  }
  const hoursRemaining = Math.floor(Math.abs(seconds / 60 / 60));
  if (hoursRemaining) {
    return `${hoursRemaining} ${pluralize("hour", hoursRemaining)}`;
  }
  const minutesRemaining = Math.floor(Math.abs(seconds / 60));
  if (minutesRemaining) {
    return `${minutesRemaining}m`;
  }
  return "1m";
};

const MeetingTimer = ({
  id,
  startDatetime,
  endDatetime,
}: {
  startDatetime: string;
  endDatetime: string;
  id: number;
}) => {
  const [wasDisplayedBeforeMeetingEnd, setWasDisplayedBeforeMeetingEnd] =
    useState(moment().isBefore(endDatetime, "seconds"));
  const [, setNow] = useState(moment().format());
  const isNow = moment().isBetween(startDatetime, endDatetime, "minutes", "[]");

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment().format());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setWasDisplayedBeforeMeetingEnd(moment().isBefore(endDatetime, "seconds"));
  }, [id, endDatetime]);

  const getTimerColor = (mins: number) => {
    if (mins < 0) {
      return "text-red-700";
    } else if (mins <= 2) {
      return "text-rose-600";
    } else if (mins <= 5) {
      return "text-amber-600";
    }
    return "text-emerald-600";
  };

  const diff = moment(endDatetime).diff(moment(), "seconds");
  const minutesRemaining = diff / 60;

  return (
    (((wasDisplayedBeforeMeetingEnd && minutesRemaining <= 0) || // displayed before the end of meeting and has now past the end of meeting
      (isNow && minutesRemaining > 0)) && ( // is during meeting
      <span
        className={classNames(
          "items-center rounded-full text-sm font-medium hidden sm:inline-flex transition-colors duration-500 tracking-tight",
          getTimerColor(minutesRemaining)
        )}
      >
        <MdTimer className="w-4 h-4 mr-1 fill-current" />
        {minutesRemaining > 0 ? (
          <span>{Math.ceil(minutesRemaining)}m</span>
        ) : (
          <span>
            {minutesRemaining < -1 ? "+" : ""}
            {getDurationSinceMeetingEnd(endDatetime)}
          </span>
        )}
      </span>
    )) ||
    null
  );
};

export default MeetingTimer;
