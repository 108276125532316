import { useQuery } from "@apollo/client";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { compact, sortBy, uniqBy } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  GetRelatedTopicArtifactsQuery,
  GetRelatedTopicArtifactsQueryVariables,
  TopicSidebarTopicNodeFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import Error from "@components/error/error";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { dateFormat } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

import getRelatedTopicsQuery from "../graphql/get-related-topics-query";

export default function RelatedMeetings({
  topic,
}: {
  topic: TopicSidebarTopicNodeFragment;
}) {
  const { loading, error, data } = useQuery<
    GetRelatedTopicArtifactsQuery,
    GetRelatedTopicArtifactsQueryVariables
  >(getRelatedTopicsQuery, {
    variables: {
      topicId: topic.id,
      meetingGroupId: topic?.meeting?.meetingGroup?.id || 0,
    },
    onError: onNotificationErrorHandler(),
  });
  const [query, setQuery] = useState("");
  const link = useLink();
  const location = useLocation<TFLocationState>();

  if (loading) {
    return <Loading mini size="4" />;
  }
  if (error) {
    return <Error title={"Cannot fetch related topics"}></Error>;
  }

  const optionDisplayed = 3;
  // merge, sort current topic in relatedTopics
  const relatedTopics = uniqBy(
    sortBy(
      compact(
        topic && data
          ? [
              {
                id: topic.id,
                topicId: topic.id,
                startDatetime: topic.meeting?.startDatetime,
                isSelected: true,
                label: moment(topic.meeting?.startDatetime).format(
                  dateFormat.shortMonthDay
                ),
              },
              ...(data?.topic?.relatedTopics?.edges.map((edge: any) => ({
                id: edge.node.id,
                topicId: edge.node.id,
                isSelected: false,
                label: moment(edge.node.meeting?.startDatetime).format(
                  dateFormat.shortMonthDay
                ),
                startDatetime: edge.node.meeting?.startDatetime,
              })) || []),
            ]
          : []
      ).filter(({ startDatetime }) => startDatetime),
      ({ startDatetime }) => -moment(startDatetime).unix()
    ),
    ({ id }) => id
  );

  // get topics we can display as pills
  const firstTopics = relatedTopics.slice(0, optionDisplayed);
  const topicsAfterToday = relatedTopics
    .filter((t) =>
      moment(t.startDatetime).isBefore(moment().endOf("day").format())
    )
    .slice(0, optionDisplayed);
  const displayedTopics = uniqBy(
    firstTopics.concat(topicsAfterToday),
    ({ id }) => id
  ).slice(-optionDisplayed);

  // all topics appearing in the combobox
  const filteredTopics =
    query === ""
      ? relatedTopics
      : relatedTopics.filter((topic) =>
          topic.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  const selectedComboboxOption = displayedTopics.find((t) => t.id === topic.id)
    ? null
    : filteredTopics.find((t) => t.id === topic.id);

  const handleSelect = (option: any) => {
    link.redirect(
      toWithBackground({
        pathname: getUrl({
          meetingGroupId: topic.meeting?.meetingGroup?.id,
          meetingId: topic.meeting?.id,
          topicId: option.topicId,
        }),
        location,
      })
    );
  };

  return (
    <>
      <NavLink
        to={toWithBackground({
          pathname: `${getUrl({
            meetingGroupId: topic.meeting?.meetingGroup?.id,
            meetingId: topic.meeting?.id,
            topicId: topic.id,
          })}/all`,
          location,
        })}
        className={(isActive) =>
          classNames(
            "hover:bg-gray-300 rounded-md px-2 py-1 text-sm",
            isActive ? "bg-gray-300" : "bg-gray-100"
          )
        }
      >
        All time
      </NavLink>
      {displayedTopics.map(({ topicId, startDatetime }) => (
        <NavLink
          key={topicId}
          exact
          to={toWithBackground({
            pathname: getUrl({
              meetingGroupId: topic.meeting?.meetingGroup?.id,
              meetingId: topic.meeting?.id,
              topicId,
            }),
            location,
          })}
          className={(isActive) =>
            classNames(
              "hidden sm:inline",
              "hover:bg-gray-300 rounded-md px-2 py-1 text-sm",
              isActive ? "bg-gray-300" : "bg-gray-100"
            )
          }
        >
          {moment(startDatetime).format(dateFormat.shortMonthDay)}
        </NavLink>
      ))}

      {relatedTopics.length > displayedTopics.length && (
        <Combobox value={selectedComboboxOption} onChange={handleSelect}>
          <div className="relative">
            <div className="relative w-40 text-left bg-white border rounded-md sm:text-sm overflow-hidden">
              <Combobox.Input
                placeholder={`${
                  relatedTopics.length - optionDisplayed
                } other meetings`}
                className={(relatedTopic) =>
                  classNames(
                    "ring-0 outline-0 w-full border-none py-1 pl-3 pr-8 text-sm leading-5 text-gray-900",
                    !relatedTopic && "text-gray-500"
                  )
                }
                displayValue={(relatedTopic: any) =>
                  relatedTopic ? relatedTopic.label : ""
                }
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto z-dropdown text-base bg-white rounded-md border shadow-lg max-h-60 sm:text-sm">
                {filteredTopics.length === 0 && query !== "" ? (
                  <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredTopics.map((relatedTopic) => (
                    <Combobox.Option
                      key={relatedTopic.topicId}
                      className={({ active }) =>
                        `cursor-default select-none relative py-2 pl-4 pr-2 flex items-center ${
                          active
                            ? "text-white bg-blue-600"
                            : "text-gray-900 bg-white"
                        }`
                      }
                      value={relatedTopic}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {relatedTopic.label}
                          </span>
                          {selected || relatedTopic.id === topic.id ? (
                            <span
                              className={`flex items-center pl-2 ${
                                active ? "text-white" : "text-blue-600"
                              }`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      )}
    </>
  );
}
