import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { DashboardInsightFragment } from "types/graphql-schema";
import { BasicUser, TFLocationState } from "types/topicflow";

import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import { currentUserVar } from "@cache/cache";
import Link from "@components/link/link";
import { classNames } from "@helpers/css";
import { assertNonNull, getUrl, toWithBackground } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const StaleGoalInsight = ({
  selectedUser,
  insight,
  onDismissInsight,
}: {
  selectedUser: BasicUser;
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const location = useLocation<TFLocationState>();
  const currentUser = currentUserVar();
  const report = useMemo(() => assertNonNull(insight.report), [insight]);
  const artifact = useMemo(
    () =>
      assertNonNull(
        insight.artifact?.__typename === "GoalArtifactNode"
          ? insight.artifact
          : null
      ),
    [insight]
  );
  const [isOpeningCheckinDialog, setIsOpeningCheckinDialog] = useState(false);
  const artifactUrl = getUrl({
    artifactId: artifact.id,
    artifactType: artifact.artifactType,
  });
  return (
    <InsightLayout
      user={report}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          {currentUser.id === selectedUser.id
            ? "You have "
            : `${selectedUser.name} has `}
          a stale goal:{" "}
          <Link
            className={classNames("text-sm text-blue-link flex-1 break-word")}
            to={toWithBackground({
              pathname: artifactUrl,
              location,
            })}
          >
            {artifact.title}
          </Link>
        </div>
      </div>
      <div>
        {isOpeningCheckinDialog && (
          <CheckinDialog
            artifact={artifact}
            onClose={() => setIsOpeningCheckinDialog(false)}
          />
        )}
        <InsightButton onClick={() => setIsOpeningCheckinDialog(true)}>
          Update progress
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default StaleGoalInsight;
