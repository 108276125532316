import { gql } from "@apollo/client";

export default gql`
  mutation reorderTopicMutation(
    $afterTopicId: Int
    $beforeTopicId: Int
    $topicId: Int!
  ) {
    reorderTopic(
      afterTopicId: $afterTopicId
      beforeTopicId: $beforeTopicId
      topicId: $topicId
    ) {
      topic {
        id
      }
      afterTopic {
        id
      }
      beforeTopic {
        id
      }
      meeting {
        id
      }
    }
  }
`;
