import { Node, mergeAttributes } from "@tiptap/core";
import { Editor, ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";

import { currentUserVar } from "@cache/cache";
import { getPluginClickOn } from "@components/wysiwyg/helpers";
import { tiptapExtensionPriority } from "@helpers/constants";

import KpiGroupView from "./kpi-group-view";
import KpiPlaceholder from "./kpi-placeholder";
import SelectKpiGroupForm from "./select-kpi-group-form";

const KpiGroupEmbedComponent = ({
  node,
  updateAttributes,
  deleteNode,
  selected,
  editor,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  deleteNode: () => void;
  selected: boolean;
  editor: Editor;
}) => {
  const currentUser = currentUserVar();
  return (
    <NodeViewWrapper className="mb-3">
      {!node.attrs.id && currentUser.id === node.attrs.createdByUser?.id ? (
        <SelectKpiGroupForm
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
          editor={editor}
        />
      ) : !node.attrs.id && currentUser.id !== node.attrs.createdByUser?.id ? (
        <KpiPlaceholder node={node} />
      ) : (
        <KpiGroupView
          selected={selected}
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
          node={node}
          editor={editor}
        />
      )}
    </NodeViewWrapper>
  );
};

const KpiGroupEmbedExtension = Node.create({
  name: "kpi-group-embed",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    // Return an object with attribute configuration
    return {
      id: {
        default: null,
        parseHTML: (node: HTMLAnchorElement) => {
          if (!node.href) {
            return null;
          }
          const url = new URL(node.href);
          if (url.searchParams.get("kpiGroup")) {
            return url.searchParams.get("kpiGroup");
          }
          return null;
        },
      },
      // used to show placeholder when a user creates a kpi in CRDT
      createdByUser: {
        default: null,
      },
      summaryPeriod: null,
      viewMode: "table",
    };
  },

  addOptions() {
    return {
      relatedArtifactId: null,
      topicId: null,
      meetingId: null,
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(KpiGroupEmbedComponent);
  },

  parseHTML() {
    return [
      {
        tag: "a",
        priority: tiptapExtensionPriority.explorerLink,
        getAttrs: (node: HTMLElement | string) => {
          if (node && node instanceof HTMLAnchorElement) {
            if (!node.href) {
              return null;
            }
            const url = new URL(node.href);
            if (url.searchParams.get("kpiGroup")) {
              return null;
            }
            return false;
          }
          return null;
        },
      },
    ];
  },

  renderText({ node }) {
    return `${window.location.origin}/kpis/${node.attrs.id}`;
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      insertKPIGroupEmbed:
        (attrs: any) =>
        ({ chain }: { chain: any }) => {
          return chain().insertContent({ type: this.name, attrs }).run();
        },
    };
  },

  addProseMirrorPlugins() {
    return [getPluginClickOn(this.name)];
  },
});

export default KpiGroupEmbedExtension;
