import clickupLogo from "@static/img/clickup.png";
import githubLogo from "@static/img/github.png";
import hubspotLogo from "@static/img/hubspot.png";
import jiraLogo from "@static/img/jira-icon.png";
import salesforceLogo from "@static/img/salesforce.png";

import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

const IntegrationLogo = ({
  node,
  size = 4,
  className = "",
}: {
  node: any;
  size: number;
  className?: string;
}) =>
  node.__typename === "GithubIssueNode" ? (
    <img
      src={getAssetUrl(githubLogo)}
      alt="GitHub"
      className={classNames(`h-${size} w-${size}`, className)}
    />
  ) : node.__typename === "JiraIssueNode" ? (
    <img
      src={getAssetUrl(jiraLogo)}
      alt="Jira"
      className={classNames(`h-${size} w-${size}`, className)}
    />
  ) : node.__typename === "SalesforceOpportunityNode" ? (
    <img
      src={getAssetUrl(salesforceLogo)}
      alt="Salesforce"
      className={classNames(`h-${size} w-${size}`, className)}
    />
  ) : node.__typename === "ClickupTaskNode" ? (
    <img
      src={getAssetUrl(clickupLogo)}
      alt="Clickup"
      className="block h-4 w-4"
    />
  ) : node.__typename === "HubspotDealNode" ? (
    <img
      src={getAssetUrl(hubspotLogo)}
      alt="Hubspot"
      className="block h-4 w-4"
    />
  ) : null;

export default IntegrationLogo;
