import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { sortBy } from "lodash";
import { Fragment, useCallback, useMemo } from "react";
import {
  GetDashboardInsightsQuery,
  GetDashboardInsightsQueryVariables,
  InsightType,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardInsightsQuery from "../graphql/get-dashboard-insights-query";
import Insight from "./insight";

const requestTypes = [InsightType.FeedbackRequest];

const Insights = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const { data, loading } = useQuery<
    GetDashboardInsightsQuery,
    GetDashboardInsightsQueryVariables
  >(getDashboardInsightsQuery, {
    fetchPolicy: "network-only",
    variables: { personId: selectedUser.id },
    onError: onNotificationErrorHandler(),
  });
  const allInsights = useMemo(() => {
    const userInsights = data?.user?.insights
      ? assertEdgesNonNull(data.user.insights)
      : [];
    return sortBy(userInsights, "type");
  }, [data]);

  const requirementInsights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !!insight.complianceProgram?.id && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const insights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !insight.complianceProgram && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const requests = useMemo(() => {
    return allInsights.filter((insight) => requestTypes.includes(insight.type));
  }, [allInsights]);

  const handleToggleInsightsContainer = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ dashboardInsightsExpanded: isOpen });
    },
    [saveUiPreference]
  );
  const handleToggleRequestsContainer = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ dashboardRequestsExpanded: isOpen });
    },
    [saveUiPreference]
  );
  const handleToggleRequirementsContainer = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ dashboardRequirementsExpanded: isOpen });
    },
    [saveUiPreference]
  );

  return (
    <CollapsibleContainerParent
      title="Requirements & Insights"
      loading={!!data && loading}
    >
      {!data && loading ? (
        <div className="py-4 bg-white rounded-b-lg">
          <Loading size="6" />
        </div>
      ) : (
        <>
          <CollapsibleContainer
            title={`Requirements (${requirementInsights.length})`}
            container={Fragment}
            startOpen={
              uiPreferenceCache.dashboardRequirementsExpanded &&
              requirementInsights.length > 0
            }
            onToggle={handleToggleRequirementsContainer}
          >
            <div className="divide-y bg-amber-50">
              {requirementInsights.map((requirementInsight) => (
                <div key={requirementInsight.id} className="py-2 px-4">
                  <Insight
                    insight={requirementInsight}
                    selectedUser={selectedUser}
                  />
                </div>
              ))}
              {requirementInsights.length === 0 && (
                <div className="py-2 px-4 text-gray-500 text-sm">
                  No requirements.
                </div>
              )}
            </div>
          </CollapsibleContainer>
          <CollapsibleContainer
            title={`Insights (${insights.length})`}
            container={Fragment}
            startOpen={
              uiPreferenceCache.dashboardInsightsExpanded && insights.length > 0
            }
            onToggle={handleToggleInsightsContainer}
          >
            <div className="divide-y bg-amber-50">
              {insights.map((insight) => (
                <div key={insight.id} className="py-2 px-4">
                  <Insight insight={insight} selectedUser={selectedUser} />
                </div>
              ))}
              {insights.length === 0 && (
                <div className="py-2 px-4 text-gray-500 text-sm">
                  No insights
                </div>
              )}
            </div>
          </CollapsibleContainer>
          <CollapsibleContainer
            title={`Requests (${requests.length})`}
            container={Fragment}
            roundedBottom={true}
            startOpen={
              uiPreferenceCache.dashboardRequestsExpanded && requests.length > 0
            }
            onToggle={handleToggleRequestsContainer}
          >
            <div className="divide-y rounded-b-lg bg-amber-50">
              {requests.map((insight) => (
                <div key={insight.id} className="py-2 px-4">
                  <Insight insight={insight} selectedUser={selectedUser} />
                </div>
              ))}
              {requests.length === 0 && (
                <div className="py-2 px-4 text-gray-500 text-sm">
                  No requests
                </div>
              )}
            </div>
          </CollapsibleContainer>
        </>
      )}
    </CollapsibleContainerParent>
  );
};

export default withErrorBoundary(Insights, {
  fallback: <Error description={"The insights could not be rendered."} />,
});
