import { ReactElement, useState } from "react";

import ArtifactCreationDialog, {
  ArtifactCreationDialogFormType,
} from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { UiPreferenceCache } from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Layout from "@components/layout/layout";

const MeetingSidebarSection = ({
  titleLabel,
  // collapsibleFilterName,
  // toggledAssignee,
  // toggledJustMeeting,
  // isFormalOneonone,
  createArtifactFormOptions,
  // subject,
  // filters,
  expandedUiPreferenceKey,
  children,
}: {
  titleLabel: "recognition" | "goal" | "action item" | "feedback";
  collapsibleFilterName: "Recognitions" | "Goals" | "ActionItems" | "Feedback";
  toggledAssignee: boolean;
  toggledJustMeeting: boolean;
  isFormalOneonone: boolean;
  newSidebarDesign: boolean;
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  createArtifactFormOptions: Partial<ArtifactCreationDialogFormType>;
  subject?: {
    id: number;
    firstName: string;
    email: string;
  } | null;
  filters?: ReactElement | ReactElement[];
  children: ReactElement | ReactElement[];
}) => {
  const label = useLabel();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);
  // const { saveUiPreference } = useUiPreferenceCache();

  // const handleToggleJustMePreference = (
  //   button: ToggleButtonGroupType<boolean>
  // ) => {
  //   const justMe = button.value;
  //   if (isFormalOneonone) {
  //     saveUiPreference({
  //       [`oneonone${collapsibleFilterName}JustMe`]: justMe,
  //     });
  //   } else {
  //     saveUiPreference({
  //       [`meeting${collapsibleFilterName}JustMe`]: justMe,
  //     });
  //   }
  // };

  // const handleToggleJustMeetingPreference = (
  //   button: ToggleButtonGroupType<boolean>
  // ) => {
  //   const justMeeting = button.value;
  //   if (isFormalOneonone) {
  //     saveUiPreference({
  //       [`oneonone${collapsibleFilterName}JustMeeting`]: justMeeting,
  //     });
  //   } else {
  //     saveUiPreference({
  //       [`meeting${collapsibleFilterName}JustMeeting`]: justMeeting,
  //     });
  //   }
  // };

  // const assigneeLabel =
  //   isFormalOneonone && subject ? subject.firstName || subject.email : "Me";

  return (
    <>
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={createArtifactFormOptions}
          onClose={() => setIsShowingCreateDialog(false)}
        />
      )}
      <Layout.SidebarSection
        title={label(titleLabel, { pluralize: true, capitalize: true })}
        expandedUiPreferenceKey={expandedUiPreferenceKey}
        aria-label={`Meeting ${titleLabel}s sidebar`}
        options={[
          {
            label: `Create ${label(titleLabel)}`,
            onClick: ({ syntheticEvent }) => {
              syntheticEvent.preventDefault();
              syntheticEvent.stopPropagation();
              setIsShowingCreateDialog(true);
            },
          },
        ]}
      >
        {children}
      </Layout.SidebarSection>
    </>
  );
};

export default MeetingSidebarSection;
