import { gql } from "@apollo/client";

import MeetingViewMeetingNodeFragment from "./meeting-view-meeting-node-fragment";
import TopicNodeFragment from "./topic-node-fragment";

export default gql`
  ${TopicNodeFragment}
  ${MeetingViewMeetingNodeFragment}
  query getMeetingHistoryQuery(
    $meetingGroupId: Int
    $flowId: Int
    $beforeDatetime: DateTime
    $after: String
    $limit: Int!
    $search: String!
  ) {
    search(
      searchTerm: $search
      first: $limit
      after: $after
      options: {
        meetingsBeforeDatetime: $beforeDatetime
        resultTypes: { meetings: true }
        meetingGroupId: $meetingGroupId
        flowId: $flowId
      }
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ... on MeetingWithRelevantSectionsNode {
            relevantSections {
              sectionType
              sectionId
            }
            meeting {
              ...MeetingViewMeetingNodeFragment
            }
          }
        }
      }
    }
  }
`;
