import { twMerge } from "tailwind-merge";
import { GoalScope } from "types/graphql-schema";

export const classNames = (
  ...classes: ReadonlyArray<string | boolean | null | undefined>
) => {
  return twMerge(classes.filter(Boolean).join(" "));
};

export const buttonBorderClassName =
  "shadow-sm border border-gray-300 rounded-md";

export const inputBorderClassName =
  "shadow-inner border border-gray-300 rounded-md";

export const primaryInputBorderClassName =
  "shadow-sm border border-transparent rounded-md hover:border-gray-300";

export const inputFocusClassName =
  "focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500";

export const getGoalTextColorClassName = (scope: GoalScope) => {
  let goalColorClassName = "text-blue-500";
  if (scope === GoalScope.Team) {
    goalColorClassName = "text-[#4C34E0]";
  }
  if (scope === GoalScope.Organization) {
    goalColorClassName = "text-[#A42CDC]";
  }
  if (scope === GoalScope.Career) {
    goalColorClassName = "text-[#0D8494]";
  }
  return goalColorClassName;
};

export const listBoxButtonClassName = ({ disabled }: { disabled: boolean }) =>
  classNames(
    "px-2 py-1 text-left text-gray-800 text-sm rounded flex items-center gap-1.5",
    disabled ? "" : "hover:bg-gray-100"
  );
