import {
  DocumentDuplicateIcon,
  DotsVerticalIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import {
  ControlledMenu,
  ControlledMenuProps,
  MenuDivider,
  MenuItem,
} from "@szhsin/react-menu";
import { Menu } from "@szhsin/react-menu";
import { Editor } from "@tiptap/core";
import { noop } from "lodash";
import { useLocation } from "react-router-dom";
import {
  TopicDataFragmentFragment,
  TopicNodeFragmentFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import { useLink } from "@components/link/link";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import { classNames } from "@helpers/css";
import { getUrl, parseStringToJSON, toWithBackground } from "@helpers/helpers";

import AddToSubMenu from "./components/add-to-meetings";
import CopyMenuItem from "./components/copy";
import DeleteMenuItem from "./components/delete";
import DuplicateToCurrentMeetingMenuItem from "./components/duplicate-to-current-meeting";
import TogglePersonalNotesMenuItem from "./components/toggle-personal-notes";
import ToggleSharedNotesMenuItem from "./components/toggle-shared-notes";

const TopicDropdownMenu = ({
  topic,
  meeting,
  meetingGroup,
  anchorPoint,
  portal = true,
  size = "6",
  className = "",
  isContextMenu = false,
  currentPageMeeting = null,
  onToggleMenu,
  isInSidebar = false,
  onDelete = noop,
  ...menuProps
}: ControlledMenuProps & {
  className?: string;
  topic: TopicNodeFragmentFragment | TopicDataFragmentFragment;
  meeting?: any;
  meetingGroup?: any;
  size?: string;
  isContextMenu?: boolean;
  currentPageMeeting?: any;
  onToggleMenu?: any;
  isInSidebar?: boolean;
  onDelete?: any;
}) => {
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const topicUrl = getUrl({
    meetingGroupId: meetingGroup?.id,
    meetingId: meeting?.id,
    topicId: topic.id,
  });
  const linkTo = toWithBackground({
    pathname: topicUrl,
    location,
  });
  const canEditTopic = topic.canUpdate.permission && !topic.isMandatory;

  const handleOpenSidebar = () => {
    link.redirect(linkTo);
  };

  const previousNotes =
    topic.previousTopic &&
    !isEmptyValue(parseStringToJSON(topic.previousTopic.discussionNotes))
      ? parseStringToJSON(topic.previousTopic.discussionNotes)
      : null;

  const handleClickCopyPreviousNotes = () => {
    const el = document.getElementById(`editor-topic-${topic.id}`);
    if (el && previousNotes) {
      const editorContainer = el.querySelector(
        '[aria-label="Meeting topic shared notes"] .js-topic-discussion-notes-input'
      ) as Element & { editor?: Editor };
      if (
        previousNotes &&
        editorContainer.editor &&
        (editorContainer.editor.isEmpty ||
          (!editorContainer.editor.isEmpty &&
            window.confirm(
              "Confirm you want to replace your current notes with the previous meeting notes?"
            )))
      ) {
        editorContainer.editor.chain().setContent(previousNotes).run();
      }
    }
  };

  const items = (
    <>
      {!isInSidebar && (
        <MenuItem className="text-sm" onClick={handleOpenSidebar}>
          <ExternalLinkIcon
            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          Open topic sidebar
        </MenuItem>
      )}
      <CopyMenuItem topic={topic} />

      {topic.includesSharedNotes && previousNotes && (
        <>
          <MenuDivider />
          <MenuItem className="text-sm" onClick={handleClickCopyPreviousNotes}>
            <DocumentDuplicateIcon
              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
            Insert previous notes
          </MenuItem>
        </>
      )}

      {canEditTopic && (
        <>
          <MenuDivider />
          <ToggleSharedNotesMenuItem topic={topic} meeting={meeting} />
          <TogglePersonalNotesMenuItem topic={topic} meeting={meeting} />
        </>
      )}

      {meetingGroup &&
        meeting &&
        meeting.isRecurring &&
        currentPageMeeting &&
        meeting.id === currentPageMeeting.id && <MenuDivider />}
      {meetingGroup &&
        meeting &&
        meeting.isRecurring &&
        currentPageMeeting &&
        meeting.id === currentPageMeeting.id && (
          <AddToSubMenu topic={topic} meetingGroup={meetingGroup} />
        )}
      {meetingGroup &&
        meeting &&
        currentPageMeeting &&
        meeting.id !== currentPageMeeting.id && (
          <DuplicateToCurrentMeetingMenuItem
            topic={topic}
            meeting={meeting}
            meetingGroup={meetingGroup}
            currentPageMeeting={currentPageMeeting}
          />
        )}

      {topic.canDelete.permission && !topic.isMandatory && (
        <>
          <MenuDivider />
          <DeleteMenuItem topic={topic} meeting={meeting} onDelete={onDelete} />
        </>
      )}
    </>
  );

  return isContextMenu ? (
    <ControlledMenu
      {...menuProps}
      portal={portal}
      transition={false}
      className="z-dropdown fs-unmask"
      anchorPoint={anchorPoint}
      onClose={() => onToggleMenu(false)}
    >
      {items}
    </ControlledMenu>
  ) : (
    <Menu
      portal={portal}
      className="z-dropdown fs-unmask"
      align="end"
      transition={false}
      aria-label="Topic dropdown menu list"
      menuButton={
        <button
          className={classNames("text-gray-400 rounded", className)}
          data-testid="topic-dropdown-button"
          aria-label="Topic dropdown menu button"
        >
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon
            className={`h-${size} w-${size}`}
            aria-hidden="true"
          />
        </button>
      }
    >
      {items}
    </Menu>
  );
};
export default TopicDropdownMenu;
