import { useMutation } from "@apollo/client";
import { Editor } from "@tiptap/react";
import { sortBy } from "lodash";
import { useRef } from "react";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNullWithStringId } from "@helpers/helpers";

import toggleTopicIndividualNoteVisibilityMutation from "../graphql/toggle-topic-individual-note-visibility-mutation";
import IndividualNotes from "./individual-notes";

const IndividualNotesContainer = ({
  topic,
  meeting,
  meetingGroup,
  searchQuery = "",
  className = "",
}: {
  topic: any;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroup: any;
  searchQuery?: string;
  className?: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const currentNotesRef = useRef<any>(null);
  const currentUser = currentUserVar();
  const [toggleIndividualNoteVisibility, { loading }] = useMutation(
    toggleTopicIndividualNoteVisibilityMutation
  );

  const enabledNotes = topic.individualNotes.edges.filter(
    ({ node: individualNote }: { node: any }) => individualNote.enabled
  );
  const participantIdsWithNotes = enabledNotes.map(
    ({ node: individualNote }: { node: any }) => individualNote.creator.id
  );
  const currentUserNote = topic.individualNotes.edges.find(
    ({ node }: { node: any }) => node.creator.id === currentUser.id
  )?.node;
  const currentUserNoteHidden = currentUserNote?.hidden || false;

  const handleToggleIndividualNotes = () => {
    if (ref.current) {
      const el = ref.current?.querySelector(
        ".ProseMirror.js-topic-discussion-notes-input"
      ) as Element & { editor: Editor };
      if (el.editor) {
        currentNotesRef.current = el.editor?.getJSON();
      }
    }
    const hidden = !currentUserNoteHidden;
    toggleIndividualNoteVisibility({
      variables: {
        topicIds: [topic.id],
        hidden,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleWebsocketSynced = ({ state }: { state: any }) => {
    if (state && currentNotesRef.current !== null) {
      const el = ref.current?.querySelector(
        ".ProseMirror.js-topic-discussion-notes-input"
      ) as Element & { editor: Editor };
      if (el.editor) {
        if (
          JSON.stringify(el.editor.getJSON()) !==
          JSON.stringify(currentNotesRef.current)
        ) {
          el.editor
            .chain()
            .clearContent()
            .setContent(currentNotesRef.current)
            .run();
          currentNotesRef.current = null;
        }
      }
    }
  };

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  return (
    <div
      ref={ref}
      className={classNames("flex flex-col gap-4 empty:hidden", className)}
    >
      {sortBy(
        participants,
        (participant) => participant.user?.id !== currentUser.id // put current user first.
      )
        .filter(
          (participant) =>
            !!participant.user?.id &&
            (participantIdsWithNotes.includes(participant.user?.id) ||
              meetingGroup.isFormalOneonone)
        )
        .map((participant) =>
          !participant.user ? null : (
            <div
              key={participant.id}
              className={classNames(
                "rounded-lg border-2",
                currentUser.id === participant.user?.id &&
                  topic.canUpdate.permission
                  ? "border-indigo-200"
                  : "border-gray-200 bg-gray-50"
              )}
            >
              <div className="ml-5 mr-4 text-xs text-gray-500 mt-1 mb-1 flex items-center justify-between gap-4">
                <div className="flex-1 flex items-center gap-1 tracking-tight">
                  <Avatar user={participant.user} size={4} />
                  <span>{participant.user?.name}</span>
                </div>
                {currentUser.id === participant.user?.id &&
                  topic.canUpdate.permission && (
                    <div className="flex items-start gap-1">
                      {loading && (
                        <div className="flex items-center">
                          <Loading mini size="4" />
                        </div>
                      )}
                      <Tooltip text="This makes your notes only visible to you. The other participants will not see this note unless you share your screen.">
                        <label className="rounded-b flex items-center gap-1 text-2xs tracking-tight">
                          <input
                            disabled={loading}
                            type="checkbox"
                            checked={currentUserNoteHidden}
                            onChange={handleToggleIndividualNotes}
                          />
                          <div>Make private</div>
                        </label>
                      </Tooltip>
                    </div>
                  )}
              </div>
              <IndividualNotes
                currentUserNoteHidden={
                  participant.user?.id === currentUser.id
                    ? currentUserNoteHidden
                    : false
                }
                participant={participant}
                topic={topic}
                meeting={meeting}
                meetingGroup={meetingGroup}
                searchQuery={searchQuery}
                onWebsocketSynced={handleWebsocketSynced}
              />
            </div>
          )
        )}
    </div>
  );
};

export default IndividualNotesContainer;
