import { PlusCircleIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { ArtifactType } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import Error from "@components/error/error";

import getDashboardRecognitionsQuery from "../graphql/get-dashboard-recognitions-query";
import RecognitionsCollapsibleContainer from "./recognitions-collapsible-container";

const Recognitions = ({
  selectedUser,
  createdBetweenDates,
}: {
  selectedUser: BasicUser;
  createdBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);
  const label = useLabel();

  return (
    <div aria-label="Dashboard recognition container">
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Recognition,
            recognitionRecipients:
              selectedUser.id !== currentUser.id ? [selectedUser] : [],
          }}
          onClose={() => setIsShowingCreateDialog(false)}
          refetchQueries={[getDashboardRecognitionsQuery]}
        />
      )}
      <div>
        <CollapsibleContainerParent
          title={
            selectedUser.id === currentUser.id
              ? label("recognition", { pluralize: true, capitalize: true })
              : `${selectedUser.firstName || selectedUser.name}'s ${label(
                  "recognition",
                  {
                    pluralize: true,
                    capitalize: true,
                  }
                )}`
          }
          titleLink="/recognition-board"
          rightSide={
            <Button
              className="shrink-0"
              icon
              theme={buttonTheme.iconGray}
              onClick={() => setIsShowingCreateDialog(true)}
              tooltip={`Create ${label("recognition")}`}
            >
              <PlusCircleIcon className="h-6 w-6" />
            </Button>
          }
        >
          <RecognitionsCollapsibleContainer
            title={`${label("recognition", { capitalize: true })} received`}
            filters={{
              recognitionRecipient: selectedUser.id,
              asUser: selectedUser.id,
              first: 5,
              createdBetweenDates,
            }}
            localStorageKey="dashboardRecognitionReceivedExpanded"
          />
          <RecognitionsCollapsibleContainer
            title={`${label("recognition", { capitalize: true })} given`}
            filters={{
              createdBy: selectedUser.id,
              asUser: selectedUser.id,
              first: 5,
              createdBetweenDates,
            }}
            localStorageKey="dashboardRecognitionGivenExpanded"
            roundedBottom
          />
        </CollapsibleContainerParent>
      </div>
    </div>
  );
};

export default Sentry.withErrorBoundary(Recognitions, {
  fallback: <Error description={"The recognitions could not be rendered."} />,
});
