import { gql } from "@apollo/client";

export default gql`
  fragment MeetingAskAIChatMessage on ChatMessageNode {
    id
    content
    role
    autogenerated
    actionItems {
      edges {
        node {
          id
          title
          assignee {
            name
          }
        }
      }
    }
    goals {
      edges {
        node {
          id
          title
        }
      }
    }
  }

  query getMeetingChatSessionMessagesQuery($chatSessionId: Int!) {
    chatSession(chatSessionId: $chatSessionId) {
      id
      messages {
        edges {
          node {
            ...MeetingAskAIChatMessage
          }
        }
      }
    }
  }
`;
