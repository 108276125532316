import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import a from "indefinite";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  GetAllOrganizationTeamsQueryQuery,
  GetAllOrganizationTeamsQueryQueryVariables,
} from "types/graphql-schema";

import getAllOrganizationTeamsQuery from "@apps/artifact/graphql/get-all-organization-teams-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { useNotificationError } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

export type TeamPickerTeam = {
  id: number;
  title: string;
};

const TeamPickerDropdown = ({
  teams,
  onChange,
}: {
  teams: TeamPickerTeam[];
  onChange: (value: TeamPickerTeam) => void;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const [options, setOptions] = useState<
    ComboboxGenericOption<TeamPickerTeam>[]
  >([]);
  const teamIds = teams.map(({ id }) => id);
  const [canLoadData, setCanLoadData] = useState(false);
  const { onError } = useNotificationError();

  const { loading } = useQuery<
    GetAllOrganizationTeamsQueryQuery,
    GetAllOrganizationTeamsQueryQueryVariables
  >(getAllOrganizationTeamsQuery, {
    variables: {
      organizationId: currentOrganization.id,
    },
    skip: !canLoadData,
    onError,
    onCompleted: (data) => {
      const teams = assertEdgesNonNull(data.organization?.teams);
      setOptions(
        uniqBy(teams, "id").map((team) => ({ label: team.title, value: team }))
      );
    },
  });

  const filteredOptions = options.filter(
    (option) => !option.value.id || !teamIds.includes(option.value.id)
  );

  const handleChangeOwner = (option: ComboboxGenericOption<TeamPickerTeam>) => {
    onChange(option.value);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  return (
    <ComboboxGeneric
      aria-label="Team picker dropdown"
      loading={loading}
      width="full"
      value={null}
      options={filteredOptions}
      onChangeValue={handleChangeOwner}
      onClickButton={handlePreloadData}
    >
      {({ setReferenceElement }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className="flex items-center text-sm gap-1 border rounded-lg py-1 px-2"
            onClick={handlePreloadData}
            ref={setReferenceElement}
          >
            <PlusIcon className="h-4 w-4" />
            Pick {a(label("team"))}
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

const TeamPicker = ({
  teams,
  onRemoveTeam,
  onAddTeam,
  canChange,
}: {
  teams: TeamPickerTeam[];
  onRemoveTeam: (recipient: TeamPickerTeam) => void;
  onAddTeam: (option: TeamPickerTeam) => void;
  canChange: boolean;
}) => {
  return (
    <div className="flex-1 flex gap-2 flex-wrap">
      {teams.map((team) => (
        <div
          className="border rounded-lg px-2 py-1 text-sm flex items-center gap-1"
          key={team.id}
        >
          {team.title}
          {canChange && (
            <button
              className="ml-0.5 rounded-full p-0.5 border bg-gray-50 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
              onClick={() => onRemoveTeam(team)}
            >
              <XIcon className="h-3 w-3" />
            </button>
          )}
        </div>
      ))}
      {canChange && <TeamPickerDropdown teams={teams} onChange={onAddTeam} />}
    </div>
  );
};

export default TeamPicker;
