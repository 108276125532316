import { gql } from "@apollo/client";

export default gql`
  query getOrganizationTeamsQuery($organizationId: Int!, $after: String) {
    organization(pk: $organizationId) {
      id
      teams(first: 20, after: $after, orderBy: "title") {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            title
            members {
              totalCount
              edges {
                node {
                  id
                  name
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`;
