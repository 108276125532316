import { useMutation, useQuery } from "@apollo/client";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  RefreshIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import {
  GetMeetingTranscriptQueryQuery,
  GetMeetingTranscriptQueryQueryVariables,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import getMeetingTranscriptQuery from "@apps/meeting-transcription-dialog/graphql/get-meeting-transcript-query";
import Dropdown from "@components/dropdown/dropdown";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteMeetingTranscriptsMutation from "./graphql/delete-meeting-transcripts-mutation";
import PrepGroup from "./prep-group";
import MeetingSummaryGroupTranscription from "./summary-group-transcription";

const MeetingSummary = ({
  meeting,
  meetingGroupId,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
  meetingGroupId: number;
}) => {
  const link = useLink();
  const [meetingSummaryToggled, setMeetingSummaryToggled] = useState(false);
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete the transcript?"
  );
  const skip = !meetingSummaryToggled;

  const [deleteMeetingTranscripts, { loading: loadingDelete }] = useMutation(
    deleteMeetingTranscriptsMutation
  );
  const { data, loading, refetch } = useQuery<
    GetMeetingTranscriptQueryQuery,
    GetMeetingTranscriptQueryQueryVariables
  >(getMeetingTranscriptQuery, {
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
    skip,
  });

  const handleClickDeleteTranscript = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteMeetingTranscripts({
        variables: { meetingId: meeting.id },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirm, deleteMeetingTranscripts, meeting]);

  const handleClickGoToSettings = () => {
    if (meeting?.organization) {
      link.redirect(
        `/settings/organization/${meeting.organization.id}/settings`
      );
    }
  };

  const hasTranscript = !!data?.meeting?.transcript?.id;

  const dropdownOptions = compact([
    hasTranscript && {
      label: "Delete transcript",
      onClick: handleClickDeleteTranscript,
    },
    {
      label: "Settings",
      onClick: handleClickGoToSettings,
    },
  ]);

  return (
    <div className="flex flex-col bg-violet-50 rounded-lg px-3 py-2 gap-2">
      <ConfirmationDialog />
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-2 justify-between">
          <div className="text-base font-medium text-violet-800 flex-1 flex items-center gap-1">
            Meeting summary <SparklesIcon className="h-5 w-5" />
          </div>
          <button
            className="p-1 text-violet-400 hover:bg-violet-100 rounded-full"
            aria-label="Meeting summary toggle"
            onClick={() => setMeetingSummaryToggled(!meetingSummaryToggled)}
            data-toggled={meetingSummaryToggled ? "1" : "0"}
          >
            {meetingSummaryToggled ? (
              <ChevronDownIcon className="h-5 w-5 " />
            ) : (
              <ChevronRightIcon className="h-5 w-5 " />
            )}
          </button>
        </div>
        {loading || loadingDelete ? (
          <div className="p-1">
            <Loading mini size="5" />
          </div>
        ) : (
          meetingSummaryToggled && (
            <div className="flex items-center gap-2">
              <button
                className="p-1 text-violet-400 hover:bg-violet-100 rounded-full"
                aria-label="Meeting summary refresh"
                onClick={() => refetch()}
                disabled={loadingDelete || loading}
              >
                <RefreshIcon className="h-5 w-5" />
              </button>
              {dropdownOptions.length > 0 && (
                <Dropdown
                  options={dropdownOptions}
                  className="text-violet-400 hover:text-violet-600"
                />
              )}
            </div>
          )
        )}
      </div>
      {meetingSummaryToggled && (
        <div
          className={classNames(
            "flex flex-col divide-y mb-2 border border-violet-200 divide-violet-200 rounded-lg bg-white"
          )}
        >
          {loadingDelete ? (
            <PrepGroup title="Summary" description="">
              <div className="mt-1 text-gray-500">Deleting transcript</div>
            </PrepGroup>
          ) : loading && !data ? (
            <PrepGroup title="Summary" description="">
              <div className="mt-1 text-gray-500">Loading transcript</div>
            </PrepGroup>
          ) : data?.meeting ? (
            <MeetingSummaryGroupTranscription
              meeting={data?.meeting}
              meetingGroupId={meetingGroupId}
            />
          ) : (
            <PrepGroup title="Summary" description="">
              <div className="mt-1 text-gray-500">No transcript</div>
            </PrepGroup>
          )}
        </div>
      )}
    </div>
  );
};

export default MeetingSummary;
