import { withErrorBoundary } from "@sentry/react";
import moment from "moment";
import {
  FinalizePermissions,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Error from "@components/error/error";

import FinalizeOneononeToggleSwitch from "./finalize-oneonone-toggle-switch";
import { getFinalizePermissionLabel } from "./prep-oneonone";

const FinalizeOneonone = ({
  meetingId,
  finalizePermission,
  topics,
  canFinalize,
  isFinalized,
  finalizedAt,
  finalizedBy,
  hasIncompleteMandatoryTopics,
}: {
  finalizePermission: FinalizePermissions;
  meetingId: MeetingViewMeetingNodeNewPageFragmentFragment["id"];
  topics: MeetingViewMeetingNodeNewPageFragmentFragment["topics"];
  canFinalize: MeetingViewMeetingNodeNewPageFragmentFragment["canFinalize"];
  isFinalized: MeetingViewMeetingNodeNewPageFragmentFragment["isFinalized"];
  finalizedAt: MeetingViewMeetingNodeNewPageFragmentFragment["finalizedAt"];
  finalizedBy: MeetingViewMeetingNodeNewPageFragmentFragment["finalizedBy"];
  hasIncompleteMandatoryTopics: MeetingViewMeetingNodeNewPageFragmentFragment["hasIncompleteMandatoryTopics"];
}) => {
  const label = useLabel();

  return (
    <div className="-mx-2 px-2">
      <div className="py-3 px-5 border border-indigo-200 bg-indigo-50 rounded-lg text-sm flex gap-4 text-gray-500 items-start">
        <FinalizeOneononeToggleSwitch
          canFinalize={canFinalize}
          meetingId={meetingId}
          topics={topics}
          isFinalized={isFinalized}
          hasIncompleteMandatoryTopics={hasIncompleteMandatoryTopics}
          tooltip={`Finalize ${label("oneonone")}. ${getFinalizePermissionLabel(
            finalizePermission
          )}`}
        />
        <div>
          <div className="font-medium text-indigo-900">
            Finalize {label("oneonone")}
          </div>
          {isFinalized ? (
            <div className="mt-1 text-xs">
              The {label("oneonone")} has been finalized{" "}
              {finalizedAt ? (
                <span>
                  on{" "}
                  <time
                    dateTime={finalizedAt}
                    className="underline decoration-dotted text-gray-700"
                  >
                    {moment(finalizedAt).format("LLL")}
                  </time>
                </span>
              ) : null}
              {finalizedBy ? ` by ${finalizedBy.name}` : ""}. It cannot be
              edited anymore.
            </div>
          ) : (
            <ul className="mt-1 text-xs">
              {getFinalizePermissionLabel(finalizePermission)} Once finalized,
              the {label("oneonone")} won't be editable anymore.
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(FinalizeOneonone, {
  fallback: <Error description={"This content could not be rendered."} />,
});
