import { useMutation } from "@apollo/client";
import { EyeOffIcon } from "@heroicons/react/outline";
import { flatMap, isInteger } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentGroupDelivery,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  AssessmentQuestionType,
  AssessmentState,
  AssessmentType,
  DeleteAssessmentAnswerMutationMutation,
  DeleteAssessmentAnswerMutationMutationVariables,
  GetAssessmentQuery,
  MultiChoiceAssessmentQuestionNode,
  RangeAssessmentQuestionNode,
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables,
  TextAssessmentQuestionNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  errorNotificationVar,
  successNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import createOrUpdateAssessmentMutation from "../../graphql/create-or-update-assessment-mutation";
import deleteAssessmentAnswerMutation from "../../graphql/delete-assessment-answer-mutation";
import getMyAssessmentsQuery from "../../graphql/get-my-assessments-query";
import getPreviousAssessmentsQuery from "../../graphql/get-previous-assessments-query";
import { bgClassName } from "../../helpers";
import AssessmentQuestionMultiSelectOptions from "../assessment-question-multi-select-options";
import AssessmentQuestionRangeOptions from "../assessment-question-range-options";

type AssessmentAnswer = {
  questionId: number;
  integerAnswer?: number | null;
  textAnswer: string | null;
  choices?: (number | null)[] | null;
  comment: string | null;
};

const AssessmentQuestionItem = ({
  answer,
  index,
  formDisabled,
  question,
  isQuestionWeightingEnabled,
  weight,
  showError,
  totalQuestionWeight,
  onUpdateAnswer,
  onDeleteAnswerOfQuestionId,
}: {
  isQuestionWeightingEnabled: boolean;
  answer?: AssessmentAnswer;
  index: number;
  formDisabled: boolean;
  question:
    | Pick<
        RangeAssessmentQuestionNode,
        | "__typename"
        | "id"
        | "title"
        | "description"
        | "labels"
        | "labelDescriptions"
        | "startValue"
        | "endValue"
        | "questionType"
        | "responseVisibility"
        | "isCommentMandatory"
      >
    | Pick<
        MultiChoiceAssessmentQuestionNode,
        | "__typename"
        | "id"
        | "title"
        | "description"
        | "options"
        | "optionDescriptions"
        | "questionType"
        | "responseVisibility"
        | "isCommentMandatory"
      >
    | Pick<
        TextAssessmentQuestionNode,
        | "__typename"
        | "id"
        | "title"
        | "description"
        | "questionType"
        | "responseVisibility"
        | "isCommentMandatory"
      >;
  weight: number;
  showError: boolean;
  totalQuestionWeight: number;
  onUpdateAnswer: (answer: AssessmentAnswer) => void;
  onDeleteAnswerOfQuestionId: (questionId: number) => void;
}) => {
  const comment = answer?.comment ?? "{}";
  const textAnswer = answer?.textAnswer ?? "{}";
  const choices = answer?.choices;

  // By default answers are not saved and don't have an id,
  // so when rendered them for the first time, the id & content is undefined.
  // Once the user types text, we save it to the DB.
  // When editing a draft answer, it initially comes as undefined then once we have the api response with answers
  // it passes the id and text content therefore we need to update the wysiwyg key or deps to re-render the component.
  // Here we optimize so we re-render the wysiwyg only when user is not focused on it to prevent losing focus while typing.
  const [isFocused, setIsFocused] = useState(false);
  const answerDependencyId = answer?.questionId ? answer?.questionId : 0;
  const [dependencyId, setDependencyId] = useState(answerDependencyId);
  useEffect(() => {
    if (!isFocused) {
      // don't update the dependency if user is focused on field.
      setDependencyId(answerDependencyId);
    }
  }, [isFocused, answerDependencyId]);

  const handleChangeRangeAnswer = (newIntegerAnswer: number) => {
    if (!answer || answer.integerAnswer !== newIntegerAnswer) {
      onUpdateAnswer({
        questionId: question.id,
        integerAnswer: newIntegerAnswer,
        textAnswer,
        choices,
        comment,
      });
    } else {
      onDeleteAnswerOfQuestionId(question.id);
    }
  };

  const questionAnswered =
    ((question.questionType === AssessmentQuestionType.Range &&
      isInteger(answer?.integerAnswer)) ||
      (question.questionType === AssessmentQuestionType.Text &&
        !isEmptyValue(textAnswer)) ||
      (question.questionType === AssessmentQuestionType.Multichoice &&
        choices &&
        choices.length > 0)) &&
    (!question.isCommentMandatory || !isEmptyValue(comment));

  const weightedScore = answer &&
    question.questionType === AssessmentQuestionType.Range && (
      <span>
        Weighted score:{" "}
        {(
          (weight * assertNonNull(answer.integerAnswer)) /
          totalQuestionWeight
        ).toFixed(1)}
      </span>
    );

  let questionContent = null;
  if (question.__typename === "RangeAssessmentQuestionNode") {
    questionContent = (
      <>
        <div className="mt-4">
          <AssessmentQuestionRangeOptions
            answer={answer?.integerAnswer}
            disabled={formDisabled}
            labels={question.labels}
            labelDescriptions={question.labelDescriptions}
            startValue={question.startValue}
            endValue={question.endValue}
            showIntegerValues={isQuestionWeightingEnabled}
            onClickAnswer={handleChangeRangeAnswer}
          />
        </div>
        {answer && (!formDisabled || !isEmptyValue(answer.comment)) && (
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Comment {question.isCommentMandatory ? "*" : ""}
              </div>
              {isQuestionWeightingEnabled && (
                <div className="text-sm text-gray-500">{weightedScore}</div>
              )}
            </div>
            <TextareaWysiwyg
              editable={!formDisabled}
              className="mt-1 bg-white"
              value={comment}
              onChangeValue={(comment) =>
                onUpdateAnswer({
                  questionId: question.id,
                  integerAnswer: assertNonNull(answer?.integerAnswer),
                  textAnswer,
                  choices,
                  comment,
                })
              }
            />
          </div>
        )}
        {answer &&
          formDisabled &&
          isEmptyValue(answer.comment) &&
          isQuestionWeightingEnabled && (
            <div className="mt-2 flex justify-end">
              <div className="text-sm text-gray-500">{weightedScore}</div>
            </div>
          )}
      </>
    );
  } else if (question.__typename === "TextAssessmentQuestionNode") {
    questionContent = (
      <TextareaWysiwyg
        editable={!formDisabled}
        className="mt-4 bg-white"
        value={textAnswer}
        onChangeValue={(text) =>
          onUpdateAnswer({
            questionId: question.id,
            integerAnswer: null,
            textAnswer: text,
            choices,
            comment: null,
          })
        }
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        deps={[dependencyId]}
      />
    );
  } else if (question.__typename === "MultiChoiceAssessmentQuestionNode") {
    questionContent = (
      <>
        <div className="mt-4">
          <AssessmentQuestionMultiSelectOptions
            choices={answer?.choices}
            disabled={formDisabled}
            options={question.options}
            optionDescriptions={question.optionDescriptions}
            onClickAnswer={(newChoices) =>
              onUpdateAnswer({
                questionId: question.id,
                integerAnswer: null,
                textAnswer,
                choices: newChoices,
                comment,
              })
            }
          />
        </div>
        {answer && (!formDisabled || !isEmptyValue(answer.comment)) && (
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Comment {question.isCommentMandatory ? "*" : ""}
              </div>
            </div>
            <TextareaWysiwyg
              editable={!formDisabled}
              className="mt-1 bg-white"
              value={comment}
              onChangeValue={(comment) =>
                onUpdateAnswer({
                  questionId: question.id,
                  integerAnswer: null,
                  textAnswer,
                  choices,
                  comment,
                })
              }
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div
      key={question.id}
      className={classNames(
        "border-b p-6",
        showError && !questionAnswered && "bg-red-50"
      )}
      aria-label={`Assessment question: ${question.title}`}
    >
      <div className="font-bold">
        {index + 1}. {question.title}
      </div>
      {question.responseVisibility ===
        AssessmentQuestionResponseVisibility.HiddenFromSubject && (
        <div className="text-xs text-gray-500 flex items-center">
          <EyeOffIcon className="text-gray-400 h-4 w-4 mr-1 shrink-0" />
          <div className="flex-1">The subject does not see this response</div>
        </div>
      )}
      {!isEmptyValue(question.description) && (
        <TextareaWysiwyg
          className="max-w-max text-gray-400"
          value={question.description}
        />
      )}

      {questionContent}
    </div>
  );
};

const AssessmentRightCol = ({
  myAssessmentsData,
  isLoadingAssessment,
  proposedAnswers,
  onChangeProposedAnswers,
}: {
  proposedAnswers: AssessmentAnswer[];
  onChangeProposedAnswers: (answers: AssessmentAnswer[]) => void;
  myAssessmentsData?: GetAssessmentQuery;
  isLoadingAssessment: boolean;
}) => {
  const debouncedProposedAnswers = useDebounce(proposedAnswers, 200);
  const { assessmentId: assessmentIdParam } = useParams<{
    assessmentId: string;
  }>();
  const assessmentId = parseInt(assessmentIdParam);
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const link = useLink();
  const label = useLabel();
  const location = useLocation<TFLocationState>();
  const backUrl = location.state?.previousPathname || "/assessments";
  const [showErrors, setShowErrors] = useState(false);

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const assessmentGroupId = useMemo(
    () => (assessment ? assessment.group.id : null),
    [assessment]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );
  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );
  const responder = useMemo(() => assessment?.responder ?? null, [assessment]);
  const isSelfAssessment = useMemo(
    () => target?.id === responder?.id,
    [responder, target]
  );

  const submitButtonLabel = useMemo(() => {
    if (
      assessment &&
      assessment.group.assessmentType === AssessmentType.Performance &&
      assessment.group.delivery === AssessmentGroupDelivery.Full &&
      assessment.state === AssessmentState.Draft &&
      !isSelfAssessment
    ) {
      return `Deliver to ${
        target?.firstName ? target.firstName : target?.email
      }`;
    }
    return "Submit";
  }, [assessment, isSelfAssessment, target]);

  const sectionNodes = useMemo(() => {
    if (!assessment) {
      return [];
    }
    return assertEdgesNonNull(assessment.group.sections);
  }, [assessment]);

  const questionNodes = useMemo(() => {
    if (!assessment) {
      return [];
    }
    const allQuestions = flatMap(sectionNodes, (section) =>
      assertEdgesNonNull(section.questions)
    );
    return allQuestions.filter((node) => {
      const { question } = node;
      return (
        question.responses === AssessmentQuestionResponses.Both ||
        (question.responses ===
          AssessmentQuestionResponses.ExcludeSelfAssessment &&
          !isSelfAssessment) ||
        (question.responses ===
          AssessmentQuestionResponses.SelfAssessmentOnly &&
          isSelfAssessment)
      );
    });
  }, [assessment, isSelfAssessment, sectionNodes]);

  const totalQuestionWeight = useMemo(
    () => questionNodes.reduce((sum, node) => sum + node.weight, 0),
    [questionNodes]
  );

  const totalWeightedScore = useMemo(() => {
    return questionNodes
      .filter(
        (questionNode) =>
          questionNode.question.questionType === AssessmentQuestionType.Range
      )
      .reduce((sum, node) => {
        const answer = proposedAnswers.find(
          (answer) => answer.questionId === node.question.id
        );
        if (!answer) {
          return sum;
        }
        if (!answer) {
          return sum;
        }
        return (
          sum +
          (node.weight * assertNonNull(answer.integerAnswer)) /
            totalQuestionWeight
        );
      }, 0);
  }, [proposedAnswers, questionNodes, totalQuestionWeight]);

  const [deleteAssessment] = useMutation<
    DeleteAssessmentAnswerMutationMutation,
    DeleteAssessmentAnswerMutationMutationVariables
  >(deleteAssessmentAnswerMutation);

  const [saveAssessment, { loading: isSavingAssessment }] = useMutation<
    SaveAssessmentMutation,
    SaveAssessmentMutationVariables
  >(createOrUpdateAssessmentMutation);

  useEffect(() => {
    if (
      !assessment ||
      debouncedProposedAnswers.length === 0 ||
      assessment.state === AssessmentState.Submitted
    ) {
      return;
    }
    saveAssessment({
      variables: {
        assessmentId: assertNonNull(assessmentId),
        organizationId: organization.id,
        targetId: assertNonNull(target).id,
        assessmentGroupId: assertNonNull(assessmentGroupId),
        complianceProgramId: assertNonNull(complianceProgram).id,
        answers: debouncedProposedAnswers,
      },
      onError,
    });
    // autosave, only fire to backend when debounced answers changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProposedAnswers]);

  const handleUpdateAnswer = useCallback(
    (newAnswer: AssessmentAnswer) => {
      const newAnswers = [
        ...proposedAnswers.filter(
          (answer) => newAnswer.questionId !== answer.questionId
        ),
        newAnswer,
      ];
      onChangeProposedAnswers(newAnswers);
    },
    [proposedAnswers]
  );

  const handleDeleteAnswer = useCallback(
    (questionId: number) => {
      if (questionId && assessmentId) {
        const newAnswers = proposedAnswers.filter(
          (answer) => answer.questionId !== questionId
        );
        onChangeProposedAnswers(newAnswers);
        deleteAssessment({
          variables: {
            questionId: questionId,
            assessmentId: assessmentId,
          },
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [assessmentId, deleteAssessment, proposedAnswers]
  );

  const areAllQuestionsAnswered = useMemo(() => {
    return questionNodes.every((node) => {
      const answer = proposedAnswers.find(
        (answer) => answer.questionId === node.question.id
      );
      return (
        answer &&
        ((node.question.questionType === AssessmentQuestionType.Range &&
          isInteger(answer.integerAnswer)) ||
          (node.question.questionType === AssessmentQuestionType.Text &&
            answer.textAnswer &&
            !isEmptyValue(answer.textAnswer)) ||
          (node.question.questionType === AssessmentQuestionType.Multichoice &&
            answer.choices &&
            answer.choices.length > 0))
      );
    });
  }, [proposedAnswers, questionNodes]);

  const areAllCommentsEntered = useMemo(() => {
    return questionNodes
      .filter((node) => node.question.isCommentMandatory)
      .every((node) =>
        proposedAnswers.find(
          (answer) =>
            answer.questionId === node.question.id &&
            !isEmptyValue(answer.comment)
        )
      );
  }, [proposedAnswers, questionNodes]);

  const handleSubmitAssessment = useCallback(() => {
    if (!areAllCommentsEntered || !areAllQuestionsAnswered) {
      errorNotificationVar({
        title: "Please answer all questions and comments",
      });
      setShowErrors(true);
      return;
    }
    saveAssessment({
      variables: {
        assessmentId: assertNonNull(assessmentId),
        organizationId: organization.id,
        targetId: assertNonNull(target).id,
        assessmentGroupId: assertNonNull(assessmentGroupId),
        complianceProgramId: assertNonNull(complianceProgram).id,
        answers: proposedAnswers,
        state: AssessmentState.Submitted,
      },
      refetchQueries: [getMyAssessmentsQuery, getPreviousAssessmentsQuery],
      onError,
      onCompleted: () => {
        const assessmentType = assertNonNull(assessment?.group.assessmentType);
        successNotificationVar({
          title: `${assessmentTypeLabels[assessmentType]} ${label(
            "review"
          )} submitted`,
        });
        link.redirect("/assessments");
      },
    });
  }, [
    saveAssessment,
    assessmentId,
    organization,
    target,
    assessmentGroupId,
    complianceProgram,
    proposedAnswers,
    onError,
    assessment,
    link,
    areAllCommentsEntered,
    areAllQuestionsAnswered,
    label,
  ]);

  if (isLoadingAssessment) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessment) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label("review", { capitalize: true })} not found
      </div>
    );
  }

  const canUpdate = assessment.canUpdate?.permission;
  const formDisabled =
    assessment.state === AssessmentState.Submitted || !canUpdate;

  return (
    <div className="flex-1 flex flex-col bg-white rounded-b-lg lg:rounded-r-lg">
      {formDisabled && (
        <div className="p-6">
          <Button to={backUrl}>Back</Button>
        </div>
      )}
      {sectionNodes.map((section) => {
        const sectionQuestions = assertEdgesNonNull(section.questions);
        const hasVisibleQuestions = sectionQuestions.some((question) =>
          questionNodes.map((q) => q.question.id).includes(question.question.id)
        );
        if (!hasVisibleQuestions) {
          return null;
        }
        const heading = section.title ? (
          <div className="p-6">
            <div className="font-bold text-xl">{section.title}</div>
            {section.description && !isEmptyValue(section.description) && (
              <TextareaWysiwyg
                editable={false}
                className="mt-1 bg-white"
                value={section.description}
              />
            )}
          </div>
        ) : null;

        return (
          <Fragment key={section.id}>
            {heading}
            {section.questions.edges.map((edge, index) => {
              const node = assertNonNull(edge?.node);

              const { question, weight } = node;
              if (
                question.responses ===
                  AssessmentQuestionResponses.ExcludeSelfAssessment &&
                isSelfAssessment
              ) {
                return null;
              }
              if (
                question.responses ===
                  AssessmentQuestionResponses.SelfAssessmentOnly &&
                !isSelfAssessment
              ) {
                return null;
              }
              const answer = proposedAnswers.find(
                (answer) => answer.questionId === question.id
              );
              return (
                <AssessmentQuestionItem
                  key={question.id}
                  answer={answer}
                  question={question}
                  weight={weight}
                  totalQuestionWeight={totalQuestionWeight}
                  index={index}
                  onUpdateAnswer={handleUpdateAnswer}
                  onDeleteAnswerOfQuestionId={handleDeleteAnswer}
                  formDisabled={formDisabled}
                  showError={showErrors}
                  isQuestionWeightingEnabled={
                    assessment.group.isQuestionWeightingEnabled
                  }
                />
              );
            })}
          </Fragment>
        );
      })}

      <div className="flex items-center justify-between mt-4 pb-6 px-6">
        <div className="text-sm text-gray-500">
          {assessment.group.isQuestionWeightingEnabled && (
            <span>Total weighted score: {totalWeightedScore.toFixed(1)}</span>
          )}
        </div>
        {!formDisabled && (
          <div className="flex items-center justify-end gap-2">
            {isSavingAssessment && <Loading mini size="4" />}
            <Button
              onClick={() => {
                successNotificationVar({
                  title: `${
                    assessmentTypeLabels[
                      assertNonNull(assessment.group.assessmentType)
                    ]
                  } ${label("review")} saved for later`,
                });
                link.redirect("/assessments");
              }}
              theme={buttonTheme.text}
              disabled={isSavingAssessment}
            >
              Save as draft
            </Button>
            <Button
              disabled={isSavingAssessment}
              type="button"
              theme={buttonTheme.primary}
              onClick={handleSubmitAssessment}
            >
              {submitButtonLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentRightCol;
