import { useState } from "react";

import { TableBodyCell, TableBodyRow } from "@components/table/table";

import TeamEditDialog from "./team-edit-dialog";

const TeamItem = ({ organization, team }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);

  return (
    <TableBodyRow
      data-testid={team.id}
      aria-label={`Org settings team: ${team.title}`}
    >
      <TableBodyCell>
        <div className="flex text-gray-800" title={team.id}>
          {team.title}
        </div>
      </TableBodyCell>
      <TableBodyCell className="text-sm px-4">
        <div className="text-sm px-4 flex">{team.members.totalCount}</div>
      </TableBodyCell>
      <TableBodyCell className="w-16 text-right">
        {showEditDialog && (
          <TeamEditDialog
            organization={organization}
            team={team}
            onClose={() => {
              setShowEditDialog(false);
            }}
          />
        )}
        <button
          className="text-blue-link hover:underline"
          onClick={() => {
            setShowEditDialog(true);
          }}
        >
          Edit
        </button>
      </TableBodyCell>
    </TableBodyRow>
  );
};

export default TeamItem;
