import { useMutation } from "@apollo/client";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import {
  ControlledMenu,
  Menu,
  MenuDivider,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import { useCallback, useState } from "react";
import {
  CancelMeetingMutationMutation,
  CancelMeetingMutationMutationVariables,
  DeleteDraftMeetingMutationMutation,
  DeleteDraftMeetingMutationMutationVariables,
  MeetingEditTypeEnum,
  OnlineVideoConference,
  PermissionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import cancelMeetingMutation from "@apps/meeting-dialog/graphql/cancel-meeting-mutation";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import MeetingTypeDropdownOptions from "@apps/meeting/components/prep/meeting-type-dropdown-options";
import deleteDraftMeetingMutation from "@apps/meeting/graphql/delete-draft-meeting-mutation";
import getMeetingQuery from "@apps/meeting/graphql/get-meeting-query";
import SetAgendaAsTemplateDialog from "@apps/set-agenda-as-template-dialog/set-agenda-as-template-dialog";
import Templates from "@apps/templates/dialog";
import { currentUserVar, successNotificationVar } from "@cache/cache";
import AppLink from "@components/link/link";
import { useNotificationError } from "@components/use-error/use-error";
import { chromeExtensionUrl } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getUrl } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import CalendarEventMenu from "./components/calendar-event-menu";
import CopyUrlMenu from "./components/copy-url-menu";
import CreateTemplateDialog from "./components/create-template-dialog";
import IgnoreMenu from "./components/ignore-menu";
import VideoConferenceMenu from "./components/video-conference-menu";

const MeetingDropdownMenu = ({
  meeting,
  meetingGroup,
  size = "6",
  className = "",
  isContextMenu = false,
  anchorPoint,
  onToggleMenu,
  onMenuChange = () => {},
  onOpenSendNotesDialog = () => {},
  ...menuProps
}: {
  meeting: {
    id: number;
    organizer?: BasicUser | null;
    isRecurring: boolean;
    externalUrl?: string | null;
    videoConferenceUrl?: string | null;
    draft: boolean;
    videoConferenceType?: OnlineVideoConference | null;
    canDelete?: Pick<PermissionNode, "permission"> | null;
    canUpdate?: Pick<PermissionNode, "permission"> | null;
    ignored: boolean;
  };
  meetingGroup: {
    id: number;
    isFormalOneonone: boolean;
    facilitator?: BasicUser | null;
    hasTemplate?: boolean | null;
  };
  size?: number | string;
  className?: string;
  isContextMenu?: boolean;
  anchorPoint?: any;
  onToggleMenu?: (opened: boolean) => void;
  onMenuChange?: ({ open }: { open: boolean }) => void;
  onOpenSendNotesDialog?: () => void;
}) => {
  const {
    ConfirmationDialog: ConfirmationDialogThisMeeting,
    confirm: confirmThisMeeting,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this meeting?"
  );
  const {
    ConfirmationDialog: ConfirmationDialogThisAndFutureMeetings,
    confirm: confirmThisAndFutureMeetings,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this and all future meetings?"
  );
  const {
    ConfirmationDialog: ConfirmationDialogAllMeetings,
    confirm: confirmAllMeetings,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this and all future meetings?"
  );
  const { onError } = useNotificationError();
  const [openCreateTemplateDialog, setOpenCreateTemplateDialog] =
    useState(false);
  const [openTemplateDialog, setOpenTemplatesDialog] = useState(false);
  const [openSetAgendaAsTemplateDialog, setOpenSetAgendaAsTemplateDialog] =
    useState(false);
  const [isShowingMeetingDialogAction, setIsShowingMeetingDialogAction] =
    useState<meetingDialogAction | false>(false);
  const [cancelMeeting] = useMutation<
    CancelMeetingMutationMutation,
    CancelMeetingMutationMutationVariables
  >(cancelMeetingMutation);
  const [deleteDraftMeeting] = useMutation<
    DeleteDraftMeetingMutationMutation,
    DeleteDraftMeetingMutationMutationVariables
  >(deleteDraftMeetingMutation);

  const handleOpenTemplatesDialog = () => {
    setOpenTemplatesDialog(true);
  };
  const handleCreateTemplateDialog = () => {
    setOpenCreateTemplateDialog(true);
  };

  const handleSetAgendaAsTemplate = () => {
    setOpenSetAgendaAsTemplateDialog(true);
  };

  const handleClickDeleteDraftMeeting = useCallback(() => {
    deleteDraftMeeting({
      variables: {
        meetingId: meeting.id,
      },
      update: (cache) => {
        const cacheId = cache.identify(meeting);
        cache.evict({ id: cacheId });
        cache.gc();
      },
      onCompleted: () => {
        successNotificationVar({ title: "Meeting discarded." });
      },
      onError,
    });
  }, [deleteDraftMeeting, meeting, onError]);

  const handleClickCancelMeeting = useCallback(
    (editType: MeetingEditTypeEnum) => {
      cancelMeeting({
        variables: {
          editType,
          meetingId: meeting.id,
        },
        update: (cache) => {
          const cacheId = cache.identify(meeting);
          cache.evict({ id: cacheId });
          cache.gc();
        },
        onCompleted: () => {
          successNotificationVar({ title: "Meetings cancelled." });
        },
        onError,
      });
    },
    []
  );

  const handleClickCancelThisMeeting = useCallback(async () => {
    const confirmation = await confirmThisMeeting();
    if (confirmation) {
      handleClickCancelMeeting(MeetingEditTypeEnum.SingleEvent);
    }
  }, []);
  const handleClickCancelThisAndFutureMeetings = useCallback(async () => {
    const confirmation = await confirmThisAndFutureMeetings();
    if (confirmation) {
      handleClickCancelMeeting(MeetingEditTypeEnum.ThisEventAndFuture);
    }
  }, []);
  const handleClickCancelAllMeetings = useCallback(async () => {
    const confirmation = await confirmAllMeetings();
    if (confirmation) {
      handleClickCancelMeeting(MeetingEditTypeEnum.AllEvents);
    }
  }, []);

  const organizerId = meeting.organizer?.id;
  const currentUser = currentUserVar();
  const isCurrentUserTheOrganizer = organizerId === currentUser.id;

  const items = (
    <>
      <ConfirmationDialogAllMeetings />
      <ConfirmationDialogThisAndFutureMeetings />
      <ConfirmationDialogThisMeeting />
      {!isContextMenu && meeting && (
        <>
          <MenuItem className={"text-sm"}>
            <AppLink
              to={`${getUrl({ meetingGroupId: meetingGroup.id })}/settings`}
            >
              Edit meeting template
            </AppLink>
          </MenuItem>
          <MenuItem className={"text-sm"} onClick={handleOpenTemplatesDialog}>
            Apply a template...
          </MenuItem>
          <MenuItem
            className={"text-sm block"}
            onClick={handleCreateTemplateDialog}
          >
            <div>Save agenda to template library</div>
          </MenuItem>
          {meeting.isRecurring && (
            <MenuItem
              className={"text-sm block"}
              onClick={handleSetAgendaAsTemplate}
            >
              <div>Reuse this agenda in upcoming meetings</div>
            </MenuItem>
          )}
          <MenuDivider />
        </>
      )}

      {meeting.externalUrl && (
        <CalendarEventMenu externalUrl={meeting.externalUrl} />
      )}
      {meeting.videoConferenceUrl && meeting.videoConferenceType && (
        <VideoConferenceMenu
          videoConferenceUrl={meeting.videoConferenceUrl}
          videoConferenceType={meeting.videoConferenceType}
        />
      )}
      {(meeting.externalUrl || meeting.videoConferenceUrl) && <MenuDivider />}

      <>
        <MenuItem
          className={"text-sm"}
          onClick={() =>
            setIsShowingMeetingDialogAction(meetingDialogAction.readonly)
          }
        >
          View meeting details
        </MenuItem>
        <MenuItem
          className={"text-sm"}
          onClick={() =>
            setIsShowingMeetingDialogAction(meetingDialogAction.edit)
          }
          disabled={!isCurrentUserTheOrganizer}
        >
          <div>
            <div>Edit meeting details</div>
            {!isCurrentUserTheOrganizer && (
              <div className="text-2xs tracking-tight text-gray-400">
                Only the organizer can edit the meeting
              </div>
            )}
          </div>
        </MenuItem>
        {!meeting.draft && meeting.canDelete?.permission && (
          <>
            {meeting.isRecurring ? (
              <SubMenu label="Delete meeting" className={"text-sm"}>
                <MenuItem
                  className={"text-sm"}
                  onClick={handleClickCancelThisMeeting}
                >
                  <div>This meeting only</div>
                </MenuItem>
                <MenuItem
                  className={"text-sm"}
                  onClick={handleClickCancelThisAndFutureMeetings}
                >
                  <div>This and all future meetings</div>
                </MenuItem>
                <MenuItem
                  className={"text-sm"}
                  onClick={handleClickCancelAllMeetings}
                >
                  <div>All meetings</div>
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                className={"text-sm"}
                onClick={handleClickCancelThisMeeting}
              >
                <div>Delete meeting</div>
              </MenuItem>
            )}
          </>
        )}
        {meeting.draft && meeting.canUpdate?.permission && (
          <MenuItem
            className={"text-sm"}
            onClick={handleClickDeleteDraftMeeting}
          >
            <div>Discard draft meeting</div>
          </MenuItem>
        )}
      </>

      <MenuDivider />
      <MeetingTypeDropdownOptions
        meetingGroup={meetingGroup}
        meeting={meeting}
      />

      <MenuDivider />
      <IgnoreMenu meeting={meeting} meetingGroup={meetingGroup} />
      {!isContextMenu && meeting && (
        <>
          <MenuDivider />
          <MenuItem className={"text-sm"}>
            <a
              href={`${getUrl({
                meetingGroupId: meetingGroup.id,
                meetingId: meeting.id,
              })}.md`}
              target="_blank"
              rel="noreferrer"
            >
              View markdown
            </a>
          </MenuItem>
          <CopyUrlMenu meeting={meeting} meetingGroup={meetingGroup} />
          <MenuItem className={"text-sm"} onClick={onOpenSendNotesDialog}>
            Share notes...
          </MenuItem>
        </>
      )}
      <>
        <MenuDivider />
        <MenuItem className={"text-sm"}>
          <a href={chromeExtensionUrl} target="_blank" rel="noreferrer">
            Transcribe meeting
          </a>
        </MenuItem>
      </>
    </>
  );

  // Render
  return (
    <>
      {meeting && meetingGroup && openTemplateDialog && (
        <Templates
          meeting={meeting}
          meetingGroup={meetingGroup}
          onClose={() => setOpenTemplatesDialog(false)}
        />
      )}
      {openCreateTemplateDialog && (
        <CreateTemplateDialog
          meeting={meeting}
          onClose={() => setOpenCreateTemplateDialog(false)}
        />
      )}
      {openSetAgendaAsTemplateDialog && (
        <SetAgendaAsTemplateDialog
          meeting={meeting}
          meetingGroup={meetingGroup}
          onClose={() => setOpenSetAgendaAsTemplateDialog(false)}
        />
      )}
      {isShowingMeetingDialogAction && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meetingGroup.id}
          onClose={() => setIsShowingMeetingDialogAction(false)}
          defaultAction={isShowingMeetingDialogAction}
          onSaved={() => setIsShowingMeetingDialogAction(false)}
          refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
        />
      )}
      {isContextMenu && onToggleMenu ? (
        <ControlledMenu
          {...menuProps}
          portal={true}
          className="z-dropdown fs-unmask"
          anchorPoint={anchorPoint}
          onClose={() => onToggleMenu(false)}
        >
          {items}
        </ControlledMenu>
      ) : (
        <Menu
          onMenuChange={onMenuChange}
          className="z-dropdown fs-unmask"
          portal
          menuButton={
            <button
              className={classNames(
                "flex items-center text-gray-400",
                className
              )}
              aria-label="Meeting dropdown button"
              data-testid="meeting-dropdown-button"
            >
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon
                className={`h-${size} w-${size}`}
                aria-hidden="true"
              />
            </button>
          }
          aria-label="Meeting dropdown list"
        >
          {items}
        </Menu>
      )}
    </>
  );
};

export default MeetingDropdownMenu;
