import { useMutation } from "@apollo/client";
import { PlusCircleIcon } from "@heroicons/react/outline";
import update from "immutability-helper";
import { compact } from "lodash";
import { ReactElement, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  ActionItemCollapsibleFragmentFragment,
  ActionItemState,
  ArtifactType,
  GetActionItemsCollapsibleQueryQueryVariables,
} from "types/graphql-schema";

import ActionItemsCollapsibleContainer from "@apps/action-items-collapsible/action-items-collapsible-container";
import changeActionItemStateMutation from "@apps/action-items-collapsible/graphql/change-action-item-state-mutation";
import ArtifactCreationDialog, {
  ArtifactCreationDialogFormType,
} from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import { getExplorerFiltersUrl } from "@apps/explorer/helpers";
import useLabel from "@apps/use-label/use-label";
import { UiPreferenceCache } from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Button, { buttonTheme } from "@components/button/button";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getWatchedQueries } from "@graphql/client";

import getActionItemsCollapsibleQuery from "./graphql/get-action-items-collapsible-query";
import reorderActionItemMutation from "./graphql/reorder-action-item-mutation";

const firstPageLimit = 5;

const ActionItemsCollapsibles = ({
  containerTitle = "Action items",
  containerRightSide,
  actionItemStates,
  orderContextTypePrefix,
  orderContextId,
  variables,
  filterResults,
  uiPreferenceCacheNamespace,
  path = "",
  creationDialogFormOptions = {},
}: {
  orderContextTypePrefix: string;
  orderContextId: number;
  containerTitle: string;
  variables: any;
  creationDialogFormOptions: Partial<ArtifactCreationDialogFormType>;
  filterResults?: (
    variables: GetActionItemsCollapsibleQueryQueryVariables
  ) => (node: ActionItemCollapsibleFragmentFragment) => boolean;
  uiPreferenceCacheNamespace: keyof UiPreferenceCache;
  actionItemStates: Pick<ActionItemState, "value" | "label">[];
  path?: string;
  containerRightSide?: ReactElement;
}) => {
  const label = useLabel();
  const [isShowingCreateArtifactModal, setIsShowingCreateArtifactModal] =
    useState(false);
  const [changeActionItemState] = useMutation(changeActionItemStateMutation);
  const [reorderActionItem] = useMutation(reorderActionItemMutation);

  const getWatchedQuery = (forState: any) => {
    return getWatchedQueries(getActionItemsCollapsibleQuery).find(
      ({ options }: { options: any }) =>
        options.variables.actionItemState === forState &&
        options.variables.orderContextId === orderContextId &&
        options.variables.orderContextType ===
          `${orderContextTypePrefix}:${forState}`
    );
  };

  const handleSaveActionItemState = (
    actionItemId: any,
    previousStateValue: any,
    matchingActionItemState: any
  ) => {
    return previousStateValue !== matchingActionItemState.value
      ? changeActionItemState({
          variables: {
            artifactId: actionItemId,
            additionalFields: {
              actionItemState: matchingActionItemState.value,
            }, // handle when changing completed state
          },
          optimisticResponse: {
            createOrUpdateArtifact: {
              artifact: {
                id: actionItemId,
                actionItemState: matchingActionItemState.value,
                isComplete: matchingActionItemState.isComplete,
                __typename: "ActionItemArtifactNode",
              },
              __typename: "CreateOrUpdateArtifactMutation",
            },
          },
          onError: onNotificationErrorHandler(),
        })
      : new Promise<void>((resolve) => resolve());
  };

  const handleSaveActionItemOrder = (
    actionItemId: any,
    destinationNewEdges: any,
    destinationIndex: any,
    toState: any
  ) => {
    const afterActionItem =
      destinationIndex === 0
        ? null
        : destinationNewEdges[destinationIndex - 1].node;
    const beforeActionItem =
      destinationIndex === 0 ? destinationNewEdges[1].node : null;
    reorderActionItem({
      variables: {
        artifactId: actionItemId,
        beforeArtifactId: beforeActionItem?.id,
        afterArtifactId: afterActionItem?.id,
        contextType: `${orderContextTypePrefix}:${toState}`,
        contextId: orderContextId,
      },
      optimisticResponse: {
        reorderArtifact: {
          artifact: {
            id: actionItemId,
            __typename: "ActionItemArtifactNode",
          },
          __typename: "ReorderArtifactMutation",
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleDragEnd = ({
    destination,
    source,
    draggableId,
    type,
    reason,
  }: {
    destination: any;
    source: any;
    draggableId: any;
    type: any;
    reason: any;
  }) => {
    if (reason !== "DROP") return;
    if (!destination) return;
    if (type !== "action-item-state") return;
    const fromState = parseInt(source.droppableId);
    const toState = parseInt(destination.droppableId);
    const actionItemId = parseInt(draggableId);
    const matchingActionItemState = actionItemStates.find(
      ({ value }) => value === toState
    );

    const sourceWatchedQuery = getWatchedQuery(fromState);
    const destinationWatchedQuery = getWatchedQuery(toState);
    if (!sourceWatchedQuery || !destinationWatchedQuery) {
      return;
    }
    const sourceCurrentResult = sourceWatchedQuery.getCurrentResult();
    const destinationCurrentResult = destinationWatchedQuery.getCurrentResult();
    const node = sourceCurrentResult.data.artifacts.edges[source.index];
    sourceWatchedQuery.updateQuery(() => {
      const newEdges = update(sourceCurrentResult.data.artifacts.edges, {
        $splice: [[source.index, 1]],
      });
      const diff =
        sourceCurrentResult.data.artifacts.edges.length - newEdges.length;
      return {
        ...sourceCurrentResult.data,
        artifacts: {
          ...sourceCurrentResult.data.artifacts,
          edges: newEdges,
          totalCount: sourceCurrentResult.data.artifacts.totalCount - diff,
        },
      };
    });
    const destinationNewEdges = update(
      destinationCurrentResult.data.artifacts.edges,
      {
        $splice: compact([
          // if within same state, we remove node from source index
          fromState === toState ? [source.index, 1] : null,
          // add node to new index
          [destination.index, 0, node],
        ]),
      }
    );
    destinationWatchedQuery.updateQuery(() => {
      const diff =
        destinationNewEdges.length -
        destinationCurrentResult.data.artifacts.edges.length;
      return {
        ...destinationCurrentResult.data,
        artifacts: {
          ...destinationCurrentResult.data.artifacts,
          edges: destinationNewEdges,
          totalCount: destinationCurrentResult.data.artifacts.totalCount + diff,
        },
      };
    });

    handleSaveActionItemState(
      actionItemId,
      fromState,
      matchingActionItemState
    ).then(() => {
      // no need to reorder if there are less than 2 action items in state container
      if (destinationNewEdges.length < 2) {
        return;
      }
      handleSaveActionItemOrder(
        actionItemId,
        destinationNewEdges,
        destination.index,
        toState
      );
    });
  };

  return (
    <>
      {isShowingCreateArtifactModal && (
        <ArtifactCreationDialog
          formOptions={creationDialogFormOptions}
          onClose={() => setIsShowingCreateArtifactModal(false)}
        />
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <CollapsibleContainerParent
          title={containerTitle}
          titleLink={getExplorerFiltersUrl({
            type: ArtifactType.ActionItem,
            actionItemAssignee: orderContextId,
            actionItemState: actionItemStates.map((s) => s.value),
          })}
          rightSide={
            <div className="flex items-center gap-2">
              {containerRightSide}
              <Button
                className="shrink-0"
                icon
                theme={buttonTheme.iconGray}
                onClick={() => setIsShowingCreateArtifactModal(true)}
                tooltip={`Create ${label("action item")}`}
                aria-label="Collapsible create action item button"
              >
                <PlusCircleIcon className="h-6 w-6" />
              </Button>
            </div>
          }
        >
          {actionItemStates.map((actionItemState, i) => (
            <ActionItemsCollapsibleContainer
              key={actionItemState?.value}
              actionItemState={actionItemState}
              roundedBottom={i === actionItemStates.length - 1}
              uiPreferenceCacheNamespace={uiPreferenceCacheNamespace}
              path={path}
              queryVariables={{
                ...variables,
                after: null,
                first: firstPageLimit,
                orderBy: "custom",
                orderContextType: `${orderContextTypePrefix}:${actionItemState?.value}`,
                orderContextId,
                actionItemState: actionItemState.value,
              }}
              filterResults={filterResults}
            />
          ))}
        </CollapsibleContainerParent>
      </DragDropContext>
    </>
  );
};

export default ActionItemsCollapsibles;
