import { gql } from "@apollo/client";

import ArtifactComponentFragment from "@apps/artifact/graphql/artifact-component-fragment";

export default gql`
  ${ArtifactComponentFragment}
  fragment AskAISessionChatMessage on ChatMessageNode {
    id
    content
    role
    autogenerated
    actionItems {
      edges {
        node {
          id
          ...ArtifactComponentFragment
        }
      }
    }
    goals {
      edges {
        node {
          id
          ...ArtifactComponentFragment
        }
      }
    }
  }

  query getAskAIChatSessionMessagesQuery($chatSessionId: Int!) {
    chatSession(chatSessionId: $chatSessionId) {
      id
      messages {
        edges {
          node {
            id
            ...AskAISessionChatMessage
          }
        }
      }
    }
  }
`;
