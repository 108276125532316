import { gql } from "@apollo/client";

import MeetingViewMeetingNodeNewPageFragment from "@apps/meeting-new/graphql/meeting-view-meeting-node-fragment";
import MeetingViewMeetingNodeFragment from "@apps/meeting/graphql/meeting-view-meeting-node-fragment";
import TopicNodeFragment from "@apps/meeting/graphql/topic-node-fragment";

export default gql`
  ${MeetingViewMeetingNodeFragment}
  ${MeetingViewMeetingNodeNewPageFragment}
  ${TopicNodeFragment}
  query getExtensionMeetingQuery($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      ...MeetingViewMeetingNodeFragment
      ...MeetingViewMeetingNodeNewPageFragment
      meetingGroup {
        id
        allowAdminVisibility
        syncCredentials {
          id
          hasCalendarWriteCredentials
          provider
        }
        organization {
          id
          name
        }
        hasTemplate
      }
    }
  }
`;
