import { useQuery } from "@apollo/client";
import { ExternalLinkIcon, EyeOffIcon } from "@heroicons/react/outline";
import { flatMap } from "lodash";
import moment from "moment";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import {
  AssessmentQuestionResponseVisibility,
  GetAssessmentDeliveryQuery,
  GetAssessmentDeliveryQueryVariables,
} from "types/graphql-schema";

import getAssessmentDelivery from "@apps/assessments/graphql/get-assessment-delivery";
import Avatar from "@components/avatar/avatar";
import CloseButton from "@components/close-button/close-button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Sidebar from "@components/sidebar/sidebar";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, getAssessmentTypeString } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

import { AssessmentDeliveryContent } from "../assessment-delivery";

const AssessmentSidebar = ({
  assessmentDeliveryId,
  onClose,
}: {
  assessmentDeliveryId: number;
  onClose: () => void;
}) => {
  const [showHiddenQuestions, setShowHiddenQuestions] = useState(false);

  const { data, loading } = useQuery<
    GetAssessmentDeliveryQuery,
    GetAssessmentDeliveryQueryVariables
  >(getAssessmentDelivery, {
    variables: { assessmentDeliveryId },
    onError: onNotificationErrorHandler(),
  });
  const assessmentDelivery = data?.assessmentDelivery;
  const excludedAnswerIds = (data?.assessmentDelivery?.excludedAnswerIds ||
    []) as number[];
  const assessmentGroup = useMemo(
    () => (data?.assessmentDelivery ? data.assessmentDelivery?.group : null),
    [data]
  );
  const sectionNodes = useMemo(() => {
    if (!assessmentGroup) {
      return [];
    }
    return assertEdgesNonNull(assessmentGroup.sections);
  }, [assessmentGroup]);

  const questionNodes = useMemo(() => {
    if (!assessmentGroup) {
      return [];
    }
    return flatMap(sectionNodes, (section) =>
      assertEdgesNonNull(section.questions)
    );
  }, [assessmentGroup, sectionNodes]);
  const canUpdate = !!assessmentDelivery?.canUpdate?.permission;

  const hiddenQuestions = questionNodes.filter(
    (questionNode) =>
      questionNode.question.responseVisibility ===
      AssessmentQuestionResponseVisibility.HiddenFromSubject
  );
  const tooltipText = `This page has ${hiddenQuestions.length} ${pluralize(
    "question",
    hiddenQuestions.length
  )} that ${pluralize("is", hiddenQuestions.length)} hidden from ${
    assessmentDelivery?.target?.firstName
  } but visible to you.`;

  const handleClickAssessmentLink = (e: MouseEvent<HTMLAnchorElement>) => {
    if (
      canUpdate &&
      hiddenQuestions.length > 0 &&
      !window.confirm(`Are you sure you want to continue? ${tooltipText}`)
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setShowHiddenQuestions(false);
  }, [assessmentDeliveryId]);

  return (
    <>
      <Sidebar show onClose={onClose}>
        {loading ? (
          <Loading>Loading assessment delivery...</Loading>
        ) : assessmentDelivery ? (
          <div className="px-4 sm:px-6 flex flex-col gap-8 text-sm">
            <div className="flex justify-between items-center">
              <AppLink
                target="_blank"
                to={`/assessments/assessment/delivery/${assessmentDelivery.id}`}
                className="font-medium text-xl tracking-tight hover:underline"
                onClick={handleClickAssessmentLink}
              >
                {getAssessmentTypeString(
                  assessmentDelivery.group.assessmentType
                )}{" "}
                assessment delivered to {assessmentDelivery.target?.firstName}
                <ExternalLinkIcon className="h-4 w-4 ml-1 text-gray-500 inline-block" />
              </AppLink>
              <CloseButton onClick={onClose} />
            </div>
            <div className="w-full gap-2 flex flex-col">
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Subject:
                </div>
                <div className="flex items-center gap-1">
                  <Avatar user={assessmentDelivery.target} size="4" />
                  {assessmentDelivery.target?.name}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Type:
                </div>
                <div className="flex items-center gap-1">
                  {getAssessmentTypeString(
                    assessmentDelivery.group.assessmentType
                  )}{" "}
                  assessment
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Program:
                </div>
                <div>
                  {assessmentDelivery.complianceProgram?.title} due on{" "}
                  {moment(assessmentDelivery.complianceProgram?.dueDate).format(
                    "MMM D, YYYY"
                  )}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Delivery:
                </div>
                <div>
                  {assessmentDelivery.deliveryDatetime
                    ? `Delivered by ${
                        assessmentDelivery.creator?.name
                      } on ${moment(assessmentDelivery.deliveryDatetime).format(
                        "MMM D, YYYY @ h:mma"
                      )}`
                    : "Immediate access"}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Link:
                </div>
                <div className="flex items-center gap-2">
                  <AppLink
                    to={`/assessments/assessment/delivery/${assessmentDelivery.id}`}
                    className="text-blue-link hover:underline flex items-center gap-1"
                    target="_blank"
                    onClick={handleClickAssessmentLink}
                  >
                    <ExternalLinkIcon className="h-4 w-4" />
                    View delivery package
                  </AppLink>
                  {canUpdate && hiddenQuestions.length > 0 && (
                    <Tooltip text={tooltipText}>
                      <div className="tracking-tight text-yellow-700 flex items-center gap-0.5">
                        <EyeOffIcon className="h-4 w-4 text-yellow-600" />(
                        {hiddenQuestions.length})
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="border-b pb-0.5">
                <span className="font-medium text-base">Preview</span>
              </div>

              <div className="flex flex-col gap-6">
                <AssessmentDeliveryContent
                  assessmentDeliveryData={data}
                  canUpdate={false}
                  isDraft={false}
                  summary={assessmentDelivery.summary}
                  showHiddenQuestions={showHiddenQuestions}
                  excludedAnswerIds={excludedAnswerIds}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="px-4 sm:px-6 flex flex-col gap-6 text-sm text-gray-500">
            Assessment delivery not found.
          </div>
        )}
      </Sidebar>
    </>
  );
};

export default AssessmentSidebar;
