import { useQuery } from "@apollo/client";
import moment from "moment";
import { Fragment } from "react";
import {
  GetMeetingRecentlyCompletedAssessmentsQueryQuery,
  GetMeetingRecentlyCompletedAssessmentsQueryQueryVariables,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import getMeetingRecentlyCompletedAssessmentsQuery from "@apps/meeting/graphql/get-meeting-recently-completed-assessments-query";
import useLabel from "@apps/use-label/use-label";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import Link from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

const RecentlyCompletedAssessments = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const label = useLabel();
  const { data } = useQuery<
    GetMeetingRecentlyCompletedAssessmentsQueryQuery,
    GetMeetingRecentlyCompletedAssessmentsQueryQueryVariables
  >(getMeetingRecentlyCompletedAssessmentsQuery, {
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });
  const assessments = data?.meeting?.recentlyCompletedAssessments
    ? assertEdgesNonNull(data.meeting.recentlyCompletedAssessments)
    : [];

  if (assessments.length === 0) {
    return null;
  }

  return (
    <div className="rounded-lg shadow overflow-hidden">
      <CollapsibleContainer
        title={`Recently completed ${label("review", { pluralize: true })} (${
          assessments.length
        })`}
        startOpen={false}
        container={Fragment}
      >
        <div className="divide-y">
          {assessments.map((assessment) => {
            return (
              <div className="pl-4 p-2 text-sm" key={assessment.id}>
                <div>
                  <Link
                    to={`/assessments/assessment/${assessment.id}`}
                    className="text-blue-link hover:underline"
                  >
                    {assessment.responder?.id === assessment.target?.id
                      ? `${assessment.responder?.name} submitted a self-assessement`
                      : `
                    ${assessment.responder?.name} assessed ${assessment.target?.name}`}
                    .
                  </Link>
                </div>
                <div className="text-xs flex justify-between mt-1 text-gray-500">
                  <Link
                    to={`/assessments/assessment/${assessment.id}`}
                    className="hover:underline"
                  >
                    {assessment.complianceProgram?.title}
                  </Link>
                  <span className="text-gray-400">
                    {moment(assessment.submittedDatetime).format("LLL")}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </CollapsibleContainer>
    </div>
  );
};
export default RecentlyCompletedAssessments;
