import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import {
  ChevronDownIcon,
  DotsVerticalIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/outline";
import { Menu, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { GetMeetingGroupQueryQuery } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import MeetingContentAccess from "@components/meeting-content-access/meeting-content-access";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

import updateMeetingGroupMutation from "../graphql/update-meeting-group-mutation";
import {
  MeetingOrTemplateVisibility,
  getMeetingAllowVisibilityLabel as getMeetingContentAccessLabel,
} from "../helpers";

type Props = {
  data: GetMeetingGroupQueryQuery;
  url: string;
  currentMeetingId: number | null;
};
const MeetingPageHeader: React.FC<Props> = ({
  data,
  url,
  currentMeetingId = null,
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const link = useLink();
  const meetingGroup = data.meetingGroup;
  const title = meetingGroup?.title || "";
  useDocumentTitle(title);

  const [updateMeetingGroup, { loading }] = useMutation(
    updateMeetingGroupMutation
  );

  const handleSaveVisibility = ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => {
    updateMeetingGroup({
      variables: {
        meetingGroupId: assertNonNull(meetingGroup).id,
        allowAdminVisibility,
        allowOrgVisibility,
        allowManagementTreeVisibility,
      },
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            allowManagementTreeVisibility,
            allowAdminVisibility,
            allowOrgVisibility,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleClickLink = (path: string) => () => {
    link.redirect(`${url}/${path}`);
  };

  const buttonClassName =
    "bg-gray-200 rounded-lg px-3 py-2 text-xs tracking-tight text-gray-500 flex items-center gap-1.5 shrink-0";
  return (
    <>
      <div className="px-3 sm:px-6 h-14 border-b flex items-center bg-gray-50 sticky top-14 md:top-0 z-horizontalNavbar">
        <header className="w-full fs-mask flex gap-4  justify-between items-center">
          <AppLink
            className="text-xl font-medium text-gray-800 truncate hover:underline"
            to={`${url}${currentMeetingId ? `/${currentMeetingId}` : ""}`}
          >
            {title}
          </AppLink>
          {meetingGroup && (
            <div className="flex gap-2">
              <div className="relative">
                <Popover>
                  <Popover.Button
                    className={classNames(
                      buttonClassName,
                      meetingGroup?.canUpdate?.permission && "hover:bg-gray-300"
                    )}
                    aria-label="Meeting permission dropdown menu button"
                    disabled={!meetingGroup?.canUpdate?.permission}
                  >
                    {loading ? (
                      <Loading mini size="4" />
                    ) : meetingGroup.allowAdminVisibility ||
                      meetingGroup.allowOrgVisibility ? (
                      <LockOpenIcon className="h-4 w-4" />
                    ) : (
                      <LockClosedIcon className="h-4 w-4" />
                    )}
                    <span className="hidden sm:inline">
                      {capitalize(
                        getMeetingContentAccessLabel(
                          {
                            allowManagementTreeVisibility:
                              meetingGroup.allowManagementTreeVisibility,
                            allowAdminVisibility:
                              meetingGroup.allowAdminVisibility,
                            allowOrgVisibility: meetingGroup.allowOrgVisibility,
                          },
                          true
                        )
                      )}
                    </span>
                    {meetingGroup?.canUpdate?.permission && (
                      <ChevronDownIcon className="h-4 w-4" />
                    )}
                  </Popover.Button>
                  <Popover.Panel className="absolute mt-1 right-0 z-dropdown bg-white shadow-lg rounded-md w-96 py-4 px-6 flex flex-col gap-4 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <div className="text-lg font-medium">
                      Change meeting content access
                    </div>
                    <MeetingContentAccess
                      allowManagementTreeVisibility={
                        meetingGroup.allowManagementTreeVisibility
                      }
                      allowAdminVisibility={meetingGroup.allowAdminVisibility}
                      allowOrgVisibility={meetingGroup.allowOrgVisibility}
                      isFormalOneonone={meetingGroup.isFormalOneonone}
                      onChange={handleSaveVisibility}
                    />
                  </Popover.Panel>
                </Popover>
              </div>
              <Menu
                align="end"
                transition
                aria-label="Meeting group dropdown menu list"
                className="text-sm not-prose z-dropdown"
                menuButton={
                  <button
                    className={classNames(
                      "text-gray-400 rounded-lg py-1.5 px-1 hover:bg-black/5"
                    )}
                    aria-label="Meeting group dropdown button"
                  >
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon
                      className={`h-5 w-5`}
                      aria-hidden="true"
                    />
                  </button>
                }
              >
                <MenuItem>
                  <AppLink
                    to={`/meeting/${meetingGroup.id}/settings`}
                    className="flex items-center"
                  >
                    Meeting settings
                  </AppLink>
                </MenuItem>
                <MenuDivider />
                <MenuItem>
                  <AppLink
                    to={`/meeting/${meetingGroup.id}/settings`}
                    className="flex items-center"
                  >
                    Meeting template
                  </AppLink>
                </MenuItem>
                <MenuDivider />

                {currentOrganization.featureFlags.actionItems && (
                  <MenuItem onClick={handleClickLink("action-items")}>
                    Action items
                  </MenuItem>
                )}
                {currentOrganization.featureFlags.goals && (
                  <MenuItem onClick={handleClickLink("goals")}>
                    {label("goal", { pluralize: true, capitalize: true })}
                  </MenuItem>
                )}
                {currentOrganization.featureFlags.decisions && (
                  <MenuItem onClick={handleClickLink("decisions")}>
                    Decisions
                  </MenuItem>
                )}
                {currentOrganization.featureFlags.documents && (
                  <MenuItem onClick={handleClickLink("documents")}>
                    Documents
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
        </header>
      </div>
    </>
  );
};

export default MeetingPageHeader;
