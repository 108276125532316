import { ServerParseError, useMutation, useQuery } from "@apollo/client";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect } from "react";
import {
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables,
  OrganizationRole,
} from "types/graphql-schema";

import getLoggedInUserQuery from "@apps/main/graphql/get-logged-in-user-query";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
} from "@cache/cache";
import Container from "@components/container/container";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import updateUserTimezoneMutation from "./graphql/update-user-timezone-mutation";
import "./index.css";
import LoggedOutRoutes from "./logged-out-routes";
import RefreshUserData from "./refresh-user-data";
import Routes from "./routes";

export default function App() {
  const { loading, error, data } = useQuery<
    GetLoggedInUserQuery,
    GetLoggedInUserQueryVariables
  >(getLoggedInUserQuery, {
    fetchPolicy: "cache-and-network",
    onError: onNotificationErrorHandler(),
  });
  const [updateUserTimezone] = useMutation(updateUserTimezoneMutation);

  const currentUser = data || { me: null, loggedIn: false };
  if (currentUser.me) {
    const organizations =
      currentUser.me.organizations.edges.map((edge) => edge?.node) || [];
    const currentOrganization =
      organizations.find((node) => !node?.personal) || organizations[0] || null;
    const isAdmin =
      currentOrganization?.userMembership?.role ===
      OrganizationRole.Administrator;
    currentUserVar(currentUser.me);
    currentOrganizationVar(currentOrganization);
    isAdminVar(isAdmin);
  }

  if (currentUser.me) {
    Sentry.setUser({ email: currentUser.me.email, id: currentUser.me.id });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  const isLoggedIn = Boolean(data && data.loggedIn);
  const hasTimezone = Boolean(data?.me?.timezone);

  useEffect(() => {
    if (isLoggedIn && !hasTimezone && data?.me) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      updateUserTimezone({
        variables: { userId: data.me.id, timezone: tz },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [isLoggedIn, hasTimezone]);

  const handleDisabledShortcuts = useCallback((event) => {
    // disable OS keyboard shortcut used for inline comment
    if (event.altKey && event.metaKey && event.code === "KeyM") {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleDisabledShortcuts);
    return () => {
      document.removeEventListener("keydown", handleDisabledShortcuts);
    };
  }, [handleDisabledShortcuts]);

  useEffect(() => {}, []);

  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (error) {
    if (
      error.networkError &&
      (error.networkError as ServerParseError).statusCode === 401
    ) {
      return <LoggedOutRoutes />;
    } else {
      return <p>Error :(</p>;
    }
  }

  if (data?.loggedIn) {
    if (!window.Cypress && import.meta.env.PROD && data.me) {
      FullStory.init({ orgId: "o-19V9FT-na1" });
      FullStory.identify(String(data.me.id), {
        displayName: data.me.name,
        email: data.me.email,
        hasSyncedCalendar: data.me.hasSyncedCalendar,
      });
    }
    return (
      <>
        <Routes />
        <RefreshUserData />
      </>
    );
  }
  return <LoggedOutRoutes />;
}
