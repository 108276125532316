import { Popover } from "@headlessui/react";
import {
  CalendarIcon,
  ExclamationIcon,
  LockClosedIcon,
  LockOpenIcon,
  MicrophoneIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import { MouseEvent, useState } from "react";
import {
  GetMeetingGroupNewPageQueryQuery,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  UserStatus,
} from "types/graphql-schema";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import MeetingPresentationDialog from "@apps/meeting-presentation-dialog/meeting-presentation-dialog";
import MeetingContentAccessDropdown from "@apps/meeting-settings/components/content-access-dropdown";
import Avatars from "@components/avatar/avatars";
import Layout from "@components/layout/layout";
import {
  ToggleButtonGroup,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNullWithStringId,
  formatMeetingTimes,
  removeCurrentYear,
} from "@helpers/helpers";
import { pluralize } from "@helpers/string";

import getMeetingQuery from "../graphql/get-meeting-query";
import { getMeetingAllowVisibilityLabel } from "../helpers";
import MeetingDraftPopoverPanel from "./draft-popover-panel";
import AIAssistMeetingBot from "./meeting-sidebar/ai-assist-bot";
import MeetingTimer from "./meeting-timer";
import PreviousMeetingsPopoverPanel from "./previous-meetings-popover-panel";
import ShareNotesDialog from "./share-meeting/share-meeting-notes-dialog";

export enum MeetingViewEnum {
  notes = "Notes",
  summary = "Summary",
}

const MeetingPageHeader = ({
  meetingGroup,
  meeting,
  meetingView,
  onChangeMeetingView,
  isInExtension = false,
}: {
  meetingGroup: NonNullable<GetMeetingGroupNewPageQueryQuery["meetingGroup"]>;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingView: MeetingViewEnum;
  onChangeMeetingView: (option: ToggleButtonGroupType<MeetingViewEnum>) => void;
  isInExtension?: boolean;
}) => {
  const [isShowingPresentationMode, setIsShowingPresentationMode] =
    useState(false);
  const [isShowingMeetingDialogAction, setIsShowingMeetingDialogAction] =
    useState<null | meetingDialogAction>(null);
  const [isShowingShareNotesDialog, setIsShowingShareNotesDialog] =
    useState(false);

  const handleClickOpenMeetingDialog = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsShowingMeetingDialogAction(meetingDialogAction.readonly);
  };

  const handleClickInviteInactiveParticipants = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setIsShowingShareNotesDialog(true);
  };
  const handleShareNotesDialog = () => {
    setIsShowingShareNotesDialog(true);
  };

  const handleCloseInvitationDialog = () => {
    setIsShowingShareNotesDialog(false);
  };

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const inactiveUserEmails = participants
    .filter((node) => node.user?.status === UserStatus.Inactive)
    .map((node) => node.participantEmail);
  const avatars = compact(
    participants.map((node) =>
      node.user
        ? {
            ...node.user,
            disabled: node.status === "declined",
            tooltipSuffix: node.status === "declined" ? " (declined)" : "",
          }
        : null
    )
  );

  const meetingDateStr = meeting.startDatetime
    ? `${removeCurrentYear(
        moment(meeting.startDatetime).format("ll")
      )}, ${formatMeetingTimes(meeting)}`
    : meeting.draft
    ? "Draft, unscheduled"
    : "Unscheduled";
  const meetingDate = meeting.startDatetime ? (
    <span>
      {meeting.draft ? "Draft, " : ""}
      <span className="inline">
        {removeCurrentYear(moment(meeting.startDatetime).format("ll"))}
      </span>
      <span className="hidden @7xl/layout-header:inline">
        , {formatMeetingTimes(meeting)}
      </span>
    </span>
  ) : meeting.draft ? (
    "Draft, unscheduled"
  ) : (
    "Unscheduled"
  );

  return (
    <>
      {isShowingPresentationMode && (
        <MeetingPresentationDialog
          meeting={meeting}
          onClose={() => setIsShowingPresentationMode(false)}
        />
      )}
      {isShowingMeetingDialogAction && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={() => setIsShowingMeetingDialogAction(null)}
          defaultAction={isShowingMeetingDialogAction}
          onSaved={() => setIsShowingMeetingDialogAction(null)}
          refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
        />
      )}
      {isShowingShareNotesDialog && (
        <ShareNotesDialog
          meeting={meeting}
          onClose={handleCloseInvitationDialog}
          isInviteDialog={isShowingShareNotesDialog}
        />
      )}
      <>
        <div className={Layout.headerPaddingClassName}>
          <ToggleButtonGroup<MeetingViewEnum>
            buttons={[
              {
                value: MeetingViewEnum.notes,
                title: MeetingViewEnum.notes,
                active: MeetingViewEnum.notes === meetingView,
              },
              {
                value: MeetingViewEnum.summary,
                title: MeetingViewEnum.summary,
                active: MeetingViewEnum.summary === meetingView,
              },
            ]}
            onClick={onChangeMeetingView}
          />
        </div>

        {meeting.endDatetime && meeting.startDatetime && (
          <div
            className={classNames(
              Layout.headerPaddingClassName,
              "hidden sm:flex items-center empty:hidden"
            )}
          >
            <MeetingTimer
              id={meeting.id}
              endDatetime={meeting.endDatetime}
              startDatetime={meeting.startDatetime}
            />
          </div>
        )}

        <div
          className={classNames(
            "text-sm tracking-tight text-gray-600 relative hidden sm:flex items-center",
            Layout.headerPaddingClassName
          )}
        >
          <Popover className="flex items-center relative">
            <Tooltip text={meetingDateStr}>
              <Popover.Button>
                <span
                  className={classNames(
                    "flex items-center gap-1",
                    meeting.draft &&
                      "rounded-lg bg-amber-100 text-amber-800 px-2 py-0.5"
                  )}
                >
                  <CalendarIcon
                    className={classNames(
                      "h-4 w-4",
                      meeting.draft ? "text-amber-800" : "text-gray-400"
                    )}
                  />
                  {meetingDate}
                </span>
              </Popover.Button>
            </Tooltip>
            {meeting.draft ? (
              <MeetingDraftPopoverPanel meeting={meeting} />
            ) : (
              <PreviousMeetingsPopoverPanel meeting={meeting} />
            )}
          </Popover>
        </div>

        <Tooltip text="Present meeting notes">
          <button
            className={classNames(
              Layout.headerIconButtonClassName,
              "hidden sm:block"
            )}
            onClick={() => setIsShowingPresentationMode(true)}
          >
            <PlayIcon className="h-5 w-5" />
          </button>
        </Tooltip>

        <button
          onClick={handleClickOpenMeetingDialog}
          className={classNames(
            Layout.headerPaddingClassName,
            "hidden sm:block"
          )}
        >
          <Avatars
            users={avatars}
            className={classNames(
              "flex items-center pl-2 shrink-0",
              avatars.length === 1 && "w-6" // otherwise single avatar gets squished
            )}
            avatarClassName="contrast-100 rounded-full ring-2 -ml-2 ring-gray-100 shrink-0"
            extraClassName="w-6 h-6 rounded-full -ml-2 text-2xs flex items-center justify-center z-1 bg-gray-200 text-gray-800"
            size="6"
            max={3}
          />
        </button>

        {!isInExtension && inactiveUserEmails.length > 0 && (
          <Tooltip
            text={`${inactiveUserEmails.length} ${pluralize(
              "guest",
              inactiveUserEmails.length
            )} in this meeting ${pluralize(
              "is",
              inactiveUserEmails.length
            )}n't ${
              inactiveUserEmails.length === 1 ? "a" : ""
            } Topicflow  ${pluralize("user", inactiveUserEmails.length)}`}
          >
            <button
              aria-label="Invite non registered users to meeting button"
              className={classNames(
                Layout.headerIconButtonClassName,
                "hidden sm:flex items-center gap-0.5 text-sm text-yellow-600"
              )}
              onClick={handleClickInviteInactiveParticipants}
            >
              <ExclamationIcon className="h-5 w-5" />
              {inactiveUserEmails.length}
            </button>
          </Tooltip>
        )}

        <MeetingVideoConferenceButton
          meeting={meeting}
          className={classNames(
            Layout.headerIconButtonClassName,
            "hidden sm:block"
          )}
          textClassName="hidden"
          iconClassName="h-5 w-5"
          compact
        />

        {/* Privacy */}
        <div className="relative hidden sm:block">
          <MeetingContentAccessDropdown
            meetingGroup={meetingGroup}
            alignLeft={false}
          >
            <Tooltip
              text={`These people can access the notes of this meeting: ${getMeetingAllowVisibilityLabel(
                {
                  allowManagementTreeVisibility:
                    meetingGroup.allowManagementTreeVisibility,
                  allowAdminVisibility: meetingGroup.allowAdminVisibility,
                  allowOrgVisibility: meetingGroup.allowOrgVisibility,
                }
              )}`}
            >
              <Popover.Button
                className={
                  meetingGroup?.canUpdate?.permission
                    ? Layout.headerIconButtonClassName
                    : Layout.headerDisableIconButtonClassName
                }
                aria-label="Meeting permission dropdown menu button"
                disabled={!meetingGroup?.canUpdate?.permission}
              >
                {meetingGroup.allowAdminVisibility ||
                meetingGroup.allowOrgVisibility ? (
                  <LockOpenIcon className="h-5 w-5" />
                ) : (
                  <LockClosedIcon className="h-5 w-5" />
                )}
              </Popover.Button>
            </Tooltip>
          </MeetingContentAccessDropdown>
        </div>

        {/* Microphone */}
        <div className="relative hidden sm:block">
          <Popover>
            <Tooltip
              text={`Open recording options (
                ${
                  meeting.hasBotInMeeting && meeting.botIsRecording
                    ? `recording`
                    : "not recording"
                })`}
            >
              <Popover.Button
                className={classNames(
                  Layout.headerIconButtonClassName,
                  meeting.hasBotInMeeting &&
                    meeting.botIsRecording &&
                    "text-red-600"
                )}
              >
                <MicrophoneIcon className="h-5 w-5" />
              </Popover.Button>
            </Tooltip>
            <Popover.Panel className="right-0 absolute mt-1 z-dropdown bg-white shadow-lg rounded-md w-96 px-2 flex flex-col gap-4 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              <AIAssistMeetingBot
                meeting={meeting}
                meetingGroup={meetingGroup}
              />
            </Popover.Panel>
          </Popover>
        </div>

        <MeetingDropdownMenu
          meeting={meeting}
          meetingGroup={meetingGroup}
          className="rounded px-0.5 z-dropdown hover:bg-black/5"
          size="5"
          onOpenSendNotesDialog={handleShareNotesDialog}
        />
      </>
    </>
  );
};

export default MeetingPageHeader;
