import { groupBy } from "lodash";
import { MeetingTranscriptFragmentFragment } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { assertEdgesNonNull } from "@helpers/helpers";

import MeetingSummaryGroupTranscriptionSuggestedArtifact from "../prep/summary-group-transcription-suggested-artifact";

export const AIAssistSuggestions = ({
  meeting,
}: {
  meeting: MeetingTranscriptFragmentFragment;
}) => {
  const label = useLabel();
  const suggestedArtifacts = (
    meeting.transcript?.suggestedArtifacts
      ? assertEdgesNonNull(meeting.transcript.suggestedArtifacts)
      : []
  ).filter(({ hidden }: { hidden: boolean }) => !hidden);

  const suggestedArtifactsGroupByType = groupBy(
    suggestedArtifacts,
    "artifactType"
  );

  return (
    <div className="flex flex-col gap-4 px-4 py-3 text-sm">
      {Object.keys(suggestedArtifactsGroupByType).map((artifactType) => (
        <div key={artifactType}>
          <div className="font-medium">
            Suggested {label(artifactType, { pluralize: true })}
          </div>
          <div className="text-gray-500 flex flex-col w-full divide-y divide-gray-100 empty:hidden border-b border-gray-100">
            {suggestedArtifactsGroupByType[artifactType].map(
              (suggestedArtifact) => (
                <MeetingSummaryGroupTranscriptionSuggestedArtifact
                  key={suggestedArtifact.id}
                  meeting={meeting}
                  suggestedArtifact={suggestedArtifact}
                />
              )
            )}
          </div>
        </div>
      ))}

      {suggestedArtifacts.length === 0 && (
        <div className="text-gray-500">No suggestions.</div>
      )}
    </div>
  );
};

export default AIAssistSuggestions;
