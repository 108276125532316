import { Listbox } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import copy from "copy-to-clipboard";
import { sortBy } from "lodash";
import { MouseEvent } from "react";
import { AttendeeRole, UserStatus } from "types/graphql-schema";

import { currentUserVar, successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Select, { SelectOption } from "@components/select/select";
import Tooltip from "@components/tooltip/tooltip";
import { attendeeRole, attendeeStatus } from "@helpers/constants";
import { classNames } from "@helpers/css";

import { meetingDialogInputClassName } from "../meeting-dialog";
import { Attendee } from "./attendee-input";

interface Props {
  validDomains?: string[];
  allowRemoveCurrentUser?: boolean;
  isReadOnly?: boolean;
  attendees: ReadonlyArray<Attendee>;
  facilitatorId?: number | null;
  organizerId?: number;
  showRoleSelector?: boolean;
  isFormalOneonone: boolean;
  onChange: (attendees: Attendee[]) => void;
}

const AttendeeList: React.FC<Props> = ({
  validDomains = [],
  isFormalOneonone,
  facilitatorId,
  allowRemoveCurrentUser = false,
  isReadOnly,
  attendees,
  organizerId,
  showRoleSelector = true,
  onChange,
}) => {
  const currentUser = currentUserVar();
  const attendeesWithExtraData = attendees.map((attendee) => {
    const isExternal = !validDomains.find((validDomain) =>
      attendee.email.endsWith(`@${validDomain}`)
    );
    return {
      ...attendee,
      isExternal,
    };
  });

  const handleRemoveAttendee = (attendeeToRemove: Attendee) => {
    onChange(attendees.filter(({ email }) => attendeeToRemove.email !== email));
  };
  const handleChangeAttendeeRole =
    (attendeeToChange: Attendee) => (option: SelectOption<AttendeeRole>) => {
      const newAttendees = attendees.map((attendee) =>
        attendeeToChange.id === attendee.id
          ? { ...attendee, role: option.value }
          : attendee
      );
      onChange(newAttendees);
    };

  const handleClickAttendee =
    (attendee: Attendee) => (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      copy(
        `${attendee.email}${
          attendee.email === attendee.name ? `` : ` (${attendee.name})`
        }`
      );
      successNotificationVar({
        title: "Participant email copied",
        timeout: 2000,
      });
    };

  const sortedAttendees = sortBy(attendeesWithExtraData, (attendee) => {
    if (attendee.id === facilitatorId) {
      return 1;
    }
    if (attendee.id === organizerId) {
      return 2;
    }
    if (attendee.id === organizerId) {
      return 3;
    }
    if (attendee.role === attendeeRole.required) {
      return 4;
    }
    return 5;
  });

  const externalAttendees = sortedAttendees.filter(
    (attendee) => attendee.isExternal
  );

  return (
    <div
      className={classNames(
        "text-sm flex flex-col gap-1",
        isReadOnly && "pt-0.5"
      )}
    >
      {sortedAttendees.map((attendee, index) => {
        const roleSelectorContent = isFormalOneonone ? (
          <span className="px-1.5 py-1 flex items-center text-gray-400 text-xs">
            {facilitatorId === attendee.id ? "Facilitator" : "Subject"}
          </span>
        ) : organizerId === attendee.id ? (
          <span className="px-1.5 py-1 flex items-center text-gray-400 text-xs">
            Organizer
          </span>
        ) : (
          <div className="ml-2">
            <Select<AttendeeRole>
              value={attendee.role || attendeeRole.optional}
              options={[
                { value: AttendeeRole.Required, label: "Attendee" },
                { value: AttendeeRole.Optional, label: "Optional" },
              ]}
              className="z-dropdown"
              showSelectedIcon={false}
              maxHeight={false}
              placement="bottom-end"
              disabled={isReadOnly}
              onChange={handleChangeAttendeeRole(attendee)}
            >
              {({ selected, setReferenceElement }) => (
                <Listbox.Button
                  className={classNames(
                    meetingDialogInputClassName,
                    "px-1.5 py-1 text-xs",
                    isReadOnly && "hover:bg-white"
                  )}
                  ref={setReferenceElement}
                >
                  {selected?.label}
                </Listbox.Button>
              )}
            </Select>
          </div>
        );
        return (
          <div
            key={`selected-item-${index}`}
            className={classNames(
              "flex pl-1 pr-0.5 py-0.5 text-gray-800 group justify-between rounded",
              validDomains.length > 0 && attendee.isExternal && "bg-yellow-100 "
            )}
          >
            <div className="flex items-center gap-2 min-w-0">
              <span className="relative flex items-center">
                {attendee.participantStatus === attendeeStatus.accepted && (
                  <span className="border-2 border-white bg-emerald-200 text-emerald-700 h-4 w-4 absolute -bottom-1 -right-1 rounded-full flex items-center justify-center">
                    <CheckIcon className="h-2 w-2" />
                  </span>
                )}
                {attendee.participantStatus === attendeeStatus.declined && (
                  <span className="border-2 border-white bg-rose-200 text-rose-700 h-4 w-4 absolute -bottom-1 -right-1 rounded-full flex items-center justify-center">
                    <XIcon className="h-2 w-2" />
                  </span>
                )}
                {attendee.participantStatus === attendeeStatus.tentative && (
                  <span
                    style={{ fontSize: "9px" }}
                    className="font-medium border-2 border-white bg-gray-200 text-gray-500 h-4 w-4 absolute -bottom-1 -right-1 rounded-full flex items-center justify-center"
                  >
                    ?
                  </span>
                )}
                <Avatar user={attendee} size="6" />
              </span>
              <Tooltip
                text={
                  attendee.status === UserStatus.Inactive
                    ? `${attendee.name} is not an active user on Topicflow`
                    : attendee.email !== attendee.name
                    ? attendee.email
                    : ""
                }
              >
                <button
                  type="button"
                  className={classNames(
                    "truncate",
                    attendee.status === UserStatus.Inactive
                      ? "text-gray-500 italic"
                      : ""
                  )}
                  onClick={handleClickAttendee(attendee)}
                >
                  {attendee.name}
                </button>
              </Tooltip>{" "}
            </div>
            <div className="flex items-center">
              {showRoleSelector && roleSelectorContent}
              {!isReadOnly && (
                <div className="w-6 flex justify-end items-center">
                  {(allowRemoveCurrentUser ||
                    attendee.email !== currentUser.email) && (
                    <button
                      className="text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded px-1"
                      type="button"
                      aria-label="Remove participant"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveAttendee(attendee);
                      }}
                    >
                      <XIcon className="h-4 w-4" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      {validDomains.length > 0 && externalAttendees.length > 0 && (
        <div className="mt-2 text-xs text-yellow-700">
          The following guests are from outside your organization:{" "}
          {externalAttendees.map(({ name }) => name).join(", ")}.
        </div>
      )}
    </div>
  );
};

export default AttendeeList;
