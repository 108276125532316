import { ErrorBoundary } from "@sentry/react";
import { Editor } from "@tiptap/core";
import { useInView } from "react-intersection-observer";
import {
  MeetingParticipantNode,
  MeetingViewMeetingNodeFragmentFragment,
  TopicNodeFragmentFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import cache, { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import WYSIWYG from "@components/wysiwyg/wysiwyg";
import { tempCacheIdPrefix } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  parseStringToJSON,
} from "@helpers/helpers";

import { handleEditorPropsOnKeyDown } from "../helpers";

const IndividualNotes = ({
  topic,
  currentUserNoteHidden,
  meeting,
  meetingGroup,
  participant,
  searchQuery,
  onWebsocketSynced,
}: {
  topic: TopicNodeFragmentFragment;
  currentUserNoteHidden: boolean;
  meeting: MeetingViewMeetingNodeFragmentFragment;
  meetingGroup: {
    id: number;
    isFormalOneonone: boolean;
    facilitator?: BasicUser;
  };
  participant: Pick<MeetingParticipantNode, "participantEmail"> & {
    user?: { id: number; firstName: string } | null;
  };
  searchQuery: string;
  onWebsocketSynced: Function;
}) => {
  const currentUser = currentUserVar();
  const isTemporary = String(topic.id).includes(tempCacheIdPrefix);
  const { isFormalOneonone } = meetingGroup;

  const individualNotes = assertEdgesNonNull(topic.individualNotes) || [];
  const individualNote = individualNotes.find(
    ({ creator }) => creator.id === participant?.user?.id
  );
  const note = individualNote?.notes || "";

  // Detect if discussion notes container is visible
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "250px 0px 0px 0px",
  });

  const key = `editor-topic-${topic.id}-${
    currentUserNoteHidden ? "private" : "public"
  }`;
  const canEdit =
    participant.user?.id === currentUser.id && topic.canUpdate.permission;

  let overlayValue = null;
  if (isFormalOneonone && isEmptyValue(note) && topic.linkedTemplateTopic?.id) {
    const defaultNotes =
      meetingGroup.facilitator?.id === participant.user?.id
        ? topic.linkedTemplateTopic.defaultFacilitatorNotes
        : topic.linkedTemplateTopic.defaultSubjectNotes;
    if (defaultNotes) overlayValue = parseStringToJSON(defaultNotes);
  }

  const handleUpdateContent = ({ editor }: { editor: Editor }) => {
    if (canEdit && individualNote) {
      cache.modify({
        id: cache.identify(individualNote),
        fields: {
          notes() {
            return editor.getJSON();
          },
        },
      });
    }
  };

  return (
    <div
      ref={ref}
      className="pl-1 pr-1 py-1"
      aria-label="Meeting topic individual notes"
    >
      {inView && (
        <div>
          {isTemporary ? (
            <div className="pb-4">
              <Loading size="5" />
            </div>
          ) : (
            <ErrorBoundary
              fallback={
                <Error
                  title="An unexpected error occurred."
                  description={
                    "The editor could not be rendered. We have been notified. Please refresh the page."
                  }
                />
              }
            >
              <div id={key} key={key}>
                <WYSIWYG
                  key={key}
                  enableComment={false}
                  value={parseStringToJSON(note)}
                  className={classNames(
                    "text-base min-h-11",
                    // important to have padding so the + hover button on each line is clickable, as well as detecting when to hide the add/drag buttons when cursor leaves the editor
                    // https://github.com/Topicflow/topicflow/pull/1314
                    "-ml-[56px] pl-[72px] py-1 pr-1",
                    "pb-8" // adding bottom padding here so the white space is clickable
                  )}
                  editable={canEdit}
                  placeholder={
                    canEdit
                      ? "Add my notes..."
                      : `${
                          participant.user?.firstName ||
                          participant?.participantEmail ||
                          "Participant"
                        }'s notes.`
                  }
                  overlayValue={overlayValue}
                  showPlaceholderOnlyWhenEditable={false}
                  showPlusButton={canEdit}
                  editorProps={{
                    handleDOMEvents: {
                      keydown: handleEditorPropsOnKeyDown,
                    },
                  }}
                  highlightSearchQuery={searchQuery}
                  uploadVariable={{ topicId: topic.id }}
                  websocketConfig={{
                    socketId: `meeting-${meeting?.id}-participant-${
                      participant.user?.id
                    }${currentUserNoteHidden ? "-private" : ""}`,
                    documentId: `topic-${topic.id}-participant-${
                      participant.user?.id
                    }${currentUserNoteHidden ? "-private" : ""}`,
                    websocketToken: assertNonNull(meeting?.websocketToken),
                    onWebsocketSynced: onWebsocketSynced,
                  }}
                  mentionsConfig={{
                    meetingGroupId: meeting?.meetingGroup?.id,
                    meetingId: meeting?.id,
                  }}
                  extraContext={{
                    topicId: topic.id,
                    relatedTopicId: !currentUserNoteHidden
                      ? topic.id
                      : undefined,
                    meetingId: meeting.id,
                    meetingGroupId: meeting.meetingGroup?.id,
                    organizationId: meeting.organization?.id,
                    meetingDate: meeting.startDatetime
                      ? meeting.startDatetime
                      : undefined,
                  }}
                  onUpdateContent={handleUpdateContent}
                />
              </div>
            </ErrorBoundary>
          )}
        </div>
      )}
    </div>
  );
};

export default IndividualNotes;
