import { gql } from "@apollo/client";

export const MeetingParticipantsNewPageFragment = gql`
  fragment MeetingParticipantsNewPageFragment on MeetingNode {
    participants {
      edges {
        node {
          id
          status
          participantEmail
          role
          user {
            id
            name
            firstName
            lastName
            avatar
            email
            status
          }
        }
      }
    }
  }
`;
