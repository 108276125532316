import { useQuery } from "@apollo/client";
import { uniqBy } from "lodash";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import getUserQuery from "@graphql/get-user-query";
import { graphqlNone } from "@helpers/constants";

import ExplorerComboboxButton from "./explorer-combobox-button";

const ExplorerUserCombobox = ({
  userId,
  prefix = "",
  ariaLabel = "Explorer user combobox",
  onSelectUserId,
  unassignedText = "No assignee",
  className = "",
  hasUnassignedOption = false,
}: {
  userId?: number | null;
  prefix?: string;
  ariaLabel?: string;
  onSelectUserId: (userId: number | null) => void;
  unassignedText?: string;
  allText?: string;
  className: string;
  hasUnassignedOption?: boolean;
}) => {
  const unassignedOption: UserComboboxUserOption = {
    name: unassignedText,
    id: graphqlNone,
    type: UserComboboxOptionType.USER,
  };

  const { data } = useQuery(getUserQuery, {
    fetchPolicy: "cache-first",
    variables: { userId },
    skip: !userId || userId === unassignedOption.id,
    onError: onNotificationErrorHandler(),
  });
  const selectedUser =
    userId === unassignedOption.id
      ? unassignedOption
      : data
      ? { type: UserComboboxOptionType.USER, ...data.user }
      : null;

  const { loading, options, query, setQuery } = useUserComboboxQuery({
    selected: selectedUser,
    emptyOption: hasUnassignedOption ? unassignedOption : undefined,
  });
  const validOptions = uniqBy([...options], "id");

  const handleChangeValue = (option: UserComboboxOption) => {
    onSelectUserId(option.id);
  };

  const handleClearValue = () => {
    onSelectUserId(null);
  };

  return (
    <div className="flex-1 min-w-0">
      <UserCombobox
        aria-label={ariaLabel}
        loading={loading}
        width="full"
        placeholder="All"
        query={query}
        value={selectedUser}
        options={validOptions}
        clearable={!!selectedUser}
        onChangeValue={handleChangeValue}
        onChangeQuery={setQuery}
        onClearValue={handleClearValue}
      >
        {({ value, onClickButton, clearable, setReferenceElement }) =>
          value?.type === UserComboboxOptionType.USER && (
            <ExplorerComboboxButton
              prefix={prefix}
              ariaLabel="Explorer user picker button"
              setReferenceElement={setReferenceElement}
              onClickButton={onClickButton}
              label={value?.name || "All"}
              clearable={clearable}
              onClear={handleClearValue}
              className={className}
              avatarUser={
                value && value.id !== unassignedOption.id ? value : undefined
              }
            />
          )
        }
      </UserCombobox>
    </div>
  );
};

export default ExplorerUserCombobox;
