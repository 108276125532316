import Document from "@tiptap/extension-document";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { EditorContent } from "@tiptap/react";
import { useEffect, useState } from "react";
import { ArtifactType } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import SearchHighlight, {
  updateSearchHighlightInEditor,
} from "@components/wysiwyg/extensions/search-highlight";
import useEditor from "@components/wysiwyg/use-editor";
import { classNames } from "@helpers/css";
import { capitalize } from "@helpers/string";

const ArtifactInput = ({
  loading,
  artifactId,
  artifactType,
  value,
  selected,
  editorIsFocused,
  searchQuery,
  onChangeTitle,
  onKeyDown,
  onFocusInput,
  onBlurInput,
}: {
  loading: boolean;
  artifactId: number;
  artifactType: ArtifactType;
  value: any;
  selected: boolean;
  editorIsFocused: boolean;
  searchQuery: string;
  onChangeTitle: (value: any) => void;
  onKeyDown: () => void;
  onFocusInput: () => void;
  onBlurInput: () => void;
}) => {
  const l = useLabel();
  const placeholder = l(artifactType);
  const [title, setTitle] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const TitleDocument = Document.extend({
    content: "text*",
  });
  const editor = useEditor({
    editable: true,
    extensions: [
      Text,
      TitleDocument,
      SearchHighlight.configure({
        searchQuery,
      }),
      Placeholder.configure({
        placeholder: capitalize(
          loading ? `Loading ${placeholder}...` : `${placeholder}...`
        ),
      }),
    ],
    editorProps: {
      attributes: {
        class: classNames(
          "js-artifact-input",
          "block w-full outline-0 text-base resize-none font-medium",
          "break-words" // needs min-w-0
        ),
        "aria-label": "Artifact title",
      },
    },
    content: value,
  });

  const handleUpdateInput = () => {
    if (editor) {
      const newTitle = editor.getText();
      setTitle(newTitle);
      onChangeTitle(newTitle);
    }
  };

  const handleFocusInput = () => {
    setIsFocused(true);
    onFocusInput();
  };

  const handleBlurInput = () => {
    setIsFocused(false);
    onBlurInput();
  };

  // we use editor events instead of editor options to prevent
  // getting staled prop functions as described here
  // https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-1062712162
  useEffect(() => {
    editor?.on("update", handleUpdateInput);
    return function cleanup() {
      editor?.off("update", handleUpdateInput);
    };
  }, [onChangeTitle, editor]);

  useEffect(() => {
    editor?.on("focus", handleFocusInput);
    return function cleanup() {
      editor?.off("focus", handleFocusInput);
    };
  }, [onFocusInput, editor]);

  useEffect(() => {
    editor?.on("blur", handleBlurInput);
    return function cleanup() {
      editor?.off("blur", handleBlurInput);
    };
  }, [onBlurInput, editor]);

  useEffect(() => {
    // Focus on input when creating an artifact
    const canFocus =
      !loading &&
      !artifactId &&
      !!editor?.view.dom &&
      editorIsFocused &&
      selected;
    if (canFocus) {
      editor.chain().focus("end").run();
    }
  }, [selected, loading, editor]);

  useEffect(() => {
    updateSearchHighlightInEditor(editor, searchQuery);
  }, [searchQuery, editor, value]);

  useEffect(() => {
    if (artifactId && value !== title && !isFocused) {
      setTitle(value);
      editor?.chain().setContent(value).run();
    }
  }, [value, editor]);

  // Do not remove min-w-0, as it prevents long text/url to wrap correctly
  // https://imgur.com/a/S90aplB
  return (
    <div
      className={classNames("flex flex-1 items-center py-0.5 min-w-0")}
      contentEditable={false}
    >
      <EditorContent
        editor={editor}
        className="flex-1 min-w-0"
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default ArtifactInput;
