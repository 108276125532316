import { gql } from "@apollo/client";

export const GoalOverviewGoalFragment = gql`
  fragment GoalOverviewGoalFragment on GoalArtifactNode {
    id
    artifactType
    title
    updated
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    creator {
      id
      name
      avatar
    }
    state
    scope
    ownerCount
    dueDate
    goalVisibility
    status
    startValue
    currentValue
    targetValue
    progress
    progressType
    isStale
    owners(first: 1) {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    activities(first: 1, activityType: goal_checkin) {
      edges {
        node {
          created
        }
      }
    }
    keyResults {
      edges {
        node {
          id
          title
          startValue
          currentValue
          targetValue
          progressType
          assignee {
            id
            name
            avatar
          }
          kpi {
            id
            title
          }
        }
      }
    }
    childGoals {
      edges {
        node {
          id
          ... on GoalArtifactNode {
            title
            artifactType
            state
            scope
            ownerCount
            goalVisibility
            status
            progress
            isStale
            startValue
            currentValue
            targetValue
            progressType
            owners(first: 1) {
              edges {
                node {
                  id
                  name
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default gql`
  ${GoalOverviewGoalFragment}
  query getOverviewGoalsQuery(
    $ownerIds: [Int]
    $contributorIds: [Int]
    $involvingUserIds: [Int]
    $teamIds: [Int]
    $goalScopes: [GoalScope]
    $goalDueBetweenDates: [Date]!
  ) {
    artifacts(
      artifactType: goal
      goalScopes: $goalScopes
      goalOwners: $ownerIds
      goalContributors: $contributorIds
      goalInvolvingUsers: $involvingUserIds
      goalTeams: $teamIds
      goalDueBetweenDates: $goalDueBetweenDates
      orderBy: "objective_alignment"
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ... on GoalArtifactNode {
            ...GoalOverviewGoalFragment
          }
        }
      }
    }
  }
`;
