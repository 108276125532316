import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";
import MeetingTranscriptFragment from "@graphql/meeting-transcript-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  ${CommonRecognitionArtifactFragment}
  ${MeetingTranscriptFragment}
  query getMeetingTranscriptQuery($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      id
      endDatetime
      hasBotInMeeting
      botIsRecording
      botIsWaitingToJoinMeeting
      organization {
        id
        name
        effectivePricingTier
        enableMeetingSummarization
      }
      meetingGroup {
        id
        addTranscriptionBotToMeetingsByDefault
      }
      videoConferenceType
      artifacts(
        createdInMeetingId: $meetingId
        artifactTypes: [action_item, decision, goal, recognition, document]
      ) {
        edges {
          node {
            id
            title
            artifactType
            canDelete {
              permission
            }
            canUpdate {
              permission
            }
            ... on ActionItemArtifactNode {
              isComplete
              actionItemState
              dueDate
              assignee {
                id
                avatar
                name
              }
            }
            ... on GoalArtifactNode {
              ...CommonGoalArtifactFragment
              contributors {
                edges {
                  node {
                    id
                    name
                    avatar
                  }
                }
              }
              activities(first: 1, activityType: goal_checkin) {
                edges {
                  node {
                    created
                  }
                }
              }
            }
            ... on RecognitionArtifactNode {
              ...CommonRecognitionArtifactFragment
            }
            ... on DecisionArtifactNode {
              isDraft
              decision
              decisionState
            }
          }
        }
      }
      ...MeetingTranscriptFragment
    }
  }
`;
