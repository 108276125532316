import { compact } from "lodash";
import { useMemo } from "react";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import useLabel from "@apps/use-label/use-label";
import { isAdminVar } from "@cache/cache";
import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { classNames } from "@helpers/css";

import Assessment from "./components/assessment";
import AssessmentDelivery from "./components/assessment-delivery";
import AssessmentGroups from "./components/assessment-groups";
import AssessmentQuestions from "./components/assessment-questions";
import AssessmentGroupEdit from "./components/edit-assessment-group";
import AssessmentQuestionEdit from "./components/edit-assessment-question";
import MyAssessments from "./components/my-assessments";

const Nav = () => {
  const label = useLabel();
  const tabs = useMemo(
    () =>
      compact([
        {
          name: label("review", { capitalize: true, pluralize: true }),
          id: "home",
        },
        {
          name: `${label("review", {
            capitalize: true,
          })} templates`,
          id: "assessment-templates",
        },
        {
          name: `${label("review", {
            capitalize: true,
          })} questions`,
          id: "assessment-questions",
        },
      ]),
    [label]
  );

  const { navId } = useParams<{ navId: string }>();
  const displayedTabId = navId || tabs[0].id;
  return (
    <div className="mb-6">
      <nav className="-mb-px flex gap-6" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={`/assessments/${tab.id}`}
            className={classNames(
              tab.id === displayedTabId
                ? "border-blue-700 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
            )}
            aria-current={tab.id === displayedTabId ? "page" : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

const Assessments = () => {
  const label = useLabel();
  useDocumentTitle(label("review", { capitalize: true, pluralize: true }));
  const { path } = useRouteMatch();
  const isAdmin = isAdminVar();

  return (
    <div
      aria-label={label("review", { capitalize: true, pluralize: true })}
      className="flex flex-col flex-1 fs-unmask"
    >
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
          to="/assessments"
        >
          {label("review", { capitalize: true, pluralize: true })}
        </AppLink>
      </div>
      <div className="p-6">
        {isAdmin && (
          <div className="mb-6">
            <Switch>
              <Route exact path={[`${path}/:navId`, path]}>
                <Nav />
              </Route>
            </Switch>
          </div>
        )}

        <Switch>
          <Route exact path={[`${path}/home`, `${path}`]}>
            <MyAssessments />
          </Route>
          {!waffle.flag_is_active("new-meeting-page") && (
            <Route exact path={`${path}/assessment/:assessmentId(\\d+)`}>
              <Assessment />
            </Route>
          )}
          <Route
            exact
            path={`${path}/assessment/delivery/:assessmentDeliveryId(\\d+)`}
          >
            <AssessmentDelivery />
          </Route>
          {isAdmin && (
            <>
              <Route exact path={`${path}/assessment-templates`}>
                <AssessmentGroups />
              </Route>
              <Route
                exact
                path={[
                  `${path}/assessment-templates/new`,
                  `${path}/assessment-templates/:assessmentGroupId(\\d+)/edit`,
                  `${path}/assessment-templates/:assessmentGroupId(\\d+)/duplicate`,
                ]}
              >
                <AssessmentGroupEdit />
              </Route>
              <Route exact path={`${path}/assessment-questions`}>
                <AssessmentQuestions />
              </Route>
              <Route
                exact
                path={[
                  `${path}/assessment-questions/new`,
                  `${path}/assessment-questions/:questionId(\\d+)/edit`,
                  `${path}/assessment-questions/:questionId(\\d+)/duplicate`,
                ]}
              >
                <AssessmentQuestionEdit />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default Assessments;
