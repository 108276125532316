import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  GetDashboardGoalsQueryQuery,
  GetDashboardGoalsQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardGoalsQuery from "../graphql/get-dashboard-goals-query";

const GoalsCollapsibleContainer = ({
  title,
  filters,
  localStorageKey,
}: {
  title: string;
  localStorageKey: keyof UiPreferenceCache;
  roundedBottom?: boolean;
  filters: GetDashboardGoalsQueryQueryVariables;
}) => {
  const label = useLabel();
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { loading, data, fetchMore } = useQuery<
    GetDashboardGoalsQueryQuery,
    GetDashboardGoalsQueryQueryVariables
  >(getDashboardGoalsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      after: null,
      orderBy: "-updated",
      ...filters,
    },
    skip: !uiPreferenceCache[localStorageKey],
    onError: onNotificationErrorHandler(),
  });
  const goals = data?.goalsForUser ? assertEdgesNonNull(data.goalsForUser) : [];
  const pageInfo = data?.goalsForUser?.pageInfo;

  const handleClickMore = () => {
    if (pageInfo) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  // RENDER
  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={localStorageKey}
      title={`${title}${title ? " " : ""}${label("goal", {
        pluralize: true,
        capitalize: !title,
      })}`}
      loading={!data && loading}
      emptyPlaceholder={
        goals.length === 0 && `No ${label("goal", { pluralize: true })}.`
      }
    >
      <Layout.SidebarSubSectionList
        aria-label={`${title} goals dashboard container`}
        loading={loading}
        hasNextPage={!!data?.goalsForUser?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {goals.map((goal) => (
          <Layout.SidebarSubSectionListItem key={goal.id}>
            <Artifact artifact={goal} />
          </Layout.SidebarSubSectionListItem>
        ))}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default Sentry.withErrorBoundary(GoalsCollapsibleContainer, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
