import { useState } from "react";

import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";

const TeamMemberCombobox = ({
  excludeUserIds,
  onSelect,
}: {
  excludeUserIds: number[];
  onSelect: (option: UserComboboxOption | null) => void;
}) => {
  // Hooks
  const [canLoadData, setCanLoadData] = useState(false);
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    excludeUserIds: excludeUserIds,
  });

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  const handleClearValue = () => {
    onSelect(null);
  };

  return (
    <div className="flex-1 flex gap-2 items-center">
      <div className="flex-1" onMouseEnter={handlePreloadData}>
        <UserCombobox
          searchPlaceholder="Pick a user"
          placeholder="Pick a user"
          aria-label="Manager combobox"
          loading={loading}
          width="full"
          query={query}
          value={null}
          options={options}
          onChangeValue={onSelect}
          onChangeQuery={setQuery}
          onClickButton={handlePreloadData}
          onClearValue={handleClearValue}
        />
      </div>
    </div>
  );
};

export default TeamMemberCombobox;
