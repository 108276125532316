import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import {
  GetMeetingRecognitionsNewPageQueryQuery,
  GetMeetingRecognitionsNewPageQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import getMeetingRecognitionsQuery from "@apps/meeting-new/graphql/get-meeting-recognitions-query";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

const MeetingRecognitionCollapsible = ({
  meeting,
  expandedUiPreferenceKey,
  title,
  variables,
  newSidebarDesign = false,
}: {
  newSidebarDesign?: boolean;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  expandedUiPreferenceKey: keyof UiPreferenceCache;
  title: string;
  variables: GetMeetingRecognitionsNewPageQueryQueryVariables;
  roundedBottom?: boolean;
}) => {
  const { url } = useRouteMatch();
  const label = useLabel();
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { data, fetchMore, loading } = useQuery<
    GetMeetingRecognitionsNewPageQueryQuery,
    GetMeetingRecognitionsNewPageQueryQueryVariables
  >(getMeetingRecognitionsQuery, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !uiPreferenceCache[`${expandedUiPreferenceKey}`],
    onError: onNotificationErrorHandler(),
  });

  const handleClickMore = () => {
    fetchMore({
      variables: {
        after: data?.artifacts?.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  const recognitions = data?.artifacts
    ? assertEdgesNonNull(data.artifacts)
    : [];

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={expandedUiPreferenceKey}
      title={title}
      loading={!data && loading}
      emptyPlaceholder={
        recognitions.length === 0 &&
        `No ${label("recognition", { pluralize: true })}.`
      }
    >
      <Layout.SidebarSubSectionList
        loading={loading}
        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {recognitions.map((recognition) =>
          recognition.__typename === "RecognitionArtifactNode" ? (
            <div key={recognition.id} className="px-3 py-2">
              <Artifact artifact={recognition} urlPrefix={url} />
            </div>
          ) : null
        )}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default MeetingRecognitionCollapsible;
