import { useCallback, useMemo, useState } from "react";
import { DashboardInsightFragment } from "types/graphql-schema";

import NudgeDialog from "@apps/reporting/components/oneonone-report/nudge-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import { assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const NudgeOneOnOneInsight = ({
  insight,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const [showNudgeMeetingModal, setShowNudgeMeetingModal] = useState(false);

  const handleClickNudgeMeeting = useCallback(() => {
    setShowNudgeMeetingModal(true);
  }, []);
  const handleCloseNudgeMeeting = useCallback(() => {
    setShowNudgeMeetingModal(false);
  }, []);

  const manager = useMemo(() => assertNonNull(insight.manager), [insight]);
  const report = useMemo(() => assertNonNull(insight.report), [insight]);

  return (
    <InsightLayout
      user={manager}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        No {label("oneonone", { pluralize: false, capitalize: false })}{" "}
        scheduled between {manager.firstName || manager.name} and{" "}
        {report.firstName || report.name}
      </div>
      <div>
        {showNudgeMeetingModal && (
          <NudgeDialog
            onClose={handleCloseNudgeMeeting}
            report={report}
            manager={manager}
            organization={currentOrganization}
          />
        )}
        <InsightButton onClick={handleClickNudgeMeeting}>
          Nudge for {label("oneonone", { pluralize: false, capitalize: false })}
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default NudgeOneOnOneInsight;
