import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import moment from "moment";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import {
  GetMeetingOverviewOneononesQueryQuery,
  GetMeetingOverviewOneononesQueryQueryVariables,
  InsightType,
} from "types/graphql-schema";

import Insight from "@apps/dashboard/components/insight";
import {
  explorerMeetingType,
  getExplorerFiltersUrl,
} from "@apps/explorer/helpers";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getMeetingOverviewOneononesQuery from "../graphql/get-meeting-overview-oneonones-query";
import NoOneononeCTA from "./no-oneonone-cta";
import OverviewMeeting from "./overview-meeting";
import StatsContainer from "./stats-container";

const MeetingsOverviewOneononeMeetings = ({
  selectedUser,
}: {
  selectedUser: UserComboboxOption;
}) => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const endOfTheDay = moment().endOf("day").format();
  const startOfTheDay = moment().startOf("day").format();
  const startOfTheWeek = moment().startOf("isoWeek").format();
  const endOfTheWeek = moment().endOf("isoWeek").format();

  const { data, loading } = useQuery<
    GetMeetingOverviewOneononesQueryQuery,
    GetMeetingOverviewOneononesQueryQueryVariables
  >(getMeetingOverviewOneononesQuery, {
    variables: {
      selectedUserId: selectedUser.id,
      startOfTheWeek,
      startOfTheDay,
      endOfTheWeek,
      endOfTheDay,
    },
    onError: onNotificationErrorHandler(),
  });
  const meetings = compact(
    (data?.meetings ? assertEdgesNonNull(data.meetings) : []).map(
      ({ meeting }) => meeting
    )
  );

  const requirements = data?.me?.insights
    ? assertEdgesNonNull(data.me.insights)
    : [];

  const validInsightTypes = [
    InsightType.CreateComplianceProgramMeeting,
    InsightType.AddNotesToComplianceProgramMeeting,
    InsightType.FinalizeComplianceProgramMeeting,
    InsightType.RequiredTemplate,
  ];
  const reports = data?.user?.directReports
    ? assertEdgesNonNull(data.user.directReports)
    : [];
  const managers = data?.user?.managers
    ? assertEdgesNonNull(data.user.managers)
    : [];
  const selectedUserData = data?.user ? assertNonNull(data.user) : null;
  const relations = (
    selectedUserData ? [selectedUserData] : [...managers, ...reports]
  ).map((relation) => ({
    ...relation,
    requirements:
      selectedUser.id === currentUser.id
        ? requirements.filter(
            (requirement) =>
              validInsightTypes.includes(requirement.type) &&
              (!selectedUser ||
                selectedUser.id === requirement?.report?.id ||
                selectedUser.id === requirement?.manager?.id) &&
              // requirement with managers & reports
              ((requirement?.report?.id === relation.id &&
                requirement.manager?.id === currentUser.id) ||
                (requirement?.report?.id === currentUser.id &&
                  requirement.manager?.id === relation.id))
          )
        : [],
  }));
  const requirementCount = relations.reduce((total, relation) => {
    return total + relation.requirements.length;
  }, 0);

  const timeInOneonones = meetings.reduce((total, meeting) => {
    if (!meeting.meetingGroup?.isFormalOneonone) {
      return total;
    }
    return (
      total + moment(meeting.endDatetime).diff(meeting.startDatetime, "minute")
    );
  }, 0);
  const hoursInOneonones = Math.ceil(timeInOneonones / 60);

  const relationsWithRequirements = relations.filter(
    (relation) => relation.requirements.length > 0
  );
  const relationsWithMeeting = relations.filter(
    (relation) => !!relation.scheduledMeetings?.edges?.[0]?.node
  );
  const oneononePieChartData = [
    {
      name: "Scheduled",
      value: relationsWithMeeting.length,
      color: "#22c55e",
    },
    {
      name: "Unscheduled",
      value: relations.length - relationsWithMeeting.length,
      color: "#dc2626",
    },
  ];
  const oneonones = compact(
    (data?.oneonones ? assertEdgesNonNull(data.oneonones) : []).map(
      ({ meeting }) => meeting
    )
  );

  // RENDER
  return (
    <div className="">
      {!data && loading ? (
        <Loading className="p-6">Loading meetings</Loading>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-4 gap-6">
            <StatsContainer
              label={`${label("1-on-1", {
                capitalize: true,
                pluralize: true,
              })} this Week`}
            >
              <div className="font-medium text-2xl">
                {hoursInOneonones > 0
                  ? `${hoursInOneonones}hr${hoursInOneonones > 1 ? "s" : ""}`
                  : ""}{" "}
                {timeInOneonones % 60}min{timeInOneonones === 1 ? "" : "s"}
              </div>
            </StatsContainer>
            <StatsContainer
              label={`Draft ${label("1-on-1", { pluralize: true })}`}
              url={getExplorerFiltersUrl({
                type: explorerMeetingType,
                meetingIsFormalOneonone: true,
                meetingIsDraft: true,
                meetingParticipants: selectedUser
                  ? [selectedUser.id]
                  : [currentUser.id],
              })}
            >
              <div className="font-medium text-2xl">
                {data?.draftMeetings?.totalCount || 0}
              </div>
            </StatsContainer>
            <StatsContainer label={`Requirements`}>
              <div className="font-medium text-2xl">
                {requirementCount || 0}
              </div>
            </StatsContainer>
            <StatsContainer
              label={`Upcoming ${label("1-on-1", {
                pluralize: true,
                capitalize: true,
              })}`}
            >
              <div className="flex justify-between gap-2">
                <div className="text-sm">
                  {oneononePieChartData.map((pieChartData) => (
                    <div
                      key={pieChartData.name}
                      className="flex items-center gap-1.5"
                    >
                      <span className="flex items-center gap-1">
                        <span
                          className="h-3 w-3 shrink-0 grow-0 rounded-full"
                          style={{ background: pieChartData.color }}
                        />
                        <span>{pieChartData.name}:</span>
                        <span className="text-black">{pieChartData.value}</span>
                      </span>
                    </div>
                  ))}
                </div>
                <PieChart width={80} height={80}>
                  <Pie
                    data={oneononePieChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={40}
                    innerRadius={26}
                    fill="#8884d8"
                  >
                    {oneononePieChartData.map((pieChartData) => (
                      <Cell
                        key={`cell-${pieChartData.name}`}
                        fill={pieChartData.color}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{ padding: "0 10px", fontSize: "12px" }}
                  />
                </PieChart>
              </div>
            </StatsContainer>
          </div>
          <div className="grid grid-cols-2 gap-12">
            <div className="flex flex-col gap-12">
              {relationsWithRequirements.length > 0 && (
                <div>
                  <div className="text-lg font-medium mb-3">
                    Meeting Requirements
                  </div>
                  <div className="flex flex-col gap-6">
                    {relationsWithRequirements.map((relation) => (
                      <div className="flex flex-col gap-1.5" key={relation.id}>
                        <div className="font-medium text-xs text-gray-700 flex items-center gap-1">
                          <Avatar user={relation} size="4" />
                          {relation.name}
                        </div>
                        <div className="rounded border divide-y bg-white overflow-hidden">
                          {relation?.requirements.length > 0 && (
                            <>
                              {relation?.requirements.map((requirement) => (
                                <div
                                  className="px-3 py-2 text-sm text-gray-500"
                                  key={requirement.id}
                                >
                                  <Insight
                                    selectedUser={currentUser}
                                    insight={requirement}
                                  />
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div>
                <div className="text-lg font-medium mb-3">
                  Upcoming {label("1-on-1", { pluralize: true })}
                </div>
                <div className="flex flex-col gap-6">
                  {reports.map((report) => (
                    <div className="flex flex-col gap-1.5" key={report.id}>
                      <div className="font-medium text-xs text-gray-700 flex items-center gap-1">
                        <Avatar user={report} size="4" />
                        {report.name}
                      </div>
                      <div className="rounded border divide-y bg-white overflow-hidden">
                        {!!report?.drafts?.edges?.[0]?.node && (
                          <>
                            {assertEdgesNonNull(report.drafts).map(
                              (meeting) => (
                                <OverviewMeeting
                                  key={meeting.id}
                                  meeting={meeting}
                                />
                              )
                            )}
                          </>
                        )}
                        {!!report?.scheduledMeetings?.edges?.[0]?.node && (
                          <OverviewMeeting
                            meeting={report.scheduledMeetings.edges[0].node}
                          />
                        )}
                        {!report?.scheduledMeetings?.edges?.[0]?.node &&
                          !report?.drafts?.edges?.[0]?.node && (
                            <NoOneononeCTA report={report} />
                          )}
                      </div>
                    </div>
                  ))}
                  {managers.map((manager) => (
                    <div className="flex flex-col gap-1.5" key={manager.id}>
                      <div className="font-medium text-xs text-gray-700 flex items-center gap-1">
                        <Avatar user={manager} size="4" />
                        {manager.name}
                      </div>
                      <div className="rounded border divide-y bg-white overflow-hidden">
                        {!!manager?.scheduledMeetings?.edges?.[0]?.node && (
                          <OverviewMeeting
                            meeting={manager.scheduledMeetings.edges[0].node}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  {relations.length === 0 && (
                    <div className="rounded border divide-y bg-white overflow-hidden">
                      <div className="px-3 py-2 text-sm text-gray-500">
                        No reports.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="text-lg font-medium mb-3">1-on-1 History</div>
              <div className="rounded border divide-y bg-white overflow-hidden">
                {oneonones.map((oneonone) => (
                  <OverviewMeeting key={oneonone.id} meeting={oneonone} />
                ))}
                {oneonones.length === 0 ? (
                  <div className="px-3 py-2 text-sm text-gray-500">
                    No past {label("1-on-1", { pluralize: true })}.
                  </div>
                ) : (
                  <AppLink
                    to={getExplorerFiltersUrl({
                      type: explorerMeetingType,
                      meetingIsFormalOneonone: true,
                      meetingParticipants: compact([
                        selectedUser?.id,
                        currentUser.id,
                      ]),
                    })}
                    className="px-3 py-2 block w-full text-sm text-blue-link hover:underline text-center"
                  >
                    View more
                  </AppLink>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingsOverviewOneononeMeetings;
