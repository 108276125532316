import { gql } from "@apollo/client";

import { DashboardInsightFragment } from "@apps/dashboard/graphql/get-dashboard-insights-query";

import MeetingOverviewMeetingFragment from "./meeting-overview-meeting-fragment";

export default gql`
  ${MeetingOverviewMeetingFragment}
  ${DashboardInsightFragment}
  query getMeetingOverviewOneononesQuery(
    $selectedUserId: Int!
    $startOfTheWeek: DateTime!
    $startOfTheDay: DateTime!
    $endOfTheDay: DateTime!
    $endOfTheWeek: DateTime!
  ) {
    user(userId: $selectedUserId) {
      id
      name
      avatar
      scheduledMeetings: meetings(
        first: 1
        isFormalOneonone: true
        draft: false
        ignored: false
        startDatetime_Gte: $startOfTheDay
        orderBy: "start_datetime"
      ) {
        edges {
          node {
            ...MeetingOverviewMeetingFragment
          }
        }
      }
      managers {
        edges {
          node {
            id
            name
            avatar
            scheduledMeetings: meetings(
              first: 1
              isFormalOneonone: true
              draft: false
              ignored: false
              startDatetime_Gte: $startOfTheDay
              orderBy: "start_datetime"
            ) {
              edges {
                node {
                  ...MeetingOverviewMeetingFragment
                }
              }
            }
          }
        }
      }
      directReports {
        edges {
          node {
            id
            name
            avatar
            scheduledMeetings: meetings(
              first: 1
              isFormalOneonone: true
              draft: false
              ignored: false
              startDatetime_Gte: $startOfTheDay
              orderBy: "start_datetime"
            ) {
              edges {
                node {
                  ...MeetingOverviewMeetingFragment
                }
              }
            }
            drafts: meetings(first: 1, isFormalOneonone: true, draft: true) {
              edges {
                node {
                  ...MeetingOverviewMeetingFragment
                }
              }
            }
          }
        }
      }
    }
    me {
      id
      insights {
        totalCount
        edges {
          node {
            ...DashboardInsight
          }
        }
      }
    }
    meetings: calendar(
      forUserId: $selectedUserId
      isFormalOneonone: true
      draft: false
      ignored: false
      startDatetime_Gte: $startOfTheWeek
      startDatetime_Lte: $endOfTheWeek
      orderBy: "start_datetime"
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          meeting {
            id
            meetingGroup {
              id
              isFormalOneonone
            }
            startDatetime
            endDatetime
          }
        }
      }
    }
    oneonones: calendar(
      forUserId: $selectedUserId
      isFormalOneonone: true
      draft: false
      ignored: false
      startDatetime_Lte: $endOfTheDay
      orderBy: "-start_datetime"
      first: 10
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          meeting {
            ...MeetingOverviewMeetingFragment
          }
        }
      }
    }
    draftMeetings: calendar(
      forUserId: $selectedUserId
      draft: true
      ignored: false
      isFormalOneonone: true
    ) {
      totalCount
    }
  }
`;
