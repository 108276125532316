import { useMutation } from "@apollo/client";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  LockClosedIcon,
} from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { words } from "lodash";
import { MouseEventHandler, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  MeetingViewMeetingNodeFragmentFragment,
  NotesRequirement,
  TopicNodeFragmentFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import TopicAssignee from "@apps/topic-assignee/topic-assignee";
import TopicDropdownMenu from "@apps/topic-dropdown-menu/topic-dropdown-menu";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { formatMeetingDate, getUrl, toWithBackground } from "@helpers/helpers";

import toggleTopicMutation from "../graphql/toggle-topic-mutation";
import DiscussionNotes from "./discussion-notes";
import IndividualNotesContainer from "./individual-notes-container";
import TopicToolbar from "./topic-toolbar";

const Topic = ({
  topic,
  index,
  meeting,
  opensInSidebar,
  searchQuery,
  relevantSections,
  currentPageMeeting = null,
}: {
  topic: TopicNodeFragmentFragment;
  index: number;
  meeting: MeetingViewMeetingNodeFragmentFragment;
  opensInSidebar: boolean;
  searchQuery: string;
  relevantSections: any;
  currentPageMeeting?: MeetingViewMeetingNodeFragmentFragment | null;
}) => {
  const key = `topic-states-${meeting.id}-${topic.id}`;
  const topicIsAppearingInASearch =
    currentPageMeeting?.id !== meeting.id && searchQuery;
  const shouldPersistCollapsedState = !topicIsAppearingInASearch;

  // get collapsed value
  const topicRelevantSections =
    relevantSections?.filter(
      ({ sectionType }: { sectionType: any }) => sectionType === "topic"
    ) || [];
  const hasRelevantSections =
    // if we are in a search and no topic relevant sections
    // it means the matches are outside the topic
    // in that case we show all topics as expanded.
    topicRelevantSections.length === 0 && topicIsAppearingInASearch
      ? true
      : topicRelevantSections.find(
          ({ sectionId }: { sectionId: number }) => sectionId === topic.id
        );
  let defaultCollapsedValue = false;
  if (shouldPersistCollapsedState) {
    const persistedKeyValue = meeting.kvItems?.edges.find(
      (edge) => edge?.node?.key === key
    )?.node;
    if (persistedKeyValue) {
      defaultCollapsedValue = !!JSON.parse(persistedKeyValue.value).collapsed;
    }
  } else {
    defaultCollapsedValue = !hasRelevantSections;
  }

  const location = useLocation<TFLocationState>();
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsedValue);
  const [toggleTopic] = useMutation(toggleTopicMutation);

  // Render
  const topicUrl = getUrl({
    meetingGroupId: meeting.meetingGroup?.id,
    meetingId: meeting.id,
    topicId: topic.id,
  });

  const handleToggleNotes: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newIsCollapsed = !isCollapsed;
    const value = JSON.stringify({ collapsed: newIsCollapsed });
    setIsCollapsed(newIsCollapsed);
    if (shouldPersistCollapsedState) {
      toggleTopic({
        variables: { key, value },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  // toggle when a search is triggered
  useEffect(() => {
    if (!!searchQuery && defaultCollapsedValue !== isCollapsed) {
      setIsCollapsed(defaultCollapsedValue);
    }
  }, [searchQuery, isCollapsed, defaultCollapsedValue, hasRelevantSections]); // set new collapse value when search query & results change

  let titleWithSearchHighlights = topic.title;
  const searchQueryWords = words(searchQuery);
  if (searchQueryWords.length > 0) {
    const regex = new RegExp(`(${searchQueryWords.join("|")})`, "gim");
    titleWithSearchHighlights = topic.title.replace(regex, (matched) => {
      return `<span class="bg-yellow-200">${matched}</span>`;
    });
  }

  const topicTemplateId = topic.linkedTemplateTopic?.topicTemplate?.id;
  const topicTemplateTitle = topic.linkedTemplateTopic?.topicTemplate?.title;
  let notesHelperText = "";
  if (topic.notesRequirement === NotesRequirement.OneParticipant) {
    notesHelperText = "At least one participant must add notes.";
  } else if (topic.notesRequirement === NotesRequirement.AllParticipants) {
    notesHelperText = "All participants must add notes.";
  }

  return (
    <Draggable draggableId={`topic-${topic.id}`} index={index}>
      {(provided, snapshot) => {
        return (
          <li
            key={topic.id}
            id={`meeting-${meeting.id}-topic-${topic.id}`}
            className={classNames(
              "group/topic",
              "py-4",
              topic.isMandatory && "relative js-mandatory-topic",
              snapshot.isDragging && "bg-purple-50/90 shadow-xl rounded-xl"
            )}
            {...provided.draggableProps}
            ref={provided.innerRef}
            aria-label={`Topic ${topic.title}`}
            data-testid={`container-topic-${topic.id}`}
          >
            <div className="flex w-full pl-5 pr-2 relative group">
              <div className="flex-1 flex gap-1">
                <span
                  className={classNames(
                    "cursor-grab text-gray-400 absolute py-1.5 px-0.5 left-0 sm:left-0 sm:top-0 hover:bg-gray-100 rounded hidden group-hover:block",
                    snapshot.isDragging && "block"
                  )}
                  {...provided.dragHandleProps}
                  aria-label="Topic drag handle"
                >
                  <MdOutlineDragIndicator className="h-4 w-4 " />
                </span>
                <div className="h-5">
                  <button
                    className="p-1 text-gray-400 hover:bg-gray-100 rounded-full"
                    aria-label="Topic toggle"
                    onClick={handleToggleNotes}
                  >
                    {isCollapsed ? (
                      <ChevronRightIcon className="h-5 w-5 " />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 " />
                    )}
                  </button>
                </div>

                {/* TEXT */}
                <div className="">
                  <AppLink
                    to={toWithBackground({
                      pathname: getUrl({
                        topicId: topic.id,
                        meetingId: meeting.id,
                        meetingGroupId: meeting.meetingGroup?.id,
                      }),
                      location,
                    })}
                    className="inline hover:underline text-lg font-medium p-1"
                    aria-label="Topic title"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: titleWithSearchHighlights,
                      }}
                    />
                  </AppLink>
                </div>
                {!!topicTemplateId && (
                  <Tooltip
                    text={`This topic has been added from the template: ${topicTemplateTitle}.`}
                  >
                    <AppLink
                      to={`/templates/${topicTemplateId}`}
                      className="pt-1.5"
                    >
                      <TbTemplate className="h-4 w-4 text-gray-500" />
                    </AppLink>
                  </Tooltip>
                )}
                {topic.isMandatory && (
                  <Tooltip
                    text={`The creator of this template has marked the topic as mandatory and it cannot be edited. ${notesHelperText}`}
                  >
                    <span className="pt-1.5">
                      <LockClosedIcon className="h-4 w-4 text-gray-500" />
                    </span>
                  </Tooltip>
                )}
              </div>

              {/* STATE & ACTIONS */}
              {/* wrapping div is necessary to keep icon align to top */}
              <div>
                <div className="flex items-center gap-1.5">
                  <Tooltip text="Open in sidebar">
                    <AppLink
                      className="p-1 rounded-lg hover:bg-gray-100 text-gray-400 hover:text-gray-700 opacity-0 group-hover:opacity-100 hidden xl:inline"
                      to={toWithBackground({
                        pathname: topicUrl,
                        location,
                      })}
                      aria-label="Open topic sidebar"
                    >
                      <ExternalLinkIcon className="h-5 w-5" />
                    </AppLink>
                  </Tooltip>
                  <TopicAssignee
                    topic={topic}
                    assignableUsers={meeting.participants?.edges.map(
                      (edge) => edge?.node?.user
                    )}
                  />
                  <div className="flex">
                    <TopicDropdownMenu
                      meetingGroup={meeting.meetingGroup}
                      topic={topic}
                      meeting={meeting}
                      portal={opensInSidebar}
                      size="5"
                      className="py-0.5 hover:bg-black hover:bg-opacity-5"
                      currentPageMeeting={currentPageMeeting}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isCollapsed && (
              <div className="pt-2">
                {topic.copiedFrom?.id && topic.copiedFrom.id !== topic.id && (
                  <div className="mb-3 mr-4 ml-meetingLeftMargin text-gray-500 text-xs flex gap-2">
                    <DuplicateIcon className="h-4 w-4 shrink-0" />
                    <div className="flex-1">
                      Copied from{" "}
                      <AppLink
                        to={getUrl({
                          meetingId: topic.copiedFrom.meeting?.id,
                          meetingGroupId:
                            topic.copiedFrom.meeting?.meetingGroupId,
                        })}
                        className="italic hover:underline"
                      >
                        {topic.copiedFrom.meeting?.title || "Unknown meeting"}{" "}
                        {topic.copiedFrom.meeting && (
                          <span>
                            ({formatMeetingDate(topic.copiedFrom.meeting)})
                          </span>
                        )}
                      </AppLink>
                    </div>
                  </div>
                )}
                {topic.includesSharedNotes && (
                  <DiscussionNotes
                    topic={topic}
                    meeting={meeting}
                    searchQuery={searchQuery}
                  />
                )}
                <div>
                  <IndividualNotesContainer
                    topic={topic}
                    meeting={meeting}
                    meetingGroup={meeting.meetingGroup}
                    searchQuery={searchQuery}
                    className="mr-4 ml-12 mb-2 sm:ml-meetingLeftMargin"
                  />
                  <TopicToolbar topic={topic} meeting={meeting} />
                </div>
              </div>
            )}
          </li>
        );
      }}
    </Draggable>
  );
};

export default withErrorBoundary(Topic, {
  fallback: <Error description={"The topic could not be rendered."} />,
});
