import { gql } from "@apollo/client";

import { MeetingViewMeetingGroupFragment } from "@apps/meeting/graphql/get-meeting-group-query";

export default gql`
  ${MeetingViewMeetingGroupFragment}
  query getMeetingGroupSettingsQuery($meetingGroupId: Int!) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      ...MeetingViewMeetingGroupFragment
      id
      transcribeMeetingsByDefault
      canUpdate {
        permission
      }
      slackNotifications {
        edges {
          node {
            id
            channel
          }
        }
      }
      organization {
        id
        hasSlackConnection
        publicSlackChannels {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
