import { useQuery } from "@apollo/client";
import {
  GetRefreshedUserDataQuery,
  GetRefreshedUserDataQueryVariables,
} from "types/graphql-schema";

import { currentTiptapJWTVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePageVisibility from "@components/use-page-visibility/use-page-visibility";

import getRefreshedUserDataQuery from "./graphql/get-refreshed-user-data-query";

export default function RefreshUserData() {
  const isVisible = usePageVisibility();
  useQuery<GetRefreshedUserDataQuery, GetRefreshedUserDataQueryVariables>(
    getRefreshedUserDataQuery,
    {
      pollInterval: 30 * 60 * 1000, // refresh every 30 minutes
      skipPollAttempt: () => !isVisible,
      onCompleted: (response) => {
        currentTiptapJWTVar(response.me?.tiptapAiJwt || "");
      },
      onError: onNotificationErrorHandler(),
    }
  );
  return null;
}
