import { gql } from "@apollo/client";

import { ActionItemCollapsibleFragment } from "./action-item-collapsible-fragment";

export default gql`
  ${ActionItemCollapsibleFragment}
  query getActionItemsCollapsibleQuery(
    $actionItemState: Int!
    $assigneeId: Int
    $assigneeIds: [Int]
    $meetingGroupId: Int
    $flowId: Int
    $orderBy: String
    $orderContextType: String
    $orderContextId: Int
    $first: Int!
    $after: String
  ) {
    artifacts(
      artifactType: action_item
      actionItemAssignee: $assigneeId
      actionItemAssignees: $assigneeIds
      meetingGroupId: $meetingGroupId
      flowId: $flowId
      actionItemState: $actionItemState
      first: $first
      orderBy: $orderBy
      orderContextType: $orderContextType
      orderContextId: $orderContextId
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          ...ActionItemCollapsibleFragment
        }
      }
    }
  }
`;
