import { useMutation } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { PropsWithChildren, useCallback } from "react";
import {
  DashboardInsightFragment,
  DismissInsightMutationMutation,
  DismissInsightMutationMutationVariables,
  InsightType,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import NominatePeersInsight from "@apps/dashboard/components/insight/nominate-peers-insight";
import Avatar from "@components/avatar/avatar";
import Error from "@components/error/error";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import useConfirm from "@helpers/hooks/use-confirm";

import dismissInsightMutation from "../graphql/dismiss-insight-mutation";
import getDashboardInsightsQuery from "../graphql/get-dashboard-insights-query";
import AddNotesToComplianceProgramMeetingInsight from "./insight/add-notes-to-compliance-program-meeting-insight";
import AssessmentInsight from "./insight/assessment-insight";
import CreateComplianceProgramMeetingInsight from "./insight/create-compliance-program-meeting-insight";
import FeedbackRequestInsight from "./insight/feedback-request";
import FinalizeComplianceProgramMeetingInsight from "./insight/finalize-compliance-program-meeting-insight";
import MissingManagerInsight from "./insight/missing-manager-insight";
import NudgeOneOnOneInsight from "./insight/nudge-one-on-one-insight";
import OfftrackGoalInsight from "./insight/offtrack-goal-insight";
import ScheduleOneOnOneInsight from "./insight/schedule-one-on-one-insight";
import StaleGoalInsight from "./insight/stale-goal-insight";

export const InsightButton = ({
  children,
  url,
  disabled,
  onClick,
}: PropsWithChildren<{
  url?: string;
  disabled?: boolean;
  onClick?: () => void;
}>) => {
  const CustomTag = url ? "a" : "button";
  return (
    <CustomTag
      className={classNames(
        "text-sm tracking-tight fs-unmask",
        disabled
          ? "text-gray-400"
          : "hover:underline text-amber-900 hover:text-gray-800"
      )}
      onClick={onClick || undefined}
      href={url || undefined}
      disabled={disabled}
    >
      {children}
    </CustomTag>
  );
};

export const InsightLayout = ({
  user,
  isRequired = false,
  children,
  onDismissInsight,
}: PropsWithChildren<{
  user?: BasicUser;
  isRequired?: boolean;
  onDismissInsight: () => void;
}>) => {
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to dismiss this insight?"
  );
  const handleDismiss = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      onDismissInsight();
    }
  }, [confirm, onDismissInsight]);

  return (
    <div className="text-sm flex gap-2 relative group">
      <div className="grow-0 shrink-0 h-5">
        <ConfirmationDialog />
        <Avatar user={user} size="5" />
      </div>
      <div className="flex gap-4 flex-1">{children}</div>
      {!isRequired && (
        <div className="flex shrink-0 grow-0 items-start">
          <InsightButton onClick={handleDismiss}>
            <XIcon className="h-5 w-5" />
          </InsightButton>
        </div>
      )}
    </div>
  );
};

const Insight = ({
  insight,
  selectedUser,
}: {
  insight: DashboardInsightFragment;
  selectedUser: BasicUser;
}) => {
  const [dismissInsight] = useMutation<
    DismissInsightMutationMutation,
    DismissInsightMutationMutationVariables
  >(dismissInsightMutation);

  const handleClickDismissInsight = useCallback(() => {
    dismissInsight({
      variables: {
        userId: selectedUser.id,
        type: insight.type,
        reportId: insight.report?.id || null,
        managerId: insight.manager?.id || null,
        complianceProgramId: insight.complianceProgram?.id || null,
        assessmentGroupId: insight.assessmentGroup?.id || null,
        artifactId:
          (insight.artifact?.__typename === "GoalArtifactNode" &&
            insight.artifact?.id) ||
          null,
      },
      onError: onNotificationErrorHandler(),
      refetchQueries: [getDashboardInsightsQuery],
    });
  }, [dismissInsight, insight, selectedUser]);

  return insight.type === InsightType.Schedule_1On1 ? (
    <ScheduleOneOnOneInsight
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.Nudge_1On1 ? (
    <NudgeOneOnOneInsight
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.MissingManager ? (
    <MissingManagerInsight
      insight={insight}
      selectedUser={selectedUser}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.OfftrackGoal ? (
    <OfftrackGoalInsight
      selectedUser={selectedUser}
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.StaleGoal ? (
    <StaleGoalInsight
      selectedUser={selectedUser}
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.CreateComplianceProgramMeeting ? (
    <CreateComplianceProgramMeetingInsight
      selectedUser={selectedUser}
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.AddNotesToComplianceProgramMeeting ? (
    <AddNotesToComplianceProgramMeetingInsight
      selectedUser={selectedUser}
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.FinalizeComplianceProgramMeeting ? (
    <FinalizeComplianceProgramMeetingInsight
      selectedUser={selectedUser}
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.CompleteAssessment ? (
    <AssessmentInsight
      selectedUser={selectedUser}
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.NominatePeers ? (
    <NominatePeersInsight
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : insight.type === InsightType.FeedbackRequest ? (
    <FeedbackRequestInsight
      insight={insight}
      onDismissInsight={handleClickDismissInsight}
    />
  ) : null;
};

export default withErrorBoundary(Insight, {
  fallback: <Error description={"This insight could not be displayed."} />,
});
