import { withErrorBoundary } from "@sentry/react";
import { MeetingViewMeetingNodeFragmentFragment } from "types/graphql-schema";

import RelatedRequirements from "@apps/meeting/components/meeting-sidebar/related-requirements";
import { currentOrganizationVar } from "@cache/cache";
import Error from "@components/error/error";

import ActionItems from "./action-items";
import AIAssist from "./ai-assist";
import Feedbacks from "./feedbacks";
import Goals from "./goals";
import RecentlyCompletedAssessmentDeliveries from "./recently-completed-assessment-deliveries";
import RecentlyCompletedAssessments from "./recently-completed-assessments";
import Recognitions from "./recognitions";

const MeetingViewSidebar = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const meetingGroup = meeting?.meetingGroup;
  const currentOrganization = currentOrganizationVar();
  return (
    <>
      {meetingGroup && meetingGroup.isFormalOneonone && (
        <RelatedRequirements meeting={meeting} />
      )}
      {meetingGroup && <AIAssist meeting={meeting} />}
      {currentOrganization.featureFlags.actionItems && meetingGroup && (
        <ActionItems meeting={meeting} />
      )}
      {currentOrganization.featureFlags.goals && meetingGroup && (
        <Goals meeting={meeting} />
      )}
      {!waffle.flag_is_active("new-meeting-assessment-container") &&
        meetingGroup &&
        meetingGroup.isFormalOneonone && (
          <RecentlyCompletedAssessments meeting={meeting} />
        )}
      {waffle.flag_is_active("new-meeting-assessment-container") &&
        meetingGroup &&
        meetingGroup.isFormalOneonone && (
          <RecentlyCompletedAssessmentDeliveries meeting={meeting} />
        )}
      {currentOrganization.featureFlags.recognitions && meetingGroup && (
        <Recognitions meeting={meeting} />
      )}
      {currentOrganization.featureFlags.feedbacks &&
        meetingGroup &&
        meetingGroup.isFormalOneonone && <Feedbacks meeting={meeting} />}
    </>
  );
};

export default withErrorBoundary(MeetingViewSidebar, {
  fallback: (
    <Error description={"The meeting sidebar could not be displayed."} />
  ),
});
