import { gql } from "@apollo/client";

const MeetingOverviewMeetingFragment = gql`
  fragment MeetingOverviewMeetingFragment on MeetingNode {
    id
    title
    draft
    meetingGroupId
    meetingGroup {
      id
      isFormalOneonone
    }
    startDate
    startDatetime
    endDatetime
    videoConferenceUrl
    videoConferenceType
    participants {
      totalCount
      edges {
        node {
          id
          user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`;

export default MeetingOverviewMeetingFragment;
