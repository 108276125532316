// @deprecated
import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";

import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getPluginClickOn } from "@components/wysiwyg/helpers";

import getWysiwygArtifactQuery from "../graphql/get-wysiwyg-artifact-query";

const DeprecatedKpiComponent = ({
  node,
  deleteNode,
  editor,
  getPos,
}: {
  node: any;
  deleteNode: () => void;
  editor: any;
  getPos: () => number;
}) => {
  const { data, loading } = useQuery(getWysiwygArtifactQuery, {
    variables: { artifactId: node.attrs.id },
    onError: onNotificationErrorHandler(),
  });
  const artifact = data?.artifact;
  const kpiId = artifact?.measurement?.kpi.id;

  const handleClickReplaceKPIEmbed = () => {
    editor.chain().focus(getPos()).insertKPIEmbed({ id: kpiId }).run();
    deleteNode();
  };

  return (
    <NodeViewWrapper>
      <div
        className="flex flex-col gap-2 border p-2 rounded-lg bg-gray-50 not-prose"
        contentEditable={false}
        aria-label="Artifact kpi view"
      >
        {loading && !artifact?.measurement ? (
          <Loading mini size="5" />
        ) : artifact?.measurement ? (
          <div>
            <div className="flex items-center justify-between mb-1">
              <AppLink
                to={`/kpis/${artifact.measurement?.kpi.id}`}
                className="font-medium text-sm hover:underline ml-1"
              >
                {artifact.measurement?.kpi.title}
              </AppLink>
              <button
                onClick={deleteNode}
                className="text-gray-600 p-0.5 rounded hover:bg-gray-200 hover:text-gray-800"
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="text-sm text-gray-500 ml-1">
              This component has been updated.{" "}
              <button
                onClick={handleClickReplaceKPIEmbed}
                className="text-blue-600 hover:underline"
              >
                Click here to update
              </button>
              .
            </div>
          </div>
        ) : null}
      </div>
    </NodeViewWrapper>
  );
};

const DeprecatedKpiExtension = Node.create({
  name: "kpi",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    // Return an object with attribute configuration
    return {
      id: {
        default: null,
      },
      // used to show placeholder when a user creates a kpi in CRDT
      createdByUser: {
        default: null,
      },
    };
  },

  addOptions() {
    return {
      relatedArtifactId: null,
      topicId: null,
      meetingId: null,
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DeprecatedKpiComponent);
  },

  renderText() {
    return `${window.location.origin}/kpis`;
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addProseMirrorPlugins() {
    return [getPluginClickOn(this.name)];
  },
});

export default DeprecatedKpiExtension;
