import {
  ChevronDownIcon,
  ChevronRightIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { compact } from "lodash";
import { MouseEvent, ReactNode, useCallback } from "react";
import { TbArrowBarLeft, TbArrowBarRight } from "react-icons/tb";

import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Dropdown, { DropdownOptionType } from "@components/dropdown/dropdown";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

const handleStopPropagation = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

const ToggleChevronIcon = ({ expanded }: { expanded: boolean }) => (
  <div className="text-gray-400 group-hover:text-gray-700 flex items-center py-0.5">
    {expanded ? (
      <ChevronDownIcon className="h-4 w-4" />
    ) : (
      <ChevronRightIcon className="h-4 w-4" />
    )}
  </div>
);

const Layout = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={classNames("min-h-full flex flex-col bg-gray-50", className)}
    {...props}
  >
    <div className="flex-1 flex flex-col min-w-0">{children}</div>
  </div>
);

const headerPaddingClassName = "px-1.5";
const headerIconButtonClassName = classNames(
  headerPaddingClassName,
  "rounded-lg py-1 text-gray-400 hover:text-gray-500 hover:bg-black/5"
);
const headerDisableIconButtonClassName = classNames(
  headerPaddingClassName,
  headerIconButtonClassName,
  "hover:text-gray-400 hover:transparent cursor-default"
);

const Header = ({
  className,
  title,
  titleUrl,
  sidebarExpandedUiPreferenceKey,
  children,
  ...props
}: {
  sidebarExpandedUiPreferenceKey?: keyof UiPreferenceCache;
  title: string | ReactNode;
  titleUrl?: string;
  className?: string;
  children?: ReactNode;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = sidebarExpandedUiPreferenceKey
    ? !!uiPreferenceCache[`${sidebarExpandedUiPreferenceKey}`]
    : true;

  const handleCollapseSidebar = useCallback(() => {
    saveUiPreference({ [`${sidebarExpandedUiPreferenceKey}`]: !expanded });
  }, [sidebarExpandedUiPreferenceKey, saveUiPreference, expanded]);

  return (
    <div
      className={classNames(
        "bg-gray-50 px-6 h-14 top-0 border-b flex items-center sticky z-horizontalNavbar",
        "@container/layout-header",
        className
      )}
      {...props}
    >
      <header className="w-full fs-mask flex gap-4 justify-between items-center">
        {typeof title === "string" && titleUrl ? (
          <AppLink
            className="font-medium text-gray-800 truncate hover:underline"
            to={titleUrl}
          >
            {title}
          </AppLink>
        ) : typeof title === "string" ? (
          <span className="font-medium text-gray-800 truncate">{title}</span>
        ) : (
          title
        )}

        <div className="flex items-center gap-1.5 shrink-0 empty:hidden">
          {children}
          {sidebarExpandedUiPreferenceKey && (
            <Tooltip text={expanded ? "Collapse sidebar" : "Expand sidebar"}>
              <button
                type="button"
                className={headerIconButtonClassName}
                onClick={handleCollapseSidebar}
              >
                {expanded ? (
                  <TbArrowBarRight className="h-5 w-5" />
                ) : (
                  <TbArrowBarLeft className="h-5 w-5" />
                )}
              </button>
            </Tooltip>
          )}
        </div>
      </header>
    </div>
  );
};

const Container = ({
  children,
  loading,
  ...props
}: {
  children: ReactNode;
  loading?: boolean;
}) => (
  <div className="flex-1 flex min-w-0" {...props}>
    {loading ? <Loading className="flex-1 py-8" /> : children}
  </div>
);

const Main = ({
  children,
  sidebarExpandedUiPreferenceKey,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  sidebarExpandedUiPreferenceKey: keyof UiPreferenceCache;
}) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const expanded = !!uiPreferenceCache[`${sidebarExpandedUiPreferenceKey}`];
  return (
    <main
      className={classNames(
        "flex-1 min-w-0",
        expanded && "lg:pr-96 xl:pr-120 2xl:pr-144", // padding and space for sidebar
        className
      )}
      {...props}
    >
      {children}
    </main>
  );
};

const MainSection = ({
  children,
  title,
  loading,
  rightSide,
  options,
  expandedUiPreferenceKey,
  className,
  ...props
}: {
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  loading?: boolean;
  title: string;
  className?: string;
  children: ReactNode;
  options?: DropdownOptionType[];
  rightSide?: ReactNode;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : true;

  const handleToggleCollapsible = useCallback(() => {
    saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
  }, [expandedUiPreferenceKey, saveUiPreference, expanded]);

  return (
    <div
      className={classNames(
        "flex flex-col bg-white rounded-lg border divide-y",
        className
      )}
      {...props}
    >
      {loading ? (
        <Loading className="py-4" size={6} />
      ) : (
        <>
          <div className="flex justify-between items-center gap-x-4 gap-y-0.5 flex-wrap px-6 py-4">
            <div
              className="flex-1 shrink-0 text-base font-semibold flex items-center justify-between gap-4"
              role={expandedUiPreferenceKey ? "button" : "none"}
              onClick={
                expandedUiPreferenceKey ? handleToggleCollapsible : undefined
              }
            >
              <div>{title}</div>
              <div className="flex items-center gap-4">
                {rightSide}
                {options && (
                  <Dropdown options={options}>
                    <button
                      className="text-gray-400 hover:bg-gray-50 rounded p-0.5"
                      onClick={handleStopPropagation}
                    >
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    </button>
                  </Dropdown>
                )}
                {expandedUiPreferenceKey && (
                  <ToggleChevronIcon expanded={expanded} />
                )}
              </div>
            </div>
          </div>
          {expanded && children}
        </>
      )}
    </div>
  );
};

const MainSubSection = ({
  children,
  title,
  rightSide = null,
  expandedUiPreferenceKey,
  className = "",
  ...props
}: {
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  title: string;
  className?: string;
  children: ReactNode;
  rightSide?: ReactNode;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : true;

  const handleToggleCollapsible = useCallback(() => {
    saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
  }, [expandedUiPreferenceKey, saveUiPreference, expanded]);

  return (
    <div
      className={classNames("px-6 py-4 text-gray-700", className)}
      {...props}
    >
      <div
        className={classNames(
          "flex justify-between items-center gap-x-2 gap-y-0.5 flex-wrap",
          expanded && "mb-3"
        )}
      >
        <div
          role={expandedUiPreferenceKey ? "button" : "none"}
          className="shrink-0 flex-1 text-sm font-semibold flex items-center gap-4"
          onClick={
            expandedUiPreferenceKey ? handleToggleCollapsible : undefined
          }
        >
          <div className="flex-1">{title}</div>
          {expandedUiPreferenceKey && <ToggleChevronIcon expanded={expanded} />}
        </div>
        {rightSide}
      </div>
      {expanded && children}
    </div>
  );
};

const Sidebar = <T,>({
  children,
  sidebarExpandedUiPreferenceKey,
  className,
  tabs,
  onChangeTab,
  ...props
}: {
  children: ReactNode;
  className?: string;
  tabs?: ToggleButtonGroupType<T>[];
  onChangeTab?: (
    button: ToggleButtonGroupType<T>,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
  sidebarExpandedUiPreferenceKey: keyof UiPreferenceCache;
}) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const expanded = !!uiPreferenceCache[`${sidebarExpandedUiPreferenceKey}`];
  if (!expanded) return null;
  return (
    <aside
      className={classNames(
        "hidden lg:block",
        "lg:w-96 xl:w-120 2xl:w-144",
        "fixed right-0 top-14 bottom-0 overflow-y-scroll",
        "border-l bg-gray-50",
        className
      )}
      {...props}
    >
      <div
        className={classNames(
          "flex flex-col divide-y",
          "pb-24" // extra padding for intercom
        )}
      >
        {tabs && onChangeTab && (
          <div className="px-5 py-4 flex items-center">
            <ToggleButtonGroup<T>
              theme={ToggleButtonGroupTheme.sidebar}
              buttons={compact(tabs)}
              onClick={onChangeTab}
            />
          </div>
        )}
        {children}
      </div>
    </aside>
  );
};

const SidebarSection = ({
  children,
  className,
  title,
  options,
  expandedUiPreferenceKey,
  ...props
}: {
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  title?: string;
  className?: string;
  options?: DropdownOptionType[];
  children: ReactNode;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : true;

  const handleToggleCollapsible = useCallback(() => {
    saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
  }, [expandedUiPreferenceKey, saveUiPreference, expanded]);

  return (
    <div className={classNames("px-6 py-5 empty:hidden", className)} {...props}>
      {title ? (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center gap-x-2 gap-y-0.5 flex-wrap">
            <div
              className="shrink-0 flex-1 text-lg font-medium flex items-center justify-between gap-4 hover:bg-gray-100 px-6 -mx-6 py-1"
              role="button"
              onClick={
                expandedUiPreferenceKey ? handleToggleCollapsible : undefined
              }
            >
              <div>{title}</div>
              <div className="flex items-center gap-4">
                {options && (
                  <Dropdown options={options}>
                    <button
                      className="text-gray-400 hover:bg-gray-50 rounded p-0.5"
                      onClick={handleStopPropagation}
                    >
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    </button>
                  </Dropdown>
                )}
                {expandedUiPreferenceKey && (
                  <ToggleChevronIcon expanded={expanded} />
                )}
              </div>
            </div>
          </div>
          {expanded && children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

const SidebarSubSectionLoading = () => (
  <div
    className={classNames(
      "flex justify-between gap-3 bg-white px-3 py-3 border rounded-lg"
    )}
  >
    <div className="skeleton h-5 flex-1 rounded-lg" />
    <div className="skeleton h-5 w-16 rounded-lg" />
  </div>
);

const SidebarSubSectionEmpty = ({
  children,
}: {
  children: string | ReactNode;
}) => <div className="py-1 text-gray-400 text-sm">{children}</div>;

const SidebarSubSection = ({
  children,
  className,
  title,
  titleRightSide = null,
  expandedUiPreferenceKey,
  isExpanded,
  loading,
  emptyPlaceholder,
  onToggleIsExpanded,
}: {
  children: ReactNode;
  className?: string;
  title: string;
  titleRightSide?: ReactNode;
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  isExpanded?: boolean;
  loading?: boolean;
  emptyPlaceholder?: string | null | false;
  onToggleIsExpanded?: (isExpanded: boolean) => void;
}) => {
  if (expandedUiPreferenceKey === undefined && isExpanded === undefined) {
    throw new Error(
      `${title}: you must define expandedUiPreferenceKey or isExpanded/onToggleIsExpanded props.`
    );
  }
  if (expandedUiPreferenceKey !== undefined && isExpanded !== undefined) {
    throw new Error(
      `${title}: expandedUiPreferenceKey and isExpanded cannot be used at the same time.`
    );
  }
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : isExpanded;
  const toggable =
    expandedUiPreferenceKey !== undefined || onToggleIsExpanded !== undefined;

  const handleToggleCollapsible = () => {
    if (expandedUiPreferenceKey) {
      saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
    } else if (onToggleIsExpanded) {
      onToggleIsExpanded(!expanded);
    }
  };

  return (
    <div
      className={
        classNames(className)
        // don't use container queries, or it'll mess up the action items drag and drop
        // https://github.com/Topicflow/topicflow/issues/1608
      }
    >
      <div
        className={classNames(
          "fs-unmask",
          "flex items-center justify-between group",
          "hover:bg-gray-100 px-6 -mx-6 py-1",
          expanded && "mb-1",
          !toggable && "cursor-default"
        )}
        role="button"
        onClick={toggable ? handleToggleCollapsible : undefined}
      >
        <div
          className={classNames(
            "font-medium text-sm flex-1 flex items-center ",
            !expanded && !expandedUiPreferenceKey
              ? "text-gray-400"
              : "text-gray-700",
            toggable && "group-hover:text-gray-800"
          )}
        >
          {title}
        </div>
        <div className="flex items-center gap-2 empty:hidden">
          {titleRightSide}
          {toggable && <ToggleChevronIcon expanded={expanded} />}
        </div>
      </div>
      {expanded && (
        <div>
          {loading ? (
            <SidebarSubSectionLoading />
          ) : emptyPlaceholder ? (
            <SidebarSubSectionEmpty>{emptyPlaceholder}</SidebarSubSectionEmpty>
          ) : (
            children
          )}
        </div>
      )}
    </div>
  );
};

const SidebarSubSectionListPaginate = ({
  loading,
  hasNextPage,
  onClickMore,
}: {
  hasNextPage?: boolean;
  loading?: boolean;
  onClickMore?: () => void;
}) =>
  hasNextPage && onClickMore !== undefined && loading !== undefined ? (
    <div className="px-4 py-2 flex items-center justify-center">
      {loading ? (
        <Loading size="5" mini />
      ) : (
        <button
          className="text-gray-400 hover:text-gray-500 text-center text-xs mr-4 hover:bg-gray-100 rounded px-1.5 py-0.5"
          onClick={onClickMore}
          disabled={loading}
        >
          View more
        </button>
      )}
    </div>
  ) : null;

const SidebarSubSectionList = ({
  children,
  className,
  hasNextPage,
  loading,
  onClickMore,
  ...props
}: {
  className?: string;
  hasNextPage?: boolean;
  loading?: boolean;
  onClickMore?: () => void;
  children: ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "divide-y divide-gray-200",
        "border border-gray-200 rounded-lg bg-white",
        className
      )}
      {...props}
    >
      {children}

      <SidebarSubSectionListPaginate
        hasNextPage={hasNextPage}
        loading={loading}
        onClickMore={onClickMore}
      />
    </div>
  );
};

const SidebarSubSectionListItem = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div className={classNames("px-3 py-2", className)} {...props}>
    {children}
  </div>
);

Layout.Sidebar = Sidebar;
Layout.SidebarSection = SidebarSection;
Layout.SidebarSubSection = SidebarSubSection;
Layout.SidebarSubSectionLoading = SidebarSubSectionLoading;
Layout.SidebarSubSectionEmpty = SidebarSubSectionEmpty;
Layout.SidebarSubSectionList = SidebarSubSectionList;
Layout.SidebarSubSectionListItem = SidebarSubSectionListItem;
Layout.SidebarSubSectionListPaginate = SidebarSubSectionListPaginate;
Layout.Container = Container;
Layout.Header = Header;
Layout.headerPaddingClassName = headerPaddingClassName;
Layout.headerIconButtonClassName = headerIconButtonClassName;
Layout.headerDisableIconButtonClassName = headerDisableIconButtonClassName;
Layout.Main = Main;
Layout.MainSection = MainSection;
Layout.MainSubSection = MainSubSection;

// const test = (
// <Layout>
//   <Layout.Header>
//     Header
//   </Layout.Header>
//   <Layout.Container>
//     <Layout.Main>
//       <Layout.MainSection>
//         <Layout.MainSubSection />
//         <Layout.MainSubSection />
//         <Layout.MainSubSection />
//       </Layout.MainSection>
//     </Layout.Main>
//     <Layout.Sidebar>
//       <Layout.SidebarSection>
//         <Layout.SidebarSubSection>
//           <Layout.SidebarSubSectionList>
//           <Layout.SidebarSubSectionListItem />
//           <Layout.SidebarSubSectionListItem />
//           <Layout.SidebarSubSectionListPaginate />
//           </Layout.SidebarSubSectionList>
//         </Layout.SidebarSubSection>
//         <Layout.SidebarSubSection />
//         <Layout.SidebarSubSection />
//       </Layout.SidebarSection>
//     </Layout.Sidebar>
//   </Layout.Container>
// </Layout>
// );

export default Layout;
