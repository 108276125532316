import { useQuery } from "@apollo/client";
import slackLogo from "@static/img/slack.png";
import {
  GetOrganizationNotificationsQuery,
  GetOrganizationNotificationsQueryVariables,
} from "types/graphql-schema";

import SlackNotification from "@apps/org-settings/components/slack-notification";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssetUrl } from "@helpers/helpers";

import getOrganizationNotifications from "../graphql/get-organization-notifications";

const Notifications = ({ organization }) => {
  const { data } = useQuery<
    GetOrganizationNotificationsQuery,
    GetOrganizationNotificationsQueryVariables
  >(getOrganizationNotifications, {
    variables: {
      organizationId: organization.id,
    },
    onError: onNotificationErrorHandler(),
  });
  const slackNotifications =
    data?.organization.slackNotifications.edges.map(({ node }) => node) || [];
  const channels = (
    data?.organization.publicSlackChannels.edges.map(({ node }) => node) || []
  ).map((channel) => ({
    label: `#${channel.name}`,
    value: channel.id,
    ...channel,
  }));
  const existingChannelIds = slackNotifications.map(({ channel }) => channel);

  return (
    <div>
      <h2 className="text-2xl pb-2 font-medium text-gray-800">Notifications</h2>
      <ul className="bg-white py-4 flex" aria-label="Notification list">
        <div className="col font-medium mb-4 flex items-start w-28 shrink-0">
          <div className="border rounded flex justify-center items-center h-24 w-28 p-4">
            <img
              src={getAssetUrl(slackLogo)}
              alt="slack"
              className="object-contain h-16"
            />
          </div>
        </div>
        <div className="flex flex-col divide-y divide-gray-100 pl-5">
          <div>
            Send notifcations of recognitions or goal check-ins to one or more
            Slack channels
          </div>
          {slackNotifications.map((slackNotification) => (
            <SlackNotification
              key={slackNotification.id}
              channels={channels}
              slackNotification={slackNotification}
              organization={organization}
              existingChannelIds={existingChannelIds}
            />
          ))}
          <SlackNotification
            channels={channels}
            slackNotification={null}
            organization={organization}
            existingChannelIds={existingChannelIds}
          />
        </div>
      </ul>
    </div>
  );
};

export default Notifications;
