import { gql } from "@apollo/client";

import MeetingOverviewMeetingFragment from "./meeting-overview-meeting-fragment";

export default gql`
  ${MeetingOverviewMeetingFragment}
  query getMeetingOverviewMeetingsQuery(
    $forUserId: Int!
    $startOfPreviousWeek: DateTime!
    $endOfTheWeek: DateTime!
  ) {
    calendar(
      forUserId: $forUserId
      draft: false
      ignored: false
      startDatetime_Gte: $startOfPreviousWeek
      startDatetime_Lte: $endOfTheWeek
      orderBy: "start_datetime"
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          meeting {
            ...MeetingOverviewMeetingFragment
          }
        }
      }
    }
    draftMeetings: calendar(
      forUserId: $forUserId
      draft: true
      ignored: false
    ) {
      totalCount
      edges {
        node {
          id
          meeting {
            ...MeetingOverviewMeetingFragment
          }
        }
      }
    }
  }
`;
