import { PlusIcon } from "@heroicons/react/outline";
import { Editor, Extension } from "@tiptap/core";
import * as tiptapPmView from "@tiptap/pm/view";
import { defer } from "lodash";
import {
  NodeSelection,
  Plugin,
  PluginKey,
  TextSelection,
} from "prosemirror-state";
import ReactDOM from "react-dom";
import { MdOutlineDragIndicator } from "react-icons/md";
import tippy from "tippy.js";

import { artifactType } from "@helpers/constants";

const artifactTypes = Object.values(artifactType);
const artifactComponentClassNames = artifactTypes.map(
  (artifactType) => `node-${artifactType}`
);
const buttonNegativeMargin = 32;

const isArtifactComponentEl = (node: HTMLDivElement) => {
  if (node?.classList) {
    return Array.from(node.classList).find((classItem) =>
      artifactComponentClassNames.includes(classItem)
    );
  }
  return false;
};

const isDraggableComponent = (node: HTMLBaseElement) => {
  const validClassNames = [
    "node-explorer",
    "node-kpi-embed",
    "node-rating",
    "node-kpi-group-embed",
    "node-url-preview",
  ];
  if (node?.classList) {
    return !!validClassNames.find((validClassName) =>
      node.classList.contains(validClassName)
    );
  }
  return false;
};

export function createRect(rect: any, dom: any) {
  if (!rect || !dom) {
    return null;
  }
  return {
    left: dom.left + document.body.scrollLeft - buttonNegativeMargin,
    top: rect.top + document.body.scrollTop,
    width: 0,
    height: 0,
    bottom: 0,
    right: 0,
  };
}

export function absoluteRect(element: any, dom: any) {
  return createRect(
    element.getBoundingClientRect(),
    dom.getBoundingClientRect()
  );
}

function blockPosAtCoords(coords: any, view: any) {
  const pos = view.posAtCoords(coords);

  // https://discuss.prosemirror.net/t/domatpos-for-atom-block-nodes/3800
  let node = view.nodeDOM(pos.inside) || view.domAtPos(pos.pos).node;
  while (node && node.parentNode) {
    if (node.nodeType !== 3) {
      break;
    }
    node = node.parentNode;
  }

  if (node && node.nodeType === 1) {
    const desc = view.docView.nearestDesc(node, true);
    if (!(!desc || desc === view.docView)) {
      return { pos: desc.posBefore, node };
    }
  }
  return { pos: null, node };
}

const placeTippy = ({ node, view }: { node: any; view: any }) => {
  // traverse up to parent till not a text
  while (node && node.parentNode) {
    if (node.nodeType !== 3) {
      break;
    }
    node = node.parentNode;
  }

  // only position + tooltip for artifact components and paragraph
  const isElement = node instanceof Element;
  const isProsemirrorEl = node.classList?.contains("ProseMirror");
  const isBlockElement =
    ["P"].includes(node.nodeName) ||
    isArtifactComponentEl(node) ||
    isDraggableComponent(node);
  if (!isElement || isProsemirrorEl || !isBlockElement) {
    return;
  }

  // Update tooltip position
  if (view.tippy && !view.tippy.state.isDestroyed) {
    view.tippy?.setProps({
      getReferenceClientRect: () => {
        // It's quite hacky. More infos and ideas on how to improve this:
        // https://github.com/ueberdosis/tiptap/issues/1313
        const selectedElement = view.dom.querySelector(
          ".extension-is-selected"
        );
        if (selectedElement) {
          return absoluteRect(selectedElement, view.dom);
        } else {
          return absoluteRect(node, view.dom);
        }
      },
    });
    view.tippy.show();
  }
};

const brokenClipboardAPI = false;

export class AddButtonView {
  editor?: Editor = undefined;
  view: any = null;
  preventHide = false;
  tippy: any = undefined;
  tippyOptions?: any = undefined;
  element?: any = undefined;

  shouldShow = ({ state }: { state: any }) => {
    const { selection } = state;
    if (selection.jsonID === "gapcursor") {
      return false;
    }
    return true;
  };

  constructor({ editor, view, tippyOptions = {} }: any) {
    this.element = document.createElement("div");
    document.body.appendChild(this.element);
    if (waffle.flag_is_active("new-meeting-page")) {
      ReactDOM.render(
        <div className="hidden sm:flex absolute items-center z-0 left-[24px] top-[2px] js-add-drag-buttons">
          <button
            draggable="true"
            className="bg-gray-100 hover:bg-gray-200 py-0.5 flex items-center justify-center text-xl text-gray-400 rounded cursor-grab js-drag-button"
            onDragStart={this.handleDragStart}
            onClick={this.handleClickAddButton}
            tabIndex={-1}
            aria-label="Editor drag button"
          >
            <MdOutlineDragIndicator className="w-4 h-4 js-drag-button" />
          </button>
        </div>,
        this.element
      );
    } else {
      ReactDOM.render(
        <div className="absolute flex items-center z-0 left-5 js-add-drag-buttons">
          <button
            className="hover:bg-gray-100 py-1 px-0.5 flex items-center justify-center text-xl text-gray-400 rounded js-add-button"
            onClick={this.handleClickAddButton}
            tabIndex={-1}
            aria-label="Editor add button"
          >
            <PlusIcon className="w-4 h-4" />
          </button>
          <button
            draggable="true"
            className="hover:bg-gray-100 py-1 px-0.5 flex items-center justify-center text-xl text-gray-400 rounded cursor-grab js-drag-button"
            onDragStart={this.handleDragStart}
            tabIndex={-1}
            aria-label="Editor drag button"
          >
            <MdOutlineDragIndicator className="w-4 h-4 js-drag-button" />
          </button>
        </div>,
        this.element
      );
    }

    this.editor = editor;
    this.view = view;
    this.tippyOptions = tippyOptions;

    // Detaches menu content from its current parent
    this.element.style.visibility = "visible";

    document.addEventListener("scroll", this.scrollHandler);
    this.element.addEventListener("mousedown", this.mousedownHandler, {
      capture: true,
    });
    this.editor?.on("focus", this.focusHandler);
    this.editor?.on("blur", this.blurHandler);
  }

  mousedownHandler = () => {
    this.preventHide = true;
  };

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    defer(() => {
      if (this.editor) this.update(this.editor.view, undefined);
    });
  };

  blurHandler = ({ event }: any) => {
    if (this.preventHide) {
      this.preventHide = false;
      return;
    }
    // stops if blurring in an element in the editor
    if (
      event?.relatedTarget &&
      this.element?.parentNode?.contains(event.relatedTarget)
    ) {
      return;
    }
    this.hide();
  };

  scrollHandler = () => {
    this.hide();
  };

  handleDragStart = (e: any) => {
    if (!e.dataTransfer) return;

    const coords = { left: e.clientX + 150, top: e.clientY };
    const { pos } = blockPosAtCoords(coords, this.view);
    if (pos === null) {
      return;
    }

    const view = this.view as any;

    if (view) {
      view.dispatch(
        view.state.tr.setSelection(NodeSelection.create(view.state.doc, pos))
      );
    }

    const slice = view.state.selection.content();
    const _tiptapPmView = tiptapPmView as any;
    const { dom, text } = _tiptapPmView.__serializeForClipboard(view, slice);

    e.dataTransfer.clearData();
    e.dataTransfer.setData(
      brokenClipboardAPI ? "Text" : "text/html",
      dom.innerHTML
    );
    if (!brokenClipboardAPI) e.dataTransfer.setData("text/plain", text);

    view.dragging = { slice, move: true };
  };

  handleClickAddButton = (e: any) => {
    const coords = { left: e.clientX + 150, top: e.clientY };
    const { pos, node } = blockPosAtCoords(coords, this.view);
    if (pos === null) {
      return;
    }

    const isArtifactEl = isArtifactComponentEl(node);
    if (isArtifactEl && this.editor) {
      const isRootElement = node.parentNode?.classList.contains("ProseMirror");
      return this.editor
        .chain()
        .focus(pos + (isRootElement ? 1 : 2))
        .insertContent("/")
        .run();
    }

    let isEmpty = false;
    this.editor
      ?.chain()
      .focus(pos + 1)
      .command(({ state, dispatch }) => {
        // Don't dispatch this command if the selection is empty
        const { $anchor } = state.selection;
        const isEmptyTextBlock =
          $anchor.parent.isTextblock &&
          !$anchor.parent.type.spec.code &&
          !$anchor.parent.textContent;

        isEmpty = isEmptyTextBlock;
        // Subtract one so that it falls within the current node
        const endPos = state.selection.$to.after() - 1;
        const selection = new TextSelection(state.doc.resolve(endPos));
        const transaction = state.tr.setSelection(selection);
        if (dispatch) dispatch(transaction.scrollIntoView());
        return true;
      })
      .run();
    if (isEmpty) {
      this.editor?.chain().insertContent("/").run();
    } else {
      this.editor?.chain().enter().insertContent("/").run();
    }

    // ------------------------------------------------
    // I want to keep that as it might be a better way
    // to detect the type of block and if empty
    // ------------------------------------------------
    // const { state } = this.editor;
    // const { selection } = state;
    // const { $anchor, empty, to } = selection;
    // // console.log({ selection, to, state, editor: this.editor });
    // // const isRootDepth = $anchor.depth === 1;
    // const isEmptyTextBlock =
    //   $anchor.parent.isTextblock &&
    //   !$anchor.parent.type.spec.code &&
    //   !$anchor.parent.textContent;
    // const artifactTypes = Object.values(artifactType);
    // const isCustomComponent = artifactTypes.includes(selection.node?.type.name);
  };

  handlePopperBlurEvent = (event: any) => {
    this.blurHandler({ event });
  };

  createTooltip() {
    if (!this.editor) {
      return;
    }
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) {
      return;
    }

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: "manual",
      placement: "right",
      hideOnClick: "toggle",
      zIndex: 0,
      theme: "headless",
      ...this.tippyOptions,
    }) as any;

    // maybe we have to hide tippy on its own blur event as well
    if (this.tippy && this.tippy.popper.firstChild) {
      this.tippy.popper.firstChild.addEventListener(
        "blur",
        this.handlePopperBlurEvent
      );
    }
  }

  update(view: any, oldState: any | undefined) {
    const { state } = view;
    const { doc, selection } = state;
    const { from } = selection;
    const isSame =
      oldState && oldState.doc.eq(doc) && oldState.selection.eq(selection);

    if (isSame) {
      return;
    }

    this.createTooltip();
    this.view.tippy = this.tippy;

    const shouldShow = this.shouldShow?.({
      state,
    });

    if (!shouldShow) {
      this.hide();
      return;
    }

    // When user is focused in editor, we show the + / drag button next to the cursor.
    if (this.editor?.isFocused) {
      const nodeAtPos = view.domAtPos(from);
      const node = nodeAtPos.node;
      placeTippy({ node, view });
    }
  }

  show() {
    if (this.tippy && !this.tippy.state.isDestroyed) {
      this.tippy.show();
    }
  }

  hide() {
    if (this.tippy && !this.tippy.state.isDestroyed) {
      this.tippy.hide();
    }
  }

  destroy() {
    // Clean up event handlers
    if (this.tippy?.popper?.firstChild) {
      this.tippy.popper.firstChild.removeEventListener(
        "blur",
        this.handlePopperBlurEvent
      );
    }
    this.element.removeEventListener("mousedown", this.mousedownHandler, {
      capture: true,
    });
    document.removeEventListener("scroll", this.scrollHandler);
    this.editor?.off("focus", this.focusHandler);
    this.editor?.off("blur", this.blurHandler);

    // delete root element
    this.tippy?.destroy();
    this.element.parentNode.removeChild(this.element);
  }
}

export const AddButtonPlugin = (options: any) => {
  return new Plugin({
    key:
      typeof options.pluginKey === "string"
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    view: (view) => new AddButtonView({ view, ...options }),
    props: {
      handleDOMEvents: {
        mouseleave(view: any, event: any) {
          // we don't hide add/drag button if it is the add/drag button itself.
          const eventEl = event.toElement || event.relatedTarget;
          if (
            view.tippy &&
            !view.tippy.state.isDestroyed &&
            !view.tippy.popper?.contains(eventEl)
          ) {
            view.tippy.hide();
          }
        },
        mouseenter(view: any) {
          view.cachedBoundingRect = view.dom.getBoundingClientRect();
        },
        drop(view: any) {
          setTimeout(() => {
            const node = document.querySelector(".ProseMirror-hideselection");
            if (node) {
              node.classList.remove("ProseMirror-hideselection");
            }
          }, 50);
          if (view.tippy && !view.tippy.state.isDestroyed) {
            view.tippy?.hide();
          }
        },
        mousemove(view: any, event) {
          // Get node/block for mouse position
          const rightSideMargin = 30;
          const coords = {
            left: view.cachedBoundingRect.right - rightSideMargin,
            top: event.clientY,
          };
          const pos = view.posAtCoords(coords);
          if (pos === null) {
            return;
          }

          const node = view.nodeDOM(pos.inside) || view.domAtPos(pos.pos).node;
          if (!node) {
            return;
          }

          placeTippy({ node, view });
        },
      },
    },
  });
};

export const AddButton = Extension.create({
  name: "addButton",

  addOptions() {
    return {
      element: null,
      tippyOptions: {},
      pluginKey: "addButton",
      shouldShow: null,
    };
  },

  addProseMirrorPlugins() {
    return [
      AddButtonPlugin({
        pluginKey: this.options.pluginKey,
        editor: this.editor,
        tippyOptions: this.options.tippyOptions,
        shouldShow: this.options.shouldShow,
      }),
    ];
  },
});

export default AddButton;
