import { compact, flatMap } from "lodash";
import { useMemo } from "react";
import {
  AssessmentType,
  ComplianceProgramParticipantStatus,
  ComplianceProgramParticipantStatusFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import ManagedTable, {
  ManagedTableColumn,
} from "@components/table/managed-table";
import Tooltip from "@components/tooltip/tooltip";
import { CHART_COMPLETE_COLOR, CHART_INCOMPLETE_COLOR } from "@helpers/charts";
import { ProgramStatusLabel, assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";

const getAssessmentCompletion = (
  assessmentGroupType: AssessmentType,
  data: ComplianceProgramParticipantStatusFragment
) => {
  return assessmentGroupType === AssessmentType.Performance
    ? data.performanceAssessmentStatus
    : assessmentGroupType === AssessmentType.Manager
    ? data.managerAssessmentStatus
    : assessmentGroupType === AssessmentType.Peer
    ? data.peerAssessmentStatus
    : ComplianceProgramParticipantStatus.NotApplicable;
};
const getSelfAssessmentCompletion = (
  assessmentGroupType: AssessmentType,
  data: ComplianceProgramParticipantStatusFragment
) => {
  return assessmentGroupType === AssessmentType.Performance
    ? data.performanceSelfAssessmentStatus
    : assessmentGroupType === AssessmentType.Manager
    ? data.managerSelfAssessmentStatus
    : assessmentGroupType === AssessmentType.Peer
    ? data.peerSelfAssessmentStatus
    : ComplianceProgramParticipantStatus.NotApplicable;
};

const getParticipantStatusProgress = (
  assessmentGroupType: AssessmentType,
  data: ComplianceProgramParticipantStatusFragment[]
) => {
  const completeCount = data.filter(
    (item) =>
      getAssessmentCompletion(assessmentGroupType, item) ===
      ComplianceProgramParticipantStatus.Complete
  ).length;
  const total = data.filter(
    (item) =>
      getAssessmentCompletion(assessmentGroupType, item) !==
      ComplianceProgramParticipantStatus.NotApplicable
  ).length;
  return {
    progress: Math.round((completeCount / total) * 100),
    fraction: `${completeCount}/${total}`,
  };
};
const getParticipantStatusSelfProgress = (
  assessmentGroupType: AssessmentType,
  data: ComplianceProgramParticipantStatusFragment[]
) => {
  const completeCount = data.filter(
    (item) =>
      getSelfAssessmentCompletion(assessmentGroupType, item) ===
      ComplianceProgramParticipantStatus.Complete
  ).length;
  const total = data.filter(
    (item) =>
      getSelfAssessmentCompletion(assessmentGroupType, item) !==
      ComplianceProgramParticipantStatus.NotApplicable
  ).length;
  return {
    progress: Math.round((completeCount / total) * 100),
    fraction: `${completeCount}/${total}`,
  };
};

const CompletionTableHeader = ({
  progress,
  fraction,
  title,
}: {
  progress: number;
  fraction: string;
  title: string;
}) => {
  return (
    <div className="flex flex-col justify-end h-20 min-[2000px]:h-12">
      <div>{title}</div>
      <CompletionTableProgress progress={progress} fraction={fraction} />
    </div>
  );
};

const CompletionTableProgress = ({
  progress,
  fraction,
}: {
  progress: number;
  fraction: string;
}) => {
  return (
    <Tooltip text={fraction}>
      <div
        className="mt-1 w-full rounded-full h-3 overflow-hidden"
        style={{ background: CHART_INCOMPLETE_COLOR }}
      >
        <div
          className="bg-blue-100 h-6"
          style={{
            width: `${progress}%`,
            background: CHART_COMPLETE_COLOR,
          }}
        />
      </div>
    </Tooltip>
  );
};

const CompletionTag = ({
  status,
  tooltip,
}: {
  status: ComplianceProgramParticipantStatus;
  tooltip?: string;
}) => {
  const tag = (
    <div
      className={classNames(
        "p-2 rounded-md w-28 text-center",
        status === ComplianceProgramParticipantStatus.Complete &&
          "bg-green-100 text-green-700",
        status === ComplianceProgramParticipantStatus.InProgress &&
          "bg-amber-100 text-amber-700",
        status === ComplianceProgramParticipantStatus.NotStarted &&
          "bg-amber-100 text-amber-700",
        status === ComplianceProgramParticipantStatus.NotApplicable &&
          "bg-gray-100 text-gray-700"
      )}
    >
      {ProgramStatusLabel[status]}
    </div>
  );
  if (!tooltip) {
    return tag;
  }
  return <Tooltip text={tooltip}>{tag}</Tooltip>;
};

const ComplianceProgramCompletionTable = ({
  assessmentGroups,
  hasOneOnOneMeeting,
  participantStatus,
}: {
  assessmentGroups: {
    id: number;
    title: string;
    assessmentType: AssessmentType;
    hasSelfAssessment: boolean;
    isOnlySelfAssessment: boolean;
  }[];
  participantStatus: ComplianceProgramParticipantStatusFragment[];
  hasOneOnOneMeeting: boolean;
}) => {
  const label = useLabel();
  const completionColumns: ManagedTableColumn<ComplianceProgramParticipantStatusFragment>[] =
    useMemo(() => {
      const assessmentColumns = flatMap(assessmentGroups, (assessmentGroup) => {
        return [
          {
            header: `${
              assessmentTypeLabels[assessmentGroup.assessmentType]
            } ${label("review")}`,
            headerRender: () => {
              const completion = getParticipantStatusProgress(
                assessmentGroup.assessmentType,
                participantStatus
              );
              return (
                <CompletionTableHeader
                  title={`${
                    assessmentTypeLabels[assessmentGroup.assessmentType]
                  } ${label("review")}`}
                  progress={completion.progress}
                  fraction={completion.fraction}
                />
              );
            },
            sortKey: `${assessmentGroup.assessmentType}`,
            render: (data: ComplianceProgramParticipantStatusFragment) => {
              const status = getAssessmentCompletion(
                assessmentGroup.assessmentType,
                data
              );
              let tooltip = undefined;
              if (status === ComplianceProgramParticipantStatus.NotApplicable) {
                tooltip =
                  assessmentGroup.assessmentType === AssessmentType.Performance
                    ? assessmentGroup.isOnlySelfAssessment
                      ? `Self ${label("review")} only`
                      : "Subject has no manager"
                    : assessmentGroup.assessmentType === AssessmentType.Peer
                    ? "Subject has no nominated peers"
                    : "Subject has no direct reports";
              }
              return <CompletionTag status={status} tooltip={tooltip} />;
            },
            value: (data: ComplianceProgramParticipantStatusFragment) =>
              `${getAssessmentCompletion(
                assessmentGroup.assessmentType,
                data
              )}`,
          },
          assessmentGroup.hasSelfAssessment && {
            header: `${
              assessmentTypeLabels[assessmentGroup.assessmentType]
            } self ${label("review")}`,
            headerRender: () => {
              const completion = getParticipantStatusSelfProgress(
                assessmentGroup.assessmentType,
                participantStatus
              );
              return (
                <CompletionTableHeader
                  title={`${
                    assessmentTypeLabels[assessmentGroup.assessmentType]
                  } self ${label("review")}`}
                  progress={completion.progress}
                  fraction={completion.fraction}
                />
              );
            },
            sortKey: `${assessmentGroup.assessmentType}-self`,
            render: (data: ComplianceProgramParticipantStatusFragment) => {
              const status = getSelfAssessmentCompletion(
                assessmentGroup.assessmentType,
                data
              );
              let tooltip = undefined;
              if (status === ComplianceProgramParticipantStatus.NotApplicable) {
                if (!assessmentGroup.hasSelfAssessment) {
                  tooltip = `Program does not require self ${label("review")}`;
                } else {
                  tooltip =
                    assessmentGroup.assessmentType ===
                    AssessmentType.Performance
                      ? "Subject has no manager"
                      : assessmentGroup.assessmentType === AssessmentType.Peer
                      ? "Subject has no nominated peers"
                      : "Subject has no direct reports";
                }
              }
              return <CompletionTag status={status} tooltip={tooltip} />;
            },
            value: (data: ComplianceProgramParticipantStatusFragment) =>
              `${getSelfAssessmentCompletion(
                assessmentGroup.assessmentType,
                data
              )}`,
          },
        ];
      });
      return compact([
        {
          header: "Subject",
          sortKey: "subject",
          render: (data) => (
            <div className="flex items-center">
              <Avatar className="mr-2" size="8" user={data.user} />{" "}
              {data.user.name}
            </div>
          ),
          value: (data) => data.user.name,
        },
        ...assessmentColumns,
        hasOneOnOneMeeting && {
          header: "1-on-1",
          headerRender: () => {
            const completed = participantStatus.filter(
              (item) =>
                item.oneononeStatus ===
                ComplianceProgramParticipantStatus.Complete
            ).length;
            const total = participantStatus.filter(
              (item) =>
                item.oneononeStatus !==
                ComplianceProgramParticipantStatus.NotApplicable
            ).length;
            return (
              <CompletionTableHeader
                title="1-on-1"
                progress={Math.round((completed / total) * 100)}
                fraction={`${completed}/${total}`}
              />
            );
          },
          sortKey: "one-on-one",
          render: (data: ComplianceProgramParticipantStatusFragment) => (
            <CompletionTag status={data.oneononeStatus} />
          ),
          value: (data: ComplianceProgramParticipantStatusFragment) =>
            data.oneononeStatus,
        },
      ]);
    }, [assessmentGroups, hasOneOnOneMeeting, participantStatus, label]);

  return (
    <ManagedTable
      scroll
      sortingEnabled
      sortKey="subject"
      sortDir="asc"
      data={participantStatus}
      columns={completionColumns}
    />
  );
};

export default ComplianceProgramCompletionTable;
