import * as Sentry from "@sentry/react";
import { useState } from "react";
import { ArtifactType, FeedbackState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

import getDashboardFeedbackQuery from "../graphql/get-dashboard-feedback-query";
import FeedbacksCollapsibleContainer from "./feedbacks-collapsible-container";

const DashboardFeedback = ({
  selectedUser,
  createdBetweenDates,
}: {
  selectedUser: BasicUser;
  createdBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);
  const label = useLabel();

  return (
    <>
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Feedback,
            feedbackRecipients:
              selectedUser.id !== currentUser.id ? [selectedUser] : [],
          }}
          onClose={() => setIsShowingCreateDialog(false)}
          refetchQueries={[getDashboardFeedbackQuery]}
        />
      )}
      <Layout.SidebarSection
        expandedUiPreferenceKey="dashboardSidebarFeedbackContainerExpanded"
        aria-label="Dashboard feedback container"
        title={
          selectedUser.id === currentUser.id
            ? label("feedback", { pluralize: true, capitalize: true })
            : `${selectedUser.firstName || selectedUser.name}'s ${label(
                "feedback",
                {
                  pluralize: true,
                  capitalize: true,
                }
              )}`
        }
        options={[
          {
            label: `Create ${label("feedback")}`,
            onClick: ({ syntheticEvent }) => {
              syntheticEvent.preventDefault();
              syntheticEvent.stopPropagation();
              setIsShowingCreateDialog(true);
            },
          },
        ]}
      >
        <FeedbacksCollapsibleContainer
          title={`${label("feedback", { capitalize: true })} received`}
          filters={{
            feedbackRecipient: selectedUser.id,
            asUser: selectedUser.id,
            feedbackState: FeedbackState.Sent,
            first: 5,
            createdBetweenDates,
          }}
          localStorageKey="dashboardFeedbackReceivedExpanded"
        />
        <FeedbacksCollapsibleContainer
          title={`${label("feedback", { capitalize: true })} given`}
          filters={{
            feedbackSender: selectedUser.id,
            asUser: selectedUser.id,
            feedbackState: FeedbackState.Sent,
            first: 5,
            createdBetweenDates,
          }}
          localStorageKey="dashboardFeedbackGivenExpanded"
          roundedBottom
        />
      </Layout.SidebarSection>
    </>
  );
};

export default Sentry.withErrorBoundary(DashboardFeedback, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
