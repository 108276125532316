import { Combobox } from "@headlessui/react";
import {
  CheckIcon,
  SearchIcon,
  UserAddIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { defer } from "lodash";
import { useEffect, useRef } from "react";
import { RiTeamLine } from "react-icons/ri";
import { TbBuilding } from "react-icons/tb";
import { BasicTeam, BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";
import Loading from "@components/loading/loading";
import { classNames } from "@helpers/css";

type ExtraOptions = {
  unavailable?: boolean;
  invited?: boolean;
};

export enum UserComboboxOptionType {
  USER = "user",
  TEAM = "team",
  ORG = "org",
}

export type UserComboboxUserOption = {
  type: UserComboboxOptionType.USER;
} & BasicUser &
  ExtraOptions;
export type UserComboboxTeamOption = {
  type: UserComboboxOptionType.TEAM;
} & BasicTeam &
  ExtraOptions;
export type UserComboboxOrgOption = {
  type: UserComboboxOptionType.ORG;
  id: number;
  name: string;
} & ExtraOptions;
export type UserComboboxOption =
  | UserComboboxUserOption
  | UserComboboxOrgOption
  | UserComboboxTeamOption;

const UserOption = ({
  value,
  user,
  hasDuplicateNames,
  showEmail,
  active,
}: {
  value: UserComboboxOption | null;
  showEmail: boolean;
  active: boolean;
  user: UserComboboxUserOption;
  hasDuplicateNames: (user: UserComboboxOption) => boolean;
}) => {
  return (
    <>
      {user.invited ? (
        <UserAddIcon className="w-5 h-5" />
      ) : (
        <Avatar
          user={user}
          size="5"
          tooltipSuffix={user.email ? ` (${user.email})` : ""}
        />
      )}
      <div className="flex-1 flex flex-col min-w-0">
        <div className="flex gap-2 min-w-0">
          <div className="flex-1 truncate">
            {user.invited ? `Invite new user` : user.name}
          </div>
        </div>
        {(showEmail || hasDuplicateNames(user)) && user.email && (
          <div className="-mt-1 text-2xs opacity-70">{user.email}</div>
        )}
      </div>
      {value?.id === user.id && value.type === UserComboboxOptionType.USER && (
        <span
          className={classNames(
            active ? "text-white" : "text-indigo-600",
            "absolute inset-y-0 right-0 flex items-center pr-4"
          )}
        >
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      )}
    </>
  );
};

const TeamOption = ({
  value,
  team,
  active,
}: {
  value: UserComboboxOption | null;
  active: boolean;
  team: UserComboboxTeamOption;
}) => {
  return (
    <>
      <RiTeamLine className="w-5 h-5" />
      <div className="flex-1 flex flex-col min-w-0">
        <div className="flex gap-2 min-w-0">
          <div className="flex-1 truncate">{team.title}</div>
        </div>
      </div>
      {value?.id === team.id && value.type === UserComboboxOptionType.TEAM && (
        <span
          className={classNames(
            active ? "text-white" : "text-indigo-600",
            "absolute inset-y-0 right-0 flex items-center pr-4"
          )}
        >
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      )}
    </>
  );
};

const OrgOption = ({
  value,
  organization,
  active,
}: {
  value: UserComboboxOption | null;
  active: boolean;
  organization: UserComboboxOrgOption;
}) => {
  return (
    <>
      <TbBuilding className="w-5 h-5" />
      <div className="flex-1 flex flex-col min-w-0">
        <div className="flex gap-2 min-w-0">
          <div className="flex-1 truncate">{organization.name}</div>
        </div>
      </div>
      {value?.id === organization.id &&
        value.type === UserComboboxOptionType.ORG && (
          <span
            className={classNames(
              active ? "text-white" : "text-indigo-600",
              "absolute inset-y-0 right-0 flex items-center pr-4"
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
    </>
  );
};

export default function UserComboboxList({
  onChange,
  open,
  close,
  width,
  onChangeQuery,
  placeholder,
  loading,
  validOptions,
  showEmail,
  value,
  query,
}: {
  onChange: (close: () => void) => (option: UserComboboxOption) => void;
  open: boolean;
  close: () => void;
  width: string;
  onChangeQuery: (query: string) => void;
  placeholder: string;
  loading: boolean;
  validOptions: UserComboboxOption[];
  showEmail: boolean;
  value: UserComboboxOption | null;
  query: string;
}) {
  const focusRef = useRef<HTMLInputElement>(null);

  // https://github.com/Topicflow/topicflow/pull/1294
  // We manage the focus here:
  // - UserCombobox is rendered in a Portal
  // - If focused immediately after being rendered in the document.body dom element by Portal
  //   the focus will cause a scroll to the top whenever the combobox is opened.
  //   So we defer the focus till the element is in the dom and positioned by popperjs
  useEffect(() => {
    if (open) {
      defer(() => {
        // hopefully after the element has been positioned
        focusRef.current?.focus();
      });
    }
  }, [open]);

  const hasDuplicateNames = (person: UserComboboxOption) => {
    return !!validOptions.find(
      (option) =>
        option.type === "user" &&
        person.type === "user" &&
        option.name === person.name &&
        option.id !== person.id
    );
  };

  return (
    <Combobox onChange={onChange(close)}>
      <div className={`relative w-${width}`}>
        <div className="relative w-full cursor-default">
          <Combobox.Input
            // Force autoFocus to be false, this prevent scroll to top bug when opening panel the input is in portal.
            autoFocus={false}
            value={query}
            ref={focusRef}
            onChange={(event) => onChangeQuery(event.target.value)}
            placeholder={placeholder}
            aria-label="User combobox search input"
            className="relative w-full pl-11 pr-9 py-2 text-left cursor-default sm:text-sm border-b focus:outline-0 focus:ring-0 bg-transparent"
          />
          <span className="absolute inset-y-0 left-0 -top-1 flex items-center pl-4">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          {loading && (
            <Loading
              mini
              size="4"
              className="absolute inset-y-0 right-2 top-2.5 flex items-center"
            />
          )}
        </div>
        {validOptions.length > 0 && (
          <Combobox.Options
            static
            className="text-sm overflow-auto focus:outline-none overflow-y-auto max-h-54 divide-y divide-gray-75"
            aria-label="User combobox list"
          >
            {validOptions.map((opt, i) => (
              <Combobox.Option
                key={`${opt.type}${opt.id}`}
                value={opt}
                disabled={opt.unavailable}
                className={({ active }) =>
                  classNames(
                    active
                      ? "text-white bg-blue-600"
                      : opt.invited
                      ? "text-blue-700"
                      : "text-gray-800",
                    "cursor-default select-none relative py-2 pl-4 pr-4 min-w-0",
                    validOptions.length - 1 === i && "rounded-b-md"
                  )
                }
              >
                {({ active }) => (
                  <div
                    className={classNames(
                      "flex justify-between items-center gap-2 min-w-0",
                      value?.id === opt.id && "pr-5"
                    )}
                  >
                    {opt.type === "user" ? (
                      <UserOption
                        user={opt}
                        value={value}
                        showEmail={showEmail}
                        active={active}
                        hasDuplicateNames={hasDuplicateNames}
                      />
                    ) : opt.type === "org" ? (
                      <OrgOption
                        organization={opt}
                        value={value}
                        active={active}
                      />
                    ) : (
                      <TeamOption team={opt} value={value} active={active} />
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {!loading && query !== "" && validOptions.length === 0 && (
          <div className="py-14 px-4 text-center sm:px-14">
            <UsersIcon
              className="mx-auto h-6 w-6 text-gray-400"
              aria-hidden="true"
            />
            <p className="mt-4 text-sm text-gray-900">No results.</p>
          </div>
        )}
      </div>
    </Combobox>
  );
}
