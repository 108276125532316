import { Editor as CoreEditor } from "@tiptap/core";
import { Extension } from "@tiptap/core";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { Editor } from "@tiptap/react";
import { words } from "lodash";
import { Plugin, PluginKey } from "prosemirror-state";

export const updateSearchHighlightInEditor = (
  editor: Editor | CoreEditor | null,
  highlightSearchQuery: string
) => {
  if (editor) {
    editor.chain().changeSearchQuery(highlightSearchQuery).run();
    const childArtifactEditorEls = [
      ...editor.view.dom.querySelectorAll(".js-artifact-input"),
    ];
    childArtifactEditorEls.forEach((childArtifactEditorEl) => {
      const el = childArtifactEditorEl as Element & { editor: Editor };
      el.editor.chain().changeSearchQuery(highlightSearchQuery).run();
    });
  }
};

class LinterPlugin {
  results: {
    message: string;
    from: number;
    to: number;
    fix?: any;
  }[] = [];
  doc;

  constructor(doc: any) {
    this.results = [];
    this.doc = doc;
  }

  record(message: string, from: number, to: number, fix?: any) {
    this.results.push({
      message,
      from,
      to,
      fix,
    });
  }

  scan(search: string | undefined) {
    return this;
  }

  getResults() {
    return this.results;
  }
}

class SearchMatch extends LinterPlugin {
  scan(searchQuery: string) {
    const searchQueryWords = words(searchQuery);
    if (searchQueryWords.length === 0) {
      return this;
    }
    const regex = new RegExp(`(${searchQueryWords.join("|")})`, "gim");
    this.doc.descendants((node: any, position: number) => {
      if (!node.isText) {
        return;
      }

      let matches = null;
      while (null !== (matches = regex.exec(node.text))) {
        if (matches) {
          this.record(
            `Match '${matches[0]}'`,
            position + matches.index,
            position + matches.index + matches[0].length
          );
        }
      }
    });

    return this;
  }
}

function runAllLinterPlugins(doc: any, searchQuery: string) {
  const decorations: any[] = [];
  const results = new SearchMatch(doc).scan(searchQuery).getResults();
  results.forEach((issue) => {
    decorations.push(
      Decoration.inline(issue.from, issue.to, {
        class: `bg-yellow-200`,
      })
    );
  });

  return DecorationSet.create(doc, decorations);
}

const SearchHighlight = Extension.create({
  name: "searchHighlight",

  addOptions() {
    return {
      searchQuery: "",
    };
  },

  addStorage() {
    return {
      searchQuery: "",
    };
  },

  addCommands() {
    return {
      changeSearchQuery: (newSearchQuery) => () => {
        this.options.searchQuery = newSearchQuery;
        this.editor.view.dispatch(this.editor.state.tr);
        return true;
      },
    };
  },

  addProseMirrorPlugins() {
    const { options } = this;
    return [
      new Plugin({
        key: new PluginKey("linter"),
        state: {
          init(_, { doc }) {
            const { searchQuery } = options;
            if (searchQuery) {
              return runAllLinterPlugins(doc, searchQuery);
            }
          },
          apply(transaction) {
            const { searchQuery } = options;
            return runAllLinterPlugins(transaction.doc, searchQuery);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default SearchHighlight;
