import { useQuery } from "@apollo/client";
import { NetworkStatus } from "@apollo/client";
import { range, sortBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { MeetingViewMeetingNodeFragmentFragment } from "types/graphql-schema";

import Button from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import getMeetingHistoryQuery from "../graphql/get-meeting-history-query";
import { allMeetingsOption, currentMeetingOption } from "../helpers";
import Meeting from "./meeting";
import Search from "./search";

const MeetingHistory = ({
  meetingGroupId,
  currentPageMeeting,
}: {
  meetingGroupId?: number;
  currentPageMeeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  // we persist the query data so we can still show the meetings while loading a new page/search
  const [oldSearchResults, setOldSearchResults] = useState<null | {
    search: string;
  }>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const defaultSearchContext =
    meetingGroupId && !currentPageMeeting.hasPreviousMeetings
      ? allMeetingsOption.value
      : currentMeetingOption.value;
  const [searchContext, setSearchContext] = useState(defaultSearchContext);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const dontShowAllMeetingsWithoutSearch =
    meetingGroupId &&
    debouncedSearchQuery.trim().length === 0 &&
    searchContext === allMeetingsOption.value;

  // Query
  const variables = {
    meetingGroupId:
      searchContext === currentMeetingOption.value && meetingGroupId
        ? meetingGroupId
        : null,
    beforeDatetime: currentPageMeeting.startDatetime,
    search: debouncedSearchQuery,
  };
  const initialMeetingLimit = 2;
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    getMeetingHistoryQuery,
    {
      notifyOnNetworkStatusChange: true,
      // fetchPolicy: "network-only",
      variables: {
        limit: initialMeetingLimit,
        ...variables,
      },
      skip: !!dontShowAllMeetingsWithoutSearch,
      onCompleted: ({ search }) => {
        const isFirstPageOfSearch =
          debouncedSearchQuery && search.edges.length <= initialMeetingLimit;
        if (isFirstPageOfSearch) {
          if (search.pageInfo.hasNextPage) {
            handleClickLoadMore();
          }
          const searchContainer = document.querySelector(
            "#js-meeting-history-search"
          );
          if (
            searchContainer &&
            searchContainer.getBoundingClientRect().top === 0
          ) {
            const stickyOffset = 57;
            const top =
              (searchContainer?.parentElement?.parentElement?.offsetTop || 0) +
              stickyOffset;
            window.scrollTo({ top, behavior: "smooth" });
          }
        }

        // When apollo fetch a new query, the data object is empty.
        // So when we change the search, we store old search results
        // so we can display those old results while fetching the new ones.
        setOldSearchResults({ search });
      },
      onError: onNotificationErrorHandler(),
    }
  );

  // reset search when changing current page meeting
  useEffect(() => {
    setSearchQuery("");
  }, [currentPageMeeting.id]);

  useEffect(() => {
    setSearchContext(defaultSearchContext);
  }, [defaultSearchContext]);

  const handleClickLoadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        after: data.search.pageInfo.endCursor,
        limit: 5,
        merge: true,
      },
    });
  };

  // if data is empty we use old search results while loading content
  const searchResults = data || oldSearchResults;
  const sortedMeetings = sortBy(
    searchResults?.search.edges,
    ({ node }) => -moment(node.meeting.startDatetime).unix()
  );
  const isFetchingMore = loading && networkStatus === NetworkStatus.fetchMore;
  const showLoadingState =
    searchQuery !== debouncedSearchQuery || (!data && loading);

  return (
    // add a key, so we force re-render of search
    // which will clear the search query
    <div key={`meeting-history-${currentPageMeeting.id}`}>
      <Search
        searchQuery={searchQuery}
        searchContext={searchContext}
        onChangeSearchContext={setSearchContext}
        onChangeSearchQuery={setSearchQuery}
        showContextSelector={true}
      />

      {error ? (
        <GraphqlError
          error={error}
          whatDidNotWork="The meetings could not be loaded."
        />
      ) : !showLoadingState && dontShowAllMeetingsWithoutSearch ? (
        <div className="text-sm italic text-gray-400">
          Please enter a search to filter the meetings.
        </div>
      ) : !showLoadingState &&
        debouncedSearchQuery.trim().length > 0 &&
        sortedMeetings.length === 0 ? (
        <div className="text-sm italic text-gray-400">
          No meetings for "{debouncedSearchQuery}".
        </div>
      ) : (
        <div>
          {searchResults && (
            <div
              className={classNames(
                showLoadingState && "opacity-30 pointer-events-none"
              )}
            >
              {sortedMeetings.map((edge) => (
                <div className="mb-6" key={edge.node.meeting.id}>
                  <Meeting
                    meeting={edge.node.meeting}
                    opensTopicsInSidebar={false}
                    currentPageMeeting={currentPageMeeting}
                    searchQuery={searchQuery}
                    relevantSections={edge.node.relevantSections}
                  />
                </div>
              ))}
              {searchResults.search.pageInfo.hasNextPage && (
                <div className="flex w-full justify-center my-6">
                  {isFetchingMore ? (
                    <Loading>Loading more meetings...</Loading>
                  ) : (
                    <Button onClick={handleClickLoadMore}>
                      Load previous meetings...
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
          {/* Loading placeholder state */}
          {(!searchResults || sortedMeetings.length === 0) && showLoadingState && (
            <div>
              {range(0, initialMeetingLimit).map((i) => (
                <div
                  key={i}
                  className="shadow rounded-md flex justify-center items-center bg-white h-48 mb-6"
                >
                  <Loading>Loading meeting</Loading>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MeetingHistory;
