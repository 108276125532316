import { useMutation } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { validate } from "email-validator";
import { uniqueId } from "lodash";
import { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  InviteUsersMutation,
  InviteUsersMutationVariables,
  OrganizationNode,
  OrganizationRole,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import { successNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Modal from "@components/modal/modal";
import TextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import InviteDialogInviteList from "./components/invite-dialog-invite-list";
import inviteUsersMutation from "./graphql/invite-users-mutation";

type Props = {
  organization: Pick<OrganizationNode, "id" | "personal" | "name">;
  onClose?: () => void;
};

export type InviteInputType = {
  uuid: string;
  email: string;
  role: OrganizationRole;
};

const getEmptyInviteInput = () => {
  return {
    uuid: uniqueId("email-invite"),
    email: "",
    role: OrganizationRole.Member,
  };
};

const InviteDialog: React.FC<Props> = ({ organization, onClose }) => {
  const nonPersonalOrganization = organization?.personal ? null : organization;
  const history = useHistory();
  const location = useLocation<TFLocationState>();
  const focusRef = useRef<HTMLInputElement>(null);
  const [inviteUsers, { loading, error }] = useMutation<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >(inviteUsersMutation);
  const [inviteMessage, setInviteMessage] = useState("");
  const [succeededInviteEmails, setSucceededInviteEmails] = useState<string[]>(
    []
  );
  const [invitationErrorsByEmail, setInvitationErrorsByEmail] = useState<
    { inviteeEmail?: string | null; message?: string | null }[]
  >([]);
  const [invites, setInvites] = useState<InviteInputType[]>([
    getEmptyInviteInput(),
  ]);
  const invitationRequests = invites
    .filter((invite) => invite.email && validate(invite.email))
    .map((invite) => {
      return {
        inviteeEmail: invite.email,
        role: invite.role,
        inviteMessage,
        organizationId: nonPersonalOrganization?.id || null,
      };
    });

  const handleChangeInvites = (updatedInvites: InviteInputType[]) => {
    const hasEmptyInviteInput = !!updatedInvites.find(
      ({ email }) => email.trim().length === 0
    );
    const newInvites = hasEmptyInviteInput
      ? updatedInvites
      : [...updatedInvites, getEmptyInviteInput()];
    setInvites(newInvites);
  };

  const modalOnClose =
    onClose ||
    function () {
      history.push(location.pathname === "/invite" ? "/" : location.pathname);
    };

  const handleClickSendInvitation = () => {
    if (invitationRequests.length) {
      setSucceededInviteEmails([]);
      setInvitationErrorsByEmail([]);
      inviteUsers({
        variables: { invitationRequests },
        onError: onNotificationErrorHandler(),
      }).then(({ data }) => {
        // parse errors and success invitations
        const errors = data?.createInvitations?.invitationErrors
          ? data?.createInvitations?.invitationErrors.map(assertNonNull)
          : [];
        setInvitationErrorsByEmail(errors);
        const succeededEmails = assertNonNull(
          data?.createInvitations?.invitations
        ).map((node) => assertNonNull(node?.inviteeEmail));

        // Remove success invite fields and fill up with empty fields
        if (errors.length === 0) {
          successNotificationVar({ title: "Invites sent!" });
          modalOnClose();
        } else {
          setSucceededInviteEmails(succeededEmails);
          handleChangeInvites([]);
        }
      });
    }
  };

  return (
    <Modal
      open
      onClose={modalOnClose}
      initialFocus={focusRef}
      aria-label="Invite dialog"
    >
      <div className="p-6 bg-white rounded-md">
        <div className="mb-4 flex justify-between">
          <h2 className="text-2xl font-semibold">
            {nonPersonalOrganization
              ? `Invite people to ${nonPersonalOrganization.name}`
              : `Invite people`}
          </h2>
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={modalOnClose}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-4">
          {invites.length > 0 && (
            <>
              <div className="my-1 flex items-center justify-between gap-4">
                <div className="font-medium text-sm">Emails</div>
              </div>
              <InviteDialogInviteList
                invites={invites}
                showRoleSelector={!!nonPersonalOrganization}
                onChange={handleChangeInvites}
              />
            </>
          )}
          <div className="mt-8 mb-1 font-medium text-sm">Message</div>
          <div className="mb-4 grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <TextareaAutosize
                placeholder="Type an invite message..."
                minRows={1}
                value={inviteMessage}
                onChange={(e) => setInviteMessage(e.target.value)}
                className={classNames(
                  "px-4 py-2 block w-full sm:text-sm",
                  inputBorderClassName,
                  inputFocusClassName
                )}
              />
            </div>
          </div>
          {error && (
            <div className="my-4">
              <GraphqlError error={error} />
            </div>
          )}
          <div className="mt-8 mb-4 flex justify-between items-center">
            <Button
              small
              theme="primary"
              onClick={handleClickSendInvitation}
              disabled={invitationRequests.length === 0 || loading}
            >
              {loading ? "Sending..." : "Send invitations"}
            </Button>
          </div>
          {succeededInviteEmails.length > 0 && (
            <div className="mt-4 text-green-700 text-sm">
              <span>Invites sent to {succeededInviteEmails.join(", ")}.</span>
            </div>
          )}
          {invitationErrorsByEmail.length > 0 && (
            <ul className="mt-4 text-red-700 text-sm list-disc ml-4">
              {invitationErrorsByEmail.map((invitationError) => (
                <li key={invitationError.inviteeEmail}>
                  {invitationError.inviteeEmail}: {invitationError.message}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InviteDialog;
