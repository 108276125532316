import logo from "@static/img/topicflow-logo-no-text.svg";
import { TopicTemplateFragmentFragment } from "types/graphql-schema";

import Avatar from "@components/avatar/avatar";
import { assertEdgesNonNull, getAssetUrl } from "@helpers/helpers";

const TemplateItem = ({
  template,
}: {
  template: TopicTemplateFragmentFragment;
}) => {
  const topics = assertEdgesNonNull(template.topics);
  const topicText = topics.map((node) => `- ${node.title}`).join("\n");
  // RENDER
  return (
    <div className="rounded-xl p-2 gradient-pale flex flex-1 w-full">
      <div className="bg-white w-full rounded-lg p-4 text-sm flex flex-col gap-4 shadow-md hover:shadow-lg transition-shadow ease-out">
        <div className="font-medium text-gray-800 h-10 line-clamp-2">
          {template.title}
        </div>
        <div className="mt-2 text-xs text-gray-500 whitespace-pre-line line-clamp-4 h-16">
          {template.description || topicText}
        </div>
        {!template.globalTemplate && template.creator ? (
          <div className="mt-4 text-xs text-gray-500 shrink grow-0 flex gap-2 items-center">
            <Avatar user={template.creator} size="4" />
            {template.creator.name}
          </div>
        ) : (
          <div className="mt-4 text-xs text-gray-500 shrink grow-0 flex gap-2 items-center">
            <img
              src={getAssetUrl(logo)}
              alt="Topicflow"
              className="block h-4"
            />
            Topicflow
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateItem;
