import { useQuery } from "@apollo/client";
import {
  GetGoalOverviewRelatedEntitiesQueryQuery,
  GetGoalOverviewRelatedEntitiesQueryQueryVariables,
  GoalOverviewRelatedEntityGoalFragmentFragment,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";

import getGoalOverviewRelatedEntitiesQuery from "./graphql/get-goal-overview-related-entities-query";
import GoalOverviewRelatedEntitiesTable from "./related-entities-table";

const GoalOverviewRelatedEntities = ({
  selected,
  selectedDateRange,
}: {
  selected: UserComboboxOption;
  selectedDateRange: DateRangeEnum;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();

  const { data, loading } = useQuery<
    GetGoalOverviewRelatedEntitiesQueryQuery,
    GetGoalOverviewRelatedEntitiesQueryQueryVariables
  >(getGoalOverviewRelatedEntitiesQuery, {
    variables: {
      goalDueBetweenDates: dateRangeToDateArray({
        range: selectedDateRange,
        quarterStartMonth: currentOrganization.quarterStartMonth,
      }),
      userId: selected.id,
      hasUserId: selected.type === UserComboboxOptionType.USER,
      teamId: selected.id,
      hasTeamId: selected.type === UserComboboxOptionType.TEAM,
      organizationId: selected.id,
      hasOrganizationId: selected.type === UserComboboxOptionType.ORG,
    },
    onError: onNotificationErrorHandler(),
  });
  const people =
    selected.type === UserComboboxOptionType.USER
      ? data?.user?.directReports
        ? assertEdgesNonNull(data?.user?.directReports)
        : []
      : selected.type === UserComboboxOptionType.TEAM
      ? data?.team?.members
        ? assertEdgesNonNull(data?.team?.members)
        : []
      : [];
  const userTeams = (
    selected.type === UserComboboxOptionType.USER
      ? data?.user?.teams
        ? assertEdgesNonNull(data?.user?.teams)
        : []
      : []
  ).map((userTeam) => ({
    ...userTeam,
    goals: assertEdgesNonNull(
      userTeam.teamGoals
    ) as GoalOverviewRelatedEntityGoalFragmentFragment[],
  }));
  const teams =
    selected.type === UserComboboxOptionType.ORG
      ? data?.organization?.teams
        ? assertEdgesNonNull(data?.organization?.teams)
        : []
      : [];
  const entities = (
    selected.type === UserComboboxOptionType.ORG ? teams : people
  ).map((entity) => ({
    ...entity,
    goals: (entity.__typename === "UserNode"
      ? (assertEdgesNonNull(
          entity.ownedGoals
        ) as GoalOverviewRelatedEntityGoalFragmentFragment[])
      : assertEdgesNonNull(
          entity.teamGoals
        )) as GoalOverviewRelatedEntityGoalFragmentFragment[],
  }));

  return (
    <>
      <div>
        <div className="font-medium mb-2 flex items-center gap-2">
          {selected.type === UserComboboxOptionType.USER
            ? `${selected.name}'s Reports`
            : selected.type === UserComboboxOptionType.TEAM
            ? `${selected.title}'s Members`
            : `${selected.name} ${label("team", {
                pluralize: true,
                capitalize: true,
              })}`}
          {loading && <Loading mini size={4} />}
        </div>
        <div className="flex flex-col gap-4">
          <GoalOverviewRelatedEntitiesTable
            entities={entities}
            selected={selected}
          />
        </div>
      </div>

      {userTeams.length > 0 && selected.type === UserComboboxOptionType.USER && (
        <div>
          <div className="font-medium mb-2 flex items-center gap-2">
            {`${selected.name}'s ${label("team", {
              pluralize: true,
              capitalize: true,
            })}`}
          </div>
          <div className="flex flex-col gap-4">
            <GoalOverviewRelatedEntitiesTable
              entities={userTeams}
              selected={selected}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GoalOverviewRelatedEntities;
