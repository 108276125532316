import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { Dialog, Popover } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/outline";
import { compact, invert, isNaN, orderBy, uniq, uniqBy } from "lodash";
import moment from "moment";
import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { matchPath, useLocation } from "react-router-dom";
import {
  ArtifactType,
  CreateArtifactMutationMutation,
  CreateArtifactMutationMutationVariables,
  CreateFeedbackRequestsMutationMutation,
  CreateFeedbackRequestsMutationMutationVariables,
  CreatedArtifactFragmentFragment,
  FeedbackState,
  GoalProgressType,
  GoalScope,
  GoalVisibility,
} from "types/graphql-schema";
import { BasicUser, TFLocationState } from "types/topicflow";

import { ActionItemStateDropdownInput } from "@apps/artifact/components/action-item-state-dropdown";
import ArtifactIcon from "@apps/artifact/components/artifact-icon";
import CoreValueDropdownInput from "@apps/artifact/components/core-value-dropdown-input";
import RecipientForm from "@apps/artifact/components/recipient-form";
import getDashboardRecognitionsQuery from "@apps/dashboard/graphql/get-dashboard-recognitions-query";
import getGoalOverviewRelatedEntitiesQuery from "@apps/goal-overview/graphql/get-goal-overview-related-entities-query";
import getMeetingRecognitionsQuery from "@apps/meeting/graphql/get-meeting-recognitions-query";
import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  currentUserVar,
  successNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import DatePickerCustomHeaderWithClearButton from "@components/datepicker/custom-header-with-clear-button";
import GraphqlError from "@components/error/graphql-error";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import ActionItemPeopleDropdown from "@components/people-dropdown/action-item-people-dropdown";
import { SelectOption } from "@components/select/select";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextWithNoBreakLinesWysiwyg from "@components/wysiwyg/text-with-no-break-lines-wysiwyg";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import WYSIWYGAIButton from "@components/wysiwyg/wysiwyg-ai-button";
import { artifactTypeUrl, graphqlNone } from "@helpers/constants";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  getCycleDates,
  getFeedbackSentToString,
  getStartValueForProgressType,
  getTargetValueForProgressType,
  isCommandEnterEvent,
  isEnterEvent,
  showConfetti,
} from "@helpers/helpers";
import { joinStringWithCommaAnd, removeBreaklines } from "@helpers/string";

import ArtifactCreationGoalFields from "./artifact-creation-goal-fields";
import createArtifactMutation from "./graphql/create-artifact-mutation";
import createFeedbackRequestsMutation from "./graphql/create-feedback-requests-mutation";
import { updateArtifactsCacheWithCreatedArtifact } from "./helpers";

type DialogLabelProps = {
  className?: string;
};
const DialogLabel: React.FC<PropsWithChildren<DialogLabelProps>> = ({
  children,
  className = "",
}) => (
  <label
    className={classNames("text-gray-500 text-sm w-24 font-medium", className)}
  >
    {children}
  </label>
);

type KpiPickerProps = {
  loading: boolean;
  value: ComboboxGenericOption<number>;
  options: ComboboxGenericOption<number>[];
  onSelectKpi: (kpi: ComboboxGenericOption<number>) => void;
};
export const KpiPicker: React.FC<KpiPickerProps> = ({
  loading,
  value,
  options,
  onSelectKpi,
}) => {
  const filterKpiOptions = uniqBy(
    orderBy(
      options,
      [({ value }) => value < 0, ({ label }) => label.toLowerCase()],
      ["desc", "asc"]
    ),
    "value"
  );
  return (
    <ComboboxGeneric
      aria-label="KPI picker"
      loading={loading}
      width="64"
      value={value}
      options={filterKpiOptions}
      clearable={false}
      onChangeValue={onSelectKpi}
    >
      {({ value, setReferenceElement }) => (
        <div
          className={classNames(
            "flex justify-between bg-white rounded-md",
            inputBorderClassName,
            inputFocusClassName
          )}
        >
          <Popover.Button
            className="px-2 py-0.5 flex gap-2 text-sm text-left items-center min-w-0 max-w-72"
            ref={setReferenceElement}
            aria-label="Kpi picker button"
          >
            <div className="flex-1 truncate">{value?.label}</div>
            <span>
              <SelectorIcon className="h-4 w-4" />
            </span>
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

type ArtifactCreationDialogFormKeyResultType = {
  uuid: string;
  title: string;
  startValue: number;
  targetValue: number;
  assignee?: BasicUser;
  progressType: GoalProgressType;
  kpi?: {
    id: number;
    currentMeasurement?: {
      measurement: number;
    };
  };
};

const sanitizeKeyResults = (
  keyResults: ArtifactCreationDialogFormKeyResultType[]
) => {
  return keyResults
    .filter(
      (kr) =>
        kr.title.trim() &&
        !isNaN(Number(kr.startValue)) &&
        !isNaN(Number(kr.targetValue))
    )
    .map((kr) => ({
      title: kr.title,
      startValue: getStartValueForProgressType(kr),
      currentValue: getStartValueForProgressType(kr),
      targetValue: getTargetValueForProgressType(kr),
      assigneeId: kr.assignee?.id || null,
      kpiId: kr.kpi?.id,
      progressType: kr.progressType,
    }));
};

export type ArtifactCreationDialogFormType = {
  title: string;
  startDate?: string | null;
  dueDate?: string | null;
  actionItemDueDate?: string | null;
  owners?: BasicUser[];
  contributors?: BasicUser[];
  assignee?: BasicUser;
  startValue: number | string;
  targetValue: number | string;
  meetingId?: number;
  meetingGroupId?: number;
  progressType?: GoalProgressType;
  scope?: GoalScope;
  goalVisibility?: GoalVisibility;
  actionItemState?: number;
  artifactType: ArtifactType;
  organizationId: number;
  parentGoal?: {
    value: number;
    label: string;
    htmlLabel?: JSX.Element;
  };
  childGoals: {
    value: number;
    label: string;
    htmlLabel?: JSX.Element;
  }[];
  keyResults: ArtifactCreationDialogFormKeyResultType[];
  feedbackSenders: BasicUser[];
  feedbackMessage?: string | JSON | null;
  feedbackRecipients: BasicUser[];
  feedbackRecipientsCanView?: boolean;
  feedbackCreatorCanView?: boolean;
  feedbackRecipientsManagersCanView?: boolean;
  feedbackAdminsCanView?: boolean;
  feedbackState?: FeedbackState;

  recognitionRecipients?: BasicUser[];
  recognitionCoreValueId?: number | null;

  canUpdate?: { permission: boolean };
  additionalFields?: any;
  teams: {
    id: number;
    title: string;
  }[];
  kpi?: {
    id: number;
    title: string;
    currentMeasurement?: {
      measurement: number;
    };
  };
};

enum FeedbackTabEnum {
  give = "give",
  requestForMe = "requestForMe",
  requestForOther = "requestForOther",
}

const ArtifactCreationDialog = ({
  formOptions = {},
  refetchQueries = [],
  onClose,
  hideTypeSelector = false,
}: {
  formOptions?: Partial<ArtifactCreationDialogFormType>;
  refetchQueries?: InternalRefetchQueriesInclude;
  onClose: (artifact?: CreatedArtifactFragmentFragment) => void;
  hideTypeSelector?: boolean;
}) => {
  const label = useLabel();
  const [showConfirmFeedback, setShowConfirmFeedback] = useState(false);
  const [, setIsRerendered] = useState(false);
  const currentOrganization = currentOrganizationVar();
  const currentUser = currentUserVar();
  const paidFeatures = assertNonNull(currentUser.paidFeatures);
  const actionItemStates = assertNonNull(currentOrganization.actionItemStates);
  const allowedArtifactTypes = compact([
    currentOrganization.featureFlags.actionItems && {
      artifactType: ArtifactType.ActionItem,
      permission: true,
    },
    currentOrganization.featureFlags.goals && {
      artifactType: ArtifactType.Goal,
      permission: paidFeatures.canCreateGoals,
    },
    currentOrganization.featureFlags.recognitions && {
      artifactType: ArtifactType.Recognition,
      permission: true,
    },
    currentOrganization.featureFlags.feedbacks && {
      artifactType: ArtifactType.Feedback,
      permission: true,
    },
    currentOrganization.featureFlags.decisions && {
      artifactType: ArtifactType.Decision,
      permission: paidFeatures.canCreateDecisions,
    },
    currentOrganization.featureFlags.documents && {
      artifactType: ArtifactType.Document,
      permission: true,
    },
  ]);
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);
  const location = useLocation<TFLocationState>();
  const inputTitleRef = useRef(null);
  const link = useLink();
  const pathNameByArtifactType = invert(artifactTypeUrl);
  const defaultArtifactTypeFromUrl = Object.keys(pathNameByArtifactType).find(
    (key) => location.pathname.startsWith(`/${key}`)
  );
  const defaultActionItemState = assertNonNull(
    actionItemStates.find((node) => assertNonNull(node?.isDefaultIncomplete))
  );

  const coreValues = useMemo(
    () =>
      currentOrganization.coreValues
        ? assertEdgesNonNull(currentOrganization.coreValues)
        : [],
    [currentOrganization]
  );

  // use to track changes between form and initial form
  const [cycleStartDate, cycleEndDate] = getCycleDates();
  const [form, setForm] = useState<ArtifactCreationDialogFormType>({
    title: "",
    startDate: cycleStartDate,
    dueDate: cycleEndDate,
    actionItemDueDate: null,
    owners: [currentUser],
    contributors: [],
    assignee: currentUser,
    startValue: 0,
    targetValue: 100,
    progressType: GoalProgressType.Percentage,
    scope: GoalScope.Personal,
    goalVisibility: GoalVisibility.Public,
    actionItemState: defaultActionItemState.value,
    artifactType: defaultArtifactTypeFromUrl
      ? (pathNameByArtifactType[defaultArtifactTypeFromUrl] as ArtifactType)
      : ArtifactType.ActionItem,
    organizationId: currentOrganization.id,
    canUpdate: { permission: true },
    feedbackSenders: [currentUser],
    feedbackMessage: null,
    feedbackRecipients: [],
    feedbackCreatorCanView: true,
    feedbackRecipientsCanView: true,
    feedbackRecipientsManagersCanView: false,
    feedbackAdminsCanView: false,
    feedbackState: FeedbackState.Sent,

    recognitionRecipients: [],
    recognitionCoreValueId: null,
    keyResults: [],
    teams: [],
    kpi: undefined,
    parentGoal: undefined,
    childGoals: [],
    additionalFields: {},
    ...(formOptions || {}),
  });
  const matchingAllowedArtifactType = allowedArtifactTypes.find(
    ({ artifactType }) => artifactType === form.artifactType
  );
  const isGoal = form.artifactType === ArtifactType.Goal;
  const isActionItem = form.artifactType === ArtifactType.ActionItem;
  const isRecognition = form.artifactType === ArtifactType.Recognition;
  const isFeedback = form.artifactType === ArtifactType.Feedback;

  const [
    createArtifact,
    { loading: loadingCreateArtifact, error: errorCreateArtifact },
  ] = useMutation<
    CreateArtifactMutationMutation,
    CreateArtifactMutationMutationVariables
  >(createArtifactMutation);
  const [
    createFeedbackRequests,
    { loading: loadingFeedbackRequests, error: errorFeedbackRequests },
  ] = useMutation<
    CreateFeedbackRequestsMutationMutation,
    CreateFeedbackRequestsMutationMutationVariables
  >(createFeedbackRequestsMutation);
  const loading = loadingCreateArtifact || loadingFeedbackRequests;
  const error = errorCreateArtifact || errorFeedbackRequests;

  const handleChangeTitle = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, title: removeBreaklines(e.target.value) });
  };
  const handleChangeTitleFromEditor = (value: string) => {
    setForm({ ...form, title: removeBreaklines(value) });
  };

  const handleChangeArtifactType = (artifactType: ArtifactType) => () => {
    setForm({ ...form, artifactType });
  };

  const handleSubmitForm = (
    e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
  };

  const handleChangeCoreValue = useCallback(
    (recognitionCoreValue: SelectOption<number> | null) => {
      setForm({
        ...form,
        recognitionCoreValueId: recognitionCoreValue?.value
          ? recognitionCoreValue.value
          : null,
      });
    },
    [form]
  );

  const handleClickClearRecognitionCoreValue = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    handleChangeCoreValue(null);
  };

  // 2 lines below are a hack to make sure
  // the title input has a minimum of 1 line when empty
  // sometimes it loads with multiple lines.
  useLayoutEffect(() => setIsRerendered(true), []);

  useEffect(() => {
    const artifactCreationDialogUrls = Object.values(artifactTypeUrl).map(
      (path) => `/${path}-new`
    );
    const isArtifactCreationDialogUrl = !!matchPath(location.pathname, {
      path: artifactCreationDialogUrls,
      exact: true,
      strict: true,
    });
    const newPathName = `/${artifactTypeUrl[form.artifactType]}-new`;
    if (isArtifactCreationDialogUrl && newPathName !== location.pathname) {
      link.replace({ ...location, pathname: newPathName });
    }
  }, [form.artifactType, location]);

  const feedbackRecipientIsCurrentUser =
    form.feedbackRecipients.length === 1 &&
    form.feedbackRecipients[0].id === currentUser.id;

  const feedbackSendersContainsCurrentUser = !!form.feedbackSenders.find(
    ({ id }) => id === currentUser.id
  );
  const isFeedbackRequestedForMe =
    isFeedback &&
    form.feedbackState === FeedbackState.Requested &&
    feedbackRecipientIsCurrentUser;
  const isFeedbackRequestedForOther =
    isFeedback &&
    form.feedbackState === FeedbackState.Requested &&
    !feedbackRecipientIsCurrentUser;

  const handleSaveForm = () => {
    setHasSubmittedOnce(true);
    setShowConfirmFeedback(false);
    if (formInvalid) return;
    const startValue = !isGoal
      ? undefined
      : getStartValueForProgressType({
          progressType: form.progressType,
          startValue: Number(form.startValue),
        });
    const targetValue = !isGoal
      ? undefined
      : getTargetValueForProgressType({
          progressType: form.progressType,
          targetValue: Number(form.targetValue),
        });
    const variables = {
      artifactType: form.artifactType,
      title: form.title,
      meetingId: form.meetingId,
      organizationId: form.organizationId,
      additionalFields: {
        ...form.additionalFields,
        // decision
        decision:
          form.artifactType === ArtifactType.Decision ? form.title : undefined,
        // goals & action items
        dueDate: isGoal
          ? form.dueDate
          : isActionItem
          ? form.actionItemDueDate
          : null,

        // goals
        parentGoalId: isGoal ? form.parentGoal?.value : undefined,
        childGoalIds: isGoal
          ? compact(form.childGoals.map(({ value }) => value))
          : undefined,
        teamIds:
          isGoal && form.scope === GoalScope.Team
            ? form.teams.map(({ id }) => id)
            : undefined,
        startDate: isGoal ? form.startDate : null,
        ownerIds:
          isGoal && form.owners ? form.owners.map(({ id }) => id) : undefined,
        contributorIds:
          isGoal && form.contributors
            ? form.contributors.map(({ id }) => id)
            : undefined,
        goalScope: isGoal && form.scope ? form.scope : undefined,
        goalVisibility:
          isGoal && form.goalVisibility ? form.goalVisibility : undefined,
        progressType: isGoal ? form.progressType : undefined,
        startValue: startValue,
        currentValue: startValue,
        targetValue: targetValue,
        keyResults: isGoal ? sanitizeKeyResults(form.keyResults) : undefined,
        goalKpiId: isGoal && form.kpi?.id ? form.kpi.id : undefined,

        // action item
        assigneeId:
          isActionItem && form.assignee && form.assignee.id !== graphqlNone
            ? form.assignee.id
            : undefined,
        actionItemState:
          isActionItem && form.actionItemState
            ? form.actionItemState
            : undefined,

        // feedback
        feedbackMessage:
          isFeedback && form.feedbackMessage ? form.feedbackMessage : undefined,
        feedbackRecipients:
          isFeedback && form.feedbackRecipients.length > 0
            ? form.feedbackRecipients.map(({ id }) => id)
            : undefined,
        feedbackRecipientsCanView: isFeedback
          ? form.feedbackRecipientsCanView
          : undefined,
        feedbackCreatorCanView: isFeedback
          ? form.feedbackCreatorCanView
          : undefined,
        feedbackRecipientsManagersCanView: isFeedback
          ? form.feedbackRecipientsManagersCanView
          : undefined,
        feedbackAdminsCanView: isFeedback
          ? form.feedbackAdminsCanView
          : undefined,
        feedbackState: isFeedback ? form.feedbackState : undefined,

        // recognition
        recognitionRecipients:
          isRecognition &&
          form.recognitionRecipients &&
          form.recognitionRecipients.length > 0
            ? form.recognitionRecipients.map(({ id }) => id)
            : undefined,
        recognitionCoreValueId:
          isRecognition && form.recognitionCoreValueId
            ? form.recognitionCoreValueId
            : undefined,
      },
    };

    if (isFeedback && form.feedbackState === FeedbackState.Requested) {
      const { title, meetingId, organizationId } = variables;
      createFeedbackRequests({
        variables: {
          title,
          meetingId,
          organizationId,
          ...variables.additionalFields,
          feedbackSenderIds:
            isFeedback && form.feedbackSenders.length > 0
              ? form.feedbackSenders.map(({ id }) => id)
              : undefined,
        },
        onCompleted: () => {
          successNotificationVar({
            title: "Feedback requested",
          });
          onClose();
        },
        refetchQueries: refetchQueries,
        onError: onNotificationErrorHandler(),
      });
    } else {
      createArtifact({
        variables: {
          ...variables,
          additionalFields: {
            ...variables.additionalFields,
            feedbackSenderId:
              isFeedback && form.feedbackSenders.length === 1
                ? form.feedbackSenders[0].id
                : undefined,
          },
        },
        onError: onNotificationErrorHandler(),
        onCompleted: (response) => {
          const createdArtifact = response.createOrUpdateArtifact?.artifact;
          if (!createdArtifact) return;
          if (isRecognition) {
            showConfetti();
          }
          onClose(createdArtifact);
          updateArtifactsCacheWithCreatedArtifact(createdArtifact);
        },
        refetchQueries: uniq([
          getMeetingRecognitionsQuery,
          getDashboardRecognitionsQuery,
          getGoalOverviewRelatedEntitiesQuery,
          ...refetchQueries,
        ]),
      });
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (location.state?.background) {
      link.redirect(location.state.background);
    } else if (location.state?.previousPathname) {
      link.redirect(location.state.previousPathname);
    } else {
      link.redirect("/");
    }
  };

  const handleKeyDownTitleInput = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (isCommandEnterEvent(e)) {
      handleSaveForm();
    } else if (isEnterEvent(e)) {
      e.preventDefault();
      handleSaveForm();
    }
  };

  const handleChangeActionItemAssignee = (
    assignee: UserComboboxUserOption | null
  ) => {
    setForm({ ...form, assignee: assignee || undefined });
  };

  const handleChangeActionItemState = ({
    value: actionItemState,
  }: {
    value: number;
  }) => {
    setForm({ ...form, actionItemState });
  };

  const handleChangeActionItemDueDate = (updatedDate: Date) => {
    const actionItemDueDate = updatedDate
      ? moment(updatedDate).format("YYYY-MM-DD")
      : null;
    setForm({ ...form, actionItemDueDate });
  };

  const handleRemoveRecognitionRecipient = (recipient: BasicUser) => {
    const recognitionRecipients = (form.recognitionRecipients || []).filter(
      ({ id }) => recipient.id !== id
    );
    setForm({ ...form, recognitionRecipients });
  };
  const handleAddRecognitionRecipient = (recipient: UserComboboxUserOption) => {
    const recognitionRecipients = (form.recognitionRecipients || []).concat(
      recipient
    );
    setForm({ ...form, recognitionRecipients });
  };

  const handleRemoveFeedbackRecipient = (recipient: BasicUser) => {
    const feedbackRecipients = (form.feedbackRecipients || []).filter(
      ({ id }) => recipient.id !== id
    );
    setForm({ ...form, feedbackRecipients });
  };
  const handleAddFeedbackRecipient = (recipient: UserComboboxUserOption) => {
    const feedbackRecipients = (form.feedbackRecipients || []).concat(
      recipient
    );
    setForm({ ...form, feedbackRecipients });
  };
  const handleAddFeedbackSender = (sender: UserComboboxUserOption) => {
    setForm({ ...form, feedbackSenders: [...form.feedbackSenders, sender] });
  };
  const handleRemoveFeedbackSender = (feedbackSenderToRemove: BasicUser) => {
    setForm({
      ...form,
      feedbackSenders: form.feedbackSenders.filter(
        ({ id }) => id !== feedbackSenderToRemove.id
      ),
    });
  };
  const handleChangeFeedbackMessage = (feedbackMessage: string) => {
    setForm({ ...form, feedbackMessage });
  };
  const handleChangeFeedbackDraft = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      feedbackState: e.target.checked
        ? FeedbackState.Draft
        : FeedbackState.Sent,
    });
  };
  const handleChangeFeedbackTab = (
    option: ToggleButtonGroupType<FeedbackTabEnum>
  ) => {
    const formRecipientsWithoutCurrentUser =
      form.feedbackRecipients?.filter(({ id }) => id !== currentUser.id) || [];
    if (option.value === FeedbackTabEnum.give) {
      setForm({
        ...form,
        feedbackState: FeedbackState.Sent,
        feedbackSenders: [currentUser],
        feedbackRecipients: formRecipientsWithoutCurrentUser,
        feedbackCreatorCanView: true,
      });
    } else if (option.value === FeedbackTabEnum.requestForMe) {
      setForm({
        ...form,
        feedbackState: FeedbackState.Requested,
        feedbackSenders: form.feedbackSenders.filter(
          ({ id }) => id !== currentUser.id
        ),
        feedbackRecipients: [currentUser],
        feedbackCreatorCanView: true,
      });
    } else if (option.value === FeedbackTabEnum.requestForOther) {
      setForm({
        ...form,
        feedbackState: FeedbackState.Requested,
        feedbackRecipients: formRecipientsWithoutCurrentUser,
        feedbackSenders: form.feedbackSenders.filter(
          ({ id }) => id !== currentUser.id
        ),
      });
    }
  };

  const handleShowConfirmFeedback = () => {
    setHasSubmittedOnce(true);
    if (formInvalid) return;
    setShowConfirmFeedback(true);
  };

  const handleCancelConfirmFeedback = () => {
    setShowConfirmFeedback(false);
    handleClose();
  };

  const artifactName = label(form.artifactType);
  const invalidGoalDate =
    form.startDate &&
    form.dueDate &&
    moment(form.startDate).isAfter(form.dueDate);
  let formInvalid = "";
  if (form.title.trim().length === 0) {
    formInvalid = "Add a title";
  } else if (
    isFeedback &&
    form.feedbackState !== FeedbackState.Requested &&
    isEmptyValue(form.feedbackMessage)
  ) {
    formInvalid = "Add a feedback message";
  } else if (invalidGoalDate) {
    formInvalid = "Fix the dates.";
  }

  // RECOGNITION
  else if (
    isRecognition &&
    form.recognitionRecipients &&
    form.recognitionRecipients.length === 0
  ) {
    formInvalid = "Pick at least one recipient.";
  } else if (
    isRecognition &&
    form.recognitionRecipients &&
    form.recognitionRecipients.find(({ id }) => id === currentUser.id)
  ) {
    formInvalid = `You cannot give a ${label("recognition")} yourself.`;
  }

  // FEEDBACK
  else if (
    isFeedback &&
    !form.feedbackCreatorCanView &&
    !form.feedbackAdminsCanView &&
    !form.feedbackRecipientsCanView &&
    !form.feedbackRecipientsManagersCanView
  ) {
    formInvalid = "Pick a visibility option.";
  } else if (isFeedback && form.feedbackRecipients.length === 0) {
    formInvalid = "Pick at least one subject.";
  } else if (isFeedback && form.feedbackSenders.length === 0) {
    formInvalid = "Select a user to request feedback from.";
  } else if (
    isFeedback &&
    form.feedbackState === FeedbackState.Requested &&
    feedbackRecipientIsCurrentUser &&
    feedbackSendersContainsCurrentUser
  ) {
    formInvalid = "You cannot request feedback from yourself.";
  } else if (
    isFeedback &&
    form.feedbackState === FeedbackState.Sent &&
    form.feedbackRecipients.find(({ id }) => id === currentUser.id)
  ) {
    formInvalid = `You cannot give a ${label("feedback")} yourself.`;
  }
  const placeholder = isRecognition
    ? `Type ${label("recognition", {
        pluralize: false,
        capitalize: false,
      })} message...`
    : isFeedback && form.feedbackState === FeedbackState.Requested
    ? "What feedback are you requesting?"
    : isFeedback
    ? `Title of the feedback you wish to provide...`
    : `Type ${artifactName} title...`;

  return (
    <Dialog
      as="div"
      aria-label="Artifact dialog"
      className="fixed z-modal inset-0 overflow-y-auto text-gray-800"
      open
      onClose={handleClose}
      initialFocus={inputTitleRef}
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="flex min-h-full py-4 px-3 items-start sm:pt-20 sm:justify-center text-center">
        {allowedArtifactTypes.length > 0 ? (
          <form
            className="w-full md:max-w-2xl mt-4 align-top transform rounded-lg bg-white text-left shadow-xl"
            onSubmit={handleSubmitForm}
          >
            <div className="z-dropdown absolute -top-4 right-0 bg-white rounded-full p-1 flex gap-2">
              <button
                type="button"
                aria-label="Meeting dialog close button"
                className="p-1 rounded-full text-gray-400 hover:bg-gray-100 focus:outline-none"
                onClick={handleClose}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="relative flex flex-col divide-y">
              {hideTypeSelector ? (
                <div className="py-4 px-6 w-full flex flex-col gap-2">
                  <div className="text-base font-medium text-gray-800">
                    {isFeedback
                      ? "Give feedback"
                      : `Create ${label(form.artifactType)}`}
                  </div>
                </div>
              ) : (
                <div className="py-4 px-6 w-full flex flex-col gap-2">
                  <div className="text-sm font-medium text-gray-500">
                    Select type
                  </div>
                  <div className="flex flex-col flex-wrap sm:flex-row gap-2 w-full">
                    {allowedArtifactTypes.map(({ artifactType }) => (
                      <button
                        className={classNames(
                          "rounded-lg py-1 px-1.5 text-sm text-gray-500 flex items-center gap-1 tracking-tight border border-transparent",
                          artifactType === form.artifactType
                            ? "bg-gray-100 text-gray-800 border-gray-200"
                            : "bg-white hover:border-gray-200 hover:text-gray-800"
                        )}
                        type="button"
                        key={artifactType}
                        onClick={handleChangeArtifactType(artifactType)}
                      >
                        <ArtifactIcon artifact={{ artifactType }} isStatic />
                        {label(artifactType, { capitalize: true })}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {form.artifactType === ArtifactType.Feedback && (
                <div className="flex items-center gap-4 py-4 px-6">
                  <DialogLabel>Type</DialogLabel>
                  <div className="flex">
                    <div>
                      <ToggleButtonGroup<FeedbackTabEnum>
                        theme={ToggleButtonGroupTheme.buttons}
                        buttons={compact([
                          {
                            title: "Give feedback",
                            value: FeedbackTabEnum.give,
                            active:
                              form.feedbackState !== FeedbackState.Requested,
                          },
                          {
                            title: "Request feedback for me",
                            value: FeedbackTabEnum.requestForMe,
                            active: isFeedbackRequestedForMe,
                          },
                          {
                            title: "Request for others",
                            value: FeedbackTabEnum.requestForOther,
                            active: isFeedbackRequestedForOther,
                          },
                        ])}
                        onClick={handleChangeFeedbackTab}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-4 py-4 px-6">
                <DialogLabel>
                  {isRecognition ? "Message" : "Title"} *
                </DialogLabel>
                <div className="flex-1 flex gap-2">
                  {waffle.flag_is_active("tiptap-ai") ? (
                    <TextWithNoBreakLinesWysiwyg
                      editable
                      autofocus
                      placeholder={placeholder}
                      value={form.title}
                      onChangeValue={handleChangeTitleFromEditor}
                      className="block text-sm px-3 py-0.5 w-full outline-0 border bg-transparent rounded-lg resize-none"
                      renderAi={
                        waffle.flag_is_active("tiptap-ai")
                          ? WYSIWYGAIButton
                          : null
                      }
                      deps={[form.artifactType]}
                    />
                  ) : (
                    <CustomTextareaAutosize
                      className="block text-sm px-3 py-1.5 w-full outline-0 border bg-transparent rounded-lg resize-none"
                      aria-label="Artifact dialog title input"
                      data-lpignore="true"
                      autoComplete="off"
                      value={form.title}
                      placeholder={placeholder}
                      onChange={handleChangeTitle}
                      onKeyDown={handleKeyDownTitleInput}
                      ref={inputTitleRef}
                      autoFocus
                      minRows={1}
                    />
                  )}
                </div>
              </div>
              {form.artifactType === ArtifactType.ActionItem && (
                <div className="flex items-center gap-4 py-4 px-6">
                  <DialogLabel>Assignee</DialogLabel>
                  <div className="flex-1">
                    <ActionItemPeopleDropdown
                      actionItem={form}
                      onChangeAssignee={handleChangeActionItemAssignee}
                    />
                  </div>
                </div>
              )}
              {form.artifactType === ArtifactType.ActionItem && (
                <div className="flex items-center gap-4 py-4 px-6">
                  <DialogLabel>State</DialogLabel>
                  <div className="flex-1">
                    <ActionItemStateDropdownInput
                      artifact={form}
                      onChangeState={handleChangeActionItemState}
                    />
                  </div>
                </div>
              )}
              {form.artifactType === ArtifactType.ActionItem && (
                <div className="flex items-center gap-4 py-4 px-6">
                  <DialogLabel>Due date</DialogLabel>
                  <div className="flex-1">
                    <DatePicker
                      selected={
                        form.actionItemDueDate
                          ? moment(form.actionItemDueDate).toDate()
                          : null
                      }
                      placeholderText="No due date"
                      onChange={handleChangeActionItemDueDate}
                      dateFormat="MMM d, yyyy"
                      className="text-sm px-3 py-1.5 outline-0 border bg-transparent rounded-lg"
                      renderCustomHeader={({
                        date: currentDate,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <DatePickerCustomHeaderWithClearButton
                          date={
                            form.actionItemDueDate
                              ? moment(form.actionItemDueDate).toDate()
                              : null
                          }
                          onChangeDate={handleChangeActionItemDueDate}
                          clearable
                          currentDate={currentDate}
                          decreaseMonth={decreaseMonth}
                          increaseMonth={increaseMonth}
                          prevMonthButtonDisabled={prevMonthButtonDisabled}
                          nextMonthButtonDisabled={nextMonthButtonDisabled}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {isRecognition && (
                <>
                  <div className="flex items-center gap-4 py-4 px-6">
                    <DialogLabel>Recipients *</DialogLabel>
                    <RecipientForm
                      canChange
                      recipients={form.recognitionRecipients || []}
                      onAddRecipient={handleAddRecognitionRecipient}
                      onRemoveRecipient={handleRemoveRecognitionRecipient}
                    />
                  </div>
                  <div className="flex items-center gap-4 py-4 px-6">
                    <DialogLabel>Core Value</DialogLabel>
                    <div className="flex items-center gap-2">
                      <CoreValueDropdownInput
                        selectedCoreValueId={form.recognitionCoreValueId}
                        coreValues={coreValues}
                        onChange={handleChangeCoreValue}
                        className="border rounded-lg"
                      />
                      {form.recognitionCoreValueId && (
                        <button
                          type="button"
                          onClick={handleClickClearRecognitionCoreValue}
                          className="rounded hover:bg-black/5 p-0.5"
                        >
                          <XIcon className="h-4 w-4 text-gray-400 pointer-events-none" />
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
              {isFeedback && form.feedbackState !== FeedbackState.Requested && (
                <>
                  <div className="flex items-start gap-4 py-4 px-6">
                    <DialogLabel className="mt-1">Message *</DialogLabel>
                    <div
                      className="flex-1 flex gap-2"
                      aria-label="Artifact creation feedback message"
                    >
                      <TextareaWysiwyg
                        value={form.feedbackMessage}
                        editable
                        onChangeValue={handleChangeFeedbackMessage}
                        className="px-3 py-0.5"
                        placeholder="Describe your feedback..."
                        renderAi={
                          waffle.flag_is_active("tiptap-ai")
                            ? WYSIWYGAIButton
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-4 py-4 px-6">
                    <DialogLabel>Subjects *</DialogLabel>
                    <RecipientForm
                      canChange
                      recipients={form.feedbackRecipients || []}
                      onAddRecipient={handleAddFeedbackRecipient}
                      onRemoveRecipient={handleRemoveFeedbackRecipient}
                    />
                  </div>
                </>
              )}
              {isFeedback &&
                form.feedbackState === FeedbackState.Requested &&
                !feedbackRecipientIsCurrentUser && (
                  <>
                    <div className="flex items-center gap-4 py-4 px-6">
                      <DialogLabel>Subject</DialogLabel>
                      <RecipientForm
                        canChange
                        recipients={form.feedbackRecipients || []}
                        maxRecipients={1}
                        excludeUserIds={form.feedbackSenders.map(
                          ({ id }) => id
                        )}
                        showCurrentUser={false}
                        onAddRecipient={handleAddFeedbackRecipient}
                        onRemoveRecipient={handleRemoveFeedbackRecipient}
                        placeholder="Pick a subject"
                      />
                    </div>
                  </>
                )}
              {isFeedback && form.feedbackState === FeedbackState.Requested && (
                <div className="flex items-center gap-4 py-4 px-6">
                  <DialogLabel>From *</DialogLabel>
                  <RecipientForm
                    canChange
                    recipients={form.feedbackSenders}
                    excludeUserIds={(form.feedbackRecipients || []).map(
                      ({ id }) => id
                    )}
                    onAddRecipient={handleAddFeedbackSender}
                    onRemoveRecipient={handleRemoveFeedbackSender}
                  />
                </div>
              )}
              {isFeedback && (
                <div className="flex items-start gap-4 py-4 px-6">
                  <DialogLabel>Visibility</DialogLabel>
                  <div className="flex flex-col gap-1 text-sm">
                    {form.feedbackState === FeedbackState.Requested &&
                      !feedbackRecipientIsCurrentUser && (
                        <label className="flex items-center gap-1.5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={form.feedbackCreatorCanView}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                feedbackCreatorCanView: e.target.checked,
                              })
                            }
                          />
                          Me
                        </label>
                      )}
                    <label className="flex items-center gap-1.5 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={form.feedbackRecipientsCanView}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            feedbackRecipientsCanView: e.target.checked,
                          })
                        }
                      />
                      {form.feedbackState === FeedbackState.Requested &&
                      feedbackRecipientIsCurrentUser
                        ? "Me"
                        : "Subjects"}
                    </label>
                    <label className="flex items-center gap-1.5 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={form.feedbackRecipientsManagersCanView}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            feedbackRecipientsManagersCanView: e.target.checked,
                          })
                        }
                      />
                      {form.feedbackState === FeedbackState.Requested &&
                      feedbackRecipientIsCurrentUser
                        ? "My managers"
                        : "Subjects' managers"}
                    </label>
                    <label className="flex items-center gap-1.5 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={form.feedbackAdminsCanView}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            feedbackAdminsCanView: e.target.checked,
                          })
                        }
                      />
                      Admins
                    </label>
                  </div>
                </div>
              )}
              {isFeedback && form.feedbackState !== FeedbackState.Requested && (
                <div className="flex items-center gap-4 py-4 px-6">
                  <DialogLabel>State</DialogLabel>
                  <div className="flex-1">
                    <label className="flex text-sm items-center gap-1.5">
                      <input
                        type="checkbox"
                        checked={form.feedbackState === FeedbackState.Draft}
                        onChange={handleChangeFeedbackDraft}
                      />
                      Draft
                    </label>
                  </div>
                </div>
              )}
              {form.artifactType === ArtifactType.Goal && (
                <ArtifactCreationGoalFields
                  form={form}
                  onChangeForm={setForm}
                />
              )}
            </div>
            {matchingAllowedArtifactType &&
              !matchingAllowedArtifactType.permission && (
                <div className="py-4 px-6 text-gray-600 text-sm">
                  Please{" "}
                  <AppLink
                    className="text-blue-link hover:underline"
                    to="/billing"
                  >
                    upgrade
                  </AppLink>{" "}
                  to be able to create {artifactName}.
                </div>
              )}
            <div
              className="py-4 px-6 flex flex-col gap-2 border-t empty:hidden"
              aria-label="Artifact dialog buttons"
            >
              {showConfirmFeedback && (
                <div className="flex flex-col gap-4">
                  <div className="font-medium">
                    Confirm you want to sent this feedback?
                  </div>
                  <div className="text-sm">
                    This feedback about{" "}
                    <span className="font-medium">
                      {joinStringWithCommaAnd(
                        form.feedbackRecipients.map((r) => r.name)
                      )}
                    </span>{" "}
                    will be visible to:{" "}
                    <span className="font-medium">
                      {getFeedbackSentToString({
                        recipientsCanView: !!form.feedbackRecipientsCanView,
                        recipientsManagersCanView:
                          !!form.feedbackRecipientsManagersCanView,
                        adminsCanView: !!form.feedbackAdminsCanView,
                      })}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      text="Confirm"
                      onClick={handleSaveForm}
                      theme={buttonTheme.primary}
                      type="button"
                      small
                    />
                    <Button
                      text="Cancel"
                      onClick={handleCancelConfirmFeedback}
                      type="button"
                      small
                    />
                  </div>
                </div>
              )}
              {!showConfirmFeedback && (
                <div className="grid grid-cols-2 gap-4">
                  <Button
                    disabled={
                      loading ||
                      (formInvalid !== "" && hasSubmittedOnce) ||
                      (matchingAllowedArtifactType &&
                        !matchingAllowedArtifactType.permission)
                    }
                    type="button"
                    onClick={
                      isFeedback && form.feedbackState === FeedbackState.Sent
                        ? handleShowConfirmFeedback
                        : handleSaveForm
                    }
                    theme="primary"
                    className="flex flex-1 w-full items-center gap-2"
                    tooltip={formInvalid}
                  >
                    {isFeedback &&
                    form.feedbackState === FeedbackState.Requested
                      ? "Request feedback"
                      : isFeedback
                      ? "Give feedback"
                      : `Create ${artifactName}`}
                    {loading && <Loading mini size="4" color="white" />}
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </Button>
                </div>
              )}
              {hasSubmittedOnce && formInvalid && (
                <div className="text-red-700 text-xs flex items-center gap-1.5">
                  <ExclamationCircleIcon className="h-4 w-4" />
                  {formInvalid}
                </div>
              )}
              {error && (
                <div className="mt-4">
                  <GraphqlError
                    whatDidNotWork="The artifact could not be created."
                    error={error}
                  />
                </div>
              )}
            </div>
          </form>
        ) : (
          <div className="p-4 bg-white shadow z-modal rounded-lg flex flex-col gap-2 items-start">
            <div>Your organization has disabled the creation of artifacts.</div>
            <div>
              <Button text="Close" onClick={handleClose} small />
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ArtifactCreationDialog;
