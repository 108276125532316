import { gql } from "@apollo/client";

export const CalendarMeetingFragment = gql`
  fragment CalendarMeetingFragment on MeetingNode {
    id
    draft
    status
    externalUrl
    ignored
    videoConferenceType
    videoConferenceUrl
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    title
    startDatetime
    isRecurring
    endDatetime
    meetingGroupId
    organizer {
      id
      name
    }
    meetingGroup {
      id
      hasTemplate
      isFormalOneonone
      facilitator {
        id
        name
        avatar
      }
    }
  }
`;

export const CalendarEventFragment = gql`
  ${CalendarMeetingFragment}
  fragment CalendarEventFragment on BasicMeetingNode {
    id
    startDatetime
    endDatetime
    title
    meetingGroupId
    canRead {
      permission
    }
    meeting {
      id
      ...CalendarMeetingFragment
    }
  }
`;

export default gql`
  ${CalendarEventFragment}
  ${CalendarMeetingFragment}
  query getCalendarMeetings(
    $forUserId: Int!
    $startDatetime_Lte: DateTime
    $startDatetime_Gte: DateTime
    $ignored: Boolean
    $statusIn: [EventStatus]
    $participants: [Int]
    $after: String
  ) {
    calendar(
      forUserId: $forUserId
      after: $after
      startDatetime_Lte: $startDatetime_Lte
      startDatetime_Gte: $startDatetime_Gte
      ignored: $ignored
      status_In: $statusIn
      participants: $participants
      first: 500
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...CalendarEventFragment
        }
      }
    }
  }
`;
