import { LockClosedIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { words } from "lodash";
import { Draggable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  NotesRequirement,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import TopicAssignee from "@apps/topic-assignee/topic-assignee";
import TopicDropdownMenu from "@apps/topic-dropdown-menu/topic-dropdown-menu";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

import { meetingPaddingClassName } from "../helpers";
import DiscussionNotes from "./discussion-notes";
import IndividualNotesContainer from "./individual-notes-container";
import TopicToolbar from "./topic-toolbar";

const Topic = ({
  topic,
  index,
  meeting,
  opensInSidebar,
  isInExtension,
  searchQuery,
  currentPageMeeting = null,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  index: number;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  opensInSidebar: boolean;
  isInExtension: boolean;
  searchQuery: string;
  relevantSections: any;
  currentPageMeeting?: MeetingViewMeetingNodeNewPageFragmentFragment | null;
}) => {
  const location = useLocation<TFLocationState>();

  let titleWithSearchHighlights = topic.title;
  const searchQueryWords = words(searchQuery);
  if (searchQueryWords.length > 0) {
    const regex = new RegExp(`(${searchQueryWords.join("|")})`, "gim");
    titleWithSearchHighlights = topic.title.replace(regex, (matched) => {
      return `<span class="bg-yellow-200">${matched}</span>`;
    });
  }

  const topicTemplateId = topic.linkedTemplateTopic?.topicTemplate?.id;
  const topicTemplateTitle = topic.linkedTemplateTopic?.topicTemplate?.title;
  let notesHelperText = "";
  if (topic.notesRequirement === NotesRequirement.OneParticipant) {
    notesHelperText = "At least one participant must add notes.";
  } else if (topic.notesRequirement === NotesRequirement.AllParticipants) {
    notesHelperText = "All participants must add notes.";
  }

  return (
    <Draggable draggableId={`topic-${topic.id}`} index={index}>
      {(provided, snapshot) => {
        return (
          <li
            key={topic.id}
            id={`meeting-${meeting.id}-topic-${topic.id}`}
            className={classNames(
              "group/topic",
              "py-4",
              topic.isMandatory && "relative js-mandatory-topic",
              snapshot.isDragging && "bg-purple-50/90 shadow-xl rounded-xl"
            )}
            {...provided.draggableProps}
            ref={provided.innerRef}
            aria-label={`Topic ${topic.title}`}
            data-testid={`container-topic-${topic.id}`}
          >
            <div
              className={classNames(
                "flex w-full relative group",
                meetingPaddingClassName
              )}
            >
              <div className="flex-1 flex gap-1">
                {!isInExtension && (
                  <span
                    className={classNames(
                      "cursor-grab text-gray-400 absolute py-0.5 px-0 left-0.5 top-1 bg-gray-100 hover:bg-gray-200 rounded hidden sm:group-hover:block",
                      snapshot.isDragging && "block"
                    )}
                    {...provided.dragHandleProps}
                    aria-label="Topic drag handle"
                  >
                    <MdOutlineDragIndicator className="h-4 w-4 " />
                  </span>
                )}

                {/* TEXT */}
                <div className="">
                  <AppLink
                    to={toWithBackground({
                      pathname: getUrl({
                        topicId: topic.id,
                        meetingId: meeting.id,
                        meetingGroupId: meeting.meetingGroup?.id,
                      }),
                      location,
                    })}
                    className="inline hover:underline text-lg font-medium p-1 -ml-1"
                    aria-label="Topic title"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: titleWithSearchHighlights,
                      }}
                    />
                  </AppLink>
                </div>
                {!isInExtension && !!topicTemplateId && (
                  <Tooltip
                    text={`This topic has been added from the template: ${topicTemplateTitle}.`}
                  >
                    <AppLink
                      to={`/templates/${topicTemplateId}`}
                      className="pt-1.5"
                    >
                      <TbTemplate className="h-4 w-4 text-gray-500" />
                    </AppLink>
                  </Tooltip>
                )}
                {!isInExtension && topic.isMandatory && (
                  <Tooltip
                    text={`The creator of this template has marked the topic as mandatory and it cannot be edited. ${notesHelperText}`}
                  >
                    <span className="pt-1.5">
                      <LockClosedIcon className="h-4 w-4 text-gray-500" />
                    </span>
                  </Tooltip>
                )}
              </div>

              {/* STATE & ACTIONS */}
              {/* wrapping div is necessary to keep icon align to top */}
              {!isInExtension && (
                <div>
                  <div className="hidden group-hover/topic:flex items-center gap-2">
                    <TopicToolbar topic={topic} meeting={meeting} />
                    <TopicAssignee
                      topic={topic}
                      assignableUsers={meeting.participants?.edges.map(
                        (edge) => edge?.node?.user
                      )}
                    />
                    <div className="flex">
                      <TopicDropdownMenu
                        meetingGroup={meeting.meetingGroup}
                        topic={topic}
                        meeting={meeting}
                        portal={opensInSidebar}
                        size="5"
                        className="py-0.5 hover:bg-black hover:bg-opacity-5"
                        currentPageMeeting={currentPageMeeting}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="pt-2">
              {topic.includesSharedNotes && (
                <DiscussionNotes
                  topic={topic}
                  meeting={meeting}
                  searchQuery={searchQuery}
                />
              )}
              <div>
                <IndividualNotesContainer
                  topic={topic}
                  meeting={meeting}
                  meetingGroup={meeting.meetingGroup}
                  searchQuery={searchQuery}
                  className={classNames("mb-2", meetingPaddingClassName)}
                />
              </div>
            </div>
          </li>
        );
      }}
    </Draggable>
  );
};

export default withErrorBoundary(Topic, {
  fallback: <Error description={"The topic could not be rendered."} />,
});
