import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  ${CommonRecognitionArtifactFragment}
  mutation updateArtifact(
    $artifactId: Int!
    $additionalFields: AdditionalArtifactInput!
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        id
        title
        artifactType
        canDelete {
          permission
        }
        ... on ActionItemArtifactNode {
          isComplete
          actionItemState
          dueDate
          assignee {
            id
            avatar
            name
          }
        }
        ... on GoalArtifactNode {
          ...CommonGoalArtifactFragment
          kpi {
            id
            title
            currentMeasurement {
              id
              measurement
            }
          }
          contributors {
            edges {
              node {
                id
                name
                avatar
              }
            }
          }
        }
        ... on RecognitionArtifactNode {
          ...CommonRecognitionArtifactFragment
        }
        ... on DecisionArtifactNode {
          isDraft
          decisionState
        }
      }
    }
  }
`;
