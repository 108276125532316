import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import {
  ChevronDownIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/outline";
import { ReactNode } from "react";
import { GetMeetingGroupQueryQuery } from "types/graphql-schema";

import updateMeetingGroupMutation from "@apps/meeting/graphql/update-meeting-group-mutation";
import {
  MeetingOrTemplateVisibility,
  getMeetingAllowVisibilityLabel as getMeetingContentAccessLabel,
} from "@apps/meeting/helpers";
import Loading from "@components/loading/loading";
import MeetingContentAccess from "@components/meeting-content-access/meeting-content-access";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

const MeetingContentAccessDropdown = ({
  meetingGroup,
  children,
  alignLeft = true,
}: {
  children?: ReactNode;
  alignLeft?: boolean;
  meetingGroup: NonNullable<GetMeetingGroupQueryQuery["meetingGroup"]>;
}) => {
  const title = meetingGroup?.title || "";
  useDocumentTitle(title);

  const [updateMeetingGroup, { loading }] = useMutation(
    updateMeetingGroupMutation
  );

  const handleSaveVisibility = ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => {
    updateMeetingGroup({
      variables: {
        meetingGroupId: assertNonNull(meetingGroup).id,
        allowAdminVisibility,
        allowOrgVisibility,
        allowManagementTreeVisibility,
      },
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            allowManagementTreeVisibility,
            allowAdminVisibility,
            allowOrgVisibility,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <Popover>
      {children ? (
        children
      ) : (
        <Popover.Button
          className={classNames(
            "bg-gray-100 rounded-lg px-3 py-2 text-xs tracking-tight text-gray-500 flex items-center gap-1.5 shrink-0",
            meetingGroup?.canUpdate?.permission && "hover:bg-gray-200"
          )}
          aria-label="Meeting permission dropdown menu button"
          disabled={!meetingGroup?.canUpdate?.permission}
        >
          {loading ? (
            <Loading mini size="4" />
          ) : meetingGroup.allowAdminVisibility ||
            meetingGroup.allowOrgVisibility ? (
            <LockOpenIcon className="h-4 w-4" />
          ) : (
            <LockClosedIcon className="h-4 w-4" />
          )}
          <span className="hidden sm:inline">
            {capitalize(
              getMeetingContentAccessLabel(
                {
                  allowManagementTreeVisibility:
                    meetingGroup.allowManagementTreeVisibility,
                  allowAdminVisibility: meetingGroup.allowAdminVisibility,
                  allowOrgVisibility: meetingGroup.allowOrgVisibility,
                },
                true
              )
            )}
          </span>
          {meetingGroup?.canUpdate?.permission && (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </Popover.Button>
      )}
      <Popover.Panel
        className={classNames(
          alignLeft ? "left-0" : "right-0",
          "absolute mt-1 z-dropdown bg-white shadow-lg rounded-md w-96 py-4 px-6 flex flex-col gap-4 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        )}
      >
        <div className="text-lg font-medium">Change meeting content access</div>
        <MeetingContentAccess
          allowManagementTreeVisibility={
            meetingGroup.allowManagementTreeVisibility
          }
          allowAdminVisibility={meetingGroup.allowAdminVisibility}
          allowOrgVisibility={meetingGroup.allowOrgVisibility}
          isFormalOneonone={meetingGroup.isFormalOneonone}
          onChange={handleSaveVisibility}
        />
      </Popover.Panel>
    </Popover>
  );
};

export default MeetingContentAccessDropdown;
