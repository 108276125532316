import { useQuery } from "@apollo/client";
import { SparklesIcon } from "@heroicons/react/outline";
import { delay } from "lodash";
import { useEffect, useState } from "react";
import {
  GetMeetingTranscriptQueryQuery,
  GetMeetingTranscriptQueryQueryVariables,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import getMeetingTranscriptQuery from "@apps/meeting-transcription-dialog/graphql/get-meeting-transcript-query";
import TranscriptionParticipation from "@apps/meeting-transcription-dialog/participation";
import NoTranscriptCta from "@apps/meeting/components/no-transcript-cta";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePageVisibility from "@components/use-page-visibility/use-page-visibility";
import { classNames } from "@helpers/css";

import AIAssistAsk from "./ai-assist-ask";
import AIAssistMeetingBot from "./ai-assist-bot";
import AIAssistSuggestions from "./ai-assist-suggestions";

enum TabEnum {
  meetingBot = "Notetaker",
  talking = "Stats",
  suggestions = "Suggestions",
  ask = "Ask AI",
  summary = "Summary",
}

const AIAssist = ({
  meeting,
  className,
  isInExtension = false,
}: {
  isInExtension?: boolean;
  className?: string;
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const [askAIChatSessionId, setAskAIChatSessionId] = useState<null | number>(
    null
  );

  const isVisible = usePageVisibility();
  const [activeTab, setActiveTab] = useState<TabEnum | null>(
    isInExtension ? TabEnum.meetingBot : null
  );
  const { data, loading } = useQuery<
    GetMeetingTranscriptQueryQuery,
    GetMeetingTranscriptQueryQueryVariables
  >(getMeetingTranscriptQuery, {
    pollInterval: 30000,
    skipPollAttempt: () => {
      // we don't poll if meeting page is not visible or if the bot is not recording
      return !isVisible || !meeting.botIsRecording;
    },
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });

  const suggestionCount =
    data?.meeting?.transcript?.suggestedArtifacts?.edges.length || 0;

  const handleScrollToMeetingSummary = () => {
    const el = document.querySelector(
      'button[aria-label="Meeting summary toggle"]'
    ) as HTMLButtonElement;
    if (el) {
      if (el.dataset.toggled === "0") el.click();
      delay(() => {
        el.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleClickTab = (option: ToggleButtonGroupType<TabEnum>) => {
    if (option.value === activeTab) {
      setActiveTab(null);
    } else if (option.value === TabEnum.summary) {
      handleScrollToMeetingSummary();
    } else if (option.value) {
      setActiveTab(option.value);
    }
  };

  const canShowAI =
    data?.meeting &&
    (data.meeting?.transcript ||
      meeting.hasPreviousMeetingsWithTopics ||
      (meeting.topics?.edges?.length || 0) > 0);

  useEffect(() => {
    // when changing meeting, we reset Ask AI
    setAskAIChatSessionId(null);
  }, [meeting.id]);

  if (!meeting.organization || !meeting.meetingGroup) {
    return null;
  }
  const organization = meeting.organization;

  return (
    <div
      className={classNames(
        "rounded-lg shadow divide divide-y divide-gray-100 items-center @container/aiassist",
        activeTab === null && "rounded-lg overflow-hidden",
        className
      )}
    >
      <div className="bg-white pl-4 pr-3 py-2 rounded-t-lg flex-col @sm/aiassist:flex-row gap-2 flex @sm/aiassist:justify-between @sm/aiassist:items-center gap-x-2 flex-wrap">
        <div className="text-lg font-medium flex items-center gap-4">
          <div className="text-violet-800 flex items-center gap-2 shrink-0">
            <span>AI Assist</span>
            <SparklesIcon className="h-5 w-5 hidden @lg/aiassist:inline" />
          </div>
        </div>
        <div className="flex justify-end items-center gap-2 ">
          <ToggleButtonGroup<TabEnum>
            theme={ToggleButtonGroupTheme.roundedNormal}
            className="bg-white gap-1 @md/aiassist:gap-2"
            onClick={handleClickTab}
            buttons={Object.values(TabEnum)
              .filter(
                (val) =>
                  val !== TabEnum.meetingBot ||
                  organization.effectivePricingTier === "ultimate"
              )
              .map((tab) => ({
                active: tab === activeTab,
                value: tab,
                className: classNames(
                  "px-1.5 @sm/aiassist:px-2 @md/aiassist:px-3",
                  tab === activeTab
                    ? "bg-violet-500 text-white"
                    : "bg-violet-100 text-violet-800 hover:bg-violet-200",
                  tab === TabEnum.summary && "hidden @sm/aiassist:inline"
                ),
                title:
                  tab === TabEnum.suggestions
                    ? `${tab} ${
                        suggestionCount > 0 ? `(${suggestionCount})` : ""
                      }`
                    : tab,
              }))}
          />
        </div>
      </div>
      {activeTab === null ? (
        <></>
      ) : meeting.organization.effectivePricingTier !== "ultimate" ? (
        <div className="bg-white py-4 px-3 rounded-b-lg text-gray-700 text-sm">
          <BillingDialogCTA className="text-blue-link underline">
            <span>Upgrade your plan</span>
          </BillingDialogCTA>{" "}
          to have access to the AI Assist feature.
        </div>
      ) : !meeting.organization.enableMeetingSummarization ? (
        <div className="bg-white py-4 px-3 rounded-b-lg text-gray-700 text-sm">
          Enable AI Meeting Summarization & Assistant in{" "}
          <AppLink
            to={`/settings/organization/${meeting.organization.id}/settings`}
            className="text-blue-600"
          >
            Organization Settings
          </AppLink>
          .
        </div>
      ) : !data && loading ? (
        <div className="bg-white py-4 px-3 rounded-b-lg">
          <Loading />
        </div>
      ) : activeTab === TabEnum.meetingBot ? (
        <div className="bg-white rounded-b-lg">
          <AIAssistMeetingBot
            meeting={meeting}
            meetingGroup={meeting.meetingGroup}
          />
        </div>
      ) : canShowAI ? (
        <>
          {activeTab === TabEnum.talking ? (
            <div className="bg-white py-1 px-3 rounded-b-lg">
              <TranscriptionParticipation meeting={data.meeting} />
            </div>
          ) : activeTab === TabEnum.suggestions ? (
            <div className="bg-white rounded-b-lg">
              <AIAssistSuggestions meeting={data.meeting} />
            </div>
          ) : activeTab === TabEnum.ask ? (
            <div className="bg-white rounded-b-lg">
              <AIAssistAsk
                meetingId={meeting.id}
                chatSessionId={askAIChatSessionId}
                onChangeChatSessionId={setAskAIChatSessionId}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div className="bg-white py-4 px-3 rounded-b-lg text-sm text-gray-500">
          <NoTranscriptCta videoConferenceType={meeting.videoConferenceType} />
        </div>
      )}
    </div>
  );
};

export default AIAssist;
