import { Popover } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/outline";
import { Ref } from "react";
import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";
import { classNames } from "@helpers/css";

const ExplorerComboboxButton = ({
  prefix = "",
  ariaLabel = "Explorer meeting group combobox",
  setReferenceElement,
  onClickButton,
  label,
  onClear,
  avatarUser,
  clearable = false,
  className = "",
  labelClassName = "",
}: {
  prefix: string;
  ariaLabel: string;
  setReferenceElement?: Ref<HTMLButtonElement>;
  onClickButton?: () => void;
  label: string;
  onClear?: () => void;
  className?: string;
  labelClassName?: string;
  clearable?: boolean;
  avatarUser?: BasicUser;
}) => {
  return (
    <div className="flex items-center flex-1">
      <Popover.Button
        className={classNames(
          "pl-3 pr-2 py-2 flex flex-1 items-center gap-2 text-sm text-gray-800",
          "min-w-0", // prevent child el with flex-1 to overflow and will add ellipsis in that case
          className,
          onClear && clearable && "pr-1 rounded-r-none"
        )}
        aria-label={ariaLabel}
        onClick={onClickButton}
        ref={setReferenceElement}
      >
        {prefix && <span className="font-medium">{prefix}: </span>}
        {avatarUser && <Avatar user={avatarUser} size="4" />}
        <div
          className={classNames(
            "flex-1 min-w-0 text-left truncate",
            labelClassName
          )}
        >
          {label}
        </div>
        <SelectorIcon className="h-4 w-4 text-gray-500" />
      </Popover.Button>
      {clearable && onClear && (
        <button
          onClick={onClear}
          className={classNames(
            className,
            "grow-0 py-1.5 px-0.5 rounded-l-none border-l-0 text-gray-400 hover:bg-gray-100"
          )}
          aria-label="Meeting group combobox remove button"
        >
          <XIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default ExplorerComboboxButton;
