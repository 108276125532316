import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import { Fragment, useState } from "react";
import { MdOutlineAssessment } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  GetMeetingInsightsAndRequirementsQueryQuery,
  GetMeetingInsightsAndRequirementsQueryQueryVariables,
  InsightType,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import getMeetingInsightsAndRequirementsQuery from "@apps/meeting/graphql/get-meeting-insights-and-requirements-query";
import TemplatesDialog from "@apps/templates/dialog";
import { currentUserVar } from "@cache/cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import AppError from "@components/error/error";
import Link from "@components/link/link";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

const RelatedRequirements = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const location = useLocation();
  const currentUser = currentUserVar();
  const { data, loading } = useQuery<
    GetMeetingInsightsAndRequirementsQueryQuery,
    GetMeetingInsightsAndRequirementsQueryQueryVariables
  >(getMeetingInsightsAndRequirementsQuery, {
    variables: {
      meetingId: meeting.id,
    },
    onError: onNotificationErrorHandler(),
  });
  const relatedAssessments = data?.meeting?.relatedAssessments
    ? assertEdgesNonNull(data?.meeting.relatedAssessments)
    : [];
  const insights = data?.meeting?.insights
    ? assertEdgesNonNull(data.meeting.insights)
    : [];
  const meetingTemplateRequirements = insights.filter(
    (insight) => insight.type === InsightType.RequiredTemplate
  );
  const [openTemplateDialog, setOpenTemplatesDialog] = useState(false);
  const [templateDialogTemplateId, setTemplateDialogTemplateId] = useState<
    number | null
  >(null);

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const currentUserIsParticipant = participants.find(
    (participant) => participant.user?.id === currentUser.id
  );

  if (
    relatedAssessments.length === 0 &&
    !(insights.length > 0 && currentUserIsParticipant)
  ) {
    return null;
  }

  const otherParticipant = participants.filter(
    (participant) => participant.user?.id !== currentUser.id
  )[0]?.user;

  const assessmentGroups = compact(
    insights
      .filter((insight) => insight.type === InsightType.CompleteAssessment)
      .map((insight) => {
        const allGroups = compact([
          insight.complianceProgram?.performanceAssessmentGroup,
          insight.complianceProgram?.managerAssessmentGroup,
          insight.complianceProgram?.peerAssessmentGroup,
        ]);
        return allGroups.find((g) => g.id === insight.assessmentGroup?.id);
      })
  );

  const performanceAssessmentRequirements = compact(
    assessmentGroups.map((assessmentGroup) => {
      const matchingAssessment = assertEdgesNonNull(
        assessmentGroup.assessments
      ).find(
        (assessment) =>
          assessment.target?.id &&
          assessment.responder?.id === currentUser.id &&
          assessment.target.id === otherParticipant?.id
      );
      if (matchingAssessment) {
        return {
          assessmentGroup,
          assessment: matchingAssessment,
        };
      }
      return null;
    })
  );

  function openTemplateDialogForTemplateId(id: number) {
    setTemplateDialogTemplateId(id);
    setOpenTemplatesDialog(true);
  }

  return (
    <div className="rounded-lg shadow overflow-hidden">
      {openTemplateDialog && (
        <TemplatesDialog
          meeting={meeting}
          meetingGroup={meeting.meetingGroup}
          initialTemplateId={templateDialogTemplateId}
          onClose={() => setOpenTemplatesDialog(false)}
        />
      )}
      <CollapsibleContainer
        title={`Related Requirements (${
          performanceAssessmentRequirements.length +
          relatedAssessments.length +
          meetingTemplateRequirements.length
        })`}
        startOpen={false}
        container={Fragment}
      >
        {loading ? (
          <Loading>Loading...</Loading>
        ) : (
          <>
            {relatedAssessments.map((node) => {
              return (
                <div className="p-2" key={node.id}>
                  <Link
                    to={{
                      pathname: `/assessments/assessment/${node.id}`,
                      state: {
                        previousPathname: `${location.pathname}`,
                      },
                    }}
                    className="text-blue-link text-sm align-middle"
                  >
                    <MdOutlineAssessment className="h-4 w-4 text-gray-500 inline-block mr-2" />
                    Assessment for {node.complianceProgram?.title}
                  </Link>
                </div>
              );
            })}
            {currentUserIsParticipant && (
              <>
                {meetingTemplateRequirements.map((node) => {
                  const template = assertEdgesNonNull(
                    node.complianceProgram?.requiredTopicTemplates
                  )[0];
                  return (
                    <div
                      className="py-2 pl-4 pr-2 text-sm flex bg-amber-50"
                      key={node.id}
                    >
                      <div className="flex-grow-0 flex-shrink">
                        You are required to discuss the{" "}
                        <Link
                          to={`/templates/${template.id}`}
                          className="text-blue-link text-sm"
                        >
                          {template.title}
                        </Link>{" "}
                        topics with {otherParticipant?.firstName}
                      </div>
                      <div className="flex-grow flex-shrink-0 basis-auto ml-2">
                        <button
                          className="text-amber-900 "
                          onClick={() => {
                            openTemplateDialogForTemplateId(template.id);
                          }}
                        >
                          Add Topics
                        </button>
                      </div>
                    </div>
                  );
                })}
                {performanceAssessmentRequirements.map(
                  ({ assessment, assessmentGroup }) => {
                    const targetUserNameStr = assessment.target?.name;
                    return (
                      <div
                        className="py-2 pl-4 pr-2 text-sm flex gap-2 justify-between bg-amber-50"
                        key={assessmentGroup.id}
                      >
                        <div className="flex-1">
                          Complete{" "}
                          <span className="font-medium text-black">
                            {assessmentGroup.title}
                          </span>{" "}
                          Assessment for {targetUserNameStr}
                        </div>
                        <div className="">
                          <AppLink
                            className="text-amber-900 "
                            to={`/assessments/assessment/${assessment.id}`}
                          >
                            Complete
                          </AppLink>
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            )}
          </>
        )}
      </CollapsibleContainer>
    </div>
  );
};

export default withErrorBoundary(RelatedRequirements, {
  fallback: (
    <AppError
      description={"The related requirements could not be displayed."}
    />
  ),
});
