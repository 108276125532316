import { AriaAttributes, ReactElement } from "react";

import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { classNames } from "@helpers/css";

const CollapsibleContainerParent = ({
  children,
  title,
  titleLink,
  loading = false,
  newSidebarDesign = false,
  rightSide = null,
  collapsibleToggleKey,
  className = "",
  ariaProps = {},
}: {
  ariaProps?: AriaAttributes;
  collapsibleToggleKey?: keyof UiPreferenceCache;
  title: string;
  titleLink?: string;
  loading?: boolean;
  newSidebarDesign?: boolean;
  className?: string;
  children: ReactElement | ReactElement[];
  rightSide?: ReactElement | ReactElement[] | null;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const toggled = collapsibleToggleKey
    ? uiPreferenceCache[`${collapsibleToggleKey}`]
    : true;

  const handleToggleCollapsible = () => {
    saveUiPreference({ [`${collapsibleToggleKey}`]: !toggled });
  };

  return newSidebarDesign ? (
    <div
      className={classNames("flex flex-col gap-3", className)}
      aria-label={ariaProps["aria-label"]}
    >
      <div className="flex justify-between items-center gap-x-2 gap-y-0.5 flex-wrap">
        <div className="shrink-0 text-lg font-medium flex items-center gap-4">
          {collapsibleToggleKey ? (
            <button
              className="hover:underline"
              type="button"
              onClick={handleToggleCollapsible}
            >
              {title}
            </button>
          ) : titleLink ? (
            <AppLink className="hover:underline" to={titleLink}>
              {title}
            </AppLink>
          ) : (
            <div>{title}</div>
          )}
          {loading && <Loading mini size="5" />}
        </div>
        {rightSide}
      </div>
      {toggled && children}
    </div>
  ) : (
    <div
      className={classNames(
        "rounded-lg shadow divide divide-y items-center divide-gray-100",
        className
      )}
      aria-label={ariaProps["aria-label"]}
    >
      <div className="bg-white pl-4 pr-3 py-2 rounded-t-lg flex justify-between items-center gap-x-2 gap-y-0.5 flex-wrap">
        <div className="shrink-0 text-lg font-medium flex items-center gap-4">
          {titleLink ? (
            <AppLink className="hover:underline" to={titleLink}>
              {title}
            </AppLink>
          ) : (
            <div>{title}</div>
          )}
          {loading && <Loading mini size="5" />}
        </div>
        {rightSide}
      </div>
      {children}
    </div>
  );
};

export default CollapsibleContainerParent;
