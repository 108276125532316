import { GetActionItemsCollapsibleQueryQueryVariables } from "types/graphql-schema";

import getActionItemsCollapsibleQuery from "@apps/action-items-collapsible/graphql/get-action-items-collapsible-query";
import { getWatchedQueries } from "@graphql/client";

export const refetchActionItemsInCollapsibleContainers = (actionItem: {
  __typename: string;
  actionItemState?: number | null;
}) => {
  if (actionItem && actionItem.__typename === "ActionItemArtifactNode") {
    const watchedQueries = getWatchedQueries(getActionItemsCollapsibleQuery);
    watchedQueries.forEach((watchedQuery) => {
      const watchedQueryVariables = watchedQuery.options
        .variables as GetActionItemsCollapsibleQueryQueryVariables;
      if (
        watchedQueryVariables.actionItemState === actionItem.actionItemState
      ) {
        const size =
          (watchedQuery.getCurrentResult().data?.artifacts.edges || []).length +
          1;
        const variables = {
          ...watchedQueryVariables,
          after: null,
          first: size,
        };
        watchedQuery.fetchMore({ variables });
      }
    });
  }
};
