import { XIcon } from "@heroicons/react/outline";
import { RefObject } from "react";

import Loading from "@components/loading/loading";

const TopicSidebarLoading = ({
  focusRef,
  onClose,
}: {
  focusRef: RefObject<HTMLButtonElement>;
  onClose: () => void;
}) => {
  return (
    <div className="-mt-3" aria-label="Loading sidebar topic">
      <div className="flex justify-end">
        <button
          type="button"
          className="ml-3 bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close topic sidebar"
          onClick={onClose}
          ref={focusRef}
        >
          <span className="sr-only">Close topic sidebar</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="pt-16">
        <Loading>Loading the topic...</Loading>
      </div>
    </div>
  );
};

export default TopicSidebarLoading;
