import * as Sentry from "@sentry/react";
import {
  ArtifactType,
  GoalScope,
  GoalState,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Error from "@components/error/error";

import MeetingGoalCollapsible from "./goal-collapsible";
import MeetingSidebarCollapsibleContainer from "./meeting-sidebar-collapsible-container";
import useMeetingSidebarCollapsibleContainer from "./use-meeting-sidebar-collapsible-container";

const Goals = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const label = useLabel();

  const {
    isFormalOneonone,
    subject,
    filterAssigneeId,
    participantIds,
    toggledAssignee,
    toggledJustMeeting,
  } = useMeetingSidebarCollapsibleContainer({
    meeting,
    collapsibleFilterName: "Goals",
  });

  const commonFilters = {
    first: 5,
    meetingGroupId: toggledJustMeeting ? meeting.meetingGroup?.id : undefined,
    owners: toggledAssignee ? [filterAssigneeId] : participantIds,
    asUsers: participantIds,
    goalScopes: isFormalOneonone
      ? undefined
      : [GoalScope.Personal, GoalScope.Team, GoalScope.Organization],
  };

  return (
    <div aria-label="Meeting goal sidebar">
      <MeetingSidebarCollapsibleContainer
        collapsibleFilterName="Goals"
        subject={subject}
        isFormalOneonone={isFormalOneonone}
        toggledAssignee={toggledAssignee}
        toggledJustMeeting={toggledJustMeeting}
        createArtifactFormOptions={{
          artifactType: ArtifactType.Goal,
          meetingId: meeting.id,
        }}
        titleLabel="goal"
      >
        <MeetingGoalCollapsible
          collapsibleToggleKey="meetingOpenGoalsExpanded"
          meeting={meeting}
          title={`Open ${label("goal", {
            pluralize: true,
          })}`}
          variables={{
            goalState: GoalState.Open,
            ...commonFilters,
          }}
        />
        <MeetingGoalCollapsible
          collapsibleToggleKey="meetingDraftGoalsExpanded"
          meeting={meeting}
          title={`Draft ${label("goal", {
            pluralize: true,
          })}`}
          variables={{
            goalState: GoalState.Draft,
            ...commonFilters,
          }}
        />
        <MeetingGoalCollapsible
          collapsibleToggleKey="meetingClosedGoalsExpanded"
          meeting={meeting}
          title={`Recently completed`}
          variables={{
            goalState: GoalState.Closed,
            goalsCompletedInTheLastXDays: 30,
            ...commonFilters,
          }}
          roundedBottom
        />
      </MeetingSidebarCollapsibleContainer>
    </div>
  );
};

export default Sentry.withErrorBoundary(Goals, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
