import { withErrorBoundary } from "@sentry/react";
import { useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import {
  FinalizePermissions,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import { assertEdgesNonNull } from "@helpers/helpers";

import DragAndDrop from "./drag-and-drop";
import FinalizeOneonone from "./prep/finalize-oneonone";
import SubmitOneonone from "./prep/submit-oneonone";
import Topic from "./topic";
import TopicCreateForm from "./topic-create-form";

const Meeting = ({
  meeting,
  searchQuery = "",
  isInExtension = false, // true when this view appears in an extension
  opensTopicsInSidebar,
  currentPageMeeting = null,
  relevantSections = null,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  searchQuery?: string;
  isInExtension?: boolean; // true when this view appears in an extension
  opensTopicsInSidebar: boolean;
  currentPageMeeting?: MeetingViewMeetingNodeNewPageFragmentFragment | null;
  relevantSections?: unknown;
}) => {
  const currentUser = currentUserVar();
  const createTopicFormRef = useRef<HTMLDivElement | null>(null);

  const meetingsGroupedById = {} as {
    [key: number]: MeetingViewMeetingNodeNewPageFragmentFragment;
  };
  meetingsGroupedById[meeting.id] = meeting;

  const topics = assertEdgesNonNull(meeting.topics);
  const mandatoryTopics = topics.filter((topic) => topic.isMandatory);
  const mandatoryTopicsWithHiddenNotes = mandatoryTopics.filter((node) => {
    const hiddenIndividualNotes =
      node.individualNotes?.edges.filter(
        (edge) => edge?.node?.creator.id === currentUser.id && edge.node.hidden
      ) || [];
    return hiddenIndividualNotes.length > 0;
  });
  const showSubmitButton =
    !meeting.isSubmitted && mandatoryTopicsWithHiddenNotes.length > 0;

  if (!meeting.meetingGroup) {
    return <Error title="This meeting does not exist." />;
  }

  return (
    <DragAndDrop meetingsGroupedById={meetingsGroupedById}>
      <div aria-label={`Meeting: ${meeting.title}`}>
        <div className="border-b empty:hidden flex flex-col gap-4 py-4 px-6">
          {meeting.meetingGroup?.isFormalOneonone && !showSubmitButton && (
            <FinalizeOneonone
              finalizePermission={
                meeting.meetingGroup?.topicTemplate?.finalizePermissions ||
                FinalizePermissions.FacilitatorsAndAdmins
              }
              meetingId={meeting.id}
              topics={meeting.topics}
              canFinalize={meeting.canFinalize}
              isFinalized={meeting.isFinalized}
              finalizedAt={meeting.finalizedAt}
              finalizedBy={meeting.finalizedBy}
              hasIncompleteMandatoryTopics={
                meeting.hasIncompleteMandatoryTopics
              }
            />
          )}
          {meeting.meetingGroup?.isFormalOneonone && !showSubmitButton && (
            <SubmitOneonone meetingId={meeting.id} />
          )}
          {/* {meeting.meetingGroup?.isFormalOneonone && (
            <PrepOneonone meeting={meeting} />
          )} */}
        </div>
        <div>
          <Droppable droppableId={`meeting-${meeting.id}`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {topics.length > 0 && (
                  <ul
                    className="fs-mask divide-gray-200 divide-y border-b"
                    aria-label="Topic list"
                  >
                    {topics.map((topic, index) => (
                      <Topic
                        key={topic.id}
                        index={index}
                        topic={topic}
                        meeting={meeting}
                        isInExtension={isInExtension}
                        opensInSidebar={opensTopicsInSidebar}
                        currentPageMeeting={currentPageMeeting}
                        searchQuery={searchQuery}
                        relevantSections={relevantSections}
                      />
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
                <div ref={createTopicFormRef}>
                  <TopicCreateForm
                    key={`create-topic-form-${meeting.id}`}
                    meetingGroup={meeting.meetingGroup}
                    meeting={meeting}
                  />
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragAndDrop>
  );
};

export default withErrorBoundary(Meeting, {
  fallback: <Error description={"The meeting could not be rendered."} />,
});
