import * as Sentry from "@sentry/react";
import { useState } from "react";
import { ArtifactType, GoalScope, GoalState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

import GoalsCollapsibleContainer from "./goals-collapsible-container";

const Goals = ({
  selectedUser,
  goalDueBetweenDates,
}: {
  selectedUser: BasicUser;
  goalDueBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const [isShowingCreateArtifactModal, setIsShowingCreateArtifactModal] =
    useState(false);
  const label = useLabel();

  return (
    <>
      {isShowingCreateArtifactModal && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Goal,
            owners: [selectedUser],
          }}
          onClose={() => setIsShowingCreateArtifactModal(false)}
        />
      )}
      <Layout.SidebarSection
        aria-label="Dashboard goal container"
        expandedUiPreferenceKey="dashboardSidebarGoalContainerExpanded"
        title={
          selectedUser.id === currentUser.id
            ? label("goal", { pluralize: true, capitalize: true })
            : `${selectedUser.firstName || selectedUser.name}'s ${label(
                "goal",
                {
                  pluralize: true,
                  capitalize: true,
                }
              )}`
        }
        options={[
          {
            label: `Create ${label("goal")}`,
            onClick: ({ syntheticEvent }) => {
              syntheticEvent.preventDefault();
              syntheticEvent.stopPropagation();
              setIsShowingCreateArtifactModal(true);
            },
          },
        ]}
      >
        <GoalsCollapsibleContainer
          title={"Individual"}
          filters={{
            goalScopes: [GoalScope.Career, GoalScope.Personal],
            owners: [selectedUser.id],
            goalStates: [GoalState.Open, GoalState.Draft],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="dashboardPersonalGoalsExpanded"
        />
        <GoalsCollapsibleContainer
          title={`${label("team", { capitalize: true })}`}
          filters={{
            goalScopes: [GoalScope.Team],
            teamGoalsOfUser: selectedUser.id,
            goalStates: [GoalState.Open, GoalState.Draft],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="dashboardTeamGoalsExpanded"
        />
        <GoalsCollapsibleContainer
          title={`${label("organization", { capitalize: true })}`}
          filters={{
            goalScopes: [GoalScope.Organization],
            goalStates: [GoalState.Open, GoalState.Draft],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          roundedBottom
          localStorageKey="dashboardOrganizationGoalsExpanded"
        />
      </Layout.SidebarSection>
    </>
  );
};

export default Sentry.withErrorBoundary(Goals, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
