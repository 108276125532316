import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import TranscriptionParticipation from "@apps/meeting-transcription-dialog/participation";

import MeetingSidebarAIContainer from "./ai-container";

const MeetingSidebarAIStats = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  return (
    <MeetingSidebarAIContainer meeting={meeting}>
      {({ meeting: meetingWithTranscript }) => (
        <div className="bg-white py-1 px-3 rounded-lg border border-gray-200">
          <TranscriptionParticipation meeting={meetingWithTranscript} />
        </div>
      )}
    </MeetingSidebarAIContainer>
  );
};

export default MeetingSidebarAIStats;
