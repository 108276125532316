import { compact } from "lodash";
import { useState } from "react";
import { AttendeeRole, AttendeeStatus } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";

import getMeetingOverviewOneononesQuery from "../graphql/get-meeting-overview-oneonones-query";

export const NoOneononeCTA = ({
  report,
  manager,
}: {
  report?: BasicUser;
  manager?: BasicUser;
}) => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const [showDialog, setShowDialog] = useState(false);

  return (
    <div className="px-3 py-2 text-sm text-gray-500 flex items-center gap-2 justify-between">
      {showDialog && (
        <MeetingDialog
          formOptions={{
            isFormalOneonone: true,
            facilitatorId: manager ? manager.id : currentUser.id,
            attendees: compact([
              {
                ...currentUser,
                role: AttendeeRole.Required,
                participantStatus: AttendeeStatus.NotResponded,
              },
              report &&
                report.email && {
                  ...report,
                  email: report.email,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
              manager &&
                manager.email && {
                  ...manager,
                  email: manager.email,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
            ]),
          }}
          onClose={() => setShowDialog(false)}
          refetchQueries={[getMeetingOverviewOneononesQuery]}
        />
      )}
      <div>No {label("1-on-1")} scheduled.</div>
      <button
        className="text-blue-link hover:underline"
        onClick={() => setShowDialog(true)}
      >
        Create {label("1-on-1")}
      </button>
    </div>
  );
};

export default NoOneononeCTA;
