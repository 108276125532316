import { UserGroupIcon } from "@heroicons/react/outline";
import pluralize from "pluralize";
import { useState } from "react";
import { GoalArtifactNode } from "types/graphql-schema";

import Avatar from "@components/avatar/avatar";
import Tooltip from "@components/tooltip/tooltip";

import GoalParticipantsDialog from "./goal-participants-dialog";

const GoalParticipants = ({
  artifact,
}: {
  artifact: {
    firstOwner: GoalArtifactNode["firstOwner"];
    owners: GoalArtifactNode["owners"];
    ownerCount: GoalArtifactNode["ownerCount"];
  };
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const owners = [
    ...(artifact?.firstOwner ? [{ node: artifact.firstOwner }] : []),
    ...(artifact?.owners?.edges || []),
  ].map((edge: any) => edge.node);
  const ownerTotalCount = artifact?.ownerCount || owners.length;
  return (
    <>
      {showDialog && (
        <GoalParticipantsDialog
          artifact={artifact}
          onClose={() => setShowDialog(false)}
        />
      )}
      <button
        className="flex items-center hover:bg-gray-100 rounded-full"
        onClick={() => setShowDialog(true)}
      >
        {ownerTotalCount === 0 ? (
          <Avatar user={null} size="5" />
        ) : ownerTotalCount === 1 ? (
          <Avatar user={owners[0]} size="5" />
        ) : (
          <Tooltip
            text={`${ownerTotalCount} ${pluralize("owner", ownerTotalCount)}`}
          >
            <UserGroupIcon className="h-5 w-5 text-gray-500 rounded-full bg-gray-100" />
          </Tooltip>
        )}
      </button>
    </>
  );
};

export default GoalParticipants;
