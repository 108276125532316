import { useMutation } from "@apollo/client";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  ArtifactType,
  CreatedArtifactFragmentFragment,
} from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import getMeetingRecognitionsQuery from "@apps/meeting-new/graphql/get-meeting-recognitions-query";
import hideSuggestedArtifactMutation from "@apps/meeting-transcription-dialog/graphql/hide-suggested-artifact-mutation";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import ActionItemPeopleDropdown from "@components/people-dropdown/action-item-people-dropdown";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";

const MeetingSummaryGroupTranscriptionSuggestedArtifact = ({
  meeting,
  suggestedArtifact,
}: {
  meeting: any;
  suggestedArtifact: any;
}) => {
  const [showArtifactDialog, setShowArtifactDialog] = useState(false);
  const currentOrganization = currentOrganizationVar();
  const label = useLabel();
  const emptyAssignee = {
    id: graphqlNone,
    name: "Unassigned",
  };

  const [assignee, setAssignee] = useState(
    suggestedArtifact.suggestedAssignee || emptyAssignee
  );
  const [hideSuggestedArtifact, { loading: loadingHide }] = useMutation(
    hideSuggestedArtifactMutation
  );
  const artifactLabel = label(suggestedArtifact.artifactType);

  const handleClickHideSuggestedArtifact = () => {
    hideSuggestedArtifact({
      variables: { suggestedArtifactId: suggestedArtifact.id },
      optimisticResponse: {
        updateSuggestedArtifact: {
          suggestedArtifact: {
            ...suggestedArtifact,
            hidden: true,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleCloseDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    setShowArtifactDialog(false);
    if (createdArtifact) {
      handleClickHideSuggestedArtifact();
    }
  };

  return (
    <div key={suggestedArtifact.id} className="flex items-center gap-2 group">
      {showArtifactDialog && (
        <ArtifactCreationDialog
          formOptions={{
            title: suggestedArtifact.title,
            feedbackMessage:
              suggestedArtifact.artifactType === ArtifactType.Feedback
                ? JSON.stringify({
                    type: "doc",
                    content: [
                      {
                        type: "paragraph",
                        content: [
                          { type: "text", text: suggestedArtifact.description },
                        ],
                      },
                    ],
                  })
                : undefined,
            artifactType: suggestedArtifact.artifactType,
            organizationId: meeting.organization?.id || currentOrganization.id,
            meetingId: meeting.id,
            meetingGroupId: meeting.meetingGroup.id,
            assignee:
              suggestedArtifact.artifactType === ArtifactType.ActionItem &&
              assignee.id !== graphqlNone
                ? assignee
                : undefined,
            recognitionRecipients:
              suggestedArtifact.artifactType === ArtifactType.Recognition &&
              assignee.id !== graphqlNone
                ? [assignee]
                : [],
            feedbackRecipients:
              suggestedArtifact.artifactType === ArtifactType.Feedback &&
              assignee.id !== graphqlNone
                ? [assignee]
                : [],
          }}
          onClose={handleCloseDialog}
          refetchQueries={[getMeetingRecognitionsQuery]}
        />
      )}
      <button
        type="button"
        className="flex-1 text-left p-1 -ml-1 fs-mask"
        onClick={() => setShowArtifactDialog(true)}
      >
        {suggestedArtifact.title}
      </button>
      <div className="flex items-start">
        <div className="flex items-center gap-2">
          <ActionItemPeopleDropdown
            actionItem={{ assignee }}
            onChangeAssignee={setAssignee}
            showName={false}
            unassignedId={graphqlNone}
          />
          <Tooltip text={`Create ${artifactLabel}`}>
            <button
              className="p-1 border border-gray-300 border-dotted rounded-full hover:bg-gray-100 text-gray-500"
              onClick={() => setShowArtifactDialog(true)}
              disabled={loadingHide}
            >
              <PlusIcon className="h-4 w-4" />
            </button>
          </Tooltip>
          <Tooltip text={`Delete suggestion`}>
            <button
              className="p-0.5 rounded hover:bg-gray-100 text-gray-500"
              onClick={handleClickHideSuggestedArtifact}
              disabled={loadingHide}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default MeetingSummaryGroupTranscriptionSuggestedArtifact;
