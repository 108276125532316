const SidebarSyncingError = ({ currentUser }) => {
  if (!currentUser.hasCalendarSyncError) {
    return null;
  }
  return (
    <div>
      <div className="bg-white text-base mt-4 font-medium bg-white rounded-lg border border-dashed flex flex-col gap-2 justify-center items-center py-6">
        Error syncing calendar
        <span className="text-sm">
          Please contact{" "}
          <a className="text-blue-500" href="mailto:hello@topicflow.com">
            support
          </a>
        </span>
      </div>
    </div>
  );
};
export default SidebarSyncingError;
