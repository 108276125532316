import { useMutation } from "@apollo/client";
import { ChangeEventHandler } from "react";
import { GetMeetingGroupSettingsQueryQuery } from "types/graphql-schema";

import updateMeetingGroupMutation from "@apps/meeting/graphql/update-meeting-group-mutation";
import { successNotificationVar } from "@cache/cache";
import Heading from "@components/heading/heading";
import AppLink from "@components/link/link";
import SettingsLayout from "@components/settings-layout/settings-layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

import MeetingContentAccessDropdown from "./content-access-dropdown";
import SettingsSlackNotification from "./settings-slack-notification";
import TemplateForm from "./template-form";

const MeetingSettingsContent = ({
  meetingGroup,
}: {
  meetingGroup: NonNullable<GetMeetingGroupSettingsQueryQuery["meetingGroup"]>;
}) => {
  const [updateMeetingGroup] = useMutation(updateMeetingGroupMutation);

  // Computed data
  const slackNotifications = assertEdgesNonNull(
    meetingGroup.slackNotifications
  );
  const meetingOrganization = meetingGroup.organization;
  const channels = (
    meetingGroup.organization?.publicSlackChannels
      ? assertEdgesNonNullWithStringId(
          meetingGroup.organization?.publicSlackChannels
        )
      : []
  ).map((channel: any) => ({
    label: `#${channel.name}`,
    value: channel.id,
    ...channel,
  }));
  const existingChannelIds = slackNotifications.map(
    ({ channel }: { channel: any }) => channel
  );

  const hangeChangeTranscribeMeetingsByDefault: ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const transcribeMeetingsByDefault = e.target.checked;
    updateMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        transcribeMeetingsByDefault,
      },
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            transcribeMeetingsByDefault,
          },
        },
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({
          title: `Transcription ${
            transcribeMeetingsByDefault ? "enabled" : "disabled"
          } by default`,
          timeout: 2000,
        });
      },
    });
  };

  return (
    <div className="mb-6 p-6">
      <div className="p-6 rounded-xl bg-white shadow">
        <Heading small title="Settings" />

        <SettingsLayout.Container>
          <SettingsLayout.Row>
            <SettingsLayout.Left label="Define who has access to the notes of this meeting">
              Content access
            </SettingsLayout.Left>
            <SettingsLayout.Right className="text-sm">
              <div className="relative">
                <MeetingContentAccessDropdown meetingGroup={meetingGroup} />
              </div>
            </SettingsLayout.Right>
          </SettingsLayout.Row>
          {meetingOrganization && (
            <SettingsLayout.Row>
              <SettingsLayout.Left label="Post meeting reminders and summaries to Slack">
                Slack notifications
              </SettingsLayout.Left>
              <SettingsLayout.Right className="text-sm">
                {meetingOrganization?.hasSlackConnection ? (
                  <div>
                    <div className="flex flex-col divide-y divide-gray-100">
                      {slackNotifications.map((slackNotification: any) => (
                        <SettingsSlackNotification
                          key={slackNotification.id}
                          channels={channels}
                          slackNotification={slackNotification}
                          meetingGroup={meetingGroup}
                          existingChannelIds={existingChannelIds}
                        />
                      ))}
                      <SettingsSlackNotification
                        channels={channels}
                        slackNotification={null}
                        meetingGroup={meetingGroup}
                        existingChannelIds={existingChannelIds}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <AppLink
                      to={`/settings/organization/${meetingOrganization.id}/integrations`}
                      className="text-blue-link hover:underline"
                    >
                      Set up Slack on your organization
                    </AppLink>
                  </div>
                )}
              </SettingsLayout.Right>
            </SettingsLayout.Row>
          )}
          <SettingsLayout.Row>
            <SettingsLayout.Left label="Transcribe your meetings">
              Transcription
            </SettingsLayout.Left>
            <SettingsLayout.Right className="text-sm">
              <label className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  checked={!!meetingGroup.transcribeMeetingsByDefault}
                  onChange={hangeChangeTranscribeMeetingsByDefault}
                />
                Enable transcription by default on current and upcoming
                recurring meetings.
              </label>
            </SettingsLayout.Right>
          </SettingsLayout.Row>
          <SettingsLayout.Row>
            <SettingsLayout.Left label="Automate meeting agenda for your future meetings">
              Templates
            </SettingsLayout.Left>
            <SettingsLayout.Right>
              <TemplateForm meetingGroup={meetingGroup} />
            </SettingsLayout.Right>
          </SettingsLayout.Row>
        </SettingsLayout.Container>
      </div>
    </div>
  );
};

export default MeetingSettingsContent;
