import { createElement } from "react";
import { IconType } from "react-icons";
import { FaGithub } from "react-icons/fa6";

enum WorkHistoryExternalObjectType {
  GITHUB_PULL_REQUEST = "github_pull_request",
}
export type WorkHistoryExternalEventObject = {
  id: string;
  type: WorkHistoryExternalObjectType;
  title: string;
  url: string;
};

const externalObjectIcons: {
  [key in WorkHistoryExternalObjectType]: IconType;
} = {
  [WorkHistoryExternalObjectType.GITHUB_PULL_REQUEST]: FaGithub,
};

const ExternalEventObject = ({
  eventObject,
}: {
  eventObject: WorkHistoryExternalEventObject;
}) => {
  const Icon = externalObjectIcons[eventObject.type];
  if (!Icon) {
    return null;
  }
  return (
    <div className="flex items-center gap-2">
      <div>{createElement(Icon)}</div>
      <div>
        <a
          className="hover:underline font-medium"
          target="_blank"
          rel="noreferrer"
          href={eventObject.url}
        >
          {eventObject.title}
        </a>
      </div>
    </div>
  );
};

export default ExternalEventObject;
