import { Listbox, Portal } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Placement } from "@popperjs/core";
import {
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { usePopper } from "react-popper";

import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

export type SelectOption<T = unknown> = {
  value: T;
  label: string | ReactNode;
  description?: string;
  disabled?: boolean;
};

type DeprecatedSelectProps<T> = Omit<
  HTMLAttributes<HTMLDivElement>,
  "onChange" | "children"
> & {
  label?: string;
  name?: string;
  value: T | null | undefined;
  options: SelectOption<T>[];
  width?: string;
  maxHeight?: boolean;
  testId?: string;
  showSelectedIcon?: boolean;
  placement?: Placement;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  children?: (props: {
    disabled: boolean;
    selected?: SelectOption<T>;
    setReferenceElement: Dispatch<SetStateAction<HTMLElement | null>>;
  }) => JSX.Element;
  onChange: (opt: SelectOption<T>, meta?: { name?: string }) => void;
};

const DeprecatedSelect = <T,>({
  label = "",
  name,
  value,
  options,
  width,
  maxHeight = true,
  testId = "",
  showSelectedIcon = true,
  onChange,
  placement = "bottom-start",
  disabled = false,
  className,
  containerClassName = "",
  children,
  ...props
}: DeprecatedSelectProps<T>) => {
  const selected = options.find((option) => option.value === value);

  const handleChange = useCallback(
    (option: SelectOption<T>) => {
      onChange(option, { name });
    },
    [name, onChange]
  );

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const modifiers = useMemo(
    () => [
      {
        name: "offset",
        options: { offset: [0, 5] },
      },
    ],
    []
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers,
  });

  return (
    <div {...props} className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <Listbox value={selected} onChange={handleChange} disabled={disabled}>
        {({ open, disabled }) => (
          <>
            <div className={classNames(`relative`, width && `w-${width}`)}>
              {children ? (
                children({ selected, setReferenceElement, disabled })
              ) : (
                <Listbox.Button
                  className={classNames(
                    "relative w-full pl-3 pr-10 py-2 text-left cursor-default text-gray-800 sm:text-sm",
                    !disabled && "bg-white",
                    disabled && "bg-gray-100",
                    inputBorderClassName,
                    inputFocusClassName,
                    className
                  )}
                  ref={setReferenceElement}
                  data-testid={testId}
                >
                  <span
                    className="block truncate"
                    data-testid={`${testId}-selected`}
                  >
                    {selected?.label ?? props.placeholder}
                  </span>
                  {!disabled && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </Listbox.Button>
              )}

              <Portal>
                <Listbox.Options
                  ref={setPopperElement}
                  style={styles.popper}
                  className={classNames(
                    "z-dropdown bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm",
                    "min-w-0 max-w-144",
                    maxHeight && `max-h-60`
                  )}
                  {...attributes.popper}
                  aria-label="Dropdown options"
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={JSON.stringify(option.value)}
                      data-testid={`${testId}-option-${option.value}`}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-blue-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-10"
                        )
                      }
                      value={option}
                      disabled={option.disabled}
                    >
                      {({ selected, active, disabled }) => (
                        <div>
                          <div>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                disabled && "text-gray-400",
                                "block truncate"
                              )}
                            >
                              {option.label}
                            </span>

                            {showSelectedIcon && selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-blue-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                          {option.description && (
                            <div
                              className={classNames(
                                "text-2xs tracking-tight",
                                active ? "text-white/70" : "text-gray-500"
                              )}
                            >
                              {option.description}
                            </div>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Portal>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export const Select = <T,>({
  name = "",
  value,
  options,
  maxHeight = true,
  testId = "",
  showSelectedIcon = true,
  onChange,
  placement = "bottom-start",
  disabled = false,
  className = "",
  isMaskedInFullStory = false,
  children,
}: {
  isMaskedInFullStory?: boolean;
  testId?: null | string;
  name?: string;
  value: string | number;
  options: SelectOption<T>[];
  maxHeight?: boolean;
  showSelectedIcon?: boolean;
  disabled?: boolean;
  placement?: Placement;
  className?: string;
  onChange: (option: SelectOption<T>, extra?: { name: string }) => void;
  children?: (props: {
    disabled: boolean;
    selected?: SelectOption<T>;
    setReferenceElement: Dispatch<SetStateAction<HTMLElement | null>>;
  }) => JSX.Element;
}) => {
  const selected = options.find((option) => option.value === value);

  const handleChange = (option: SelectOption<T>) => {
    onChange(option, { name });
  };

  const [referenceElement, setReferenceElement] = useState(null);
  const modifiers = useMemo(
    () => [
      {
        name: "offset",
        options: { offset: [0, 5] },
      },
    ],
    []
  );
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers,
  });

  return (
    <Listbox value={selected} onChange={handleChange} disabled={disabled}>
      {({ disabled }) => (
        <div
          className={classNames(
            `flex min-w-0`,
            !isMaskedInFullStory && "fs-unmask"
          )}
        >
          {children ? (
            children({ selected, setReferenceElement, disabled })
          ) : (
            <Listbox.Button
              disabled={disabled}
              className={classNames(
                "flex-1 truncate bg-white relative w-full pl-3 pr-10 py-2 text-left cursor-default text-gray-800 sm:text-sm",
                inputBorderClassName,
                inputFocusClassName,
                className
              )}
              ref={setReferenceElement}
              data-testid={testId}
            >
              <span
                className="block truncate"
                data-testid={`${testId}-selected`}
              >
                {selected?.label}
              </span>
              {!disabled && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-4 w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </span>
              )}
            </Listbox.Button>
          )}

          <Portal>
            <Listbox.Options
              ref={setPopperElement}
              style={styles.popper}
              className={classNames(
                "z-dropdown bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm",
                "min-w-0 max-w-144",
                maxHeight && `max-h-60`,
                !isMaskedInFullStory && "fs-unmask"
              )}
              {...attributes.popper}
              aria-label="Dropdown options"
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  data-testid={`${testId}-option-${option.value}`}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-blue-600" : "text-gray-900",
                      "cursor-default select-none relative py-2 pl-3 pr-10"
                    )
                  }
                  value={option}
                  disabled={option.disabled}
                >
                  {({ selected, active }) => (
                    <div>
                      <div>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.label}
                        </span>

                        {showSelectedIcon && selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                      {option.description && (
                        <div
                          className={classNames(
                            "text-2xs tracking-tight",
                            active ? "text-white/70" : "text-gray-500"
                          )}
                        >
                          {option.description}
                        </div>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Portal>
        </div>
      )}
    </Listbox>
  );
};

export default DeprecatedSelect;
