import { gql } from "@apollo/client";

import { GoalArtifactComponentFragment } from "@apps/artifact/graphql/artifact-component-fragment";
import KeyResultFragment from "@graphql/key-result-fragment";

export const AlignmentGoalFragment = gql`
  ${KeyResultFragment}
  ${GoalArtifactComponentFragment}
  fragment AlignmentGoalFragment on ArtifactInterface {
    id
    ... on GoalArtifactNode {
      ...GoalArtifactComponentFragment
      created
      canRead {
        permission
      }
      keyResults {
        edges {
          node {
            id
            ...KeyResultFragment
          }
        }
      }
      parentGoal {
        id
      }
      childGoals(
        goalStates: $goalStates
        goalDueBetweenDates: $goalDueBetweenDates
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export default gql`
  ${AlignmentGoalFragment}
  query getTopLevelGoalsQuery(
    $goalScopes: [GoalScope]
    $goalStates: [GoalState]
    $goalDueBetweenDates: [Date]
  ) {
    artifacts(
      artifactType: goal
      orderBy: "objective_alignment"
      goalScopes: $goalScopes
      goalStates: $goalStates
      goalDueBetweenDates: $goalDueBetweenDates
      goalParentIds: []
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...AlignmentGoalFragment
        }
      }
    }
  }
`;
