import { gql } from "@apollo/client";

export default gql`
  mutation changeBotRecordingStatusMutation(
    $meetingId: Int!
    $isRecording: Boolean!
  ) {
    changeBotRecordingStatus(meetingId: $meetingId, isRecording: $isRecording) {
      meeting {
        botIsRecording
        botIsWaitingToJoinMeeting
        id
      }
    }
  }
`;
