import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { browserTracingIntegration } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import "intersection-observer";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import ExtensionRoutes from "@apps/extension/routes";
import MainApp from "@apps/main/app";
import OnboardingLayout from "@apps/onboarding/components/layout";
import Onboarding from "@apps/onboarding/onboarding";
import cache, { ignoredCacheKeys } from "@cache/cache";
import Container from "@components/container/container";
import Loading from "@components/loading/loading";
import ScrollToTop from "@components/scroll-top/scroll-top";
import customApolloClient from "@graphql/client";
import { apolloPersistCacheKey } from "@helpers/constants";

if (!window.Cypress) {
  if (!import.meta.env.DEV) {
    Sentry.init({
      dsn: import.meta.env.DEV
        ? "https://049612b8042e4796b0bb85596ec1696f@o1012718.ingest.sentry.io/6231090"
        : "https://b86026f89303448088e0bd6dfefe3047@o1012718.ingest.sentry.io/6041535",
      integrations: [browserTracingIntegration()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      debug: import.meta.env.VITE_SENTRY_SHOW_LOG === "1",
      release: window.currentCommitHash,
    });
  }

  if (!import.meta.env.DEV && window.Intercom) {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: window.intercomAppId,
    });
  }
}

const TopicflowApp = () => {
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();
  useEffect(() => {
    if (window.TF.hijacked) {
      // do not persist data when using django hijack
      setClient(customApolloClient);
    } else {
      async function initCache() {
        await persistCache({
          cache,
          storage: new LocalStorageWrapper(window.localStorage),
          key: apolloPersistCacheKey,
          persistenceMapper: async (data: any) => {
            const dateOneMonthAgo = moment
              .utc()
              .subtract(1, "month")
              .format("YYYY-MM-DD[T]HH:mm:ss[+00:00]");
            try {
              const parsedData = JSON.parse(data);
              const filteredData = Object.entries(parsedData)
                // remove ignored cache keys
                .filter(
                  ([key]) =>
                    !ignoredCacheKeys.find((ignoredCacheKey) =>
                      key.includes(ignoredCacheKey)
                    )
                )
                // remove old meetings
                .filter(([key, value]: any[]) => {
                  if (key.includes("BasicMeetingNode")) {
                    return value.endDatetime > dateOneMonthAgo;
                  }
                  return true;
                });
              return JSON.stringify(Object.fromEntries(filteredData));
            } catch (e) {
              window.console.error(e);
            }
            return "{}";
          },
        });
        setClient(customApolloClient);
      }
      initCache();
    }
  }, []);

  if (!client) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Tooltip
          id="topicflow-tooltip"
          style={{
            zIndex: 1800, // tippyZIndex in tailwind.config.js
            padding: "5px 12px",
            borderRadius: "5px",
          }}
        />
        <ScrollToTop />
        <Switch>
          {/* Extension Apps */}
          <Route path="/extension">
            <ExtensionRoutes />
          </Route>

          {/* Onboarding Apps */}
          <Route path="/onboarding">
            <OnboardingLayout>
              <Onboarding />
            </OnboardingLayout>
          </Route>

          {/* Apps with nav */}
          <Route path="*">
            <MainApp />
          </Route>
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
};

ReactDOM.render(<TopicflowApp />, document.getElementById("root"));
