import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { compact, isEqual } from "lodash";
import moment from "moment";

import Select from "@components/select/select";
import {
  meetingFrequency,
  nthWeekDaysInMonth,
  recurrenceValue,
} from "@helpers/constants";
import { classNames } from "@helpers/css";

import { meetingDialogInputClassName } from "../meeting-dialog";

// https://dateutil.readthedocs.io/en/stable/rrule.html
const defaultFormMeetingFrequency = {
  byMonth: null,
  byMonthDay: null,
  byYearDay: null,
  byWeekDay: null,
  bySetPos: null,
  frequency: meetingFrequency.weekly,
  interval: 1,
};

export const getRecurrenceValue = (recurrence: any) => {
  if (!recurrence) {
    return recurrenceValue.none;
  }
  if (recurrence.frequency === meetingFrequency.weekly) {
    if (isEqual(recurrence.byWeekDay, [0, 1, 2, 3, 4])) {
      return recurrenceValue.weekdays;
    }
    return recurrenceValue.weekly;
  }
  if (recurrence.frequency === meetingFrequency.monthly) {
    if (recurrence.byMonthDay?.length > 0) {
      return recurrenceValue.monthlyMonthDay;
    }
    return recurrenceValue.monthlyWeekDay;
  }
  return recurrence.frequency;
};

const MeetingRecurrence = ({
  isReadOnly,
  formStartDatetime,
  formMeetingFrequency,
  onChangeMeetingFrequency,
  buttonProps = {},
}: {
  isReadOnly: boolean;
  formStartDatetime?: string;
  formMeetingFrequency: any;
  buttonProps: any;
  onChangeMeetingFrequency: (frequency: any) => void;
}) => {
  const defaultWeekDay = moment(formStartDatetime).isoWeekday() - 1;
  const nthWeekDay = Math.floor(moment(formStartDatetime).date() / 7) + 1;
  const validNthWeekDay = nthWeekDay > 4 ? -1 : nthWeekDay;
  const nthWeekDayInMonth = nthWeekDaysInMonth.find(
    ({ value }) => value === validNthWeekDay
  );
  const defaultMonthlySetPos = nthWeekDaysInMonth.find(
    ({ value }) => value === nthWeekDay
  )
    ? nthWeekDay
    : nthWeekDaysInMonth[0].value;
  const recurrencyOptions = compact([
    {
      value: meetingFrequency.none,
      frequency: meetingFrequency.none,
      label: "Does not repeat",
    },
    {
      value: meetingFrequency.daily,
      frequency: meetingFrequency.daily,
      label: "Daily",
    },
    {
      value: recurrenceValue.weekdays,
      frequency: meetingFrequency.weekly,
      label: "Every weekday (Monday to Friday)",
    },
    {
      value: meetingFrequency.weekly,
      frequency: meetingFrequency.weekly,
      label: `Weekly on ${moment(formStartDatetime).format("dddd")}`,
    },
    {
      value: recurrenceValue.monthlyMonthDay,
      frequency: meetingFrequency.monthly,
      label: `Monthly on the ${moment(formStartDatetime).format("Do")}`,
    },
    nthWeekDayInMonth && {
      value: recurrenceValue.monthlyWeekDay,
      frequency: meetingFrequency.monthly,
      label: `Monthly on the ${nthWeekDayInMonth.label.toLowerCase()} ${moment(
        formStartDatetime
      ).format("dddd")}`,
    },
    {
      value: meetingFrequency.yearly,
      frequency: meetingFrequency.yearly,
      label: `Yearly on ${moment(formStartDatetime).format("MMMM D")}`,
    },
  ]);

  const handleChangeMeetingFrequency = (
    { value, frequency }: any,
    { name }: any
  ) => {
    // changing frequency
    if (name === "meeting-frequency") {
      if (value === meetingFrequency.none) {
        return onChangeMeetingFrequency(null);
      }

      return onChangeMeetingFrequency({
        ...defaultFormMeetingFrequency,
        value,
        frequency,
        ...(value === meetingFrequency.weekly && {
          byWeekDay: [defaultWeekDay],
        }),
        ...(value === recurrenceValue.monthlyWeekDay && {
          byWeekDay: [defaultWeekDay],
          bySetPos: defaultMonthlySetPos,
        }),
        ...(value === recurrenceValue.monthlyMonthDay && {
          byMonthDay: [moment(formStartDatetime).date()],
          bySetPos: defaultMonthlySetPos,
        }),
        ...(value === meetingFrequency.yearly && {
          byMonth: moment(formStartDatetime).month(),
          byMonthDay: [moment(formStartDatetime).date()],
        }),
        ...(value === meetingFrequency.weekdays && {
          byWeekDay: [0, 1, 2, 3, 4],
        }),
      });
    }
  };

  // Render
  return (
    <div className="flex gap-2 flex-wrap">
      <Select<string>
        width="72"
        name="meeting-frequency"
        value={
          formMeetingFrequency
            ? formMeetingFrequency.value
            : meetingFrequency.none
        }
        options={recurrencyOptions}
        className="z-dropdown"
        testId="meeting-frequency-select"
        onChange={handleChangeMeetingFrequency}
        showSelectedIcon={false}
        maxHeight={false}
        disabled={isReadOnly}
      >
        {({ selected, setReferenceElement }) => (
          <Listbox.Button
            {...buttonProps}
            ref={setReferenceElement}
            className={classNames(
              meetingDialogInputClassName,
              isReadOnly && "hover:bg-white"
            )}
            disabled={isReadOnly}
          >
            <div className="flex items-center gap-1.5">
              {selected?.label}
              {!isReadOnly && (
                <ChevronDownIcon
                  className="h-4 w-4 text-gray-700"
                  aria-hidden="true"
                />
              )}
            </div>
          </Listbox.Button>
        )}
      </Select>
    </div>
  );
};

export default MeetingRecurrence;
