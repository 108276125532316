import { useMutation, useQuery } from "@apollo/client";
import { ChangeEventHandler } from "react";

import updateMeetingGroupMutation from "@apps/meeting/graphql/update-meeting-group-mutation";
import { successNotificationVar } from "@cache/cache";
import Heading from "@components/heading/heading";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import SettingsLayout from "@components/settings-layout/settings-layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import getMeetingGroupSlackQuery from "./graphql/get-meeting-group-slack-query";
import SettingsSlackNotification from "./settings-slack-notification";
import TemplateForm from "./template-form";

const SettingsTab = ({ meetingGroup }: { meetingGroup: any }) => {
  const { data, loading } = useQuery(getMeetingGroupSlackQuery, {
    variables: { meetingGroupId: meetingGroup.id },
    onError: onNotificationErrorHandler(),
  });
  const [updateMeetingGroup] = useMutation(updateMeetingGroupMutation);

  // Computed data
  const slackNotifications =
    data?.meetingGroup.slackNotifications.edges.map(
      ({ node }: { node: any }) => node
    ) || [];
  const meetingOrganization = data?.meetingGroup.organization;
  const channels = (
    meetingOrganization?.publicSlackChannels.edges.map(
      ({ node }: { node: any }) => node
    ) || []
  ).map((channel: any) => ({
    label: `#${channel.name}`,
    value: channel.id,
    ...channel,
  }));
  const existingChannelIds = slackNotifications.map(
    ({ channel }: { channel: any }) => channel
  );

  const hangeChangeTranscribeMeetingsByDefault: ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const transcribeMeetingsByDefault = e.target.checked;
    updateMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        transcribeMeetingsByDefault,
      },
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            transcribeMeetingsByDefault,
          },
        },
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({
          title: `Transcription ${
            transcribeMeetingsByDefault ? "enabled" : "disabled"
          } by default`,
          timeout: 2000,
        });
      },
    });
  };

  return (
    <div className="mb-6 p-6">
      <div className="p-6 rounded-xl bg-white shadow">
        <Heading small title="Settings" />

        <SettingsLayout.Container>
          {meetingOrganization && (
            <SettingsLayout.Row>
              <SettingsLayout.Left label="Post meeting reminders and summaries to Slack">
                Slack notifications
              </SettingsLayout.Left>
              <SettingsLayout.Right className="text-sm">
                {loading ? (
                  <div className="flex gap-2 items-center text-gray-500 text-sm">
                    <Loading size="5" />
                    <div>Loading Slack channels</div>
                  </div>
                ) : loading || meetingOrganization?.hasSlackConnection ? (
                  <div>
                    <div className="flex flex-col divide-y divide-gray-100">
                      {slackNotifications.map((slackNotification: any) => (
                        <SettingsSlackNotification
                          key={slackNotification.id}
                          channels={channels}
                          slackNotification={slackNotification}
                          meetingGroup={meetingGroup}
                          existingChannelIds={existingChannelIds}
                        />
                      ))}
                      <SettingsSlackNotification
                        channels={channels}
                        slackNotification={null}
                        meetingGroup={meetingGroup}
                        existingChannelIds={existingChannelIds}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <AppLink
                      to={`/settings/organization/${meetingOrganization.id}/integrations`}
                      className="text-blue-link hover:underline"
                    >
                      Set up Slack on your organization
                    </AppLink>
                  </div>
                )}
              </SettingsLayout.Right>
            </SettingsLayout.Row>
          )}
          <SettingsLayout.Row>
            <SettingsLayout.Left label="Transcribe your meetings">
              Transcription
            </SettingsLayout.Left>
            <SettingsLayout.Right className="text-sm">
              <label className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  checked={meetingGroup.transcribeMeetingsByDefault}
                  onChange={hangeChangeTranscribeMeetingsByDefault}
                />
                Enable transcription by default on current and upcoming
                recurring meetings.
              </label>
            </SettingsLayout.Right>
          </SettingsLayout.Row>
          <SettingsLayout.Row>
            <SettingsLayout.Left label="Automate meeting agenda for your future meetings">
              Templates
            </SettingsLayout.Left>
            <SettingsLayout.Right>
              <TemplateForm meetingGroup={meetingGroup} />
            </SettingsLayout.Right>
          </SettingsLayout.Row>
        </SettingsLayout.Container>
      </div>
    </div>
  );
};

export default SettingsTab;
