import { GetMeetingGroupQueryQuery } from "types/graphql-schema";

import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

type Props = {
  meetingGroup: NonNullable<GetMeetingGroupQueryQuery["meetingGroup"]>;
};
const MeetingSettingsHeader: React.FC<Props> = ({ meetingGroup }) => {
  const title = meetingGroup?.title || "";
  useDocumentTitle(title);
  const defaultMeetingUrl = `/meeting/${meetingGroup.id}`;
  const queryParams = useUrlQueryParams();
  const from = queryParams.get("from");

  return (
    <>
      <div className="px-3 sm:px-6 h-14 border-b flex items-center bg-white sticky top-14 md:top-0 z-horizontalNavbar">
        <header className="w-full fs-mask flex gap-4 justify-between items-center">
          <AppLink
            className="font-medium text-gray-800 truncate hover:underline"
            to={
              from && from.includes(defaultMeetingUrl)
                ? from
                : defaultMeetingUrl
            }
          >
            {title}
          </AppLink>
        </header>
      </div>
    </>
  );
};

export default MeetingSettingsHeader;
