import moment from "moment";
import { useEffect, useState } from "react";

import usePageVisibility from "./use-page-visibility";

export default function usePageVisibleDebounced(
  callback: () => void,
  seconds = 30
) {
  const [fetchedLastTime, setFetchedLastTime] = useState(moment().format());
  const isVisible = usePageVisibility();

  useEffect(() => {
    if (
      isVisible &&
      moment(fetchedLastTime).add(seconds, "seconds").isBefore(moment())
    ) {
      setFetchedLastTime(moment().format());
      callback();
    }
  }, [callback, isVisible, fetchedLastTime, seconds]);
}
