import { PlusCircleIcon } from "@heroicons/react/outline";
import { ReactElement, useState } from "react";

import ArtifactCreationDialog, {
  ArtifactCreationDialogFormType,
} from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Button, { buttonTheme } from "@components/button/button";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import {
  ToggleButtonGroup,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import Tooltip from "@components/tooltip/tooltip";

const MeetingSidebarCollapsibleContainer = ({
  titleLabel,
  collapsibleFilterName,
  toggledAssignee,
  toggledJustMeeting,
  isFormalOneonone,
  createArtifactFormOptions,
  subject,
  filters,
  children,
}: {
  titleLabel: "recognition" | "goal" | "action item" | "feedback";
  collapsibleFilterName: "Recognitions" | "Goals" | "ActionItems" | "Feedback";
  toggledAssignee: boolean;
  toggledJustMeeting: boolean;
  isFormalOneonone: boolean;
  createArtifactFormOptions: Partial<ArtifactCreationDialogFormType>;
  subject?: {
    id: number;
    firstName: string;
    email: string;
  } | null;
  filters?: ReactElement | ReactElement[];
  children: ReactElement | ReactElement[];
}) => {
  const label = useLabel();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);
  const { saveUiPreference } = useUiPreferenceCache();

  const handleToggleJustMePreference = (
    button: ToggleButtonGroupType<boolean>
  ) => {
    const justMe = button.value;
    if (isFormalOneonone) {
      saveUiPreference({
        [`oneonone${collapsibleFilterName}JustMe`]: justMe,
      });
    } else {
      saveUiPreference({
        [`meeting${collapsibleFilterName}JustMe`]: justMe,
      });
    }
  };

  const handleToggleJustMeetingPreference = (
    button: ToggleButtonGroupType<boolean>
  ) => {
    const justMeeting = button.value;
    if (isFormalOneonone) {
      saveUiPreference({
        [`oneonone${collapsibleFilterName}JustMeeting`]: justMeeting,
      });
    } else {
      saveUiPreference({
        [`meeting${collapsibleFilterName}JustMeeting`]: justMeeting,
      });
    }
  };

  const assigneeLabel =
    isFormalOneonone && subject ? subject.firstName || subject.email : "Me";

  return (
    <div aria-label={`Meeting ${titleLabel}s sidebar`}>
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={createArtifactFormOptions}
          onClose={() => setIsShowingCreateDialog(false)}
        />
      )}
      <CollapsibleContainerParent
        title={label(titleLabel, { pluralize: true, capitalize: true })}
        rightSide={
          <div className="flex justify-end items-center gap-2 flex-1">
            <div className="flex items-center gap-2">
              {filters ? (
                filters
              ) : (
                <>
                  <ToggleButtonGroup<boolean>
                    onClick={handleToggleJustMeetingPreference}
                    buttons={[
                      {
                        tooltip: `Show ${label(titleLabel, {
                          pluralize: true,
                        })} referenced in and outside this meeting`,
                        active: !toggledJustMeeting,
                        value: false,
                        title: "All",
                      },
                      {
                        tooltip: `Show only ${label(titleLabel, {
                          pluralize: true,
                        })} that have been referenced in this meeting`,
                        active: toggledJustMeeting,
                        title: "Meeting",
                        value: true,
                      },
                    ]}
                  />
                  <ToggleButtonGroup<boolean>
                    onClick={handleToggleJustMePreference}
                    buttons={[
                      {
                        tooltip: `Show ${label(titleLabel, {
                          pluralize: true,
                        })} owned by any meeting participants`,
                        active: !toggledAssignee,
                        value: false,
                        title: "Participants",
                      },
                      {
                        tooltip: `Show ${label(titleLabel, {
                          pluralize: true,
                        })} owned by ${assigneeLabel}`,
                        active: toggledAssignee,
                        title: assigneeLabel,
                        value: true,
                      },
                    ]}
                  />
                </>
              )}
            </div>

            <div className="flex items-center">
              <Tooltip text={`Create ${label(titleLabel)}`}>
                <Button
                  className="shrink-0"
                  icon
                  theme={buttonTheme.iconGray}
                  onClick={() => setIsShowingCreateDialog(true)}
                >
                  <PlusCircleIcon className="h-6 w-6" />
                </Button>
              </Tooltip>
            </div>
          </div>
        }
      >
        {children}
      </CollapsibleContainerParent>
    </div>
  );
};

export default MeetingSidebarCollapsibleContainer;
