import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { toLower } from "lodash";

import { errorNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";

type ErrorMatchType = {
  match: string;
  title: string;
  description?: string;
};

type UseNotificationErrorType = {
  errorMatches: Array<ErrorMatchType>;
  error?: any;
};

const failedToFetch = "failed to fetch";
const anUnexpectedErrorOccurred = "An unexpected error occurred.";
const offlineError =
  "Make sure you are connected to the internet and try again.";

const matchError = (err: string | Error, match: string) =>
  String(err).toLocaleLowerCase().includes(match.toLocaleLowerCase());

const getErrorComponentProps = ({
  errorMatches,
  error,
}: {
  errorMatches: ErrorMatchType[];
  error?: any;
}) => {
  if (!error) return null;

  // common errors (logged out/offline)
  if (matchError(error, failedToFetch)) {
    return {
      title: anUnexpectedErrorOccurred,
      description: offlineError,
      error,
    };
  }

  // return props for Error component if there is a match
  const errorMatch = errorMatches.find(({ match }) => matchError(error, match));
  if (errorMatch) {
    return { ...errorMatch, error };
  }

  // default error message
  return {
    error,
    title: anUnexpectedErrorOccurred,
    description: "We have been notified. Please try again.",
  };
};

const onErrorHandler = ({
  errorMatches,
  useNotifications,
}: {
  errorMatches: ErrorMatchType[];
  useNotifications: boolean;
}) =>
  function (error: ApolloError) {
    if (error.networkError) {
      if (
        "statusCode" in error.networkError &&
        error.networkError.statusCode === 401
      ) {
        const loginUrl = `/login?next=${window.location.pathname}`;
        errorNotificationVar({
          timeout: null,
          title: "Your session has expired.",
          description: (
            <div>
              Please refresh the page and{" "}
              <a href={loginUrl} className="underline text-blue-600">
                log in
              </a>
              .
              <div className="mt-4">
                <Button url={loginUrl} theme={buttonTheme.primary} small>
                  Login
                </Button>
              </div>
            </div>
          ),
        });
        return;
      } else if (
        toLower(String(error.networkError)).includes("failed to fetch")
      ) {
        errorNotificationVar({
          description:
            "Make sure you are connected to the internet and try again.",
        });
        return;
      } else {
        errorNotificationVar({
          description: `An unexpected error occurred. We have been notified. Please try again.${
            window.Cypress ? JSON.stringify(error.networkError) : ""
          }`,
        });
        return;
      }
    }

    // if we handle the error from the hook, then we don't need to show any notifications
    if (matchError(error, failedToFetch)) {
      if (useNotifications) {
        errorNotificationVar({ description: offlineError });
      }
      return;
    }

    // handle notification custom errors
    const errorMatch = errorMatches.find(({ match }) =>
      String(error).toLocaleLowerCase().includes(match.toLocaleLowerCase())
    );
    if (errorMatch) {
      if (useNotifications) {
        errorNotificationVar(errorMatch);
      }
      return;
    }

    // throw error if does not have any matches
    errorNotificationVar({
      title: anUnexpectedErrorOccurred,
      description: "We have been notified. Please try again.",
    });

    // track graphql errors to sentry
    if (!window.Cypress) {
      Sentry.withScope((scope) => {
        const queryDefinition = (this?.query?.definitions || []).find(
          (definition: any) => definition?.operation === "query"
        );
        const errorName = queryDefinition?.name?.value
          ? queryDefinition.name.value
          : error.name;
        if (error.graphQLErrors?.[0]?.path) {
          // We can also add the path as breadcrumb
          scope.addBreadcrumb({
            category: "query-path",
            message: error.graphQLErrors[0]?.path.join(" > "),
          });
        }
        Sentry.captureMessage(`${errorName}: ${error.message}`);
      });
    }
  };

export const onNotificationErrorHandler = (
  errorMatches?: Array<ErrorMatchType>
) =>
  onErrorHandler({ errorMatches: errorMatches || [], useNotifications: true });

export const useNotificationError = (
  args: { errorMatches: Array<ErrorMatchType> } = {
    errorMatches: [],
  }
) => {
  const { errorMatches } = args;
  return {
    onError: onErrorHandler({ errorMatches, useNotifications: true }),
  };
};
export const useComponentError = (
  args: UseNotificationErrorType = { errorMatches: [], error: null }
) => {
  const { errorMatches = [], error = null } = args;
  return {
    onError: onErrorHandler({ errorMatches, useNotifications: false }),
    errorProps: getErrorComponentProps({ errorMatches, error }),
  };
};
