import { gql } from "@apollo/client";

import { OrgSettingsOrganizationFragment } from "./get-organization-settings-query";

export default gql`
  ${OrgSettingsOrganizationFragment}
  mutation createOrUpdateOrganizationSettings(
    $organizationId: Int!
    $name: String
    $goalLabel: String
    $orgLabel: String
    $recognitionLabel: String
    $teamLabel: String
    $reviewLabel: String
    $expectationLabel: String
    $conversationLabel: String
    $developmentLabel: String
    $membershipSetting: OrganizationMembershipSetting
    $enableMeetingSummarization: Boolean
    $transcribeMeetingsByDefault: Boolean
    $defaultGoalState: GoalState
    $defaultDecisionState: DecisionState
    $quarterStartMonth: Int
    $featureFlags: OrganizationFeatureFlagsInput
  ) {
    createOrUpdateOrganization(
      organizationId: $organizationId
      name: $name
      featureFlags: $featureFlags
      goalLabel: $goalLabel
      teamLabel: $teamLabel
      quarterStartMonth: $quarterStartMonth
      orgLabel: $orgLabel
      recognitionLabel: $recognitionLabel
      reviewLabel: $reviewLabel
      expectationLabel: $expectationLabel
      conversationLabel: $conversationLabel
      developmentLabel: $developmentLabel
      membershipSetting: $membershipSetting
      enableMeetingSummarization: $enableMeetingSummarization
      transcribeMeetingsByDefault: $transcribeMeetingsByDefault
      defaultGoalState: $defaultGoalState
      defaultDecisionState: $defaultDecisionState
    ) {
      organization {
        ...OrgSettingsOrganizationFragment
      }
    }
  }
`;
