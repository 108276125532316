import { useMutation } from "@apollo/client";
import { useState } from "react";

import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric from "@components/combobox/generic-combobox";
import Dropdown from "@components/dropdown/dropdown";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import createOrUpdateSlackNotificationForMeetingGroupMutation from "./graphql/create-or-update-slack-notification-for-meeting-group-mutation";
import deleteSlackNotificationForMeetingGroupMutation from "./graphql/delete-slack-notification-for-meeting-group-mutation";

const SettingsSlackNotification = ({
  meetingGroup,
  slackNotification,
  channels,
  existingChannelIds,
}) => {
  const noSelectedChannel = {
    label: "Select Slack channel",
    value: null,
    placeholder: true,
  };
  const matchingChannel =
    channels.find(({ value }) => value === slackNotification?.channel) ||
    noSelectedChannel;
  const [selectedChannel, setSelectedChannel] = useState(matchingChannel);
  const [
    createOrUpdateSlackNotificationForMeetingGroup,
    { loading: loadingSave },
  ] = useMutation(createOrUpdateSlackNotificationForMeetingGroupMutation);
  const [deleteSlackNotificationsForMeetingGroup, { loading: loadingDelete }] =
    useMutation(deleteSlackNotificationForMeetingGroupMutation);

  // Computed data
  const defaultChannels =
    matchingChannel.value === noSelectedChannel.value
      ? [noSelectedChannel]
      : [];
  const filteredChannels = defaultChannels
    .concat(channels)
    .filter(({ value }) => !existingChannelIds.includes(value));

  const handleSaveSlackNotification = () => {
    if (selectedChannel.value === noSelectedChannel.value) {
      handleDeleteSlackNotification();
      return;
    }
    createOrUpdateSlackNotificationForMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        notificationId: slackNotification?.id || null,
        channel: selectedChannel.value,
      },
      onCompleted() {
        if (!slackNotification) {
          setSelectedChannel(noSelectedChannel);
        }
      },
      onError: onNotificationErrorHandler(),
    });
  };
  const handleDeleteSlackNotification = () => {
    deleteSlackNotificationsForMeetingGroup({
      variables: {
        notificationId: slackNotification.id,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const loading = loadingSave || loadingDelete;
  return (
    <div className="w-full flex gap-3 items-center py-2">
      <ComboboxGeneric
        className="max-w-96 flex-1"
        options={filteredChannels}
        loading={loading}
        disabled={loading || !meetingGroup.canUpdate.permission}
        onChangeValue={setSelectedChannel}
        value={selectedChannel}
      />
      {((slackNotification &&
        matchingChannel.value !== selectedChannel.value) ||
        !slackNotification) && (
        <Button
          text={slackNotification ? "Update" : "Create new Slack notification"}
          disabled={
            matchingChannel.value === selectedChannel.value ||
            loading ||
            !meetingGroup.canUpdate.permission
          }
          theme={buttonTheme.primary}
          onClick={handleSaveSlackNotification}
        />
      )}
      {slackNotification && (
        <Dropdown
          className="px-0"
          options={[
            {
              label: "Delete",
              onClick: handleDeleteSlackNotification,
            },
          ]}
        />
      )}
      {loading && <Loading mini size="5" />}
    </div>
  );
};

export default SettingsSlackNotification;
