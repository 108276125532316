import { makeVar } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import {
  ActionItemCollapsibleFragmentFragment,
  ArtifactType,
  GetActionItemsCollapsibleQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ActionItemsCollapsibles from "@apps/action-items-collapsible/action-items-collapsibles";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Error from "@components/error/error";

export const droppedActionVar = makeVar(null);

const ActionItems = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const currentUser = currentUserVar();
  const organization = currentOrganizationVar();
  const filterResults =
    (queryVariables: GetActionItemsCollapsibleQueryQueryVariables) =>
    (node: ActionItemCollapsibleFragmentFragment) =>
      node.__typename === "ActionItemArtifactNode" &&
      node.assignee?.id === queryVariables.assigneeId;

  // RENDER
  const actionItemStates = organization.actionItemStates;
  const openedActionItemStates = compact(
    actionItemStates.filter((actionItemState) => !actionItemState?.isComplete)
  );
  return (
    <div aria-label="Dashboard action item container">
      <ActionItemsCollapsibles
        variables={{
          after: null,
          assigneeId: selectedUser.id,
        }}
        creationDialogFormOptions={{
          artifactType: ArtifactType.ActionItem,
          assignee: selectedUser,
        }}
        containerTitle={
          selectedUser.id !== currentUser.id
            ? `${selectedUser?.firstName}'s Action Items`
            : "Action Items"
        }
        orderContextId={selectedUser.id}
        orderContextTypePrefix="dashboard_action_items"
        actionItemStates={openedActionItemStates}
        filterResults={filterResults}
        uiPreferenceCacheNamespace="dashboardActionItemsExpanded"
      />
    </div>
  );
};

export default withErrorBoundary(ActionItems, {
  fallback: <Error description={"The action items could not be rendered."} />,
});
