import { useMutation } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import moment from "moment";
import { useCallback } from "react";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  UpdateMeetingFinalizationStatusNewPageMutationMutation,
  UpdateMeetingFinalizationStatusNewPageMutationMutationVariables,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import { useNotificationError } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import updateMeetingFinalizationStatusMutation from "./graphql/update-meeting-finalization-status-mutation";

const FinalizeOneononeToggleSwitch = ({
  meetingId,
  topics,
  canFinalize,
  isFinalized,
  tooltip,
  hasIncompleteMandatoryTopics,
}: {
  meetingId: number;
  topics: MeetingViewMeetingNodeNewPageFragmentFragment["topics"];
  canFinalize: MeetingViewMeetingNodeNewPageFragmentFragment["canFinalize"];
  isFinalized: boolean;
  tooltip: string;
  hasIncompleteMandatoryTopics: boolean;
}) => {
  const currentUser = currentUserVar();
  const { onError } = useNotificationError({
    errorMatches: [
      {
        match: "Cannot finalize a meeting with incomplete mandatory topics",
        title: "In order to be finalized, all topics must contains notes.",
      },
    ],
  });

  const [toggleFinalizeMeeting] = useMutation<
    UpdateMeetingFinalizationStatusNewPageMutationMutation,
    UpdateMeetingFinalizationStatusNewPageMutationMutationVariables
  >(updateMeetingFinalizationStatusMutation);

  const handleClickFinalizeMeeting = useCallback(
    (newIsFinalized: boolean) => {
      const nonNullTopics = assertNonNull(topics);
      toggleFinalizeMeeting({
        variables: {
          meetingId: meetingId,
          isFinalized: newIsFinalized,
        },
        onError: onError,
        optimisticResponse: {
          __typename: "Mutation",
          updateMeetingFinalizationStatus: {
            meeting: {
              id: meetingId,
              __typename: "MeetingNode",
              isFinalized: newIsFinalized,
              finalizedAt: newIsFinalized ? moment().format() : null,
              finalizedBy: newIsFinalized ? currentUser : null,
              topics: {
                ...nonNullTopics,
                edges: nonNullTopics.edges.map((edge) => {
                  const topicEdge = assertNonNull(edge);
                  const topicEdgeNode = assertNonNull(topicEdge.node);
                  return {
                    ...topicEdge,
                    node: {
                      ...topicEdgeNode,
                      canUpdate: {
                        ...topicEdgeNode.canUpdate,
                        permission: !newIsFinalized,
                      },
                      canDelete: {
                        ...topicEdgeNode.canDelete,
                        permission: !newIsFinalized,
                      },
                    },
                  };
                }),
              },
            },
            __typename: "UpdateMeetingFinalizationStatus",
          },
        },
      });
    },
    [topics, currentUser, toggleFinalizeMeeting, meetingId, onError]
  );

  return (
    <ToggleSwitch
      tooltip={
        hasIncompleteMandatoryTopics
          ? "Some mandatory topics require notes before the meeting can be finalized."
          : tooltip
      }
      disabled={hasIncompleteMandatoryTopics || !canFinalize.permission}
      checked={isFinalized}
      onChange={handleClickFinalizeMeeting}
    />
  );
};

export default withErrorBoundary(FinalizeOneononeToggleSwitch, {
  fallback: <Error description={"This content could not be rendered."} />,
});
