import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  createHttpLink,
  from,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { getOperationName } from "@apollo/client/utilities";
import Cookies from "js-cookie";
import { uniq } from "lodash";

import cache, { appVersionVar, editorVersionVar } from "@cache/cache";
import { pusher } from "@helpers/pusher";

// Client
const customFetch = (uri: RequestInfo | URL, options: any) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}?opname=${operationName}`, options);
};

const customBatchFetch = (uri: RequestInfo | URL, options: any) => {
  const body = JSON.parse(options.body);
  const operationName = uniq(
    body.map(({ operationName }: { operationName: string }) => operationName)
  ).join();
  return fetch(`${uri}?opname=${operationName}`, options);
};

const httpLink = createHttpLink({
  uri: `/graphql`,
  fetch: customFetch,
});

const publicHttpLink = createHttpLink({
  uri: `/public_graphql/`,
  fetch: customFetch,
});

const batchHttpLink = new BatchHttpLink({
  uri: `/graphql`,
  fetch: customBatchFetch,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get("csrftoken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      [`X-CSRFToken`]: token,
      // https://pusher.com/docs/channels/server_api/excluding-event-recipients/
      // we send this socket id to prevent pusher events to received by user emitting the event.
      [`X-pusher-socket-id`]: pusher?.connection.socket_id,
    },
  };
});

// Convert the value to a number if it is the id field and it is actually a number
const convertIdToInteger = (key: string, value: any) => {
  return key === "id" ? (isNaN(value) ? value : parseInt(value)) : value;
};

const convertIdToIntegerLink = new ApolloLink((operation, forward) => {
  const newOperation = operation;
  return forward(newOperation).map((data) => {
    return JSON.parse(JSON.stringify(data), convertIdToInteger);
  });
});

const appVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // set app version
    const appVersion = operation
      .getContext()
      .response.headers.get("X-App-Version");
    appVersionVar(appVersion);

    // set editor version
    const newVersion = operation
      .getContext()
      .response.headers.get("X-Editor-Extensions-Version");
    if (newVersion) {
      const currentEditorVersion = editorVersionVar();
      editorVersionVar({
        ...currentEditorVersion,
        oldVersion: currentEditorVersion.oldVersion
          ? currentEditorVersion.oldVersion
          : newVersion,
        newVersion,
      });
    }
    return response;
  });
});

const clientDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
};
const client = new ApolloClient({
  link: from([authLink, convertIdToIntegerLink, appVersionLink, httpLink]),
  cache,
  defaultOptions: clientDefaultOptions,
});

export const publicClient = new ApolloClient({
  link: from([
    authLink,
    convertIdToIntegerLink,
    appVersionLink,
    publicHttpLink,
  ]),
  cache,
  defaultOptions: clientDefaultOptions,
});

export const batchClient = new ApolloClient({
  link: from([authLink, convertIdToIntegerLink, appVersionLink, batchHttpLink]),
  cache,
  defaultOptions: clientDefaultOptions,
});

export const getWatchedQuery = (query: any) => {
  const queries = Array.from(client.getObservableQueries().entries()).map(
    (arr) => arr[1]
  );

  return queries.find(({ queryName }) => queryName === getOperationName(query));
};

export const getWatchedQueries = (query: any) => {
  const queries = Array.from(client.getObservableQueries().entries()).map(
    (arr) => arr[1]
  );
  return queries.filter(
    ({ queryName }) => queryName === getOperationName(query)
  );
};

export default client;
