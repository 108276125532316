import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { range } from "lodash";
import { Fragment, ReactElement, useCallback } from "react";
import {
  GetDashboardFeedbackQueryQuery,
  GetDashboardFeedbackQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardFeedbackQuery from "../graphql/get-dashboard-feedback-query";

const FeedbackCollapsibleContainer = ({
  title,
  filters,
  titleRightSide = null,
  localStorageKey,
  roundedBottom = false,
}: {
  title: string;
  localStorageKey: keyof UiPreferenceCache;
  roundedBottom?: boolean;
  filters: GetDashboardFeedbackQueryQueryVariables;
  titleRightSide?: ReactElement | null;
}) => {
  const label = useLabel();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();

  const { loading, data, fetchMore } = useQuery<
    GetDashboardFeedbackQueryQuery,
    GetDashboardFeedbackQueryQueryVariables
  >(getDashboardFeedbackQuery, {
    variables: { after: null, ...filters },
    skip: !uiPreferenceCache[localStorageKey],
    onError: onNotificationErrorHandler(),
  });
  const feedback = data?.artifacts ? assertEdgesNonNull(data.artifacts) : [];

  const handleClickMore = () => {
    if (data?.artifacts) {
      fetchMore({
        variables: {
          after: data.artifacts.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  const handleToggleContainer = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ [`${localStorageKey}`]: isOpen });
    },
    [saveUiPreference, localStorageKey]
  );

  return (
    <CollapsibleContainer
      container={Fragment}
      startOpen={uiPreferenceCache[localStorageKey]}
      title={title}
      titleRightSide={titleRightSide}
      roundedBottom={roundedBottom}
      onToggle={handleToggleContainer}
    >
      {!data && loading ? (
        <div className="flex flex-col divide-y divide-gray-100">
          {range(3).map((i) => (
            <div key={i} className="flex justify-between bg-white px-4 py-3">
              <div className="skeleton h-6 w-72 rounded-lg" />
              <div className="skeleton h-6 w-16 rounded-lg" />
            </div>
          ))}
        </div>
      ) : feedback.length ? (
        <div
          className="divide-y divide-gray-100"
          aria-label={`${title} feedback dashboard container`}
        >
          {feedback.map(
            (feedbackItem) =>
              feedbackItem.__typename === "FeedbackArtifactNode" && (
                <div className="px-3 py-2" key={feedbackItem.id}>
                  <Artifact artifact={feedbackItem} />
                </div>
              )
          )}
          {data?.artifacts?.pageInfo.hasNextPage && (
            <div className="px-4 py-2 flex items-center justify-center">
              {loading ? (
                <Loading size="5" mini />
              ) : (
                <button
                  className="text-gray-500 text-center text-xs mr-4 hover:bg-gray-100 rounded px-1.5 py-0.5"
                  onClick={handleClickMore}
                  disabled={loading}
                >
                  View more
                </button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="px-4 py-3 text-gray-600 text-sm">
            No {label("feedback", { pluralize: true })} to show.
          </div>
        </div>
      )}
    </CollapsibleContainer>
  );
};

export default Sentry.withErrorBoundary(FeedbackCollapsibleContainer, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
