import { ReactElement } from "react";

import AppLink from "@components/link/link";

const StatsContainer = ({
  label,
  children,
  url,
}: {
  label: string;
  children: ReactElement;
  url?: string;
}) => (
  <div className="bg-white rounded shadow-sm px-3 py-2 flex flex-col gap-2 group">
    {url ? (
      <AppLink
        to={url}
        className="uppercase text-xs text-gray-500 group-hover:underline"
      >
        {label}
      </AppLink>
    ) : (
      <div className="uppercase text-xs text-gray-500">{label}</div>
    )}
    {children}
  </div>
);

export default StatsContainer;
