import { gql } from "@apollo/client";

export default gql`
  mutation updateMeetingFinalizationStatusMutation(
    $meetingId: Int!
    $isFinalized: Boolean!
  ) {
    updateMeetingFinalizationStatus(
      meetingId: $meetingId
      isFinalized: $isFinalized
    ) {
      meeting {
        id
        isFinalized
        finalizedBy {
          id
          name
        }
        finalizedAt
        topics {
          edges {
            node {
              id
              isMandatory
              canUpdate {
                permission
              }
              canDelete {
                permission
              }
            }
          }
        }
      }
    }
  }
`;
