import { gql } from "@apollo/client";

export default gql`
  query getMeetingGroupSlackQuery($meetingGroupId: Int!) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      canUpdate {
        permission
      }
      slackNotifications {
        edges {
          node {
            id
            channel
          }
        }
      }
      organization {
        id
        hasSlackConnection
        publicSlackChannels {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
