import { useQuery } from "@apollo/client";
import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  PlusSmIcon,
} from "@heroicons/react/outline";
import { range } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  GetSidebarDataQueryQuery,
  GetSidebarDataQueryQueryVariables,
  MeetingDialogFragmentFragment,
} from "types/graphql-schema";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";
import Link, { useLink } from "@components/link/link";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePageVisibility from "@components/use-page-visibility/use-page-visibility";
import { assertEdgesNonNull } from "@helpers/helpers";

import getSidebarDataQuery from "../graphql/get-sidebar-data-query";
import SidebarMeeting from "./sidebar-meeting";

const SidebarMeetings = () => {
  const currentUser = currentUserVar();
  const [meetingDate, setMeetingDate] = useState(moment().format("YYYY-MM-DD"));
  const debouncedMeetingDate = useDebounce(meetingDate, 500);
  const lastTimeVisible = useRef<null | string>(null);
  const isVisible = usePageVisibility();
  const getTimeAgo = () => ({
    startDatetimeGte: moment(debouncedMeetingDate).startOf("day").format(),
    startDatetimeLte: moment(debouncedMeetingDate).endOf("day").format(),
  });
  const { data, loading } = useQuery<
    GetSidebarDataQueryQuery,
    GetSidebarDataQueryQueryVariables
  >(getSidebarDataQuery, {
    pollInterval: 5 * 60 * 1000, // 5 minutes
    skipPollAttempt: () => {
      return !isVisible;
    },
    variables: {
      ...getTimeAgo(),
      participants: [currentUser.id],
    },
    onError: onNotificationErrorHandler(),
  });

  const link = useLink();

  const handleClickPreviousDay = () => {
    setMeetingDate(moment(meetingDate).subtract(1, "day").format("YYYY-MM-DD"));
  };
  const handleClickNextDay = () => {
    setMeetingDate(moment(meetingDate).add(1, "day").format("YYYY-MM-DD"));
  };
  const handleClickToday = () => {
    setMeetingDate(moment().format("YYYY-MM-DD"));
  };

  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);

  const meetings = (
    data?.meetings ? assertEdgesNonNull(data.meetings) : []
  ).filter(({ meetingGroup }) => meetingGroup); // clean up meeting with no meeting groups
  const unscheduledMeetings = data?.unscheduledMeetings
    ? assertEdgesNonNull(data.unscheduledMeetings)
    : []; // clean up meeting with no meeting groups

  useEffect(() => {
    if (!isVisible) {
      lastTimeVisible.current = moment().format();
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      isVisible &&
      lastTimeVisible.current !== null &&
      (!moment().isSame(lastTimeVisible.current, "day") ||
        moment().isAfter(moment(lastTimeVisible.current).add(30, "minutes")))
    ) {
      setMeetingDate(moment().format("YYYY-MM-DD"));
    }
  }, [isVisible]);

  return (
    <div>
      {showCreateMeetingModal && (
        <MeetingDialog
          refetchQueries={[getSidebarDataQuery]}
          onSaved={({
            meeting,
          }: {
            meeting: MeetingDialogFragmentFragment;
          }) => {
            setShowCreateMeetingModal(false);
            link.redirect(
              `/meeting/${meeting?.meetingGroup?.id}/${meeting.id}`
            );
          }}
          onClose={() => setShowCreateMeetingModal(false)}
        />
      )}
      <div className="fs-mask" aria-label="Daily meetings">
        <div className="sidebar-meeting">
          <div className="flex items-center justify-between pt-2 pb-0.5">
            <Tooltip text={moment(meetingDate).format("dddd, MMMM D, YYYY")}>
              <AppLink
                to="/calendar"
                className="text-gray-500 uppercase px-2 py-2 font-semibold text-xs hover:underline"
              >
                {moment(meetingDate).format("ddd DD")}
              </AppLink>
            </Tooltip>
            <div className="flex items-center gap-2">
              {!moment(meetingDate).isSame(moment(), "day") && (
                <button
                  onClick={handleClickToday}
                  className="bg-transparent rounded-full text-gray-500 hover:bg-gray-100 px-2 py-px flex items-center justify-center uppercase text-[10px]"
                >
                  Today
                </button>
              )}
              <Tooltip
                text={moment(meetingDate).subtract(1, "days").format("ddd DD")}
              >
                <button
                  onClick={handleClickPreviousDay}
                  className="text-gray-500 rounded-lg hover:bg-gray-100 p-0.5"
                  aria-label="Sidebar previous day button"
                >
                  <ArrowSmLeftIcon className="h-5 w-5" />
                </button>
              </Tooltip>
              <Tooltip
                text={moment(meetingDate).add(1, "days").format("ddd DD")}
              >
                <button
                  onClick={handleClickNextDay}
                  className="text-gray-500 rounded-lg hover:bg-gray-100 p-0.5"
                  aria-label="Sidebar next day button"
                >
                  <ArrowSmRightIcon className="h-5 w-5" />
                </button>
              </Tooltip>
              <Tooltip text="New event">
                <button
                  onClick={() => setShowCreateMeetingModal(true)}
                  className="text-gray-500 rounded-lg hover:bg-gray-100 p-0.5"
                >
                  <PlusSmIcon className="h-5 w-5" />
                </button>
              </Tooltip>
            </div>
          </div>

          {loading && !data && (
            <div className="flex flex-col gap-2">
              {range(3).map((i) => (
                <div
                  key={i}
                  className="bg-gray-100 rounded-lg p-2 pl-3 flex justify-between gap-4"
                >
                  <div className="skeleton h-6 w-24 rounded-lg" />
                  <div className="skeleton h-6 w-14 rounded-lg" />
                </div>
              ))}
            </div>
          )}
          {data && (
            <div className="flex flex-col gap-0.5">
              {meetings.length > 0 ? (
                meetings.map((meeting) => (
                  <SidebarMeeting key={meeting.id} meeting={meeting} />
                ))
              ) : (
                <div className="italic text-gray-500 py-2 px-2 text-sm">
                  No meetings
                </div>
              )}
            </div>
          )}
        </div>
        {data?.meetings?.pageInfo.hasNextPage && (
          <Link
            to="/calendar"
            className="block my-4 text-gray-600 text-sm hover:underline"
          >
            More meetings
          </Link>
        )}
        {unscheduledMeetings.length > 0 && (
          <div aria-label="Sidebar unscheduled meetings">
            <div className="flex items-center justify-between pt-2">
              <Tooltip text="Unscheduled meetings">
                <span className="text-gray-500 uppercase px-2 py-2 font-semibold text-xs hover:underline">
                  Unscheduled
                </span>
              </Tooltip>
            </div>
            <div className="flex flex-col gap-0.5">
              {unscheduledMeetings.map((meeting) => (
                <SidebarMeeting key={meeting.id} meeting={meeting} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarMeetings;
