import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { range } from "lodash";
import { Fragment, useCallback } from "react";
import {
  GetDashboardGoalsQueryQuery,
  GetDashboardGoalsQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardGoalsQuery from "../graphql/get-dashboard-goals-query";

const GoalsCollapsibleContainer = ({
  title,
  filters,
  localStorageKey,
  roundedBottom = false,
}: {
  title: string;
  localStorageKey: keyof UiPreferenceCache;
  roundedBottom?: boolean;
  filters: GetDashboardGoalsQueryQueryVariables;
}) => {
  const label = useLabel();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();

  const { loading, data, fetchMore } = useQuery<
    GetDashboardGoalsQueryQuery,
    GetDashboardGoalsQueryQueryVariables
  >(getDashboardGoalsQuery, {
    variables: {
      after: null,
      orderBy: "-updated",
      ...filters,
    },
    skip: !uiPreferenceCache[localStorageKey],
    onError: onNotificationErrorHandler(),
  });
  const goals = data?.goalsForUser ? assertEdgesNonNull(data.goalsForUser) : [];
  const pageInfo = data?.goalsForUser?.pageInfo;
  const totalCount = data?.goalsForUser?.totalCount || goals.length;

  const handleClickMore = () => {
    if (pageInfo) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  const handleToggleContainer = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ [`${localStorageKey}`]: isOpen });
    },
    [saveUiPreference, localStorageKey]
  );

  // RENDER
  return (
    <CollapsibleContainer
      container={Fragment}
      startOpen={uiPreferenceCache[localStorageKey]}
      title={`${title}${title ? " " : ""}${label("goal", {
        pluralize: true,
        capitalize: !title,
      })}`}
      roundedBottom={roundedBottom}
      onToggle={handleToggleContainer}
    >
      {!data && loading ? (
        <div className="flex flex-col divide-y divide-gray-100">
          {range(3).map((i) => (
            <div key={i} className="flex justify-between bg-white px-4 py-3">
              <div className="skeleton h-6 w-72 rounded-lg" />
              <div className="skeleton h-6 w-16 rounded-lg" />
            </div>
          ))}
        </div>
      ) : goals.length ? (
        <div
          className="divide-y divide-gray-100"
          aria-label={`${title} goals dashboard container`}
        >
          {goals.map((goal) => (
            <div key={goal.id} className="px-3 py-2">
              <Artifact artifact={goal} />
            </div>
          ))}
          {data?.goalsForUser?.pageInfo.hasNextPage && (
            <div className="px-4 py-2 flex items-center justify-center">
              {loading ? (
                <Loading size="5" mini />
              ) : (
                <button
                  className="text-gray-500 text-center text-xs mr-4 hover:bg-gray-100 rounded px-1.5 py-0.5"
                  onClick={handleClickMore}
                  disabled={loading}
                >
                  View {totalCount - goals.length} more
                </button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="px-4 py-3 text-gray-600 text-sm">
            No {label("goal", { pluralize: true })} to show.
          </div>
        </div>
      )}
    </CollapsibleContainer>
  );
};

export default Sentry.withErrorBoundary(GoalsCollapsibleContainer, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
