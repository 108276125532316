import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  GoalArtifactSidebarFragmentFragment,
  GoalProgressType,
  KeyResultFragmentFragment,
  KeyResultNode,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button from "@components/button/button";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import deleteGoalKeyResultMutation from "../graphql/delete-key-result-mutation";
import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import manageGoalKeyResultMutation from "../graphql/manage-goal-key-result-mutation";
import GoalKeyResultItem from "./goal-key-result-item";

const DEFAULT_KEY_RESULT: Omit<KeyResultNode, "id"> = {
  __typename: "KeyResultNode",
  progressType: GoalProgressType.Percentage,
  title: "",
  description: "",
  startValue: 0,
  currentValue: 0,
  targetValue: 100,
  canUpdate: {
    __typename: "PermissionNode",
    permission: true,
    reason: "",
  },
};

const GoalKeyResultList = ({
  goalArtifact,
  isReadOnly = false,
}: {
  goalArtifact: GoalArtifactSidebarFragmentFragment;
  isReadOnly?: boolean;
}) => {
  const label = useLabel();
  const [deleteKeyResult] = useMutation(deleteGoalKeyResultMutation);
  const [manageKeyResults, { loading: loadingUpdate }] = useMutation(
    manageGoalKeyResultMutation
  );
  const [addedKrId, setAddedKrId] = useState(null);

  const handleAddKeyResult = () => {
    manageKeyResults({
      variables: {
        ...DEFAULT_KEY_RESULT,
        title: label("key result", { capitalize: true }),
        goalId: goalArtifact.id,
      },
      update: (_, { data }) => {
        setAddedKrId(data.createOrUpdateKeyResult.keyResult.id);
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const handleDeleteKeyResult = (keyResult: KeyResultFragmentFragment) => {
    const newKeyResults = assertEdgesNonNull(goalArtifact.keyResults).filter(
      (node) => node.id !== keyResult.id
    );
    deleteKeyResult({
      variables: {
        keyResultId: keyResult.id,
      },
      optimisticResponse: {
        deleteKeyResult: {
          keyResult,
          goal: {
            ...goalArtifact,
            keyResults: {
              ...goalArtifact.keyResults,
              edges: newKeyResults,
            },
          },
        },
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  if (
    goalArtifact.keyResults?.edges.length === 0 &&
    !goalArtifact.canUpdate.permission
  ) {
    return null;
  }

  const keyResults = assertEdgesNonNull(goalArtifact.keyResults);

  if (keyResults.length === 0 && isReadOnly) {
    return null;
  }
  return (
    <div className="w-full">
      <div className="-mx-3 mb-2 px-3 text-sm font-medium">
        {keyResults.length}{" "}
        {label("key result", {
          pluralize: keyResults.length,
          capitalize: true,
        })}
      </div>
      <div className="flex flex-col items-start w-full border-t">
        {keyResults.map(
          (keyResult) =>
            keyResult && (
              <div
                className="border-b hover:bg-gray-50 w-full"
                key={keyResult.id}
                aria-label="Goal sidebar key result item"
              >
                <GoalKeyResultItem
                  defaultIsEditing={addedKrId === keyResult.id}
                  goalArtifact={goalArtifact}
                  keyResult={keyResult}
                  isReadOnly={isReadOnly}
                  onDelete={handleDeleteKeyResult}
                  onHideEditing={() => setAddedKrId(null)}
                />
              </div>
            )
        )}
        {!isReadOnly && goalArtifact.canUpdate.permission && (
          <div className="mt-3 flex items-center gap-2">
            <Button
              text={`Add ${label("key result")}`}
              aria-label="Add key result"
              onClick={handleAddKeyResult}
              mini
              disabled={loadingUpdate}
            />
            {loadingUpdate && <Loading mini size="4" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default GoalKeyResultList;
