import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { ReactNode } from "react";

import { classNames } from "@helpers/css";

export type DropdownOptionType = {
  label: string;
  description?: string | ReactNode;
  onClick: (data: { value?: any; syntheticEvent: any }) => void;
};

const Dropdown = ({
  options,
  className = "",
  children,
  ...props
}: {
  children?: JSX.Element;
  "aria-label"?: string;
  className?: string;
  options: DropdownOptionType[];
}) => {
  return (
    <Menu
      className="relative inline-block text-left text-sm not-prose z-dropdown fs-unmask"
      portal
      menuButton={
        children ? (
          children
        ) : (
          <button
            aria-label={props["aria-label"]}
            className={classNames("text-gray-400 rounded", className)}
          >
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className={`h-5 w-5`} aria-hidden="true" />
          </button>
        )
      }
    >
      {options.map(({ label, description, onClick }) => (
        <MenuItem
          key={label}
          className={classNames(
            "block w-full text-left px-4 py-2 text-sm",
            "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          )}
          onClick={onClick}
        >
          <div>{label}</div>
          {description && (
            <div className="text-gray-500 text-xs tracking-tight">
              {description}
            </div>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default Dropdown;
