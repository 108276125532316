import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

export type TableSortDir = "asc" | "desc";

export type TableProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  scroll?: boolean;
};

export const TableContainer = ({
  children,
  scroll,
  className,
  ...props
}: PropsWithChildren<TableProps>) => (
  <div
    className={classNames(
      "min-w-full border rounded-md",
      scroll ? "overflow-scroll" : "overflow-hidden",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export const Table = ({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLTableElement>>) => (
  <table className="bg-white min-w-full divide-y divide-gray-200" {...props}>
    {children}
  </table>
);

export const TableHeadCell = ({
  className = "",
  width = "",
  children = "",
  roundedLeft = false,
  roundedRight = false,
  sorted,
  onClick,
}: PropsWithChildren<{
  className?: string;
  width?: string;
  roundedLeft?: boolean;
  roundedRight?: boolean;
  sorted?: TableSortDir | null;
  onClick?: () => void;
}>) => (
  <th
    scope="col"
    className={classNames(
      "px-5 py-2 text-left text-xs font-medium text-gray-500 uppercase bg-gray-50",
      width && `w-${width}`,
      roundedLeft && "rounded-tl-md",
      roundedRight && "rounded-tr-md",
      onClick && "cursor-pointer hover:underline"
    )}
    onClick={onClick}
  >
    <div
      className={classNames(className, "flex items-center justify-start gap-2")}
    >
      <div className="flex-auto">{children}</div>
      {sorted === "asc" && (
        <div>
          <ArrowUpIcon className="h-3 w-3" />
        </div>
      )}
      {sorted === "desc" && (
        <div>
          <ArrowDownIcon className="h-3 w-3" />
        </div>
      )}
    </div>
  </th>
);

export const TableHeadRow = ({ children }: PropsWithChildren) => (
  <thead className="">
    <tr>{children}</tr>
  </thead>
);

export const TableBody = ({ children }: PropsWithChildren) => (
  <tbody>{children}</tbody>
);

export const TableBodyRow = ({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLTableRowElement>>) => (
  <tr className={classNames("border-t", className)} {...props}>
    {children}
  </tr>
);

export const TableBodyCell = ({
  children,
  className = "",
  colSpan,
}: PropsWithChildren<{ className?: string; colSpan?: number }>) => (
  <td
    className={classNames("px-5 py-3 text-sm text-gray-500", className)}
    colSpan={colSpan}
  >
    {children}
  </td>
);

export const TableFooter = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={classNames("px-5 pt-3 pb-1 text-sm text-gray-700", className)}
  >
    {children}
  </div>
);

export default Table;
