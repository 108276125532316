import { DashboardInsightFragment } from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";

import { InsightButton, InsightLayout } from "../insight";

const NominatePeersInsight = ({
  insight,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const currentUser = currentUserVar();
  const targetUserNames = insight.targetUsers.edges.map(
    (edge) => edge?.node?.firstName
  );

  // 1 target user = <first user>
  // 2 target users = <first user> and <second user>
  // 3 target users = <first user>, <second user> and <third user>
  // more than 3 target users = <first user>, <second user> and <total_count - 2> others
  const targetUserNameStr =
    insight.targetUsers.totalCount === 1
      ? targetUserNames[0]
      : insight.targetUsers.totalCount <= 3
      ? `${targetUserNames
          .slice(0, targetUserNames.length - 1)
          .join(", ")} and ${targetUserNames[targetUserNames.length - 1]}`
      : `${targetUserNames.slice(0, 2).join(", ")} and ${
          insight.targetUsers.totalCount - 2
        } others`;

  return (
    <InsightLayout
      user={currentUser}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          Nominate assessment providers for {insight?.assessmentGroup?.title}{" "}
          for {targetUserNameStr}
        </div>
      </div>

      <div>
        <InsightButton url={"/assessments?tab=nominations"}>
          Nominate Providers
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default NominatePeersInsight;
