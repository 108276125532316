import { useMutation } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import {
  MeetingViewMeetingNodeFragmentFragment,
  SubmitOneOnOneMutation,
  SubmitOneononeMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import Error from "@components/error/error";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import submitOneononeMutation from "./graphql/submit-oneonone-mutation";

const SubmitOneonone = ({
  meetingId,
}: {
  meetingId: MeetingViewMeetingNodeFragmentFragment["id"];
}) => {
  const label = useLabel();

  const [submitOneonone, { loading }] = useMutation<
    SubmitOneOnOneMutation,
    SubmitOneononeMutationVariables
  >(submitOneononeMutation);

  const handleClickSubmit = () => {
    submitOneonone({
      variables: {
        meetingId,
        unhideIndividualNotes: true,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div className="-mx-2 px-2">
      <div className="py-3 px-5 border border-indigo-200 bg-indigo-50 rounded-lg text-sm flex gap-4 text-gray-500 items-center justify-between">
        <div>
          <div className="font-medium text-indigo-900">
            Submit {label("oneonone")}
          </div>

          <div className="mt-1 text-xs">
            Submitting this {label("oneonone")} will reveal your notes to the
            other participant.
          </div>
        </div>
        <Button
          theme={buttonTheme.primary}
          onClick={handleClickSubmit}
          disabled={loading}
          className="gap-2 items-center truncate overflow-hidden"
        >
          <span>
            Submit{" "}
            <span className="hidden sm:inline md:hidden xl:inline">
              {label("oneonone")}
            </span>
          </span>
        </Button>
      </div>
    </div>
  );
};

export default withErrorBoundary(SubmitOneonone, {
  fallback: <Error description={"This content could not be rendered."} />,
});
