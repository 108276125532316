import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

const MeetingGroupGoalsFragment = gql`
  ${CommonGoalArtifactFragment}
  fragment MeetingGroupGoalsFragment on MeetingGroupNode {
    id
    incompleteGoals: goals(orderBy: "due_date", goalState: open) {
      edges {
        node {
          id
          artifactType
          title
          canDelete {
            permission
          }
          ... on GoalArtifactNode {
            currentUserIsOwner
            owners {
              edges {
                node {
                  id
                  name
                  avatar
                }
              }
            }
          }
          ...CommonGoalArtifactFragment
        }
      }
    }
    draftGoals: goals(orderBy: "due_date", goalState: draft) {
      edges {
        node {
          id
          artifactType
          title
          canDelete {
            permission
          }
          ... on GoalArtifactNode {
            currentUserIsOwner
            owners {
              edges {
                node {
                  id
                  name
                  avatar
                }
              }
            }
          }
          ...CommonGoalArtifactFragment
        }
      }
    }
    completeGoals: goals(
      orderBy: "due_date"
      goalState: closed
      completedGoalsForMeetingGroup: $meetingGroupId
      completedGoalsForFlow: $flowId
    ) {
      edges {
        node {
          id
          artifactType
          title
          canDelete {
            permission
          }
          ... on GoalArtifactNode {
            currentUserIsOwner
            owners {
              edges {
                node {
                  id
                  name
                  avatar
                }
              }
            }
          }
          ...CommonGoalArtifactFragment
        }
      }
    }
  }
`;

export default MeetingGroupGoalsFragment;
