import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GetDashboardSelectedUserQueryQuery,
  GetDashboardSelectedUserQueryQueryVariables,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import DashboardContent from "./components/dashboard-content";
import getDashboardSelectedUserQuery from "./graphql/get-dashboard-selected-user-query";

const Dashboard = () => {
  const currentUser = currentUserVar();
  const { userId } = useParams<{ userId: string | undefined }>();
  const selectedUserId = userId ? parseInt(userId) : currentUser.id;

  const { data, loading } = useQuery<
    GetDashboardSelectedUserQueryQuery,
    GetDashboardSelectedUserQueryQueryVariables
  >(getDashboardSelectedUserQuery, {
    variables: { userId: selectedUserId },
    onError: onNotificationErrorHandler(),
  });
  const selectedUser = data?.user;

  return !data && loading ? (
    <div className="mt-10">
      <Loading />
    </div>
  ) : selectedUser ? (
    <DashboardContent selectedUser={selectedUser} />
  ) : (
    <AppError title="User cannot be found" />
  );
};

export default Dashboard;
