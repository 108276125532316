import { makeVar } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import { useState } from "react";
import {
  ActionItemCollapsibleFragmentFragment,
  ArtifactType,
  GetActionItemsCollapsibleQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ActionItemsCollapsible from "@apps/action-items-collapsible/action-items-collapsible-container";
import ActionItemsCollapsibleDragAndDropContext from "@apps/action-items-collapsible/action-items-collapsible-drag-drop-context";
import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

export const droppedActionVar = makeVar(null);

const ActionItems = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const [isShowingCreateArtifactModal, setIsShowingCreateArtifactModal] =
    useState(false);
  const label = useLabel();
  const organization = currentOrganizationVar();
  const filterResults =
    (queryVariables: GetActionItemsCollapsibleQueryQueryVariables) =>
    (node: ActionItemCollapsibleFragmentFragment) =>
      node.__typename === "ActionItemArtifactNode" &&
      node.assignee?.id === queryVariables.assigneeId;

  // RENDER
  const actionItemStates = organization.actionItemStates;
  const openedActionItemStates = compact(
    actionItemStates
      .filter((actionItemState) => !actionItemState?.isComplete)
      .filter((actionItemState) => actionItemState !== null)
  );
  return (
    <>
      {isShowingCreateArtifactModal && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.ActionItem,
            assignee: selectedUser,
          }}
          onClose={() => setIsShowingCreateArtifactModal(false)}
        />
      )}
      <Layout.SidebarSection
        title={label("action item", { pluralize: true, capitalize: true })}
        expandedUiPreferenceKey="dashboardSidebarActionItemContainerExpanded"
        aria-label="Dashboard action item container"
        options={[
          {
            label: `Create ${label("action item")}`,
            onClick: ({ syntheticEvent }) => {
              syntheticEvent.preventDefault();
              syntheticEvent.stopPropagation();
              setIsShowingCreateArtifactModal(true);
            },
          },
        ]}
      >
        <ActionItemsCollapsibleDragAndDropContext
          actionItemStates={openedActionItemStates}
          orderContextId={selectedUser.id}
          orderContextTypePrefix="dashboard_action_items"
        >
          {openedActionItemStates.map((actionItemState) => (
            <ActionItemsCollapsible
              newSidebarDesign
              key={actionItemState?.value}
              actionItemState={actionItemState}
              uiPreferenceCacheNamespace={"meetingActionItemsExpanded"}
              filterResults={filterResults}
              queryVariables={{
                after: null,
                assigneeId: selectedUser.id,
                first: 5,
                orderBy: "custom",
                orderContextType: `${"meeting_group_action_items"}:${
                  actionItemState?.value
                }`,
                orderContextId: selectedUser.id,
                actionItemState: actionItemState.value,
              }}
            />
          ))}
        </ActionItemsCollapsibleDragAndDropContext>
      </Layout.SidebarSection>
    </>
  );
};

export default withErrorBoundary(ActionItems, {
  fallback: <Error description={"The action items could not be rendered."} />,
});
