import { useQuery } from "@apollo/client";
import actionItemEmptyPlaceholderIcon from "@static/img/action-items-empty-placeholder-icon.png";
import decisionEmptyPlaceholderIcon from "@static/img/decisions-empty-placeholder-icon.png";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ArtifactType } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import Artifact from "@apps/artifact/artifact";
import getActionItemsQuery from "@apps/meeting/graphql/get-action-items-query";
import getDecisionsQuery from "@apps/meeting/graphql/get-decisions-query";
import getDocumentsQuery from "@apps/meeting/graphql/get-documents-query";
import useLabel from "@apps/use-label/use-label";
import Link from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { artifactType } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  getArtifactBgColor,
  getAssetUrl,
  getUrl,
  toWithBackground,
} from "@helpers/helpers";

import getGoalsQuery from "../../graphql/get-goals-query";

const ArtifactsTab = ({
  meetingGroupId,
  flowId,
  type,
}: {
  meetingGroupId?: number;
  flowId?: number;
  type: ArtifactType;
}) => {
  const l = useLabel();
  const location = useLocation<TFLocationState>();
  const query =
    type === artifactType.goal
      ? getGoalsQuery
      : type === artifactType.actionItem
      ? getActionItemsQuery
      : type === artifactType.decision
      ? getDecisionsQuery
      : type === artifactType.document
      ? getDocumentsQuery
      : null;
  if (query === null) throw new Error("Type does not have a query");
  const { data, loading, fetchMore } = useQuery(query, {
    variables: {
      meetingGroupId: meetingGroupId || null,
      flowId: flowId || null,
    },
    onError: onNotificationErrorHandler(),
  });

  const dataTopics = (data?.topics ? assertEdgesNonNull(data.topics) : []) as {
    meeting: any;
    title: string;
    id: number;
    artifacts: any;
  }[];
  const topics = dataTopics.filter(
    ({ artifacts }: any) => artifacts.edges.length > 0
  );
  const meetings = uniqBy(
    topics.map((topic: any) => topic.meeting),
    "id"
  );
  const meetingsWithTopics = meetings.map((meeting) => ({
    meeting,
    topics: uniqBy(
      topics.filter((topic) => topic.meeting.id === meeting.id),
      "id"
    ),
  }));

  const handleClickLoadMore = () => {
    fetchMore({
      variables: {
        after: data.topics.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  return !data && loading ? (
    <div className="p-6">
      <Loading>loading {l(type, { pluralize: true })}</Loading>
    </div>
  ) : (
    data && (
      <div className="p-2 sm:p-6">
        <div className="mb-6 text-lg font-medium">
          {l(type, { capitalize: true, pluralize: true })}
        </div>
        {meetingsWithTopics.length === 0 ? (
          <div
            className="mt-12 flex flex-col pt-8 pb-12 text-gray-500"
            aria-label={`Empty ${l(type)} list`}
          >
            <div className="flex justify-center mb-4">
              {type === artifactType.decision ? (
                <img
                  src={getAssetUrl(decisionEmptyPlaceholderIcon)}
                  alt="Empty state"
                  style={{ width: "120px", height: "120px" }}
                />
              ) : (
                <img
                  src={getAssetUrl(actionItemEmptyPlaceholderIcon)}
                  alt="Empty state"
                  style={{ width: "120px", height: "120px" }}
                />
              )}
            </div>
            <div className="text-center text-xl mb-8 text-gray-600">
              {l(type, { pluralize: true, capitalize: true })} from meetings
              will appear here
            </div>
            <div className="text-lg text-center">
              Type{" "}
              <span className="font-mono text-sm py-0.5 text-blue-700 border-blue-500 border bg-white px-2 rounded mx-2">
                /
              </span>{" "}
              {type === artifactType.decision && (
                <>
                  or{" "}
                  <span className="font-mono text-sm py-0.5 text-blue-700 border-blue-500 border bg-white px-2 rounded mx-2">
                    {"=>"}
                  </span>{" "}
                </>
              )}
              {type === artifactType.actionItem && (
                <>
                  or{" "}
                  <span className="font-mono text-sm py-0.5 text-blue-700 border-blue-500 border bg-white px-2 rounded mx-2">
                    {"[ ]"}
                  </span>{" "}
                </>
              )}
              in the meeting notes to quickly create a new {l(type)}.
            </div>
          </div>
        ) : (
          <div>
            {meetingsWithTopics.map(({ meeting, topics }) => (
              <div
                className="mb-8 flex gap-2 sm:gap-4 w-full xl:w-2/3"
                key={meeting.id}
              >
                <div>
                  <Link
                    to={`/meeting/${meetingGroupId}/${meeting.id}`}
                    className="tracking-tight inline-block mt-2 sm:mt-4 uppercase text-xs font-medium text-gray-600 bg-gray-200 hover:text-blue-600 hover:bg-blue-100 rounded-lg py-1 w-24 text-center"
                  >
                    {moment(meeting.startDatetime).format("MMM D, YYYY")}
                  </Link>
                </div>
                <div className="flex-1 flex flex-col gap-8 bg-white p-2 sm:p-4 rounded-lg shadow">
                  {topics.map((topic) => (
                    <div key={topic.id}>
                      <div className="py-1 font-medium flex gap-2 items-center mb-2">
                        <Link
                          to={toWithBackground({
                            pathname: getUrl({
                              meetingGroupId: meeting.meetingGroupId,
                              meetingId: meeting.id,
                              topicId: topic.id,
                            }),
                            location,
                          })}
                        >
                          {topic.title}
                        </Link>
                      </div>
                      <div className="flex flex-col gap-3">
                        {topic.artifacts.edges.map(
                          ({ node: artifact }: any) => (
                            <div
                              key={artifact.id}
                              className={classNames(
                                "px-3 py-1 rounded-lg",
                                getArtifactBgColor(artifact)
                              )}
                            >
                              <Artifact artifact={artifact} />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {data?.topics.pageInfo.hasNextPage && (
              <div className="flex items-center gap-2">
                <button
                  className="py-1 text-sm text-gray-500 hover:text-gray-800"
                  onClick={handleClickLoadMore}
                  disabled={loading}
                >
                  More {l(type, { pluralize: true })}
                </button>
                {loading && <Loading mini size={5} />}
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default ArtifactsTab;
