import { Location } from "history";
import JSConfetti from "js-confetti";
import json5 from "json5";
import { compact, round } from "lodash";
import replace from "lodash/replace";
import moment from "moment";
import numeral from "numeral";
import { KeyboardEvent } from "react";
import { Formatter } from "react-timeago";
import {
  ArtifactInterface,
  ArtifactType,
  AssessmentType,
  BaseArtifactNode,
  GoalProgressType,
  MeetingNode,
  Node,
  UserNode,
} from "types/graphql-schema";
import {
  DateRangeEnum,
  PastOnlyDateRangeEnum,
  TFLocation,
} from "types/topicflow";
import { v4 as uuidv4 } from "uuid";

import {
  allArtifactTypes,
  currentOrganizationVar,
  urlPreviewCardNodeTypes,
} from "@cache/cache";
import { toObjectProps } from "@components/link/link";

import {
  artifactType,
  artifactTypeUrl,
  progressTypeBooleanStartValue,
  progressTypeBooleanTargetValue,
} from "./constants";
import { joinStringWithCommaAnd } from "./string";

export const getTiptapSchema = (node: any) => {
  if (!node || !("__typename" in node)) {
    return null;
  }
  // artifacts
  if ("id" in node && "artifactType" in node) {
    const uuid = "uuid" in node ? node.uuid : uuidv4();
    return {
      type: node.artifactType,
      attrs: { id: node.id, uuid },
    };
  }

  // integrations
  if (urlPreviewCardNodeTypes.includes(node.__typename) && "url" in node) {
    return {
      type: "url-preview",
      attrs: { url: node.url },
    };
  }

  // user
  if (node.__typename === "UserNode") {
    return {
      type: "paragraph",
      content: [
        {
          marks: [
            {
              type: "link",
              attrs: { href: getUrl({ userId: node.id }) },
            },
          ],
          type: "text",
          text: node.name,
        },
      ],
    };
  }

  throw new Error(`no matching tiptap schema for node ${node.__typename}`);
};

export const testIsArtifactTypename = (typename: string) => {
  return (
    typename === "ActionItemArtifactNode" ||
    typename === "FeedbackArtifactNode" ||
    typename === "DecisionArtifactNode" ||
    typename === "GoalArtifactNode" ||
    typename === "KPIArtifactNode" ||
    typename === "DocumentArtifactNode" ||
    typename === "RecognitionArtifactNode"
  );
};

// urlToInfoCard
export const getNodeUrl = (
  node:
    | {
        __typename: "MeetingWithRelevantSectionsNode";
        meeting?: {
          id: number;
          meetingGroupId: number;
        } | null;
      }
    | {
        __typename:
          | "JiraIssueNode"
          | "GithubIssueNode"
          | "HubspotDealNode"
          | "ClickupTaskNode"
          | "SalesforceOpportunityNode";
        url?: string | null;
      }
    | {
        __typename: "GithubIssueNode";
        url?: string | null;
      }
    | {
        __typename: "ExternalProfileNode";
        url?: string | null;
      }
    | {
        __typename: "UserNode";
        id: number;
      }
    | {
        __typename: "MeetingNode";
        id: number;
        meetingGroupId: number;
      }
    | {
        __typename: allArtifactTypes;
        id: number;
        artifactType: ArtifactType;
      }
) => {
  if (node.__typename === "MeetingWithRelevantSectionsNode") {
    if (node.meeting) {
      return getUrl({
        meetingId: node.meeting.id,
        meetingGroupId: node.meeting.meetingGroupId,
      });
    }
    return "";
  }
  if (node.__typename === "JiraIssueNode") return node.url;
  if (node.__typename === "GithubIssueNode") return node.url;
  if (node.__typename === "ExternalProfileNode" && node.url) return node.url;
  if (node.__typename === "UserNode" && node.id)
    return getUrl({ userId: node.id });
  if (node.__typename === "MeetingNode")
    return getUrl({
      meetingId: node.id,
      meetingGroupId: node.meetingGroupId,
    });
  if (testIsArtifactTypename(node.__typename)) {
    const artifactNode = node as BaseArtifactNode;
    return getUrl({
      artifactId: artifactNode.id,
      artifactType: artifactNode.artifactType,
    });
  }
  throw new Error(`no matching url for node ${node.__typename}`);
};

export const getUrl = ({
  meetingGroupId,
  meetingId,
  topicId,
  artifactId,
  artifactType,
  userId,
}: {
  userId?: number | string | undefined | null;
  meetingGroupId?: number | string | undefined | null;
  meetingId?: number | string | undefined | null;
  topicId?: number | string | undefined | null;
  artifactId?: number | string | undefined | null;
  artifactType?: ArtifactType | undefined | null;
}) => {
  if (userId) {
    return `/dashboard/user/${userId}`;
  }
  if (
    !meetingId &&
    !meetingGroupId &&
    !topicId &&
    !artifactId &&
    !artifactType
  ) {
    window.console.error(
      "Meeting url requires at least a meeting group id, meeting id or topic id."
    );
  }
  if (meetingId && !meetingGroupId) {
    window.console.error("Meeting url requires a meeting group id.");
  }
  let url = "";
  if (meetingGroupId) {
    url = `${url}/meeting/${meetingGroupId}`;
  }
  if (meetingId) {
    url = `${url}/${meetingId}`;
  }
  if (topicId) {
    url = `${url}/topic/${topicId}`;
  }
  if (artifactId) {
    if (!artifactType) {
      window.console.error("Artifact url requires an artifactType.");
    } else {
      url = `${url}/${artifactTypeUrl[artifactType]}/${artifactId}`;
    }
  }

  return url;
};

export const getUrlUsingTypename = (
  node: Pick<UserNode, "id"> & { __typename: string }
) => {
  if (node.__typename === "UserNode") {
    return `/dashboard/user/${node.id}`;
  }
  throw new Error("No matching typename");
};

export const isBackgroundLocation = (location: Location) => {
  return window.location.pathname === location.pathname;
};

export const toWithBackground = ({
  pathname,
  location,
}: {
  pathname: string | toObjectProps;
  location: TFLocation;
}) => {
  const background = location.state?.background
    ? location.state?.background
    : location;
  return {
    pathname,
    state: { background },
  } as toObjectProps;
};

export const getCommentUrl = ({
  topicId,
  artifactId,
  meetingId,
  meetingGroupId,
  artifactType,
  commentId,
}: {
  topicId?: number;
  artifactId?: number;
  meetingId?: number;
  meetingGroupId?: number;
  artifactType?: ArtifactType;
  commentId?: number;
}) => {
  if (topicId) {
    return `/topic/${topicId}/comment/${commentId}`;
  }
  if (artifactId) {
    return `${getUrl({
      artifactId: artifactId,
      meetingId: meetingId,
      meetingGroupId: meetingGroupId,
      artifactType: artifactType,
    })}/comment/${commentId}`;
  }
  return "/";
};

export const removeLineBreaks = (text: string) =>
  text.replace(/(\r\n|\n|\r)/gm, "");

export const getLocalstorageCommentForTopicId = (topicId: number) => {
  const localStorageComment = localStorage?.getItem("comment");
  if (localStorageComment) {
    const data = JSON.parse(localStorageComment);
    return data && data.topicId === topicId ? data.comment : "";
  }
  return "";
};

export const getLocalstorageCommentForArtifactId = (artifactId: number) => {
  const localStorageComment = localStorage?.getItem("comment");
  if (localStorageComment) {
    const data = JSON.parse(localStorageComment);
    return data && data.artifactId === artifactId ? data.comment : "";
  }
  return "";
};

export const parseStringToJSON = (str: any) => {
  // we use json5 in case the string is using single quote (non valid json)
  try {
    return json5.parse(str);
  } catch (e) {
    return "";
  }
};

export const clearLocalstorageComment = () => {
  localStorage.removeItem("comment");
};

export const getLocalStorage = (key: string, defaultValue: any) => {
  const value = localStorage?.getItem(key);
  return value ? JSON.parse(value) : defaultValue;
};

export const setLocalStorage = (key: string, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeTypename = (variables: unknown) => {
  const omitTypename = (key: string, value: unknown) =>
    key === "__typename" ? undefined : value;
  return JSON.parse(JSON.stringify(variables), omitTypename);
};

export const isCommandCopyEvent = (e: KeyboardEvent) =>
  (e.which === 67 || e.keyCode === 67 || e.key === "c") && e.metaKey;

export const isEnterEvent = (
  e: KeyboardEvent | KeyboardEvent<HTMLTextAreaElement>
) => e.which === 13 || e.keyCode === 13 || e.key === "Enter";

export const isSpaceEvent = (e: KeyboardEvent) =>
  e.which === 32 || e.keyCode === 32 || e.key === " ";

export const isArrowDownEvent = (e: KeyboardEvent) =>
  e.which === 40 || e.keyCode === 40 || e.key === "ArrowDown";

export const isArrowUpEvent = (e: KeyboardEvent) =>
  e.which === 38 || e.keyCode === 38 || e.key === "ArrowUp";

export const isArrowRightEvent = (e: KeyboardEvent) =>
  e.which === 39 || e.keyCode === 39 || e.key === "ArrowRight";

export const isArrowLeftEvent = (e: KeyboardEvent) =>
  e.which === 37 || e.keyCode === 37 || e.key === "ArrowLeft";

export const isShiftEvent = (e: KeyboardEvent) =>
  e.which === 67 || e.keyCode === 67 || e.key === "Shift";

export const isHomeEvent = (e: KeyboardEvent) =>
  e.which === 36 || e.keyCode === 36 || e.key === "Home";

export const isEndEvent = (e: KeyboardEvent) =>
  e.which === 35 || e.keyCode === 35 || e.key === "End";

export const isArrowEvent = (e: KeyboardEvent) =>
  isArrowLeftEvent(e) ||
  isArrowRightEvent(e) ||
  isArrowDownEvent(e) ||
  isArrowUpEvent(e);

export const isBackSpaceEvent = (e: KeyboardEvent) =>
  e.which === 8 || e.keyCode === 8 || e.key === "Backspace";

export const isEscapeEvent = (e: KeyboardEvent) =>
  e.which === 27 || e.keyCode === 27 || e.key === "Escape";

export const isCommandEnterEvent = (e: KeyboardEvent) =>
  isEnterEvent(e) && e.metaKey;

export const isCtrlSpaceEvent = (e: KeyboardEvent) =>
  isSpaceEvent(e) && e.ctrlKey && !e.metaKey && !e.shiftKey;

export const isShiftEnterEvent = (e: KeyboardEvent) =>
  isEnterEvent(e) && e.shiftKey;

export const getFormattedDatetime = (datetime: string) => {
  const date = removeCurrentYear(moment(datetime).format("ll"));
  const time = formatTime(moment(datetime).format("h:mma"));
  return `${date}, ${time}`;
};

export const formatMeetingDate = (meeting: {
  startDatetime?: string | null;
}) => {
  if (!meeting.startDatetime) {
    throw new Error("No meeting start datetime.");
  }
  return removeCurrentYear(moment(meeting.startDatetime).format("ll"));
};

export const formatMeetingTimes = (meeting: {
  startDatetime?: string | null;
  endDatetime?: string | null;
}) => {
  if (!meeting.startDatetime) return null;
  const meetingStartTime = moment(meeting.startDatetime).format("h:mma");
  const meetingEndTime = meeting.endDatetime
    ? moment(meeting.endDatetime).format("h:mma")
    : null;
  return `${formatTime(meetingStartTime)}${
    meetingEndTime && ` - ${formatTime(meetingEndTime)}`
  }`;
};

export const formatTime = (datetime: string) => {
  return replace(datetime, ":00", "");
};

export const removeCurrentYear = (datetime: string) => {
  return replace(datetime, `, ${moment().format("YYYY")}`, "");
};

export const getRelativeTime = (meeting: MeetingNode) => {
  const oneHourBeforeStartDatetime = moment(meeting.startDatetime).subtract(
    1,
    "hour"
  );
  if (moment().isBetween(oneHourBeforeStartDatetime, meeting.startDatetime)) {
    return moment().to(meeting.startDatetime);
  }
};

export const getAssetUrl = (path: string | URL) => {
  return new URL(path, import.meta.url).href;
};

export const matchApolloErrorMessage = (error: Error, matchStr: string) => {
  const errors = Array.isArray(error) ? error : [error];
  return !!errors.find(({ message }) =>
    String(message).toLowerCase().includes(matchStr.toLowerCase())
  );
};

export const isFeatureEnabledOnSomeOrganizations = (
  featureName: "userRelationships"
) => {
  return Object.values(window.featuresByOrganization).some(
    (feature) => feature[featureName]
  );
};

export const isFeatureEnabledOnOrganization = (
  featureName: "userRelationships",
  organizationId: number
) => {
  return window.featuresByOrganization[organizationId][featureName];
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export const errorMatches = (error: Error, str: string) => {
  return String(error).includes(str);
};

export const isMobileView = () => {
  const mobileElement = document.querySelector(
    "#js-mobile-navbar-toggle-button"
  ) as HTMLElement;
  if (mobileElement) {
    return mobileElement.offsetParent !== null;
  }
  return false;
};

export const getArtifactBgColor = (artifact: ArtifactInterface) => {
  if (artifact.artifactType === artifactType.actionItem) {
    return "bg-violet-50";
  }
  if (artifact.artifactType === artifactType.decision) {
    return "bg-emerald-50";
  }
  if (artifact.artifactType === artifactType.goal) {
    return "bg-blue-50";
  }
  return "bg-gray-50";
};

export type TargetValueProgressData = {
  startValue: number;
  currentValue: number;
  targetValue: number;
};

export const getProgressFromStartCurrentTargetValues = ({
  startValue,
  currentValue,
  targetValue,
}: TargetValueProgressData) => {
  return currentValue === startValue
    ? 0
    : startValue === targetValue
    ? 100
    : currentValue === targetValue
    ? 100
    : round(
        ((currentValue - startValue) / (targetValue - startValue)) * 100,
        2
      );
};

export const getStartValueForProgressType = (node: {
  progressType?: GoalProgressType | null;
  startValue: number;
}) =>
  node.progressType === GoalProgressType.Boolean
    ? progressTypeBooleanStartValue
    : node.startValue;

export const getTargetValueForProgressType = (node: {
  progressType?: GoalProgressType | null;
  targetValue: number;
}) =>
  node.progressType === GoalProgressType.Boolean
    ? progressTypeBooleanTargetValue
    : node.targetValue;

export const getProgressLabel = (
  {
    progressType,
    startValue,
    currentValue,
    targetValue,
  }: {
    progressType: GoalProgressType;
    startValue: number;
    currentValue: number;
    targetValue: number;
  },
  options = {
    shortVersion: false,
  }
) => {
  const progress = getProgressFromStartCurrentTargetValues({
    startValue,
    currentValue,
    targetValue,
  });
  const progressLabel = `${numeral(progress).format("0,0[.]0[0]")}%`;
  return progressType === GoalProgressType.Boolean
    ? progress === progressTypeBooleanTargetValue
      ? "Completed"
      : "Incomplete"
    : progressType === GoalProgressType.Numeric
    ? `${numeral(currentValue).format("0[.]00a")}/${numeral(targetValue).format(
        "0[.]00a"
      )}${options.shortVersion ? "" : ` (${progressLabel})`}`
    : progressType === GoalProgressType.Currency
    ? `${numeral(currentValue).format("$0[.]00a")}/${numeral(
        targetValue
      ).format("$0[.]00a")}${options.shortVersion ? "" : ` (${progressLabel})`}`
    : progressLabel; // by default, we display progress
};

export const justNowFormatter: Formatter = (
  value,
  unit,
  suffix,
  millis,
  defaultFormatter
) => {
  if (unit === "second") {
    return "just now";
  } else if (defaultFormatter) {
    return defaultFormatter(value, unit, suffix, millis);
  }
};

export const showConfetti = () => {
  if (window.Cypress) return;
  const canvas = document.createElement("canvas");
  canvas.setAttribute("id", "confetti-canvas");
  canvas.classList.add("absolute", "inset-0", "z-tooltip", "h-full", "w-full");
  document.body.appendChild(canvas);
  const jsConfetti = new JSConfetti({ canvas });
  return jsConfetti.addConfetti().then(() => {
    canvas.remove();
    document.getElementById("confetti-canvas")?.remove();
  });
};

export const simulateMouseClick = (element: Element) => {
  const events = ["mousedown", "click", "mouseup"];
  events.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
};

export const getUrlParam = (location: Location<unknown>, param: string) => {
  const urlQueries = new URLSearchParams(location.search);
  return urlQueries.get(param);
};

export const assertEdgesNonNull = <T extends Node>(
  obj: { edges: readonly ({ node?: T | null } | null)[] } | null | undefined
) => {
  const nonNullEdges = assertNonNull(obj?.edges);
  const nodes = nonNullEdges.map((edge) => assertNonNull(edge?.node));
  return nodes as T[];
};

export const assertEdgesNonNullWithStringId = <
  T extends { id?: string | null }
>(
  obj: { edges: readonly ({ node?: T | null } | null)[] } | null | undefined
) => {
  const nonNullEdges = assertNonNull(obj?.edges);
  const nodes = nonNullEdges.map((edge) => assertNonNull(edge?.node));
  return nodes as T[];
};

export const assertNonNull = <T>(obj: T | null | undefined): T => {
  if (obj === null || obj === undefined) {
    throw new Error("Non-null expected");
  }
  return obj;
};

export function isDateRangeEnum(value: string): value is DateRangeEnum {
  return Object.values<string>(DateRangeEnum).includes(value);
}

export const dateRangeToDateArray = ({
  range,
  format = "YYYY-MM-DD",
  quarterStartMonth,
}: {
  range: string[] | DateRangeEnum | PastOnlyDateRangeEnum;
  format?: string;
  quarterStartMonth?: number;
}): string[] => {
  if (quarterStartMonth === undefined) {
    const currentOrganization = currentOrganizationVar();
    quarterStartMonth = currentOrganization.quarterStartMonth;
  }
  if (Array.isArray(range)) {
    if (
      range.length === 2 &&
      moment(range[0], format).isBefore(moment(range[1], format))
    )
      return range;
    throw new Error("Invalid date range");
  }
  if (
    typeof range === "string" &&
    (Object.values<string>(DateRangeEnum).includes(range) ||
      Object.values<string>(PastOnlyDateRangeEnum).includes(range))
  ) {
    if (
      // Quarters
      [
        DateRangeEnum.thisQuarter,
        DateRangeEnum.nextQuarter,
        DateRangeEnum.previousQuarter,
        PastOnlyDateRangeEnum.previousQuarter,
      ].includes(range)
    ) {
      const currentMonth = moment().month();
      const currentMonthOffset = currentMonth % 3;
      const quarterStartMonthOffset = (quarterStartMonth - 1) % 3;
      const diff =
        currentMonthOffset < quarterStartMonthOffset
          ? quarterStartMonthOffset - 3
          : quarterStartMonthOffset;
      const startOfQuarter = moment().startOf("quarter").add(diff, "months");

      if (range === DateRangeEnum.thisQuarter) {
        return [
          startOfQuarter.clone().format(format),
          startOfQuarter.clone().add(2, "months").endOf("month").format(format),
        ];
      }
      if (range === DateRangeEnum.nextQuarter) {
        return [
          startOfQuarter
            .clone()
            .add(3, "months")
            .startOf("month")
            .format(format),
          startOfQuarter.clone().add(5, "months").endOf("month").format(format),
        ];
      }
      if (
        range === DateRangeEnum.previousQuarter ||
        range === PastOnlyDateRangeEnum.previousQuarter
      ) {
        return [
          startOfQuarter
            .clone()
            .subtract(3, "months")
            .startOf("month")
            .format(format),
          startOfQuarter
            .clone()
            .subtract(1, "day")
            .endOf("month")
            .format(format),
        ];
      }
    }
    if (range === DateRangeEnum.thisMonth) {
      return [
        moment().startOf("month").format(format),
        moment().endOf("month").format(format),
      ];
    }
    if (range === DateRangeEnum.nextMonth) {
      return [
        moment().add(1, "month").startOf("month").format(format),
        moment().add(1, "month").endOf("month").format(format),
      ];
    }
    if (
      range === DateRangeEnum.previousMonth ||
      range === PastOnlyDateRangeEnum.previousMonth
    ) {
      return [
        moment().subtract(1, "month").startOf("month").format(format),
        moment().subtract(1, "month").endOf("month").format(format),
      ];
    }
    if (range === PastOnlyDateRangeEnum.previousYear) {
      return [
        moment().subtract(1, "year").startOf("year").format(format),
        moment().subtract(1, "year").endOf("year").format(format),
      ];
    }
  }
  throw new Error("Date range not found");
};

export const getCycleDates = (): string[] => {
  const currentOrganization = currentOrganizationVar();
  return dateRangeToDateArray({
    range: DateRangeEnum.thisQuarter,
    quarterStartMonth: currentOrganization.quarterStartMonth,
  });
};

export const getFeedbackSentToString = ({
  recipientsCanView,
  recipientsManagersCanView,
  adminsCanView,
}: {
  recipientsCanView: boolean;
  recipientsManagersCanView: boolean;
  adminsCanView: boolean;
}) => {
  const options = compact([
    recipientsCanView && "subjects",
    recipientsManagersCanView && `subjects' managers`,
    adminsCanView && "admins",
  ]);
  return options.length > 0 ? joinStringWithCommaAnd(options) : "None";
};

export const getAssessmentTypeString = (assessmentType: AssessmentType) => {
  if (assessmentType === AssessmentType.Manager) return "Manager effectiveness";
  if (assessmentType === AssessmentType.Peer) return "Peer";
  return "Performance";
};
