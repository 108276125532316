import { useMutation } from "@apollo/client";
import { ReactNode } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import addTopicToMeetingCache from "@cache/add-topic-to-meeting";
import removeTopicFromMeetingCache from "@cache/remove-topic-from-meeting";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import reorderTopicMutation from "../graphql/reorder-topic-mutation";

const DragAndDrop = ({
  children,
  meetingsGroupedById,
}: {
  children: ReactNode;
  meetingsGroupedById: {
    [key: number]: MeetingViewMeetingNodeNewPageFragmentFragment;
  };
}) => {
  const [reorderTopic] = useMutation(reorderTopicMutation, {
    update(cache, { data }) {
      const topicCacheId = cache.identify(data.reorderTopic.topic);
      if (topicCacheId) {
        removeTopicFromMeetingCache({
          topic: data.reorderTopic.topic,
          meeting: data.reorderTopic.meeting,
        });
        addTopicToMeetingCache({
          topic: data.reorderTopic.topic,
          meeting: data.reorderTopic.meeting,
          afterTopic: data.reorderTopic.afterTopic,
          beforeTopic: data.reorderTopic.beforeTopic,
        });
      }
    },
  });

  const handleDragEnd = ({
    reason,
    draggableId,
    destination,
    source,
  }: {
    reason: string;
    draggableId: string;
    destination: any;
    source: any;
  }) => {
    if (reason === "DROP") {
      // get topic and topic groups
      const sourceMeetingId = parseInt(
        source.droppableId.replace("meeting-", "")
      );
      const topicId = parseInt(draggableId.split("-")[1]);
      const topicEdge =
        meetingsGroupedById[sourceMeetingId]?.topics?.edges[source.index];
      const topic = topicEdge?.node;
      const sourceMeeting = meetingsGroupedById[sourceMeetingId];

      // Same topic group
      if (source.droppableId === destination.droppableId) {
        if (source.index === destination.index) {
          return;
        }

        // reorder topic in collection
        const newMeetingEdges = Array.from(sourceMeeting?.topics?.edges || []);
        newMeetingEdges.splice(source.index, 1);
        if (topicEdge) newMeetingEdges.splice(destination.index, 0, topicEdge);
        const newIndex = newMeetingEdges.findIndex(
          (edge: any) => edge?.node?.id === topic?.id
        );

        // determine the after topic and before topic based on index.
        const afterTopic =
          newIndex === 0 ? null : newMeetingEdges[newIndex - 1]?.node;
        const beforeTopic = newIndex === 0 ? newMeetingEdges[1]?.node : null;

        reorderTopic({
          variables: {
            afterTopicId: afterTopic?.id,
            beforeTopicId: beforeTopic?.id,
            topicId,
          },
          optimisticResponse: {
            reorderTopic: {
              meeting: {
                ...sourceMeeting,
              },
              //   topics: {
              //     edges: newMeetingEdges,
              //   },
              // },
              afterTopic,
              beforeTopic,
              topic,
              __typename: "ReorderTopicMutation",
            },
          },
          onError: onNotificationErrorHandler(),
        });
      } else {
        throw new Error("Cannot move topic to another meeting.");
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>{children}</DragDropContext>
  );
};

export default DragAndDrop;
