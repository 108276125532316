import { gql } from "@apollo/client";

export default gql`
  query getDocumentsPage($meetingGroupId: Int, $flowId: Int, $after: String) {
    topics(
      hasDocuments: true
      orderBy: "-meeting__start_datetime"
      meetingInMeetingGroupId: $meetingGroupId
      meetingInFlowId: $flowId
      first: 20
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          state
          artifacts(artifactType: document) {
            edges {
              node {
                canDelete {
                  permission
                }
                id
                artifactType
                title
              }
            }
          }
          meeting {
            id
            meetingGroupId
            startDatetime
          }
        }
      }
    }
  }
`;
