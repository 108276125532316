import { useMutation } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { GoalState } from "types/graphql-schema";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import getDashboardGoalsQuery from "@apps/dashboard/graphql/get-dashboard-goals-query";
import getMeetingGoalsQuery from "@apps/meeting/graphql/get-meeting-goals-query";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { goalStateLabel } from "@helpers/constants";
import { classNames, listBoxButtonClassName } from "@helpers/css";

import updateArtifactMutation from "../graphql/update-artifact-mutation";

const GoalStateComponent = ({
  artifact,
  className = "",
  iconSize = "4",
}: {
  artifact: {
    id: number;
    state: GoalState;
    canUpdate: { permission: boolean };
  };
  className?: string;
  iconSize?: string;
}) => {
  const [updateArtifact] = useMutation(updateArtifactMutation);

  const handleChangeState = ({ value: newState }: SelectOption<GoalState>) => {
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { state: newState },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            state: newState,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      refetchQueries: [
        getDashboardGoalsQuery,
        getMeetingGoalsQuery,
        getArtifactActivitiesQuery,
      ],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <Select<GoalState>
      disabled={!artifact.canUpdate.permission}
      options={[
        {
          value: GoalState.Draft,
          label: goalStateLabel[GoalState.Draft],
        },
        {
          value: GoalState.Open,
          label: goalStateLabel[GoalState.Open],
        },
        {
          value: GoalState.Closed,
          label: goalStateLabel[GoalState.Closed],
        },
      ]}
      value={artifact.state}
      onChange={handleChangeState}
    >
      {({ selected, setReferenceElement, disabled }) => (
        <Listbox.Button
          className={classNames(
            listBoxButtonClassName({ disabled }),
            className
          )}
          aria-label="Goal state dropdown button"
          ref={setReferenceElement}
        >
          <div className="flex items-center gap-1.5">{selected?.label}</div>
          {!disabled && (
            <SelectorIcon
              className={`h-${iconSize} w-${iconSize} text-gray-400 pointer-events-none`}
              aria-hidden="true"
            />
          )}
        </Listbox.Button>
      )}
    </Select>
  );
};

export default GoalStateComponent;
