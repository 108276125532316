import { useMutation } from "@apollo/client";
import { DocumentDuplicateIcon } from "@heroicons/react/outline";
import { Menu } from "@szhsin/react-menu";
import { Editor } from "@tiptap/core";
import { useRef } from "react";
import { MdOutlineSnooze } from "react-icons/md";
import { TbToggleLeft, TbToggleRightFilled } from "react-icons/tb";
import {
  MeetingViewMeetingNodeFragmentFragment,
  TopicNodeFragmentFragment,
} from "types/graphql-schema";

import AddToMeetingsSubMenu from "@apps/topic-dropdown-menu/components/add-to-meetings";
import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, parseStringToJSON } from "@helpers/helpers";

import toggleTopicIndividualNoteVisibilityMutation from "../graphql/toggle-topic-individual-note-visibility-mutation";
import { getOptimisticResponseWhenTogglingIndividualNotes } from "../helpers";

export const defaultNotesLocalStorageKey = "default-notes";

const TopicToolbar = ({
  topic,
  meeting,
}: {
  topic: TopicNodeFragmentFragment;
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  // Render
  const containerRef = useRef<HTMLDivElement | null>(null);
  const previousNotes =
    topic?.previousTopic?.discussionNotes &&
    !isEmptyValue(parseStringToJSON(topic.previousTopic.discussionNotes))
      ? parseStringToJSON(topic.previousTopic.discussionNotes)
      : null;

  const [
    toggleIndividualNoteVisibility,
    { loading: loadingToggleIndividualNotes },
  ] = useMutation(toggleTopicIndividualNoteVisibilityMutation);

  const currentUser = currentUserVar();
  const individualNotes = topic.individualNotes
    ? assertEdgesNonNull(topic.individualNotes)
    : [];

  const currentUserNote = individualNotes.find(
    (node) => node.creator.id === currentUser.id
  );
  const currentUserNoteEnabled = currentUserNote?.enabled || false;

  const handleToggleIndividualNotes = () => {
    const enabled = !currentUserNoteEnabled;
    const optimisticResponse = getOptimisticResponseWhenTogglingIndividualNotes(
      meeting,
      topic,
      enabled
    );
    toggleIndividualNoteVisibility({
      variables: {
        topicIds: [topic.id],
        enabled,
      },
      optimisticResponse,
      onError: onNotificationErrorHandler(),
    });
  };

  const handleCopyPreviousNotes = () => {
    if (containerRef.current) {
      const editorContainer = containerRef.current
        ?.closest("li")
        ?.querySelector(
          '[aria-label="Meeting topic shared notes"] .js-topic-discussion-notes-input'
        ) as Element & { editor?: Editor };
      if (
        previousNotes &&
        topic?.previousTopic?.discussionNotes &&
        editorContainer.editor &&
        (editorContainer.editor.isEmpty ||
          (!editorContainer.editor.isEmpty &&
            window.confirm(
              "Confirm you want to replace your current notes with the previous meeting notes?"
            )))
      ) {
        editorContainer.editor
          .chain()
          .setContent(parseStringToJSON(topic.previousTopic.discussionNotes))
          .run();
      }
    }
  };

  const buttonClassName =
    "text-xs tracking-tighter @xl/topic-toolbar:tracking-tight text-gray-400 flex gap-0.5 @xl/topic-toolbar:gap-1 items-center px-1.5 py-1 hover:bg-gray-100 hover:text-gray-600 rounded-md ";
  return (
    <div ref={containerRef} className={classNames("mr-3 ml-meetingLeftMargin")}>
      <div className="flex gap-2 @container/topic-toolbar -ml-1.5">
        {topic.canUpdate.permission && !meeting.meetingGroup?.isFormalOneonone && (
          <div className="flex items-center">
            <Tooltip text="Toggle my notes" delay={0}>
              <button
                className={buttonClassName}
                onClick={handleToggleIndividualNotes}
              >
                {loadingToggleIndividualNotes ? (
                  <Loading mini size="4" />
                ) : topic.includesIndividualNotesForCurrentUser ? (
                  <TbToggleRightFilled className="h-4 w-4" />
                ) : (
                  <TbToggleLeft className="h-4 w-4" />
                )}
                My notes
              </button>
            </Tooltip>
          </div>
        )}

        {meeting.isRecurring && !topic.linkedTemplateTopic?.id && (
          <div>
            <Menu
              className="z-dropdown"
              align="end"
              portal
              transition
              aria-label="Topic dropdown menu list"
              menuButton={
                <span>
                  <Tooltip text="Copy this topic in the next meeting">
                    <button className={buttonClassName}>
                      <MdOutlineSnooze className="h-4 w-4" />
                      <span className="hidden @lg/topic-toolbar:inline">
                        Snooze
                      </span>
                    </button>
                  </Tooltip>
                </span>
              }
            >
              <AddToMeetingsSubMenu
                withSubMenu={false}
                meetingGroup={meeting.meetingGroup}
                topic={topic}
                excludeIds={[meeting.id]}
              />
            </Menu>
          </div>
        )}
        {meeting.isRecurring && previousNotes && (
          <Tooltip text="Insert notes from topic in previous meeting">
            <button
              onClick={handleCopyPreviousNotes}
              className={buttonClassName}
            >
              <DocumentDuplicateIcon className="h-4 w-4" />
              <span className="hidden @lg/topic-toolbar:inline">
                Insert previous notes
              </span>
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default TopicToolbar;
