import { gql } from "@apollo/client";

export const MeetingViewMeetingGroupFragment = gql`
  fragment MeetingViewMeetingGroupFragment on MeetingGroupNode {
    id
    title
    allowAdminVisibility
    allowOrgVisibility
    allowManagementTreeVisibility
    isFormalOneonone
    transcribeMeetingsByDefault
    addTranscriptionBotToMeetingsByDefault
    canUpdate {
      permission
    }
    facilitator {
      id
      name
      avatar
    }
    organization {
      id
      name
    }
    syncCredentials {
      id
      hasCalendarWriteCredentials
      provider
    }
    hasTemplate
    topicTemplate {
      id
      title
      organization {
        id
      }
      globalTemplate
      publicTemplate
      meetingTemplate
      finalizePermissions
    }
  }
`;

export default gql`
  ${MeetingViewMeetingGroupFragment}
  query getMeetingGroupQuery(
    $meetingGroupId: Int!
    $hasMeetingGroup: Boolean!
  ) {
    meetingGroup(meetingGroupId: $meetingGroupId)
      @include(if: $hasMeetingGroup) {
      ...MeetingViewMeetingGroupFragment
    }
  }
`;
