import { gql } from "@apollo/client";

import { AlignmentGoalFragment } from "./get-top-level-goals-query";

export default gql`
  ${AlignmentGoalFragment}
  query getAlignedChildGoalsQuery(
    $goalId: Int!
    $goalStates: [GoalState]
    $goalDueBetweenDates: [Date]
  ) {
    artifact(artifactId: $goalId) {
      id
      ... on GoalArtifactNode {
        childGoals(
          goalStates: $goalStates
          goalDueBetweenDates: $goalDueBetweenDates
        ) {
          edges {
            node {
              id
              ...AlignmentGoalFragment
            }
          }
        }
      }
    }
  }
`;
