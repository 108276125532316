import { gql } from "@apollo/client";

export default gql`
  query getActionItemsPage($meetingGroupId: Int, $flowId: Int, $after: String) {
    topics(
      hasActionItems: true
      orderBy: "-meeting__start_datetime"
      meetingInMeetingGroupId: $meetingGroupId
      meetingInFlowId: $flowId
      first: 20
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          state
          artifacts(artifactType: action_item) {
            edges {
              node {
                canDelete {
                  permission
                }
                id
                artifactType
                title
                ... on ActionItemArtifactNode {
                  isComplete
                  actionItemState
                  dueDate
                  assignee {
                    id
                    avatar
                    name
                  }
                }
              }
            }
          }
          meeting {
            id
            meetingGroupId
            startDatetime
          }
        }
      }
    }
  }
`;
