import { useQuery } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { compact } from "lodash";
import { Fragment } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  GetOrganizationSettingsQuery,
  GetOrganizationSettingsQueryVariables,
} from "types/graphql-schema";

import Integrations from "@apps/org-settings/components/integrations";
import Notifications from "@apps/org-settings/components/notifications";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Link from "@components/link/link";
import Loading from "@components/loading/loading";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { role } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import Billing from "./components/billing";
import CoreValues from "./components/core-values";
import DirectorySync from "./components/directory-sync";
import Members from "./components/members";
import Settings from "./components/settings";
import Teams from "./components/teams";
import getOrganizationSettingsQuery from "./graphql/get-organization-settings-query";

type Props = {
  organizationId: number;
};

const Nav: React.FC<Props> = ({ organizationId }) => {
  const label = useLabel();
  const tabs = compact([
    { name: "Settings", id: "settings" },
    { name: "Members", id: "members" },
    {
      name: label("team", { capitalize: true, pluralize: true }),
      id: "teams",
    },
    { name: "Core Values", id: "core-values" },
    { name: "Billing", id: "billing" },
    { name: "Directory Sync", id: "directory-sync" },
    // slack is the only integration right now
    {
      name: "Integrations",
      id: "integrations",
    },
    {
      name: "Notifications",
      id: "notifications",
    },
  ]);

  const { navId } = useParams<{ navId: string }>();
  const displayedTabId = navId || tabs[0].id;
  return (
    <div className="mb-6">
      <nav className="-mb-px flex gap-6 overflow-auto" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={`/settings/organization/${organizationId}/${tab.id}`}
            className={classNames(
              tab.id === displayedTabId
                ? "border-blue-700 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
            )}
            aria-current={tab.id === displayedTabId ? "page" : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

const OrgSettings = () => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  useDocumentTitle(`${label("organization", { capitalize: true })} settings`);
  const { path } = useRouteMatch();
  const { organizationId: organizationIdParam } = useParams<{
    organizationId: string;
  }>();
  const organizationId =
    parseInt(organizationIdParam) || currentOrganization.id;
  const history = useHistory();

  // HOOKS
  const { data, loading } = useQuery<
    GetOrganizationSettingsQuery,
    GetOrganizationSettingsQueryVariables
  >(getOrganizationSettingsQuery, { onError: onNotificationErrorHandler() });
  const organizations = data?.me?.organizations
    ? assertEdgesNonNull(data.me.organizations)
    : [];
  const selectedOrganization = organizations.find(
    ({ id }) => id === organizationId
  );
  if (
    selectedOrganization?.userMembership &&
    selectedOrganization.userMembership.role !== role.administrator
  ) {
    return <Redirect to="/" />;
  }

  const handleChangeOrganization = ({ id }: { id: number }) => {
    history.push(`/settings/organization/${id}/settings`);
  };

  // RENDER
  return (
    <div aria-label="Dashboard" className="flex flex-col flex-1">
      <div className="flex flex-wrap items-center h-14 px-4 sm:px-6 border-b bg-gray-50">
        <div className="mb-2 sm:mb-0 text-2xl font-medium tracking-tight mr-8 flex items-center">
          <span>Organization Settings</span>
          <span className="mx-2 text-gray-400">/</span>
          {!loading && (
            <Listbox
              value={selectedOrganization}
              onChange={handleChangeOrganization}
              disabled={loading}
            >
              <div className="relative">
                <Listbox.Button className="flex items-center tracking-tight">
                  <span aria-label="Organization switch">
                    {selectedOrganization?.name ?? "Unknown organization"}
                  </span>
                  <span className="ml-2 flex items-center pointer-events-none">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-dropdown mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {organizations.map((organization) => (
                      <Listbox.Option
                        key={organization.id}
                        className={({ active }) =>
                          classNames(
                            active ? "text-white bg-blue-600" : "text-gray-900",
                            "w-64 cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={organization}
                        data-testid={`organization-dropdown-option-${organization.id}`}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex flex-1 items-center">
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "flex-1 ml-3 block truncate"
                                )}
                              >
                                {organization.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-blue-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                    {loading && (
                      <Listbox.Option
                        key="loading"
                        className="text-gray-500 w-64 cursor-default select-none relative py-2 pl-3 pr-9 flex items-center"
                        value="loading"
                        disabled
                      >
                        <Loading mini size="6" />
                        <span className="ml-3">loading...</span>
                      </Listbox.Option>
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          )}
        </div>
        {loading && (
          <div className="ml-2">
            <Loading mini size="5" />
          </div>
        )}
      </div>
      {selectedOrganization && (
        <div className="p-6">
          <div className="mb-6">
            <Switch>
              <Route exact path={[`${path}/:navId`, path]}>
                <Nav organizationId={selectedOrganization.id} />
              </Route>
            </Switch>
          </div>

          <div className="p-6 bg-white shadow sm:rounded-md text-gray-800">
            <Switch>
              <Route exact path={[`${path}/settings`, `${path}`]}>
                <Settings organization={selectedOrganization} />
              </Route>
              <Route exact path={`${path}/members`}>
                <Members organization={selectedOrganization} />
              </Route>
              <Route exact path={`${path}/teams`}>
                <Teams organization={selectedOrganization} />
              </Route>
              <Route exact path={`${path}/core-values`}>
                <CoreValues organizationId={selectedOrganization.id} />
              </Route>
              <Route exact path={`${path}/billing`}>
                <Billing organization={selectedOrganization} />
              </Route>
              <Route exact path={`${path}/directory-sync`}>
                <DirectorySync organization={selectedOrganization} />
              </Route>
              <Route exact path={`${path}/integrations`}>
                <Integrations organization={selectedOrganization} />
              </Route>
              <Route exact path={`${path}/notifications`}>
                <Notifications organization={selectedOrganization} />
              </Route>
            </Switch>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrgSettings;
