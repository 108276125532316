import { ReactNode } from "react";
import {
  MeetingViewMeetingNodeFragmentFragment,
  TopicNode,
} from "types/graphql-schema";

import Loading from "@components/loading/loading";

import SuggestedTopic from "./suggested-topic";

const SuggestedTopicGroup = ({
  topics,
  meeting,
  title,
  children,
  groupActionText = null,
  groupActionLoading = false,
  onClickGroupAction,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
  topics: Pick<TopicNode, "id" | "title" | "discussionNotes" | "isMandatory">[];
  title: string;
  children?: ReactNode[] | null;
  groupActionText?: string | null;
  groupActionLoading?: boolean;
  onClickGroupAction?: () => void;
}) => {
  const handleAddAllMeetingTopics = () => {
    if (onClickGroupAction) {
      onClickGroupAction();
    }
  };

  return (
    <div className="w-full flex flex-col border rounded overflow-hidden divide-y">
      <div className="py-2 px-3 flex items-center justify-between gap-4 bg-gray-50">
        <div className="font-medium text-sm">{title}</div>
        {groupActionText && onClickGroupAction && (
          <div className="flex items-center gap-1.5">
            {groupActionLoading && <Loading size={4} />}
            <button
              onClick={handleAddAllMeetingTopics}
              className="text-xs tracking-tight flex items-center gap-1 text-blue-link hover:underline"
            >
              {groupActionText}
            </button>
          </div>
        )}
      </div>
      {topics.map((topic) => (
        <div key={topic.id} className="w-full flex">
          <SuggestedTopic meeting={meeting} topic={topic} />
        </div>
      ))}
      {children}
    </div>
  );
};

export default SuggestedTopicGroup;
