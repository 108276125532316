import { useMutation } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import { ChangeEventHandler, useEffect, useState } from "react";
import { GetAccountQuery } from "types/graphql-schema";

import Heading from "@components/heading/heading";
import Select from "@components/select/select";
import SettingsLayout from "@components/settings-layout/settings-layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import updateUserMutation from "../graphql/update-user-mutation";

const emailSettings = [
  {
    name: "Email notifications",
    value: "notification_emails",
    description: "Email me updates as things happen.",
  },
  {
    name: "No email notifications",
    value: "no_notification_emails",
    description: "No email notifications will be sent to you.",
  },
];

const meetingSummaryEmailSettings = [
  {
    name: "Never",
    value: "none",
    description: "Never send meeting summary emails.",
  },
  {
    name: "When not attending",
    value: "when_declined",
    description:
      "Send meeting summary emails if you decline the calendar event.",
  },
  {
    name: "Always",
    value: "always",
    description: "Always send a summary email after a meeting.",
  },
];

const meetingPreparationEmailSettings = [
  {
    name: "Never",
    value: "never",
    description: "Never send meeting agenda reminders.",
  },
  {
    name: "Missing agenda as organizer",
    value: "when_organizer",
    description:
      "Send agenda reminders when there is an agenda, or if you are the organizer of a meeting with no agenda",
  },
  {
    name: "When agenda exists",
    value: "when_agenda_exists",
    description: "Send agenda reminders when there is an agenda.",
  },
  {
    name: "Always",
    value: "always",
    description: "Always send agenda reminders.",
  },
];

const Email = ({ me }: { me: NonNullable<GetAccountQuery["me"]> }) => {
  const [updateUser] = useMutation(updateUserMutation);
  const [emailPreference, setEmailPreference] = useState(me.emailPreference);
  const [meetingSummaryEmailPreference, setMeetingSummaryEmailPreference] =
    useState(me.meetingSummaryEmailPreference);
  const [
    meetingPreparationEmailPreference,
    setMeetingPreparationEmailPreference,
  ] = useState(me.meetingPreparationEmailPreference);
  const [enableDailySummaryEmails, setEnableDailySummaryEmails] = useState(
    me.enableDailySummaryEmails
  );
  const [enableDailyCommitmentEmails, setEnableDailyCommitmentEmails] =
    useState(me.enableDailyCommitmentEmails);
  const [enableDraftReminderEmails, setEnableDraftReminderEmails] = useState(
    me.enableDraftReminderEmails
  );
  const [primaryEmail, setPrimaryEmail] = useState(me.email);

  const handleChangeEmailSetting = (value: any) => {
    setEmailPreference(value);
    updateUser({
      variables: { userId: me.id, emailPreference: value },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeMeetingSummaryEmailSetting = (value: any) => {
    setMeetingSummaryEmailPreference(value);
    updateUser({
      variables: { userId: me.id, meetingSummaryEmailPreference: value },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeAgendaEmailSetting = (value: any) => {
    setMeetingPreparationEmailPreference(value);
    updateUser({
      variables: { userId: me.id, meetingPreparationEmailPreference: value },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeDailySummaryEmailSetting: ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setEnableDailySummaryEmails(event.target.checked);
    updateUser({
      variables: {
        userId: me.id,
        enableDailySummaryEmails: event.target.checked,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeDailyCommitmentEmailSetting: ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setEnableDailyCommitmentEmails(event.target.checked);
    updateUser({
      variables: {
        userId: me.id,
        enableDailyCommitmentEmails: event.target.checked,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeDraftReminderEmailSetting: ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setEnableDraftReminderEmails(event.target.checked);
    updateUser({
      variables: {
        userId: me.id,
        enableDraftReminderEmails: event.target.checked,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangePrimaryEmail = ({ value }: { value: string }) => {
    setPrimaryEmail(value);
    updateUser({
      variables: {
        userId: me.id,
        primaryEmailAddress: value,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  useEffect(() => {
    setEmailPreference(me.emailPreference);
    setMeetingPreparationEmailPreference(me.meetingPreparationEmailPreference);
    setEnableDailySummaryEmails(me.enableDailySummaryEmails);
    setEnableDailyCommitmentEmails(me.enableDailyCommitmentEmails);
    setMeetingSummaryEmailPreference(me.meetingSummaryEmailPreference);
    setEnableDraftReminderEmails(me.enableDraftReminderEmails);
    setPrimaryEmail(me.email);
  }, [me]);

  return (
    <div>
      <Heading small title="Notifications" />

      <SettingsLayout.Container>
        {me.emails.length > 1 && (
          <SettingsLayout.Row>
            <SettingsLayout.Left>
              Send email notifications to
            </SettingsLayout.Left>
            <SettingsLayout.Right>
              <Select
                width="96"
                label=""
                onChange={handleChangePrimaryEmail}
                value={primaryEmail}
                options={me.emails.map((email) => ({
                  value: email,
                  label: email,
                }))}
              />
            </SettingsLayout.Right>
          </SettingsLayout.Row>
        )}

        <SettingsLayout.Row>
          <SettingsLayout.Left>Event notifications</SettingsLayout.Left>
          <SettingsLayout.Right>
            <RadioGroup
              value={emailPreference}
              onChange={handleChangeEmailSetting}
            >
              <RadioGroup.Label className="sr-only">
                Email notification setting
              </RadioGroup.Label>
              <div className="bg-white rounded-md -space-y-px">
                {emailSettings.map((setting, settingIdx) => (
                  <RadioGroup.Option
                    key={setting.name}
                    value={setting.value}
                    className={({ checked }) =>
                      classNames(
                        settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        settingIdx === emailSettings.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "bg-indigo-50 border-indigo-200"
                          : "border-gray-200",
                        "relative border p-4 flex cursor-pointer focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "bg-indigo-600 border-transparent"
                              : "bg-white border-gray-300",
                            active
                              ? "ring-2 ring-offset-2 ring-indigo-500"
                              : "",
                            "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {setting.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {setting.description}
                          </RadioGroup.Description>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Daily commitments</SettingsLayout.Left>
          <SettingsLayout.Right className="text-sm flex items-center gap-2">
            <input
              type="checkbox"
              checked={!!enableDailyCommitmentEmails}
              onChange={handleChangeDailyCommitmentEmailSetting}
            />{" "}
            Email me a preview of my day at 8am
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Daily summary</SettingsLayout.Left>
          <SettingsLayout.Right className="text-sm flex items-center gap-2">
            <input
              type="checkbox"
              checked={!!enableDailySummaryEmails}
              onChange={handleChangeDailySummaryEmailSetting}
            />{" "}
            Email me a summary of my day at 5pm
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Agenda reminders</SettingsLayout.Left>
          <SettingsLayout.Right className="text-sm flex items-center gap-2">
            <RadioGroup
              value={meetingPreparationEmailPreference}
              onChange={handleChangeAgendaEmailSetting}
            >
              <RadioGroup.Label className="sr-only">
                Meeting Agenda Emails
              </RadioGroup.Label>
              <div className="bg-white rounded-md -space-y-px">
                {meetingPreparationEmailSettings.map((setting, settingIdx) => (
                  <RadioGroup.Option
                    key={setting.name}
                    value={setting.value}
                    className={({ checked }) =>
                      classNames(
                        settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        settingIdx === emailSettings.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "bg-indigo-50 border-indigo-200"
                          : "border-gray-200",
                        "relative border p-4 flex cursor-pointer focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "bg-indigo-600 border-transparent"
                              : "bg-white border-gray-300",
                            active
                              ? "ring-2 ring-offset-2 ring-indigo-500"
                              : "",
                            "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {setting.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {setting.description}
                          </RadioGroup.Description>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Draft reminders</SettingsLayout.Left>
          <SettingsLayout.Right className="text-sm flex items-center gap-2">
            <input
              type="checkbox"
              checked={!!enableDraftReminderEmails}
              onChange={handleChangeDraftReminderEmailSetting}
            />{" "}
            Remind me about unpublished draft meetings
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Meeting summary emails</SettingsLayout.Left>
          <SettingsLayout.Right>
            <RadioGroup
              value={meetingSummaryEmailPreference}
              onChange={handleChangeMeetingSummaryEmailSetting}
            >
              <RadioGroup.Label className="sr-only">
                Meeting Summary Emails
              </RadioGroup.Label>
              <div className="bg-white rounded-md -space-y-px">
                {meetingSummaryEmailSettings.map((setting, settingIdx) => (
                  <RadioGroup.Option
                    key={setting.name}
                    value={setting.value}
                    className={({ checked }) =>
                      classNames(
                        settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        settingIdx === emailSettings.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "bg-indigo-50 border-indigo-200"
                          : "border-gray-200",
                        "relative border p-4 flex cursor-pointer focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "bg-indigo-600 border-transparent"
                              : "bg-white border-gray-300",
                            active
                              ? "ring-2 ring-offset-2 ring-indigo-500"
                              : "",
                            "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {setting.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {setting.description}
                          </RadioGroup.Description>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </SettingsLayout.Right>
        </SettingsLayout.Row>
      </SettingsLayout.Container>
    </div>
  );
};

export default Email;
