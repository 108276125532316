import { CalendarIcon, UsersIcon } from "@heroicons/react/outline";
import moment from "moment";
import { MeetingOverviewMeetingFragmentFragment } from "types/graphql-schema";

import MeetingVideoConferenceButton from "@apps/meeting/components/meeting/video-conference-button";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import { classNames } from "@helpers/css";
import { assertEdgesNonNullWithStringId, getUrl } from "@helpers/helpers";

const OverviewMeeting = ({
  meeting,
}: {
  meeting: MeetingOverviewMeetingFragmentFragment;
}) => {
  const currentUser = currentUserVar();
  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const otherParticipant = participants.find(
    (participant) => participant.user && participant.user.id !== currentUser.id
  );
  return (
    <AppLink
      className="px-3 py-2 flex flex-col gap-2 group hover:bg-gray-50"
      key={meeting.id}
      to={getUrl({
        meetingGroupId: meeting.meetingGroupId,
        meetingId: meeting.id,
      })}
    >
      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-2">
          <CalendarIcon className="h-5 w-5 text-gray-400" />
          <span className={classNames("text-sm group-hover:underline")}>
            {meeting.title}
          </span>
        </div>
        {meeting.videoConferenceUrl &&
          meeting.startDatetime &&
          moment().isBetween(
            moment(meeting.startDatetime).subtract(5, "minutes"),
            meeting.endDatetime
          ) && (
            <div className="mt-2 text-sm text-gray-700 -ml-1 flex">
              <MeetingVideoConferenceButton
                meeting={meeting}
                className="hover:text-gray-800 hover:bg-black/5 text-xs px-1 py-0.5 rounded text-gray-600 gap-1.5"
              />
            </div>
          )}
      </div>
      <div className="flex items-center text-xs text-gray-500 gap-4 justify-between">
        <div className="">
          {meeting.startDatetime
            ? `${moment(meeting.startDatetime)
                .format("MMM d, h:mma")
                .replace(":00", "")}-${moment(meeting.endDatetime)
                .format("h:mma")
                .replace(":00", "")}`
            : "Unscheduled"}
          {meeting.draft && " - Draft"}
        </div>
        {meeting.meetingGroup?.isFormalOneonone && otherParticipant ? (
          <div className="flex items-center gap-1">
            {otherParticipant.user?.name}
            <Avatar user={otherParticipant.user} size={4} />
          </div>
        ) : (
          <div className="flex items-center gap-0.5">
            <UsersIcon className="h-4 w-4" />{" "}
            {meeting.participants?.totalCount || 1}
          </div>
        )}
      </div>
    </AppLink>
  );
};

export default OverviewMeeting;
