import { CalendarIcon } from "@heroicons/react/outline";
import moment from "moment";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";

import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

import DatePickerCustomHeaderWithClearButton from "./custom-header-with-clear-button";

const CustomDatePickerInput = forwardRef<
  HTMLButtonElement,
  {
    emptyValue: any;
    cannotBeOverdue: any;
    value?: any;
    className: any;
    labelName: any;
    onClick?: any;
    isCompleted: any;
    size: any;
    tooltipPrefix: string;
    alwaysShowCalendarIcon: any;
  }
>(
  (
    {
      emptyValue,
      cannotBeOverdue,
      value,
      className,
      labelName,
      onClick,
      isCompleted,
      size,
      tooltipPrefix = "",
      alwaysShowCalendarIcon,
    },
    ref
  ) => (
    <Tooltip
      text={`${tooltipPrefix}${
        value ? moment(value).format("dddd, LL") : `Add ${labelName}`
      }`}
    >
      <button
        onClick={onClick}
        ref={ref}
        className={classNames(
          "flex items-center gap-1.5 px-1 hover:bg-black/5 rounded hover:text-gray-600 tracking-tight",
          !value ? "text-gray-400" : "text-gray-600 uppercase",
          !isCompleted &&
            !cannotBeOverdue &&
            moment().isAfter(value, "day") &&
            "text-red-600 hover:text-red-600",
          className
        )}
        aria-label={`Action item ${labelName}`}
      >
        {(alwaysShowCalendarIcon || !value) && (
          <CalendarIcon className={`h-${size} w-${size}`} />
        )}
        {value ? moment(value).format("MMM D") : emptyValue}
      </button>
    </Tooltip>
  )
);

export type ArtifactDatePickerProps = {
  date: any;
  isCompleted: any;
  onChangeDate: (date: Date | null) => void;
  size?: string;
  className?: any;
  emptyValue?: any;
  alwaysShowCalendarIcon?: boolean;
  tooltipPrefix?: string;
  labelName: string;
  minDate?: any;
  maxDate?: any;
  clearable?: boolean;
  cannotBeOverdue?: boolean;
};

const ArtifactDatePicker = ({
  date,
  isCompleted,
  onChangeDate,
  size = "4",
  className,
  emptyValue = null,
  alwaysShowCalendarIcon = false,
  tooltipPrefix = "",
  labelName = "date",
  minDate = null,
  maxDate = null,
  clearable = true,
  cannotBeOverdue = false,
}: ArtifactDatePickerProps) => {
  return (
    <div className="flex group items-center">
      <DatePicker
        selected={date ? moment(date).toDate() : null}
        dateFormat="yyyy-MM-dd"
        placeholderText="No due date"
        popperClassName="z-tooltip"
        onChange={onChangeDate}
        portalId="portal-react-datepicker"
        popperProps={{ strategy: "fixed" }}
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={({
          date: currentDate,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <DatePickerCustomHeaderWithClearButton
            date={date}
            onChangeDate={onChangeDate}
            clearable={clearable}
            currentDate={currentDate}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
        customInput={
          <CustomDatePickerInput
            cannotBeOverdue={cannotBeOverdue}
            labelName={labelName}
            isCompleted={isCompleted}
            className={className}
            size={size}
            tooltipPrefix={tooltipPrefix}
            emptyValue={emptyValue}
            alwaysShowCalendarIcon={alwaysShowCalendarIcon}
          />
        }
      />
    </div>
  );
};

export default ArtifactDatePicker;
