import { gql } from "@apollo/client";

import MeetingViewMeetingNodeFragment from "./meeting-view-meeting-node-fragment";

export default gql`
  ${MeetingViewMeetingNodeFragment}
  query getMeetingQuery(
    $meetingId: Int
    $meetingGroupId: Int!
    $today: DateTime!
    $hasMeetingId: Boolean = false
  ) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      nextMeetings: meetings(
        first: 1
        startDatetime_Gte: $today
        status: confirmed
        ignored: false
      ) @skip(if: $hasMeetingId) {
        edges {
          node {
            id
          }
        }
      }
      lastMeetings: meetings(
        last: 1
        orderBy: "start_datetime"
        status: confirmed
        ignored: false
      ) @skip(if: $hasMeetingId) {
        edges {
          node {
            id
          }
        }
      }
    }
    meeting(meetingId: $meetingId) @include(if: $hasMeetingId) {
      ...MeetingViewMeetingNodeFragment
    }
  }
`;
