import { parseStringToJSON } from "@helpers/helpers";

export const transformTopicsObjectToArray = (topics: any) => {
  return topics.map((topic: any) => ({
    ...topic,
    uid: String(topic.id).includes("topic-") ? null : parseInt(topic.id),
  }));
};

export const filterTopics = (topics: any, options = { clearId: false }) => {
  const { clearId } = options;
  return topics
    .map(
      ({
        uid,
        title,
        description,
        discussionNotes,
        defaultSubjectNotes,
        defaultFacilitatorNotes,
        includesIndividualNotes,
        includesIndividualNotesForCurrentUser,
        includesSharedNotes,
      }: {
        uid: any;
        title: any;
        description: any;
        discussionNotes: any;
        defaultSubjectNotes: any;
        defaultFacilitatorNotes: any;
        includesIndividualNotes: any;
        includesIndividualNotesForCurrentUser: any;
        includesSharedNotes: any;
      }) => ({
        id: clearId ? null : uid,
        title,
        description,
        discussionNotes,
        defaultSubjectNotes,
        defaultFacilitatorNotes,
        includesIndividualNotes,
        includesIndividualNotesForCurrentUser,
        includesSharedNotes,
      })
    )
    .filter(({ title }: { title: any }) => title);
};

export const getTopicArrayFromTemplate = (template: any) => {
  if (!template) {
    return [];
  }
  return template.topics.edges.reduce(
    (memo: any, { node: topic }: { node: any }) => {
      return memo.concat({
        ...topic,
        discussionNotes: parseStringToJSON(topic.discussionNotes),
        defaultSubjectNotes: parseStringToJSON(topic.defaultSubjectNotes),
        defaultFacilitatorNotes: parseStringToJSON(
          topic.defaultFacilitatorNotes
        ),
      });
    },
    []
  );
};

export const getTopicArrayFromMeetingGroupTemplateSeries = (data: any) => {
  return (
    data?.meetingGroup.templateSeries.edges.reduce(
      (memo1: any, { node: templateSerie }: { node: any }) => {
        const memoFromTemplateSerie = templateSerie.topicTemplates.edges.reduce(
          (memo2: any, { node }: { node: any }) => {
            const topicsFromTopicTemplate = getTopicArrayFromTemplate(
              node.topicTemplate
            );
            return memo2.concat(topicsFromTopicTemplate);
          },
          []
        );
        return memo1.concat(memoFromTemplateSerie);
      },
      []
    ) || []
  );
};
