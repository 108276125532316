import { FolderIcon } from "@heroicons/react/outline";
import logo from "@static/img/topicflow-logo-no-text.svg";
import { TopicTemplateFragmentFragment } from "types/graphql-schema";

import { getFinalizePermissionLabel } from "@apps/meeting/components/prep/prep-oneonone";
import { getMeetingAllowVisibilityLabel } from "@apps/meeting/helpers";
import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import { assertEdgesNonNull, getAssetUrl } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

const TemplateSidebar = ({
  template,
}: {
  template: TopicTemplateFragmentFragment;
}) => {
  const label = useLabel();
  const categories = assertEdgesNonNull(template.categories);
  return (
    <div className="sm:w-72 flex flex-col text-sm gap-4 bg-gray-50 sm:border-r p-4 sm:p-6 rounded-l-lg">
      <AppLink
        to={`/templates/${template.id}`}
        className="text-xl font-medium hover:underline"
      >
        {template.title}
      </AppLink>
      {template.description && (
        <div className="text-sm text-gray-700 whitespace-pre-line">
          {template.description}
        </div>
      )}
      <div className="text-sm text-gray-700 whitespace-pre-line">
        <strong className="font-medium">Type: </strong>
        {template.oneononeTemplate
          ? label("oneonone", { capitalize: true })
          : "Meeting"}
      </div>

      {template.oneononeTemplate && (
        <div className="text-sm text-gray-700 whitespace-pre-line">
          <div className="font-medium">Finalization content locking: </div>
          {getFinalizePermissionLabel(template.finalizePermissions)}
        </div>
      )}

      {template.managerInstructions && (
        <div>
          <div className="text-2xs text-gray-600 uppercase">
            Instructions for manager
          </div>
          <div className="text-sm text-gray-700 whitespace-pre-line">
            {template.managerInstructions}
          </div>
        </div>
      )}
      {template.reportInstructions && (
        <div>
          <div className="text-2xs text-gray-600 uppercase">
            Instructions for report
          </div>
          <div className="text-sm text-gray-700 whitespace-pre-line">
            {template.reportInstructions}
          </div>
        </div>
      )}

      <div className="text-sm text-gray-700 whitespace-pre-line">
        <div className="font-medium">Content access:</div>
        {capitalize(
          getMeetingAllowVisibilityLabel(
            {
              allowAdminVisibility: template.allowAdminVisibility,
              allowOrgVisibility: template.allowOrgVisibility,
              allowManagementTreeVisibility:
                template.allowManagementTreeVisibility,
            },
            true
          )
        )}
      </div>

      <div className="flex flex-col gap-2">
        {template.creator ? (
          <div className="mt-4 text-xs text-gray-500 shrink grow-0 flex gap-2 items-center">
            <Avatar size="4" user={template.creator} />
            {template.creator.name}
          </div>
        ) : (
          <div className="mt-4 text-xs text-gray-500 shrink grow-0 flex gap-2 items-center">
            <img
              src={getAssetUrl(logo)}
              alt="Topicflow"
              className="block h-4"
            />
            Topicflow
          </div>
        )}
        <div className="mt-4 text-xs text-gray-500 flex flex-col gap-1.5">
          {categories.map((node) => (
            <AppLink
              key={node.id}
              to={`/templates/group/${node.id}`}
              className="hover:underline flex gap-2"
            >
              <FolderIcon className="h-4 w-4" />
              {node.title}
            </AppLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TemplateSidebar;
