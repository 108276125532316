import { gql } from "@apollo/client";

const TopicNodeFragment = gql`
  fragment TopicNodeNewPageFragment on TopicNode {
    id
    title
    description
    discussionNotes
    created
    state
    eventChannelName
    includesIndividualNotes
    includesIndividualNotesForCurrentUser
    includesSharedNotes
    isMandatory
    notesRequirement
    individualNotes {
      edges {
        node {
          id
          created
          creator {
            id
          }
          hidden
          enabled
          notes
        }
      }
    }
    creator {
      id
      name
    }
    assignee {
      id
      avatar
      name
    }
    previousTopic {
      id
      discussionNotes
    }
    linkedTemplateTopic {
      id
      title
      discussionNotes
      defaultSubjectNotes
      defaultFacilitatorNotes
      topicTemplate {
        id
        title
      }
    }
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    copiedFrom(hasMeeting: true) {
      id
      title
      meeting {
        id
        title
        startDatetime
        meetingGroupId
      }
    }
  }
`;

export default TopicNodeFragment;
