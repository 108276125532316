import {
  CalendarIcon,
  ChartSquareBarIcon,
  DocumentAddIcon,
  PencilAltIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  ArtifactActivityFragmentFragment,
  ArtifactActivityType,
  GetArtifactSidebarQueryQuery,
  GoalArtifactSidebarFragmentFragment,
  KeyResultNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ActivityArtifactKeyResultUpdated from "@apps/activities/components/artifact-key-result-updated";
import ActivityArtifactReferencedFromArtifact from "@apps/activities/components/artifact-referenced-from-artifact";
import ActivityArtifactReferencedFromTopic from "@apps/activities/components/artifact-referenced-from-topic";
import ActivityCommentAdded from "@apps/activities/components/comment-added";
import ActivityGoalCheckin from "@apps/activities/components/goal-checkin";
import useLabel from "@apps/use-label/use-label";
import ErrorContainer from "@components/error/error";
import AppLink from "@components/link/link";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

import ActivityArtifactUpdate from "./artifact-update";

const ArtifactActivity = ({
  artifact,
  activity,
  hideLeftIcon = false,
}: {
  artifact:
    | GetArtifactSidebarQueryQuery["artifact"]
    | GoalArtifactSidebarFragmentFragment;
  activity: ArtifactActivityFragmentFragment;
  hideLeftIcon?: boolean;
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const location = useLocation<TFLocationState>();
  const { search } = location;
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const matchingUrlActivityId =
    queryParams.get("activityId") === String(activity.id);
  const { activityType } = activity;
  const label = useLabel();
  let ActivityIcon = null;
  let content = null;
  let actionLabel: string | JSX.Element = String(activity.activityType);
  const firstChangedField = activity.changedFields?.[0];
  if (activityType === ArtifactActivityType.ArtifactCreation) {
    ActivityIcon = DocumentAddIcon;
    actionLabel = `created this ${label(artifact.artifactType)}`;
  } else if (
    activityType === ArtifactActivityType.KeyResultAdded &&
    firstChangedField
  ) {
    ActivityIcon = ChartSquareBarIcon;
    const keyResult = firstChangedField.newValueObject as KeyResultNode;
    ActivityIcon = ChartSquareBarIcon;
    actionLabel = (
      <span>
        added the {label("key result")}:{" "}
        <span className="font-medium text-gray-700">{keyResult.title}</span>
      </span>
    );
  } else if (
    activityType === ArtifactActivityType.KeyResultRemoved &&
    firstChangedField
  ) {
    ActivityIcon = ChartSquareBarIcon;
    const keyResult = firstChangedField.previousValueObject as KeyResultNode;
    actionLabel = (
      <span>
        removed the {label("key result")}:{" "}
        <span className="font-medium text-gray-700">{keyResult.title}</span>
      </span>
    );
  } else if (
    activityType === ArtifactActivityType.KeyResultUpdated &&
    firstChangedField
  ) {
    const keyResult = firstChangedField.newValueObject as KeyResultNode;
    ActivityIcon = ChartSquareBarIcon;
    actionLabel = (
      <span>
        updated the {label("key result")}:{" "}
        <span className="font-medium text-gray-700">{keyResult.title}</span>
      </span>
    );
    content = <ActivityArtifactKeyResultUpdated activity={activity} />;
  } else if (
    activityType === ArtifactActivityType.ArtifactUpdate &&
    firstChangedField
  ) {
    ActivityIcon = PencilAltIcon;
    actionLabel = `updated this ${label(artifact.artifactType)}`;
    content = (
      <ActivityArtifactUpdate activity={activity} artifactId={artifact.id} />
    );
  } else if (activityType === ArtifactActivityType.ReferencedFromTopic) {
    ActivityIcon = CalendarIcon;
    actionLabel = `mentioned this ${label(artifact.artifactType)} in the topic`;
    content = <ActivityArtifactReferencedFromTopic activity={activity} />;
  } else if (activityType === ArtifactActivityType.ReferencedFromArtifact) {
    ActivityIcon = CalendarIcon;
    const newValueObj = activity?.changedFields?.[0]?.newValueObject;
    actionLabel = `mentioned this ${label(artifact.artifactType)} in the ${
      newValueObj && newValueObj.__typename === "BaseArtifactNode"
        ? label(newValueObj.artifactType)
        : ""
    }`;
    content = <ActivityArtifactReferencedFromArtifact activity={activity} />;
  } else if (
    activityType === ArtifactActivityType.GoalCheckin &&
    artifact.__typename === "GoalArtifactNode"
  ) {
    ActivityIcon = PresentationChartLineIcon;
    actionLabel = `updated this ${label(artifact.artifactType)}`;
    content = <ActivityGoalCheckin activity={activity} artifact={artifact} />;
  } else if (activityType === ArtifactActivityType.CommentAdded) {
    ActivityIcon = PresentationChartLineIcon;
    actionLabel = `added a comment`;
    content = <ActivityCommentAdded activity={activity} artifact={artifact} />;
  } else {
    throw new Error(`activityType not handled: ${activityType}`);
  }

  useEffect(() => {
    if (ref.current && matchingUrlActivityId) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref, matchingUrlActivityId]);

  return (
    <div
      className={classNames(
        matchingUrlActivityId &&
          "bg-yellow-50 rounded border border-yellow-200 -m-2 p-2"
      )}
    >
      <div className="relative flex items-start space-x-3" ref={ref}>
        {!hideLeftIcon && (
          <div>
            <div className="relative px-1">
              <div className="mt-1 h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center">
                {ActivityIcon && (
                  <ActivityIcon className="h-4 w-4 text-gray-500" />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="min-w-0 flex-1 py-1.5">
          <div className="text-sm text-gray-500">
            <span className="font-medium text-gray-700">
              {activity.actor?.name || "A user"}
            </span>{" "}
            {actionLabel}
            <AppLink
              to={toWithBackground({
                pathname: `${getUrl({
                  artifactId: artifact.id,
                  artifactType: artifact.artifactType,
                })}?activityId=${activity.id}`,
                location,
              })}
              className="whitespace-nowrap text-xs tracking-tighter ml-1 text-gray-400 hover:underline"
            >
              (<TimeAgoCustom date={activity.created} />)
            </AppLink>
          </div>
          <div className="text-sm text-gray-800 mt-2 empty:hidden ">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(ArtifactActivity, {
  fallback: (
    <ErrorContainer description={"This activity could not be displayed."} />
  ),
});
