import { useQuery } from "@apollo/client";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { pull, union } from "lodash";
import {
  AlignmentGoalFragmentFragment,
  GetAlignedChildGoalsQueryQuery,
  GetAlignedChildGoalsQueryQueryVariables,
  GetTopLevelGoalsQueryQueryVariables,
} from "types/graphql-schema";

import GoalKeyResultItem from "@apps/artifact-sidebar/components/goal-key-result-item";
import Artifact from "@apps/artifact/artifact";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { batchClient } from "@graphql/client";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getAlignedChildGoalsQuery from "./graphql/get-aligned-child-goals-query";

const GoalAlignmentTreeGoal = ({
  goal,
  isShowingKeyResults,
  goalDueBetweenDates,
  goalStates,
}: {
  goal: AlignmentGoalFragmentFragment;
  isShowingKeyResults: boolean;
  goalDueBetweenDates: GetTopLevelGoalsQueryQueryVariables["goalDueBetweenDates"];
  goalStates: GetTopLevelGoalsQueryQueryVariables["goalStates"];
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const isExpanded = (
    uiPreferenceCache.objectiveAlignmentExpandedIds || []
  ).includes(goal.id);

  const { data } = useQuery<
    GetAlignedChildGoalsQueryQuery,
    GetAlignedChildGoalsQueryQueryVariables
  >(getAlignedChildGoalsQuery, {
    variables: {
      goalId: goal.id,
      goalDueBetweenDates,
      goalStates,
    },
    skip: !isExpanded,
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  if (goal.__typename !== "GoalArtifactNode") return null;
  const childGoals = (
    data?.artifact && data.artifact.__typename === "GoalArtifactNode"
      ? assertEdgesNonNull(data.artifact.childGoals)
      : []
  ) as AlignmentGoalFragmentFragment[];

  const handleToggleChildGoals = () => {
    const objectiveAlignmentExpandedIds = isExpanded
      ? pull(uiPreferenceCache.objectiveAlignmentExpandedIds, goal.id)
      : union(uiPreferenceCache.objectiveAlignmentExpandedIds, [goal.id]);
    saveUiPreference({ objectiveAlignmentExpandedIds });
  };

  const childGoalIds = assertEdgesNonNull(goal.childGoals).map(({ id }) => id);
  return (
    <div key={goal.id}>
      <div key={goal.id} className="flex items-center gap-1">
        <button
          className={classNames(
            "p-1 rounded hover:bg-black/5",
            childGoalIds.length === 0 && "opacity-0"
          )}
          onClick={handleToggleChildGoals}
        >
          {isExpanded ? (
            <ChevronDownIcon className="h-4 w-4" />
          ) : (
            <ChevronRightIcon className="h-4 w-4" />
          )}
        </button>
        <div
          key={goal.id}
          className={classNames(
            "rounded-md bg-white border px-2 py-1 w-96 2xl:w-144 4xl:w-192",
            isShowingKeyResults && goal.keyResults.edges.length > 0 && "pb-2"
          )}
        >
          <Artifact artifact={goal} showTitleOnlyOnOneLine />
          {isShowingKeyResults && goal.keyResults.edges.length > 0 && (
            <div className="mt-2 divide-y border rounded text-sm text-gray-800">
              {assertEdgesNonNull(goal.keyResults).map((keyResult) => (
                <GoalKeyResultItem
                  key={keyResult.id}
                  className="px-1 py-0.5 gap-2"
                  goalArtifact={goal}
                  keyResult={keyResult}
                  isReadOnly
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {isExpanded && childGoals.length > 0 && (
        <div className="ml-9 mt-2 relative">
          {isExpanded && childGoals.length > 0 && (
            <div className="w-px absolute top-0 bottom-0 left-3 bg-black/5 -ml-px" />
          )}
          <GoalAlignmentTree
            goals={childGoals}
            isShowingKeyResults={isShowingKeyResults}
            goalDueBetweenDates={goalDueBetweenDates}
            goalStates={goalStates}
          />
        </div>
      )}
    </div>
  );
};

const GoalAlignmentTree = ({
  goals,
  isShowingKeyResults,
  goalDueBetweenDates,
  goalStates,
}: {
  goals: AlignmentGoalFragmentFragment[];
  isShowingKeyResults: boolean;
  goalDueBetweenDates: GetTopLevelGoalsQueryQueryVariables["goalDueBetweenDates"];
  goalStates: GetTopLevelGoalsQueryQueryVariables["goalStates"];
}) => {
  return (
    <div className="flex flex-col gap-2">
      {goals.map((goal) =>
        goal.__typename === "GoalArtifactNode" ? (
          <GoalAlignmentTreeGoal
            key={goal.id}
            goal={goal}
            isShowingKeyResults={isShowingKeyResults}
            goalDueBetweenDates={goalDueBetweenDates}
            goalStates={goalStates}
          />
        ) : null
      )}
    </div>
  );
};

export default GoalAlignmentTree;
