import * as Sentry from "@sentry/react";
import { compact } from "lodash";
import {
  ArtifactType,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import {
  ToggleButtonGroup,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";

import MeetingFeedbackCollapsible from "./feedback-collapsible";
import MeetingSidebarCollapsibleContainer from "./meeting-sidebar-collapsible-container";
import useMeetingSidebarCollapsibleContainer from "./use-meeting-sidebar-collapsible-container";

const Feedbacks = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const label = useLabel();
  const { isFormalOneonone, subject, participantIds } =
    useMeetingSidebarCollapsibleContainer({
      meeting,
      collapsibleFilterName: "Feedback",
    });

  const { saveUiPreference, uiPreferenceCache } = useUiPreferenceCache();
  const handleToggleFilter = (option: ToggleButtonGroupType<boolean>) => {
    saveUiPreference({ oneononeFeedbackOnlyParticipants: !!option.value });
  };

  const commonFilters = {
    first: 5,
  };

  if (!subject || !isFormalOneonone) return null;

  return (
    <MeetingSidebarCollapsibleContainer
      collapsibleFilterName="Feedback"
      subject={subject}
      isFormalOneonone={isFormalOneonone}
      toggledAssignee={false}
      toggledJustMeeting={false}
      createArtifactFormOptions={{
        artifactType: ArtifactType.Feedback,
        meetingId: meeting.id,
        meetingGroupId: meeting.meetingGroup?.id,
      }}
      titleLabel="feedback"
      filters={
        <>
          <ToggleButtonGroup<boolean>
            onClick={handleToggleFilter}
            buttons={[
              {
                tooltip: `Show all ${label("feedback", {
                  pluralize: true,
                })}`,
                active: !uiPreferenceCache.oneononeFeedbackOnlyParticipants,
                value: false,
                title: "All",
              },
              {
                tooltip: `Show only meeting participants ${label("feedback", {
                  pluralize: true,
                })}`,
                active: uiPreferenceCache.oneononeFeedbackOnlyParticipants,
                title: "Participants",
                value: true,
              },
            ]}
          />
        </>
      }
    >
      <MeetingFeedbackCollapsible
        collapsibleToggleKey="meetingReceivedFeedbackExpanded"
        title={`${label("feedback")} received by ${subject.firstName}`}
        variables={{
          ...commonFilters,
          feedbackRecipients: compact([subject.id]),
          feedbackSenders: uiPreferenceCache.oneononeFeedbackOnlyParticipants
            ? participantIds
            : undefined,
        }}
      />
      <MeetingFeedbackCollapsible
        collapsibleToggleKey="meetingGivenFeedbackExpanded"
        title={`${label("feedback")} given by ${
          subject.firstName || "subject"
        }`}
        variables={{
          ...commonFilters,
          feedbackSenders: compact([subject.id]),
          feedbackRecipients: uiPreferenceCache.oneononeFeedbackOnlyParticipants
            ? participantIds
            : undefined,
        }}
        roundedBottom
      />
    </MeetingSidebarCollapsibleContainer>
  );
};

export default Sentry.withErrorBoundary(Feedbacks, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
