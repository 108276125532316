import { useMutation } from "@apollo/client";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  GoalArtifactSidebarFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import ArtifactSidebarGoalPickerCombobox from "./aligned-goal-picker-combobox";
import alignGoalMutation from "./graphql/align-goal-mutation";

const ArtifactSidebarGoalPicker = ({
  artifact,
  alignmentType = "parent",
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
  alignmentType: "child" | "parent";
}) => {
  const label = useLabel();

  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);

  const parentGoal = artifact.parentGoal;
  const childGoals = artifact?.childGoals
    ? assertEdgesNonNull(artifact.childGoals)
    : [];

  const handleSelectGoal = (option: ComboboxGenericOption<number>) => {
    if (alignmentType === "parent") {
      updateGoal({
        variables: {
          goalId: artifact.id,
          parentGoalId: option.value,
        },
        onError: onNotificationErrorHandler(),
      });
    } else {
      updateGoal({
        variables: {
          goalId: option.value,
          parentGoalId: artifact.id,
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const hasAlreadyAParent = alignmentType === "parent" && !!parentGoal;
  return (
    <div className="flex items-center gap-2">
      <div className="w-56">
        <ArtifactSidebarGoalPickerCombobox
          alignmentType={alignmentType}
          goalId={artifact.id}
          goalScope={artifact.scope}
          onSelectGoal={handleSelectGoal}
          parentGoalId={parentGoal?.id}
          childGoalIds={childGoals.map(({ id }) => id)}
        />
      </div>
      {hasAlreadyAParent && (
        <div className="text-xs text-gray-400 tracking-tight">
          There can be only one {label("goal")} aligned up.
        </div>
      )}
    </div>
  );
};

export default ArtifactSidebarGoalPicker;
