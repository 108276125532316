import { useMutation } from "@apollo/client";
import { validate } from "email-validator";
import uniqBy from "lodash/uniqBy";
import pluralize from "pluralize";
import { useState } from "react";

import inviteUsersMutation from "@apps/invite-dialog/graphql/invite-users-mutation";
import Button from "@components/button/button";
import Onboarding from "@components/onboarding/onboarding";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { role } from "@helpers/constants";

const OnboardingCreateOrganizationInvite = ({
  currentUser,
  showDoneStep,
  organization,
  onNextStep,
}) => {
  const [emails, setEmails] = useState("");
  const [message, setMessage] = useState("Join our team on Topicflow!");
  const [inviteUsers, { data, loading }] = useMutation(inviteUsersMutation);
  const invitationRequests = uniqBy(
    emails
      .split(",")
      .map((email) => email.trim())
      .filter((email) => validate(email))
      .map((email) => ({
        inviteeEmail: email.trim(),
        inviteMessage: message,
        organizationId: organization.id,
        role: organization.personal ? role.administrator : role.member,
      })),
    ({ inviteeEmail }) => inviteeEmail
  );
  const invitationsSent = data?.createInvitations.invitations || [];

  const handleClickInvite = () => {
    if (invitationRequests.length === 0) {
      onNextStep();
    }
    inviteUsers({
      variables: { invitationRequests },
      onCompleted: () => {
        onNextStep();
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return invitationsSent.length > 0 && showDoneStep ? (
    <Onboarding.SuccessStep
      stepName="Invited"
      aria-label="Create organization invite done"
    >
      {invitationRequests.length} {pluralize("member", invitationsSent.length)}
    </Onboarding.SuccessStep>
  ) : invitationsSent.length === 0 && showDoneStep ? null : (
    <div className="px-8 py-6 bg-white shadow rounded-md text-sm">
      <Onboarding.SubTitle className="mb-6">
        Add your team to {organization.name}
      </Onboarding.SubTitle>
      <div className="mb-6 flex gap-4">
        <div className="w-28 text-base">Emails</div>
        <div className="flex-1">
          <CustomTextareaAutosize
            placeholder="Add your team members"
            rows={2}
            defaultValue={emails}
            onChange={(e) => setEmails(e.currentTarget.value)}
            className="w-full text-sm rounded-md border border-gray-300 shadow-inner p-2"
            aria-label="Textarea invite emails"
          />
          <div className="text-sm text-gray-500">
            Separate email addresses with commas
          </div>
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="w-28 text-base">Add a message</div>
        <div className="flex-1">
          <CustomTextareaAutosize
            placeholder="Add a message..."
            aria-label="Textarea invite message"
            rows={2}
            defaultValue={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            className="w-full text-sm rounded-md border border-gray-300 shadow-inner p-2"
          />
        </div>
      </div>
      <div className="mb-6 flex justify-center">
        <Button
          theme="primary"
          onClick={handleClickInvite}
          disabled={loading || invitationRequests.length === 0}
          text={loading ? "Sending invites" : "Send invites"}
        />
      </div>
      <div className="flex justify-center">
        <button className={Onboarding.textButtonClassName} onClick={onNextStep}>
          Skip
        </button>
      </div>
    </div>
  );
};

export default OnboardingCreateOrganizationInvite;
