import { Popover } from "@headlessui/react";
import { ArrowSmRightIcon, XIcon } from "@heroicons/react/outline";
import { Menu } from "@szhsin/react-menu";
import { isEqual, sortBy, uniq } from "lodash";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { MdOutlineBookmarks } from "react-icons/md";
import {
  ArtifactType,
  GoalScope,
  GoalState,
  GoalStatus,
  GoalVisibility,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import ExplorerHubspotStageCombobox from "@apps/components/hubspot-stage-picker/hubspot-stage-picker";
import ExplorerExport from "@apps/explorer/components/explorer-export";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { assertNonNull, dateRangeToDateArray } from "@helpers/helpers";

import {
  ExplorerFilterType,
  explorerHubspotType,
  explorerIsFilteringArtifactType,
  explorerMeetingType,
  filtersWithMultipleValues,
  getDefaultFilters,
  isTypeAllowedForGroupBy,
  testIsAllowedArtifactType,
} from "../helpers";
import ExplorerBookmarkPopover from "./bookmark-popover";
import ExplorerCoreValueCombobox from "./explorer-core-value-picker";
import ExplorerMeetingGroupCombobox from "./explorer-meeting-group-picker";
import ExplorerMenuButton from "./explorer-menu-button";
import ExplorerMenuOptions from "./explorer-menu-options";
import ExplorerOrganizationCombobox from "./explorer-organization-picker";
import ExplorerSearchInput from "./explorer-search-input";
import ExplorerTeamCombobox from "./explorer-team-picker";
import ExplorerTypeDropdown from "./explorer-type-dropdown";
import ExplorerUserPicker from "./explorer-user-picker";
import ExplorerGroupByOptions from "./group-by-options-menu";
import ExplorerSaveBookmarkButton from "./save-bookmark-button";
import ExplorerSortOptionsMenu from "./sort-options-menu";

type Props = {
  filters: ExplorerFilterType;
  hideExportButton?: boolean;
  onChangeFilters: (filters: ExplorerFilterType) => void;
  onClickBookmark?: (filters: ExplorerFilterType) => void;
};

export const explorerSelectClassName = classNames(
  "bg-white text-gray-700 hover:text-gray-800 border border-gray-300 shadow-sm",
  "rounded text-left cursor-default text-sm tracking-tight",
  "flex-1 pl-2 pr-1 py-1 flex items-center gap-1"
);
export const explorerSelectDisabledClassName = classNames(
  "bg-white text-gray-400 hover:text-gray-400 bg-gray-50"
);

const ExplorerFilters: React.FC<Props> = ({
  filters,
  onChangeFilters,
  onClickBookmark,
  hideExportButton = false,
}) => {
  const currentOrganization = currentOrganizationVar();
  const label = useLabel();
  const defaultFilters = getDefaultFilters();

  const allActionItemState = {
    selectedLabel: "All",
    value: null,
    filters: { actionItemState: null },
    label: "All",
    selected: filters.actionItemState === null,
  };
  const orgActionItemStateOptions = assertNonNull(
    currentOrganization.actionItemStates
  )
    .map(assertNonNull)
    .map((actionItemState) => ({
      selectedLabel: actionItemState.label,
      value: actionItemState.value,
      filters: {
        actionItemState: [actionItemState.value],
      },
      label: actionItemState.label,
      selected: !!(
        actionItemState?.value &&
        filters.actionItemState?.includes(actionItemState.value)
      ),
    }));
  const actionItemStateOptions = [
    allActionItemState,
    ...orgActionItemStateOptions,
  ];

  const goalStateOptions = [
    {
      value: null,
      filters: { goalState: null },
      label: `All`,
      selectedLabel: `All`,
      selected: filters.goalState === null,
    },
    {
      value: GoalState.Open,
      filters: { goalState: [GoalState.Open] },
      label: `Opened ${label("goal", { pluralize: true })}`,
      selectedLabel: `Opened`,
      selected: filters.goalState?.includes(GoalState.Open),
    },
    {
      value: GoalState.Closed,
      filters: { goalState: [GoalState.Closed] },
      label: `Closed ${label("goal", { pluralize: true })}`,
      selectedLabel: `Closed`,
      selected: filters.goalState?.includes(GoalState.Closed),
    },
    {
      value: GoalState.Draft,
      filters: { goalState: [GoalState.Draft] },
      label: `Draft ${label("goal", { pluralize: true })}`,
      selectedLabel: `Draft`,
      selected: filters.goalState?.includes(GoalState.Draft),
    },
  ];

  const dueDateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
    value: dateRange,
    filters: { dueBetweenDates: dateRange },
    label: label(dateRange, { capitalize: true }),
    selectedLabel: label(dateRange, { capitalize: true }),
    selected: filters.dueBetweenDates === dateRange,
    description: dateRangeToDateArray({
      range: dateRange,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
      .map((date) => moment(date).format("ll"))
      .join(" - "),
  }));
  const isCustomDueDateRange =
    !dueDateRangeOptions.find(({ selected }) => selected) &&
    filters.dueBetweenDates !== null;
  const dueBetweenDatesOptions = [
    {
      value: null,
      filters: { dueBetweenDates: null },
      label: `All`,
      selectedLabel: `All`,
      selected: filters.dueBetweenDates === null,
    },
    ...dueDateRangeOptions,
    {
      value: "custom",
      filters: {
        dueBetweenDates: isCustomDueDateRange
          ? filters.dueBetweenDates
          : [
              // default to today + 1 month.
              moment().format("YYYY-MM-DD"),
              moment().add(1, "month").format("YYYY-MM-DD"),
            ],
      },
      label: "Custom range",
      selectedLabel: "Custom range",
      selected: isCustomDueDateRange,
    },
  ];

  const createdDateRangeOptions = Object.values(DateRangeEnum).map(
    (dateRange) => ({
      value: dateRange,
      filters: { createdBetweenDates: dateRange },
      label: label(dateRange, { capitalize: true }),
      selectedLabel: label(dateRange, { capitalize: true }),
      selected: filters.createdBetweenDates === dateRange,
      description: dateRangeToDateArray({
        range: dateRange,
        quarterStartMonth: currentOrganization.quarterStartMonth,
      })
        .map((date) => moment(date).format("ll"))
        .join(" - "),
    })
  );
  const isCustomCreatedDateRange =
    !createdDateRangeOptions.find(({ selected }) => selected) &&
    filters.createdBetweenDates !== null;
  const createdBetweenDatesOptions = [
    {
      value: null,
      filters: { createdBetweenDates: null },
      label: `All`,
      selectedLabel: `All`,
      selected: filters.createdBetweenDates === null,
    },
    ...createdDateRangeOptions,
    {
      value: "custom",
      filters: {
        createdBetweenDates: isCustomDueDateRange
          ? filters.createdBetweenDates
          : [
              // default to today + 1 month.
              moment().format("YYYY-MM-DD"),
              moment().add(1, "month").format("YYYY-MM-DD"),
            ],
      },
      label: "Custom range",
      selectedLabel: "Custom range",
      selected: isCustomCreatedDateRange,
    },
  ];

  const goalStatusOptions = [
    {
      value: null,
      filters: { goalStatus: null },
      label: `All`,
      selectedLabel: "All",
    },
    {
      value: GoalStatus.OnTrack,
      filters: { goalStatus: GoalStatus.OnTrack },
      label: `On track ${label("goal", { pluralize: true })}`,
      selectedLabel: "On track",
    },
    {
      value: GoalStatus.AtRisk,
      filters: { goalStatus: GoalStatus.AtRisk },
      label: `At risk ${label("goal", { pluralize: true })}`,
      selectedLabel: "At risk",
    },
    {
      value: GoalStatus.OffTrack,
      filters: { goalStatus: GoalStatus.OffTrack },
      label: `Off track ${label("goal", { pluralize: true })}`,
      selectedLabel: "Off track",
    },
  ];

  const isStaleOptions = [
    {
      value: null,
      filters: { isStale: null },
      label: "All",
      selectedLabel: "All",
    },
    {
      value: true,
      filters: { isStale: true },
      label: "Yes",
      description: "Updated more than 14 days ago",
      selectedLabel: "Yes",
    },
    {
      value: false,
      filters: { isStale: false },
      label: "No",
      description: "Updated in the last 14 days",
      selectedLabel: "No",
    },
  ];

  const meetingIsFormalOneononeOptions = [
    {
      value: null,
      filters: { meetingIsFormalOneonone: null },
      label: `All meeting types`,
      selectedLabel: `All`,
    },
    {
      value: true,
      filters: { meetingIsFormalOneonone: true },
      label: `Only formal ${label("1-on-1", { pluralize: true })}`,
      selectedLabel: `${label("1-on-1", { pluralize: true })}`,
    },
    {
      value: false,
      filters: { meetingIsFormalOneonone: false },
      label: "All team meetings",
      selectedLabel: `Team meetings`,
    },
  ];

  const meetingIsDraftOptions = [
    {
      value: null,
      filters: { meetingIsDraft: null },
      label: `All`,
      selectedLabel: `All`,
    },
    {
      value: true,
      filters: { meetingIsDraft: true },
      label: `Only draft meetings`,
      selectedLabel: `Yes`,
    },
    {
      value: false,
      filters: { meetingIsDraft: false },
      label: "No draft meetings",
      selectedLabel: `No`,
    },
  ];

  const goalVisibilityOptions = [
    {
      value: null,
      filters: { goalVisibility: null },
      label: "All",
      selectedLabel: "All",
    },
    {
      value: GoalVisibility.Public,
      filters: { goalVisibility: GoalVisibility.Public },
      label: "Public",
      selectedLabel: "Public",
    },
    {
      value: GoalVisibility.Private,
      filters: { goalVisibility: GoalVisibility.Private },
      label: "Private",
      selectedLabel: "Private",
    },
  ];

  const handleChangeFilters =
    (filter: keyof ExplorerFilterType) => (value: unknown) => {
      const newFilters: ExplorerFilterType = { [`${filter}`]: value };
      onChangeFilters(newFilters);
    };

  const handleClickReset = () => {
    onChangeFilters(defaultFilters);
  };

  const handleChangeGoalOwnersFilters = (
    value: ExplorerFilterType["goalOwners"]
  ) => {
    if (value !== null) {
      return handleChangeFilters("goalOwners")([value]);
    }
    handleChangeFilters("goalOwners")(null);
  };

  const handleChangeGoalTeamsFilters = (value: number | null) => {
    if (value !== null) {
      return handleChangeFilters("goalTeams")([value]);
    }
    handleChangeFilters("goalTeams")(null);
  };

  const handleChangeMeetingParticipantsFilters = (value: number | null) => {
    if (value !== null) {
      return handleChangeFilters("meetingParticipants")([value]);
    }
    handleChangeFilters("meetingParticipants")(null);
  };

  const handleChangeMeetingGroupFilters = (
    value: ExplorerFilterType["meetingGroupId"]
  ) => {
    if (value !== null) {
      return handleChangeFilters("meetingGroupId")(value);
    }
    handleChangeFilters("meetingGroupId")(null);
  };

  const handleChangeHubspotStageFilters = (
    value: ExplorerFilterType["hubspotStageId"]
  ) => {
    if (value !== null) {
      return handleChangeFilters("hubspotStageId")(value);
    }
    handleChangeFilters("hubspotStageId")(null);
  };

  const handleChangeCoreValueFilter = (
    value: ExplorerFilterType["recognitionCoreValue"]
  ) => {
    if (value !== null) {
      return handleChangeFilters("recognitionCoreValue")(value);
    }
    handleChangeFilters("recognitionCoreValue")(null);
  };

  const handleSelectMenuItem = (
    updatedFilters: ExplorerFilterType,
    filterKey: keyof ExplorerFilterType
  ) => {
    const currentFilterValue = filters[filterKey];
    const updatedFitlerValue = updatedFilters[filterKey];
    if (
      filtersWithMultipleValues.includes(filterKey) &&
      Array.isArray(updatedFitlerValue) &&
      Array.isArray(currentFilterValue)
    ) {
      const value = updatedFitlerValue[0];
      const newArrValues = (currentFilterValue as any[]).includes(value)
        ? currentFilterValue.filter((val: any) => val !== value)
        : (currentFilterValue || []).concat(value);
      const cleanedArrValues = sortBy(uniq(newArrValues));
      const arrValues = cleanedArrValues.length > 0 ? cleanedArrValues : null;
      onChangeFilters({ ...filters, [`${filterKey}`]: arrValues });
    } else {
      onChangeFilters(updatedFilters);
    }
  };

  const handleChangeCustomRangeStartDate = (newDate: Date | null) => {
    if (newDate && filters.dueBetweenDates) {
      const dueBetweenDates = [...filters.dueBetweenDates];
      dueBetweenDates[0] = moment(newDate).format("YYYY-MM-DD");
      onChangeFilters({ ...filters, dueBetweenDates });
    }
  };

  const handleChangeCustomRangeEndDate = (newDate: Date | null) => {
    if (newDate && filters.dueBetweenDates) {
      const dueBetweenDates = [...filters.dueBetweenDates];
      dueBetweenDates[1] = moment(newDate).format("YYYY-MM-DD");
      onChangeFilters({ ...filters, dueBetweenDates });
    }
  };

  // RENDER
  return (
    <div aria-label="Explorer filters container" className="fs-unmask">
      <div className="text-gray-500 text-sm font-medium mb-3 flex justify-between items-center gap-2">
        <div className="flex items-center gap-2">
          <span className="text-xs uppercase">Filters</span>

          {!isEqual(defaultFilters, filters) && (
            <Tooltip text="Clear filters">
              <button
                className="text-gray-500 text-2xs tracking-tight pl-1 pr-1.5 rounded-md font-normal focus:outline-none focus:ring-0 bg-white border flex gap-0.5 items-center hover:bg-gray-50"
                onClick={handleClickReset}
              >
                <XIcon className="h-4 w-4 text-gray-500" />
                Clear
              </button>
            </Tooltip>
          )}
        </div>
        <div className="flex items-center">
          <ExplorerBookmarkPopover
            filters={filters}
            onClickBookmark={onClickBookmark}
          >
            {({ setReferenceElement }) => (
              <Popover.Button
                ref={setReferenceElement}
                className="text-gray-500 text-2xs tracking-tight pl-1 pr-1.5 rounded-md font-normal focus:outline-none focus:ring-0 bg-white border flex gap-0.5 items-center hover:bg-gray-50"
              >
                <MdOutlineBookmarks className="h-4 w-4" />
                Bookmarks
              </Popover.Button>
            )}
          </ExplorerBookmarkPopover>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <ExplorerSearchInput
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
        <ExplorerTypeDropdown
          filters={filters}
          onChangeFilters={onChangeFilters}
        />

        {/* ACTION ITEMS */}
        {filters.type === ArtifactType.ActionItem && (
          <div className="flex">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={actionItemStateOptions}
                  filters={filters}
                  prefix="State"
                  multiple
                  filterKey="actionItemState"
                />
              }
            >
              <ExplorerMenuOptions
                filterKey="actionItemState"
                options={actionItemStateOptions}
                onSelectMenuItem={handleSelectMenuItem}
              />
            </Menu>
          </div>
        )}
        {filters.type === ArtifactType.ActionItem && (
          <ExplorerUserPicker
            prefix="Assignee"
            ariaLabel="Explorer actionItemAssignee user combobox"
            className={explorerSelectClassName}
            onSelectUserId={handleChangeFilters("actionItemAssignee")}
            userId={filters.actionItemAssignee}
          />
        )}
        {filters.type === ArtifactType.ActionItem && (
          <ExplorerTeamCombobox
            prefix={label("team", { capitalize: true })}
            ariaLabel="Explorer actionItemAssignedToMembersOfTeam team combobox"
            unassignedText={`No ${label("team", { pluralize: true })}`}
            className={explorerSelectClassName}
            onSelectTeamId={handleChangeFilters(
              "actionItemAssignedToMembersOfTeam"
            )}
            teamId={filters.actionItemAssignedToMembersOfTeam}
          />
        )}

        {/* GOALS */}
        {filters.type === ArtifactType.Goal && (
          <div className="flex">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={goalStateOptions}
                  filterKey="goalState"
                  multiple
                  filters={filters}
                  prefix="State"
                />
              }
            >
              <ExplorerMenuOptions
                filterKey="goalState"
                options={goalStateOptions}
                onSelectMenuItem={handleSelectMenuItem}
              />
            </Menu>
          </div>
        )}
        {filters.type === ArtifactType.Goal && (
          <div className="flex">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={goalStatusOptions}
                  filters={filters}
                  prefix="Status"
                  filterKey="goalStatus"
                />
              }
            >
              <ExplorerMenuOptions
                options={goalStatusOptions}
                onSelectMenuItem={handleSelectMenuItem}
                filterKey="goalStatus"
              />
            </Menu>
          </div>
        )}
        {filters.type === ArtifactType.Goal && (
          <div className="flex">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={goalVisibilityOptions}
                  filters={filters}
                  prefix="Visibility"
                  filterKey="goalVisibility"
                />
              }
            >
              <ExplorerMenuOptions
                options={goalVisibilityOptions}
                onSelectMenuItem={handleSelectMenuItem}
                filterKey="goalVisibility"
              />
            </Menu>
          </div>
        )}
        {filters.type === ArtifactType.Goal && (
          <div className="flex">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={isStaleOptions}
                  filters={filters}
                  prefix="Stale"
                  filterKey="isStale"
                />
              }
            >
              <ExplorerMenuOptions
                options={isStaleOptions}
                onSelectMenuItem={handleSelectMenuItem}
                filterKey="isStale"
              />
            </Menu>
          </div>
        )}
        {filters.type === ArtifactType.Goal && (
          <ExplorerUserPicker
            prefix="Owner"
            ariaLabel="Explorer goalOwners user combobox"
            className={explorerSelectClassName}
            onSelectUserId={handleChangeGoalOwnersFilters}
            userId={
              Array.isArray(filters.goalOwners) && filters.goalOwners.length > 0
                ? Number(filters.goalOwners[0])
                : filters.goalOwners !== null
                ? Number(filters.goalOwners)
                : null
            }
          />
        )}
        {filters.type === ArtifactType.Goal &&
          filters.goalScope === GoalScope.Team && (
            <ExplorerTeamCombobox
              prefix={label("team", { capitalize: true })}
              ariaLabel="Explorer goalTeams team combobox"
              unassignedText={`No ${label("team", { pluralize: true })}`}
              className={explorerSelectClassName}
              onSelectTeamId={handleChangeGoalTeamsFilters}
              teamId={
                Array.isArray(filters.goalTeams) && filters.goalTeams.length > 0
                  ? Number(filters.goalTeams[0])
                  : filters.goalTeams !== null
                  ? Number(filters.goalTeams)
                  : null
              }
            />
          )}

        {(filters.type === ArtifactType.ActionItem ||
          filters.type === ArtifactType.Goal) && (
          <div className="flex flex-col">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={dueBetweenDatesOptions}
                  filters={filters}
                  prefix="Due"
                  filterKey="dueBetweenDates"
                />
              }
            >
              <ExplorerMenuOptions
                options={dueBetweenDatesOptions}
                onSelectMenuItem={handleSelectMenuItem}
                filterKey="dueBetweenDates"
              />
            </Menu>
            {isCustomDueDateRange && filters.dueBetweenDates && (
              <div className="mt-1 flex justify-end gap-0.5 items-center">
                <ReactDatePicker
                  placeholderText="Start date"
                  selected={moment(filters.dueBetweenDates[0]).toDate()}
                  onChange={handleChangeCustomRangeStartDate}
                  dateFormat="MMM d, yyyy"
                  className={classNames(
                    explorerSelectClassName,
                    "w-24 tracking-tight py-0.5 text-xs"
                  )}
                  maxDate={moment(filters.dueBetweenDates[1]).toDate()}
                />
                <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
                <ReactDatePicker
                  selected={moment(filters.dueBetweenDates[1]).toDate()}
                  placeholderText="End date"
                  onChange={handleChangeCustomRangeEndDate}
                  dateFormat="MMM d, yyyy"
                  className={classNames(
                    explorerSelectClassName,
                    "w-24 tracking-tight py-0.5 text-xs"
                  )}
                  minDate={moment(filters.dueBetweenDates[0]).toDate()}
                />
              </div>
            )}
          </div>
        )}

        {/* RECOGNITIONS */}
        {filters.type === ArtifactType.Recognition && (
          <ExplorerCoreValueCombobox
            prefix="Core value"
            ariaLabel="Explorer core value combobox"
            className={explorerSelectClassName}
            onSelectCoreValue={handleChangeCoreValueFilter}
            coreValueId={filters.recognitionCoreValue}
          />
        )}
        {filters.type === ArtifactType.Recognition && (
          <ExplorerUserPicker
            prefix="Recipient"
            ariaLabel="Explorer recognitionRecipient user combobox"
            className={explorerSelectClassName}
            onSelectUserId={handleChangeFilters("recognitionRecipient")}
            userId={filters.recognitionRecipient}
          />
        )}

        {/* Feedback */}
        {filters.type === ArtifactType.Feedback && (
          <ExplorerUserPicker
            prefix="Subject"
            ariaLabel="Explorer feedbackRecipient user combobox"
            className={explorerSelectClassName}
            onSelectUserId={handleChangeFilters("feedbackRecipient")}
            userId={filters.feedbackRecipient}
          />
        )}

        {/* MEETINGS */}
        {filters.type === explorerMeetingType && (
          <>
            <div className="flex">
              <Menu
                className="text-sm z-dropdown"
                portal
                menuButton={
                  <ExplorerMenuButton
                    options={meetingIsFormalOneononeOptions}
                    filters={filters}
                    prefix={`Meeting type`}
                    filterKey="meetingIsFormalOneonone"
                  />
                }
              >
                <ExplorerMenuOptions
                  options={meetingIsFormalOneononeOptions}
                  onSelectMenuItem={handleSelectMenuItem}
                  filterKey="meetingIsFormalOneonone"
                />
              </Menu>
            </div>
            <div className="flex">
              <Menu
                className="text-sm z-dropdown"
                portal
                menuButton={
                  <ExplorerMenuButton
                    options={meetingIsDraftOptions}
                    filters={filters}
                    prefix={`Draft`}
                    filterKey="meetingIsDraft"
                  />
                }
              >
                <ExplorerMenuOptions
                  options={meetingIsDraftOptions}
                  onSelectMenuItem={handleSelectMenuItem}
                  filterKey="meetingIsDraft"
                />
              </Menu>
            </div>
            <ExplorerUserPicker
              prefix="Participant"
              ariaLabel="Explorer meetingParticipants user combobox"
              className={explorerSelectClassName}
              onSelectUserId={handleChangeMeetingParticipantsFilters}
              userId={
                Array.isArray(filters.meetingParticipants) &&
                filters.meetingParticipants.length > 0
                  ? Number(filters.meetingParticipants[0])
                  : filters.meetingParticipants !== null
                  ? Number(filters.meetingParticipants)
                  : null
              }
            />
          </>
        )}

        {/* COMMON TO ARTIFACTS */}
        {filters.type && testIsAllowedArtifactType(filters.type) && (
          <ExplorerOrganizationCombobox
            prefix={label("organization", { capitalize: true })}
            ariaLabel="Explorer assignedToMembersOfOrganizationId organization combobox"
            className={explorerSelectClassName}
            onSelectOrganizationId={handleChangeFilters(
              "assignedToMembersOfOrganizationId"
            )}
            organizationId={filters.assignedToMembersOfOrganizationId}
          />
        )}
        {filters.type && testIsAllowedArtifactType(filters.type) && (
          <ExplorerMeetingGroupCombobox
            prefix="Meeting group"
            ariaLabel="Explorer meeting group combobox"
            unassignedText="None"
            className={explorerSelectClassName}
            onSelectMeetingGroupId={handleChangeMeetingGroupFilters}
            meetingGroupId={filters.meetingGroupId}
          />
        )}

        {/* Created by & between */}
        {filters.type && testIsAllowedArtifactType(filters.type) && (
          <ExplorerUserPicker
            prefix="Created by"
            ariaLabel="Explorer createdBy user combobox"
            className={explorerSelectClassName}
            onSelectUserId={handleChangeFilters("createdBy")}
            userId={filters.createdBy}
          />
        )}
        {filters.type && testIsAllowedArtifactType(filters.type) && (
          <div className="flex flex-col">
            <Menu
              className="text-sm z-dropdown"
              portal
              menuButton={
                <ExplorerMenuButton
                  options={createdBetweenDatesOptions}
                  filters={filters}
                  prefix="Created"
                  filterKey="createdBetweenDates"
                />
              }
            >
              <ExplorerMenuOptions
                options={createdBetweenDatesOptions}
                onSelectMenuItem={handleSelectMenuItem}
                filterKey="createdBetweenDates"
              />
            </Menu>
            {isCustomCreatedDateRange && filters.createdBetweenDates && (
              <div className="mt-1 flex justify-end gap-0.5 items-center">
                <ReactDatePicker
                  placeholderText="Start date"
                  selected={moment(filters.createdBetweenDates[0]).toDate()}
                  onChange={handleChangeCustomRangeStartDate}
                  dateFormat="MMM d, yyyy"
                  className={classNames(
                    explorerSelectClassName,
                    "w-24 tracking-tight py-0.5 text-xs"
                  )}
                  maxDate={moment(filters.createdBetweenDates[1]).toDate()}
                />
                <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
                <ReactDatePicker
                  selected={moment(filters.createdBetweenDates[1]).toDate()}
                  placeholderText="End date"
                  onChange={handleChangeCustomRangeEndDate}
                  dateFormat="MMM d, yyyy"
                  className={classNames(
                    explorerSelectClassName,
                    "w-24 tracking-tight py-0.5 text-xs"
                  )}
                  minDate={moment(filters.createdBetweenDates[0]).toDate()}
                />
              </div>
            )}
          </div>
        )}

        {/* Sorting & grouping */}
        {explorerIsFilteringArtifactType(filters) && (
          <div>
            <ExplorerSortOptionsMenu
              filters={filters}
              onChangeFilters={onChangeFilters}
            />
          </div>
        )}
        {isTypeAllowedForGroupBy(filters) && (
          <div>
            <ExplorerGroupByOptions
              filters={filters}
              onChangeFilters={onChangeFilters}
            />
          </div>
        )}

        {/* INTEGRATIONS */}
        {filters.type === explorerHubspotType && (
          <ExplorerHubspotStageCombobox
            prefix="Stage"
            ariaLabel="Explorer hubspot stage combobox"
            className={explorerSelectClassName}
            onSelectStageId={handleChangeHubspotStageFilters}
            stageId={filters.hubspotStageId}
          />
        )}

        {/* EXPORT */}
        {!hideExportButton && (
          <div className="mt-2 pt-4 border-t border-black/10 flex items-center gap-2">
            <ExplorerExport filters={filters} />
            <ExplorerSaveBookmarkButton filters={filters} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExplorerFilters;
