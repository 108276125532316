import { CalendarIcon } from "@heroicons/react/outline";
import { ExclamationIcon, PlayIcon } from "@heroicons/react/solid";
import { withErrorBoundary } from "@sentry/react";
import moment from "moment";
import pluralize from "pluralize";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { RiShareForwardFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import {
  FinalizePermissions,
  MeetingViewMeetingNodeFragmentFragment,
  UserStatus,
} from "types/graphql-schema";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingPresentationDialog from "@apps/meeting-presentation-dialog/meeting-presentation-dialog";
import { currentUserVar } from "@cache/cache";
import Avatars from "@components/avatar/avatars";
import Error from "@components/error/error";
import Link, { useLink } from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import useScrollPosition from "@components/use-scroll-position/use-scroll-position";
import { dateFormat } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  formatMeetingTimes,
  getUrl,
  isMobileView,
  removeCurrentYear,
} from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import getMeetingQuery from "../graphql/get-meeting-query";
import DragAndDrop from "./drag-and-drop";
import MeetingTimer from "./meeting-timer";
import MeetingVideoConferenceButton from "./meeting/video-conference-button";
import FinalizeOneonone from "./prep/finalize-oneonone";
import PrepOneonone from "./prep/prep-oneonone";
import SubmitOneonone from "./prep/submit-oneonone";
import MeetingSummary from "./prep/summary";
import ShareNotesDialog from "./share-meeting/share-meeting-notes-dialog";
import Topic from "./topic";
import TopicCreateForm from "./topic-create-form";

const Meeting = ({
  meeting,
  searchQuery = "",
  isInExtension = false, // true when this view appears in an extension
  opensTopicsInSidebar,
  currentPageMeeting = null,
  relevantSections = null,
  hasSearchUiVisibileInParent = true,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
  searchQuery?: string;
  isInExtension?: boolean; // true when this view appears in an extension
  opensTopicsInSidebar: boolean;
  currentPageMeeting?: MeetingViewMeetingNodeFragmentFragment | null;
  relevantSections?: unknown;
  hasSearchUiVisibileInParent?: boolean;
}) => {
  const currentUser = currentUserVar();
  const query = useUrlQueryParams();
  const location = useLocation();
  const link = useLink();

  const [isShowingPresentationMode, setIsShowingPresentationMode] =
    useState(false);
  const [isShowingMeetingDialogAction, setIsShowingMeetingDialogAction] =
    useState<null | meetingDialogAction>(null);
  const [isShowingInvitationDialog, setIsShowingInvitationDialog] =
    useState(false);
  const [isShowingShareNotesDialog, setIsShowingShareNotesDialog] =
    useState(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const createTopicFormRef = useRef<HTMLDivElement | null>(null);
  const meetingHeaderRef = useRef<HTMLDivElement | null>(null);
  const scrollPosition = useScrollPosition();
  const meetingDateTime = removeCurrentYear(
    moment(meeting.startDatetime).format(dateFormat.longAbbreviated)
  );

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const avatars = participants.map((node) => ({
    ...node.user,
    disabled: node.status === "declined",
    tooltipSuffix: node.status === "declined" ? " (declined)" : "",
  }));

  const inactiveUserEmails = participants
    .filter((node) => node.user?.status === UserStatus.Inactive)
    .map((node) => node.participantEmail);

  const meetingsGroupedById = {} as {
    [key: number]: MeetingViewMeetingNodeFragmentFragment;
  };
  meetingsGroupedById[meeting.id] = meeting;
  const isCurrentMeeting = meeting.id === currentPageMeeting?.id;
  const meetingHistorySearchHeight =
    document.querySelector("#js-meeting-history-search")?.offsetHeight || 0;
  const stickySearchPadding =
    isCurrentMeeting || !hasSearchUiVisibileInParent
      ? 0
      : meetingHistorySearchHeight;
  const meetingNavHeight = 56;

  const isSticky =
    !isMobileView() &&
    meetingHeaderRef.current &&
    scrollPosition + stickySearchPadding + meetingNavHeight >=
      meetingHeaderRef.current.offsetTop;

  const topics = assertEdgesNonNull(meeting.topics);
  const mandatoryTopics = topics.filter((topic) => topic.isMandatory);
  const mandatoryTopicsWithHiddenNotes = mandatoryTopics.filter((node) => {
    const hiddenIndividualNotes =
      node.individualNotes?.edges.filter(
        (edge) => edge?.node?.creator.id === currentUser.id && edge.node.hidden
      ) || [];
    return hiddenIndividualNotes.length > 0;
  });
  const showSubmitButton =
    !meeting.isSubmitted && mandatoryTopicsWithHiddenNotes.length > 0;

  const handleClickInviteInactiveParticipants = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setIsShowingInvitationDialog(true);
  };

  const handleClickOpenMeetingDialog = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsShowingMeetingDialogAction(meetingDialogAction.readonly);
  };

  const handleShareNotesDialog = () => {
    setIsShowingShareNotesDialog(true);
  };

  const handleCloseMeetingNotesDialog = () => {
    setIsShowingShareNotesDialog(false);
    setIsShowingInvitationDialog(false);
  };

  useEffect(() => {
    if (query.get("action") === "edit-meeting") {
      setIsShowingMeetingDialogAction(meetingDialogAction.edit);
      link.replace(location.pathname);
    }
  }, [query, link, location]);

  if (!meeting.meetingGroup) {
    return <Error title="This meeting does not exist." />;
  }

  return (
    <DragAndDrop meetingsGroupedById={meetingsGroupedById}>
      <div
        aria-label={`Meeting: ${meeting.title}`}
        className="bg-white rounded-none sm:rounded-lg shadow p-2 pb-3"
      >
        {isShowingMeetingDialogAction && (
          <MeetingDialog
            meetingId={meeting.id}
            meetingGroupId={meeting.meetingGroup?.id}
            onClose={() => setIsShowingMeetingDialogAction(null)}
            defaultAction={isShowingMeetingDialogAction}
            onSaved={() => setIsShowingMeetingDialogAction(null)}
            refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
          />
        )}
        {(isShowingShareNotesDialog || isShowingInvitationDialog) && (
          <ShareNotesDialog
            meeting={meeting}
            onClose={handleCloseMeetingNotesDialog}
            isInviteDialog={isShowingInvitationDialog}
          />
        )}
        {isShowingPresentationMode && (
          <MeetingPresentationDialog
            meeting={meeting}
            onClose={() => setIsShowingPresentationMode(false)}
          />
        )}
        <div
          ref={meetingHeaderRef}
          className={classNames(
            "@container/meeting-header",
            "md:sticky",
            // we create a container that never change height to prevent flickering
            // we use pointer none to allow user to click through transparent background
            // h-20 is to ensure the height is higher than the natural height of this container
            "h-10 pointer-events-none flex flex-col",
            isInExtension
              ? "top-2"
              : isCurrentMeeting || !hasSearchUiVisibileInParent
              ? "top-14"
              : "top-28",
            // we do this to force dropdown in sticky headers to appear above the sticky headers that are below in the DOM.
            // - current meeting sticky header > sticky search form > other sticky meeting headers
            // this assumes that the search form has z-index set to z-sticky-30.
            isDropdownOpened
              ? isCurrentMeeting
                ? "z-sticky-50"
                : "z-sticky-20"
              : isCurrentMeeting
              ? "z-sticky-40"
              : "z-sticky-10"
          )}
        >
          <div className="pointer-events-auto">
            <div
              className={classNames(
                "flex justify-between items-center gap-4 px-3 tracking-tight bg-gray-100",
                !isInExtension && isSticky ? "rounded-b-md" : "rounded-md"
              )}
            >
              <div
                className={classNames(
                  "flex items-center py-2 gap-4",
                  "overflow-hidden min-w-0" // don't remove it handles truncating
                )}
              >
                <Link
                  to={getUrl({
                    meetingId: meeting.id,
                    meetingGroupId: meeting.meetingGroup?.id,
                  })}
                  aria-label={`Meeting link: ${meeting.title}`}
                  target={isInExtension ? "_blank" : undefined}
                  className={classNames(
                    "overflow-hidden truncate", // don't remove: it handles truncating
                    "antialiased font-semibold text-base hover:underline",
                    "text-gray-800"
                  )}
                >
                  {meeting.startDatetime ? (
                    <>
                      <span className="inline @2xl/meeting-header:hidden">
                        {removeCurrentYear(
                          moment(meeting.startDatetime).format("ll")
                        )}
                        , {formatMeetingTimes(meeting)}
                      </span>
                      <span className="hidden @2xl/meeting-header:inline tracking-tight">
                        {removeCurrentYear(meetingDateTime)},{" "}
                        {formatMeetingTimes(meeting)}
                      </span>
                    </>
                  ) : (
                    <span className="flex items-center gap-1 tracking-tight">
                      <CalendarIcon className="h-4 w-4 text-gray-400" />
                      Unscheduled
                    </span>
                  )}
                </Link>
                {meeting.startDatetime && meeting.endDatetime && (
                  <MeetingTimer
                    id={meeting.id}
                    startDatetime={meeting.startDatetime}
                    endDatetime={meeting.endDatetime}
                  />
                )}
              </div>
              <div className="text-sm flex align-items gap-1.5 shrink-0 min-w-6">
                <div className="flex justify-between items-center gap-1.5">
                  {!meeting.draft && (
                    <Tooltip text="Share notes">
                      <button
                        className="flex items-center gap-1 rounded px-2 py-1 text-gray-700 hover:bg-black/5"
                        onClick={handleShareNotesDialog}
                      >
                        <RiShareForwardFill className="h-5 w-5" />
                        <span className="hidden @3xl/meeting-header:inline @5xl/meeting-header:hidden">
                          Share
                        </span>
                        <span className="hidden @5xl/meeting-header:inline">
                          Share notes
                        </span>
                      </button>
                    </Tooltip>
                  )}
                  <MeetingVideoConferenceButton
                    meeting={meeting}
                    className="rounded text-gray-700 hover:bg-black/5 px-2 py-1 gap-1"
                    textClassName="hidden @3xl/meeting-header:inline-flex @5xl/meeting-header:hidden"
                    compact
                  />
                  <Tooltip text="Present meeting notes">
                    <button
                      className="flex items-center gap-1 rounded px-2 py-1 text-gray-700 hover:bg-black/5"
                      onClick={() => setIsShowingPresentationMode(true)}
                    >
                      <PlayIcon className="h-5 w-5" />
                      <span className="hidden @3xl/meeting-header:inline">
                        Present
                      </span>
                    </button>
                  </Tooltip>
                </div>

                <div className="flex items-center px-1 gap-0.5">
                  <button onClick={handleClickOpenMeetingDialog}>
                    <Avatars
                      users={avatars}
                      className={classNames(
                        "flex items-center",
                        avatars.length === 1 ? "w-6" : "pl-2" // otherwise single avatar gets squished
                      )}
                      avatarClassName="contrast-100 rounded-full ring-2 -ml-2 ring-gray-100"
                      extraClassName="w-6 h-6 rounded-full -ml-2 text-2xs flex items-center justify-center z-1 bg-gray-200 text-gray-800"
                      size="6"
                      max={3}
                      highlightSearchQuery={searchQuery}
                    />
                  </button>
                  {!isInExtension &&
                    isCurrentMeeting &&
                    inactiveUserEmails.length > 0 && (
                      <Tooltip
                        text={`${inactiveUserEmails.length} ${pluralize(
                          "guest",
                          inactiveUserEmails.length
                        )} in this meeting ${pluralize(
                          "is",
                          inactiveUserEmails.length
                        )}n't ${
                          inactiveUserEmails.length === 1 ? "a" : ""
                        } Topicflow  ${pluralize(
                          "user",
                          inactiveUserEmails.length
                        )}`}
                      >
                        <button
                          aria-label="Invite non registered users to meeting button"
                          className="py-0.5 px-1 flex items-center gap-px rounded text-xs text-gray-700 hover:bg-black/5"
                          onClick={handleClickInviteInactiveParticipants}
                        >
                          <ExclamationIcon className="mt-px h-5 w-5 text-yellow-500" />
                          {inactiveUserEmails.length}
                        </button>
                      </Tooltip>
                    )}
                </div>
                <MeetingDropdownMenu
                  meeting={meeting}
                  meetingGroup={meeting.meetingGroup}
                  className="rounded px-0.5 z-dropdown hover:bg-black/5"
                  size="5"
                  onMenuChange={({ open }: { open: boolean }) => {
                    setIsDropdownOpened(open);
                  }}
                  onOpenSendNotesDialog={handleShareNotesDialog}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {meeting.meetingGroup?.isFormalOneonone && (
            <PrepOneonone meeting={meeting} />
          )}
          <div className={waffle.flag_is_active("meeting-prep") ? "" : "mt-3"}>
            <Droppable droppableId={`meeting-${meeting.id}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {topics.length > 0 && (
                    <ul
                      className="fs-mask border-t border-b  divide-gray-200 divide-y -mx-2"
                      aria-label="Topic list"
                    >
                      {topics.map((topic, index) => (
                        <Topic
                          key={topic.id}
                          index={index}
                          topic={topic}
                          meeting={meeting}
                          opensInSidebar={opensTopicsInSidebar}
                          currentPageMeeting={currentPageMeeting}
                          searchQuery={searchQuery}
                          relevantSections={relevantSections}
                        />
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                  <div ref={createTopicFormRef} className="-mx-2">
                    <TopicCreateForm
                      key={`create-topic-form-${meeting.id}`}
                      meetingGroup={meeting.meetingGroup}
                      meeting={meeting}
                    />
                  </div>
                </div>
              )}
            </Droppable>
          </div>
          <div className="flex flex-col gap-3 text-sm -mx-2 px-2 pt-3 border-t border-gray-200">
            {meeting.meetingGroup?.isFormalOneonone && !showSubmitButton && (
              <FinalizeOneonone
                finalizePermission={
                  meeting.meetingGroup?.topicTemplate?.finalizePermissions ||
                  FinalizePermissions.FacilitatorsAndAdmins
                }
                meetingId={meeting.id}
                topics={meeting.topics}
                canFinalize={meeting.canFinalize}
                isFinalized={meeting.isFinalized}
                finalizedAt={meeting.finalizedAt}
                finalizedBy={meeting.finalizedBy}
                hasIncompleteMandatoryTopics={
                  meeting.hasIncompleteMandatoryTopics
                }
              />
            )}
            {meeting.meetingGroup?.isFormalOneonone && showSubmitButton && (
              <SubmitOneonone meetingId={meeting.id} />
            )}
            <MeetingSummary
              meeting={meeting}
              meetingGroupId={meeting.meetingGroup.id}
            />
          </div>
        </div>
      </div>
    </DragAndDrop>
  );
};

export default withErrorBoundary(Meeting, {
  fallback: <Error description={"The meeting could not be rendered."} />,
});
