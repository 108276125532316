import { gql } from "@apollo/client";

export default gql`
  mutation emailMeetingNotesMutation(
    $meetingId: Int!
    $recipientEmails: [String]!
    $message: String
  ) {
    emailMeetingNotes(
      meetingId: $meetingId
      recipientEmails: $recipientEmails
      message: $message
    ) {
      meeting {
        id
      }
    }
  }
`;
