import { useMutation } from "@apollo/client";
import { MenuItem } from "@szhsin/react-menu";
import { TbToggleLeft, TbToggleRightFilled } from "react-icons/tb";

import toggleTopicIndividualNoteVisibilityMutation from "@apps/meeting/graphql/toggle-topic-individual-note-visibility-mutation";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

const TogglePersonalNotesMenuItem = ({
  topic,
  meeting,
  meetingGroup,
  currentPageMeeting,
  ...props
}: {
  topic: any;
  meeting: any;
  meetingGroup?: any;
  currentPageMeeting?: any;
}) => {
  const [toggleIndividualNoteVisibility] = useMutation(
    toggleTopicIndividualNoteVisibilityMutation
  );

  const handleToggleIndividualNotes = () => {
    toggleIndividualNoteVisibility({
      variables: {
        topicIds: [topic.id],
        enabled: !topic.includesIndividualNotesForCurrentUser,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  // Render
  return (
    <MenuItem
      className="text-sm"
      onClick={handleToggleIndividualNotes}
      {...props}
    >
      {topic.includesIndividualNotesForCurrentUser ? (
        <TbToggleRightFilled className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ) : (
        <TbToggleLeft className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      )}{" "}
      {topic.includesIndividualNotesForCurrentUser ? "Hide" : "Show"} my notes
    </MenuItem>
  );
};

export default TogglePersonalNotesMenuItem;
