import { XIcon } from "@heroicons/react/outline";
import { MouseEventHandler } from "react";

const CloseButton = ({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      type="button"
      className="bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
      aria-label="Close"
      onClick={onClick}
    >
      <span className="sr-only">Close</span>
      <XIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default CloseButton;
