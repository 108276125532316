import { gql } from "@apollo/client";

import WorkHistoryGoalUpdateFragment from "./work-history-goal-update-fragment";

const MyWorkHistoryItemFragment = gql`
  ${WorkHistoryGoalUpdateFragment}
  fragment MyWorkHistoryItemFragment on WorkHistorySummaryNode {
    id
    summary
    modifiedSummary
    meetingCount
    meetingTimeHours
    created
    summaryStartDate
    summaryEndDate
    externalEventData
    goalUpdates {
      edges {
        node {
          ...WorkHistoryGoalUpdateFragment
        }
      }
    }
    subject {
      id
      name
      avatar
    }
    sections {
      edges {
        node {
          id
          title
          modifiedTitle
          content
          modifiedContent
          hidden
        }
      }
    }
  }
`;

export default MyWorkHistoryItemFragment;
