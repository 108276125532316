import {
  ArrowSmRightIcon,
  LinkIcon,
  PencilAltIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import pluralize from "pluralize";
import { PropsWithChildren, useState } from "react";
import {
  GoalArtifactSidebarFragmentFragment,
  GoalScope,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import SidebarLabel from "@apps/artifact-sidebar/components/sidebar-label";
import SidebarRow from "@apps/artifact-sidebar/components/sidebar-row";
import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import ArtifactTeams from "@apps/artifact/components/artifact-teams";
import GoalParticipantsDialog from "@apps/artifact/components/goal-participants-dialog";
import GoalScopeComponent from "@apps/artifact/components/goal-scope";
import GoalStartDate from "@apps/artifact/components/goal-start-date";
import GoalState from "@apps/artifact/components/goal-state";
import GoalVisibilityDropdown from "@apps/artifact/components/goal-visibility";
import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import useLabel from "@apps/use-label/use-label";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import GoalProgressBar from "@components/goal-progress-bar/goal-progress-bar";
import StartCurrentTargetTooltip from "@components/tooltip/start-current-target-tooltip";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import ArtifactSidebarAlignedGoalItem from "./aligned-goal-item";
import ArtifactSidebarGoalPicker from "./aligned-goal-picker";
import GoalEditValues from "./components/goal-edit-values";
import GoalKeyResultList from "./components/goal-key-result-list";

const SidebarContainer = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={classNames("flex w-full flex-col gap-3 bg-white", className)}>
    {children}
  </div>
);
const SidebarContainer2Columns = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={classNames(
      "grid sm:grid-cols-2 w-full gap-3 bg-white",
      className
    )}
  >
    {children}
  </div>
);

const GoalParticipantRow = ({
  participants,
  canUpdate,
  onClick,
  label = "Owner",
}: {
  participants: BasicUser[];
  canUpdate: boolean;
  onClick: () => void;
  label: string;
}) => (
  <SidebarRow>
    <SidebarLabel>{pluralize(label, 2)}</SidebarLabel>
    <div className="flex items-center gap-1">
      <button
        className="flex items-center gap-2 px-2 py-1 group rounded hover:bg-gray-100"
        onClick={onClick}
        disabled={!canUpdate}
      >
        {participants.length > 0 && (
          <Avatars
            className={classNames(
              "flex items-center pl-1.5",
              participants.length === 1 && "w-6" // otherwise single avatar gets squished
            )}
            avatarClassName={classNames(
              "contrast-100 rounded-full ring-2 -ml-1.5 ring-white",
              "bg-gray-600 text-gray-200"
            )}
            extraClassName="w-6 h-6 rounded-full -ml-2 text-2xs flex items-center justify-center z-1 bg-gray-200"
            users={participants}
            max={2}
            size="6"
          />
        )}
        {participants.length > 0 && (
          <span className="text-gray-400 text-xs">
            {participants.length}{" "}
            {pluralize(label.toLowerCase(), participants.length)}
          </span>
        )}
        {participants.length === 0 && (
          <span className="text-gray-400 text-xs">None</span>
        )}
        {canUpdate && (
          <div>
            <PencilAltIcon
              className="w-5 h-5 text-gray-400 flex-none opacity-0 group-hover:opacity-100"
              aria-label={`Edit ${label.toLowerCase()}s button`}
            />
          </div>
        )}
      </button>
    </div>
  </SidebarRow>
);

const ArtifactSidebarGoalSection = ({
  artifact,
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
}) => {
  const label = useLabel();
  const [isEditingAlignment, setIsEditingAlignment] = useState(false);
  const [isShowingCheckinDialog, setIsShowingCheckinDialog] = useState(false);
  const [isEditingGoalValues, setIsEditingGoalValues] = useState(false);
  const [showParticipantDialog, setShowParticipantDialog] = useState(false);

  const childGoals = artifact?.childGoals
    ? assertEdgesNonNull(artifact.childGoals)
    : [];
  const owners = artifact?.owners ? assertEdgesNonNull(artifact.owners) : [];
  const contributors = artifact?.contributors
    ? assertEdgesNonNull(artifact.contributors)
    : [];

  return (
    <>
      {showParticipantDialog && (
        <GoalParticipantsDialog
          artifact={artifact}
          onClose={() => setShowParticipantDialog(false)}
        />
      )}
      {isShowingCheckinDialog && (
        <CheckinDialog
          artifact={artifact}
          onClose={() => setIsShowingCheckinDialog(false)}
        />
      )}
      <div className="flex flex-col gap-4 bg-white">
        <SidebarContainer2Columns>
          <SidebarRow>
            <SidebarLabel>Type</SidebarLabel>
            <GoalScopeComponent artifact={artifact} className="" />
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>State</SidebarLabel>
            <GoalState artifact={artifact} className="text-sm" />
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>Visibility</SidebarLabel>
            <GoalVisibilityDropdown artifact={artifact} />
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>Dates</SidebarLabel>
            <div className="flex items-center text-sm tracking-tighter gap-0.5">
              <div className="group">
                <GoalStartDate
                  artifact={artifact}
                  size="4"
                  alwaysShowCalendarIcon
                  className="text-sm py-1 px-2 gap-1 tracking-tighter capitalize"
                  labelName="start date"
                  emptyValue="Start"
                  tooltipPrefix={artifact.startDate ? "Start date: " : ""}
                  maxDate={
                    artifact.dueDate ? moment(artifact.dueDate).toDate() : null
                  }
                />
              </div>
              <ArrowSmRightIcon className="h-4 w-4 text-gray-400" />
              <div className="group">
                <ArtifactDueDate
                  artifact={artifact}
                  size="4"
                  alwaysShowCalendarIcon
                  labelName="due date"
                  className="text-sm py-1 px-2 gap-1 tracking-tighter capitalize"
                  emptyValue="Due"
                  tooltipPrefix={artifact.dueDate ? "Due date: " : ""}
                  minDate={
                    artifact.startDate
                      ? moment(artifact.startDate).toDate()
                      : null
                  }
                />
              </div>
            </div>
          </SidebarRow>
          <GoalParticipantRow
            participants={owners}
            canUpdate={artifact.canUpdate?.permission}
            onClick={() => {
              setShowParticipantDialog(true);
            }}
            label="Owner"
          />
          <GoalParticipantRow
            participants={contributors}
            canUpdate={artifact.canUpdate?.permission}
            onClick={() => {
              setShowParticipantDialog(true);
            }}
            label="Contributor"
          />
        </SidebarContainer2Columns>
        <SidebarContainer>
          {artifact.scope === GoalScope.Team && (
            <SidebarRow>
              <SidebarLabel>Teams</SidebarLabel>
              <div className="pl-2">
                <ArtifactTeams artifact={artifact} />
              </div>
            </SidebarRow>
          )}
        </SidebarContainer>

        {/* PROGRESS */}
        <div className="px-4 sm:px-6 pt-4 py-8 bg-white flex flex-col gap-4 items-start text-gray-700 border-t">
          <div className="w-full flex justify-between items-center">
            <div className="text-xs uppercase">
              {label("goal", { capitalize: true })} progress
            </div>
            {artifact.canUpdate.permission && (
              <div className="flex gap-2 items-center">
                <Button
                  text="Update progress"
                  theme={buttonTheme.roundBluePrimary}
                  mini
                  onClick={() => setIsShowingCheckinDialog(true)}
                />
              </div>
            )}
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-6">
              {isEditingGoalValues ? (
                <div className="flex flex-col gap-2">
                  <GoalEditValues
                    artifact={artifact}
                    onClose={() => setIsEditingGoalValues(false)}
                  />
                </div>
              ) : (
                <div className="w-full flex items-center gap-3">
                  <div className="flex-1">
                    <StartCurrentTargetTooltip goalOrKeyresult={artifact}>
                      <div className="h-6">
                        <GoalProgressBar
                          goal={artifact}
                          canUpdateProgress={artifact.canUpdate.permission}
                          onClickUpdateProgress={() =>
                            setIsShowingCheckinDialog(true)
                          }
                        />
                      </div>
                    </StartCurrentTargetTooltip>
                  </div>
                  {artifact.kpi && (
                    <Tooltip text={`Connected to KPI: ${artifact.kpi.title}`}>
                      <div className="flex items-center">
                        <Button
                          theme={buttonTheme.iconGray}
                          to={`/kpis/${artifact.kpi.id}`}
                          icon
                        >
                          <LinkIcon className="w-4 h-4" />
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  {artifact.canUpdate.permission && (
                    <Button
                      theme={buttonTheme.iconGray}
                      aria-label="Edit goal values"
                      icon
                      onClick={() => setIsEditingGoalValues(true)}
                    >
                      <PencilIcon className="w-4 h-4" />
                    </Button>
                  )}
                </div>
              )}
              <GoalKeyResultList goalArtifact={artifact} />
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6 pt-4 py-8 bg-white flex flex-col gap-4 items-start text-gray-700 border-t">
          <div className="w-full flex justify-between items-center">
            <div className="text-xs uppercase">
              {label("goal", { capitalize: true })} alignment
            </div>
            {artifact.canUpdate.permission && (
              <div className="flex gap-2 items-center">
                <Button
                  text="Edit alignment"
                  theme={buttonTheme.roundBluePrimary}
                  mini
                  onClick={() => setIsEditingAlignment(!isEditingAlignment)}
                />
              </div>
            )}
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium">Align up</div>
                {artifact.parentGoal ? (
                  <div className="divide-y border-t border-b empty:hidden">
                    <ArtifactSidebarAlignedGoalItem
                      artifact={artifact}
                      alignedGoal={artifact.parentGoal}
                      isEditingAlignment={isEditingAlignment}
                      alignmentType="parent"
                    />
                  </div>
                ) : (
                  !isEditingAlignment && (
                    <div className="text-gray-400 text-xs">
                      No {label("goal")}.
                    </div>
                  )
                )}

                {isEditingAlignment && (
                  <ArtifactSidebarGoalPicker
                    artifact={artifact}
                    alignmentType="parent"
                  />
                )}
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium">Align down</div>
                {childGoals.length > 0 ? (
                  <div className="divide-y border-t border-b empty:hidden">
                    {childGoals.map((childGoal) => (
                      <ArtifactSidebarAlignedGoalItem
                        key={childGoal.id}
                        artifact={artifact}
                        alignedGoal={childGoal}
                        alignmentType="child"
                        isEditingAlignment={isEditingAlignment}
                      />
                    ))}
                  </div>
                ) : (
                  !isEditingAlignment && (
                    <div className="text-gray-400 text-xs">
                      No {label("goal", { pluralize: true })}.
                    </div>
                  )
                )}
                {isEditingAlignment && (
                  <ArtifactSidebarGoalPicker
                    artifact={artifact}
                    alignmentType="child"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtifactSidebarGoalSection;
