import { ArrowUpIcon, SearchIcon } from "@heroicons/react/outline";
import { ChangeEventHandler, useRef } from "react";

import Select from "@components/select/select";
import useScrollPosition from "@components/use-scroll-position/use-scroll-position";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { isMobileView } from "@helpers/helpers";

import { searchContextOptions } from "../helpers";

export const searchStickyHeight = 14;

const Search = ({
  searchQuery,
  searchContext,
  showContextSelector,
  onChangeSearchQuery,
  onChangeSearchContext,
}: {
  searchQuery: any;
  searchContext: any;
  showContextSelector: any;
  onChangeSearchQuery: any;
  onChangeSearchContext: any;
}) => {
  const searchRef = useRef<any | null>(null);
  const scrollPosition = useScrollPosition();
  const heightOfShrinkedSearch = 56;
  const meetingNavHeight = 56;
  const isSticky =
    !isMobileView() &&
    searchRef.current &&
    scrollPosition - heightOfShrinkedSearch + meetingNavHeight >=
      searchRef.current.parentNode?.offsetTop;

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeSearchQuery(e.target.value);
  };
  return (
    <div
      ref={searchRef}
      className={classNames(
        // we create a container that never change height to prevent flickering
        // we use pointer none to allow user to click through transparent background
        "h-24 pointer-events-none mt-12 mb-6", // h-24 is just to make sure it is bigger than natural search container height
        "md:sticky top-14 z-sticky-30" // has to have sticky z-index between current meeting header z-index and other sticky meeting headers z-index.
      )}
    >
      <div
        className="-mx-1 px-1 pointer-events-auto"
        aria-label="Meeting history search container"
        id="js-meeting-history-search"
      >
        <div
          className={classNames(
            isSticky &&
              `h-${searchStickyHeight} flex items-center border-b gap-2 bg-gray-100 px-2`
          )}
        >
          {!isSticky && (
            <div className="flex mb-2 text-gray-700 font-medium">
              Meeting history
            </div>
          )}
          <div className="flex justify-between items-center flex-1">
            <div
              className={classNames(
                "flex-1 flex gap-4 items-center flex-row px-3 md:px-0"
              )}
            >
              {isSticky && (
                <span className="text-gray-700 font-medium text-sm">
                  Meeting history
                </span>
              )}
              <div className="relative shrink md:max-w-52 text-sm">
                <input
                  placeholder="Search"
                  className={classNames(
                    "w-full block rounded-full pr-4 pl-10 py-1 text-sm",
                    inputBorderClassName,
                    inputFocusClassName
                  )}
                  aria-label="Meeting history search input"
                  value={searchQuery}
                  onChange={handleInput}
                />
                <div className="absolute top-0 left-0 bottom-0 ml-3 w-5 flex items-center">
                  <SearchIcon className="w-5 h-5 text-gray-400" />
                </div>
              </div>
              {showContextSelector && (
                <Select
                  className="rounded-full pl-4 py-1 text-sm flex-1 md:w-52"
                  options={searchContextOptions}
                  value={searchContext}
                  onChange={({ value }) => onChangeSearchContext(value)}
                  aria-label="Meeting history search context select"
                />
              )}
            </div>
          </div>
          {isSticky && !isMobileView() && (
            <button
              onClick={handleScrollToTop}
              className="shrink-0 bg-gray-800 hover:bg-gray-900 text-gray-200 hover:text-gray-50 text-sm px-2 py-0.5 rounded flex items-center gap-2 min-h-6"
            >
              <ArrowUpIcon className="h-3 w-3" />
              <span className="hidden xl:inline">To current</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
