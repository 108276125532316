import { intersection } from "lodash";
import {
  CreatedArtifactFragmentFragment,
  GetDashboardGoalsQueryQueryVariables,
  GetDashboardRecognitionsQueryQueryVariables,
  GetMeetingGoalsQueryQueryVariables,
  GetMeetingRecognitionsQueryQueryVariables,
} from "types/graphql-schema";

import { refetchActionItemsInCollapsibleContainers } from "@apps/action-items-collapsible/helpers";
import getDashboardGoalsQuery from "@apps/dashboard/graphql/get-dashboard-goals-query";
import getDashboardRecognitionsQuery from "@apps/dashboard/graphql/get-dashboard-recognitions-query";
import getMeetingGoalsQuery from "@apps/meeting/graphql/get-meeting-goals-query";
import getMeetingRecognitionsQuery from "@apps/meeting/graphql/get-meeting-recognitions-query";
import { getWatchedQueries } from "@graphql/client";

export const updateArtifactsCacheWithCreatedArtifact = (
  createdArtifact: CreatedArtifactFragmentFragment
) => {
  // Refresh watched queries
  if (createdArtifact.__typename === "ActionItemArtifactNode") {
    refetchActionItemsInCollapsibleContainers(createdArtifact);
  }
  if (createdArtifact.__typename === "GoalArtifactNode") {
    const watchedQueries = getWatchedQueries(getDashboardGoalsQuery);
    watchedQueries.forEach((watchedQuery) => {
      const watchedQueryVariables = watchedQuery.options
        .variables as GetDashboardGoalsQueryQueryVariables;
      if (
        watchedQueryVariables.goalScopes &&
        watchedQueryVariables.goalScopes.includes(createdArtifact.scope)
      ) {
        const size = (
          watchedQuery.getCurrentResult().data?.goalsForUser.edges || []
        ).length;
        const variables = {
          ...watchedQueryVariables,
          after: null,
          first: size + 1,
        };
        watchedQuery.fetchMore({ variables });
      }
    });
  }
  if (createdArtifact.__typename === "GoalArtifactNode") {
    const watchedQueries = getWatchedQueries(getMeetingGoalsQuery);
    watchedQueries.forEach((watchedQuery) => {
      const watchedQueryVariables = watchedQuery.options
        .variables as GetMeetingGoalsQueryQueryVariables;
      if (watchedQueryVariables.goalState === createdArtifact.goalState) {
        const size = (
          watchedQuery.getCurrentResult().data?.artifacts.edges || []
        ).length;
        const variables = {
          ...watchedQueryVariables,
          after: null,
          first: size + 1,
        };
        watchedQuery.fetchMore({ variables });
      }
    });
  }
  if (createdArtifact.__typename === "RecognitionArtifactNode") {
    const recipientIds =
      createdArtifact.recipients?.edges.map((edge) => edge?.node?.id) || [];
    const watchedQueries = getWatchedQueries(getDashboardRecognitionsQuery);
    watchedQueries.forEach((watchedQuery) => {
      const watchedQueryVariables = watchedQuery.options
        .variables as GetDashboardRecognitionsQueryQueryVariables;
      if (
        watchedQueryVariables.createdBy === createdArtifact.creator?.id ||
        (watchedQueryVariables.recognitionRecipient &&
          recipientIds.includes(watchedQueryVariables.recognitionRecipient))
      ) {
        const size = (
          watchedQuery.getCurrentResult().data?.artifacts.edges || []
        ).length;
        const variables = {
          ...watchedQueryVariables,
          after: null,
          first: size + 1,
        };
        watchedQuery.fetchMore({ variables });
      }
    });
  }
  if (createdArtifact.__typename === "RecognitionArtifactNode") {
    const recipientIds =
      createdArtifact.recipients?.edges.map((edge) => edge?.node?.id) || [];
    const watchedQueries = getWatchedQueries(getMeetingRecognitionsQuery);
    watchedQueries.forEach((watchedQuery) => {
      const watchedQueryVariables = watchedQuery.options
        .variables as GetMeetingRecognitionsQueryQueryVariables;
      if (
        (createdArtifact.creator?.id &&
          Array.isArray(watchedQueryVariables.createdByIds) &&
          watchedQueryVariables.createdByIds.includes(
            createdArtifact.creator.id
          )) ||
        (Array.isArray(watchedQueryVariables.recognitionRecipients) &&
          intersection(
            watchedQueryVariables.recognitionRecipients || [],
            recipientIds
          ).length > 0)
      ) {
        const size = (
          watchedQuery.getCurrentResult().data?.artifacts.edges || []
        ).length;
        const variables = {
          ...watchedQueryVariables,
          after: null,
          first: size + 1,
        };
        watchedQuery.fetchMore({ variables });
      }
    });
  }
};
