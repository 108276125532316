import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { ReactElement } from "react";
import {
  GetDashboardRecognitionsQueryQuery,
  GetDashboardRecognitionsQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardRecognitionsQuery from "../graphql/get-dashboard-recognitions-query";

const RecognitionsCollapsibleContainer = ({
  title,
  filters,
  titleRightSide = null,
  localStorageKey,
}: {
  title: string;
  localStorageKey: keyof UiPreferenceCache;
  roundedBottom?: boolean;
  filters: GetDashboardRecognitionsQueryQueryVariables;
  titleRightSide?: ReactElement | null;
}) => {
  const label = useLabel();
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { loading, data, fetchMore } = useQuery<
    GetDashboardRecognitionsQueryQuery,
    GetDashboardRecognitionsQueryQueryVariables
  >(getDashboardRecognitionsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { after: null, ...filters },
    skip: !uiPreferenceCache[localStorageKey],
    onError: onNotificationErrorHandler(),
  });
  const recognitions = data?.artifacts
    ? assertEdgesNonNull(data.artifacts)
    : [];

  const handleClickMore = () => {
    if (data?.artifacts) {
      fetchMore({
        variables: {
          after: data.artifacts.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={localStorageKey}
      title={title}
      titleRightSide={titleRightSide}
      loading={!data && loading}
      emptyPlaceholder={
        recognitions.length === 0 &&
        `No ${label("recognition", { pluralize: true })}.`
      }
    >
      <Layout.SidebarSubSectionList
        aria-label={`${title} recognition dashboard container`}
        loading={loading}
        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {recognitions.map(
          (recognition) =>
            recognition.__typename === "RecognitionArtifactNode" && (
              <Layout.SidebarSubSectionListItem key={recognition.id}>
                <Artifact artifact={recognition} />
              </Layout.SidebarSubSectionListItem>
            )
        )}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default Sentry.withErrorBoundary(RecognitionsCollapsibleContainer, {
  fallback: <Error description={"The recognitions could not be rendered."} />,
});
