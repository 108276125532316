import { useQuery } from "@apollo/client";
import { Fragment, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  GetMeetingRecognitionsQueryQuery,
  GetMeetingRecognitionsQueryQueryVariables,
  MeetingViewMeetingNodeFragmentFragment,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import getMeetingRecognitionsQuery from "@apps/meeting/graphql/get-meeting-recognitions-query";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import CollapsibleEmpty from "@components/collapsible-container/collapsible-empty";
import CollapsibleLoading from "@components/collapsible-container/collapsible-loading";
import CollapsiblePaginate from "@components/collapsible-container/collapsible-paginate";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

const MeetingRecognitionCollapsible = ({
  meeting,
  collapsibleToggleKey,
  title,
  roundedBottom = false,
  variables,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
  collapsibleToggleKey: keyof UiPreferenceCache;
  title: string;
  variables: GetMeetingRecognitionsQueryQueryVariables;
  roundedBottom?: boolean;
}) => {
  const meetingGroup = meeting.meetingGroup;
  const { url } = useRouteMatch();
  const label = useLabel();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();

  const { data, fetchMore, loading } = useQuery<
    GetMeetingRecognitionsQueryQuery,
    GetMeetingRecognitionsQueryQueryVariables
  >(getMeetingRecognitionsQuery, {
    variables,
    skip: !uiPreferenceCache[`${collapsibleToggleKey}`],
    onError: onNotificationErrorHandler(),
  });

  const handleToggleCollapsible = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ [`${collapsibleToggleKey}`]: isOpen });
    },
    [saveUiPreference, collapsibleToggleKey]
  );

  const handleClickMore = () => {
    fetchMore({
      variables: {
        after: data?.artifacts?.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  const recognitions = data?.artifacts
    ? assertEdgesNonNull(data.artifacts)
    : [];

  return (
    <CollapsibleContainer
      container={Fragment}
      startOpen={uiPreferenceCache[`${collapsibleToggleKey}`]}
      roundedBottom={roundedBottom}
      title={title}
      onToggle={handleToggleCollapsible}
    >
      {!data && loading ? (
        <CollapsibleLoading roundedBottom />
      ) : recognitions.length === 0 ? (
        <CollapsibleEmpty>
          No {label("recognition", { pluralize: true })}.
        </CollapsibleEmpty>
      ) : (
        <div className="divide-y divide-gray-100">
          {recognitions.map((recognition) =>
            recognition.__typename === "RecognitionArtifactNode" ? (
              <div key={recognition.id} className="px-4 py-2">
                <Artifact
                  artifact={recognition}
                  meetingGroup={meetingGroup}
                  urlPrefix={url}
                />
              </div>
            ) : null
          )}
          <CollapsiblePaginate
            loading={loading}
            hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
            onClickMore={handleClickMore}
          />
        </div>
      )}
    </CollapsibleContainer>
  );
};

export default MeetingRecognitionCollapsible;
