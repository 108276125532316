import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import GoogleCalendarLogo from "@static/img/google-calendar-logo.svg";
import MicrosoftCalendarLogo from "@static/img/microsoft-calendar-logo.svg";
import { useState } from "react";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import Modal from "@components/modal/modal";
import { getAssetUrl } from "@helpers/helpers";

const CalendarConnectCTA = ({ organizationId, redirectUrl }) => {
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    useState(false);

  const handleClickConnectCalendar = () => {
    setShowConnectCalendarModal(true);
  };

  return (
    <div>
      <Modal open={showConnectCalendarModal}>
        <div
          className="p-6 flex justify-between items-start"
          aria-label="Connect calendar modal"
        >
          <div className="flex-1">
            <CalendarConnectNew
              extraUrl={organizationId ? `&org_id=${organizationId}` : ""}
              next={redirectUrl}
            />
          </div>
          <button
            onClick={() => setShowConnectCalendarModal(false)}
            className="p-1 rounded hover:bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <XIcon className="w-7 h-7" />
          </button>
        </div>
      </Modal>

      <button
        className="w-full items-center mt-4 text-blue-600 border border-dashed border-gray-300 rounded-md px-4 py-2 flex justify-between bg-gray-50 text-base hover:border-solid hover:border-blue-600"
        onClick={handleClickConnectCalendar}
      >
        <div className="flex items-center gap-2">
          <PlusCircleIcon className="w-5 h-5" />
          Connect a calendar
        </div>
        <div className="flex items-center gap-2">
          <img
            src={getAssetUrl(GoogleCalendarLogo)}
            className="w-7 h-7"
            alt="Google calendar logo"
          />
          <img
            src={getAssetUrl(MicrosoftCalendarLogo)}
            className="w-10 h-10"
            alt="Microsoft calendar logo"
          />
        </div>
      </button>
    </div>
  );
};

export default CalendarConnectCTA;
