import { ErrorBoundary } from "@sentry/react";
import { useInView } from "react-intersection-observer";
import {
  MeetingViewMeetingNodeFragmentFragment,
  TopicNodeFragmentFragment,
} from "types/graphql-schema";

import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import WYSIWYG from "@components/wysiwyg/wysiwyg";
import { tempCacheIdPrefix } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { parseStringToJSON } from "@helpers/helpers";

import { handleEditorPropsOnKeyDown } from "../helpers";

const DiscussionNotes = ({
  topic,
  meeting,
  searchQuery,
}: {
  topic: TopicNodeFragmentFragment;
  meeting: MeetingViewMeetingNodeFragmentFragment;
  searchQuery: string;
}) => {
  const isTemporary = String(topic.id).includes(tempCacheIdPrefix);

  const topicValue = parseStringToJSON(topic.discussionNotes);
  let overlayValue = null;
  if (topic.linkedTemplateTopic?.id) {
    if (isEmptyValue(topicValue) && topic.linkedTemplateTopic.discussionNotes) {
      overlayValue = parseStringToJSON(
        topic.linkedTemplateTopic.discussionNotes
      );
    }
  }

  // Detect if discussion notes container is visible
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "250px 0px 0px 0px",
  });

  return (
    <div ref={ref} aria-label="Meeting topic shared notes">
      {inView && (
        <div>
          {isTemporary ? (
            <div className="pb-4">
              <Loading size="5" />
            </div>
          ) : (
            <ErrorBoundary
              fallback={
                <Error
                  title="An unexpected error occurred."
                  description={
                    "The editor could not be rendered. We have been notified. Please refresh the page."
                  }
                />
              }
            >
              <div id={`editor-topic-${topic.id}`}>
                <WYSIWYG
                  key={`topic-${topic.id}`}
                  value={topicValue}
                  enableComment={topic.canUpdate.permission}
                  className={classNames(
                    "text-base",
                    // important to have padding so the +/drag hover buttons on each line is clickable and we can detect correctly when user's mouse leaves the editor.
                    // the buttons need to be inside the editor.
                    "pl-meetingLeftMargin py-0.5 pr-4",
                    topic.canUpdate.permission && "min-h-24",
                    "pb-6" // adding bottom padding here so the white space is clickable
                  )}
                  editable={topic.canUpdate.permission}
                  placeholder="Add meeting notes, '/' for commands"
                  showPlusButton={topic.canUpdate.permission}
                  editorProps={{
                    handleDOMEvents: {
                      keydown: handleEditorPropsOnKeyDown,
                    },
                  }}
                  highlightSearchQuery={searchQuery}
                  uploadVariable={{ topicId: topic.id }}
                  overlayValue={overlayValue}
                  websocketConfig={{
                    socketId: `meeting-${meeting.id}`,
                    documentId: `topic-${topic.id}`,
                    websocketToken: meeting.websocketToken || "",
                  }}
                  mentionsConfig={{
                    meetingGroupId: meeting.meetingGroup?.id,
                    meetingId: meeting.id,
                  }}
                  extraContext={{
                    topicId: topic.id,
                    relatedTopicId: topic.id,
                    meetingId: meeting.id,
                    meetingDate: meeting.startDatetime || undefined,
                    meetingGroupId: meeting.meetingGroup?.id || undefined,
                    organizationId: meeting.organization?.id,
                  }}
                />
              </div>
            </ErrorBoundary>
          )}
        </div>
      )}
    </div>
  );
};

export default DiscussionNotes;
