import { Editor } from "@tiptap/core";

import { parseStringToJSON } from "@helpers/helpers";

import WYSIWYG from "./wysiwyg";

export default function ArtifactWYSIWYG({
  artifact,
  className = "",
  placeholder,
  editable = false,
  showFixedMenu = true,
  showPlusButton = false,
  organizationId,
  onChange = undefined,
  fixedMenuShowFullScreen = true,
  isInSidebar = false,
}: {
  artifact: any;
  className?: string;
  placeholder?: string;
  editable?: boolean;
  showFixedMenu?: boolean;
  showPlusButton?: boolean;
  organizationId?: number;
  onChange?: ({ editor }: { editor: Editor }) => void;
  fixedMenuShowFullScreen?: boolean;
  isInSidebar?: boolean;
}) {
  const description = artifact?.description
    ? parseStringToJSON(artifact.description)
    : "";
  return (
    <WYSIWYG
      key={artifact.id}
      className={className}
      placeholder={placeholder}
      value={description}
      editable={editable}
      enableComment={true}
      showPlusButton={showPlusButton}
      showFixedMenu={showFixedMenu}
      isInSidebar={isInSidebar}
      fixedMenuShowFullScreen={fixedMenuShowFullScreen}
      uploadVariable={{ artifactId: artifact.id }}
      websocketConfig={{
        socketId: `artifact-${artifact.id}`,
        documentId: `artifact-${artifact.id}`,
        websocketToken: artifact.websocketToken,
      }}
      mentionsConfig={{
        artifactId: artifact.id,
      }}
      onUpdateContent={onChange}
      extraContext={{
        organizationId: organizationId,
        relatedArtifactId: artifact.id,
        relatedArtifactType: artifact.artifactType,
      }}
    />
  );
}
