import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { compact, uniqBy } from "lodash";
import moment from "moment";
import { Fragment, useCallback } from "react";
import {
  DashboardPeopleTableUserFragmentFragment,
  DashboardPeopleTableUserMeetingFragmentFragment,
  GetPeopleTableQueryQuery,
  GetPeopleTableQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getPeopleTableQuery from "../graphql/get-people-table-query";
import DashboardPeopleTableRow from "./people-table-row";
import DashboardTeamSettingsAddButton from "./team-settings-add-button";

export const peopleTableGoalMax = 5;

const ContainerLabels = ({
  isDisplayingColumns,
  isShowingOneonone = false,
}: {
  isDisplayingColumns: boolean;
  isShowingOneonone?: boolean;
}) => {
  const currentOrganization = currentOrganizationVar();
  const label = useLabel();
  return isDisplayingColumns ? (
    <div className="hidden @md/people-table:flex flex-none items-center w-[270px] @xl/people-table:gap-[10px] @xl/people-table:w-[420px] @3xl/people-table:gap-[24px] @3xl/people-table:w-[500px] @4xl/people-table:w-[590px] text-sm text-gray-500 capitalize tracking-tight">
      {currentOrganization.featureFlags.actionItems && (
        <div className="hidden @4xl/people-table:flex flex-none items-center gap-0.5 w-[100px]">
          {label("action item", { pluralize: true, capitalize: true })}
        </div>
      )}
      {currentOrganization.featureFlags.goals && (
        <div className="flex flex-none items-center gap-0.5 w-[120px]">
          {label("goal", { pluralize: true, capitalize: true })}
        </div>
      )}
      <div className="hidden @md/people-table:flex flex-none items-center gap-0.5 w-[150px] tracking-tight">
        Next{" "}
        {isShowingOneonone
          ? label("oneonone", { pluralize: false, capitalize: true })
          : "Meeting"}
      </div>
      {currentOrganization.featureFlags.recognitions && (
        <div className="hidden @xl/people-table:flex flex-none items-center gap-0.5 w-[160px] tracking-tight">
          Last {label("recognition")}
        </div>
      )}
    </div>
  ) : null;
};

export type PeopleTableRowUser = DashboardPeopleTableUserFragmentFragment & {
  meetings: DashboardPeopleTableUserMeetingFragmentFragment[];
};

const _DashboardPeopleTable = ({
  selectedUser,
}: {
  selectedUser: BasicUser;
}) => {
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const incompleteStates = (compact(currentOrganization.actionItemStates) || [])
    .filter((state) => state && !state.isComplete)
    .map((state) => state.value);
  const { data, loading } = useQuery<
    GetPeopleTableQueryQuery,
    GetPeopleTableQueryQueryVariables
  >(getPeopleTableQuery, {
    variables: {
      userId: selectedUser.id,
      now: moment().startOf("hour").format(),
      peopleTableGoalMax,
      actionItemStates: incompleteStates,
    },
    onError: onNotificationErrorHandler(),
  });

  const user = data?.user;
  const managers = (
    user?.managers ? assertEdgesNonNull(user.managers) : []
  ).map((user) => ({ ...user, meetings: assertEdgesNonNull(user.meetings) }));
  const reports = (
    user?.directReports ? assertEdgesNonNull(user.directReports) : []
  ).map((user) => ({ ...user, meetings: assertEdgesNonNull(user.meetings) }));
  const favourites = user?.favouritesGroups
    ? assertEdgesNonNull(user.favouritesGroups)
    : [];
  const allDirectTeamUsers = managers.reduce((memo, manager) => {
    const managerReports = (
      manager?.directReports ? assertEdgesNonNull(manager.directReports) : []
    ).map((user) => ({ ...user, meetings: assertEdgesNonNull(user.meetings) }));
    return [...memo, ...managerReports];
  }, [] as PeopleTableRowUser[]);
  const directTeamUsers = uniqBy(allDirectTeamUsers, "id").filter(
    ({ id }) => id !== selectedUser.id
  );
  const favouriteUsers = favourites.reduce((memo, favouriteGroup) => {
    const users = assertEdgesNonNull(favouriteGroup.users).map((user) => ({
      ...user,
      meetings: user?.meetings ? assertEdgesNonNull(user.meetings) : [],
    }));
    return [...memo, ...users];
  }, [] as PeopleTableRowUser[]);

  const handleToggleContainer = useCallback(
    (key: string, isOpen: boolean) => {
      saveUiPreference({ [`${key}`]: isOpen });
    },
    [saveUiPreference]
  );
  const handleToggleReportsContainer = useCallback(
    (isOpen: boolean) => {
      handleToggleContainer("dashboardPeopleTableReportsExpanded", isOpen);
    },
    [handleToggleContainer]
  );

  const handleToggleManagersContainer = useCallback(
    (isOpen: boolean) => {
      handleToggleContainer("dashboardPeopleTableManagersExpanded", isOpen);
    },
    [handleToggleContainer]
  );

  const handleToggleFavouritesContainer = useCallback(
    (isOpen: boolean) => {
      handleToggleContainer("dashboardPeopleTableFavouritesExpanded", isOpen);
    },
    [handleToggleContainer]
  );

  const handleToggleDirectTeamContainer = useCallback(
    (isOpen: boolean) => {
      handleToggleContainer("dashboardPeopleTableDirectTeamExpanded", isOpen);
    },
    [handleToggleContainer]
  );

  // RENDER
  return (
    <CollapsibleContainerParent
      title={
        selectedUser.id === currentUser.id
          ? "My Connections"
          : `${selectedUser.firstName}'s Connections`
      }
      loading={!!data && loading}
      rightSide={
        user?.id === currentUser.id ? (
          <DashboardTeamSettingsAddButton
            managers={managers}
            reports={reports}
            favouriteUsers={favouriteUsers}
          />
        ) : null
      }
      className="@container/people-table"
      ariaProps={{ "aria-label": "People table container" }}
    >
      {!data && loading ? (
        <div className="py-4 bg-white rounded-b-lg">
          <Loading size="6" />
        </div>
      ) : (
        <div>
          {reports.length > 0 && (
            <CollapsibleContainer
              title="Reports"
              container={Fragment}
              startOpen={
                uiPreferenceCache.dashboardPeopleTableReportsExpanded &&
                reports.length > 0
              }
              onToggle={handleToggleReportsContainer}
              titleRightSide={
                <ContainerLabels
                  isDisplayingColumns={
                    uiPreferenceCache.dashboardPeopleTableReportsExpanded &&
                    reports.length > 0
                  }
                  isShowingOneonone
                />
              }
            >
              <div className="divide-y">
                {reports.map((report) => (
                  <DashboardPeopleTableRow
                    selectedUser={selectedUser}
                    user={report}
                    key={report.id}
                  />
                ))}
                {reports.length === 0 && (
                  <div className="px-4 py-2 text-sm text-gray-500">
                    No reports.
                  </div>
                )}
              </div>
            </CollapsibleContainer>
          )}
          <CollapsibleContainer
            title="Managers"
            container={Fragment}
            startOpen={
              uiPreferenceCache.dashboardPeopleTableManagersExpanded &&
              managers.length > 0
            }
            onToggle={handleToggleManagersContainer}
            titleRightSide={
              <ContainerLabels
                isDisplayingColumns={
                  uiPreferenceCache.dashboardPeopleTableManagersExpanded &&
                  managers.length > 0
                }
                isShowingOneonone
              />
            }
          >
            <div className="divide-y">
              {managers.map((manager) => (
                <DashboardPeopleTableRow
                  selectedUser={selectedUser}
                  user={manager}
                  key={manager.id}
                />
              ))}
              {managers.length === 0 && (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No managers.
                </div>
              )}
            </div>
          </CollapsibleContainer>
          <CollapsibleContainer
            title="Direct Team"
            container={Fragment}
            startOpen={
              uiPreferenceCache.dashboardPeopleTableDirectTeamExpanded &&
              directTeamUsers.length > 0
            }
            onToggle={handleToggleDirectTeamContainer}
            titleRightSide={
              <ContainerLabels
                isDisplayingColumns={
                  uiPreferenceCache.dashboardPeopleTableDirectTeamExpanded &&
                  directTeamUsers.length > 0
                }
              />
            }
          >
            <div className="divide-y">
              {directTeamUsers.map((directTeamUser) => (
                <DashboardPeopleTableRow
                  selectedUser={selectedUser}
                  user={directTeamUser}
                  key={directTeamUser.id}
                  hideOneOnOneCTA
                />
              ))}
              {directTeamUsers.length === 0 && (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No direct team.
                </div>
              )}
            </div>
          </CollapsibleContainer>
          <CollapsibleContainer
            title="Favorites"
            container={Fragment}
            roundedBottom={true}
            startOpen={
              uiPreferenceCache.dashboardPeopleTableFavouritesExpanded &&
              favouriteUsers.length > 0
            }
            onToggle={handleToggleFavouritesContainer}
            titleRightSide={
              <ContainerLabels
                isDisplayingColumns={
                  uiPreferenceCache.dashboardPeopleTableFavouritesExpanded &&
                  favouriteUsers.length > 0
                }
              />
            }
          >
            <div className="divide-y rounded-b-lg">
              {favouriteUsers.map((favouriteUser) => (
                <DashboardPeopleTableRow
                  selectedUser={selectedUser}
                  user={favouriteUser}
                  key={favouriteUser.id}
                />
              ))}
              {favouriteUsers.length === 0 && (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No favorites.
                </div>
              )}
            </div>
          </CollapsibleContainer>
        </div>
      )}
    </CollapsibleContainerParent>
  );
};

const DashboardPeopleTable = withErrorBoundary(_DashboardPeopleTable, {
  fallback: <Error description={"The people table could not be rendered."} />,
});
export default DashboardPeopleTable;
