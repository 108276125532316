import { XIcon } from "@heroicons/react/outline";

import Error from "@components/error/error";

const ArtifactSidebarError = ({ onClose }: { onClose: () => void }) => {
  const title = "An error occurred, we cannot load this content.";
  const description = undefined;

  return (
    <div className="-mt-3 px-6" aria-label="Error sidebar artifact">
      <div className="flex justify-end">
        <button
          type="button"
          className="ml-3 bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close artifact sidebar"
          onClick={onClose}
        >
          <span className="sr-only">Close artifact sidebar</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="pt-2">
        <Error title={title} description={description} />
      </div>
    </div>
  );
};

export default ArtifactSidebarError;
