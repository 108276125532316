import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import { Fragment, ReactElement, useCallback } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import {
  ActionItemCollapsibleFragmentFragment,
  ActionItemState,
  GetActionItemsCollapsibleQueryQuery,
  GetActionItemsCollapsibleQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import CollapsibleEmpty from "@components/collapsible-container/collapsible-empty";
import CollapsibleLoading from "@components/collapsible-container/collapsible-loading";
import CollapsiblePaginate from "@components/collapsible-container/collapsible-paginate";
import Layout from "@components/layout/layout";
import { useNotificationError } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getActionItemsCollapsibleQuery from "./graphql/get-action-items-collapsible-query";

export const dashboardActionItemsFirstPageLimit = 5;

const ActionItemsCollapsibleContainer = ({
  actionItemState,
  uiPreferenceCacheNamespace,
  path = "",
  roundedTop = false,
  roundedBottom = false,
  queryVariables,
  filterResults,
  titleRightSide = null,
  newSidebarDesign = false,
}: {
  actionItemState: Pick<ActionItemState, "label" | "value">;
  uiPreferenceCacheNamespace: keyof UiPreferenceCache;
  path?: string;
  roundedTop?: boolean;
  roundedBottom?: boolean;
  newSidebarDesign?: boolean;
  queryVariables: GetActionItemsCollapsibleQueryQueryVariables;
  filterResults?: (
    variables: GetActionItemsCollapsibleQueryQueryVariables
  ) => (node: ActionItemCollapsibleFragmentFragment) => boolean;
  titleRightSide?: ReactElement | null;
}) => {
  const label = useLabel();
  const organization = currentOrganizationVar();

  // HOOKS
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const open =
    uiPreferenceCache[`${uiPreferenceCacheNamespace}`] === null ||
    uiPreferenceCache[`${uiPreferenceCacheNamespace}`].includes(
      actionItemState.value
    );

  const limit = 20;
  const { onError } = useNotificationError();
  const { loading, data, fetchMore } = useQuery<
    GetActionItemsCollapsibleQueryQuery,
    GetActionItemsCollapsibleQueryQueryVariables
  >(getActionItemsCollapsibleQuery, {
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    onError,
  });

  const artifacts = data?.artifacts
    ? assertEdgesNonNull(data.artifacts).filter(
        (d) => d.__typename === "ActionItemArtifactNode"
      )
    : [];
  const actionItems = artifacts.filter(
    (actionItem) =>
      actionItem.__typename === "ActionItemArtifactNode" &&
      actionItem.actionItemState === actionItemState.value
  );
  const filteredActionItems = filterResults
    ? actionItems.filter((actionItem) =>
        filterResults(queryVariables)(actionItem)
      )
    : actionItems;

  const handleClickMore = () => {
    fetchMore({
      variables: {
        merge: true,
        after: data?.artifacts?.pageInfo.endCursor,
        first: limit,
      },
    });
  };

  const handleToggleCollapsible = useCallback(
    (isOpen: boolean) => {
      const allActionItemStates = compact(organization.actionItemStates).map(
        ({ value }) => value
      );
      const states =
        uiPreferenceCache[`${uiPreferenceCacheNamespace}`] ||
        allActionItemStates;
      const newVal = isOpen
        ? states.concat(actionItemState.value)
        : states.filter((state: number) => actionItemState.value !== state);
      saveUiPreference({ [`${uiPreferenceCacheNamespace}`]: newVal });
    },
    [
      actionItemState.value,
      organization.actionItemStates,
      saveUiPreference,
      uiPreferenceCache,
      uiPreferenceCacheNamespace,
    ]
  );

  const Container = newSidebarDesign
    ? Layout.SidebarSubSection
    : CollapsibleContainer;

  // RENDER;
  return (
    <>
      <Droppable
        droppableId={String(actionItemState.value)}
        type="action-item-state"
      >
        {(providedDroppable, snapshotDroppable) => (
          <div
            ref={providedDroppable.innerRef}
            className="relative"
            {...providedDroppable.droppableProps}
            aria-label={`${actionItemState.label.toLowerCase()} action items droppable container`}
          >
            <Container
              title={actionItemState.label}
              // old component
              roundedTop={roundedTop}
              roundedBottom={roundedBottom}
              container={Fragment}
              startOpen={open || snapshotDroppable.isDraggingOver}
              onToggle={handleToggleCollapsible}
              // new component
              isExpanded={open || snapshotDroppable.isDraggingOver}
              onToggleIsExpanded={handleToggleCollapsible}
              className="relative"
              // titleRightSide={
              //   newSidebarDesign &&
              //   data?.artifacts?.totalCount !== undefined && (
              //     <div className="text-xs text-gray-300">
              //       {data.artifacts.totalCount}{" "}
              //       {label("action item", {
              //         pluralize: data.artifacts.totalCount,
              //       })}
              //     </div>
              //   )
              // }
            >
              {newSidebarDesign ? (
                <>
                  {!data && loading ? (
                    <>
                      <Layout.SidebarSubSectionLoading />
                      {providedDroppable.placeholder}
                    </>
                  ) : snapshotDroppable.isDraggingOver ||
                    filteredActionItems.length > 0 ? (
                    <Layout.SidebarSubSectionList aria-label="Action items collapsible container">
                      {filteredActionItems.map((actionItem, index) => (
                        <Draggable
                          key={actionItem.id}
                          draggableId={String(actionItem.id)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Layout.SidebarSubSectionListItem
                                className={classNames(
                                  "relative group",
                                  snapshot.isDragging &&
                                    "bg-purple-50/90 shadow-xl rounded-xl"
                                )}
                              >
                                <button
                                  {...provided.dragHandleProps}
                                  className={classNames(
                                    "absolute top-1 -left-3",
                                    "text-gray-500 hover:text-gray-800 bg-white hover:bg-gray-50",
                                    "hidden group-hover:block",
                                    "px-0.5 py-1 cursor-move rounded border"
                                  )}
                                  aria-label="Artifact drag button"
                                >
                                  <MdOutlineDragIndicator className="h-4 w-4" />
                                </button>
                                <Artifact
                                  artifact={actionItem}
                                  urlPrefix={path}
                                />
                              </Layout.SidebarSubSectionListItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {providedDroppable.placeholder}
                      <Layout.SidebarSubSectionListPaginate
                        loading={loading}
                        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
                        onClickMore={handleClickMore}
                      />
                    </Layout.SidebarSubSectionList>
                  ) : (
                    <div>
                      <Layout.SidebarSubSectionEmpty>
                        No {label("action item", { pluralize: 0 })}.
                      </Layout.SidebarSubSectionEmpty>
                      {providedDroppable.placeholder}
                    </div>
                  )}
                </>
              ) : (
                <div className="relative">
                  {!data && loading ? (
                    <>
                      <CollapsibleLoading />
                      {providedDroppable.placeholder}
                    </>
                  ) : snapshotDroppable.isDraggingOver ||
                    filteredActionItems.length > 0 ? (
                    <div
                      className={classNames(
                        "divide-y divide-gray-100",
                        newSidebarDesign &&
                          "bg-white border divide-gray-200 rounded-lg"
                      )}
                      aria-label="Action items collapsible container"
                    >
                      {filteredActionItems.map((actionItem, index) => (
                        <Draggable
                          key={actionItem.id}
                          draggableId={String(actionItem.id)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className={classNames(
                                  "px-3 py-2 relative group",
                                  snapshot.isDragging &&
                                    "bg-purple-50/90 shadow-xl rounded-xl"
                                )}
                              >
                                <button
                                  {...provided.dragHandleProps}
                                  className={classNames(
                                    "absolute top-1 -left-3",
                                    "text-gray-500 hover:text-gray-800 bg-white hover:bg-gray-50",
                                    "hidden group-hover:block",
                                    "px-0.5 py-1 cursor-move rounded border"
                                  )}
                                  aria-label="Artifact drag button"
                                >
                                  <MdOutlineDragIndicator className="h-4 w-4" />
                                </button>
                                <Artifact
                                  artifact={actionItem}
                                  urlPrefix={path}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {providedDroppable.placeholder}
                      <CollapsiblePaginate
                        loading={loading}
                        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
                        onClickMore={handleClickMore}
                      />
                    </div>
                  ) : (
                    <div>
                      <CollapsibleEmpty>
                        No {label("action item", { pluralize: 0 })}.
                      </CollapsibleEmpty>
                      {providedDroppable.placeholder}
                    </div>
                  )}
                </div>
              )}
            </Container>
          </div>
        )}
      </Droppable>
    </>
  );
};

export default ActionItemsCollapsibleContainer;
