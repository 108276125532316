import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  query getGoalsPage($meetingGroupId: Int, $flowId: Int, $after: String) {
    topics(
      hasGoals: true
      orderBy: "-meeting__start_datetime"
      meetingInMeetingGroupId: $meetingGroupId
      meetingInFlowId: $flowId
      first: 20
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          state
          artifacts(artifactType: goal) {
            edges {
              node {
                id
                canDelete {
                  permission
                }
                artifactType
                title
                ...CommonGoalArtifactFragment
              }
            }
          }
          meeting {
            id
            meetingGroupId
            startDatetime
          }
        }
      }
    }
  }
`;
