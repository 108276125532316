import { useLocation } from "react-router-dom";

import useLabel from "@apps/use-label/use-label";
import AppLink from "@components/link/link";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

import MeetingsOverviewGroupMeetings from "./components/overview-meetings";
import MeetingsOverviewOneononeMeetings from "./components/overview-oneonone";

const MeetingOverview = ({
  selectedUser,
}: {
  selectedUser: UserComboboxOption;
}) => {
  const { pathname } = useLocation();
  const label = useLabel();

  const tabs = [
    {
      url: "group",
      label: "Meetings",
      active: !pathname.includes("/1-on-1"),
    },
    {
      url: "1-on-1",
      label: label("1-on-1", { pluralize: true, capitalize: true }),
      active: pathname.includes("/1-on-1"),
    },
  ];

  // RENDER
  return (
    <div className="mx-auto w-full max-w-7xl px-3 sm:px-6 7xl:px-0 py-6 flex flex-col gap-6">
      <nav className="-mb-px flex gap-6 overflow-auto" aria-label="Tabs">
        {tabs.map((tab) => (
          <AppLink
            key={tab.url}
            to={`/meetings/${tab.url}/user/${selectedUser.id}`}
            className={classNames(
              tab.active
                ? "border-blue-700 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
            )}
          >
            {tab.label}
          </AppLink>
        ))}
      </nav>

      {pathname.includes("/meetings/1-on-1") ? (
        <MeetingsOverviewOneononeMeetings selectedUser={selectedUser} />
      ) : (
        <MeetingsOverviewGroupMeetings selectedUser={selectedUser} />
      )}
    </div>
  );
};

export default MeetingOverview;
