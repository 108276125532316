import { Listbox } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/outline";
import { sortBy } from "lodash";
import moment from "moment";
import { useEffect, useRef } from "react";
import { KpiSummaryPeriod } from "types/graphql-schema";

import KPILineChart from "@apps/kpis/components/kpi-line-chart";
import { KPISummaryTableCell } from "@apps/kpis/components/summary-table-row";
import { kpiSummaryPeriodOptions } from "@apps/kpis/helpers";
import AppLink from "@components/link/link";
import Select from "@components/select/select";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

import { singleKpiViewLimit } from "./single-kpi-view";

export const viewOptions = [
  {
    value: "table",
    label: "Table",
  },
  {
    value: "graph",
    label: "Graph",
  },
];

const KpiView = ({
  kpi,
  deleteNode,
  selected,
  summaryPeriod,
  setSummaryPeriod,
  setViewMode,
  viewMode,
  isEmbeddedInKpiGroup = false,
  onAddValue = () => {},
}: {
  kpi: any;
  deleteNode?: () => void;
  selected: boolean;
  summaryPeriod?: KpiSummaryPeriod;
  setSummaryPeriod?: (sp: KpiSummaryPeriod) => void;
  setViewMode: (viewMode: string) => void;
  viewMode: string;
  isEmbeddedInKpiGroup: boolean;
  onAddValue?: () => void;
}) => {
  const measurements = sortBy(kpi.summarizedMeasurements.edges, ({ node }) =>
    moment(node.date).unix()
  );
  const measurementCellClassName = "text-center px-2 w-36 flex-none";
  const measurementHeaderCellClassName = classNames(measurementCellClassName);
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);

  const handleChangeSummaryPeriod = ({
    value,
  }: {
    value: KpiSummaryPeriod;
  }) => {
    if (setSummaryPeriod) setSummaryPeriod(value);
  };
  const handleChangeView = ({ value }: { value: string }) => setViewMode(value);

  const filteredMeasurements = sortBy(
    measurements,
    ({ node }) => -moment(node.date).unix()
  )
    .slice(0, singleKpiViewLimit)
    .reverse();

  const hasDates = filteredMeasurements.length;

  useEffect(() => {
    if (hasDates && scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, [hasDates]);

  return (
    <div
      className={classNames(
        "flex flex-col gap-2 border rounded-lg bg-gray-50 not-prose",
        !isEmbeddedInKpiGroup &&
          selected &&
          "ring-2 ring-blue-200 ring-offset-2"
      )}
      contentEditable={false}
      aria-label="Artifact kpi view"
    >
      {kpi?.currentMeasurement ? (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between px-2 pt-1">
            <div>
              <AppLink
                to={`/kpis/${kpi.id}`}
                className="font-medium text-sm text-blue-link hover:underline"
              >
                {kpi.title}
              </AppLink>
              <span className="text-sm pl-2 fs-mask">
                (current value: {kpi.currentMeasurement.measurement})
              </span>
            </div>
            {deleteNode && (
              <button
                onClick={deleteNode}
                className="text-gray-600 p-0.5 rounded hover:bg-gray-200 hover:text-gray-800"
              >
                <XIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className={"flex gap-2 px-2"}>
            {summaryPeriod && !isEmbeddedInKpiGroup && (
              <Select
                onChange={handleChangeSummaryPeriod}
                value={summaryPeriod}
                options={kpiSummaryPeriodOptions}
              >
                {({ selected, setReferenceElement, disabled }) => (
                  <Listbox.Button
                    className={classNames(
                      "bg-white relative w-full pl-1.5 pr-6 py-1 text-left cursor-default text-gray-800 text-xs",
                      inputBorderClassName,
                      inputFocusClassName
                    )}
                    ref={setReferenceElement}
                  >
                    <span className="block truncate">{selected?.label}</span>
                    {!disabled && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-1.5 pointer-events-none">
                        <SelectorIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>
                )}
              </Select>
            )}
            {viewMode && !isEmbeddedInKpiGroup && (
              <Select
                onChange={handleChangeView}
                value={viewMode}
                options={viewOptions}
              >
                {({ selected, setReferenceElement, disabled }) => (
                  <Listbox.Button
                    className={classNames(
                      "bg-white relative w-full pl-1.5 pr-6 py-1 text-left cursor-default text-gray-800 text-xs",
                      inputBorderClassName,
                      inputFocusClassName
                    )}
                    ref={setReferenceElement}
                  >
                    <span className="block truncate">{selected?.label}</span>
                    {!disabled && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-1.5 pointer-events-none">
                        <SelectorIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>
                )}
              </Select>
            )}
          </div>
          {viewMode === "table" && (
            <div
              className="overflow-x-auto overscroll-none"
              ref={scrollContainerRef}
            >
              <table className="rounded-b-lg w-full">
                <thead>
                  <tr>
                    {filteredMeasurements.map(({ node }, i) => (
                      <th
                        key={node.date}
                        className={classNames(
                          "!border !border-gray-300 !border-r-0 text-xs font-medium p-2 bg-gray-50 text-gray-600 tracking-tight !text-center",
                          measurementHeaderCellClassName,
                          i === 0 && "!border-l-0"
                        )}
                      >
                        {moment(node.date).format("ll")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {filteredMeasurements.map(({ node }, i) => (
                      <td
                        className={classNames(
                          "!border !p-0 !border-b-0 !border-r-0",
                          i === 0 && "!border-l-0"
                        )}
                        key={node.date}
                      >
                        <KPISummaryTableCell
                          key={i}
                          kpi={kpi}
                          isLastCell={i === filteredMeasurements.length - 1}
                          measurement={node}
                          previousMeasurement={
                            filteredMeasurements[i - 1]?.node
                          }
                          className={classNames(
                            "text-center px-2 h-14 py-2 text-gray-500 text-sm"
                          )}
                          onAddValue={onAddValue}
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {viewMode === "graph" && (
            <div>
              <KPILineChart kpi={kpi} height={200} />
            </div>
          )}
        </div>
      ) : (
        <div className="text-gray-500 text-sm">No measurement.</div>
      )}
    </div>
  );
};

export default KpiView;
