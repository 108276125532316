import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import { classNames } from "@helpers/css";

import MeetingSidebarAIContainer from "../meeting-sidebar/ai-container";
import MeetingSummaryGroupTranscription from "./summary-group-transcription";

const MeetingSummary = ({
  meeting,
  meetingGroupId,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroupId: number;
}) => {
  return (
    <MeetingSidebarAIContainer meeting={meeting}>
      {({ meeting: transcriptMeeting }) => (
        <div className={classNames("text-sm flex flex-col divide-y")}>
          <MeetingSummaryGroupTranscription
            meeting={transcriptMeeting}
            meetingGroupId={meetingGroupId}
          />
        </div>
      )}
    </MeetingSidebarAIContainer>
  );
};

export default MeetingSummary;
