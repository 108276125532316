import { useMenuState } from "@szhsin/react-menu";
import moment from "moment";
import { useEffect, useState } from "react";
import { SidebarMeetingFragmentFragment } from "types/graphql-schema";

import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingVideoConferenceButton from "@apps/meeting/components/meeting/video-conference-button";
import Link from "@components/link/link";
import { classNames } from "@helpers/css";
import { getUrl } from "@helpers/helpers";

const SidebarMeeting = ({
  meeting,
}: {
  meeting: SidebarMeetingFragmentFragment;
}) => {
  // eslint-disable-next-line
  const [now, setNow] = useState(moment().format());
  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const handleContextMenu = (e: any) => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const currentPath = getUrl({
    meetingGroupId: meeting.meetingGroup?.id,
    meetingId: meeting.id,
  });
  const isActive = window.location.href.includes(currentPath);

  const isInProgress = meeting.startDatetime
    ? moment().isBetween(meeting.startDatetime, meeting.endDatetime)
    : false;
  const isAboutToStartProgress = meeting.startDatetime
    ? moment().isBetween(
        moment(meeting.startDatetime).subtract(5, "minutes"),
        meeting.endDatetime
      )
    : false;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment().format());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  if (!meeting.meetingGroup?.id) {
    return null;
  }

  return (
    <Link
      key={meeting.id}
      to={getUrl({
        meetingGroupId: meeting.meetingGroup.id,
        meetingId: meeting.id,
      })}
      className={classNames(
        "block rounded-lg py-1.5 px-2.5 mb-1 border bg-white  border-transparent border-gray-200 hover:border-gray-300",
        meeting.draft &&
          "bg-amber-50 hover:border-amber-400 border-dashed border-amber-300",
        isActive ? "border-blue-500 hover:border-blue-500" : "",
        moment(meeting.endDatetime).isBefore(moment()) &&
          !isActive &&
          "opacity-50"
      )}
      onContextMenu={handleContextMenu}
    >
      <MeetingDropdownMenu
        meetingGroup={meeting.meetingGroup}
        meeting={meeting}
        isContextMenu={true}
        onToggleMenu={toggleMenu}
        anchorPoint={anchorPoint}
        {...menuProps}
      />
      <div className="flex items-start gap-3">
        <div
          className={classNames(
            "flex gap-2 flex-1 text-sm text-gray-800 font-medium",
            "min-w-0 break-words", // to break words that are too long
            moment(meeting.endDatetime).isBefore(moment()) && "line-through",
            meeting.draft && "text-amber-800",
            isActive && "text-blue-800"
          )}
        >
          <div
            className={classNames(
              "relative mt-1 w-3 h-3 rounded-full shrink-0",
              moment().isBefore(meeting.startDatetime) && "bg-gray-300",
              moment().isAfter(meeting.endDatetime) && "bg-gray-300",
              meeting.draft && "bg-amber-300",
              isInProgress && "bg-emerald-500",
              isActive && "bg-blue-500"
            )}
          >
            {isInProgress && !isActive && (
              <div className="absolute top-0 w-3 h-3 rounded-full bg-emerald-500 animate-ping" />
            )}
          </div>
          {/* flex-1 & min-w-0 to break words that are too long */}
          <div className="flex-1 min-w-0 line-clamp-2">{meeting.title}</div>
        </div>
        {meeting.startDatetime && (
          <div
            className={classNames(
              "px-1 py-0.5 text-xs rounded-full",
              meeting.draft
                ? "text-amber-800"
                : isInProgress
                ? "bg-emerald-600 text-white font-medium"
                : "text-gray-500",
              isActive && !isInProgress && "text-blue-800"
            )}
          >
            {moment(meeting.startDatetime).format("h:mma").replace(":00", "")}
          </div>
        )}
      </div>
      {meeting.videoConferenceUrl && isAboutToStartProgress && (
        <div className="mt-2 text-sm text-gray-700 -ml-1 flex">
          <MeetingVideoConferenceButton
            meeting={meeting}
            className="hover:text-gray-800 hover:bg-black/5 text-xs px-1 py-0.5 rounded text-gray-600 gap-1.5"
          />
        </div>
      )}
    </Link>
  );
};

export default SidebarMeeting;
