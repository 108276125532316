import { useMutation } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  GoalArtifactSidebarFragmentFragment,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import getTopLevelGoalsQuery from "@apps/goal-alignment/graphql/get-top-level-goals-query";
import Button, { buttonTheme } from "@components/button/button";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";

import alignGoalMutation from "./graphql/align-goal-mutation";
import getArtifactSidebarQuery from "./graphql/get-artifact-sidebar-query";

const ArtifactSidebarAlignedGoalItem = ({
  artifact,
  alignedGoal,
  isEditingAlignment,
  alignmentType,
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
  alignedGoal: any;
  isEditingAlignment: boolean;
  alignmentType: "parent" | "child";
}) => {
  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);

  const handleClearAlignment = () => {
    if (alignmentType === "parent") {
      updateGoal({
        variables: {
          goalId: artifact.id,
          parentGoalId: graphqlNone,
        },
        refetchQueries: [getTopLevelGoalsQuery],
        onError: onNotificationErrorHandler(),
      });
    } else {
      updateGoal({
        variables: {
          goalId: alignedGoal.id,
          parentGoalId: graphqlNone,
        },
        refetchQueries: [getArtifactSidebarQuery],
        onError: onNotificationErrorHandler(),
      });
    }
  };

  return (
    <div className="py-1 flex items-center gap-2" key={alignedGoal.id}>
      {isEditingAlignment && (
        <Button
          icon
          theme={buttonTheme.iconGray}
          onClick={handleClearAlignment}
        >
          <XIcon className="h-4 w-4" />
        </Button>
      )}
      <div className="flex-1">
        <Artifact artifact={alignedGoal} hideActionDropdown />
      </div>
    </div>
  );
};

export default ArtifactSidebarAlignedGoalItem;
