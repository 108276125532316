import { useMutation } from "@apollo/client";
import { LockClosedIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useCallback, useMemo } from "react";
import {
  AssessmentGroupAnonymity,
  AssessmentState,
  GetAssessmentQuery,
  UnsubmitAssessmentMutation,
  UnsubmitAssessmentMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import {
  currentUserVar,
  isAdminVar,
  successNotificationVar,
} from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button from "@components/button/button";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import getAssessmentQuery from "../../graphql/get-assessment-query";
import reopenAssessmentMutation from "../../graphql/reopen-assessment-mutation";
import { bgClassName } from "../../helpers";

const AssessmentLeftCol = ({
  myAssessmentsData,
  isLoadingAssessment,
}: {
  myAssessmentsData?: GetAssessmentQuery;
  isLoadingAssessment: boolean;
}) => {
  const currentUser = currentUserVar();
  const isAdmin = isAdminVar();
  const label = useLabel();

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );

  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );
  const responder = useMemo(() => assessment?.responder ?? null, [assessment]);
  const isSelfAssessment = useMemo(
    () => target?.id === responder?.id,
    [responder, target]
  );

  const [reopenAssessment, { loading: isReopeningAssessment }] = useMutation<
    UnsubmitAssessmentMutation,
    UnsubmitAssessmentMutationVariables
  >(reopenAssessmentMutation);

  const handleReopenAssessment = useCallback(() => {
    if (!assessment) {
      return;
    }
    reopenAssessment({
      variables: {
        assessmentId: assessment.id,
      },
      refetchQueries: [getAssessmentQuery],
      onCompleted: () => {
        successNotificationVar({
          title: `${label("review", {
            capitalize: true,
          })} reopened. The responder can now edit their responses.`,
        });
      },
      onError: onNotificationErrorHandler(),
    });
  }, [assessment, reopenAssessment, label]);

  if (isLoadingAssessment) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessment) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label("review", { capitalize: true })} not found
      </div>
    );
  }

  return (
    <div className="w-full lg:w-72 flex flex-col text-sm gap-4 bg-gray-50 border-b lg:border-r p-6 rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg">
      <div className="font-bold text-lg">
        {assessmentTypeLabels[assertNonNull(assessment.group.assessmentType)]}{" "}
        {label("review")}
      </div>
      <div className="flex flex-col gap-1">
        {isSelfAssessment ? (
          <div className="flex items-center">
            <Avatar className="mr-1" user={assertNonNull(target)} size="8" />{" "}
            {`${target?.name} (Self ${label("review")})`}
          </div>
        ) : (
          <>
            <div className="italic text-gray-500">Responder:</div>
            <div className="flex items-center">
              <Avatar className="mr-1" user={responder} size="8" />{" "}
              {responder?.name ?? "Anonymous"}
            </div>
            <div className="mt-2 italic text-gray-500">Subject:</div>
            <div className="flex items-center">
              <Avatar className="mr-1" user={assertNonNull(target)} size="8" />{" "}
              {target?.name}
            </div>
            {target?.organizationSpecificInfo?.position && (
              <div className="text-gray-500">
                Position:{" "}
                <span className="font-bold">
                  {target?.organizationSpecificInfo?.position}
                </span>
              </div>
            )}
            {target?.organizationSpecificInfo?.jobLevel && (
              <div className="text-gray-500">
                Job level:{" "}
                <span className="font-bold">
                  {target?.organizationSpecificInfo?.jobLevel}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <div className="text-gray-500">
        Compliance program:{" "}
        <span className="font-bold">{complianceProgram?.title}</span>
      </div>
      <div className="text-gray-500">
        Program period:{" "}
        <span className="font-bold">
          {complianceProgram?.periodStartDate &&
          complianceProgram?.periodEndDate
            ? `${moment(complianceProgram.periodStartDate).format(
                "MMM D, YYYY"
              )} - ${moment(complianceProgram.periodEndDate).format(
                "MMM D, YYYY"
              )}`
            : "Not set"}
        </span>
      </div>
      <div className="text-gray-500">
        Due date:{" "}
        <span className="font-bold">
          {moment(complianceProgram?.dueDate).format("MMM D, YYYY")}
        </span>
      </div>
      {assessment.state === AssessmentState.Submitted && (
        <div className="text-green-700">
          Submitted{" "}
          {moment(assessment.submittedDatetime).format("MMM D, YYYY @ h:mma")}
        </div>
      )}
      {assessment.state === AssessmentState.Submitted && isAdmin && (
        <div className="flex items-center w-full">
          <Button
            className="mr-2"
            onClick={handleReopenAssessment}
            disabled={isReopeningAssessment}
          >
            Reopen
          </Button>
          {isReopeningAssessment && <Loading size="5" mini />}
        </div>
      )}
      {[
        AssessmentGroupAnonymity.SemiAnonymous,
        AssessmentGroupAnonymity.Anonymous,
      ].includes(assessment.group.anonymity) &&
        assessment.state !== AssessmentState.Submitted &&
        currentUser.id === responder?.id &&
        !isSelfAssessment && (
          <div className="border rounded-md border-gray-200 text-gray-500 p-4">
            <LockClosedIcon className="h-4 w-4 inline mr-1" /> Your responses
            will appear anonymous
            {assessment.group.anonymity ===
            AssessmentGroupAnonymity.SemiAnonymous
              ? " to the subject"
              : ""}
            . Anonymity will be affected if you put identifiable content in your
            comments.
          </div>
        )}
    </div>
  );
};

export default AssessmentLeftCol;
