import { gql } from "@apollo/client";

import { DashboardGoalFragment } from "./dashboard-goal-fragment";

export default gql`
  ${DashboardGoalFragment}
  query getDashboardGoalsQuery(
    $owners: [Int]
    $contributors: [Int]
    $teamGoalsOfUser: Int
    $forUserId: Int!
    $goalStates: [GoalState]!
    $goalScopes: [GoalScope]
    $goalDueBetweenDates: [Date]
    $orderBy: String
    $after: String
  ) {
    goalsForUser(
      goalOwners: $owners
      goalContributors: $contributors
      teamGoalsOfUser: $teamGoalsOfUser
      goalStates: $goalStates
      goalScopes: $goalScopes
      goalDueBetweenDates: $goalDueBetweenDates
      forUserId: $forUserId
      first: 10
      orderBy: $orderBy
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...DashboardGoalFragment
        }
      }
    }
  }
`;
