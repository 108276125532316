import { ReactElement } from "react";

export const CollapsibleEmpty = ({
  children,
}: {
  children: ReactElement | string[] | string;
}) => {
  return waffle.flag_is_active("new-meeting-page") ? (
    <div className="py-1 text-gray-400 text-sm">{children}</div>
  ) : (
    <div className="pl-4 py-2 text-slate-500 text-sm">{children}</div>
  );
};

export default CollapsibleEmpty;
