import { Dialog } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/react";
import size from "lodash/size";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import SidebarSyncingError from "@apps/main/components/sidebar-syncing-error";
import SearchModal from "@apps/search/search";
import { currentUserVar, errorNotificationVar } from "@cache/cache";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import { useLink } from "@components/link/link";
import { classNames } from "@helpers/css";
import {
  getLocalStorage,
  getNodeUrl,
  isCtrlSpaceEvent,
  setLocalStorage,
} from "@helpers/helpers";

import Logo from "./logo";
import Notification from "./notification";
import OutdatedEditorLayout from "./outdated-editor-dialog";
import LayoutAddSearch from "./sidebar-add-search";
import SidebarBillingCta from "./sidebar-billing-cta";
import SidebarMeetings from "./sidebar-meetings";
import SidebarSettings from "./sidebar-my-account";
import SidebarNavigation from "./sidebar-navigation";
import SidebarOnboarding from "./sidebar-onboarding";
import SidebarSyncingCalendar from "./sidebar-syncing-calendar";

const menuCollapsedLocastorageKey = "menu:collapsed";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const currentUser = currentUserVar();
  const location = useLocation<TFLocationState>();
  const link = useLink();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isShowingSearchModal, setIsShowingSearchModal] = useState(false);
  const [menuIsCollapsed, setMenuIsCollapsed] = useState(
    getLocalStorage(menuCollapsedLocastorageKey, false)
  );

  const handleToggleMenu = () => {
    setLocalStorage(menuCollapsedLocastorageKey, !menuIsCollapsed);
    setMenuIsCollapsed(!menuIsCollapsed);
  };

  // Show messages coming from the back-end
  useEffect(() => {
    if (size(window.errorMessages) > 0) {
      errorNotificationVar({
        title: "Error",
        description: window.errorMessages[0],
        timeout: null,
      });
      window.errorMessages = [];
    }
  }, []);

  const handleShowSearchModal = useCallback(() => {
    if (!location.state?.background) {
      setIsShowingSearchModal(true);
    }
  }, [location]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (isCtrlSpaceEvent(event)) {
        handleShowSearchModal();
      }
    },
    [handleShowSearchModal]
  );

  const handleHideSearchModal = useCallback(() => {
    setIsShowingSearchModal(false);
  }, []);

  const handleClickSearchModalResult = useCallback(
    (result: Parameters<typeof getNodeUrl>[0]) => {
      handleHideSearchModal();
      link.redirect(getNodeUrl(result) || "/");
    },
    [handleHideSearchModal, link]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="min-h-full flex flex-1">
        {/* Prompt to refresh dialog when editor version changes. See https://github.com/Topicflow/topicflow/pull/721 */}
        <OutdatedEditorLayout />
        {isShowingSearchModal && (
          <SearchModal
            onClose={handleHideSearchModal}
            onClickResult={handleClickSearchModalResult}
          />
        )}

        {/* Mobile nav */}
        <Dialog
          as="div"
          open={sidebarOpen}
          className="fixed inset-0 flex z-leftSidebar md:hidden"
          onClose={setSidebarOpen}
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />

          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white">
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setSidebarOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="flex-shrink-0 flex justify-between items-center px-4">
              <Logo />
              <LayoutAddSearch
                menuIsCollapsed={menuIsCollapsed}
                onShowSearchModal={handleShowSearchModal}
              />
            </div>
            <div className="mt-5 flex-1 h-0 flex flex-col">
              <nav className="space-y-1 flex-1 overflow-y-auto">
                <div className="px-2">
                  <SidebarNavigation
                    onClickSearch={handleShowSearchModal}
                    menuIsCollapsed={false}
                  />
                </div>
                <div className="pt-4 px-2">
                  <SidebarMeetings key="sidebar-meetings" />
                </div>
              </nav>

              <SidebarBillingCta menuIsCollapsed={menuIsCollapsed} />
              <div className="flex items-center justify-center text-sm py-2 border-r border-t">
                <SidebarSettings />
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div
          className={classNames(
            "hidden md:flex md:flex-col md:fixed md:inset-y-0 z-10 bg-gray-50",
            menuIsCollapsed ? "md:w-16" : "md:w-52 lg:w-60"
          )}
          id="sidebar"
        >
          <div
            className={classNames(
              "flex h-14 bg-white border-b border-r items-center flex-shrink-0 pl-3 md:pl-4 pr-2 gap-2",
              menuIsCollapsed ? "justify-center" : "justify-between"
            )}
          >
            <Logo menuIsCollapsed={menuIsCollapsed} />
            <LayoutAddSearch
              menuIsCollapsed={menuIsCollapsed}
              onShowSearchModal={handleShowSearchModal}
            />
          </div>
          <nav className="pt-4 border-r flex-1 overflow-y-auto text-sm">
            <div className="px-2">
              <SidebarNavigation
                menuIsCollapsed={menuIsCollapsed}
                onClickSearch={handleShowSearchModal}
              />
            </div>
            {!menuIsCollapsed && (
              <div className="border-t mt-4">
                <div className="mx-2 mt-2">
                  <Sentry.ErrorBoundary
                    fallback={<div>Cannot load meetings.</div>}
                    showDialog
                  >
                    <SidebarMeetings key="sidebar-meetings" />
                  </Sentry.ErrorBoundary>
                </div>
              </div>
            )}
            {!menuIsCollapsed && (
              <div className="mx-2 mt-4">
                <SidebarOnboarding currentUser={currentUser} />
                <SidebarSyncingCalendar currentUser={currentUser} />
                <SidebarSyncingError currentUser={currentUser} />
              </div>
            )}
          </nav>
          <SidebarBillingCta menuIsCollapsed={menuIsCollapsed} />
          <div className="text-sm py-2 border-r border-t">
            <SidebarSettings
              menuIsCollapsed={menuIsCollapsed}
              onToggleMenu={handleToggleMenu}
            />
          </div>
        </div>

        <div
          className={classNames(
            "min-h-full flex flex-col flex-1 min-w-0",
            menuIsCollapsed ? "md:pl-16" : "md:pl-52 lg:pl-60"
          )}
        >
          {window.readOnlyMode && (
            <div className="p-3 bg-orange-300">
              Topicflow is currently in read-only mode. You may view content but
              not edit it.
            </div>
          )}

          {currentUser.hasOrgWithTooManyUsers && (
            <div className="px-6 py-2 min-h-14 flex gap-2 justify-between items-center bg-yellow-50 border-b align-middle text-sm antialiased">
              Your organization on the free tier currently has more than 10
              users.
              <BillingDialogCTA className="text-blue-link hover:underline">
                Contact us to upgrade.
              </BillingDialogCTA>
            </div>
          )}

          <div
            className="flex md:hidden sticky top-0 z-leftSidebar flex-shrink-0 h-14 bg-white border-b"
            aria-label="top-navbar"
          >
            <div className="flex-1 px-4 flex items-center">
              <Logo />
            </div>
            <button
              type="button"
              className="px-4 border-l border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
              id="js-mobile-navbar-toggle-button"
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main
            className={classNames(
              "flex-1 flex flex-col",
              waffle.flag_is_active("new-meeting-page")
                ? "bg-gray-50"
                : "bg-gradient"
            )}
          >
            {children}
          </main>
        </div>
      </div>
      <Notification />
    </>
  );
};

export default Layout;
