import { gql } from "@apollo/client";

export default gql`
  mutation reorderActionItemMutation(
    $artifactId: Int!
    $beforeArtifactId: Int
    $afterArtifactId: Int
    $contextType: String!
    $contextId: Int!
  ) {
    reorderArtifact(
      artifactId: $artifactId
      beforeArtifactId: $beforeArtifactId
      afterArtifactId: $afterArtifactId
      contextType: $contextType
      contextId: $contextId
    ) {
      artifact {
        id
      }
    }
  }
`;
