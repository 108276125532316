import { useQuery } from "@apollo/client";

import Rating from "@apps/ratings/components/rating";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import getWysiwygArtifactQuery from "../graphql/get-wysiwyg-artifact-query";

const RatingView = ({
  node,
  extension,
  deleteNode,
  selected,
}: {
  node: any;
  extension: any;
  deleteNode: () => void;
  selected: boolean;
}) => {
  const { data, loading } = useQuery(getWysiwygArtifactQuery, {
    variables: {
      artifactId: node.attrs.id,
    },
    onError: onNotificationErrorHandler(),
  });
  const artifact = data?.artifact;

  return (
    <div
      className={classNames(
        "flex flex-col gap-4 border py-2 px-4 rounded bg-gray-50",
        selected && "ring-2 ring-blue-200 ring-offset-2"
      )}
      contentEditable={false}
      aria-label="Artifact rating view"
    >
      {loading && !artifact?.rating ? (
        <Loading mini size="5" />
      ) : artifact?.rating ? (
        <Rating
          rating={artifact.rating}
          onDelete={deleteNode}
          artifact={artifact}
          meetingId={extension.options.meetingId}
          meetingGroupId={extension.options.meetingGroupId}
          ratingRequirement={undefined}
        />
      ) : null}
    </div>
  );
};

export default RatingView;
