import { useMutation } from "@apollo/client";
import {
  AnnotationIcon,
  DotsVerticalIcon,
  ExternalLinkIcon,
  LinkIcon,
  TrashIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import {
  ClickEvent,
  ControlledMenu,
  Menu,
  MenuDivider,
  MenuItem,
} from "@szhsin/react-menu";
import copy from "copy-to-clipboard";
import { noop } from "lodash";
import { useCallback, useState } from "react";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { TbArrowBarDown, TbArrowBarUp, TbArrowsDiagonal } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  GoalArtifactNode,
  GoalProgressType,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import CommentFormDialog from "@apps/comments/components/comment-form-dialog";
import useLabel from "@apps/use-label/use-label";
import cache, { errorNotificationVar } from "@cache/cache";
import { successNotificationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteArtifactMutation from "./graphql/delete-artifact-mutation";

const ArtifactDropdownMenu = ({
  artifact,
  portal = true,
  size = "6",
  className = "",
  isInWysiwyg = false,
  isContextMenu = false,
  descriptionIsExpanded = false,
  onToggleDescription,
  anchorPoint,
  onToggleMenu = noop,
  onDelete = noop,
  onSavedComment = noop,
  onRemoveComment,
  ...menuProps
}: {
  artifact: {
    id: number;
    artifactType: ArtifactType;
    title?: string | null;
    progressType?: GoalProgressType;
    canDelete: {
      permission: boolean;
    };
  };
  portal?: boolean;
  size?: string;
  className?: string;
  isInWysiwyg?: boolean;
  isContextMenu?: boolean;
  descriptionIsExpanded?: boolean;
  onToggleDescription?: () => void;
  anchorPoint?: any;
  onToggleMenu?: (bool: boolean) => void;
  onDelete?: (obj?: { artifact: any }) => void;
  onSavedComment?: (comment: any) => void;
  onRemoveComment?: () => void;
}) => {
  const l = useLabel();
  const artifactName = l(artifact.artifactType);
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const artifactUrl = getUrl({
    artifactType: artifact.artifactType,
    artifactId: artifact.id,
  });
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    `Are you sure you want to delete this ${artifactName}?`
  );

  const [isOpeningCommentFormDialog, setIsOpeningCommentFormDialog] =
    useState(false);
  const [isOpeningCheckinDialog, setIsOpeningCheckinDialog] = useState(false);

  const handleOpenSidebar = () => {
    link.redirect(
      toWithBackground({
        pathname: artifactUrl,
        location,
      })
    );
  };
  const handleCopyLink = () => {
    copy(`${window.location.origin}${artifactUrl}`);
    successNotificationVar({
      title: "Url copied.",
    });
  };

  /* Mutations */
  const [deleteArtifact] = useMutation(deleteArtifactMutation, {
    onCompleted: () => {
      cache.evict({ id: cache.identify(artifact) });
      cache.gc();
      onDelete({ artifact });
    },
    onError: () => {
      errorNotificationVar({
        description: `This ${artifactName} could not be deleted.`,
      });
    },
  });

  const handleSavedComment = (comment: any) => {
    setIsOpeningCommentFormDialog(false);
    onSavedComment(comment);
  };

  const handleRemoveArtifactFromNotes = () => {
    onDelete();
  };

  const handleClickEditDescriptionFullscreen = () => {
    link.redirect({
      pathname: `${artifactUrl}/fullscreen`,
      state: { previousPathname: location.pathname },
    });
  };

  /* Handler */
  const handleDeleteArtifact = useCallback(
    async (e: ClickEvent) => {
      const confirmation = await confirm();
      if (confirmation) {
        deleteArtifact({
          variables: { artifactId: artifact.id },
          optimisticResponse: {
            deleteArtifact: {
              artifact,
            },
          },
          update(cache) {
            const artifactCacheId = cache.identify(artifact);
            cache.evict({ id: artifactCacheId });
            const baseArtifactCacheId = cache.identify({
              __typename: "BaseArtifactNode",
              id: artifact.id,
            });
            cache.evict({ id: baseArtifactCacheId });
            cache.gc();
          },
          onError: onNotificationErrorHandler(),
        });
        e.stopPropagation = true;
      }
    },
    [artifact, confirm, deleteArtifact]
  );

  const items = (
    <>
      {artifact.id && (
        <>
          {isInWysiwyg && (
            <MenuItem contentEditable={false} onClick={handleOpenSidebar}>
              <ExternalLinkIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />{" "}
              Open
            </MenuItem>
          )}
          <MenuItem contentEditable={false} onClick={handleCopyLink}>
            <LinkIcon
              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />{" "}
            Copy link
          </MenuItem>

          <MenuItem
            contentEditable={false}
            onClick={handleClickEditDescriptionFullscreen}
          >
            <TbArrowsDiagonal
              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />{" "}
            Edit description full screen
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {artifact.id && artifact.artifactType === ArtifactType.Goal && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={() => setIsOpeningCheckinDialog(true)}
          >
            <TrendingUpIcon
              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />{" "}
            Update progress
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {isInWysiwyg && artifact.id && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={() => setIsOpeningCommentFormDialog(true)}
          >
            <AnnotationIcon
              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />{" "}
            Add a comment
          </MenuItem>
          {onRemoveComment && (
            <MenuItem contentEditable={false} onClick={onRemoveComment}>
              <AnnotationIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />{" "}
              Remove comment
            </MenuItem>
          )}
          <MenuDivider />
        </>
      )}
      {artifact.id && onToggleDescription && (
        <>
          <MenuItem contentEditable={false} onClick={onToggleDescription}>
            {descriptionIsExpanded ? (
              <TbArrowBarUp className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            ) : (
              <TbArrowBarDown className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            )}{" "}
            {descriptionIsExpanded ? "Collapse" : "Expand"} description
          </MenuItem>
          <MenuDivider />
        </>
      )}
      {isInWysiwyg && (
        <MenuItem
          onClick={handleRemoveArtifactFromNotes}
          data-testid="artifact-dropdown-remove-from-notes"
        >
          <MdOutlinePlaylistRemove
            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />{" "}
          Remove from notes
        </MenuItem>
      )}
      {artifact.id && artifact.canDelete.permission && (
        <>
          <ConfirmationDialog />
          <MenuItem
            onClick={handleDeleteArtifact}
            data-testid="artifact-dropdown-delete"
          >
            <TrashIcon
              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />{" "}
            Delete
          </MenuItem>
        </>
      )}
    </>
  );

  return (
    <>
      {isOpeningCommentFormDialog && (
        <CommentFormDialog
          artifactId={artifact.id}
          onClose={() => setIsOpeningCommentFormDialog(false)}
          onSavedComment={handleSavedComment}
        />
      )}
      {isOpeningCheckinDialog && artifact.progressType && (
        <CheckinDialog
          artifact={artifact as GoalArtifactNode}
          onClose={() => setIsOpeningCheckinDialog(false)}
        />
      )}
      {isContextMenu ? (
        <ControlledMenu
          {...menuProps}
          portal={portal}
          className="z-dropdown fs-unmask"
          anchorPoint={anchorPoint}
          onClose={() => onToggleMenu(false)}
        >
          {items}
        </ControlledMenu>
      ) : (
        <Menu
          portal={portal}
          align="end"
          transition={false}
          aria-label="Artifact dropdown menu list"
          className="text-sm not-prose z-dropdown fs-unmask"
          menuButton={
            <button
              className={classNames("text-gray-400 rounded", className)}
              data-testid="artifact-dropdown-button"
              aria-label="Artifact dropdown menu button"
            >
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon
                className={`h-${size} w-${size}`}
                aria-hidden="true"
              />
            </button>
          }
        >
          {items}
        </Menu>
      )}
    </>
  );
};
export default ArtifactDropdownMenu;
