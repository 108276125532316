import { useMutation } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { GoalScope, GoalState } from "types/graphql-schema";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import getDashboardGoalsQuery from "@apps/dashboard/graphql/get-dashboard-goals-query";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import GoalIcon from "@components/goal-icon/goal-icon";
import { useLink } from "@components/link/link";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  classNames,
  getGoalTextColorClassName,
  listBoxButtonClassName,
} from "@helpers/css";

import updateArtifactMutation from "../graphql/update-artifact-mutation";

export const GoalScopeDropdownInput = ({
  artifact,
  onChangeScope,
  className,
  iconSize,
}: {
  artifact: {
    scope: GoalScope;
    state: GoalState;
    canUpdate: { permission: boolean };
  };
  onChangeScope: (scope: SelectOption<GoalScope>) => void;
  className?: string;
  iconSize: string | number;
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();
  return (
    <Select<GoalScope>
      disabled={!artifact.canUpdate.permission}
      options={[
        {
          value: GoalScope.Personal,
          label: label("goal", { capitalize: true }),
        },
        {
          value: GoalScope.Team,
          label: `${label("team", { capitalize: true })} ${label("goal")}`,
          description: !currentUser.paidFeatures.canCreateGroupGoals
            ? "Upgrade to a higher tier"
            : "",
        },
        {
          value: GoalScope.Organization,
          label: `${label("organization", { capitalize: true })} ${label(
            "goal"
          )}`,
          description: !currentUser.paidFeatures.canCreateGroupGoals
            ? "Upgrade to a higher tier"
            : "",
        },
        {
          value: GoalScope.Career,
          label: `${label("career", { capitalize: true })} ${label("goal")}`,
          description: !currentUser.paidFeatures.canCreateGroupGoals
            ? "Upgrade to a higher tier"
            : "",
        },
      ]}
      value={artifact.scope}
      onChange={onChangeScope}
    >
      {({ selected, setReferenceElement, disabled }) => (
        <Listbox.Button
          className={classNames(
            listBoxButtonClassName({ disabled }),
            className
          )}
          aria-label="Goal scope dropdown button"
          ref={setReferenceElement}
        >
          <GoalIcon
            state={artifact.state}
            scope={artifact.scope}
            size={iconSize}
          />
          <span
            className={classNames(
              "block truncate",
              getGoalTextColorClassName(artifact.scope)
            )}
          >
            {selected?.label}
          </span>
          {!disabled && (
            <SelectorIcon
              className={`h-${iconSize} w-${iconSize} text-gray-400 pointer-events-none`}
              aria-hidden="true"
            />
          )}
        </Listbox.Button>
      )}
    </Select>
  );
};

const GoalScopeDropdown = ({
  artifact,
  className,
  iconSize = "4",
}: {
  artifact: {
    id: number;
    scope: GoalScope;
    state: GoalState;
    canUpdate: { permission: boolean };
  };
  className?: string;
  iconSize?: number | string;
}) => {
  const link = useLink();
  const currentUser = currentUserVar();
  const [updateArtifact] = useMutation(updateArtifactMutation);

  const handleChangeScope = (option: SelectOption<GoalScope>) => {
    if (
      !currentUser.paidFeatures.canCreateGroupGoals &&
      option.value !== GoalScope.Personal
    ) {
      return link.redirect("/billing");
    }
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { goalScope: option.value },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            scope: option.value,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      refetchQueries: [getDashboardGoalsQuery, getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <GoalScopeDropdownInput
      artifact={artifact}
      onChangeScope={handleChangeScope}
      className={className}
      iconSize={iconSize}
    />
  );
};

export default GoalScopeDropdown;
