import { useReactiveVar } from "@apollo/client";
import { Editor, EditorOptions } from "@tiptap/core";
import { EditorContent } from "@tiptap/react";
import { forwardRef, useEffect } from "react";

import { currentTiptapJWTVar, currentUserVar } from "@cache/cache";
import { classNames } from "@helpers/css";

import BubbleMenu from "./extensions/bubble-menu";
import { getExtensions } from "./helpers";
import { allSuggestion } from "./mentions/all-suggestions";
import useEditor from "./use-editor";

type Props = {
  autofocus?: boolean;
  placeholder?: string;
  value: any;
  editable?: boolean;
  meetingGroupId?: number;
  topicId?: number;
  className?: string;
  onChange?: (value: any) => void;
  mentionsConfig: {
    meetingGroupId?: number;
    meetingId?: number;
    artifactId?: number;
  };
  uploadVariable: {
    topicId?: number;
    artifactId?: number;
  };
  onUpdateContent?: any;
  mentions?: any;
  meetingId?: number;
  organizationId?: number | null;
  highlightSearchQuery?: string;
};
const CommentWYSIWYG = forwardRef<
  Editor | null,
  Props & Partial<Omit<EditorOptions, "extensions" | "content" | "onUpdate">>
>(
  (
    {
      value,
      onChange,
      onUpdateContent = null,
      className,
      placeholder = "Leave a comment",
      autofocus = false,
      editable = false,
      mentions,

      // upload variables { artifactId or topicId }
      uploadVariable,

      // config
      mentionsConfig,
      topicId = null,
      meetingId,
      meetingGroupId = null,
      organizationId = null,
      editorProps = {},
      highlightSearchQuery = "",
      ...props
    },
    ref
  ) => {
    const currentUser = currentUserVar();
    const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);
    const { id, name, avatar } = currentUser;

    const atMentionSuggestions = allSuggestion(mentionsConfig);

    const context = {
      topicId,
      relatedTopicId: topicId,
      meetingId: meetingId,
      meetingGroupId: meetingGroupId,
      organizationId: organizationId,
      searchQuery: highlightSearchQuery,
      currentUser: { id, name, avatar },
    };

    const editor = useEditor(
      {
        autofocus,
        editable,
        // when creating/updating extensions, never rename extension.name
        // otherwise previous discussion notes won't be able to
        // render the content of that extension.
        extensions: getExtensions({
          paidFeatures: currentUser.paidFeatures,
          tiptapAiJwt,
          placeholder,
          emptyPlaceholder: null,
          uploadVariable,
          atMentionSuggestions,
          context,
          history: true,
        }),
        editorProps: {
          attributes: {
            class: classNames(
              "prose max-w-full focus:outline-none break-words relative text-sm js-topic-discussion-notes-input",
              className
            ),
            "data-testid": "wysiwyg",
          },
          ...editorProps,
        },
        // when WebRTC enable, it seems we should not use content
        // https://github.com/ueberdosis/tiptap/discussions/2193
        content: value,
        onUpdate: ({ editor }) => {
          if (onChange) {
            onChange(editor.getJSON());
          }
        },
        ...props,
      },
      [tiptapAiJwt]
    );

    useEffect(() => {
      if (onUpdateContent) {
        editor?.on("update", onUpdateContent);
      }
      return function cleanup() {
        if (onUpdateContent) {
          editor?.off("update", onUpdateContent);
        }
      };
    }, [onUpdateContent, editor]);
    if (ref && typeof ref !== "function") {
      ref.current = editor;
    }
    return (
      <>
        {editable && editor && !editor.isDestroyed && (
          <BubbleMenu
            enableComment={false}
            editor={editor}
            meetingId={meetingId}
            topicId={topicId}
            organizationId={organizationId}
          />
        )}
        <EditorContent editor={editor} />
      </>
    );
  }
);

export default CommentWYSIWYG;
