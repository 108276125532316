import { Editor } from "@tiptap/core";
import { KeyboardEvent } from "react";
import {
  ArtifactType,
  GetLoggedInUserQuery,
  GoalStatus,
  LoggedInUserOrgFragment,
} from "types/graphql-schema";

import { artifactType, typename } from "@helpers/constants";
import {
  isArrowDownEvent,
  isArrowUpEvent,
  isBackSpaceEvent,
  isEnterEvent,
  isShiftEnterEvent,
} from "@helpers/helpers";

export const handleKeyDownEvent = ({
  e,
  editor,
  getPos,
  onDelete,
  onExpandArtifactDescription,
}: {
  e: KeyboardEvent<Element> | undefined;
  editor: any;
  getPos: () => number;
  onDelete: (_e?: any, callback?: () => void) => void;
  onExpandArtifactDescription: () => void;
}) => {
  if (!e) return;
  const target = e.target as HTMLElement & { editor: Editor };
  const { editor: artifactEditor } = target;
  const { selection } = artifactEditor.state;
  const { from, to } = selection;
  // const pos = getPos();

  // in case user has focused directly on the artifact editor and bypassing the topic tiptap editor
  // if (editor.state.selection.from !== pos) {
  //   editor.chain().focus(getPos()).run();
  // }

  // press enter go to next node in editor
  if (isShiftEnterEvent(e)) {
    e.stopPropagation();
    e.preventDefault();
    onExpandArtifactDescription();
    return;
  }

  if (isEnterEvent(e)) {
    e.stopPropagation();
    e.preventDefault();
    const isInList =
      editor.isActive("bulletList") || editor.isActive("orderedList");

    const commandChain = editor
      .chain()
      .focus(getPos() + 1)
      .insertContent("<p></p>");
    if (isInList) {
      commandChain.wrapInList("listItem").run();
    } else {
      commandChain.run();
    }
  }

  // press arrow down on last letter in input, go to next node in editor
  if (isArrowDownEvent(e)) {
    const cursorPosition = target.editor.state.selection.$anchor.pos;
    if (cursorPosition >= target.editor.getText().length) {
      e.stopPropagation();
      e.preventDefault();
      // when next node uses a gap cursor we increment one less to show gap cursor
      const nodeAfter = editor.state.doc.resolve(getPos() + 1).nodeAfter;
      const incrementPosition =
        nodeAfter && nodeAfter.type.spec.allowGapCursor ? 1 : 2;
      editor
        .chain()
        .focus(getPos() + incrementPosition) // when another artifact
        .focusWithGapcursor()
        .run();
    }
  }

  if (isArrowUpEvent(e)) {
    const cursorPosition = target.editor.state.selection.$anchor.pos;
    if (cursorPosition === 0) {
      e.stopPropagation();
      e.preventDefault();
      const nodeBefore = editor.state.doc.resolve(getPos()).nodeBefore;
      const decrementPosition =
        nodeBefore && nodeBefore.type.spec.allowGapCursor ? 0 : 1;
      editor
        .chain()
        .focus(getPos() - decrementPosition)
        .focusWithGapcursor()
        .run();
    }
  }

  if (isBackSpaceEvent(e) && from === 0 && to === 0) {
    onDelete(null, () => {
      editor
        .chain()
        .focus(getPos() - 1)
        .focusWithGapcursor()
        .run();
    });
  }
};

export const deleteArtifact = ({
  artifact,
  deleteNode,
  callback,
}: {
  artifact: any;
  deleteNode: () => void;
  callback?: () => void;
}) => {
  if (artifact.id) {
    deleteNode();
    if (callback) callback();
    return;
  }
  deleteNode();
  if (callback) callback();
};

export const getDefaultArtifact = (
  artifactTypeName: string,
  currentUser: GetLoggedInUserQuery["me"],
  defaultTitle = "",
  currentOrganization: LoggedInUserOrgFragment
) => {
  const defaultAttributes = {
    id: null,
    uuid: null,
    websocketToken: null,
    description: "",
    canRead: {
      permission: true,
    },
    canUpdate: {
      permission: true,
    },
    creator: {
      id: null,
      name: "",
      avatar: null,
    },
  };
  return artifactTypeName === artifactType.actionItem
    ? {
        ...defaultAttributes,
        title: defaultTitle,
        isComplete: false,
        actionItemState: null,
        dueDate: null,
        assignee: currentUser,
        artifactType: artifactType.actionItem,
        __typename: typename.ActionItemArtifactNode,
      }
    : artifactTypeName === artifactType.decision
    ? {
        ...defaultAttributes,
        decision: defaultTitle,
        artifactType: artifactType.decision,
        decisionState: currentOrganization.defaultDecisionState,
        __typename: typename.DecisionArtifactNode,
      }
    : artifactTypeName === artifactType.feedback
    ? {
        ...defaultAttributes,
        title: defaultTitle,
        artifactType: artifactType.feedback,
        __typename: "FeedbackArtifactnode",
      }
    : artifactTypeName === artifactType.document
    ? {
        ...defaultAttributes,
        title: defaultTitle,
        artifactType: artifactType.document,
        __typename: typename.DocumentArtifactNode,
      }
    : artifactTypeName === ArtifactType.Recognition
    ? {
        ...defaultAttributes,
        title: defaultTitle,
        artifactType: ArtifactType.Recognition,
        recipients: {
          totalCount: 0,
          edges: [],
        },
        __typename: typename.RecognitionArtifactNode,
      }
    : artifactTypeName === artifactType.kpi
    ? {
        ...defaultAttributes,
        artifactType: artifactType.kpi,
        __typename: typename.KPIArtifactNode,
      }
    : artifactTypeName === artifactType.goal
    ? {
        ...defaultAttributes,
        title: defaultTitle,
        progress: 0,
        state: currentOrganization.defaultGoalState,
        decisionState: currentOrganization.defaultDecisionState,
        status: GoalStatus.None,
        currentUserIsOwner: true,
        dueDate: null,
        assignee: currentUser,
        artifactType: artifactType.goal,
        firstOwners: {
          edges: [],
        },
        __typename: typename.GoalArtifactNode,
      }
    : null;
};
