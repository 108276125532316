import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables,
} from "types/graphql-schema";

import getLoggedInUserQuery from "@apps/main/graphql/get-logged-in-user-query";
import Pusher from "@apps/pusher/pusher";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Container from "@components/container/container";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import ExtensionMeetingContainer from "./components/container";
import ExtensionMeetings from "./components/meetings";

export default function ExtensionRoutes() {
  const { path } = useRouteMatch();
  const currentUser = useReactiveVar(currentUserVar);
  const currentOrganization = useReactiveVar(currentOrganizationVar);
  const { loading, data } = useQuery<
    GetLoggedInUserQuery,
    GetLoggedInUserQueryVariables
  >(getLoggedInUserQuery, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data && data.me) {
        const organizations = assertEdgesNonNull(data.me.organizations);
        const organization =
          organizations.find(({ personal }) => !personal) ||
          organizations[0] ||
          null;
        currentUserVar(data.me);
        currentOrganizationVar(organization);
      }
    },
    onError: onNotificationErrorHandler(),
  });

  useEffect(() => {
    document.querySelector("html")?.classList.add("text-sm");
    if (window?.Intercom) {
      window.Intercom("shutdown");
    }
    return function cleanup() {
      document.querySelector("html")?.classList.remove("text-sm");
    };
  });

  if (
    (!data && loading) ||
    (data?.loggedIn && !currentUser) ||
    !currentOrganization
  ) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (!data?.loggedIn) {
    return (
      <Container>
        <div className="mx-4">
          <Error title="You must be logged in to Topicflow." />
        </div>
        <div className="mt-6 mx-4 flex justify-center gap-8 text-gray-500">
          <AppLink
            to="/"
            target="_blank"
            className="text-blue-600 hover:underline"
          >
            Sign up
          </AppLink>
          <AppLink
            to="/"
            target="_blank"
            className="text-blue-600 hover:underline"
          >
            Log in
          </AppLink>
        </div>
      </Container>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={[`${path}/meeting/:meetingGroupId/:meetingId`, `${path}/meeting`]}
      >
        <Pusher>
          <ExtensionMeetingContainer />
        </Pusher>
      </Route>
      <Route path={`${path}/meetings`}>
        <Pusher>
          <ExtensionMeetings />
        </Pusher>
      </Route>
    </Switch>
  );
}
