import { gql } from "@apollo/client";

export default gql`
  query getDecisionsPage($meetingGroupId: Int, $flowId: Int, $after: String) {
    topics(
      hasDecisions: true
      orderBy: "-meeting__start_datetime"
      meetingInMeetingGroupId: $meetingGroupId
      meetingInFlowId: $flowId
      first: 20
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          state
          artifacts(artifactType: decision) {
            edges {
              node {
                canDelete {
                  permission
                }
                id
                artifactType
                title
                ... on DecisionArtifactNode {
                  decision
                  isDraft
                  decisionState
                }
              }
            }
          }
          meeting {
            id
            meetingGroupId
            startDatetime
          }
        }
      }
    }
  }
`;
