import { gql } from "@apollo/client";

export default gql`
  query getArtifactExplorerMeetingGroupQuery(
    $meetingGroupId: Int!
    $search: String
    $fetchMeetingGroup: Boolean!
    $organizationId: Float!
  ) {
    meetingGroups(organizationId: $organizationId, search: $search, first: 10) {
      edges {
        node {
          id
          recurrenceRule
          meetings(last: 1, orderBy: "-start_datetime") {
            edges {
              node {
                id
                meetingGroupId
                title
                startDatetime
              }
            }
          }
        }
      }
    }
    meetingGroup(meetingGroupId: $meetingGroupId)
      @include(if: $fetchMeetingGroup) {
      id
      recurrenceRule
      meetings(last: 1, orderBy: "-start_datetime") {
        edges {
          node {
            id
            meetingGroupId
            title
            startDatetime
          }
        }
      }
    }
  }
`;
