// import { EditorView } from "@tiptap/pm/view";
import { Editor, EditorOptions, PureEditorContent } from "@tiptap/react";
import { useEffect, useState } from "react";

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

// When changing page quickly, it shows error related to the CollaborationCursor extension
// It seems like the extension tries to update the editor when it is already destroyed.
// https://github.com/ueberdosis/tiptap/issues/2380#issuecomment-1010815190
// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-954856305
// https://github.com/ueberdosis/tiptap/commit/44e47222a03be60835d5058d01bc510e4cf31d60
// EditorView.prototype.updateState = function updateState(state) {
//   if (!this.docView) return; // This prevents the matchesNode error on hot reloads
//   this.updateStateInner(state, this.state.plugins !== state.plugins);
// };
PureEditorContent.prototype.init = function () {
  const { editor } = this.props;

  if (editor && editor.options.element) {
    if (editor.contentComponent) {
      return;
    }

    const element = this.editorContentRef.current;
    element.append(...editor.options.element.childNodes);
    editor.setOptions({ element });
    editor.contentComponent = this;

    // is there an alternative to setTimeout?
    setTimeout(() => {
      if (!editor.isDestroyed) {
        editor.createNodeViews();
      }
    }, 0);
  }
};
//-----------------------------------------

// https://github.com/ueberdosis/tiptap/tree/main/packages/react/src
export default function useEditor(
  { markdown, ...options }: Partial<EditorOptions> & { markdown?: unknown },
  deps: unknown[] = []
) {
  const [editor, setEditor] = useState<Editor | null>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let isMounted = true;
    const instance = new Editor(options);
    setEditor(instance);

    instance.on("transaction", () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (isMounted && !instance.isDestroyed) forceUpdate();
        });
      });
    });

    return () => {
      instance.destroy();
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return editor;
}
