import { compact, isEqual, omit } from "lodash";
import moment from "moment";
import {
  ArtifactType,
  ExplorerResultFragmentFragment,
  GetExplorerResultsQueryQueryVariables,
  GoalScope,
  GoalState,
  GoalStatus,
  GoalVisibility,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import { getLabel } from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import { dateRangeToDateArray } from "@helpers/helpers";

const valueSeparator = ","; // can't use - as it messes up with negative values.
export const explorerGithubType = "github";
export const explorerJiraType = "jira";
export const explorerHubspotType = "hubspot";
export const explorerUserType = "user";
export const explorerMeetingType = "meeting";
type ExplorerTypeMeetingType = typeof explorerMeetingType;
type ExplorerTypeGithubType = typeof explorerGithubType;
type ExplorerTypeJiraType = typeof explorerJiraType;
type ExplorerTypeHubspotType = typeof explorerHubspotType;
type ExplorerTypeUserType = typeof explorerUserType;
export type ExplorerTypeArtifactType =
  | ArtifactType.ActionItem
  | ArtifactType.Goal
  | ArtifactType.Decision
  | ArtifactType.Document
  | ArtifactType.Recognition
  | ArtifactType.Feedback;
export type ExplorerTypeType =
  | ExplorerTypeMeetingType
  | ExplorerTypeArtifactType
  | ExplorerTypeUserType
  | ExplorerTypeJiraType
  | ExplorerTypeGithubType
  | ExplorerTypeHubspotType
  | null;

export type ExplorerFilterType = {
  search?: string;
  type?: ExplorerTypeType;
  goalScope?: GoalScope | null;
  actionItemState?: number[] | null;
  actionItemAssignee?: number | null;
  actionItemAssignedToMembersOfTeam?: number | null;
  assignedToMembersOfOrganizationId?: number | null;
  goalOwners?: number | Array<number> | null;
  goalTeams?: Array<number> | null;
  goalState?: Array<GoalState> | null;
  goalStatus?: GoalStatus | null;
  goalVisibility?: GoalVisibility | null;
  meetingId?: number | null;
  meetingParticipants?: number[] | null;
  meetingGroupId?: number | null;
  meetingsBeforeDatetime?: string | null;
  meetingsAfterDatetime?: string | null;
  meetingIsDraft?: boolean | null;
  meetingIsFormalOneonone?: boolean | null;
  hubspotStageId?: number | null;
  feedbackRecipient?: number | null;
  recognitionRecipient?: number | null;
  recognitionCoreValue?: number | null;
  createdBy?: number | null;
  isStale?: boolean | null;
  orderBy?: string | null;
  groupBy?: string | null;
  dueBetweenDates?: DateRangeEnum | Array<string> | null;
  createdBetweenDates?: DateRangeEnum | Array<string> | null;
};

export const testIsAllowedArtifactType = (filterType: ExplorerTypeType) => {
  const currentOrganization = currentOrganizationVar();
  return (
    (filterType === ArtifactType.ActionItem &&
      currentOrganization.featureFlags.actionItems) ||
    (filterType === ArtifactType.Goal &&
      currentOrganization.featureFlags.goals) ||
    (filterType === ArtifactType.Decision &&
      currentOrganization.featureFlags.decisions) ||
    (filterType === ArtifactType.Document &&
      currentOrganization.featureFlags.documents) ||
    (filterType === ArtifactType.Recognition &&
      currentOrganization.featureFlags.recognitions) ||
    (filterType === ArtifactType.Feedback &&
      currentOrganization.featureFlags.feedbacks)
  );
};

export const explorerAllowedArtifactTypes = (): ExplorerTypeArtifactType[] => {
  const currentOrganization = currentOrganizationVar();
  return compact([
    currentOrganization.featureFlags.actionItems && ArtifactType.ActionItem,
    currentOrganization.featureFlags.goals && ArtifactType.Goal,
    currentOrganization.featureFlags.decisions && ArtifactType.Decision,
    currentOrganization.featureFlags.documents && ArtifactType.Document,
    currentOrganization.featureFlags.actionItems && ArtifactType.Recognition,
    currentOrganization.featureFlags.feedbacks && ArtifactType.Feedback,
  ]);
};

export const isIntegrationSetup = (integrationType?: ExplorerTypeType) => {
  const currentUser = currentUserVar();
  return (
    (integrationType === explorerHubspotType && currentUser.hasHubspotAuth) ||
    (integrationType === explorerGithubType && currentUser.hasGithubAuth) ||
    (integrationType === explorerJiraType && currentUser.hasJiraAuth)
  );
};

export const getDefaultFilters = (): ExplorerFilterType => {
  return {
    search: "",
    type: null,
    goalScope: null,
    actionItemState: null,
    actionItemAssignee: null,
    actionItemAssignedToMembersOfTeam: null,
    assignedToMembersOfOrganizationId: null,
    goalOwners: null,
    goalTeams: null,
    goalState: null,
    goalStatus: null,
    isStale: null,
    goalVisibility: null,
    meetingGroupId: null,
    meetingId: null,
    meetingsBeforeDatetime: null,
    meetingsAfterDatetime: null,
    meetingIsDraft: null,
    meetingParticipants: null,
    meetingIsFormalOneonone: null,
    hubspotStageId: null,
    feedbackRecipient: null,
    recognitionRecipient: null,
    recognitionCoreValue: null,
    createdBy: null,
    orderBy: "-updated",
    groupBy: null,
    dueBetweenDates: null,
    createdBetweenDates: null,
  };
};

export const filtersWithMultipleValues = ["actionItemState", "goalState"];
export const groupByValues = ["actionItemState", "goalState"];

export const getFlattenExplorerResult = (
  result: ExplorerResultFragmentFragment
) => {
  if (result.__typename === "BaseArtifactNode") {
    return result.specificArtifact;
  }
  if (result.__typename === "MeetingWithRelevantSectionsNode") {
    return result.meeting;
  }
  return result;
};

const getExplorerFiltersSearchParams = (filters: ExplorerFilterType) => {
  const defaultFilters = getDefaultFilters();
  const newFilters = { ...defaultFilters, ...(filters || {}) };
  const params = new URLSearchParams();
  const filterKeys = Object.keys(defaultFilters) as Array<
    keyof ExplorerFilterType
  >;
  filterKeys.forEach((key) => {
    const newValue = newFilters[key];
    const defaultValue = defaultFilters[key];
    if (!isEqual(newValue, defaultValue)) {
      if (Array.isArray(newValue)) {
        params.append(key, newValue.join(valueSeparator));
      } else {
        params.append(key, `${newValue}`);
      }
    }
  });
  return params.toString() ? `?${params.toString()}` : "";
};

export const getExplorerFiltersUrl = (filters: ExplorerFilterType) => {
  const currentOrganization = currentOrganizationVar();
  if (currentOrganization.featureFlags.explorer) {
    return `/explorer${getExplorerFiltersSearchParams(filters || {})}`;
  }
  return "/";
};

const splitArrInt = (value: string): number[] | null => {
  if (value !== null) {
    const values = compact(
      value.split(valueSeparator.trim()).map((val) => parseInt(val))
    );
    if (values.length === 0) return null;
    return values;
  }
  return value;
};

const oneOf = (allowedValues: any[]) => (value: any) => {
  if (allowedValues.includes(value)) {
    return value;
  }
  return null;
};

const testDateRange = (value: string): string[] | DateRangeEnum | null => {
  if (value !== null) {
    if (Object.values<string>(DateRangeEnum).includes(value)) {
      return value as DateRangeEnum;
    }
    const values = compact(
      value.split(valueSeparator).map((val) => val.trim())
    );
    if (values.length !== 2) return null;
    const startDate = moment(values[0], "YYYY-MM-DD", true);
    const endDate = moment(values[1], "YYYY-MM-DD", true);
    if (!startDate.isValid() || !endDate.isValid()) return null;
    if (startDate.isAfter(endDate)) return null;
    return values;
  }
  return null;
};

const splitArrString = (value: string): string[] | null => {
  if (value !== null) {
    const values = compact(
      value.split(valueSeparator).map((val) => val.trim())
    );
    if (values.length === 0) return null;
    return values;
  }
  return value;
};

const testBooleanString = (value: string): boolean => {
  return String(value).toLowerCase() === "true";
};

const testMatchValues = (possibleValues: any[]) => (value: any) => {
  if (possibleValues.includes(value)) {
    return value;
  }
  return null;
};

const sanitizeExplorerFilterUrlSearch = (
  key: keyof ExplorerFilterType,
  fn: null | ((val: any) => any),
  locationSearch: any
) => {
  const urlParams = new URLSearchParams(locationSearch);
  if (!urlParams.has(key)) {
    return getDefaultFilters()[key];
  }
  const val = urlParams.get(key);
  return fn ? fn(val) : val;
};

export const getSanitizedExplorerFiltersFromUrlSearch = (
  locationSearch: any
): ExplorerFilterType => {
  const sanitize = (
    key: keyof ExplorerFilterType,
    clean: ((val: any) => any) | null = null
  ) => sanitizeExplorerFilterUrlSearch(key, clean, locationSearch);

  const validTypes = [
    ...explorerAllowedArtifactTypes(),
    explorerUserType,
    explorerMeetingType,
    explorerJiraType,
    explorerGithubType,
    explorerHubspotType,
  ];
  const type = sanitize("type", testMatchValues(validTypes)) || null;
  const isGoalType = type === ArtifactType.Goal;
  const isArtifactType = explorerAllowedArtifactTypes().includes(type);
  const isActionItemType = type === ArtifactType.ActionItem;
  const isMeetingType = type === explorerMeetingType;
  const goalScope = isGoalType ? sanitize("goalScope") : null;
  const sanitizedGroupBy = sanitize("groupBy", oneOf(groupByValues));
  let groupBy = null;
  if (
    (isActionItemType && sanitizedGroupBy === "actionItemState") ||
    (isGoalType && sanitizedGroupBy === "goalState")
  ) {
    groupBy = sanitizedGroupBy;
  }
  const sanitizedFilters = {
    search: sanitize("search"),
    orderBy: sanitize("orderBy"),
    groupBy,
    type,
    actionItemState: isActionItemType
      ? sanitize("actionItemState", splitArrInt)
      : null,
    actionItemAssignee: isActionItemType
      ? sanitize("actionItemAssignee", parseInt)
      : null,
    assignedToMembersOfOrganizationId: isArtifactType
      ? sanitize("assignedToMembersOfOrganizationId", parseInt)
      : null,
    goalState: isGoalType ? sanitize("goalState", splitArrString) : null,
    goalStatus: type === ArtifactType.Goal ? sanitize("goalStatus") : null,
    goalScope,
    goalVisibility: isGoalType ? sanitize("goalVisibility") : null,
    goalOwners: isGoalType ? sanitize("goalOwners", splitArrInt) : null,
    goalTeams:
      isGoalType && goalScope === GoalScope.Team
        ? sanitize("goalTeams", splitArrInt)
        : null,
    isStale: isGoalType ? sanitize("isStale", testBooleanString) : null,
    actionItemAssignedToMembersOfTeam: isActionItemType
      ? sanitize("actionItemAssignedToMembersOfTeam", parseInt)
      : null,
    recognitionRecipient:
      type === ArtifactType.Recognition
        ? sanitize("recognitionRecipient", parseInt)
        : null,
    feedbackRecipient:
      type === ArtifactType.Feedback
        ? sanitize("feedbackRecipient", parseInt)
        : null,
    recognitionCoreValue:
      type === ArtifactType.Recognition
        ? sanitize("recognitionCoreValue", parseInt)
        : null,
    dueBetweenDates:
      isGoalType || isActionItemType
        ? sanitize("dueBetweenDates", testDateRange)
        : null,
    createdBetweenDates: isArtifactType
      ? sanitize("createdBetweenDates", testDateRange)
      : null,
    meetingGroupId: isArtifactType
      ? sanitize("meetingGroupId", parseInt)
      : null,
    meetingId: isArtifactType ? sanitize("meetingId", parseInt) : null,
    meetingsBeforeDatetime: isMeetingType
      ? sanitize("meetingsBeforeDatetime")
      : null,
    meetingsAfterDatetime: isMeetingType
      ? sanitize("meetingsAfterDatetime")
      : null,
    meetingIsDraft: isMeetingType
      ? sanitize("meetingIsDraft", testBooleanString)
      : null,
    meetingParticipants: isMeetingType
      ? sanitize("meetingParticipants", splitArrInt)
      : null,
    meetingIsFormalOneonone: isMeetingType
      ? sanitize("meetingIsFormalOneonone", testBooleanString)
      : null,
    hubspotStageId:
      type === explorerHubspotType
        ? sanitize("hubspotStageId", parseInt)
        : null,
    createdBy: isArtifactType ? sanitize("createdBy", parseInt) : null,
  } as ExplorerFilterType;
  return sanitizedFilters;
};

export const getSanitizedExplorerFilters = (filters: ExplorerFilterType) => {
  const locationSearch = getExplorerFiltersSearchParams(filters || {});
  return getSanitizedExplorerFiltersFromUrlSearch(locationSearch);
};

export const isIntegrationType = (filters: ExplorerFilterType) => {
  const isJiraType = filters.type === explorerJiraType;
  const isGithubType = filters.type === explorerGithubType;
  const isHubspotType = filters.type === explorerHubspotType;
  return isGithubType || isJiraType || isHubspotType;
};

export const explorerIsFilteringArtifactType = (
  filters: ExplorerFilterType
) => {
  return filters.type && testIsAllowedArtifactType(filters.type);
};

export const isTypeAllowedForGroupBy = (filters: ExplorerFilterType) => {
  const arr = [
    ArtifactType.ActionItem,
    ArtifactType.Goal,
  ] as ExplorerTypeType[];
  return filters?.type && arr.includes(filters.type);
};

export const getQueryVariablesForExplorerFilters = (
  filters: ExplorerFilterType,
  limit = 20,
  after: string | null = null
): GetExplorerResultsQueryQueryVariables => {
  const hasNoType = !filters.type;
  const isUserType = filters.type === explorerUserType;
  const isMeetingType = filters.type === explorerMeetingType;
  const isArtifactType =
    filters.type &&
    testIsAllowedArtifactType(filters.type) &&
    !isMeetingType &&
    !isUserType;
  const isActionItemType = filters.type === ArtifactType.ActionItem;
  const isGoalType = filters.type === ArtifactType.Goal;
  const isGithubType = filters.type === explorerGithubType;
  const isJiraType = filters.type === explorerJiraType;
  const isHubspotType = filters.type === explorerHubspotType;
  const artifactFilters =
    (isArtifactType || hasNoType) && !isMeetingType && !isUserType
      ? {
          ...omit(
            filters,
            "search",
            "type",
            "hubspotStageId",
            "goalState",
            "actionItemState",
            "meetingsBeforeDatetime",
            "meetingsAfterDatetime",
            "meetingIsDraft",
            "meetingParticipants",
            "meetingIsFormalOneonone",
            "dueBetweenDates",
            "createdBetweenDates",
            "goalOwners"
          ),
          // handle state arrays
          actionItemStates: filters.actionItemState
            ? filters.actionItemState
            : undefined,
          goalStates: filters.goalState ? filters.goalState : undefined,
          artifactType: filters.type as ExplorerTypeArtifactType,
          artifactTypes: explorerAllowedArtifactTypes(),
          actionItemDueBetweenDates:
            isActionItemType && filters.dueBetweenDates
              ? dateRangeToDateArray({ range: filters.dueBetweenDates })
              : undefined,
          goalDueBetweenDates:
            isGoalType && filters.dueBetweenDates
              ? dateRangeToDateArray({ range: filters.dueBetweenDates })
              : undefined,
          goalOwners:
            isGoalType && filters.goalOwners
              ? Array.isArray(filters.goalOwners)
                ? filters.goalOwners
                : [filters.goalOwners]
              : undefined,
          createdBetweenDates:
            isArtifactType && filters.createdBetweenDates
              ? dateRangeToDateArray({ range: filters.createdBetweenDates })
              : undefined,
        }
      : null;
  const meetingFilters =
    !hasNoType && isMeetingType
      ? {
          meetingsBeforeDatetime: filters.meetingsBeforeDatetime,
          meetingsAfterDatetime: filters.meetingsAfterDatetime,
          meetingIsDraft: filters.meetingIsDraft,
          meetingIsFormalOneonone: filters.meetingIsFormalOneonone,
          meetingParticipants: filters.meetingParticipants,
        }
      : {};
  return {
    after,
    limit,
    search: filters.search || "",
    options: {
      resultTypes: {
        users: isUserType,
        meetings: isMeetingType,
        artifacts: isArtifactType || hasNoType,
        githubIssues: isGithubType,
        jiraIssues: isJiraType,
        hubspotDeals: isHubspotType,
      },
      artifactFilters,
      hubspotStageId: filters.hubspotStageId
        ? String(filters.hubspotStageId)
        : undefined,
      ...meetingFilters,
    },
  };
};

export const getTitleForExplorerUrl = (locationSearch: any) => {
  return getTitleForExplorerFilters(
    getSanitizedExplorerFiltersFromUrlSearch(locationSearch)
  );
};

export const getTitleForExplorerFilters = (filters: ExplorerFilterType) => {
  const label = getLabel();
  if (!filters.type && filters.search) {
    return `Search: ${filters.search}`;
  }
  if (filters.type === explorerUserType) {
    return `Users`;
  }
  if (filters.type === explorerMeetingType) {
    return `Meetings`;
  }
  if (filters.type === explorerGithubType) {
    return `Github search`;
  }
  if (filters.type === explorerHubspotType) {
    return `Hubspot search`;
  }
  if (filters.type === explorerJiraType) {
    return `Jira search`;
  }

  if (filters.type && explorerAllowedArtifactTypes().includes(filters.type)) {
    return label(filters.type, { pluralize: true, capitalize: true });
  }
  return "Explorer";
};

export const extractPageInfoFromData = (data: any) =>
  data?.search?.pageInfo || null;
