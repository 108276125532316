import { gql } from "@apollo/client";

export default gql`
  mutation changeActionItemStateMutation(
    $artifactId: Int!
    $additionalFields: AdditionalArtifactInput!
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        id
        ... on ActionItemArtifactNode {
          actionItemState
          isComplete
        }
      }
    }
  }
`;
