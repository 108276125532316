import { gql } from "@apollo/client";

export default gql`
  fragment UnmetComplainceProgramAssessmentGroup on AssessmentGroupNode {
    id
    title
  }
  fragment UnmetComplianceProgram on ComplianceProgramNode {
    id
    title
    dueDate
    usersMissingAssessment {
      edges {
        node {
          target {
            id
            name
            avatar
          }
          responder {
            id
            name
            avatar
          }
          assessmentGroupId
        }
      }
    }
    performanceAssessmentGroup {
      ...UnmetComplainceProgramAssessmentGroup
    }
    managerAssessmentGroup {
      ...UnmetComplainceProgramAssessmentGroup
    }
    peerAssessmentGroup {
      ...UnmetComplainceProgramAssessmentGroup
    }
    assessments(responderId: $currentUserId) {
      edges {
        node {
          id
          state
          target {
            id
            name
            avatar
          }
          group {
            id
          }
        }
      }
    }
  }
  query getMyAssessments(
    $organizationId: Int!
    $currentUserId: Int!
    $first: Int
    $afterPerformance: String
    $afterPeer: String
    $afterManager: String
    $orderBy: String!
  ) {
    unmetPerformanceAssessmentCompliancePrograms(
      organizationId: $organizationId
      orderBy: $orderBy
      first: $first
      after: $afterPerformance
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UnmetComplianceProgram
        }
      }
    }
    unmetPeerAssessmentCompliancePrograms(
      organizationId: $organizationId
      orderBy: $orderBy
      first: $first
      after: $afterPeer
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UnmetComplianceProgram
        }
      }
    }
    unmetManagerEffectivenessCompliancePrograms(
      organizationId: $organizationId
      orderBy: $orderBy
      first: $first
      after: $afterManager
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UnmetComplianceProgram
        }
      }
    }
  }
`;
