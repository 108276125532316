import { gql } from "@apollo/client";

const WorkHistoryGoalUpdateFragment = gql`
  fragment WorkHistoryGoalUpdateFragment on WorkHistoryGoalDataNode {
    isNew
    isClosed
    activityCount
    goal {
      id
      title
      state
      scope
    }
  }
`;

export default WorkHistoryGoalUpdateFragment;
