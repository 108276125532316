import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  GetMeetingNewPageQueryQuery,
  GetMeetingNewPageQueryQueryVariables,
  MeetingViewMeetingGroupNewPageFragmentFragment,
} from "types/graphql-schema";

import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import { ToggleButtonGroupType } from "@components/toggle-button-group/toggle-button-group";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePageVisibleDebounced from "@components/use-page-visibility/use-page-visible-debounced";
import { getUrl } from "@helpers/helpers";

import getMeetingQuery from "../graphql/get-meeting-query";
import MeetingPageHeader, { MeetingViewEnum } from "./header";
import Meeting from "./meeting";
import MeetingViewSidebar from "./meeting-sidebar/meeting-view-sidebar";
import MeetingSummary from "./prep/summary";

const AllMeetingsTab = ({
  meetingGroup,
}: {
  meetingGroup: MeetingViewMeetingGroupNewPageFragmentFragment;
}) => {
  const params = useParams<{ meetingId: string }>();
  const meetingId = parseInt(params.meetingId);
  const [meetingView, setMeetingView] = useState(MeetingViewEnum.notes);

  const { loading, error, data, refetch } = useQuery<
    GetMeetingNewPageQueryQuery,
    GetMeetingNewPageQueryQueryVariables
  >(getMeetingQuery, {
    variables: {
      meetingId,
      meetingGroupId: meetingGroup.id,
      today: moment().startOf("day").format(),
      hasMeetingId: !!meetingId,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleRefetch = () => {
    refetch();
  };

  const handleChangeMeetingView = (
    option: ToggleButtonGroupType<MeetingViewEnum>
  ) => {
    if (option?.value) setMeetingView(option.value);
  };

  usePageVisibleDebounced(handleRefetch, 2 * 60); // 2 minutes

  const meeting = data?.meeting;
  const closestMeetingId = // pick meeting closest to today
    data?.meetingGroup?.nextMeetings?.edges?.[0]?.node?.id || // or pick the next meeting
    data?.meetingGroup?.lastMeetings?.edges?.[0]?.node?.id; // or the last meeting that ever happened (case of past one of event)

  const title = meeting?.title || meetingGroup.title || "";
  useDocumentTitle(title);

  // Renders
  if (error) {
    return (
      <Container>
        <GraphqlError
          error={error}
          whatDidNotWork="The meeting could not be loaded."
        />
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (!loading && !meeting) {
    if (!meetingId && closestMeetingId) {
      return (
        <Container>
          <Redirect
            to={getUrl({
              meetingGroupId: meetingGroup.id,
              meetingId: closestMeetingId,
            })}
          />
        </Container>
      );
    }
    if (meetingId) {
      return (
        <Container>
          <GraphqlError
            error={new Error("The meeting could not be loaded")}
            whatDidNotWork="The meeting could not be loaded."
            descriptionList={[
              "This meeting does not exist anymore or you might not have access to it.",
            ]}
          />
        </Container>
      );
    }
  }

  if (!data || !meeting) {
    return null;
  }

  return (
    <>
      <Layout.Header
        className="bg-white"
        sidebarExpandedUiPreferenceKey="meetingSidebarExpanded"
        title={title}
        titleUrl={getUrl({
          meetingGroupId: meetingGroup.id,
          meetingId: meeting.id,
        })}
      >
        <MeetingPageHeader
          meetingGroup={meetingGroup}
          meeting={meeting}
          meetingView={meetingView}
          onChangeMeetingView={handleChangeMeetingView}
        />
      </Layout.Header>
      <Layout.Container>
        <Layout.Main sidebarExpandedUiPreferenceKey="meetingSidebarExpanded">
          {meetingView === MeetingViewEnum.notes ? (
            <>
              <div className="mb-6 mx-auto max-w-screen-lg">
                <Meeting
                  meeting={meeting}
                  opensTopicsInSidebar={false}
                  currentPageMeeting={meeting}
                />
              </div>
            </>
          ) : (
            <div className="mx-auto max-w-screen-lg">
              <MeetingSummary
                meeting={meeting}
                meetingGroupId={meetingGroup.id}
              />
            </div>
          )}
        </Layout.Main>
        <MeetingViewSidebar meeting={meeting} />
      </Layout.Container>
    </>
  );
};

export default AllMeetingsTab;
