import { useReactiveVar } from "@apollo/client";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { Editor, EditorContent } from "@tiptap/react";
import { compact } from "lodash";
import { ReactElementLike } from "prop-types";
import { useEffect, useState } from "react";

import { currentTiptapJWTVar } from "@cache/cache";
import useEditor from "@components/wysiwyg/use-editor";
import { classNames } from "@helpers/css";

import { getAiExtension } from "./helpers";

const TextWithNoBreakLinesDocument = Document.extend({
  name: "text-no-breaklines-document",
  content: "paragraph",
});

const TextWithNoBreakLinesWysiwyg = ({
  deps = [],
  value,
  editable = false,
  autofocus = false,
  placeholder = "",
  className = "",
  onChangeValue,
  renderAi = null,
}: {
  deps?: any[];
  value?: string | null;
  autofocus?: boolean;
  editable?: boolean;
  placeholder?: string;
  className?: string;
  onChangeValue?: (value: string) => void;
  renderAi:
    | (({
        editor,
        aiLoading,
      }: {
        editor: Editor;
        aiLoading: boolean;
      }) => ReactElementLike)
    | null;
}) => {
  const [aiLoading, setAiLoading] = useState(false);
  const enableAi = !!renderAi;
  const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);

  const content = !value // if falsy, return null
    ? null
    : value; // or we just return the string value.
  const editor = useEditor(
    {
      editable,
      autofocus,
      extensions: compact([
        Text,
        Paragraph,
        TextWithNoBreakLinesDocument,
        Placeholder.configure({
          placeholder: placeholder,
          showOnlyCurrent: true,
        }),
        enableAi &&
          tiptapAiJwt &&
          getAiExtension(tiptapAiJwt, {
            onError: () => setAiLoading(false),
            onSuccess: () => setAiLoading(false),
            onLoading: () => setAiLoading(true),
          }),
      ]),
      editorProps: {
        attributes: {
          class: classNames("prose", className),
        },
      },
      content,
    },
    [...deps, enableAi ? tiptapAiJwt : null]
  );

  useEffect(() => {
    const handleUpdateValue = ({ editor }: any) => {
      if (onChangeValue) onChangeValue(editor.getText());
    };
    editor?.on("update", handleUpdateValue);
    return function cleanup() {
      editor?.off("update", handleUpdateValue);
    };
  }, [editor, onChangeValue]);

  return (
    <>
      <EditorContent editor={editor} className="flex-1 min-w-0" />
      {renderAi && editor ? renderAi({ editor, aiLoading }) : null}
    </>
  );
};

export default TextWithNoBreakLinesWysiwyg;
