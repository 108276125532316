import { compact } from "lodash";
import { DashboardInsightFragment } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { assertNonNull } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

import { InsightButton, InsightLayout } from "../insight";

const AssessmentInsight = ({
  insight,
  onDismissInsight,
  selectedUser,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
  selectedUser: BasicUser;
}) => {
  const complianceProgram = assertNonNull(insight.complianceProgram);
  const allAssessmentGroups = compact([
    complianceProgram.performanceAssessmentGroup,
    complianceProgram.managerAssessmentGroup,
    complianceProgram.peerAssessmentGroup,
  ]);
  const assessmentGroup = assertNonNull(
    allAssessmentGroups.find(
      (g) => g.id === assertNonNull(insight.assessmentGroup?.id)
    )
  );
  const targetUserNames = insight.targetUsers.edges.map(
    (edge) => edge?.node?.firstName
  );

  // 1 target user = <first user>
  // 2 target users = <first user> and <second user>
  // 3 target users = <first user>, <second user> and <third user>
  // more than 3 target users = <first user>, <second user> and <total_count - 2> others
  const targetUserNameStr =
    insight.targetUsers.totalCount === 1
      ? targetUserNames[0]
      : insight.targetUsers.totalCount <= 3
      ? `${targetUserNames
          .slice(0, targetUserNames.length - 1)
          .join(", ")} and ${targetUserNames[targetUserNames.length - 1]}`
      : `${targetUserNames.slice(0, 2).join(", ")} and ${
          insight.targetUsers.totalCount - 2
        } others`;

  return (
    <InsightLayout
      user={selectedUser}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          Complete {assessmentGroup.title} Assessment for {targetUserNameStr}
        </div>
      </div>
      <div>
        <InsightButton url="/assessments">
          Complete {pluralize("assessment", insight.targetUsers.totalCount)}
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default AssessmentInsight;
