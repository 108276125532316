import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  GetMeetingQueryQuery,
  GetMeetingQueryQueryVariables,
  MeetingViewMeetingGroupFragmentFragment,
} from "types/graphql-schema";

import Button from "@components/button/button";
import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePageVisibleDebounced from "@components/use-page-visibility/use-page-visible-debounced";
import { classNames } from "@helpers/css";
import { getUrl } from "@helpers/helpers";

import getMeetingQuery from "../graphql/get-meeting-query";
import MeetingDraftContainer from "./draft-container";
import Meeting from "./meeting";
import MeetingHistory from "./meeting-history";
import MeetingViewSidebar from "./meeting-sidebar/meeting-view-sidebar";

const AllMeetingsTab = ({
  meetingGroup,
}: {
  meetingGroup: MeetingViewMeetingGroupFragmentFragment;
}) => {
  const params = useParams<{ meetingId: string }>();
  const [showMeetingHistory, setShowMeetingHistory] = useState(false);
  const meetingId = parseInt(params.meetingId);

  const { loading, error, data, refetch } = useQuery<
    GetMeetingQueryQuery,
    GetMeetingQueryQueryVariables
  >(getMeetingQuery, {
    variables: {
      meetingId,
      meetingGroupId: meetingGroup.id,
      today: moment().startOf("day").format(),
      hasMeetingId: !!meetingId,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleRefetch = () => {
    refetch();
  };

  usePageVisibleDebounced(handleRefetch, 2 * 60); // 2 minutes

  const meeting = data?.meeting;
  const closestMeetingId = // pick meeting closest to today
    data?.meetingGroup?.nextMeetings?.edges?.[0]?.node?.id || // or pick the next meeting
    data?.meetingGroup?.lastMeetings?.edges?.[0]?.node?.id; // or the last meeting that ever happened (case of past one of event)

  // Renders
  if (error) {
    return (
      <Container>
        <GraphqlError
          error={error}
          whatDidNotWork="The meeting could not be loaded."
        />
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (!loading && !meeting) {
    if (!meetingId && closestMeetingId) {
      return (
        <Container>
          <Redirect
            to={getUrl({
              meetingGroupId: meetingGroup.id,
              meetingId: closestMeetingId,
            })}
          />
        </Container>
      );
    }
    if (meetingId) {
      return (
        <Container>
          <GraphqlError
            error={new Error("The meeting could not be loaded")}
            whatDidNotWork="The meeting could not be loaded."
            descriptionList={[
              "This meeting does not exist anymore or you might not have access to it.",
            ]}
          />
        </Container>
      );
    }
  }
  if (!data || !meeting) {
    return null;
  }

  const asideClassName = classNames(
    "hidden lg:block",
    "lg:w-96 xl:w-120 2xl:w-144 3xl:w-192 4xl:w-240",
    "fixed right-0 bottom-0 top-14 overflow-y-scroll"
  );
  return (
    <div className="flex-1 flex min-w-0">
      <main
        className={classNames(
          "flex-1 min-w-0",
          "p-0 pt-3 sm:pt-6 sm:p-6",
          "lg:pr-96 xl:pr-120 2xl:pr-144 3xl:pr-192 4xl:pr-240" // padding and space for sidebar
        )}
      >
        <div className="mb-6">
          <MeetingDraftContainer meeting={meeting}>
            {() => (
              <Meeting
                meeting={meeting}
                opensTopicsInSidebar={false}
                currentPageMeeting={meeting}
              />
            )}
          </MeetingDraftContainer>
        </div>

        {!meeting.draft && (
          <>
            {showMeetingHistory ? (
              <MeetingHistory
                currentPageMeeting={meeting}
                meetingGroupId={meetingGroup.id}
              />
            ) : (
              <div className="flex justify-center pb-8">
                <Button
                  onClick={() => setShowMeetingHistory(true)}
                  text="Search and view meeting history"
                />
              </div>
            )}
          </>
        )}
      </main>

      <aside className={asideClassName} aria-label="Meeting sidebar">
        <div
          className={classNames(
            "flex flex-col gap-8 pt-6 px-6",
            "pb-24" // extra padding for intercom
          )}
        >
          <MeetingViewSidebar meeting={meeting} />
        </div>
      </aside>
    </div>
  );
};

export default AllMeetingsTab;
