import { useQuery } from "@apollo/client";
import { compact, sortBy, uniqBy } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  AssessmentDeliveryState,
  AssessmentState,
  AssessmentType,
  GetUserActivityInComplianceProgramQuery,
  GetUserActivityInComplianceProgramQueryVariables,
  UserActivityComplianceGroupAssessmentGroupFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button from "@components/button/button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import usePkParams from "@helpers/hooks/use-pk-params";
import { joinStringWithCommaAnd, pluralize } from "@helpers/string";

import getUserActivityInComplianceProgramQuery from "../graphql/get-user-activity-in-compliance-program-query";
import ComplianceProgramUserActivityAssessmentDeliveries from "./compliance-program-user-activity-assessment-deliveries";
import ComplianceProgramUserActivityAssessmentList from "./compliance-program-user-activity-assessment-list";
import ComplianceProgramUserActivityAssessmentNominations from "./compliance-program-user-activity-assessment-nominations";
import ComplianceProgramUserActivityMeetingList, {
  getReportsAndManagersWithMatchingOneonones,
} from "./compliance-program-user-activity-meeting-list";

enum Tabs {
  assessments,
  nominations,
  requiredMeetings,
  deliveries,
}

const ComplianceProgramUserActivity = () => {
  const currentUser = currentUserVar();
  const label = useLabel();
  const [selectedTab, setSelectedTab] = useState(Tabs.assessments);
  const complianceProgramId = usePkParams("complianceProgramId");

  const { data: complianceProgramData, loading: isLoadingComplianceProgram } =
    useQuery<
      GetUserActivityInComplianceProgramQuery,
      GetUserActivityInComplianceProgramQueryVariables
    >(getUserActivityInComplianceProgramQuery, {
      variables: { complianceProgramId, responderId: currentUser.id },
      onError: onNotificationErrorHandler(),
    });

  const complianceProgram = useMemo(
    () => complianceProgramData?.complianceProgram,
    [complianceProgramData]
  );
  const deliveries = complianceProgramData?.assessmentDeliveries
    ? assertEdgesNonNull(complianceProgramData.assessmentDeliveries)
    : [];

  const assessmentOpenForNominations = useMemo(
    () =>
      compact(
        complianceProgramData?.assessmentsOpenForNominations
          ? complianceProgramData.assessmentsOpenForNominations.edges.map(
              (edge) => edge!.node
            )
          : []
      ),
    [complianceProgramData]
  );
  const assessmentOpenForNominationWithNominations =
    assessmentOpenForNominations.filter(
      (assessmentOpenForNominations) =>
        assessmentOpenForNominations.nominations.totalCount > 0
    );

  const requiredTopicTemplates = complianceProgram?.requiredTopicTemplates
    ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)
    : [];

  const usersMissingAssessments = complianceProgram?.usersMissingAssessment
    ? complianceProgram.usersMissingAssessment.edges.map((edge) =>
        assertNonNull(edge?.node)
      )
    : [];
  const complianceGroupAssessments = complianceProgram?.assessments
    ? assertEdgesNonNull(complianceProgram.assessments)
    : [];

  const getAssessmentStateList = (
    assessmentGroup: UserActivityComplianceGroupAssessmentGroupFragment
  ) => {
    const matchingUserMissingAssessments = usersMissingAssessments
      .filter((matchingUserMissingPeerAssessment) => {
        return (
          matchingUserMissingPeerAssessment.responder.id === currentUser.id &&
          matchingUserMissingPeerAssessment.assessmentGroup.id ===
            assessmentGroup.id
        );
      })
      .map((matchingUserMissingPeerAssessment) => ({
        id: null,
        target: matchingUserMissingPeerAssessment.target,
        state: null,
      }));
    const createdAssessments = complianceGroupAssessments
      .filter(
        (createdAssessment) => createdAssessment.group.id === assessmentGroup.id
      )
      .map((createdAssessment) => ({
        id: createdAssessment.id,
        target: createdAssessment.target,
        state: createdAssessment.state,
      }));
    const allAssessments = uniqBy(
      [...createdAssessments, ...matchingUserMissingAssessments],
      (assessment) => assessment.target?.id
    );
    return sortBy(allAssessments, (assessment) =>
      assessment.state === null
        ? 1
        : assessment.state === AssessmentState.Draft
        ? 2
        : 3
    );
  };

  const handleChangeTab = (option: ToggleButtonGroupType<Tabs>) => {
    if (option.value !== undefined) {
      setSelectedTab(option.value);
    }
  };

  const assessmentGroups = compact([
    complianceProgram?.performanceAssessmentGroup,
    complianceProgram?.managerAssessmentGroup,
    complianceProgram?.peerAssessmentGroup,
  ]);
  const assessmentCount = assessmentGroups.reduce((memo, assessmentGroup) => {
    const assessments = getAssessmentStateList(assessmentGroup);
    return memo + assessments.length;
  }, 0);
  const assessmentSubmittedCount = assessmentGroups.reduce(
    (memo, assessmentGroup) => {
      const submittedAssessments = getAssessmentStateList(
        assessmentGroup
      ).filter((assessment) => assessment.state === AssessmentState.Submitted);
      return memo + submittedAssessments.length;
    },
    0
  );
  const requiredMeetingsCount = complianceProgram
    ? requiredTopicTemplates.reduce((memo, requiredTopicTemplate) => {
        const oneonones = getReportsAndManagersWithMatchingOneonones(
          complianceProgram,
          requiredTopicTemplate.id
        );
        return memo + oneonones.length;
      }, 0)
    : 0;
  const requiredMeetingsWithMeetingCount = complianceProgram
    ? requiredTopicTemplates.reduce((memo, requiredTopicTemplate) => {
        const oneonones = getReportsAndManagersWithMatchingOneonones(
          complianceProgram,
          requiredTopicTemplate.id
        ).filter((user) => !!user.matchingOneonone);
        return memo + oneonones.length;
      }, 0)
    : 0;

  const deliveriesDraftCount = deliveries.filter(
    ({ state }) => state === AssessmentDeliveryState.Draft
  ).length;

  const nominationDiffCount =
    assessmentOpenForNominations.length -
    assessmentOpenForNominationWithNominations.length;
  const assessmentDiffCount = assessmentCount - assessmentSubmittedCount;
  const meetingsDiffCount =
    requiredMeetingsCount - requiredMeetingsWithMeetingCount;
  const assessmentLabel =
    assessmentCount > 0 && assessmentDiffCount > 0
      ? `submit ${assessmentDiffCount} ${pluralize(
          "assessment",
          assessmentDiffCount
        )}`
      : null;
  const meetingLabel =
    requiredMeetingsCount > 0 && meetingsDiffCount > 0
      ? `schedule ${meetingsDiffCount} ${label("1-on-1", {
          pluralize: meetingsDiffCount,
        })}`
      : null;
  const nominationLabel =
    assessmentOpenForNominations.length > 0 && nominationDiffCount > 0
      ? `nominate responders for ${nominationDiffCount} ${pluralize(
          "assessment",
          nominationDiffCount
        )}`
      : null;
  const deliveriesLabel =
    deliveriesDraftCount > 0
      ? `deliver ${deliveriesDraftCount} ${pluralize(
          "assessment",
          deliveriesDraftCount
        )}`
      : null;
  const currentUserNameLabel = `${currentUser.firstName}, ${
    assessmentLabel || meetingLabel || nominationLabel || deliveriesLabel
      ? "you need to"
      : "you have completed all requirements for this program"
  }`;
  const actionLabels = joinStringWithCommaAnd(
    compact([assessmentLabel, meetingLabel, nominationLabel, deliveriesLabel])
  );
  const currentUserLabels = compact([currentUserNameLabel, actionLabels]).join(
    " "
  );

  const tabButtons = compact([
    assessmentCount > 0 && {
      active: selectedTab === Tabs.assessments,
      title: `${label("review", {
        capitalize: true,
        pluralize: true,
      })} (${assessmentSubmittedCount}/${assessmentCount})`,
      value: Tabs.assessments,
    },
    assessmentOpenForNominations.length > 0 && {
      active: selectedTab === Tabs.nominations,
      title: `Nominations (${assessmentOpenForNominationWithNominations.length}/${assessmentOpenForNominations.length})`,
      value: Tabs.nominations,
    },
    requiredMeetingsCount > 0 && {
      active: selectedTab === Tabs.requiredMeetings,
      title: `Required ${label("1-on-1", {
        pluralize: true,
        capitalize: true,
      })} (${requiredMeetingsWithMeetingCount}/${requiredMeetingsCount})`,
      value: Tabs.requiredMeetings,
    },
    deliveriesDraftCount > 0 && {
      active: selectedTab === Tabs.deliveries,
      title: `Delivered ${label("review", {
        pluralize: true,
      })} (${deliveries.length - deliveriesDraftCount}/${deliveries.length})`,
      value: Tabs.deliveries,
    },
  ]);

  useEffect(() => {
    const match = tabButtons.find(({ value }) => value === selectedTab);
    if (!match && tabButtons.length > 0) {
      setSelectedTab(tabButtons[0].value);
    }
  }, [tabButtons, setSelectedTab, selectedTab]);

  if (isLoadingComplianceProgram) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading program..</Loading>
      </div>
    );
  }

  if (!complianceProgram) {
    return (
      <div className="flex-1 flex justify-center p-10">Program not found</div>
    );
  }

  return (
    <div
      className="sm:flex flex-1 w-full rounded-lg bg-white flex flex-col gap-8 p-6"
      aria-label="Compliance Program user activity"
    >
      <div>
        <div className="flex justify-between gap-2">
          <AppLink
            className="font-medium text-xl hover:underline"
            to={`/programs/${complianceProgram.id}`}
          >
            {complianceProgram.title}
          </AppLink>
          {complianceProgram.canUpdate?.permission && (
            <Button
              small
              to={`/programs/${complianceProgram.id}/admin`}
              text="Manage program"
            />
          )}
        </div>
        <div className="text-xs text-gray-500 mt-1">
          {moment(complianceProgram.startDate).format("LL")} -{" "}
          {moment(complianceProgram.dueDate).format("LL")}
        </div>
      </div>

      <div>{currentUserLabels}.</div>

      <div className="flex">
        <ToggleButtonGroup<Tabs>
          buttons={tabButtons}
          theme={ToggleButtonGroupTheme.buttons}
          onClick={handleChangeTab}
        />
      </div>

      {selectedTab === Tabs.nominations &&
        assessmentOpenForNominations.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="font-medium">Assessment Nominations</div>
            <div className="text-gray-800 text-sm">
              <ComplianceProgramUserActivityAssessmentNominations
                assessmentOpenForNominations={
                  assessmentOpenForNominations || []
                }
                complianceProgramId={complianceProgramId}
              />
            </div>
          </div>
        )}
      {selectedTab === Tabs.assessments &&
        assessmentGroups.map((assessmentGroup) => (
          <div className="flex flex-col gap-2" key={assessmentGroup.id}>
            <div className="font-medium">
              {assessmentGroup.assessmentType === AssessmentType.Performance
                ? "Performance"
                : assessmentGroup.assessmentType === AssessmentType.Manager
                ? "Manager Effectiveness"
                : "Peer"}{" "}
              {label("review", { capitalize: true })}: ({assessmentGroup.title})
            </div>
            <div className="text-gray-800 text-sm">
              <ComplianceProgramUserActivityAssessmentList
                assessments={getAssessmentStateList(assessmentGroup)}
                complianceProgramId={complianceProgramId}
                assessmentGroupId={assessmentGroup.id}
              />
            </div>
          </div>
        ))}

      {selectedTab === Tabs.requiredMeetings &&
        requiredTopicTemplates.map((requiredTopicTemplate) => (
          <div className="flex flex-col gap-2" key={requiredTopicTemplate.id}>
            <div className="font-medium">
              Required {label("1-on-1", { pluralize: true })}:{" "}
              {requiredTopicTemplate.title}
            </div>
            <ComplianceProgramUserActivityMeetingList
              requiredTopicTemplateId={requiredTopicTemplate.id}
              complianceProgram={complianceProgram}
            />
          </div>
        ))}

      {selectedTab === Tabs.deliveries && deliveries.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="font-medium">Assessment deliveries</div>
          <div className="text-gray-800 text-sm">
            <ComplianceProgramUserActivityAssessmentDeliveries
              assessmentDeliveries={deliveries || []}
              complianceProgramId={complianceProgramId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplianceProgramUserActivity;
