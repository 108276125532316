import { Location } from "history";

export type BasicUser = {
  id: number;
  name: string;
  avatar?: string | null;
  email?: string;
  firstName?: string;
};

export type BasicTeam = {
  id: number;
  title: string;
};

export enum DateRangeEnum {
  previousQuarter = "previous-quarter",
  thisQuarter = "this-quarter",
  nextQuarter = "next-quarter",
  previousMonth = "previous-month",
  thisMonth = "this-month",
  nextMonth = "next-month",
}

export enum PastOnlyDateRangeEnum {
  previousQuarter = "previous-quarter",
  previousMonth = "previous-month",
  previousYear = "previous-year",
}

export type TFLocationState =
  | {
      background?: TFLocation;
      previousPathname?: string;
    }
  | undefined;

export type TFLocation = Location<TFLocationState>;
