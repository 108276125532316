import Loading from "@components/loading/loading";

const SidebarSyncingCalendar = ({ currentUser }) => {
  if (!currentUser.calendarIsSyncingForFirstTime) {
    return null;
  }
  return (
    <div>
      <div className="bg-white text-base mt-4 font-medium bg-white rounded-lg border border-dashed flex flex-col gap-2 justify-center items-center py-6">
        <span className="gradient-basic text-transparent bg-clip-text">
          Calendar is syncing...
        </span>
        <Loading size={6} />
      </div>
    </div>
  );
};
export default SidebarSyncingCalendar;
