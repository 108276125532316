import { Switch } from "@headlessui/react";

import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

export const ToggleSwitch = ({
  checked,
  onChange,
  tooltip = null,
  disabled = false,
  height = 5,
  width = 9,
}: {
  tooltip?: string | null;
  disabled?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  width?: number;
  height?: number;
}) => (
  <Tooltip text={tooltip}>
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={classNames(
        checked ? "bg-emerald-600" : "bg-gray-300",
        !disabled && "cursor-pointer",
        "relative inline-flex flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:ring-offset-2",
        `h-${height} w-${width}`
      )}
    >
      <span className="sr-only">Use settings</span>
      <span
        aria-hidden="true"
        className={classNames(
          checked ? `translate-x-${height - 1}` : "translate-x-0",
          `pointer-events-none inline-block h-${height - 1} w-${
            height - 1
          } transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`
        )}
      />
    </Switch>
  </Tooltip>
);

export default ToggleSwitch;
